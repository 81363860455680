import React from 'react'
import Popup from './Popup'

function BatchDetailsPopup(props) {
    var batchData = props.batchData1
    const modalbody = (
        <>
            <div><span className="fw-600 pe-2 font-13">Batch Name:</span>  {batchData.batchName}</div>
            <div><span className="fw-600 pe-2 text-justify font-13">Batch Notes:</span>  {batchData.batchNote || '-'}</div>
            <div className="fw-600 pe-2 text-justify mt-3 font-15 ps-2">Ticket History</div>
            <div className='col-12'  >
                {props.AttachTicketData.length != 0 ?
                    props.AttachTicketData.map((item, i) => {
                        return (
                            <div className="greyBox p-3 mb-3 CommonClassForTicketID" idForTicketId={item.id} key={i}>
                                <div className="row">
                                    <div className="col-md-6 my-1"> <b className='font-13'>Ticket #: </b><span>&nbsp;{item.ticket_num}</span></div>
                                    <div className="col-md-6 my-1"> <b className='font-13'>Status: </b><span>&nbsp;{item.ticket_status_name}</span> </div>
                                    <div className="col-md-6 my-1"> <b className='font-13'>Serial #: </b>&nbsp;<span className="wordwrap">{item.SerialNum}</span> </div>
                                    <div className="col-md-6 my-1"> <b className='font-13'> User: </b>&nbsp;<span>{(item?.student?.device_user_first_name || '-') + " " + (item?.student?.device_user_middle_name || '') + ' ' + (item?.student?.device_user_last_name || '')}</span> </div>
                                    <div className="col-12 my-1"> <b className='font-13'> Notes: </b>&nbsp;<span>{item.notes}</span> </div>
                                </div>
                                <hr className="my-1" />
                                <div className="col-12 data-table-container">
                                    <table className="table data-table mb-0" >
                                        <thead className='GridHeader'>
                                            <tr>
                                                <th scope="col" className='fw-600 font-13'>Part Name</th>
                                                <th scope="col" className='fw-600 font-13'>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.ticket_attachment.length != 0 ?
                                                item.ticket_attachment.map((itemPart, i) => {
                                                    return (
                                                        <tr key={i} className={`OddEvenClass tableBorderHide ${item.TicketFlag} CommonClassParts_${item.id}`}
                                                            idForPartID={`PartsNoteId_${itemPart.ID}`}>
                                                            <td className={`${itemPart.Parts_Covered_by == 1 ? 'highlight-part py-12' : 'py-12'}`}>{itemPart.part_name}</td>
                                                            <td className='py-12'>${itemPart.Parts_Price == null ? '0' : itemPart.Parts_Price.toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={5} className="p-3 text-center">
                                                        No Record Found
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className='text-center' col-md-12>No Record Found</div>
                }
            </div>
        </>
    )
    return (
        <Popup isshow={props.isShow} size={"lg"} title={"Batch Details"}
            closePopup={(e) => { props.ClosePopup() }} modalBody={modalbody} visibleSaveBtn={false} />
    )
}

export default BatchDetailsPopup
