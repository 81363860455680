import React from 'react'
import { HideLoder, ShowLoder } from '../JS/Common';

export default function PageHead({
    LimitForPagination,
    setLimitForPagination,
    setCurrentPage,
    heading,
    handleSearch,
    Alerts,
    loading,
    showsearch }) {
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row align-items-center pe-0'>
                <div className={`${showsearch ? 'col-md-6' : 'col-md-9'}`}>
                    <h1 className="PageHeading mb-0">{heading}</h1>
                </div>
                {showsearch && (
                    <div className="col-md-3 row align-items-center">
                        <div className="px-1 col-md-8 text-end font-13">Items per page:</div>
                        <div className="col-md-4 px-0">
                            <select value={LimitForPagination} onChange={(e) => {
                                setLimitForPagination(e.target.value);
                                setCurrentPage(1);
                            }}>
                                {[25, 50, 100, 150, 200].map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </select></div>
                    </div>
                )}
                {showsearch && (
                    <div className="col-md-3 pe-0 text-end">
                        <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                            <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon">
                                <i className="bi bi-search"></i>
                            </div>
                            <input className="form-control marginCss" autoComplete="off" type="text"
                                placeholder="Search" onKeyUp={handleSearch} />
                        </form>
                    </div>
                )}
            </div>
        </>
    )
}
