import React, { useContext, useEffect, useRef } from "react";
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { questiondetailscontext } from "./HelpdeskTicket";

export default function TextEditor({ identifier, subindex, subcategoryindex }) {
    const { HelpdeskDetails, setHelpdeskDetails } = useContext(questiondetailscontext);
    const quillRef = useRef(null);
    const customImageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/svg+xml,image/png,image/jpeg,image/jpg');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            if (file.size > 2 * 1024 * 1024) {
                alert("Image size exceeds 2MB limit.");
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                quill.insertEmbed(range.index, 'image', e.target.result);
            };
            reader.readAsDataURL(file);
        };
    };

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.getModule('toolbar').addHandler('image', customImageHandler);
        }
    }, []);

    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ],
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];

    const handleProcedureContentChange = (content) => {
        if (identifier === "mainCategory") {
            setHelpdeskDetails(prevDetails => {
                return { ...prevDetails, description: content };
            })
        } else if (identifier === "Subcategory") {
            const updatedSubCategory = [...HelpdeskDetails.SubCategory];
            updatedSubCategory[subindex].description = content;
            setHelpdeskDetails({
                ...HelpdeskDetails,
                SubCategory: updatedSubCategory
            });
        } else {
            setHelpdeskDetails(prevState => ({
                ...prevState,
                SubCategory: prevState.SubCategory.map((subCategory, Index) => {
                    if (Index === subcategoryindex) {
                        const updatedIssues = subCategory.issues.map((issue, i) => {
                            if (i === subindex) {
                                return { ...issue, description: content };
                            }
                            return issue;
                        });
                        return {
                            ...subCategory,
                            issues: updatedIssues
                        };
                    }
                    return subCategory;
                })
            }));
        }

    };

    return (
        <div style={{ justifyContent: "center" }}>
            <ReactQuill
                ref={quillRef} // Attach the ref to ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                placeholder="Description ...."
                onChange={handleProcedureContentChange}
            />
        </div>
    )
}
