import React from 'react'

export function TermsAndCondition() {
  return (
    <div>
      <div className='termsandconditionheaderstyle row text-center'>
        <div className='row px-5 pt-4'>
          <div className='col-md-6 text-start'>
            <img src="/images/logo-icon.svg" id="LeftMenuLogo" className="logo-icon Leftmenulogo" alt="logo icon" />
          </div>
          <div className='col-md-6 text-end'>
            <a className='cursor-pointer' href="/" style={{ color: 'white' }}><u>Login</u></a>&nbsp;&nbsp;&nbsp;
            <span><a className='cursor-pointer' href="/register" style={{ color: 'white' }}><u>Sign Up</u></a></span>
          </div>
        </div>

        <h1 style={{ paddingTop: '1rem', paddingBottom: '7rem', color: 'white' }}>Terms of Service</h1>

      </div>
      <div className="GridBox p-3" style={{ marginLeft: '11rem', marginRight: '11rem', position: 'relative', top: '-80px' }}>
        <div className="p-4 row">
          <p>Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the https://rocket.k12techrepairs.com website (the “Service”) operated by K-12 Tech (“us”, “we”, or “our”).</p>
          <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Service.</p>
          <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.</p>
          <ul>
            <li className='py-2 text-justify'><b>Links To Other Web Sites:</b> Our Service may contain links to third party websites or services that are not owned or controlled by K-12 Tech. K-12 Tech has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites. You acknowledge and agree that K-12 Tech shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party websites or services. We strongly advise you to read the terms and conditions and privacy policies of any third party websites or services that you visit.</li>

            <li className='py-2 text-justify'><b>Termination:</b> We may terminate or suspend your access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms. All provisions of the Terms which by their nature should survive termination shall survive
              termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</li>

            <li className='py-2 text-justify'><b>Governing Law:</b> These Terms shall be governed and construed in accordance with the laws of Indiana, United States, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.</li>

            <li className='py-2 text-justify'><b>Changes:</b> We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</li>


          </ul>

          <h2 className='pt-4 pb-3 text-decoration-underline'>Cookies Policy</h2>
          <p>K-12 Tech (“us”, “we”, or “our”) uses cookies on the https://rocket.k12techrepairs.com website (the “Service”). By using the Service, you consent to the use of cookies.</p>
          <p>Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.</p>
          <ul>
            <li className='py-2 text-justify'><b>What are cookies:</b> Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you. Cookies can be “persistent” or “session” cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser.</li>

            <li className='py-2 text-justify'><b>How K-12 Tech uses cookies:</b> When you use and access the Service, we may place a number of cookies files in your web browser.
              We use cookies for the following purposes:
              <ul>
                <li className='text-justify py-1'>To enable certain functions of the Service</li>
                <li className='text-justify py-1'>To provide analytics</li>
              </ul>
              We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:
              <ul>
                <li className='text-justify py-1'>Essential cookies. We may use cookies to remember information that changes the way the Service behaves or looks, such as a user's language preference on the Service.</li>
                <li className='text-justify py-1'>Analytics cookies. We may use analytics cookies to track information how the Service is used so that we can make improvements. We may also use analytics cookies to test new advertisements, pages, features or new functionality of the Service to see how our users react to them.</li>
              </ul>
            </li>

            <li className='py-2 text-justify'><b>Third-party cookies:</b> In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.</li>

            <li className='py-2 text-justify'><b>What are your choices regarding cookies:</b> If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</li>

            <ul>
              <li className='text-justify py-1'>For the Chrome web browser, please visit this page from Google: <br /><a href="https://support.google.com/accounts/answer/32050" target="_blank" rel="noopener noreferrer" style={{ color: '#2cc3a9' }}>https://support.google.com/accounts/answer/32050</a></li>
              <li className='text-justify py-1'>For the Internet Explorer web browser, please visit this page from Microsoft: <br /><a href="https://support.microsoft.com/en-us" target="_blank" rel="noopener noreferrer" style={{ color: '#2cc3a9' }}>http://support.microsoft.com/kb/278835</a></li>
              <li className='text-justify py-1'>For the Firefox web browser, please visit this page from Mozilla: <br /><a href="https://support.mozilla.org/en-US/kb/how-clear-firefox-cache" target="_blank" rel="noopener noreferrer" style={{ color: '#2cc3a9' }}>https://support.mozilla.org/en-US/kb/how-clear-firefox-cache</a></li>
              <li className='text-justify py-1'>For the Safari web browser, please visit this page from Apple: <br /><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer" style={{ color: '#2cc3a9' }}>https://support.apple.com/en-us/HT201265</a></li>
            </ul>
            For any other web browser, please visit your web browser's official web pages.

          </ul>
          <h2 className='pt-4 pb-3 text-decoration-underline'>Disclaimer</h2>
          <ul>
            <li className='py-2 text-justify'>The information contained on https://rocket.k12techrepairs.com website (the “Service”) is for general information purposes only.</li>
            <li className='py-2 text-justify'>K-12 Tech assumes no responsibility for errors or omissions in the contents on the Service.</li>
            <li className='py-2 text-justify'>In no event shall K-12 Tech be liable for any special, direct, indirect, consequential, or
              incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. K-12 Tech reserves the right to make additions, deletions, or modification to the contents on the Service at any time without prior notice.</li>
            <li className='py-2 text-justify'>K-12 Tech does not warrant that the Service is free of viruses or other harmful components.</li>
          </ul>

        </div>
      </div>
    </div>
  )
}

