import React, { useEffect, useState } from 'react';

function VideoUpload(props) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [Filename, setFilename] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        props.selectedFile(selectedFile);
    }, [selectedFile, props]);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 10485760) {
                setSelectedFile(file);
                setFilename(file.name);
                setError('');
            } else {
                setSelectedFile(null);
                setFilename('');
                setError('File size exceeds the limit. You can upload up to 10 MB.');
            }
        }
    };


    return (
        <div className='col-md-4 py-3'>
            <input type="file" id="UploadVideoId" name="myFile" accept="video/*" onChange={handleFileChange} />
            <label className="supportTicketbtn col-md-12" htmlFor="UploadVideoId" style={{ color: "#909091" }}>
                Choose File (Attach Video)
            </label>
            {Filename && <label className="ps-2 mt-1" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{Filename} </label>}
            {error && <div className="ps-2 mt-1" style={{ color: 'red' }}>{error}</div>}
        </div>
    );
}

export default VideoUpload;
