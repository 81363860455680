import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { IndexUsers } from '../ManageUsers';
import { UsersAddUpdate } from '../ManageUsers/addupdate';
import { Setup } from '../ParentalCoverage/setup';
import AddSetup from '../ParentalCoverage/AddSetup';
import History from '../ParentalCoverage/History';
import CoveragePurchased from '../ParentalCoverage/CoveragePurchased';
import { ImportExport } from '../ImportExport';
import { ErrorPage } from '../404Error';
import { FaqIndex } from '../FAQ';

export default function Access13() {
    return (
        <Routes>
            <Route path="/" element={<IndexUsers />} />
            <Route path="/users/*" element={<IndexUsers />} />
            <Route path="/addupdate-users/*" element={<UsersAddUpdate />} />
            <Route exact path="/setup" element={<Setup />} />
            <Route exact path="/add-setup" element={<AddSetup />} />
            <Route exact path="/plan-history" element={<History />} />
            <Route exact path="/purchases" element={<CoveragePurchased />} />
            <Route exact path="*" element={<ErrorPage />} />
            <Route exact path="/import-export" element={<ImportExport />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
        </Routes>
    )
}
