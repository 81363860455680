import React from 'react'
import { useState } from 'react';
import { ApiGetCall, ApiPostCall } from '../JS/Connector';
import { HideLoder, LogInSchoolID, LogInUserID, ShowLoder } from '../JS/Common';
import { useEffect } from 'react';
import AlertsComp from './AlertsComp';
import Cookies from 'js-cookie';
import Popup from './Popup';

function TicketStatusDropdown(props) {
    const [TicketStatus, setTicketStatus] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [isStatusClosepopup, setIsStatusClosepopup] = useState(false);
    const [NewAlerts, setNewAlerts] = useState("");
    const [selectedOptionForLoner, setSelectedOptionForLoner] = useState('');
    const [Errormsg1, setErrormsg1] = useState('');
    const [NoteStatusPopUp, setNoteStatusPopUp] = useState(false);
    const [ticketstatusnotes, setTicketStatusNotes] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        ListOfTicketStatus();
    }, [])

    async function ListOfTicketStatus() {
        if (props.GridFlag == 1 || props.GridFlag == 3) {
            var flag = 1
        } else if (props.GridFlag == 2) {
            var flag = 2
        } else if (props.GridFlag == 5) {
            var flag = 3
        }
        setLoading(true);
        await ApiGetCall("/getTicketStatus/" + flag).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setTicketStatus(responseRs);
                setLoading(false);
            }
        });
    };



    function handleOptionChange(e) {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue);
        if (LogInSchoolID != 0) {
            if (selectedValue == '2' && props.lonerFlagCount != 0) {
                setIsStatusClosepopup(true);
            } else if (selectedValue == '7' && props.lonerFlagCount != 0) {
                setIsStatusClosepopup(true);
            } else if (selectedValue == '8' && props.lonerFlagCount != 0) {
                setIsStatusClosepopup(true);
            } else {
                setIsStatusClosepopup(false);
                DropdownStatusCall(selectedValue);
            }
        }

        if (LogInSchoolID == 0 || LogInSchoolID == undefined || LogInSchoolID == '')
            if (['9', '10', '11', '12'].includes(selectedValue)) {
                setNoteStatusPopUp(true);
            } else if (selectedValue !== '2' && [2, 3, 4].includes(props.GridFlag)) {
                DropdownStatusCall(selectedValue);
            } else if (selectedValue === '2' && props.GridFlag === 3) {
                DropdownStatusCall(selectedValue);
            }

    }

    async function DropdownStatusCall(selectedValue) {
        if (selectedOptionForLoner == 0 && selectedValue == '2' && props.lonerFlagCount != 0) {
            setErrormsg1('Select atleast one option')
            return
        } else if (selectedOptionForLoner == 0 && selectedValue == '7' && props.lonerFlagCount != 0) {
            setErrormsg1('Select atleast one option')
            return
        } else if (selectedOptionForLoner == 0 && selectedValue == '8' && props.lonerFlagCount != 0) {
            setErrormsg1('Select atleast one option')
            return
        }

        const issueIDArray = props.selectedIds.map((ticketId) => {
            return { TicketID: ticketId };
        });

        var raw = JSON.stringify({
            UserId: LogInUserID,
            IssueIDArray: issueIDArray,
            Status: selectedValue,
            Flag: props.lonerFlagCount != 0 ? 1 : 0,
            closestatus: selectedOptionForLoner,
            schoolID: LogInSchoolID
        });
        await ApiPostCall("/changeticketStatus", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Status Updated Successfully"} />);
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />)
                        if (selectedValue == 2) {
                            setIsStatusClosepopup(false);
                        }
                        if (props.GridFlag == 1) {
                            props.GetGridData(1, null, null, null)
                        } else if (props.GridFlag == 2) {
                            props.GetGridData(2, null, null, null)
                        } else {
                            props.GetGridData(3, null, null, null)
                        }
                        props.ClosePopup();
                        props.setSelectedIds([])
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    async function DropdownStatusCallForAdmin(selectedValue) {
        var raw = JSON.stringify({
            UserId: LogInUserID,
            TicketIDArray: props.selectedIds,
            Status: selectedValue,
            Note: ticketstatusnotes,
            WhoWorkedOn: Cookies.get('CsvUserId'),
            schoolID: props.schoolId
        });
        await ApiPostCall("/AdminChangeTicketStatus", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Status Updated Successfully"} />);
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />)
                        setNoteStatusPopUp(false)
                        var batchid = props.selectedbatchid
                        if (batchid == 0) {
                            batchid = null
                        }
                        if (props.tabflagforadmin == 4) {
                            props.GetGridData(null, 4, batchid, null)
                        } else if (props.tabflagforadmin == 1) {
                            props.GetGridData(null, 1, batchid, null)
                        } else if (props.tabflagforadmin == 2) {
                            props.GetGridData(null, 2, batchid, null)
                        } else {
                            props.GetGridData(null, 3, batchid, null)
                        }
                        props.setSelectedIdsAtVendor1([]);
                        props.setSelectedIdsSentOut1([]);
                        props.setSelectedIdsPedning1([]);
                        props.setSelectedIdsOpen1([]);
                        props.setSelectedIdsAtVendor([]);
                        props.setSelectedIdsSentOut([]);
                        props.setSelectedIdsPedning([]);
                        props.setSelectedIdsOpen([]);
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    function handleStatusForClose(flag) {
        if (flag === 'FreeDevice') {
            setSelectedOptionForLoner(2)
        } else if (flag === 'ParmanentDevice') {
            setSelectedOptionForLoner(1)
        } else {
            setSelectedOptionForLoner(0)
        }
    }
    const assignlonerbody = (
        <>
            <div>
                <input className="form-check-input me-2" type="radio" name="CloseStatus" id="FreeDevice" value="FreeDevice"
                    onChange={() => handleStatusForClose('FreeDevice')} /> Give Original Device Back To User?
            </div>
            <div className="col-12">
                <input className="form-check-input me-2" type="radio" name="CloseStatus" id="ParmanentDevice" value="ParmanentDevice"
                    onChange={() => handleStatusForClose('ParmanentDevice')} /> Keep loaner device as a permanent device for student?
            </div>
            {selectedOptionForLoner == 0 ?
                <div className=" px-5" id='statusMsg' style={{ color: 'red' }}>{Errormsg1}</div>
                : <></>
            }
        </>
    )
    const ticketstatusbody = (
        <div className="p-3">
            <div className="col-12">
                <textarea rows={3} className="form-control" value={ticketstatusnotes} onChange={(e) => setTicketStatusNotes(e.target.value)}></textarea>
                <span>Notes</span>
            </div>
        </div>
    )
    return (
        <div>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <select onChange={(e) => handleOptionChange(e)} value={selectedOption} >
                <option>Change Ticket Status</option>
                {TicketStatus.map((item, i) => {
                    var returData;
                    returData = (
                        <option className="TicketStatus" statusid={item.id} value={item.id} key={i}>{item.status} </option>
                    );
                    return returData;
                })}
            </select>

            <Popup isshow={isStatusClosepopup} size={"md"} title={"Assign a loaner"}
                closePopup={(e) => setIsStatusClosepopup(false)}
                modalBody={assignlonerbody} handleSave={(e) => DropdownStatusCall(selectedOption)} visibleSaveBtn={true} btnText={"Submit"} />

            <Popup isshow={NoteStatusPopUp} size={"md"} title={"Ticket Status Note"}
                closePopup={(e) => setNoteStatusPopUp(false)}
                modalBody={ticketstatusbody} visibleSaveBtn={true} handleSave={(e) => DropdownStatusCallForAdmin(selectedOption)} btnText={"Change Status"} />
        </div >
    )
}

export default TicketStatusDropdown
