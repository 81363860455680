import React, { Fragment, useContext, useEffect, useState } from "react";
import { CamelCaseWithSpaces, Camelcase, LogInSchoolID, LogInUserID, formatDateToMMDDYYYY, getUrlParameter, typewatch } from "../../JS/Common";
import { ApiGetCall, ApiGetHeaders } from "../../JS/Connector";
import { DeviceDetailsPopup } from "../../Components/DeviceDetailsPopup";
import TicketDetailsPopup from "../../Components/TicketDetailsPopup";
import TicketStatuspopup from "../../Components/TicketStatuspopup";
import { useLocation, useNavigate } from "react-router-dom";
import TicketStatusDropdown from "../../Components/TicketStatusDropdown";
import Pagination from "react-js-pagination";
import CustomizationPopup from "../../Components/CustomizationPopup";
import AllTickets from "./AllTickets";
import UserDetailsPopup from "../../Components/UserDetailsPopup";
import AlertsComp from "../../Components/AlertsComp";
import { UserContext } from "../../App";
import PageHead from "../../Components/PageHead";
import Tab from "../../Components/Tab";
export function ManageTicket() {
  const [TicketTabs, setTicketTabs] = useState({
    OpenTicket: "active",
    AllTicket: "",
    CloseTicket: "",
    PendingTicket: "",
  });
  const [TicketParams, setTicketParams] = useState({
    ticketTabFlag: 1,
    Sortbykey: "",
    SortbyFlag: "asc",
    inventoryId: "",
    ticketId: "",
    lonerFlag: "",
  });
  const [searchString, setSearchString] = useState({
    SearchForOpen: "",
    SearchForClose: "",
    SearchForAllOpen: "",
    SearchForAllClose: "",
    SearchForPending: "",
  });
  const [GridData, setGridData] = useState([]);
  const [isModal, setisModal] = useState({
    isshowpopup: false,
    isTicketDetailspopup: false,
    isTicketStatuspopup: false,
    IsDropdownStatusVisible: false,
    TicketStatusId: ''
  });
  const [noteVisibility, setNoteVisibility] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [CustomizationModal, setCustomizationModal] = useState(false)
  const [columnNames, setColumnNames] = useState([]);
  const showPagination = GridData.length > 0;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRangeCount, setpageRangeCount] = useState(0);
  const [LimitForPagination, setLimitForPagination] = useState(25);
  const [UserModal, setUserModal] = useState(false)
  const [Userid, setUserid] = useState('');
  const navigate = useNavigate();
  const [lonerFlagCount, setLonerFlagCount] = useState(0);
  const [alert, setAlert] = useState('')
  const [Skey, setSKey] = useState('')
  const { UserData } = useContext(UserContext);
  var MoreTicketFlag = getUrlParameter("flag");
  const isAllSelected =
    TicketParams.ticketTabFlag == 1
      ? selectedIds.length == GridData.filter((item) => item.StatusID !== 11 && item.StatusID !== 12).length
      : selectedIds.length == GridData.length;

  const location = useLocation();
  const TicketIdFromCreateTicket = location.state && location.state.ticketid;
  useEffect(() => {
    const isAlertShownInHistory = window.history.state && window.history.state.alertShown;
    if (TicketIdFromCreateTicket != null && !isAlertShownInHistory) {
      setAlert(<AlertsComp show={true} variant="success" msg={`Ticket (${TicketIdFromCreateTicket}) Created Successfully`} />);
      window.history.pushState({ alertShown: true }, '');
      setTimeout(function () {
        setAlert(<AlertsComp show={false} />);
      }, 5000);
    }
  }, [TicketIdFromCreateTicket]);
  useEffect(() => {
    ApiGetHeaders(41, setColumnNames)
  }, []);
  useEffect(() => {
    if (TicketTabs.OpenTicket == 'active') {
      GetGridData(1, searchString.SearchForOpen || null, Skey ? Skey : null, null);
    } else if (TicketTabs.CloseTicket == 'active') {
      GetGridData(2, searchString.SearchForClose || null, Skey ? Skey : null, null);
    } else if (TicketTabs.PendingTicket == 'active') {
      GetGridData(3, searchString.SearchForPending || null, Skey ? Skey : null, null);
    }
    if (MoreTicketFlag == 1) {
      setTicketTabs({ ...TicketTabs, PendingTicket: "active", AllTicket: "", CloseTicket: "", OpenTicket: "", });
      GetGridData(3, null, Skey ? Skey : null, null);
    }
  }, [currentPage, LimitForPagination]);
  useEffect(() => {
    if (selectedIds.length == 0) {
      setisModal({ ...isModal, IsDropdownStatusVisible: false });
    }
  }, [selectedIds])
  async function GetGridData(flag, SearchString, skey, allticketsearchflag) {
    SearchString = SearchString || null;
    if (skey) TicketParams.SortbyFlag = TicketParams.SortbyFlag == "asc" ? "desc" : "asc";
    typewatch(async function () {
      setLoading(true)
      await ApiGetCall("/Tickets/" + LogInSchoolID + "&" + flag + "&" + SearchString + "&" + allticketsearchflag + "&" + skey + "&" + TicketParams.SortbyFlag + "&" + LogInUserID + '&' + currentPage + '&' + LimitForPagination).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          setLoading(false);
          const responseRs = JSON.parse(result);
          setpageRangeCount(responseRs.total)
          if (flag != 4) {
            setGridData(responseRs.tickets.length ? responseRs.tickets : [])
          }
        }
      });
    }, SearchString != null ? 1000 : 0);
  }

  async function ExportTicketsData(flag) {
    setLoading(true);
    await ApiGetCall("/exportTickets/" + LogInSchoolID + '&' + flag).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        if (responseRs.length > 0) {
          DownloadCsv(responseRs, flag);
        } else {
          DownloadCsv([]);
        }
        setLoading(false);
      }
    });
  }
  const DownloadCsv = (CsvDataForTicket, flag) => {
    const csvContent = [];
    const buildingMap = [];
    CsvDataForTicket?.forEach((ticket) => {
      const rowData = {
        'Ticket Number': ticket.ticket_num,
        'Serial Number': ticket?.inventory_management?.serial_number,
        'Student Number': ticket?.inventory_management?.student_inventory?.student?.student_num,
        'User First Name': ticket?.inventory_management?.student_inventory?.student?.device_user_first_name,
        'User Middle Name': ticket?.inventory_management?.student_inventory?.student?.device_user_middle_name,
        'User Last Name': ticket?.inventory_management?.student_inventory?.student?.device_user_last_name,
        'Ticket Created By': ticket?.user?.first_name + ' ' + ticket?.user?.last_name,
        'Ticket Status': `"${ticket?.status?.status?.replace(/\n/g, '-')}"`,
        'Grade': ticket?.inventory_management?.student_inventory?.student?.grade,
        'Building': ticket?.inventory_management?.building_name?.name,
        'Return Location': ticket?.returnLocationName,
        'Notes': `"${ticket?.notes?.replace(/\n/g, ' ')}"`,
        'Created Date': ticket.CreatedDate ? ticket.CreatedDate : '',
        'Closed Date': ticket.closedDate ? ticket.closedDate : '',
        'Issues': `"${ticket?.issues.join(", ")}"`,
        'Parts': `"${ticket?.parts.map((itemm) => `${itemm.partName} ($${itemm.partPrice})`).join(", ")}"`,
        'Total Cost': `$${ticket?.subtotal}`
      };
      buildingMap.push(rowData);
    });

    if (buildingMap.length > 0) {
      const headers = ['Ticket Number', 'Serial Number', 'Student Number', 'User First Name', 'User Middle Name', 'User Last Name', 'Ticket Created By', 'Ticket Status',
        'Grade', 'Building', 'Return Location', 'Notes', 'Created Date', 'Closed Date', 'Issues', 'Parts', 'Total Cost'];
      csvContent.push(headers);
      buildingMap.forEach((rowData) => {
        const values = Object.values(rowData);
        csvContent.push(values);
      });
      csvContent.push([]);
      const csvString = csvContent.map((row) => row.join(',')).join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = flag == 1 ? 'OpenTickets.csv' : flag == 2 ? 'ClosedTickets.csv' : 'RepairedTickets.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log('No data available for download.');
    }
  };
  function handleArrowClick(ticketId) {
    setNoteVisibility((prevState) => ({
      ...prevState,
      [ticketId]: !prevState[ticketId],
    }));
  }

  function handleHeaderCheckboxChange(e) {
    const isChecked = e.target.checked;
    let updatedSelectedIds;
    if (isChecked) {
      const filteredData = GridData.filter(item => item.StatusID !== 11 && item.StatusID !== 12);
      updatedSelectedIds = filteredData.map(item => item.id);
      setSelectedIds(updatedSelectedIds);
      setisModal({ ...isModal, IsDropdownStatusVisible: true });
    } else {
      setSelectedIds([]);
      setisModal({ ...isModal, IsDropdownStatusVisible: false });
      updatedSelectedIds = [];
    }
    const lonerFlagCount = updatedSelectedIds.reduce((count, id) => {
      const selectedItem = GridData.find(item => item.id === id);
      return selectedItem && selectedItem.LonerFlag === 'yes' ? count + 1 : count;
    }, 0);
    setLonerFlagCount(lonerFlagCount);
  }

  function handleCheckboxChange(e, id, item) {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedIds((prevIds) => [...prevIds, id]);
      setisModal({ ...isModal, IsDropdownStatusVisible: true });
      if (item && item.LonerFlag === 'yes') {
        setLonerFlagCount((prevCount) => prevCount + 1);
      }
    } else {
      setSelectedIds((prevIds) =>
        prevIds.filter((selectedId) => selectedId !== id)
      );
      if (item && item.LonerFlag === 'yes') {
        setLonerFlagCount((prevCount) => Math.max(prevCount - 1, 0));
      }
    }
  }
  async function print() {
    setLoading(true);
    await ApiGetCall("/closedTicketsPdf/" + LogInSchoolID + '&' + LogInUserID).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        setLoading(false);
        if (result == "success") {
          setAlert(<AlertsComp show={true} variant="success" msg={`The PDF has been successfully sent via email : ${UserData.emailid}`} />);
          setTimeout(function () {
            setAlert(<AlertsComp show={false} />)
          }, 7000);
        }
      }
    });
  }
  function RepairdetailsScreen(TicketID, FlagParent) {
    setTicketParams({ ...TicketParams, ticketId: TicketID, });
    var tabTicketId = TicketID
    var ticketTabFlag = TicketParams.ticketTabFlag
    var workedonflag = 0
    var parentalCoverageFlag = FlagParent
    navigate('/repair-details', { state: { tabTicketId, ticketTabFlag, workedonflag, parentalCoverageFlag } });
  }
  const handleSortClick = (item) => {
    setSKey(item)
    if (TicketTabs.OpenTicket == 'active') {
      GetGridData(TicketParams.ticketTabFlag, searchString.SearchForOpen, item, null)
    } else if (TicketTabs.CloseTicket == 'active') {
      GetGridData(TicketParams.ticketTabFlag, searchString.SearchForClose, item, null)
    } else if (TicketTabs.PendingTicket == 'active') {
      GetGridData(TicketParams.ticketTabFlag, searchString.SearchForPending, item, null)
    }
  };
  function renderContentForColumn(columnName, item) {
    const contentMap = {
      "ticket_num": <span
        className="cursor-pointer"
        title="Show Ticket Details"
        onClick={(e) => {
          setisModal({ ...isModal, isTicketDetailspopup: true });
          setTicketParams({ ...TicketParams, ticketId: item.id, inventoryId: item.inventory_id });
        }}
      >
        <u>{item.ticket_num || '-'}</u>
      </span>,
      "Serial_number": <span
        className="cursor-pointer"
        title="Show Device Details"
        onClick={(e) => {
          setisModal({ ...isModal, isshowpopup: true });
          setTicketParams({ ...TicketParams, inventoryId: item.inventory_id, ticketId: item.id });
        }}
      >
        <u>{item.serialNum || "-"}</u>
      </span>,
      "User": item?.inventory_management?.student_inventory?.student?.device_user_first_name ?
        <u onClick={(e) => { setUserid(item?.inventory_management?.student_inventory?.student?.id); setUserModal(true) }} className="cursor-pointer">{Camelcase(item?.inventory_management?.student_inventory?.student?.device_user_first_name) + ' ' + (Camelcase(item?.inventory_management?.student_inventory?.student?.device_user_middle_name || ' ')) + ' ' + Camelcase(item?.inventory_management?.student_inventory?.student?.device_user_last_name || '')}</u>
        : '-',
      "return_location": item.returnLocationName || '-',
      "created_at": formatDateToMMDDYYYY(item.date) || "-",
      "created_by": item.TicketCreatedBy || "-",
      "parts_total": item.subtotal ? `$${item.subtotal.toFixed(2)}` : "$0",
      "Device_model": item.Device_model || "-",
      "asset_tag": item.asset_tag || "-",
      "Grade": item.Grade || "-",
      "Parent_Guardian_Email": item.Parent_Guardian_Email || "-",
      "Parent_phone_number": item.Parent_phone_number || "-",
      "Parent_guardian_name": item.Parent_guardian_name || "-",
      "Parental_coverage": item.Parental_coverage == "1" ? "Yes" : "No",
      "Student_num": item.Student_num || "-",
    };
    return contentMap[columnName] || null;
  }
  function handleSearchChange(e) {
    setCurrentPage(1);
    let searchKey = e.target.value;
    let tabFlag = TicketParams.ticketTabFlag;
    GetGridData(tabFlag, searchKey, null, null);
    let searchStringKey = tabFlag == 1 ? 'SearchForOpen' :
      tabFlag == 2 ? 'SearchForClose' :
        'SearchForPending';

    setSearchString({ ...searchString, [searchStringKey]: searchKey });
  }
  function handleTabClick(tabType) {
    if (tabType == "open") {
      setTicketTabs({ ...TicketTabs, OpenTicket: "active", AllTicket: "", CloseTicket: "", PendingTicket: "", });
      setTicketParams({ ...TicketParams, ticketTabFlag: 1 });
      GetGridData(1, null, null, null);
      setisModal({ ...isModal, IsDropdownStatusVisible: false });
      setSelectedIds([]);
      setSearchString({ ...searchString, SearchForOpen: "", SearchForClose: "", SearchForPending: "", });
      setCurrentPage(1);
      setSKey('')
    } else if (tabType == "close") {
      setTicketTabs({ ...TicketTabs, OpenTicket: "", AllTicket: "", CloseTicket: "active", PendingTicket: "", });
      setTicketParams({ ...TicketParams, ticketTabFlag: 2 });
      GetGridData(2, null, null, null);
      setisModal({ ...isModal, IsDropdownStatusVisible: false });
      setSelectedIds([]);
      setSearchString({ ...searchString, SearchForOpen: "", SearchForClose: "", SearchForPending: "", });
      setCurrentPage(1);
      setSKey('')
    } else if (tabType == "repaired") {
      setTicketTabs({ ...TicketTabs, OpenTicket: "", AllTicket: "", CloseTicket: "", PendingTicket: "active", });
      setTicketParams({ ...TicketParams, ticketTabFlag: 3 });
      GetGridData(3, null, null, null);
      setisModal({ ...isModal, IsDropdownStatusVisible: false });
      setSelectedIds([]);
      setSearchString({ ...searchString, SearchForOpen: "", SearchForClose: "", SearchForPending: "", });
      setCurrentPage(1);
      setSKey('')
    } else if (tabType == "all") {
      setTicketTabs({ ...TicketTabs, OpenTicket: "", AllTicket: "active", CloseTicket: "", PendingTicket: "", });
      setTicketParams({ ...TicketParams, ticketTabFlag: 4 });
      setisModal({ ...isModal, IsDropdownStatusVisible: false });
      setSelectedIds([]);
      setSKey('')
    }
  }
  return (
    <Fragment>
      <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
        setCurrentPage={setCurrentPage} heading={"Manage Tickets"} handleSearch={handleSearchChange}
        loading={loading} Alerts={alert} showsearch={TicketParams.ticketTabFlag != 4 ? true : false} />
      <div className='row my-2 d-flex justify-content-between pe-0 align-items-center'>
        <div className='col-md-12 ms-2 ps-0 text-center d-md-flex justify-content-end align-items-end'>
          {TicketTabs.CloseTicket == "active" && GridData.length != 0 ?
            <label className="BorderBtn text-center" onClick={print}>
              Export Closed Tickets History
              <img src="/images/ExportInventory.svg" className="img-fluid ps-2" />
            </label>
            :
            <></>
          }
          {TicketTabs.AllTicket != "active" && GridData.length != 0 ? (
            <div className="col-md-3 mx-2 text-center row pe-0" title="Export CSV For Tickets">
              <div className="BlackFont BorderBtn" onClick={(e) => ExportTicketsData(TicketTabs.OpenTicket == 'active' ? 1 : TicketTabs.CloseTicket == 'active' ? 2 : 3)}>
                {TicketTabs.OpenTicket == 'active' ?
                  "Export Open Tickets CSV" :
                  TicketTabs.CloseTicket == 'active' ? "Export Closed Tickets CSV " :
                    "Export Repaired Tickets CSV"
                }
                <img src="/images/ExportInventory.svg" className="img-fluid ps-2" />
              </div>
            </div>
          ) : (
            <div className="col-md-3 text-center row pe-0"></div>
          )}
          <label className="BorderBtn mx-2 text-center" onClick={() => navigate("/create-ticket", { replace: true, state: { CreateTicketNext: 1 } })}>
            Create Ticket
            <img src="/images/AddInventory.svg" className="img-fluid ps-2" />
          </label>
        </div>
      </div>
      <div className="GridBox mt-2 p-3">
        <div className="row align-items-center">
          <div className="col-md-9 px-0">
            <ul className="nav nav-tabs px-3">
              <Tab isActive={TicketTabs.OpenTicket == 'active'}
                label="Open Tickets" onClick={() => handleTabClick('open')} col={'col-md-3'} />
              <Tab isActive={TicketTabs.CloseTicket == 'active'}
                label="Closed Tickets" onClick={() => handleTabClick('close')} col={'col-md-3'} />
              <Tab isActive={TicketTabs.PendingTicket == 'active'}
                label="Repaired Tickets" onClick={() => handleTabClick('repaired')} col={'col-md-3'} />
              <Tab isActive={TicketTabs.AllTicket == 'active'}
                label="All Tickets" onClick={() => handleTabClick('all')} col={'col-md-2'} />
            </ul>
          </div>
          <div className="col-md-3">
            {isModal.IsDropdownStatusVisible == true && (
              <TicketStatusDropdown selectedIds={selectedIds} GetGridData={GetGridData} GridFlag={TicketParams.ticketTabFlag}
                ClosePopup={(e) => setisModal({ ...isModal, IsDropdownStatusVisible: false })} setSelectedIds={setSelectedIds} lonerFlagCount={lonerFlagCount} />
            )}
          </div>
        </div>
        {TicketParams.ticketTabFlag != 4 && (
          <div className="row mt-2 px-3">
            <div className="greyBox">
              <div className="Header row align-items-center">
                <div className="col-12 data-table-container">
                  <table className="table data-table mb-0">
                    <thead className="GridHeader">
                      <tr>
                        <th scope="col">
                          {GridData.length !== 0 && GridData.some(item => item.StatusID !== 11 && item.StatusID !== 12) ?
                            <input className="form-check-input cursor-pointer mt-1" type="checkbox" name="allSelect"
                              checked={isAllSelected} onChange={handleHeaderCheckboxChange} /> : <></>}
                        </th>
                        {columnNames?.map((item) => {
                          const columnName = item.replace(/_/g, ' ');
                          return columnName != 'Parent phone number' && columnName != 'Parental coverage' && columnName != 'parts total' ?
                            <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                              {CamelCaseWithSpaces(columnName)}
                              <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                            </th>
                            :
                            <th key={item} scope="col" className='fw-600'>
                              {CamelCaseWithSpaces(columnName)}
                            </th>
                        })}
                        <th scope="col" className="fw-600">Status</th>
                        <th scope="col">Action
                          <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {GridData.length != 0 ? (
                        GridData.map((item, i) => {
                          const isNoteVisible = noteVisibility[item.id];
                          var returData = (
                            <tr key={i} className={`OddEvenClass tableBorderHide ${item.TicketFlag}`} >
                              <td className="py-12">
                                {item.StatusID != 11 && item.StatusID != 12 && (
                                  <input type="checkbox" className="form-check-input cursor-pointer" name={item.serialNum} checked={selectedIds.includes(item.id)} onChange={(e) => handleCheckboxChange(e, item.id, item)} />
                                )}
                              </td>
                              {columnNames?.map(header => (
                                <td className="py-12" key={header}>
                                  {renderContentForColumn(header, item)}
                                </td>
                              ))}
                              <td className="py-12">
                                {item.StatusID == 11 || item.StatusID == 12 ?
                                  <span>{item.Status}</span> :
                                  <span
                                    className="cursor-pointer"
                                    title="Change Ticket Status"
                                    ticketid={item.id}
                                    onClick={(e) => {
                                      setisModal({ ...isModal, isTicketStatuspopup: true, TicketStatusId: item.StatusID });
                                      setTicketParams({ ...TicketParams, lonerFlag: item.LonerFlag, ticketId: item.id });
                                    }}
                                  >
                                    <u>{item.Status}</u>
                                  </span>
                                }
                              </td>
                              <td className="py-12">
                                <img src="/images/EditIconNew.svg" title="Edit Ticket" className="cursor-pointer img-fluid pe-2" ticketid={item.id} onClick={(e) => navigate("/create-ticket/?deviceid=" + item.inventory_id + "&ticketid=" + item.id)} />
                                <img src="/images/DownRoundArrow.svg" title="Show More Details" className={`cursor-pointer img-fluid ${isNoteVisible ? "d-none" : ""}`} ticketid={item.id} onClick={() => handleArrowClick(item.id)} />
                                <img src="/images/UpRoundArrow.svg" title="Hide More Details" className={`img-fluid cursor-pointer ${isNoteVisible ? "" : "d-none"}`} ticketid={item.id} onClick={() => handleArrowClick(item.id)} />
                                <img src="/images/repairTech.svg" className="px-2 cursor-pointer" title="Add Repair Parts" ticketid={item.id} onClick={(e) => { RepairdetailsScreen(item.id, item.StudentHaveInsurancePlan) }} />
                              </td>
                            </tr>
                          );
                          if (isNoteVisible) {
                            returData = (
                              <>
                                {returData}
                                <tr className={`col-12  px-5`}>
                                  <td colSpan={18}>
                                    <b className="font-13 pe-3"> Notes:</b>
                                    <div style={{ display: "inline" }}> {item.notes || '-'} </div>
                                  </td>
                                </tr>
                                <tr className={`col-12  px-5`}>
                                  <td colSpan={18}>
                                    <b className="font-13 pe-3">Device Issues:</b>
                                    <div style={{ display: "inline" }}> {item.issues.map((item) => item).join(", ")} </div>
                                  </td>
                                </tr>
                                <tr className={`col-12  px-5`}>
                                  <td colSpan={18}>
                                    <b className="font-13 pe-3">Building:</b>
                                    <div style={{ display: "inline" }}> {item.BuildingName || '-'}</div>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                          return returData;
                        })
                      ) : (
                        <tr><td colSpan={18} className="p-3 text-center"> No Record Found </td></tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {showPagination && (
                  <Pagination
                    activePage={currentPage}
                    totalItemsCount={pageRangeCount}
                    pageRangeDisplayed={15}
                    itemsCountPerPage={LimitForPagination}
                    onChange={(e) => setCurrentPage(e)}
                    color="primary"
                    lastPageText=""
                    firstPageText=""
                    itemClassFirst="MasterClass"
                    itemClassPrev="MasterClass"
                    itemClassNext="MasterClass"
                    itemClassLast="MasterClass"
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {TicketParams.ticketTabFlag == 4 && <AllTickets />}
      </div>
      {isModal.isshowpopup == true && (
        <DeviceDetailsPopup Showbuttons={1} ticketid={TicketParams.ticketId} createticketbutton={true} deviceid={TicketParams.inventoryId} activetab="device" isShow={isModal.isshowpopup} ClosePopup={(e) => setisModal({ ...isModal, isshowpopup: false })} />
      )}
      {isModal.isTicketDetailspopup == true && (
        <TicketDetailsPopup isShow={isModal.isTicketDetailspopup} ticketid={TicketParams.ticketId} inventoryid={TicketParams.inventoryId} ClosePopup={(e) => setisModal({ ...isModal, isTicketDetailspopup: false })} />
      )}
      {isModal.isTicketStatuspopup == true && (
        <TicketStatuspopup isShow={isModal.isTicketStatuspopup} ClosePopup={(e) => setisModal({ ...isModal, isTicketStatuspopup: false })}
          Lonerflag={TicketParams.lonerFlag}
          TicketId={TicketParams.ticketId}
          GetGridData={GetGridData}
          GridFlag={TicketParams.ticketTabFlag}
          clearSearch={(e) => setSearchString({ ...searchString, SearchForOpen: "", SearchForClose: "", SearchForPending: "", })}
          TicketStatusId={isModal.TicketStatusId}
          setSelectedIds={setSelectedIds}
        />
      )}
      {CustomizationModal == true && (
        <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={"Manage Ticket"} GetCall={() => ApiGetHeaders(41, setColumnNames)} />
      )}
      {UserModal == true && (
        <UserDetailsPopup isShow={UserModal} ClosePopup={(e) => setUserModal(false)} userid={Userid} />
      )}
    </Fragment>
  );
}
