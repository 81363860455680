import React, { useState } from 'react';

const SectionComponent = ({ title, data, scannedYesNoType, handleRadioChange, scanyesno, radiotext, showyesno, index }) => {
    const renderRow = (item) => (
        <div className="row tableBorderHide NewOddEvenClass" key={item.serial_number}>
            <div className="col-md-2 py-1 px-0 cursor-pointer wordwrap">
                {item.serial_number}
            </div>
            <div className="col-md-2 py-1 px-0 cursor-pointer wordwrap">
                {item.asset_tag || '-'}
            </div>
            <div className="col-md-2 py-1 px-0 cursor-pointer wordwrap">
                {item.json_building_name || '-'}
            </div>
            <div className="col-md-2 py-1 px-0 wordwrap">
                {item.system_building_name || '-'}
            </div>
            <div className="col-md-2 py-1 px-0 wordwrap">
                {item.json_room_name || '-'}
            </div>
            <div className="col-md-2 py-1 px-0 wordwrap">
                {item.system_room_name || '-'}
            </div>
        </div>
    );

    const renderGrid = () => (
        <div className="px-4 py-3 mt-2">
            <div className="w-100 px-2">
                {data?.length > 0 && (
                    <div className="row pb-2 brdr-Btm">
                        <div className="col-2 fw-600 px-0">Serial #</div>
                        <div className="col-2 fw-600 px-0">Asset Tag</div>
                        <div className="col-2 fw-600 px-0">Scanned Buildings</div>
                        <div className="col-2 fw-600 px-0">System Buildings</div>
                        <div className="col-2 fw-600 px-0">Scanned Rooms</div>
                        <div className="col-2 fw-600 px-0">System Rooms</div>
                    </div>
                )}
                {data?.length ? data.map((item) => renderRow(item)) : (
                    <div className='col-12 '>No Record Found</div>
                )}
            </div>
        </div>
    );

    return (
        <div className='col-12 mb-3 px-2'>
            <div className="col-12 GridBox px-2">
                <div className="row my-2 px-2 time" >
                    <div className='Header col-12 '>
                        <label className='font-15 fw-600 pt-2'>{title}</label>
                    </div>
                    {showyesno && (
                        data?.length > 0 && (
                            <div className='col-12 '>
                                <div className='row pt-1 font-13'>
                                    <div className='col-md-10'>
                                        <label>{radiotext}</label>
                                    </div>
                                    <div className='col-md-2 d-flex justify-content-end'>
                                        <div className="form-check ms-3">
                                            <input className="form-check-input cursor-pointer" type="radio"
                                                name={`scanned${scannedYesNoType}${index}`}
                                                value={1}
                                                defaultChecked={scanyesno == 1}
                                                onChange={() => handleRadioChange(index, scannedYesNoType, 1)} />
                                            <label className="form-check-label"> Yes</label>
                                        </div>
                                        <div className="form-check ms-5">
                                            <input className="form-check-input cursor-pointer" type="radio"
                                                name={`scanned${scannedYesNoType}${index}`}
                                                value={0}
                                                defaultChecked={scanyesno == 0}
                                                onChange={() => handleRadioChange(index, scannedYesNoType, 0)} />
                                            <label className="form-check-label"> No </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}

                </div>
                {renderGrid()}
            </div>
        </div>
    );
}

export default SectionComponent;
