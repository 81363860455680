import React from 'react'
import Chart from "react-apexcharts";

export default function TechniciansPerformance({ TechnicianPerformance }) {
    var series;
    var options;
    if (TechnicianPerformance?.data?.series != undefined) {
        var series = TechnicianPerformance?.data?.series
        options = {

            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false,
                },
            },
            colors: ["#3CBBA5", "#FFD700", "#FF6347", "#00FFD3", "#ffa5a5", "#151821", "#C3C3C3"],
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },

            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: TechnicianPerformance?.data?.categories,
                title: {
                    text: 'Tickets'
                },

            },
            yaxis: {

                title: {
                    text: 'Technicians'
                },
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                offsetX: 40
            }
        };
    }

    return (
        <div className="col-12  p-3 mb-3 h-100">
            <div className="greyBoxShadow w-100">
                <div className="row my-2 px-2 time text-center">
                    <div className='Header'>
                        <h5 className="fw-bold ps-2 mb-0">Top 10 Technicians Performance</h5>
                    </div>
                </div>
                <div className="pb-0">
                    <>
                        {TechnicianPerformance?.loader ?
                            <><div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div></>
                            : TechnicianPerformance?.data?.series?.length == 0 ? <>
                                <div className="fw-bold py-3 text-center">No technician ticket found.</div>
                            </> : TechnicianPerformance?.data?.series != undefined &&
                            <Chart
                                options={options}
                                series={series}
                                type={"bar"}
                                height={400}
                            />}

                    </>
                </div>
            </div>
        </div>
    )
}
