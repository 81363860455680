import { useState, useContext, useEffect } from "react"
import { Camelcase, HideLoder, MMDDYYYY, ShowLoder, typewatch } from "../../JS/Common";
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import AlertsComp from "../../Components/AlertsComp";
import { CheckValidation } from "../../Components/Validations";
import { UserContext } from "../../App";
import { Logout } from "../Signout";
import { MDBSwitch } from "mdb-react-ui-kit";
import Pagination from "react-js-pagination";
import CustomizationPopup from "../../Components/CustomizationPopup";
import PageHead from "../../Components/PageHead";
import Popup from "../../Components/Popup";
import AlertPopup from "../../Components/AlertPopup";

export function DomainIndex() {
    const { UserData } = useContext(UserContext);
    const [isDomainPopup, setisDomainPopup] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [DomainData, setDomainData] = useState([]);
    const [allData, setAllData] = useState({
        DomainName: "",
        DomainId: 0,
        UpdateFlag: 1,
        flag: 3,
        SortbyFlag: ''
    });
    const [loading, setLoading] = useState(false);
    const [Searchstring, setSearchstring] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const showPagination = DomainData.length > 0;
    const [DeactiveToActivePopup, setDeactiveToActivePopup] = useState(false);
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    useEffect(() => {
        ApiGetHeaders(34, setColumnNames)
        GetAllDomains(Searchstring ? Searchstring : null, 'desc');
    }, [currentPage, LimitForPagination]);
    async function GetAllDomains(searchstring, Sflag) {
        if (searchstring == "" || searchstring == null) {
            searchstring = null;
        }
        if (Sflag == 'asc') {
            Sflag = 'desc'
        } else {
            Sflag = 'asc'
        }
        setAllData({ ...allData, SortbyFlag: Sflag })
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/AllDomain/" + searchstring + '&' + Sflag + '&' + currentPage + '&' + LimitForPagination).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    setpageRangeCount(responseRs.total)
                    if (responseRs.length != 0) {
                        setDomainData(responseRs.data);
                    }
                    else {
                        setDomainData([]);
                    }
                }
            });
        }, 500);
    }
    async function AddUpdateDomain() {
        var isFormValid = CheckValidation({ formID: 'DomainPopup' });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            Id: allData.DomainId == '' ? 0 : allData.DomainId,
            name: allData.DomainName
        });
        await ApiPostCall("/AddUpdateDomain", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.response == "success") {
                    if (allData.UpdateFlag == 1) {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Domain Added Successfully."} />);
                    } else {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Domain Updated Successfully."} />);
                    }
                    setisDomainPopup(false);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        GetAllDomains(null, '');
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
            }
        });
    }
    async function ActiveDeactive(id, name, flag) {
        setLoading(true);
        var loginemailid = UserData.emailid;
        var vsplit = loginemailid.split('@');
        var domainname = vsplit[1];
        var raw = JSON.stringify({
            Id: id,
            name: name,
            flag: flag
        });
        await ApiPostCall("/AddUpdateDomain", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (flag == 1) {
                    setDeactiveToActivePopup(false)
                }
                if (responseRs.response == "success") {
                    if (flag == 1) {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Domain Activated Successfully."} />);
                    } else {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Domain Deactivated Successfully."} />);
                    }
                    GetAllDomains(null, '');
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                    if (domainname == name) {
                        Logout();
                    }
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
            }
        });
    }
    async function EditDomain(id) {
        await ApiGetCall("/DomainDataByID/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setAllData({ ...allData, UpdateFlag: 2, DomainName: responseRs.name, DomainId: responseRs.id });
                setisDomainPopup(true);
            }
        });
    }
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "Name": item.name || '-',
            "created_at": MMDDYYYY(item.created_at) || '-'
        };
        return contentMap[columnName] || null;
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        GetAllDomains(e.target.value, allData.SortbyFlag);
        setSearchstring(e.target.value)
    }
    const modalBody = (
        <>
            {allData.UpdateFlag == 1 ?
                <div className="col-12 FormLabel">
                    You can approve School Domain names here in order to let School Members use their domain emails associated with Google or Microsoft to sign-up/login. Use comma(,) to separate multiple domain names and add them all in one click.
                    Eg. outlook.com, gmail.com
                </div> :
                <div className="col-12 FormLabel">
                    You can approve School Domain names here in order to let School Members use their domain emails associated with Google or Microsoft to sign-up/login.
                </div>
            }
            <div id="DomainPopup" className="parent">
                <div className='col-12 pt-3'>
                    {allData.UpdateFlag == 1 ?
                        <>
                            <textarea className="form-control" required value={allData.DomainName}
                                onChange={(e) => setAllData({ ...allData, DomainName: e.target.value })} />
                            <div className='FormLabel'>Domain Name(s)<label className='redText'>*</label></div>
                        </>
                        :
                        <>
                            <input className="form-control" required value={allData.DomainName} onChange={(e) => setAllData({ ...allData, DomainName: e.target.value })} />
                            <div className='FormLabel'>Domain Name</div>
                        </>
                    }


                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
            </div>
        </>
    )
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Domains"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />

            <div className="GridBox mt-2 px-3 py-2">
                <div className='row text-end'>
                    <div className="col-md-9"></div>
                    <div className="col-md-3 pb-2">
                        <label className='BorderBtn text-center marginDetailsPopup' onClick={(e) => { setisDomainPopup(true); setAllData({ ...allData, UpdateFlag: 1, flag: 1, DomainId: "", DomainName: "" }) }}> Add Domain
                            <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                </div>
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                {columnNames?.map((item) => {
                                    const columnName = item.replace(/_/g, ' ');
                                    return (
                                        item != "created_at" ?
                                            <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => GetAllDomains(Searchstring, allData.SortbyFlag)}>
                                                {Camelcase(columnName)}
                                                <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                            </th>
                                            :
                                            <th key={item} scope="col" className='fw-600'>
                                                {Camelcase(columnName)}
                                            </th>
                                    );
                                })}
                                <th scope="col" className='cursor-pointer fw-600 text-center'>Active</th>
                                <th scope="col" className='cursor-pointer fw-600 text-center'>Action <img src="/images/EditHeaderIcon.svg" className="img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {DomainData.length != 0 ?
                                DomainData.map((item, i) => {
                                    return <tr key={i} className="OddEvenClass tableBorderHide">
                                        {columnNames?.map(header => (
                                            <td className="py-12" key={header}>
                                                {renderContentForColumn(header, item)}
                                            </td>
                                        ))}
                                        <td scope="col" className='text-center py-12'>
                                            {item.status == 1 ?
                                                <MDBSwitch className="BgPink cursor-pointer" checked={item?.isChecked || true} onChange={(e) => { ActiveDeactive(item.id, item.name, 2) }} domainid={item.id} />
                                                :
                                                <MDBSwitch className="BgPink cursor-pointer" checked={item?.isChecked || false} onChange={(e) => { setAllData({ ...allData, DomainName: item.name, DomainId: item.id }); setDeactiveToActivePopup(true) }} domainid={item.id} />
                                            }
                                        </td>
                                        <td scope="col" className='text-center py-12'>
                                            <img src='/images/EditIconNew.svg' title='Edit Domain' className='img-fluid pe-2 cursor-pointer' onClick={(e) => EditDomain(item.id)} domainid={item.id} />
                                        </td>
                                    </tr>
                                })
                                :
                                <tr className="text-center">
                                    <td colSpan={4}>No Record Found</td>
                                </tr>
                            }
                        </tbody>

                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div>

            <Popup isshow={isDomainPopup} size={"md"} title={allData.UpdateFlag == 1 ? 'Add Domain(s)' : 'Edit Domain'}
                closePopup={(e) => setisDomainPopup(false)}
                modalBody={modalBody} handleSave={AddUpdateDomain} visibleSaveBtn={true} btnText={allData.UpdateFlag == 1 ? 'Save' : 'Update'} />

            <AlertPopup isshow={DeactiveToActivePopup} img={'/images/greenInfo.svg'}
                headingtext={`Are you sure you want to activate this domain? Any schools linked to this domain registered on the same day will also be activated.`} text={''}
                cancelcall={(e) => { setDeactiveToActivePopup(false) }}
                canceltext={"No"} successtext={"Continue"} successcall={(e) => { ActiveDeactive(allData.DomainId, allData.DomainName, 1) }} btntype={'SaveBtn'} />

            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Domains'} GetCall={(e) => ApiGetHeaders(34, setColumnNames)} />
            )}
        </>
    )
}
