import React, { useEffect, useState } from 'react'
import { ApiGetCallAuthKey, ApiPostCall } from '../../JS/Connector';
import { LogInSchoolID } from '../../JS/Common';
import Deletepopup from '../../Components/Deletepopup';
import AlertsComp from '../../Components/AlertsComp';
import { useNavigate } from 'react-router-dom';

export default function ArchiveUsersDetails({ setLoading, setNewAlerts }) {
    const navigate = useNavigate();
    const [GradeArray, setGradeArray] = useState([]);
    const [grade, setgrade] = useState(0);
    const [ErrorMsg, setErrorMsg] = useState('');
    const [DecommissionPopup, setDecommissionPopup] = useState(false);
    useEffect(() => {
        ApiGetCallAuthKey("/allGradeandBuilding/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setGradeArray(responseRs.grade);
                }
            }
        });
    }, [])
    function gradeValueChange() {
        if (parseInt(grade) == 0) {
            setErrorMsg('select at least one grade')
        } else {
            setDecommissionPopup(true)
        }
    }
    async function DecommissionUsersCall() {
        setLoading(true);
        await ApiPostCall("/deallocateUsers/" + LogInSchoolID + '&' + grade).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"User Deallocated Successfully"} />);
                    setTimeout(() => {
                        setDecommissionPopup(false)
                        navigate('/users')
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    return (
        <>
            <div className="mt-2 greyBox h-100 p-3 align-items-center">
                <div className='row d-flex align-items-center p-1'>
                    <span className='GridTitle pb-2'> Archive Users</span>
                </div>
                <hr className='my-0' />
                <div className='p-1 pt-3 text-justify'>
                    This functionality empowers you to streamline your panel by effortlessly managing the visibility of students based on their respective grades.
                    Here's how it works: simply select a grade from the dropdown menu, and with a single click of the "Archive Users" button,
                    all students belonging to the chosen grade will be seamlessly removed from your panel.
                    This means that those users will no longer be visible or accessible within your system,
                    allowing you to focus on the specific groups you wish to engage with.
                </div>
                <div className="row d-flex pt-3 pb-2">
                    <div className="col-5 py-1 ">
                        <select defaultValue={grade} onChange={(e) => { setgrade(e.target.value) }}>
                            <option value="0">Select Grade</option>
                            {GradeArray.map((item, j) => (
                                <option value={item.Grade} key={j}>
                                    {item.Grade}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-7 ">
                        {grade == 0 ?
                            <label className='BorderBtn' onClick={(e) => { gradeValueChange(e) }}>Archive Users </label>
                            :
                            <label className='BorderBtn' onClick={(e) => { gradeValueChange(e) }} style={{ backgroundColor: '#1ebfa2', color: 'white' }}>Archive Users </label>
                        }
                    </div>

                </div>
                <div className='ps-4' style={{ color: 'red' }}>{ErrorMsg}</div>
            </div>
            <Deletepopup text={`This will archive all users/students from the ${grade} option. Those users/students will not be part of your panel anymore and will not be visible.`} deletePopup={DecommissionPopup} size="xl"
                deletePart={DecommissionUsersCall} DeleteShowClose={(e) => setDecommissionPopup(false)} />
        </>
    )
}
