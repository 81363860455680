import { Alert } from "react-bootstrap";

export default function AlertsComp({ variant, show, msg }) {
    return (
        <>
            {["success", "danger"].includes(variant) && (
                <Alert
                    show={show}
                    variant={variant}
                    className={`w-auto mt-3 ml-3 reactalert-${variant}`}>
                    {msg}
                </Alert>
            )}
        </>
    );
}
