import React, { useState } from 'react'
import Chart from "react-apexcharts";

export default function Charts({ data }) {


    const categories = data?.map((item) => item?.name);
    const series = data?.map((item) => item?.ticket_count);
    const series1 = data?.map((item) => item?.time);

    const state = {

        series1: [
            {
                name: "Tickets closed",
                data: series
            },
            {
                name: "Days",
                data: series1
            }
        ],
        options1: {
            chart: {
                height: 250,
                type: 'line',
                toolbar: {
                    show: false
                }
            },
            colors: ['#3CBBA5', '#000000'],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: 'smooth'
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: categories,

            },
            yaxis: {
                title: {
                    text: 'Work Efficiency'
                },
                min: 0,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                floating: true,
                offsetY: -25,
                offsetX: -5
            }
        },


    }
    return (

        <div style={{ width: data?.length > 20 ? "1500px" : "100%" }}>
            <Chart
                options={state?.options1}
                series={state?.series1}
                type={"line"}
                height={250}
            />
        </div>
    )
}
