import React, { useEffect, useState } from 'react'
import { ApiGetCall } from '../JS/Connector';
import Popup from './Popup';
import { formatDateToMMDDYYYY } from '../JS/Common';

export default function ReworkPopup(props) {
    const [Details, setDetails] = useState([]);
    const [Status, setStatus] = useState('');
    useEffect(() => {
        ApiGetCall("/reworkTicketDetails/" + props.ticketid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setDetails(responseRs.msg);
                setStatus(responseRs.response)

            }
        });
    }, []);
    const modalbody = (
        <>
            {Status == "success" ?
                <>
                    <h6 className='text-decoration-underline'>Device Details</h6>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='content-forsetupcards'>Serial # : </label> <b>{Details?.inventory_management?.serial_number}</b>
                        </div>
                        <div className='col-md-6'>
                            <label className='content-forsetupcards'>Asset Tag : </label> <b>{Details?.inventory_management?.asset_tag || '-'}</b>
                        </div>
                        {Details?.inventory_management?.student_inventory &&
                            <div className='col-md-6'>
                                <label className='content-forsetupcards'>User # : </label> <b>{Details?.inventory_management?.student_inventory?.student?.student_num || '-'}</b>
                            </div>
                        }
                        {Details?.inventory_management?.student_inventory &&
                            <div className='col-md-6'>
                                <label className='content-forsetupcards'> User Name : </label> <b>{Details?.inventory_management?.student_inventory?.student?.device_user_first_name} {Details?.inventory_management?.student_inventory?.student?.device_user_last_name}</b>
                            </div>
                        }
                    </div>
                    <h6 className='text-decoration-underline pt-3'>Previous Ticket Details</h6>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='content-forsetupcards'>Ticket # : </label> <b>{Details?.ticket_num}</b>
                        </div>
                        <div className='col-md-6'>
                            <label className='content-forsetupcards'> Status : </label> <b>{Details?.statusname?.status}</b>
                        </div>
                        <div className='col-12'>
                            <label className='content-forsetupcards'> Created at  : </label> <b>{formatDateToMMDDYYYY(Details?.created_at)}</b>
                        </div>
                        <div className='col-12'>
                            <label className='content-forsetupcards'>Ticket Issues :  </label> <b>{Details?.ticket_issues?.map((item) => item.device_issue?.issue).join(", ")}</b>
                        </div>
                        <div className='col-12'>
                            <label className='content-forsetupcards'>Notes : </label> <b>{Details?.notes || '-'}</b>
                        </div>
                    </div>
                    <h6 className='text-decoration-underline pt-3'>Attached Parts Details</h6>
                    <div className='row'>
                        {Details?.ticket_attachments?.length > 0 ?
                            Details?.ticket_attachments.map((item, i) => {
                                return <>
                                    <div><label className='content-forsetupcards'>Name : </label> <b>{item.part?.title}</b></div>
                                    <div><label className='content-forsetupcards'>Price : </label> <b>${item?.part_price}</b></div>
                                    <div><label className='content-forsetupcards'>Created By : </label> <b>{item?.user?.first_name} {item?.user?.last_name}</b></div>
                                    <hr />
                                </>
                            })
                            :
                            <>No Parts Attached</>
                        }
                    </div>
                </> :
                <div className='col-12 text-center'>
                    No Record Found
                </div>
            }

        </>
    )
    return (
        <Popup isshow={props.isShow} size={"md"} title={"Previous Ticket Details"}
            closePopup={props.ClosePopup} modalBody={modalbody} visibleSaveBtn={false} />
    )
}
