import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../App';
import { LogInSchoolID } from '../../JS/Common';
import { ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';

export default function SendOutForRepairDetails({ setLoading, setNewAlerts }) {
    const { UserData, setUserData } = useContext(UserContext);
    const [SendOutRepairFlag, setSendOutRepairFlag] = useState(UserData.sendOutForRepairFlag)
    useEffect(() => {
        setSendOutRepairFlag(UserData.sendOutForRepairFlag)
    }, [UserData.sendOutForRepairFlag])
    async function SendOutForRepairCall() {
        setLoading(true);
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            Flag: SendOutRepairFlag
        })
        await ApiPostCall("/SetDefaultTicketStatus", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Send Out for Repair Setting Updated Successfully"} />);
                    setTimeout(() => {
                        setUserData((prevUserData) => ({ ...prevUserData, sendOutForRepairFlag: SendOutRepairFlag }));
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    return (
        <div className="mt-2 greyBox h-100 p-3 align-items-center" >
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle pb-2'>Send Out for Repair</span>
            </div>
            <hr className='my-0' />
            <div className="p-1 pe-0 mt-1 pt-3">
                <label>Would you like Set 'Send Out for Repair' as the default status for new tickets? </label>
                <div className='col-md-12 mt-3 d-flex align-items-center'>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name='SendOutTicket'
                            onChange={(e) => setSendOutRepairFlag(1)}
                            defaultChecked={SendOutRepairFlag == 1}
                        />
                        <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check ms-5">
                        <input
                            className="form-check-input"
                            type="radio"
                            name='SendOutTicket'
                            onChange={(e) => setSendOutRepairFlag(0)}
                            defaultChecked={SendOutRepairFlag != 1}
                        />
                        <label className="form-check-label">No</label>
                    </div>
                    <div className='px-5'>
                        <button className="SaveBtn" onClick={SendOutForRepairCall}> Confirm   </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
