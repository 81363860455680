import { useState } from "react";
import GoogleOauth from "../../Components/GoogleOauth";
export function GoogleLoginForSupportTicket(props) {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const schoolnumber = pathArray[2];
    const [AlertColor, setAlertColor] = useState('')
    const [alertsMsg, setAlertsMsg] = useState('')
    return (
        <>
            <div className="card mx-auto">
                <div className="p-4 row">
                    <div className="col-12 text-center">
                        <div style={{ color: `${AlertColor}` }} className="font-16" >{alertsMsg}</div>
                    </div>
                    <div className="my-4">
                        <img src="/images/LoginLogo.png" className="img-fluid" alt="Logo" />
                    </div>
                    <div className="fw-600">
                        {props.flag == "supportticket" ?
                            <label>To create a support ticket, please sign in with google first.</label>
                            :
                            <label>To create helpdesk, please sign in with google first.</label>
                        }
                    </div>
                    <div className="col-md-8 offset-md-2 text-center mt-4">
                        <GoogleOauth pageflag={props.flag} flag="supportticket" schoolnumber={schoolnumber} alertmsg={setAlertsMsg} alertcolor={setAlertColor} setLoginFlag={props.setLoginFlag} />
                    </div>
                </div>
            </div>



        </>
    )
}
