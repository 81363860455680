import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ApiGetCall, ApiGetCallAuthKey, ApiPostCall } from '../../JS/Connector';
import { LogInSchoolID, getAllBuildings, getAllUsers, typewatch } from '../../JS/Common';
import { useNavigate } from 'react-router-dom';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertsComp from '../../Components/AlertsComp';
import Pagination from 'react-js-pagination';
import PageHead from '../../Components/PageHead';
import Popup from '../../Components/Popup';
export default function HdticketAssignments() {
    const navigate = useNavigate();
    const [SearchString, setSearchString] = useState('');
    const [flag, setFlag] = useState(0);
    const [Ispopup, setIspopup] = useState(false);
    const [GridData, setGridData] = useState([]);
    const [BuildingData, setBuildingData] = useState([]);
    const [HelpdeskType, setHelpdeskType] = useState([]);
    const [HelpdeskCategories, setHelpdeskCategories] = useState([]);
    const [AllUsers, setAllUsers] = useState([]);
    const [AllGroups, setAllGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [assignedflag, setassignedflag] = useState(0);
    const [ErrorMsg, setErrorMsg] = useState('');
    const [Values, setValues] = useState({
        buildingId: '0',
        CategoryId: '0',
        HDtypeid: '0'
    });
    const [selectedUserId, setSelectedUserId] = useState(0)
    const [selectedGroupId, setSelectedGroupId] = useState(0)
    const [TicketAssignmentId, setTicketAssignmentId] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const showPagination = GridData.length > 0;
    const [GroupInfo, setGroupInfo] = useState([]);
    const [showPopover, setShowPopover] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    useEffect(() => {
        GetGridData(SearchString ? SearchString : null);
    }, [currentPage, LimitForPagination]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showPopover && !event.target.closest('.popover')) {
                setShowPopover(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showPopover]);
    async function GetGridData(searchstring) {
        if (searchstring == "" || searchstring == undefined) {
            searchstring = null;
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/GetHelpdeskAssignments/" + LogInSchoolID + '&' + searchstring + '&' + currentPage + '&' + LimitForPagination).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs?.pagination_total)
                    if (responseRs.data.length != 0) {
                        setGridData(responseRs.data);
                    } else {
                        setGridData([])
                    }
                }
            });
        }, 500);
    }
    function GetAllHdTypes(flag, hdtypeid, foreditflag) {
        ApiGetCallAuthKey("/GetDataById/" + LogInSchoolID + "&" + hdtypeid + "&" + flag).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.length != 0) {
                    if (flag == 3) {
                        setHelpdeskType(responseRs);
                        if (foreditflag == 1) {
                            GetAllHdTypes(4, hdtypeid, 0);
                            setHelpdeskCategories(responseRs)
                        }
                    } else if (flag == 4) {
                        setHelpdeskCategories(responseRs)
                    }
                }
                else {
                    if (flag == 3) {
                        setHelpdeskType([]);
                    } else if (flag == 4) {
                        setHelpdeskCategories([])
                    }
                }
            }
        });
    }
    function handleChangeForUsers(e, selectedid) {
        setSelectedUserId(selectedid);
        setSelectedGroupId(0);
    }

    function handleChangeForGroups(e, selectedid) {
        setSelectedGroupId(selectedid);
        setSelectedUserId(0);
    }
    async function handleSaveUpdate(flag) {
        var raw = JSON.stringify({
            schoolid: LogInSchoolID,
            buildingid: Values.buildingId,
            hdtypeid: Values.HDtypeid,
            hdcategoryid: Values.CategoryId,
            assignedflag: assignedflag,
            assignedid: assignedflag == 1 ? selectedGroupId : selectedUserId,
            id: flag == 1 ? 0 : TicketAssignmentId
        });
        if (Values.buildingId == '' || Values.buildingId == '0') {
            setErrorMsg('Building is required');
            return
        } else if (assignedflag == 0) {
            setErrorMsg('what are you assigned to in this building?');
            return
        } else if (assignedflag == 1 && selectedGroupId == 0) {
            setErrorMsg('Select at least one group');
            return
        } else if (assignedflag == 2 && selectedUserId == 0) {
            setErrorMsg('Select at least one user');
            return
        } else {
            setErrorMsg('');
        }
        await ApiPostCall("/Savehelpdeskassignments", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result)
                if (responseRs.response == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={`Helpdesk Ticket Assignments ${flag == 1 ? 'Added' : 'Updated'} Successfully`} />);
                    setTimeout(() => {
                        setIspopup(false)
                        setAlerts(<AlertsComp show={false} />);
                        GetGridData(SearchString ? SearchString : null)
                        setassignedflag(0); setErrorMsg(''); setHelpdeskType([]); setHelpdeskCategories([]); setValues({ ...Values, buildingId: '0', CategoryId: '0', HDtypeid: '0' }); setAllUsers([])
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }
    function handleAssignChange(flag) {
        setassignedflag(flag);
        if (flag == 1) {
            GetAllGroups();
        } else {
            getAllUsers(setAllUsers);
        }
        setSelectedUserId(0);
        setSelectedGroupId(0);
    }
    function GetAllGroups() {
        ApiGetCall("/GetGroups/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.length != 0) {
                    setAllGroups(responseRs);
                }
            }
        });
    }
    async function GetAssignmentDataById(GridID) {
        setLoading(true)
        await ApiGetCall("/GetHelpdeskAssignmentById/" + LogInSchoolID + '&' + GridID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setValues({ ...Values, buildingId: responseRs[0]?.building_id, CategoryId: responseRs[0]?.hd_category_id, HDtypeid: responseRs[0]?.hd_type_id })
                setassignedflag(responseRs[0]?.assigned_flag)
                getAllBuildings(setBuildingData, LogInSchoolID);
                GetAllHdTypes(3, responseRs[0]?.hd_type_id, 1);
                if (responseRs[0]?.assigned_flag == 1) {
                    GetAllGroups();
                    setSelectedGroupId(responseRs[0]?.assigned_id)
                } else {
                    getAllUsers(setAllUsers);
                    setSelectedUserId(responseRs[0]?.assigned_id)
                }
                setLoading(false);
            }
        });
    }
    async function GetGroupinfoById(gid) {
        await ApiGetCall("/GetGroupbyId/" + LogInSchoolID + '&' + gid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length != 0) {
                    setGroupInfo(responseRs);
                    setShowPopover(true);
                }
                else {
                    setGroupInfo([]);
                }
            }
        })
    }
    const popover = (
        <Popover id="popover-basic" style={{ maxWidth: '50%', fontSize: '14px' }} onMouseLeave={() => setShowPopover(false)}>
            <Popover.Header as="h3">Users</Popover.Header>
            <Popover.Body>

                {GroupInfo?.users?.length != null ? (
                    GroupInfo?.users?.map((item, i) => {
                        var returData = (
                            <li>{item.first_name + ' ' + item.last_name}</li>
                        )
                        return returData;
                    })
                ) :
                    <li>No Record Found</li>
                }

            </Popover.Body>
        </Popover>
    );

    const handleToggle = (nextShow, itemId) => {
        GetGroupinfoById(itemId)
        if (nextShow) {
            setSelectedItemId(itemId);
        } else {
            setSelectedItemId(null);
        }
    };
    function handleSearchChange(e) {
        setSearchString(e.target.value);
        setCurrentPage(1);
        GetGridData(e.target.value)
    }
    const modalbody = (
        <>
            <div className="px-3" >
                <div className='row pb-2 align-items-center'>
                    <div className="col-md-6">Building Name : </div>
                    <div className='col-md-6'>
                        {flag == 1 ?
                            <select className="form-select" value={Values.buildingId} onChange={(e) => {
                                setValues({ ...Values, buildingId: e.target.value });
                                GetAllHdTypes(3, null, 0);
                            }}>
                                <option value='0'>Select</option>
                                {BuildingData?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            :
                            <select className="form-select" disabled value={Values.buildingId}>
                                <option value='0'>Select</option>
                                {BuildingData?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        }

                    </div>
                </div>
                <div className={`row py-2 align-items-center ${Values.buildingId != "0" ? '' : 'd-none'} `}>
                    <div className="col-md-6">Helpdesk Type : </div>
                    <div className='col-md-6'>
                        {flag == 1 ?
                            <select className="form-select" value={Values.HDtypeid} onChange={(e) => { setValues({ ...Values, HDtypeid: e.target.value }); GetAllHdTypes(4, e.target.value, 0); }}  >
                                <option value='0'>All</option>
                                {HelpdeskType?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            :
                            <select className="form-select" disabled value={Values.HDtypeid} >
                                <option value='0'>All</option>
                                {HelpdeskType?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        }
                    </div>
                </div>
                <div className={`row py-2 align-items-center ${Values.HDtypeid != "0" ? '' : 'd-none'} `}>
                    <div className="col-md-6">Helpdesk Category : </div>
                    <div className='col-md-6'>
                        <select className="form-select" value={Values.CategoryId} onChange={(e) => { setValues({ ...Values, CategoryId: e.target.value }); }}  >
                            <option value='0'>All</option>
                            {HelpdeskCategories?.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className={`row mt - 2`}>
                    <hr className='my-2' />
                    <div className='col-12'>
                        <b className='h5'>Assigned to : </b>
                    </div>
                    <hr className='my-2' />
                    <div className='greyBox mt-2'>
                        <div className='row align-items-center'>
                            <div className='col-12 d-flex justify-content-around'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name='assigned' checked={assignedflag == 1 ? true : false} onChange={(e) => handleAssignChange(1)} />
                                    <b className="form-check-label ps-1 FormLabel">
                                        Group
                                    </b>
                                </div>
                                <div className="form-check ms-5">
                                    <input className="form-check-input" type="radio" name='assigned' checked={assignedflag == 2 ? true : false} onChange={(e) => handleAssignChange(2)} />
                                    <b className="form-check-label ps-1 FormLabel">
                                        Individual
                                    </b>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-2 '>
                            {assignedflag == 2 ?
                                <>
                                    <hr />
                                    {AllUsers.length > 0 ?
                                        AllUsers.map((item, i) => {
                                            return <div className="col-md-4 py-2 FormLabel" key={i}>
                                                <input type="radio"
                                                    className="form-check-input me-2"
                                                    onChange={(e) => handleChangeForUsers(e, item.id)}
                                                    name="users"
                                                    checked={selectedUserId === item.id} />
                                                {item.name}
                                            </div>
                                        })
                                        :
                                        <div className='col-12 text-center'>
                                            No Record Found
                                        </div>
                                    }

                                </>
                                : assignedflag == 1 ?
                                    <>
                                        <hr />
                                        {AllGroups.length > 0 ?
                                            AllGroups.map((item, i) => {
                                                return <div className="col-md-4 py-2 d-flex FormLabel" key={i}>
                                                    <input type="radio"
                                                        className="form-check-input me-2"
                                                        onChange={(e) => handleChangeForGroups(e, item.id)}
                                                        name="groups"
                                                        checked={selectedGroupId === item.id}
                                                    />
                                                    {item.name}
                                                    <div className="ps-2" key={item.id}>
                                                        <OverlayTrigger
                                                            trigger="hover"
                                                            placement="right"
                                                            show={showPopover && selectedItemId == item.id}
                                                            onToggle={(nextShow) => handleToggle(nextShow, item.id)}
                                                            overlay={popover}
                                                        >
                                                            <a href="#" role="button">
                                                                <img src="/images/InfoBtn.svg" className="cursor-pointer" height='13px' />
                                                            </a>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            })
                                            :
                                            <div className='col-12 text-center'>
                                                No Record Found
                                            </div>
                                        }
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='ps-3 mt-2' style={{ color: 'red' }}>{ErrorMsg}</div>
        </>
    )
    return (
        <div>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Helpdesk Ticket Assignments"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className='row align-items-center pe-0'>
                <div className='col-md-12 py-2 text-end'>
                    <label className='BorderBtn' onClick={(e) => { setFlag(1); setIspopup(true); getAllBuildings(setBuildingData, LogInSchoolID); }} title='Assign Staff Member'>
                        <img src='/images/AddInventory.svg' className='img-fluid px-2' />Assign Staff Member
                    </label>
                    <label className='BorderBtn ms-2' onClick={(e) => navigate('/staff-members')} title='Edit Staff Member'>
                        <img src='/images/AddInventory.svg' className='img-fluid px-2' />Edit Staff Member
                    </label>
                </div>
            </div>
            <div className="GridBox p-3" >
                <div className="col-md-12 pb-2">
                    <div className="d-flex justify-content-end align-items-center font-13">
                        <div className={`d-flex align-items-center`}>
                            <div className="setup-status-square mx-3" style={{ backgroundColor: '#eff4ff' }}></div><span>Assign to Group</span>
                        </div>
                        <div className={`d-flex align-items-center`}>
                            <div className="setup-status-square mx-3" style={{ backgroundColor: '#e7fef6' }}></div><span>Assign to Individual</span>
                        </div>
                    </div>
                </div>
                <div className='greyBox p-3'>
                    <div className='row g-4 g-lg-4 pb-3'>
                        {GridData.length !== 0 ? (
                            GridData.map((item, i) => {
                                return (
                                    <div className='col-md-4'>
                                        <div className='p-3 GridBox boxshaowstyle' style={{ maxHeight: '100%', minHeight: '100%', backgroundColor: item.assigned_flag == 2 ? "#e7fef6" : '#eff4ff' }}>
                                            <img src="/images/NewEditIconn.svg" className="cursor-pointer" title='Edit Helpdesk Ticket Assignments' style={{ float: 'right' }} height='16px' onClick={(e) => { setFlag(2); setTicketAssignmentId(item.id); GetAssignmentDataById(item.id); setIspopup(true) }} />
                                            <h4 className='mb-0 mt-1 fw-600' style={{ color: '#2cc3a9', fontSize: '24px' }}>{item.building_id}</h4>
                                            <div className='mt-3' style={{ fontSize: '14px', color: '#4C5258' }}>Type : <label className='fw-600'>{item.hdtype == null ? 'All' : item.hdtype}</label></div>
                                            <div className='mt-2' style={{ fontSize: '14px', color: '#4C5258' }}>Category : <label className='fw-600'>{item.hdcategory == null && item.hdtype != null ? 'All' : item.hdcategory == null && item.hdtype == null ? '-' : item.hdcategory}</label></div>
                                            <div className='d-flex align-items-center'>
                                                <div className='mt-3'>{item.assigned_flag == 2 ? <FontAwesomeIcon icon={faUser} /> : <FontAwesomeIcon icon={faUsers} />}</div>
                                                <div className='fw-600 mt-3 ps-2' style={{ fontSize: '16px', color: '#4C5258' }}>{item.assignid}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className='text-center m-auto' style={{ alignItems: 'center' }}>
                                <img src='/images/TicketAssignmentIcon.svg' />
                            </div>
                        )}
                    </div>
                    {showPagination && (
                        <Pagination activePage={currentPage} totalItemsCount={pageRangeCount}
                            pageRangeDisplayed={15} itemsCountPerPage={LimitForPagination} onChange={(e) => setCurrentPage(e)}
                            color="primary" lastPageText="" firstPageText="" itemClassFirst="MasterClass" itemClassPrev="MasterClass"
                            itemClassNext="MasterClass" itemClassLast="MasterClass" />
                    )}
                </div>
            </div>


            <Popup isshow={Ispopup} size={"md"} title={flag == 1 ? 'Add Helpdesk Ticket Assignments' : 'Update Helpdesk Ticket Assignments'}
                closePopup={(e) => { setIspopup(false); setassignedflag(0); setErrorMsg(''); setHelpdeskType([]); setHelpdeskCategories([]); setValues({ ...Values, buildingId: '0', CategoryId: '0', HDtypeid: '0' }); setAllUsers([]) }}
                modalBody={modalbody} handleSave={(e) => handleSaveUpdate(flag)} visibleSaveBtn={true} btnText={flag == 1 ? 'Save' : 'Update'} />
        </div>
    )
}
