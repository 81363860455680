import React, { useEffect, useState } from 'react'
import { LogInSchoolID, MMDDYYYY, getAllUsers, typewatch } from '../../JS/Common';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';
import Deletepopup from '../../Components/Deletepopup';
import AlertsComp from '../../Components/AlertsComp';
import { CheckValidation } from '../../Components/Validations';
import Popup from '../../Components/Popup';
import PageHead from '../../Components/PageHead';
function UsersGroup() {
    const [loading, setLoading] = useState(false);
    const [GropuInfoModal, setGropuInfoModal] = useState(false);
    const [DeletePopup, setDeletePopup] = useState(false);
    const [Alerts, setAlerts] = useState('');
    const [GroupId, setGroupId] = useState('');
    const [Errormsg, setErrormsg] = useState('');
    const [UserId, setUserId] = useState('');
    const [UserGroupData, setUserGroupData] = useState([]);
    const [SelectedIdsAll, setSelectedIdsAll] = useState([]);
    const [AllUsers, setAllUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [allData, setAllData] = useState({
        SortbyFlag: 'asc',
        searchstring: '',
        SortbyKey: ''
    })
    const [GroupInfo, setGroupInfo] = useState({
        groupid: '',
        groupname: '',
        users: []
    });
    const navigate = useNavigate()
    const showPagination = UserGroupData.length > 0;
    useEffect(() => {
        GetUsers(allData.searchstring || null, allData.SortbyKey || null);
    }, [currentPage, LimitForPagination]);

    async function GetUsers(searchstring, sortkey) {
        if (!searchstring) searchstring = null;
        if (sortkey) allData.SortbyFlag = allData.SortbyFlag == "asc" ? "desc" : "asc";
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/GetAllGroups/" + LogInSchoolID + '&' + searchstring + '&' + sortkey + '&' + allData.SortbyFlag + '&' + currentPage + '&' + LimitForPagination).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    setpageRangeCount(responseRs.msg.total)
                    setUserGroupData(responseRs.msg.data.length ? responseRs.msg.data : []);
                }
            });
        }, 500);
    }
    async function GetGroupInfo(gid) {
        setLoading(true);
        await ApiGetCall("/GetGroupbyId/" + LogInSchoolID + '&' + gid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var msg = responseRs.msg;
                setLoading(false);
                if (msg != null) {
                    setGroupInfo({ ...GroupInfo, groupid: msg.id, groupname: msg.name, users: responseRs.users });
                }
                getAllUsers(setAllUsers);
            }
        })
    }
    async function DeleteGroup() {
        setLoading(true);
        var raw = "";
        await ApiPostCall("/deletegroup/" + LogInSchoolID + "&" + GroupId, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setDeletePopup(false);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Group Deleted Successfully."} />);
                    setTimeout(() => {
                        GetUsers(allData.searchstring || null, allData.SortbyKey || null);
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
            }
        });
    }
    async function handleSaveGroup() {
        const joinedIds = SelectedIdsAll.join(',');
        var isFormValid = CheckValidation({ formID: 'GroupModelId' });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            SchoolID: LogInSchoolID,
            groupname: GroupInfo.groupname,
            groupid: GroupInfo.groupid,
            groups: joinedIds
        });
        await ApiPostCall("/SaveUserGroups", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result)
                if (responseRs.response == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Group Updated Successfully."} />);
                    setGropuInfoModal(false);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        GetUsers(allData.searchstring || null, allData.SortbyKey || null);
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
            }
        });
    }
    async function handleDeleteFromModal(userid) {
        setUserId(userid);
        if (GroupInfo.users.length == 1) {
            setErrormsg('This is the last active user in this group. If you delete this user, the group will be automatically removed, and any tickets assigned to it will be unassigned. Are you sure you want to proceed?');
            return;
        } else {
            FinalDeleteUser(1, userid);
        }
    }
    async function FinalDeleteUser(flag, userid) {
        var raw = JSON.stringify({
            SchoolID: LogInSchoolID,
            groupid: GroupInfo.groupid,
            userid: userid,
            Flag: flag //1 -> more than one user 2->last user
        });
        await ApiPostCall("/deleteuserfromgroup", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"User Deleted Successfully."} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        if (flag == 1) {
                            GetGroupInfo(GroupInfo.groupid)
                        } else {
                            setGropuInfoModal(false);
                            GetUsers(allData.searchstring || null, allData.SortbyKey || null);
                        }
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    function handleCheckboxChange(e, ID) {
        const isChecked = e.target.checked;
        setSelectedIdsAll((prevIds) => {
            if (isChecked) {
                return [...prevIds, ID];
            } else {
                return prevIds.filter((id) => id !== ID);
            }
        });
    }
    const GroupInfoModalBody = (
        <div id="GroupModelId" className='parent'>
            <div className='col-12'>
                <input type="text" required autoComplete='off' name='groupname' className="form-control" value={GroupInfo.groupname}
                    onChange={(e) => setGroupInfo({ ...GroupInfo, groupname: e.target.value })} />
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            <div className='col-12 pt-2'>
                <div className='col-12 text-end'>
                    <label className='redText font-12'>*Existing Users</label>
                </div>
                <div className="greyBox">
                    <table className="table">
                        <thead className="GridHeader">
                            <tr>
                                <th scope="col" className="fw-600 text-start">User Name</th>
                                <th scope="col" className="fw-600 text-start">Email</th>
                                <th scope="col" className="fw-600 text-start"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {AllUsers?.length != null ? (
                                GroupInfo.users.map((groupUser, i) => {
                                    return (
                                        <tr key={groupUser.id} className={`OddEvenClass tableBorderHide text-danger`}>
                                            <td className="py-12 text-start"> {groupUser.first_name + ' ' + groupUser.last_name} </td>
                                            <td className="py-12 text-start">{groupUser.email || '-'}   </td>
                                            <td className='text-center py-12'>
                                                <img src='/images/RedDelete.svg' title='Delete this user from group' style={{ height: "18px" }}
                                                    className='cursor-pointer' onClick={() => { handleDeleteFromModal(groupUser.id); }} />
                                            </td>
                                        </tr>
                                    );
                                }).concat(
                                    AllUsers
                                        .filter(user => !GroupInfo.users.some(groupUser => groupUser.id === user.id))
                                        .map((item, i) => {
                                            return (
                                                <tr key={item.id} className={`OddEvenClass tableBorderHide`}>
                                                    <td className="py-12 text-start"> {item.name} </td>
                                                    <td className="py-12 text-start">{item.email || '-'}   </td>
                                                    <td className='text-center py-12'>
                                                        <input type="checkbox" title="Add user" class="form-check-input cursor-pointer" name="user"
                                                            onChange={(e) => handleCheckboxChange(e, item.id)} />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                )
                            ) :
                                <tr><td colspan={3} className="text-center"> No Record Found </td> </tr>
                            }

                        </tbody>

                    </table>
                </div>
                <label className='font-12 redText pt-3'>{Errormsg}</label>
                {Errormsg != '' && (
                    <div className='col-12 text-center'>
                        <label className="ms-2 cursor-pointer" onClick={(e) => FinalDeleteUser(2, UserId)}><u>Yes</u></label>
                        <label className="ms-3 cursor-pointer" onClick={() => { setErrormsg('') }}><u>No</u></label>
                    </div>
                )}
            </div>
        </div>
    )
    const handleSearchChange = (e) => {
        setCurrentPage(1);
        GetUsers(e.target.value, null);
        setAllData({ ...allData, searchstring: e.target.value })
    }
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Users Groups"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <section>
                <div className="GridBox mt-2 px-3 pb-3">
                    <div className='row justify-content-end'>
                        <div className="col-md-3 my-2 text-end">
                            <label className='BorderBtn text-center marginDetailsPopup' title='Create Group' onClick={() => navigate('/staff-members')}>
                                <img src='/images/AddInventory.svg' className='img-fluid pe-2' alt='Add Group' />
                                Create Group
                            </label>
                        </div>
                    </div>
                    <div className="greyBox p-3 data-table-container">
                        <table className="table data-table">
                            <thead className='GridHeader'>
                                <tr>
                                    <th scope="col" className='fw-600 cursor-pointer' onClick={(e => GetUsers(allData.searchstring, 'name'))}>Name <img src="/images/sortbynew.svg" alt={`Sort by Name`} className="img-fluid ps-2 sortbyClass" /></th>
                                    <th scope="col" className='fw-600'>No. of Users</th>
                                    <th scope="col" className='fw-600 cursor-pointer' onClick={(e => GetUsers(allData.searchstring, 'created_at'))}>Created at <img src="/images/sortbynew.svg" alt={`Sort by Created at`} className="img-fluid ps-2 sortbyClass" /></th>
                                    <th scope="col" className='fw-600 text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {UserGroupData.length != 0 ?
                                    UserGroupData.map((item, i) => {
                                        const userid = item.user_id.split(',');
                                        const count = userid.length;
                                        return <tr key={i} className="OddEvenClass tableBorderHide">
                                            <td scope="col" className='py-12'>{item.name}</td>
                                            <td scope="col" className='py-12'>{count}</td>
                                            <td scope="col" className='py-12'>{MMDDYYYY(item.created_at)}</td>
                                            <td scope="col" className='text-center py-12'>
                                                <img src='/images/EditIconNew.svg' title='Edit Group' className='img-fluid pe-2 cursor-pointer' onClick={(e) => { setGropuInfoModal(true); setSelectedIdsAll([]); GetGroupInfo(item.id) }} />
                                                <img src="/images/DeleteIcon.svg" title="Delete Group" className="img-fluid cursor-pointer"
                                                    onClick={(e) => { setDeletePopup(true); setGroupId(item.id) }} />
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr className="text-center"> <td colSpan={15}>No Record Found</td> </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {showPagination && (
                        <Pagination
                            activePage={currentPage}
                            totalItemsCount={pageRangeCount}
                            pageRangeDisplayed={15}
                            itemsCountPerPage={LimitForPagination}
                            onChange={(e) => setCurrentPage(e)}
                            color="primary"
                            lastPageText=""
                            firstPageText=""
                            itemClassFirst="MasterClass"
                            itemClassPrev="MasterClass"
                            itemClassNext="MasterClass"
                            itemClassLast="MasterClass"
                        />
                    )}
                </div>
            </section>
            <Popup isshow={GropuInfoModal} size={"md"} title={"Users Group Details"}
                closePopup={(e) => { setGropuInfoModal(false); GetUsers(allData.searchstring || null, allData.SortbyKey || null) }}
                modalBody={GroupInfoModalBody} handleSave={handleSaveGroup} visibleSaveBtn={true} btnText={"Save"} />
            <Deletepopup text={`If you delete this group, all the tickets assigned to it will become unassigned. You won't be able to revert this!`} deletePopup={DeletePopup} size="xl" deletePart={DeleteGroup} DeleteShowClose={(e) => setDeletePopup(false)} />
        </React.Fragment >
    )
}

export default UsersGroup