import React from 'react';
export default function ShowInvoice() {
    const pdfUrl = 'https://53.fs1.hubspotusercontent-na1.net/hubfs/53/assets/directories/business-templates/files/EN/basic-invoice-file-pdf.pdf?hubs_content=www.hubspot.com%2Fbusiness-templates%2Fpdf&hubs_content-cta=bt-template-detail-card-anchor&_gl=1*wunf5h*_ga*MTA2MDI0ODYyNC4xNjk5MzUxODE3*_ga_LXTM6CQ0XK*MTcwMzA2Nzc2NC4zLjEuMTcwMzA2NzgyMS4zLjAuMA..';
    const handleGenerateInvoice = () => {
        fetch(pdfUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'invoice.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error downloading PDF:', error);
            });
    };
    return (
        <div className='text-center'>
            <iframe title="PDF Viewer" src={`https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
                width="80%" height="450">
                <p>Your browser does not support iframes.</p>
            </iframe>
            <div className='col-12 p-3'>
                <button className="SaveBtn" onClick={handleGenerateInvoice}>Generate Invoice</button>
            </div>
        </div>
    )
}
