import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination';
import { LogInSchoolID, MMDDYYYY, typewatch } from '../../JS/Common';
import { ApiGetCall } from '../../JS/Connector';
import PageHead from '../../Components/PageHead';

export default function PreviousAudits() {
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchvalue, setsearchvalue] = useState('');
    const [sortkey, setsortkey] = useState('asc');
    const [AuditData, setAuditData] = useState([]);
    const showPagination = AuditData.length > 0;
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [loading, setLoading] = useState(false);
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    useEffect(() => {
        GetAllPreviousAudits(searchvalue)
    }, [currentPage, LimitForPagination, sortkey])
    async function GetAllPreviousAudits(searchstring) {
        setsearchvalue(searchstring);
        if (searchstring == "" || searchstring == null) {
            searchstring = null;
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/getAllCompletedAudit/" + LogInSchoolID + '&' + searchstring + '&' + sortkey + '&' + LimitForPagination + '&' + currentPage).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    setpageRangeCount(responseRs.msg.total)
                    if (responseRs.msg.data.length != 0) {
                        setAuditData(responseRs.msg.data);
                    }
                    else {
                        setAuditData([]);
                    }
                }
            });
        }, 500);
    }
    function DownloadReport(pdfurl) {
        const link = document.createElement('a');
        link.href = `${BucketS3Link}${pdfurl}`;
        window.open(link.href, '_blank');
    }
    function handleSort() {
        setsortkey(prevSortKey => prevSortKey === 'asc' ? 'desc' : 'asc');
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        GetAllPreviousAudits(e.target.value, sortkey);
    }
    return (
        <div>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Previous Audits"} handleSearch={handleSearchChange}
                loading={loading} showsearch={true} />
            <div className="GridBox mt-2 p-3">
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                <th scope="col" className='fw-600 cursor-pointer' onClick={(e) => handleSort()}>
                                    Audit Number
                                    <img src="/images/sortbynew.svg" alt={`Sort by Audit Number`}
                                        className="img-fluid ps-2 sortbyClass" />
                                </th>
                                <th scope="col" className=' fw-600'>Date</th>
                                <th scope="col" className=' fw-600'>Reports</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AuditData.length != 0 ?
                                AuditData.map((item, i) => {
                                    return <tr key={i} className="OddEvenClass tableBorderHide">
                                        <td scope="col" className='py-12'>{item.audit_number}</td>
                                        <td scope="col" className='py-12'>{MMDDYYYY(item.created_at)}</td>
                                        <td scope="col" className='py-12'>
                                            <img
                                                src="/images/previewpdficon.svg"
                                                className="px-1 cursor-pointer"
                                                title="Download Report"
                                                onClick={() => DownloadReport(item.pdf)}
                                                style={{ height: '22px' }}
                                            />
                                        </td>
                                    </tr>
                                })
                                :
                                <tr className="text-center">
                                    <td colSpan={3}>No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div>
        </div>
    )
}
