import React, { useContext, useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { LogInSchoolID } from '../../JS/Common';
import { ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import { UserContext } from '../../App';
import AlertPopup from '../../Components/AlertPopup';
export default function Helpdeskdetails({ setLoading, setNewAlerts }) {
    const { UserData, setUserData } = useContext(UserContext);
    const [GoogleSSOFlag, setGoogleSSOFlag] = useState(UserData.ssoFlog)
    const [showcopymsg, setshowcopymsg] = useState(false);
    const [RevertHelpdeskPopup, setRevertHelpdeskPopup] = useState(false);
    const labelRef = useRef(null);
    var baseurl = window.location.origin;
    var schoolnumber = Cookies.get("Schoolnumber");
    useEffect(() => {
        setGoogleSSOFlag(UserData.ssoFlog)
    }, [UserData.ssoFlog])
    const handleCopyClick = () => {
        if (labelRef.current) {
            const labelValue = labelRef.current.innerText;
            navigator.clipboard.writeText(labelValue);
            setshowcopymsg(true);
            setTimeout(() => setshowcopymsg(false), 2000);
        }
    };
    async function updateSSOSetting() {
        setLoading(true)
        Cookies.remove('supportTicketDetails');
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            Flag: GoogleSSOFlag
        })
        await ApiPostCall("/ssoSetForSchool", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Helpdesk Setting Updated Successfully"} />);
                    setTimeout(() => {
                        setUserData((prevUserData) => ({ ...prevUserData, ssoFlog: GoogleSSOFlag }));
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    async function FuncRevertHelpdesk() {
        setLoading(true)
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            HelpdeskFlag: 1
        })
        await ApiPostCall("/HelpdeskRevert", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Helpdesk revert successfully!"} />);
                    setTimeout(() => {
                        setRevertHelpdeskPopup(false)
                        setUserData((prevUserData) => ({ ...prevUserData, helpdeskUpdatedFlag: null }));
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const renderCopyMessage = () => (
        showcopymsg && <label className="pt-2" style={{ color: "#3bbba4", fontSize: "15px" }}>Copied!</label>
    );
    const renderSSOSetting = () => (
        <div className="p-1 pe-0 mt-1 pt-3">
            <label>Do you require that only staff members create support tickets? </label>
            <div className='col-md-12 mt-3 d-flex align-items-center'>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name='googlesso'
                        onChange={() => setGoogleSSOFlag(1)}
                        defaultChecked={GoogleSSOFlag == 1}
                    />
                    <label className="form-check-label">Yes</label>
                </div>
                <div className="form-check ms-5">
                    <input
                        className="form-check-input"
                        type="radio"
                        name='googlesso'
                        onChange={() => setGoogleSSOFlag(0)}
                        defaultChecked={GoogleSSOFlag != 1}
                    />
                    <label className="form-check-label">No</label>
                </div>
                <div className="ps-5">
                    <button className="SaveBtn" onClick={updateSSOSetting}>Confirm</button>
                </div>
            </div>
        </div>
    );
    const renderRevertOption = () => (
        <div className="p-1 pe-0 mt-1 pt-3">
            <label className='pe-3'>Revert to simple helpdesk? </label>
            <button className="SaveBtn" onClick={() => setRevertHelpdeskPopup(true)}>Revert</button>
        </div>
    );
    const renderHelpdeskUrl = () => (
        <div className="p-1 pe-0 mt-3 d-flex align-items-center">
            <label ref={labelRef} style={{ wordBreak: 'break-all' }}>
                <b>{baseurl}{UserData.helpdeskUpdatedFlag == null ? `/tickets/${schoolnumber}` : `/helpdesk-request/${schoolnumber}`}</b>
            </label>
            <FontAwesomeIcon icon={faCopy} style={{ height: "20px" }} className='cursor-pointer ps-3' title='Click to copy this URL' onClick={handleCopyClick} />
        </div>
    );
    return (
        <>
            <div className="greyBox h-100 p-3 align-items-center" >
                <div className='row d-flex align-items-center p-1'>
                    <span className='GridTitle pb-2'>{UserData.helpdeskUpdatedFlag == null ? 'Support Ticket URL' : 'Helpdesk Ticket URL'}</span>
                </div>
                <hr className='my-0' />
                <div className='p-1 text-justify pt-3'>
                    {UserData.helpdeskUpdatedFlag == null ?
                        "Feel free to utilize this unique support ticket creation URL across your school website, enabling students, teachers, and parents to submit their support tickets conveniently."
                        :
                        "Since you're now using the advanced helpdesk, the old support ticket system is no longer available. You can access the helpdesk through this URL:"
                    }
                </div>
                {renderHelpdeskUrl()}
                <div className='col-12 text-center'>
                    {renderCopyMessage()}
                </div>
                {UserData.helpdeskUpdatedFlag == null ? renderSSOSetting() : renderRevertOption()}
            </div>

            <AlertPopup isshow={RevertHelpdeskPopup} img={'/images/redInfo.svg'}
                headingtext={`Are you sure?`} text={'Reverting to simple helpdesk will remove any configuration you have made on advanced helpdesk. Are you sure you want to revert?'}
                cancelcall={(e) => setRevertHelpdeskPopup(false)}
                canceltext={"No"} successtext={"Yes, Revert It!"} successcall={FuncRevertHelpdesk} btntype={'deleteBtn'} />
        </>
    )
}
