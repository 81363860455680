import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { HideLoder, LogInSchoolID, ShowLoder } from '../../../JS/Common';
import AlertsComp from '../../../Components/AlertsComp';
import { useNavigate } from 'react-router-dom';

export default function Importtickets() {
    const BaseUrl = process.env.REACT_APP_Base_URL;
    var ciphertext = Cookies.get('ciphertext');
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", ciphertext);
    const RepairTicketRef = useRef();
    const [fileName, setFileName] = useState({
        tickets: ''
    });
    const [NewAlerts, setNewAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    function ImportRepairTicketCSV(e) {
        setLoading(true)
        e.preventDefault();
        const fileInput = RepairTicketRef.current;
        const files = fileInput.files[0];
        if (!files) {
            setLoading(false);
            setFileName({ ...fileName, tickets: "First, import the CSV file." });
            return;
        }
        const filename = files.name;
        const extension = filename.split('.').pop();

        if (extension !== "csv") {
            setLoading(false);
            setFileName({ ...fileName, tickets: filename });
            return;
        }
        setFileName({ ...fileName, tickets: filename });

        const formdata = new FormData();
        formdata.append("file", files);
        formdata.append("ID", parseInt(Cookies.get('CsvUserId')));
        formdata.append("schId", parseInt(LogInSchoolID));

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        fetch(`${BaseUrl}/importTickets`, requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                if (result == "Success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'CSV imported Successfully'} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                        navigate('/manage-tickets')
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            })
            .catch(error => console.error('error', error));
    }
    function ShowFileName(flag) {
        const fileInput = RepairTicketRef.current;
        const files = fileInput.files[0];
        const filename = files.name;
        var extension = filename.split('.').pop();
        if (extension == "csv") {
            setFileName({ ...fileName, tickets: filename })
        } else {
            setFileName({ ...fileName, tickets: "You can only upload CSV files." })
        }
    }
    return (
        <div>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Import Tickets</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    To ensure that Tickets are entered properly with data accuracy, please download our CSV template file and ensure your columns match the template before you import your tickets into the system.
                </p>
                <div className="row pt-3 px-3">
                    <label className="pb-4 ps-0 text-justify" style={{ fontSize: "13px" }}><a href="/Csv/Ticket.csv" style={{ color: "#04ADFD", fontWeight: "600" }}>CLICK HERE</a> to download the template CSV file for tickets data. Please use this as a template and prepare your CSV file accordingly. Once your file is ready, use button below to browse file from your computer and click on upload button to import all data into your account. </label>
                    <div className="col-12">
                        <form onSubmit={(e) => ImportRepairTicketCSV(e)}>
                            <input type="file" ref={RepairTicketRef} name="repairTicket_file" id="RepairTicketFile" accept='.csv' onChange={(e) => ShowFileName(4)} />
                            <label className='ImportInventoryBtnForimportExport buttonclssMobile1' htmlFor="RepairTicketFile"> Import Tickets
                                <img src='/images/ImportInventory.svg' className='img-fluid ps-2' />
                            </label>
                            {fileName.tickets != '' ?
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' style={{ backgroundColor: '#3CBBA5', color: '#fff' }} />
                                :
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' />
                            }
                            <br />
                            <div id="ImportInventoryRepairTicketText" style={{ fontWeight: "600", color: 'green' }}>{fileName.tickets}</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
