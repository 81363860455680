import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuditContext } from '.';
import { HideLoder, LogInSchoolID, LogInUserID, ShowLoder } from '../../JS/Common';
import { ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';

const InputGroup = ({ startFrom, values, setValues, onLastInputFocus }) => {
    const firstInputRef = useRef(null);
    const handleInputChange = (index, event) => {
        const newValues = [...values];
        const inputValue = event.target.value.toUpperCase().trim();
        newValues[startFrom + index - 1] = inputValue;
        setValues(newValues);
        const nextInput = document.querySelector(`input[data-index='${startFrom + index + 1}']`);
        if (!nextInput) {
            onLastInputFocus();
        }
    };
    return (
        <div className="row">
            {[...Array(12)].map((_, i) => (
                <div key={i} className='col-xl-3 col-md-4 p-2'>
                    <div className='p-3 GridBox boxshaowstyle'>
                        <input
                            className="form-control input-border special-input"
                            type="text"
                            placeholder={`Serial Number/ Asset Tag`}
                            value={values[startFrom + i - 1] || ''}
                            onChange={(e) => handleInputChange(i, e)}
                            data-index={startFrom + i}
                            ref={i == 0 ? firstInputRef : null}
                        />
                        <div className='fw-600 pt-2' style={{ color: '#030e20ab' }}> Device {startFrom + i}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default function ScanDevices() {
    const { BuildingData, RoomData, AllAuditDrafts, setFinalScanned, setFinalUnScanned, showWelcomeAuditDiv, setVisibilityDiv, Auditnum } = useContext(AuditContext);
    const initialCount = 12;
    const [groups, setGroups] = useState([1]);
    const [ShowReAuditOptions, setShowReAuditOptions] = useState(false);
    const [inputValues, setInputValues] = useState(Array(initialCount).fill(''));
    const [isAnyInputFilled, setIsAnyInputFilled] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const anyInputFilled = inputValues.some(value => value.trim() != '');
        setIsAnyInputFilled(anyInputFilled);
    }, [inputValues]);
    function addMoreInputs() {
        setGroups(prevGroups => [...prevGroups, prevGroups.length + 1]);
        setInputValues(prevValues => [...prevValues, ...Array(12).fill('')]);
    };
    async function ContinueAudit(checkflag) {
        setLoading(true)
        const nonBlankDevices = inputValues.filter(device => device.trim() != '');
        const uniqueDevices = Array.from(new Set(nonBlankDevices));
        var drafts = {
            schoolID: LogInSchoolID,
            UserId: LogInUserID,
            BuildingId: BuildingData?.BuildingId,
            BuidlingName: BuildingData?.BuildingName,
            RoomId: RoomData?.RoomId,
            RoomName: RoomData?.RoomName,
            Devices: uniqueDevices,
            agrementFlag: showWelcomeAuditDiv,
            ContinueOrFinish: 1

        };
        var raw = JSON.stringify({
            schoolID: LogInSchoolID,
            userID: LogInUserID,
            drafts: drafts,
            auditNumber: Auditnum,
            agreementFlag: showWelcomeAuditDiv
        });
        await ApiPostCall("/saveAuditDraft", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    if (checkflag == 1) {
                        setVisibilityDiv(1);
                    } else {
                        FinishAudit();
                    }
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    async function FinishAudit() {
        setLoading(true);
        const nonBlankDevices = inputValues.filter(device => device.trim() !== '');
        var auditarray = [];
        AllAuditDrafts?.map((item, i) => {
            var vjson = {};
            const draftitem = JSON.parse(item.draft);
            var buildingid = draftitem?.BuildingId == "all" ? 0 : draftitem?.BuildingId;
            var roomid = draftitem?.RoomId == "all" ? 0 : draftitem?.RoomId;
            vjson['BuildingId'] = buildingid;
            vjson['BuildingName'] = draftitem?.BuidlingName;
            vjson['RoomId'] = roomid;
            vjson['RoomName'] = draftitem?.RoomName;
            vjson['Devices'] = draftitem?.Devices;
            auditarray.push(vjson);
        })
        var newjson = {};
        if (nonBlankDevices.length > 0) {
            var buildingid = BuildingData?.BuildingId == "all" ? 0 : BuildingData?.BuildingId;
            var roomid = RoomData?.RoomId == "all" ? 0 : RoomData?.RoomId;
            newjson['BuildingId'] = buildingid;
            newjson['BuildingName'] = BuildingData?.BuildingName;
            newjson['RoomId'] = roomid;
            newjson['RoomName'] = RoomData?.RoomName;
            newjson['Devices'] = nonBlankDevices;
            auditarray.push(newjson);
        }
        var raw = JSON.stringify({
            UserId: LogInUserID,
            SchoolId: LogInSchoolID,
            AuditArray: auditarray,
            AuditNumber: Auditnum
        });
        await ApiPostCall("/applyAudit", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setLoading(false);
                    setFinalScanned(responseRs.finalData);
                    setFinalUnScanned(responseRs.unscannedData);
                    setVisibilityDiv(3);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });

    }
    return (
        <div>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='GridBox px-4 py-3 mt-2'>
                <h6 className=' text-center'>
                    {BuildingData.BuildingId == "all" && RoomData.RoomId == "all" ?
                        <label>
                            Selecting <b className='schoolColorStatus'>'All Buildings'</b> or <b className='schoolColorStatus'>'Entire Building'</b> will disable location-based reporting functionality.
                        </label>
                        :
                        <label>
                            You have selected <b className='schoolColorStatus'>{RoomData.RoomName}</b> from <b className='schoolColorStatus'>{BuildingData.BuildingName}</b>.
                        </label>
                    }
                </h6>

                {groups.map((_, index) => (
                    <InputGroup key={index} startFrom={index * 12 + 1} values={inputValues}
                        setValues={setInputValues} onLastInputFocus={addMoreInputs} />
                ))}

                <div className='col-12 text-center py-4'>
                    {isAnyInputFilled && (
                        <u className='cursor-pointer' onClick={(e) => setShowReAuditOptions(true)}>
                            Finalize Building/Room
                        </u>
                    )}
                </div>
                <div className='col-12 text-center'>
                    {ShowReAuditOptions && (
                        <div className="col-12 font-15 px-5 text-center row pt-2">
                            <div className='d-flex ps-4 align-items-center justify-content-center font-13'>
                                <button className='SaveBtn align-items-center me-3' onClick={(e) => ContinueAudit(1)}>
                                    Continue Audit
                                </button>
                                <button className='SaveBtn align-items-center' onClick={(e) => ContinueAudit(2)}>
                                    Finish Audit
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
