import React from 'react'
import { ApiGetCall } from '../../../JS/Connector';
import { HideLoder, LogInSchoolID, MMDDYYYY, ShowLoder } from '../../../JS/Common';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export default function Exportdevices() {
    const [loading, setLoading] = useState(false);
    const [CsvData, setCsvData] = useState([]);
    const [divVisibleForExport, setDivVisibleForExport] = useState({
        forDevice: false,
        forDeviceNoReocrd: false
    });

    async function ExportInventory(flag) {
        setLoading(true);
        await ApiGetCall("/exportInventory/" + flag + "&" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var msgdata = responseRs.msg;
                if (msgdata.length > 0) {
                    setCsvData(responseRs);
                    setDivVisibleForExport({ ...divVisibleForExport, forDevice: true, forDeviceNoReocrd: false });
                } else {
                    setDivVisibleForExport({ ...divVisibleForExport, forDevice: false, forDeviceNoReocrd: true });
                }
                setLoading(false);
            }
        });
    }
    function RemoveCsv() {
        setCsvData([]);
        setDivVisibleForExport({ ...divVisibleForExport, forDevice: false, forDeviceNoReocrd: false });
    }

    const DownloadCsv = () => {
        // Assuming CsvData contains your data
        const csvHeaders = CsvData?.headers?.split('~') || [];
        const csvData = CsvData?.msg || [];

        // Transform headers to display-friendly format
        const transformedHeaders = csvHeaders.map(header => {
            switch (header) {
                case 'user':
                    return 'User';
                case 'building':
                    return 'Building';
                case 'room':
                    return 'Room';
                case 'created_at':
                    return 'Created At';
                default:
                    return header.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
            }
        });

        // Create CSV content
        let csvContent = transformedHeaders.join(',') + '\n';

        // Add data rows to CSV
        csvData.forEach(item => {
            const rowData = csvHeaders.map(header => {
                // Map each header to its corresponding data field in the item
                let dataValue;
                switch (header) {
                    case 'user':
                        dataValue = `${item.device_user_first_name || ''} ${item.device_user_middle_name || ''} ${item.device_user_last_name || ''}`;
                        break;
                    case 'building':
                        dataValue = item.building_name;
                        break;
                    case 'room':
                        dataValue = item.room_name;
                        break;
                    case 'created_at':
                        dataValue = item.created_at ? MMDDYYYY(item.created_at) : '';
                        break;
                    default:
                        dataValue = item[header.toLowerCase().replace(/ /g, '_')];
                        break;
                }
                if (typeof dataValue === 'undefined') {
                    dataValue = ''; // Handle missing data
                }
                return dataValue;
            });
            csvContent += rowData.join(',') + '\n';
        });

        // Create a Blob object with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a download link for the Blob object
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = 'data.csv';

        // Append the download link to the document and trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };


    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Export Devices</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    Want to export your devices? For active devices click on the "Export Active Devices" button. For decommissioned devices click on the "Export Decommissioned Devices" button. For loaner devices click on the “Export Loaner Devices" button. For all devices click on the "Export All" button below
                </p>
                <div className="row p-3">
                    <div className="col-md-12 px-0 py-2">
                        <label className='BorderBtnForimportExport' onClick={(e) => ExportInventory(1)}>Export Active Devices
                            <img src='/images/ExportInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                    <div className="col-md-12 px-0 py-2">
                        <label className='BorderBtnForimportExport' onClick={(e) => ExportInventory(2)}>Export Decommissioned Devices
                            <img src='/images/ExportInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                    <div className="col-md-12 px-0 py-2">
                        <label className='BorderBtnForimportExport' onClick={(e) => ExportInventory(3)}>Export Loaner Devices
                            <img src='/images/ExportInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                    <div className="col-md-12 px-0 py-2">
                        <label className='BorderBtnForimportExport' onClick={(e) => ExportInventory(4)}>Export All
                            <img src='/images/ExportInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                    {divVisibleForExport.forDevice &&
                        <div className="row pt-2">
                            <div>Your Devices CSV file is now ready for download. Please click the button below to initiate the file download.</div>
                            <div className="col-12 text-center">
                                <label style={{ fontSize: "12px", color: "#04ADFD" }} id="ExportedFileName">
                                    Devices.csv
                                </label>
                                <label className="WhiteFont"><button className='SaveBtn ms-4' onClick={DownloadCsv}>Download</button></label>
                                <FontAwesomeIcon icon={faCircleXmark} className="cursor-pointer ps-3" onClick={RemoveCsv} />
                            </div>
                        </div>
                    }
                    {divVisibleForExport.forDeviceNoReocrd &&
                        <div className="row pt-2">
                            <div className="text-danger">No Record Found!</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
