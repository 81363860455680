import React, { useContext, useState } from 'react'
import { HideLoder, LogInSchoolID, LogInUserID, ShowLoder } from '../../JS/Common';
import { AuditContext } from '.';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import Popup from '../../Components/Popup';

export default function FinishAuditPopup(props) {
    const { AllAuditDrafts, setFinalScanned, setVisibilityDiv, Auditnum, getAllDrafts, setFinalUnScanned } = useContext(AuditContext);
    const [Alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    async function FinishAudit() {
        setLoading(true);
        var auditarray = [];
        AllAuditDrafts.map((item, i) => {
            var vjson = {};
            const draftitem = JSON.parse(item.draft);
            var buildingid = draftitem.BuildingId == "all" ? 0 : draftitem.BuildingId;
            var roomid = draftitem.RoomId == "all" ? 0 : draftitem.RoomId;
            vjson['BuildingId'] = buildingid;
            vjson['BuildingName'] = draftitem.BuidlingName;
            vjson['RoomId'] = roomid;
            vjson['RoomName'] = draftitem.RoomName;
            vjson['Devices'] = draftitem.Devices;
            auditarray.push(vjson);
        })
        var raw = JSON.stringify({
            UserId: LogInUserID,
            SchoolId: LogInSchoolID,
            AuditArray: auditarray,
            AuditNumber: Auditnum
        });
        await ApiPostCall("/applyAudit", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setLoading(false);
                    setFinalScanned(responseRs.finalData);
                    setFinalUnScanned(responseRs.unscannedData);
                    props.closepopup();
                    setVisibilityDiv(3);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });

    }
    async function ClearAudit() {
        setLoading(true);
        await ApiGetCall("/clearAudit/" + LogInSchoolID + '&' + LogInUserID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                if (result == "success") {
                    setLoading(false);
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Audit Successfully Cleared!"} />);
                    setTimeout(function () {
                        setAlerts(<AlertsComp show={false} />)
                        props.closepopup();
                        setVisibilityDiv(1);
                        getAllDrafts();
                    }, 2500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setLoading(false);
                }
            }
        });
    }
    const modalbody = (
        <div className="col-12">
            <div className='col-12 d-flex justify-content-center pb-1'>
                <h5>Audit Number : </h5>&nbsp; {Auditnum}
            </div>
            {AllAuditDrafts.map((item, i) => {
                const draft = JSON.parse(item.draft);
                return <div className='p-2 mb-2 greyBox'>

                    <div className='row pb-1'>
                        <div className='col-md-4'>
                            <b>Building : </b>
                        </div>
                        <div className='col-md-8'>
                            {draft.BuidlingName}
                        </div>
                    </div>
                    <div className='row pb-1'>
                        <div className='col-md-4'>
                            <b>Room : </b>
                        </div>
                        <div className='col-md-8'>
                            {draft.RoomName ? draft.RoomName : "-"}
                        </div>
                    </div>
                    <div className='row pb-1'>
                        <div className='col-md-4'>
                            <b>Scanned Device : </b>
                        </div>
                        <div className='col-md-8'>
                            {draft.Devices.join(', ')}
                        </div>
                    </div>
                </div>
            })}
        </div>
    )
    return (
        <div>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.PendingAuditModal} size={"md"} title={"Pending Audits"}
                closePopup={(e) => props.closepopup()}
                modalBody={modalbody} handleSave={FinishAudit} visibleSaveBtn={true} btnText={"Finish Audit"}
                ExtraText={"Clear Audit"} handleSaveForExtra={ClearAudit} extraBtn={true} btntype={false} />
        </div >
    )
}
