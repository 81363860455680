import React from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Contract from './Contract';
export default function ParentContract() {
    var publishablekey = process.env.REACT_APP_PUBLISHABLE_Stripe_KEY;
    const stripePromise = loadStripe(publishablekey);
    return (
        <Elements stripe={stripePromise}>
            <Contract />
        </Elements>
    )
}
