import { useEffect, useState } from "react"
import { ApiGetCall, ApiPostCall } from "../../JS/Connector";
import { getUrlParameter, isValidEmail, typewatch } from "../../JS/Common";
import AlertsComp from "../../Components/AlertsComp";
import { StaffAddUpdate } from "./addupdate";
import { createContext } from "react";
import { CheckValidation } from "../../Components/Validations";
import { LogInSchoolID } from "../../JS/Common";
import { useNavigate } from "react-router-dom";
import PageHead from "../../Components/PageHead";
import Popup from "../../Components/Popup";
export const staffdetailscontent = createContext();
export function StaffMemberIndex() {
    const navigate = useNavigate();
    const [allData, setAllData] = useState({
        firstname: "",
        lastname: "",
        access: 0,
        email: "",
        flag: 1,
        Memberid: 0,
        SortbyFlag: 'asc',
        searchstring: ''
    });
    const [AccessDevice, setAccessDevice] = useState([]);
    const [EmailError, setEmailError] = useState("");
    const [Users, setUsers] = useState([]);
    const [Alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const [ErrorForInvaid, setErrorForInvaid] = useState('');
    const [isaddupadtepopup, setIsaddupadtepopup] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const showPagination = Users.length > 0;
    const [columnNames, setColumnNames] = useState([]);
    const [selectedIdsAll, setSelectedIdsAll] = useState([]);
    const [SelectedItems, setSelectedItems] = useState([]);
    const [CreateGroupModal, setCreateGroupModal] = useState(false)
    const [CreateBatchbuttonVisible, setCreateBatchbuttonVisible] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [grpErrorMsg, setGrpErrorMsg] = useState(false)
    useEffect(() => {
        GetAccessDevice(null, allData.searchstring ? allData.searchstring : null);
        if (getUrlParameter("flag") == "1") {
            setTimeout(function () {
                setAllData({ ...allData, access: 2 });
            }, 500);
        }
    }, [currentPage, LimitForPagination]);
    async function GetAccessDevice(skey, searchstring) {
        if (!searchstring) searchstring = null;
        if (skey) allData.SortbyFlag = allData.SortbyFlag == "asc" ? "desc" : "asc";
        var joinedString = null;
        if (columnNames.length > 0) {
            joinedString = columnNames.filter(name => name !== 'avatar').join('~');
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/allstaffmember/" + LogInSchoolID + '&' + searchstring + '&' + skey + '&' + allData.SortbyFlag + '&' + currentPage + '&' + LimitForPagination + "?data=" + joinedString).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs?.pagination?.total)
                    setAccessDevice(responseRs.access);
                    setUsers(responseRs.msg.length ? responseRs.msg : [])
                }
            });
        }, 500);
    }
    async function AddUpdateStaffMember(flag) {
        if (allData.email != "" || allData.email != null) {
            if (!isValidEmail(allData.email)) {
                setEmailError("*Invalid Email");
                isFormValid = false;
            } else {
                setEmailError("");
            }
        }
        var isFormValid = CheckValidation({ formID: 'UserForm' });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            firstname: allData.firstname,
            lastname: allData.lastname,
            access: allData.access,
            schoolId: LogInSchoolID,
            email: allData.email,
            id: allData.Memberid
        });
        await ApiPostCall("/addUpdateUser", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setAlerts("");
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    var msg = flag == 1 ? 'Member Added Successfully.' : 'Member Updated Successfully.';
                    setAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        setIsaddupadtepopup(false)
                        GetAccessDevice(null, null);
                        setAllData({ ...allData, firstname: '', lastname: '', email: '', flag: 1, access: 0, Memberid: 0 })
                        setErrorForInvaid('')
                    }, 2500);
                }
                else if (responseRs.msg == 'Invalid Domain') {
                    setErrorForInvaid(`Please add a staff member with an email address that corresponds to school's domain, which is ${responseRs.domain} `)
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1000);
                }
            }
        });
    }
    async function CreateGroupCall() {
        const joinedIds = selectedIdsAll.join(',');
        if (selectedIdsAll.length < 2) {
            setGrpErrorMsg(true)
            return
        }
        var isFormValid = CheckValidation({ formID: 'Staffmembergroup' });
        if (!isFormValid) return;
        setLoading(true);

        var raw = JSON.stringify({
            SchoolID: LogInSchoolID,
            groupname: groupName,
            groups: joinedIds,
            groupid: 0
        });
        await ApiPostCall("/SaveUserGroups", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result)
                if (responseRs.response == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Group Created Successfully"} />);
                    setCreateGroupModal(false);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        navigate("/users-groups")
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        GetAccessDevice(null, e.target.value)
        setAllData({ ...allData, searchstring: e.target.value });
    }
    const modalBody = (
        <div className="row parent" id="UserForm">
            <div className='col-12 py-2'>
                <div className='FormLabel'>First Name<label className='redText'>*</label></div>
                <input type="text" name='firstname' autoComplete="off" className="form-control" required value={allData.firstname}
                    onChange={(e) => setAllData({ ...allData, firstname: e.target.value })} />
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            <div className='col-12 py-2'>
                <div className='FormLabel'>Last Name<label className='redText'>*</label></div>

                <input type="text" name='firstname' autoComplete="off" className="form-control" required value={allData.lastname}
                    onChange={(e) => setAllData({ ...allData, lastname: e.target.value })} />
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            <div className="col-12 py-2">
                <div className='FormLabel'>Access<label className='redText'>*</label></div>

                <select required value={allData.access} onChange={(e) => setAllData({ ...allData, access: e.target.value })}>
                    <option value="0">Select</option>
                    {AccessDevice.map((item, i) => {
                        return <option value={item.id} key={i} title={item.defination} >{item.access_type} </option>
                    })}
                </select>
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            <div className='col-12 py-2'>
                <div className='FormLabel'>Email<label className='redText'>*</label></div>

                <input type="email" name='Email' autoComplete="off" className="form-control " value={allData.email}
                    onChange={(e) => setAllData({ ...allData, email: e.target.value })} />
                <span className="Emailinvalid">
                    {EmailError}
                </span>
            </div>
            <div className="col-md-12" style={{ color: 'red' }}>{ErrorForInvaid}</div>
        </div>
    );
    const creategroupmodalbody = (
        <div id="Staffmembergroup" className="parent">
            <div className='col-12'>
                <div className='FormLabel'>Group Name<label className='redText'>*</label></div>
                <input className="form-control" autoComplete='off' name='building' required
                    value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            {grpErrorMsg == true && (
                <label className="Emailinvalid">Minimum two members are required</label>
            )}
        </div>
    )
    return (
        <staffdetailscontent.Provider value={{ allData, setAllData }}>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Staff Members"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="container-fluid px-0 mt-2">
                <div className="GridBox px-3 py-2">
                    <div className="row">
                        <div className='row my-2 d-flex justify-content-between pe-0 align-items-center'>
                            <div className='col-md-12 ps-0 text-center d-md-flex justify-content-end align-items-center'>
                                {CreateBatchbuttonVisible == true && (
                                    <button className="BorderBtn py-2 cursor-pointer" id='createdBatchbutton' onClick={(e) => setCreateGroupModal(true)}><img src="/images/AddInventory.svg" className="img-fluid pe-2" />Create Group </button>
                                )}
                                <label className='BorderBtn p-2 me-0 c text-center' title="Add Staff Member" onClick={(e) => { setIsaddupadtepopup(true); }}>Add Staff Member
                                    <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="greyBox h-100">
                                <div className="row">
                                    <StaffAddUpdate CurrentUsers={Users} setUsers={setUsers} GetAccessDevice={GetAccessDevice} setIsaddupadtepopup={setIsaddupadtepopup}
                                        Users={Users} currentPage={currentPage} LimitForPagination={LimitForPagination} showPagination={showPagination}
                                        setCurrentPage={setCurrentPage} pageRangeCount={pageRangeCount} setColumnNames={setColumnNames} columnNames={columnNames}
                                        selectedIdsAll={selectedIdsAll} SelectedItems={SelectedItems} setSelectedIdsAll={setSelectedIdsAll} setSelectedItems={setSelectedItems}
                                        setCreateGroupModal={setCreateGroupModal} setCreateBatchbuttonVisible={setCreateBatchbuttonVisible} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popup isshow={isaddupadtepopup} size={"md"} title={allData.flag == 1 ? 'Add Staff Member' : 'Edit Staff Member'}
                closePopup={(e) => { setIsaddupadtepopup(false); setAllData({ ...allData, firstname: '', lastname: '', access: 0, email: '', flag: 1, Memberid: 0 }) }}
                modalBody={modalBody} handleSave={(e) => allData.flag == 1 ? AddUpdateStaffMember(1) : AddUpdateStaffMember(2)} visibleSaveBtn={true} btnText={allData.flag == 1 ? 'Add' : 'Update'} />

            <Popup isshow={CreateGroupModal} size={"sm"} title={'Create Group'}
                closePopup={(e) => { setGroupName(''); setGrpErrorMsg(false); setCreateGroupModal(false) }}
                modalBody={creategroupmodalbody} handleSave={(e) => CreateGroupCall(e)} visibleSaveBtn={true} btnText={'Create'} />
        </staffdetailscontent.Provider>
    )
}
