import React, { useEffect, useRef, useState } from "react"
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import { CamelCaseWithSpaces, LogInUserID, MMDDYYYY, typewatch } from "../../JS/Common";
import Pagination from "react-js-pagination";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import AlertsComp from "../../Components/AlertsComp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons";
import CustomizationPopup from "../../Components/CustomizationPopup";
import { Schoolsearchbar } from "../../Components/Schoolsearchbar";
import BatchDetailsPopup from "../../Components/BatchDetailsPopup";
import SignatureCanvas from "react-signature-canvas";
import jinja from "jinja-js";
import html2pdf from "html2pdf.js";
import PageHead from "../../Components/PageHead";
import Tab from "../../Components/Tab";
import AlertPopup from "../../Components/AlertPopup";
export function IncomingBatches() {
    const BaseUrl = process.env.REACT_APP_Base_URL;
    const navigate = useNavigate();
    const [Batches, setBatches] = useState([]);
    const [SchoolID, setSchoolID] = useState([]);
    const [loading, setLoading] = useState(false);
    const [AttachTicketData, setAttachTicketData] = useState([]);
    const [BatchParams, setBatchParams] = useState({
        BatchTicketDataModal: false,
        BatchId: '',
        searchString: '',
        SortbyFlag: 'desc'
    });
    const [TabData, setTabData] = useState({
        OpenBatchesTab: 'active',
        CompletedBatchesTab: ''
    })
    const [ShowCreateBatchButton, setShowCreateBatchButton] = useState(false)
    const [AtVendorModal, setAtVendorModal] = useState(false)
    const [Alerts, setAlerts] = useState("");
    const [selectedIdsAll, setSelectedIdsAll] = useState([]);
    const [SelectedItems, setSelectedItems] = useState([]);
    const AdminSchoolId = Cookies.get('AdminSchoolId');
    const [currentPage, setCurrentPage] = useState(1);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const showPagination = Batches.length > 0;
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const SearchBatchName = pathArray[2];
    const currentLocation = useLocation();
    const sigCanvasForReceivedBy = useRef({});
    const [batchData, setBatchData] = useState({
        batchName: '',
        batchNote: ''
    })
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [SortKey, setSortKey] = useState(null)
    const [SignatureDataForReceivedBy, setSignatureDataForReceivedBy] = useState('')
    useEffect(() => {
        ApiGetHeaders(30, setColumnNames)
    }, []);
    useEffect(() => {
        setSchoolID(AdminSchoolId)
        if (AdminSchoolId != null && AdminSchoolId != undefined) {
            if (SearchBatchName != undefined) {
                setBatchParams({ ...BatchParams, searchString: SearchBatchName })
            }
            if (TabData.OpenBatchesTab == 'active') {
                AllBatches(AdminSchoolId, SearchBatchName ? SearchBatchName : null, SortKey || null, 1);
            } else {
                AllBatches(AdminSchoolId, SearchBatchName ? SearchBatchName : null, SortKey || null, 2);
            }
        }
    }, [AdminSchoolId, currentPage, LimitForPagination, SearchBatchName]);
    useEffect(() => {
        const selectedArray = [
            ...selectedIdsAll.map((id) => ({ id })),
        ];
        const idAndSubtotalArray = selectedArray.map(({ id }) => ({ id: id }));
        setSelectedItems(idAndSubtotalArray);
        if (idAndSubtotalArray.length === 0) {
            setShowCreateBatchButton(false)
        }
    }, [selectedIdsAll])
    async function AllBatches(schlid, searchString, SortKey, flag) {
        if (!searchString) searchString = null;
        if (SortKey) BatchParams.SortbyFlag = BatchParams.SortbyFlagg == "asc" ? "desc" : "asc";
        var tabflag = null;
        if (flag == 1) {
            tabflag = 2
        } else {
            tabflag = 1
        }
        typewatch(async function () {
            setLoading(true)
            await ApiGetCall("/getAllSchoolBatch/" + schlid + '&' + searchString + '&' + SortKey + '&' + BatchParams.SortbyFlag + '&' + currentPage + '&' + LimitForPagination + '&' + tabflag).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs.total)
                    if (responseRs.data.length !== 0) {
                        var OpenBatches = null;
                        var CompletedBatches = null;
                        if (flag == 1) {
                            var OpenBatches = responseRs?.data
                        } else {
                            var CompletedBatches = responseRs?.data
                        }
                        if (SearchBatchName) {
                            const foundInOpenBatches = OpenBatches?.some(batch => {
                                const batchName = batch.batch_name.trim();
                                const searchStringTrimmed = searchString.trim();
                                return batchName && batchName.includes(searchStringTrimmed);
                            });
                            const foundInCompletedBatches = CompletedBatches?.some(batch => {
                                const batchName = batch.batch_name.trim();
                                const searchStringTrimmed = searchString.trim();
                                return batchName && batchName.includes(searchStringTrimmed);
                            });
                            if (foundInOpenBatches) {
                                setBatches(OpenBatches);
                                setTabData({ ...TabData, OpenBatchesTab: 'active', CompletedBatchesTab: '' });
                            } else if (foundInCompletedBatches) {
                                setBatches(CompletedBatches);
                                setTabData({ ...TabData, CompletedBatchesTab: 'active', OpenBatchesTab: '' });
                            } else {
                                setBatches([]);
                            }
                        } else {
                            if (flag === 1) {
                                setBatches(OpenBatches);
                                setTabData({ ...TabData, OpenBatchesTab: 'active', CompletedBatchesTab: '' });
                            } else {
                                setBatches(CompletedBatches);
                                setTabData({ ...TabData, CompletedBatchesTab: 'active', OpenBatchesTab: '' });
                            }
                        }
                    } else {
                        setBatches([]);
                    }
                }
            });
        }, searchString != null ? 500 : 0);
    }

    function handleSetSchoolId(id) {
        setSchoolID(id);
    }
    async function ShowTicketDataForBatch(batchId) {
        setLoading(true)
        setBatchParams({ ...BatchParams, BatchTicketDataModal: true, BatchId: batchId });
        await ApiGetCall("/getSchoolBatchData/" + batchId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                setBatchData({ ...batchData, batchName: responseRs?.BatchName, batchNote: responseRs?.BatchNotes })
                if (responseRs.ticket.length != 0) {
                    setAttachTicketData(responseRs.ticket);
                } else {
                    setAttachTicketData([]);
                }
            }
        });
    }
    function trackingHandle(trackingnum) {
        const url = `https://www.fedex.com/fedextrack/?action=track&trackingnumber=${trackingnum}&cntry_code=us&locale=en_us`;
        window.open(url, '_blank');
    }
    async function ExtraDocument(FedExQr) {
        const link = document.createElement('a');
        link.href = `${BucketS3Link}${FedExQr}`;
        window.open(link.href, '_blank');
    };

    function handleHeaderCheckboxChange(e, ticketFlag) {
        setShowCreateBatchButton(true)
        const isChecked = e.target.checked;
        if (ticketFlag === "all") {
            setSelectedIdsAll(
                isChecked ? Batches.map((item) => item.id) : []
            );
        }
    };
    function handleCheckboxChange(e, ticketFlag, ID) {
        const isChecked = e.target.checked;
        setShowCreateBatchButton(true)
        if (ticketFlag === "all") {
            setSelectedIdsAll((prevIds) => {
                if (isChecked) {
                    return [...prevIds, ID];
                } else {
                    return prevIds.filter((id) => id !== ID);
                }
            });
        }
    }
    async function AtVendorStatusChangeCall() {
        var signatureBase64 = null;
        signatureBase64 = sigCanvasForReceivedBy.current.isEmpty() ? null : sigCanvasForReceivedBy.current.getTrimmedCanvas().toDataURL("image/png");
        var raw = JSON.stringify({
            schoolId: SchoolID,
            BatchId: SelectedItems,
            userId: LogInUserID,
            Receiversignature: signatureBase64
        });
        setLoading(true)
        await ApiPostCall("/changeBatchTicketStatusforAdmin", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setAtVendorModal(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Status Change Successfully"} />);
                    setTimeout(() => {
                        navigate('/school-tickets')
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const handleSortClick = (item) => {
        if (item == 'Total_Devices') {
            item = 'count'
        } else if (item == 'BatchName') {
            item = 'batch_name'
        } else {
            item = item.toLowerCase().split(' ').join('_');
        }
        if (item !== 0) {
            if (TabData.OpenBatchesTab == 'active') {
                AllBatches(SchoolID, BatchParams.searchString, item, 1)
            } else {
                AllBatches(SchoolID, BatchParams.searchString, item, 2)
            }
            setSortKey(item)
        }
    };
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "BatchName": <u className="cursor-pointer" title="Show Batch Details" onClick={(e) => ShowTicketDataForBatch(item.id)} batchid={item.id}>{item.batch_name}</u>,
            "Total_Devices": item.school_batch_log_count || '0',
            "TrackingNum": <span className='py-12 cursor-pointer' title="Track Shipment">
                {item.tracking_num != null ? <u onClick={(e) => trackingHandle(item.tracking_num)} batchid={item.id}>{item.tracking_num}</u> : <>-</>}
            </span>,
            "created_at": MMDDYYYY(item.created_at) || '-',
            "Notes": <div className="address">{item.notes || '-'}</div>,
        };
        return contentMap[columnName] || null;
    }
    const clearSignatureForReceivedBy = () => {
        if (SignatureDataForReceivedBy != null) {
            setSignatureDataForReceivedBy(null)
        } else {
            sigCanvasForReceivedBy.current.clear();
        }
    };
    function PrintPackingList(batchid) {
        var ciphertext = Cookies.get('ciphertext');
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", ciphertext);
        setLoading(true)
        fetch('PackingList.html')
            .then(response => response.text())
            .then(templateText => jinja.compile(templateText))
            .then(template => {
                fetch(BaseUrl + "/printlistForBatch/" + batchid, { headers: myHeaders })
                    .then(response => response.json())
                    .then(data => {
                        const formattedDate = new Date(data.created_at).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        });
                        const rendered = template.render({ data, formattedDate });
                        const pdfContent = document.createElement('div');
                        pdfContent.innerHTML = rendered;
                        pdfContent.style.padding = '20px';
                        html2pdf().from(pdfContent).save();
                        setLoading(false)
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error('Error loading data from API', error);
                    });
            })
            .catch(error => {
                setLoading(false)
                console.error('Error loading template', error);
            });
    }
    function handleSearchChange(e) {
        if (TabData.OpenBatchesTab) {
            setCurrentPage(1);
            AllBatches(SchoolID, e.target.value, null, 1);
            setBatchParams({ ...BatchParams, searchString: e.target.value });
        } else {
            setCurrentPage(1);
            AllBatches(SchoolID, e.target.value, null, 2);
            setBatchParams({ ...BatchParams, searchString: e.target.value })
        }
        setBatchParams({ ...BatchParams, searchString: e.target.value });
        if (currentLocation.pathname.includes(SearchBatchName)) {
            navigate('/incoming-batches');
        }
    }
    function handleTabClick(tabType) {
        if (tabType == "open") {
            if (currentLocation.pathname.includes(SearchBatchName)) {
                navigate('/incoming-batches');
                setBatchParams({ ...BatchParams, searchString: '' });
            }
            setTabData({ ...TabData, OpenBatchesTab: 'active', CompletedBatchesTab: '' });
            AllBatches(SchoolID, null, null, 1);
        } else if (tabType == "completed") {
            if (currentLocation.pathname.includes(SearchBatchName)) {
                navigate('/incoming-batches');
                setBatchParams({ ...BatchParams, searchString: '' });
            }
            setTabData({ ...TabData, OpenBatchesTab: '', CompletedBatchesTab: 'active' });
            AllBatches(SchoolID, null, null, 2);
            setSelectedIdsAll([])
        }
    }
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Incoming Batches"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={AdminSchoolId || null} />
            <div className='row col-12 align-items-center pe-0 pt-2'>
                <div className="col-10">
                    <Schoolsearchbar schlid={handleSetSchoolId} GetGridData={AllBatches} flag={5} />
                </div>
            </div>
            <div className="GridBox p-3 mt-2">
                {AdminSchoolId && (
                    <div className="row align-items-center px-3">
                        <div className="col-xl-9 col-md-9 px-0">
                            <ul className="nav nav-tabs pb-3">
                                <Tab isActive={TabData.OpenBatchesTab == 'active'}
                                    label="Open Batches" onClick={() => handleTabClick('open')} col={'col-md-3'} />
                                <Tab isActive={TabData.CompletedBatchesTab == 'active'}
                                    label="Completed Batches" onClick={() => handleTabClick('completed')} col={'col-md-3'} />
                            </ul>
                        </div>
                        <div className="col-xl-3 text-end px-0">
                            {ShowCreateBatchButton == true && (
                                <label className="BorderBtn ms-3 text-center mx-0" onClick={() => setAtVendorModal(true)}>
                                    <FontAwesomeIcon icon={faSatelliteDish} className="pe-2" />Receive
                                </label>
                            )}
                        </div>
                    </div>
                )}
                <div className="greyBox p-3">
                    {AdminSchoolId ?
                        <div className="row px-3">
                            <div className="greyBox py-0">
                                <div className="Header row align-items-center">
                                    <div className="col-12 data-table-container">
                                        <table className="table data-table mb-0" >
                                            <thead className='GridHeader'>
                                                <tr>
                                                    {Batches.length != 0 && TabData.OpenBatchesTab == 'active' && (
                                                        <th scope="col"> <input className='form-check-input' type="checkbox" checked={selectedIdsAll.length === Batches.length} onChange={(e) => handleHeaderCheckboxChange(e, "all")} /></th>
                                                    )}
                                                    {columnNames?.map((item) => {
                                                        const columnName = item.replace(/_/g, ' ');
                                                        return (
                                                            <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                                                {CamelCaseWithSpaces(columnName)}
                                                                <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                                            </th>
                                                        );
                                                    })}
                                                    <th scope="col" className='fw-600'>Action  <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Batches.length != 0 ?
                                                    Batches.map((item, i) => {
                                                        return <tr key={i} className={`OddEvenClass tableBorderHide ${item.TicketFlag}`}>
                                                            {TabData.OpenBatchesTab == 'active' && (
                                                                <td className="py-12">
                                                                    <input className='form-check-input' type="checkbox" checked={selectedIdsAll.includes(item.id)} onChange={(e) => handleCheckboxChange(e, "all", item.id)} batchid={item.id} />
                                                                </td>)}
                                                            {columnNames?.map(header => (
                                                                <td className="py-12" key={header}>
                                                                    {renderContentForColumn(header, item)}
                                                                </td>
                                                            ))}
                                                            <td className='py-12'>
                                                                {item.fedex_qr ?
                                                                    <img src="/images/previewpdficon.svg" className="cursor-pointer" title="Preview FedEX Document"
                                                                        onClick={(e) => ExtraDocument(item.fedex_qr)} style={{ height: '18px' }} batchid={item.id} />
                                                                    : <>-</>
                                                                }
                                                                <img src="/images/printlabelicon.svg" title="Show Packing List" className="img-fluid cursor-pointer ps-2" onClick={(e) => PrintPackingList(item.id)} style={{ height: 18 }} />
                                                            </td>
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={16} className="p-3 text-center">
                                                            No Record Found
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {showPagination && (
                                        <Pagination activePage={currentPage} totalItemsCount={pageRangeCount}
                                            pageRangeDisplayed={15} itemsCountPerPage={LimitForPagination} onChange={(e) => setCurrentPage(e)}
                                            color="primary" lastPageText="" firstPageText="" itemClassFirst="MasterClass" itemClassPrev="MasterClass"
                                            itemClassNext="MasterClass" itemClassLast="MasterClass" />
                                    )}
                                </div>
                            </div>
                        </div>
                        :
                        <div className='text-center'>
                            <h1 className="PageHeading text-center mt-1" style={{ fontSize: "30px" }}>Please select school from the search box above to get going!</h1>
                            <img src="/images/SchoolTicketVector.svg" className="img-fluid"></img>
                        </div>
                    }
                </div>
            </div>

            {BatchParams.BatchTicketDataModal == true && (
                <BatchDetailsPopup isShow={BatchParams.BatchTicketDataModal} ClosePopup={() => setBatchParams({ ...BatchParams, BatchTicketDataModal: false })} batchData1={batchData} AttachTicketData={AttachTicketData} />
            )}

            <AlertPopup isshow={AtVendorModal} img={'/images/greenInfo.svg'}
                headingtext={`Are you sure you want to mark all the tickets in this batch as 'At Vendor'?`}
                text={<div className="greyBox mt-4">
                    <div className='fw-600 font-15 text-center'>Received By Signature:</div>
                    <div className='mt-2 text-center'>
                        {SignatureDataForReceivedBy ? (
                            <img src={SignatureDataForReceivedBy} alt="Received By Signature" />
                        ) : (
                            <SignatureCanvas
                                ref={sigCanvasForReceivedBy}
                                canvasProps={{ width: 430, height: 100, className: "signatureCanvas" }}
                            />
                        )}
                    </div>
                    <div className="text-end"> <label className="cursor-pointer" onClick={clearSignatureForReceivedBy}><u>Clear</u></label></div>
                </div>}
                cancelcall={(e) => { setAtVendorModal(false); setSelectedIdsAll([]) }}
                canceltext={"Cancel"} successtext={"Yes"} successcall={(e) => AtVendorStatusChangeCall(e)} btntype={'SaveBtn'} />
            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={"Incoming Batches"} GetCall={() => ApiGetHeaders(30, setColumnNames)} />
            )}
        </React.Fragment>
    )
}
