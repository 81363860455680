import React, { useRef } from 'react'
import { useState } from 'react';
import { LogInSchoolID, getTodayDate, getUrlParameter, isValidEmail } from '../../JS/Common';
import { CheckValidation } from '../../Components/Validations';
import { useEffect } from 'react';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import { useContext } from 'react';
import { UserContext } from '../../App';
import AlertsComp from '../../Components/AlertsComp';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import PageHead from '../../Components/PageHead';


function AddSetup() {
    const [AllData, setAllData] = useState({
        schoolName: '',
        contactName: '',
        contactEmail: '',
        planstatus: ''
    })
    const [planName, setPlanName] = useState('')
    const [PlanId, setPlanId] = useState('')
    const [EstimatedStudent, setEstimatedStudent] = useState('')
    const [PercentOfDevices, setPercentOfDevices] = useState('')
    const [AccessCode, setAccessCode] = useState('')
    const [CreatedDate, setCreatedDate] = useState('')
    const [EndDate, setEndDate] = useState('');
    const [CoverageEndDate, setCoverageEndDate] = useState('')
    const [DeviceModels, setDeviceModels] = useState('')
    const [Loading, setLoading] = useState(false);
    const [NewAlerts, setNewAlerts] = useState("");
    const [emailError, setEmailError] = useState("");
    const [GradeError, setGradeError] = useState("");
    const { UserData } = useContext(UserContext);
    const loggedinemailid = UserData.emailid;
    const loggedinusername = UserData.UserName;
    var planIdFromGrid = getUrlParameter("id");
    const navigate = useNavigate();
    const currentDate = getTodayDate(new Date());
    const [AllGrades, setAllGrades] = useState([]);
    const [UserGrades, setUserGrades] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showOthersInput, setShowOthersInput] = useState(false);
    const [customGrades, setCustomGrades] = useState('');
    const inputRef = useRef(null);
    const location = useLocation();
    const { state } = location;
    const fromPage = state && state.fromPage;
    const schoolId = state && state.schoolid;
    useEffect(() => {
        if (planIdFromGrid != 0) {
            setPlanId(planIdFromGrid)
            EditData(planIdFromGrid)
        } else {
            setAllData({ ...AllData, schoolName: UserData.schoolname, contactName: loggedinusername, contactEmail: loggedinemailid })
            setCreatedDate(currentDate)
        }
        var schoolid = fromPage == 'admin' ? schoolId : LogInSchoolID
        ApiGetCall("/getUnusedGradesForPlan/" + schoolid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                setUserGrades(responseRs.length ? responseRs : [])
            }
        });
    }, []);

    const calculateEndDate = (startDate) => {
        const startYear = new Date(startDate).getFullYear();
        const endYear = startYear + 1;
        const endDate = `${endYear}-06-30`;
        return endDate;
    };
    useEffect(() => {
        if (CreatedDate) {
            const calculatedEndDate = calculateEndDate(CreatedDate);
            setCoverageEndDate(calculatedEndDate);
        }
    }, [CreatedDate]);


    async function EditData(planid) {
        setLoading(true);
        await ApiGetCall("/getPlanById/" + planid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setAllData({
                    ...AllData, contactName: responseRs.contact_name, contactEmail: responseRs.contact_email, schoolName: responseRs.school_name,
                    planstatus: responseRs.status
                })
                const grades = responseRs.grades ? responseRs.grades.split(',') : [];
                setPlanId(planid)
                setPlanName(responseRs.plan_name)
                setAccessCode(responseRs.access_token)
                setEstimatedStudent(responseRs.estimated_enrollment)
                setPercentOfDevices(responseRs.devices_not_leave_school)
                setDeviceModels(responseRs.device_models)
                setLoading(false);
                setCreatedDate(responseRs.start_date);
                setEndDate(responseRs.end_date);
                var newgrades = grades.sort((a, b) => {
                    return a.localeCompare(b);
                });
                setAllGrades(newgrades)
            }
        });
    }
    async function CreateNewPlan(flag) {
        var isFormValid = CheckValidation({ formID: 'CreatePlanForm' });
        if (AllData.contactEmail == null) {
            AllData.contactEmail = "";
        }
        if (AllData.contactEmail !== "") {
            if (!isValidEmail(AllData.contactEmail)) {
                setEmailError('*Invalid Email')
                isFormValid = false;
            } else {
                setEmailError('')
            }
        }
        if (AllGrades.length == 0) {
            setGradeError("*required");
            isFormValid = false;
        } else {
            setGradeError("")
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            SchoolId: fromPage == 'admin' ? schoolId : LogInSchoolID,
            SchoolName: AllData.schoolName,
            ContactName: AllData.contactName,
            ContactEmail: AllData.contactEmail,
            PlanName: planName,
            EstimatedStudent: EstimatedStudent,
            PercentOfDevices: PercentOfDevices,
            DeviceModels: DeviceModels,
            PlanID: PlanId ? PlanId : 0,
            StartDate: CreatedDate,
            EndDate: EndDate,
            CoverageStartDate: CreatedDate,
            CoverageEndDate: CoverageEndDate,
            Grades: AllGrades,
            AccessCode: AccessCode,
            PlanStatus: AllData.planstatus
        });
        await ApiPostCall("/AddUpdateInsurancePlan", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    var msg = flag == 1 ? 'Plan Created Successfully' : 'Plan Updated Successfully';
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={msg} />)
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        if (fromPage == 'admin') {
                            navigate('/pricing-activation-process');
                        }
                        else {
                            navigate('/setup');
                        }
                    }, 2000);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        let updatedOptions;
        if (checked) {
            updatedOptions = [...AllGrades, value];
        } else {
            updatedOptions = AllGrades.filter(option => option != value);
        }
        updatedOptions.sort();
        setAllGrades(updatedOptions);
    };
    const toggleDropdown = (isOpen) => {
        setShowDropdown(isOpen);
    };
    const toggleOthers = () => {
        setShowOthersInput(!showOthersInput);
        if (!showOthersInput) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 100)
        }
    }
    const handleGrades = (e) => {
        setCustomGrades(e.target.value)
    }
    const addCustomGrade = () => {
        if (customGrades.trim() != '') {
            const trimmedGrade = customGrades.trim().toUpperCase();
            const alreadyUsed = UserGrades.find(option => option.name.toUpperCase() == trimmedGrade && option.flag == 1);
            if (alreadyUsed) {
                setGradeError(`Grade '${trimmedGrade}' is already used in previous plans.`);
                return;
            }
            setGradeError('');
            const updatedOptions = [...AllGrades, trimmedGrade];
            const uniqueGrades = Array.from(new Set(updatedOptions));
            uniqueGrades.sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));
            setAllGrades(uniqueGrades);
        }
        inputRef.current.focus();
        setCustomGrades('')
    };
    const RemoveCustomGrades = (grade) => {
        const updatedOtherGrades = AllGrades.filter(g => g != grade);
        setAllGrades(updatedOtherGrades);
    }
    const [showStartDatePopover, setShowStartDatePopover] = useState(false);
    const [showEndDatePopover, setShowEndDatePopover] = useState(false);
    const StartPopover = (
        <Popover id="popover-start" style={{ maxWidth: '50%', fontSize: '14px' }} onMouseLeave={() => setShowStartDatePopover(false)}>
            <Popover.Body>
                <label>The date when sign-ups for coverage begin.</label>
            </Popover.Body>
        </Popover>
    );
    const EndPopover = (
        <Popover id="popover-end" style={{ maxWidth: '50%', fontSize: '14px' }} onMouseLeave={() => setShowEndDatePopover(false)}>
            <Popover.Body>
                <label>The date when sign-ups for coverage close.</label>
            </Popover.Body>
        </Popover>
    );
    const handleStartToggle = (nextShow) => {
        setShowStartDatePopover(nextShow);
    };
    const handleEndToggle = (nextShow) => {
        setShowEndDatePopover(nextShow);
    };
    const handleCancelClick = () => {
        if (fromPage == 'admin') {
            navigate('/pricing-activation-process');
        }
        else {
            navigate('/setup');
        }
    }
    return (
        <>
            <PageHead heading={planIdFromGrid != 0 ? 'Edit Plan' : 'Create Plan'}
                loading={Loading} Alerts={NewAlerts} showsearch={false} />
            <div className='GridBox p-3'>
                <div className="col-12 ">
                    <div id='CreatePlanForm' className="parent">
                        <div className="row">
                            <div className='col-lg-8  px-4 my-1'>
                                <div className='greyBox row' style={{ height: "100%" }}>
                                    <div className='col-md-6 py-2'>
                                        <label className='fw-600 content-forsetupcards py-2'>Plan Name *</label>
                                        <input type="text" name='planName' className="form-control" autoComplete='off' required value={planName} onChange={(e) => { setPlanName(e.target.value) }} />
                                        <span className="form-text invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                    <div className='col-md-6  py-2'>
                                        <label className='fw-600 content-forsetupcards py-2'>Mid-Year Transfer Access Code *</label>
                                        <input type="text" name='accesscode' className="form-control" autoComplete='off' required value={AccessCode} onChange={(e) => { setAccessCode(e.target.value) }} />
                                        <span className="form-text invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                    <div className='col-md-6 py-2'>
                                        <label className='fw-600 content-forsetupcards py-2'>Sign Up Start Date *</label>
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="right"
                                            show={showStartDatePopover}
                                            onToggle={handleStartToggle}
                                            overlay={StartPopover}
                                        >
                                            <img src="/images/InfoBtn.svg" title="What is Sign Up Start Date?" className="cursor-pointer" height='12px' />
                                        </OverlayTrigger>

                                        <input type="date" autoComplete='off' name='startDate' className="form-control" required value={CreatedDate}
                                            onChange={(e) => { setCreatedDate(e.target.value); }} min={currentDate}
                                        />
                                        <span className="form-text invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                    <div className='col-md-6 py-2'>
                                        <label className='fw-600 content-forsetupcards py-2'>Sign Up End Date *</label>
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="right"
                                            show={showEndDatePopover}
                                            onToggle={handleEndToggle}
                                            overlay={EndPopover}
                                        >
                                            <img src="/images/InfoBtn.svg" title="What is Sign Up End Date?" className="cursor-pointer" height='12px' />
                                        </OverlayTrigger>
                                        <input type="date" autoComplete='off' name='endDate' className="form-control" required
                                            value={EndDate}
                                            onChange={(e) => setEndDate(e.target.value)} min={currentDate}
                                        />
                                        <span className="form-text invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                    <div className="col-md-6 py-2">
                                        <label className='fw-600 content-forsetupcards py-2'>Coverage Start Date *</label>
                                        <input type="date" autoComplete='off' name='startDate' className="form-control" value={CreatedDate} disabled readOnly />
                                    </div>
                                    <div className="col-md-6 py-2">
                                        <label className='fw-600 content-forsetupcards py-2'>Coverage End Date *</label>
                                        <input type="date" autoComplete='off' className="form-control" value={CoverageEndDate} disabled readOnly />
                                    </div>
                                    <div className='col-md-6 py-2'>
                                        <label className='fw-600 content-forsetupcards py-2'>School Contact Name *</label>
                                        <input type="text" name='contactName' className="form-control" autoComplete='off' required value={AllData.contactName} onChange={(e) => { setAllData({ ...AllData, contactName: e.target.value }) }} />
                                        <span className="form-text invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                    <div className='col-md-6 py-2'>
                                        <label className='fw-600 content-forsetupcards py-2'>School Contact Email *</label>
                                        <input type="text" name='contactEmail' className="form-control" autoComplete='off' value={AllData.contactEmail} onChange={(e) => { setAllData({ ...AllData, contactEmail: e.target.value }) }} />
                                        <span className="Emailinvalid">
                                            {emailError}
                                        </span>
                                    </div>

                                    <div className='col-md-6  py-2'>
                                        <label className='fw-600 content-forsetupcards py-2'>Estimated Student Enrollment *</label>
                                        <input type="number" name='Enrollment' className="form-control" min={0} autoComplete='off' required value={EstimatedStudent} onChange={(e) => { setEstimatedStudent(e.target.value) }} />
                                        <span className="form-text invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                    <div className='col-md-6 py-2'>
                                        <label className='fw-600 content-forsetupcards py-2'>Device Models Covered *</label>
                                        <textarea name='devicemodels' className="form-control" rows={1} required value={DeviceModels} onChange={(e) => { setDeviceModels(e.target.value) }} />
                                        <span className="form-text invalid-feedback">
                                            *required
                                        </span>
                                    </div>

                                    <div className='row py-2 align-items-center pe-0'>
                                        <div className='col-9 pe-0'>
                                            <label className='fw-600 content-forsetupcards py-2'>What Percentage of Devices go home with Students? *</label>
                                        </div>
                                        <div className='col-3 px-0'>
                                            <div className="input-group d-flex">
                                                <input className="form-control" name='GoHomeWithStudents' autoComplete="off" required min={0} value={PercentOfDevices} onChange={(e) => { setPercentOfDevices(e.target.value) }} type="number" />
                                                <span className="input-group-text">%</span>
                                                <span className="form-text invalid-feedback">
                                                    *required
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-4 px-4 my-1'>
                                <div className="py-3 row greyBox">
                                    <label className='fw-600 content-forsetupcards py-2'>Grades *</label>
                                    <Dropdown show={showDropdown} onToggle={toggleDropdown}>
                                        <Dropdown.Toggle style={{ backgroundColor: "white", color: "black", textAlign: 'left', width: "100%", border: "1px solid #e2e3e4", display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => setShowDropdown(!showDropdown)}>
                                            Select Grades
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ width: "100%" }}>
                                            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                {Object.entries(UserGrades).map(([key, option]) => (
                                                    <Dropdown.Item key={option.name} as="div"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (option.flag != 1) {
                                                                handleCheckboxChange({
                                                                    target: {
                                                                        value: option.name,
                                                                        checked: !AllGrades.includes(option.name)
                                                                    }
                                                                });
                                                            }
                                                        }}>
                                                        <Form.Check type="checkbox" id={`checkbox-${option.name}`}
                                                            label={option.name} value={option.name} disabled={option.flag == 1}
                                                            checked={AllGrades.includes(option.name)}
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                handleCheckboxChange(e);
                                                            }} />
                                                    </Dropdown.Item>
                                                ))}
                                                <Dropdown.Item onClick={toggleOthers}>
                                                    <Form.Check type="checkbox" id="checkbox-others" label="Others"
                                                        checked={showOthersInput} onChange={toggleOthers} />
                                                </Dropdown.Item>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className='col-12 p-3'>
                                        {showOthersInput && (
                                            <div className="row">
                                                <div className='col-md-10'>
                                                    <input type="text" className='form-control' placeholder='Enter Grade' value={customGrades} onChange={handleGrades} ref={inputRef} />
                                                </div>
                                                <div className="col-md-2">
                                                    <label className='BorderBtn' style={{ marginRight: '0', padding: '2px 8px 2px 8px' }} onClick={addCustomGrade} ><img src="/images/helpdeskcategoryadd.svg" alt="" style={{ height: "25px" }} /></label>
                                                </div>
                                            </div>
                                        )}

                                        {AllGrades.length > 0 && AllGrades.map(option => (
                                            <span key={option} className="badge bg-secondary me-2 mt-2">
                                                {option}
                                                <span>
                                                    <FontAwesomeIcon icon={faTimes} className="cursor-pointer" title="Remove Grade" style={{ marginLeft: '5px' }} onClick={() => RemoveCustomGrades(option)} />
                                                </span>

                                            </span>
                                        ))}
                                    </div>
                                    <span className="Emailinvalid">
                                        {GradeError}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {fromPage == 'admin' &&
                        <div className='mt-3 text-center'>
                            <span><FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "red", height: "100px", height: '18px' }} className="img-fluid" /></span>
                            <span className="ps-2 redText">Editing this plan will affect all currently active users associated with it. Please review changes carefully.</span>
                        </div>
                    }
                    <div className='pt-3 text-center'>
                        {planIdFromGrid != 0 ?
                            <button className='SaveBtn' onClick={(e) => CreateNewPlan(2)}>Update Plan</button>
                            :
                            <button className='SaveBtn' onClick={(e) => CreateNewPlan(1)}>Create Plan</button>
                        }
                        <label className="mx-2 cursor-pointer" onClick={handleCancelClick}><u>Cancel</u></label>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AddSetup
