import { userdetailscontent } from "../Layout/ManageDevice/addupdate";
import { useContext, useEffect, useState } from "react";
import { getAllGrades } from "../JS/Common";
export function UserDetails() {
    const { allData, setAllData } = useContext(userdetailscontent);
    const [Grades, setGrades] = useState([]);
    const [GradeOtherDiv, setGradeOtherDiv] = useState('d-none');
    const [customGrade, setCustomGrade] = useState("");
    const handleChange = (flag) => {
        if (flag == "yes") {
            setAllData({ ...allData, ParentCheck: 1, CheckParentCoverage: 1 })
        } else {
            setAllData({ ...allData, ParentCheck: 0, CheckParentCoverage: 0 })
        }
    }
    const handleCheckboxChange = (e) => {
        setAllData({
            ...allData,
            IsStudent: e.target.checked ? 1 : 0,
        });
    };
    useEffect(() => {
        getAllGrades(setGrades);
    }, [])
    return (
        <>
            <div className="row">
                <div className='col-md-6 row align-items-center pt-2'>
                    <div className='col-md-7 FormLabel'>Device User First Name<label className='redText'>*</label></div>
                    <div className='col-md-5'>
                        <input type="text" autoComplete='off' name='firstname' required className="form-control" value={allData.FirstName}
                            onChange={(e) => setAllData({ ...allData, FirstName: e.target.value })} />
                        <span className="form-text invalid-feedback">
                            *required
                        </span>
                    </div>
                </div>
                <div className='col-md-6 row align-items-center pt-2'>
                    <div className='col-md-7 FormLabel'>Device User Middle Name</div>
                    <div className='col-md-5'>
                        <input type="text" autoComplete='off' name='middleName' className="form-control" value={allData.MiddleName}
                            onChange={(e) => setAllData({ ...allData, MiddleName: e.target.value })} />
                    </div>
                </div>
                <div className='col-md-6 row align-items-center pt-2'>
                    <div className='col-md-7 FormLabel'>Device User Last Name<label className='redText'>*</label></div>
                    <div className='col-md-5'>
                        <input type="text" autoComplete='off' name='lastName' required className="form-control" value={allData.LastName}
                            onChange={(e) => setAllData({ ...allData, LastName: e.target.value })} />
                        <span className="form-text invalid-feedback">
                            *required
                        </span>
                    </div>
                </div>
                <div className='col-md-6 row align-items-center pt-2'>
                    <div className='col-md-7 FormLabel'> User ID<label className='redText'>*</label></div>
                    <div className='col-md-5'>
                        <input type="text" name='studentID' required autoComplete='off' className="form-control" value={allData.StudentNumber}
                            onChange={(e) => setAllData({ ...allData, StudentNumber: e.target.value.toUpperCase() })} />
                        <span className="form-text invalid-feedback">
                            *required
                        </span>
                    </div>
                </div>
                <div className="col-md-6 row align-items-center pt-2">
                    <div className='col-md-7 FormLabel'>Is the user a student?</div>
                    <div className='col-md-5'>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name='isstudent'
                                checked={allData.IsStudent == 1}
                                onChange={handleCheckboxChange} />
                            <label className="form-check-label">
                                Yes
                            </label>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 row align-items-center pt-2'>
                    <div className='col-md-7 FormLabel'>Grade</div>
                    <div className='col-md-5'>
                        <select className="gradeselect" defaultValue={allData.Grade} disabled={allData.IsStudent == 0} onChange={(e) => {
                            setAllData({ ...allData, Grade: e.target.value });
                            if (e.target.value == "0") {
                                setAllData({ ...allData, Grade: "" })
                                setGradeOtherDiv("");
                                setCustomGrade("");
                            }
                            else {
                                setGradeOtherDiv("d-none");
                                setAllData({ ...allData, Grade: e.target.value });
                            }
                        }}>
                            <option value="">Select</option>
                            {Grades.map((grade, key) => (
                                <option key={key} value={grade} selected={allData.Grade == grade}>{grade}</option>
                            ))}
                            <option value="0">Other</option>
                        </select>
                        <div className={`${GradeOtherDiv} mt-2`} >
                            <input type="text" className="form-control" placeholder="Enter Grade" value={customGrade} onChange={(e) => {
                                setCustomGrade(e.target.value.toUpperCase()); setAllData({ ...allData, Grade: e.target.value.toUpperCase() })
                            }} />
                        </div>

                    </div>
                </div>
                <div className='col-md-6 row align-items-center pt-2'>
                    <div className='col-md-7 FormLabel'>Department</div>
                    <div className='col-md-5'>
                        <input type="text" autoComplete='off' name='department' className="form-control" value={allData.Department}
                            onChange={(e) => setAllData({ ...allData, Department: e.target.value })} disabled={allData.IsStudent == 1} />
                    </div>
                </div>
                <div className='col-md-6 row align-items-center pt-2'>
                    <div className='col-md-7 FormLabel'>Parent Email</div>
                    <div className='col-md-5'>
                        <input type="text" name='guardianemail' autoComplete='off' className="form-control" value={allData.ParentEmail}
                            onChange={(e) => setAllData({ ...allData, ParentEmail: e.target.value })} />
                        <span className="Emailinvalid">
                            {allData.emailError}
                        </span>
                    </div>
                </div>
                <div className='col-md-6 row align-items-center pt-2'>
                    <div className='col-md-7 FormLabel'>Parent Name</div>
                    <div className='col-md-5'>
                        <input type="text" autoComplete='off' name='guardianName' className="form-control" value={allData.ParentName}
                            onChange={(e) => setAllData({ ...allData, ParentName: e.target.value })} />
                    </div>
                </div>
                <div className='col-md-6 row align-items-center pt-2'>
                    <div className='col-md-7 FormLabel'>Parent Contact</div>
                    <div className='col-md-5'>
                        <input type="number" autoComplete='off' name='parentphonenumber' className="form-control" value={allData.ParentPhoneNumber}
                            onChange={(e) => setAllData({ ...allData, ParentPhoneNumber: e.target.value })} />
                        <span className="form-text invalid-feedback">
                            *required
                        </span>
                    </div>
                </div>
                <div className='col-md-6 row align-items-center pt-3'>
                    <div className='col-md-7 FormLabel'>Parental Coverage</div>
                    <div className='col-md-5 d-flex'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name='ParentalCoverage' checked={allData.ParentCheck == 1 ? true : false} onChange={(e) => handleChange("yes")} />
                            <label className="form-check-label">
                                Yes
                            </label>
                        </div>
                        <div className="form-check ms-5">
                            <input className="form-check-input" type="radio" name='ParentalCoverage' checked={allData.ParentCheck == 0 ? true : false} onChange={(e) => handleChange("no")} />
                            <label className="form-check-label">
                                No
                            </label>
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}
