import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { LogInSchoolID } from '../../../JS/Common';
import AlertsComp from '../../../Components/AlertsComp';
import { useNavigate } from 'react-router-dom';

export default function ImportDevices() {
    const BaseUrl = process.env.REACT_APP_Base_URL;
    const navigate = useNavigate();
    var authkey = process.env.REACT_APP_AuthKey;;
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", authkey);

    const fileRef = useRef();
    const fileWithoutStudentRef = useRef();
    const refForWithUser = React.useRef(null);
    const refForWithOutUser = React.useRef(null);

    const [NewAlerts, setNewAlerts] = useState("");
    const [fileName, setFileName] = useState({
        withStudent: '',
        withoutStudent: '',
    });

    async function ImportCSV(e, flag) {
        var temp = 0;
        e.preventDefault();
        var formdata = new FormData();
        const fileInput = flag == 1 ? fileRef.current : fileWithoutStudentRef.current;
        const files = fileInput.files[0];
        if (files == undefined) {
            setFileName({ ...fileName, [flag == 1 ? 'withStudent' : 'withoutStudent']: "First, import the CSV file." });
        } else {
            temp++;
            const filename = files.name;
            var extension = filename.split('.').pop();

            if (extension == "csv") {
                setFileName({ ...fileName, [flag == 1 ? 'withStudent' : 'withoutStudent']: filename });
            }

            formdata.append("file", files);
            formdata.append("flag", flag);
            formdata.append("ID", parseInt(Cookies.get('CsvUserId')));
            formdata.append("schId", parseInt(LogInSchoolID));
        }
        if (temp == 1) {
            const loaderRef = flag == 1 ? refForWithUser.current : refForWithOutUser.current;
            if (loaderRef) {
                loaderRef.continuousStart();
            }
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };
            try {
                const response = await fetch(`${BaseUrl}/upload`, requestOptions);
                const result = await response.text();
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'CSV imported Successfully'} />);
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />);
                        navigate('/manage-device')
                    }, 1500);

                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.response} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 2000);
                }
            } catch (error) {
                console.log('error', error);
            }
            finally {
                if (loaderRef) {
                    loaderRef.complete();
                }
            }
        }
    }
    function ShowFileName(flag) {
        const fileRefvar = flag == 1 ? fileRef.current : fileWithoutStudentRef.current;
        const key = flag == 1 ? 'withStudent' : 'withoutStudent';

        const files = fileRefvar.files[0];
        const filename = files.name;
        const extension = filename.split('.').pop();

        const isValidExtension = extension == 'csv';

        const errorMessage = isValidExtension ? null : 'You can only upload CSV files.';

        setFileName({ ...fileName, [key]: isValidExtension ? filename : errorMessage });
    }

    return (
        <div>
            {NewAlerts}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Import Devices</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <label className='redText text-center pb-2'>Ensure the date format is in mm/dd/yyyy within the CSV file.</label>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    To ensure devices and data accuracy please download our CSV template file and ensure your columns match the template before you import your devices into the system.
                </p>

                <div className="row pt-1">
                    <div className="col-12">
                        <label className="pb-4 ps-2 text-justify" style={{ fontSize: "13px" }}><a href="/Csv/inventory_withStudent.csv" style={{ color: "#04ADFD", fontWeight: "600" }}>CLICK HERE</a> to download the template CSV file for devices with users data. Please use this as a template and prepare your CSV file accordingly. Once your file is ready, use button below to browse file from your computer and click on upload button to import all data into your account. </label>
                        <form onSubmit={(e) => ImportCSV(e, 1)}>
                            <input type="file" ref={fileRef} name="upload_file" id="UploadFileId" accept='.csv' onChange={(e) => ShowFileName(1)} />
                            <label className='ImportInventoryBtnForimportExport buttonclssMobile1' htmlFor="UploadFileId"> Import Devices With Users
                                <img src='/images/ImportInventory.svg' className='img-fluid ps-2' />
                            </label>
                            {fileName.withStudent != '' ?
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' style={{ backgroundColor: '#3CBBA5', color: '#fff' }} />
                                :
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' />
                            }
                            <br />
                            <div className=" ps-2 my-2" style={{ fontWeight: "600", color: 'green' }}>{fileName.withStudent}</div>
                        </form>
                        <LoadingBar style={{ borderRadius: "6px" }} color='#3cbba5' ref={refForWithUser} containerClassName='progressbarClass' />
                    </div>
                    <div className="col-12 pt-3">
                        <label className="pb-4 ps-2 text-justify" style={{ fontSize: "13px" }}><a href="/Csv/inventory_withoutStudent.csv" style={{ color: "#04ADFD", fontWeight: "600" }}>CLICK HERE</a> to download the template CSV file for devices without users data (Use this import for all loaner devices). Please use this as a template and prepare your CSV file accordingly. Once your file is ready, use button below to browse file from your computer and click on upload button to import all data into your account.  </label>
                        <form onSubmit={(e) => ImportCSV(e, 2)}>
                            <input type="file" ref={fileWithoutStudentRef} name="upload_filewithoutstudent" id="UploadFileWithoutStudentId" accept='.csv' onChange={(e) => ShowFileName(2)} />
                            <label className='ImportInventoryBtnForimportExport buttonclssMobile1' htmlFor="UploadFileWithoutStudentId"> Import Devices Without Users
                                <img src='/images/ImportInventory.svg' className='img-fluid ps-2' />
                            </label>
                            {fileName.withoutStudent != '' ?
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' style={{ backgroundColor: '#3CBBA5', color: '#fff' }} />
                                :
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' />
                            }
                            <br />
                            <div className=" ps-2 my-2" style={{ fontWeight: "600", color: 'green' }}>{fileName.withoutStudent}</div>
                        </form>
                        <LoadingBar style={{ borderRadius: "6px" }} color='#3cbba5' ref={refForWithOutUser} containerClassName='progressbarClass' />
                    </div>
                </div>
            </div>
        </div>
    )
}
