import { useEffect, useState } from "react";
import { LogInUserID, typewatch, CamelCaseWithSpaces } from "../../JS/Common";
import { ApiGetCall, ApiGetCallAuthKey, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import AlertsComp from "../../Components/AlertsComp";
import Cookies from "js-cookie";
import Pagination from "react-js-pagination";
import { MDBSwitch } from "mdb-react-ui-kit";
import CustomizationPopup from "../../Components/CustomizationPopup";
import React from "react";
import PageHead from "../../Components/PageHead";
import Tab from "../../Components/Tab";
import AlertPopup from "../../Components/AlertPopup";
import { useNavigate } from "react-router-dom";

export function IndexSchools() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(null);
    const [isDropdownShippingType, setisDropdownShippingType] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedShippingItem, setselectedShippingItem] = useState(null);
    const [AllSchoolsData, setAllSchoolsData] = useState([]);
    const [schooltabs, setschooltabs] = useState({
        k12customers: "active",
        other: "",
    });
    const [Locations, setLocations] = useState([]);
    const [Alerts, setAlerts] = useState("");
    const [AdminData, setAdminData] = useState({
        useremail: "",
        Schoolname: "",
        schoolid: "",
        Location: "",
        ShippingType: "",
        contactID: ""
    })
    const [Searchstring, setSearchstring] = useState(null)
    const [SortbyFlag, setSortbyFlag] = useState('asc')
    const [SortbyKey, setSortbyKey] = useState(null)
    const [isStatusPopup, setisStatusPopup] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const showPagination = AllSchoolsData.length > 0;
    const [loading, setLoading] = useState(false);
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        ApiGetHeaders(42, setColumnNames)
        ApiGetCall("/allLocation").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length != 0) {
                    setLocations(responseRs);
                }
            }
        });
    }, []);
    useEffect(() => {
        getAllSchools(SortbyKey, Searchstring, SortbyFlag);
    }, [currentPage, LimitForPagination, schooltabs]);
    async function getAllSchools(sortbykey, search, sortbyflag) {
        if (!search) search = null;
        if (sortbykey) {
            setSortbyKey(sortbykey)
            setSortbyFlag(sortbyflag);
        };
        var tabflag = null;
        schooltabs.k12customers == "active" ? tabflag = 24 : tabflag = 25;
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/fetchAllSchools/" + search + "&" + LogInUserID + "&" + sortbykey + "&" + sortbyflag + '&' + tabflag + '&' + currentPage + '&' + LimitForPagination).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs.total)
                    setAllSchoolsData(responseRs?.data)
                    setLoading(false);
                }
            });
        }, search != null ? 500 : 0);
    }
    function viewTickets(email, id, schoolname, contactID) {
        setAdminData({ ...AdminData, useremail: email, schoolid: id, flag: 1, Schoolname: schoolname, contactID: contactID });
        setisStatusPopup(true);
    }
    async function LoginIntoSchool() {
        setLoading(true);
        await ApiGetCallAuthKey("/setK12LoginasSchoolLogin/" + LogInUserID + "&" + AdminData.contactID + "&1").then((result) => {
            setLoading(false);
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var sugData = responseRs.k12data;
                if (responseRs.status == "success") {
                    Cookies.set('CsvUserId', sugData.id, { expires: 1, secure: true, sameSite: 'Strict' });
                    Cookies.set('schoolid', responseRs.schooldata.school_id, { expires: 1, secure: true, sameSite: 'Strict' });
                    Cookies.set('ShippingType', responseRs?.schooldata?.school?.shipping_type, { expires: 1, secure: true, sameSite: 'Strict' });
                    if (responseRs.schooldata.school != null) {
                        Cookies.set('Schoolnumber', responseRs.schooldata.school.school_number, { expires: 1, secure: true, sameSite: 'Strict' });
                    } else {
                        Cookies.set('Schoolnumber', '', { expires: 1, secure: true, sameSite: 'Strict' });
                    }
                    window.location.href = '/manage-tickets';
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.status} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }

            }
        });
    }
    async function UpdateLocation(Location, schoolid, shippingtype) {
        setLoading(true);
        var raw = JSON.stringify({
            schoolId: schoolid,
            location: Location == "0" ? null : Location,
            shppingType: Location == '0' ? 1 : shippingtype
        });
        await ApiPostCall("/updateSchoolData", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Location Updated Successfully."} />);
                    getAllSchools(SortbyKey, Searchstring, SortbyFlag);
                    setIsDropdownOpen(null);
                    setisDropdownShippingType(null);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    function showSchoolTicket(schoolId, contactID, schoolname) {
        const expires = 1;
        Cookies.set('AdminSchoolId', schoolId, { expires: expires, secure: true, sameSite: 'Strict' });
        Cookies.set('SchoolContactID', contactID, { expires: expires, secure: true, sameSite: 'Strict' });
        Cookies.set('Schoolname', schoolname, { expires: expires, secure: true, sameSite: 'Strict' });
        navigate('/school-tickets');
    }
    async function ActiveDeactive(id, flag) {
        setLoading(true);
        var raw = JSON.stringify({
            SchoolID: id,
            Flag: flag
        });
        await ApiPostCall("/fetchAllSchoolsStatusChange", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    var msg = flag == 1 ? 'School Activated Successfully' : 'School Deactivated Successfully';
                    setAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        getAllSchools(SortbyKey, Searchstring, SortbyFlag);
                    }, 2000);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const handleSortClick = (item) => {
        if (item == 'schoolName') {
            item = 'name';
        } else if (item == 'openTickets') {
            item = 'openTicketCount';
        } else if (item == 'closeTickets') {
            item = 'closedTicketCount';
        } else if (item == 'totalTickets') {
            item = 'allTicketCount';
        }
        if (item !== 0) {
            setSortbyKey((prevSortbyKey) => {
                setSortbyFlag((prevSortbyFlag) => {
                    const newSortbyFlag = prevSortbyFlag === "asc" ? "desc" : "asc";
                    getAllSchools(item, Searchstring, newSortbyFlag);
                    return newSortbyFlag;
                });
                return item;
            });
        }
    };
    const handleSelectItem = (item, i) => {
        setSelectedItem(item);
        setIsDropdownOpen(i)
    };
    const handleSelectShippingItem = (item, i) => {
        setselectedShippingItem(item);
        setisDropdownShippingType(i)
    };
    function renderContentForColumn(columnName, item, i) {
        const contentMap = {
            "schoolName": item.name || '-',
            "locationName": (
                <div schoolid={item.id} className={`col-md-12 grid-item ${selectedItem == item ? 'selected' : ''}`}
                    onClick={() => handleSelectItem(item, i)}
                    onMouseLeave={(e) => {
                        if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget) || e.relatedTarget.tagName.toLowerCase() !== 'select') {
                            setIsDropdownOpen(null);
                        }
                    }}>
                    {isDropdownOpen == i ?
                        <select value={item?.location?.id} onChange={(e) => { setAdminData({ ...AdminData, Location: e.target.value }); UpdateLocation(e.target.value, item.id, item.shipping_type); }}  >
                            <option value="0">Select</option>
                            {Locations.map((locationItem, j) => (
                                <option value={locationItem.id} key={j} defaultValue={locationItem.id == item?.location?.id} >
                                    {locationItem.name}
                                </option>
                            ))}
                        </select>
                        : item?.location?.id == null || item?.location?.id == 0 ?
                            (<div>-</div>)
                            :
                            (<div className="py-2 cursor-pointer">{item?.location?.name}</div>)
                    }
                </div>

            ),
            "shippingType": (
                schooltabs.k12customers == "active" ?
                    <div schoolid={item.id} className={`col-md-12 grid-item ${selectedShippingItem === item ? 'selected' : ''}`}
                        onClick={() => handleSelectShippingItem(item, i)}
                        onMouseLeave={(e) => {
                            if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget) || e.relatedTarget.tagName.toLowerCase() !== 'select') {
                                setisDropdownShippingType(null);
                            }
                        }}>
                        {isDropdownShippingType === i ?
                            <select value={item.shipping_type}
                                onChange={(e) => { setAdminData({ ...AdminData, ShippingType: e.target.value }); UpdateLocation(item.location_id, item.id, e.target.value); }}>
                                <option value="0">Select</option>
                                <option value="1">Local</option>
                                <option value="2">Mail In</option>
                            </select>
                            :
                            <div className="py-2 cursor-pointer">{item.shipping_type == "1" ? "Local" : item.shipping_type == "0" ? "Select" : "Mail In"}</div>
                        }
                    </div>
                    :
                    <div>Local</div>

            ),
            "totalTickets": item.allTicketCount || "0",
            "closeTickets": item.closedTicketCount || "0",
            "contactEmail": item.oldestUser?.email || "-",
            "contactName": item.oldestUser?.first_name + ' ' + item.oldestUser?.last_name || "-",
            "openTickets": item.openTicketCount || '0'
        };

        return contentMap[columnName] || null;
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        setSearchstring((prevSearchstring) => {
            const newSearchstring = e.target.value;
            getAllSchools(SortbyKey, newSearchstring, SortbyFlag);
            return newSearchstring;
        });
    }
    function handleTabClick(tabType) {
        if (tabType == "k12") {
            setCurrentPage(1);
            setschooltabs({ ...schooltabs, k12customers: "active", other: "" });
            setIsDropdownOpen(null);
            setisDropdownShippingType(null);
        } else if (tabType == "other") {
            setCurrentPage(1);
            setschooltabs({ ...schooltabs, k12customers: "", other: "active" });
            setIsDropdownOpen(null);
            setisDropdownShippingType(null);
        }
    }
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Schools"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="GridBox mt-2 p-3">
                <div className="greyBox p-3 data-table-container">
                    <div className="col-md-12 pe-0 pb-3">
                        <ul className="nav nav-tabs">
                            <Tab isActive={schooltabs.k12customers == 'active'}
                                label="K12 Customers" onClick={() => handleTabClick('k12')} col={'col-md-3'} />
                            <Tab isActive={schooltabs.other == 'active'}
                                label="Other Rocket Users" onClick={() => handleTabClick('other')} col={'col-md-3'} />
                        </ul>
                    </div>
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                {columnNames?.map((item) => {
                                    const columnName = item.replace(/_/g, ' ');
                                    return columnName != 'contactEmail' && columnName != 'contactName' && columnName != 'shippingType' ?
                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                            {CamelCaseWithSpaces(columnName)}
                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                        </th>
                                        :
                                        <th key={item} scope="col" className='fw-600'>
                                            {CamelCaseWithSpaces(columnName)}
                                        </th>
                                })}
                                <th scope="col" className='fw-600 '>Active</th>
                                <th scope="col" className='fw-600' >Action<img src="/images/EditHeaderIcon.svg" className="cursor-pointer  img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {AllSchoolsData?.length != 0 ?
                                AllSchoolsData?.map((item, i) => {
                                    return <tr key={i} className='OddEvenClass tableBorderHide'>
                                        {columnNames?.map((header, k) => (
                                            <td scope="col" className="py-12" key={k}>
                                                {renderContentForColumn(header, item, i)}
                                            </td>
                                        ))}
                                        <td scope="col" className='py-4 py-12'>
                                            {item.status == 1 ?
                                                <MDBSwitch className="BgPink cursor-pointer" checked={item?.isChecked || true} onChange={(e) => { ActiveDeactive(item.id, 2) }} />
                                                :
                                                <MDBSwitch className="BgPink cursor-pointer" checked={item?.isChecked || false} onChange={(e) => { ActiveDeactive(item.id, 1) }} />
                                            }
                                        </td>
                                        <td className="text-center d-flex align-items-center py-4">
                                            <FontAwesomeIcon icon={faArrowRightToBracket} title="Login as school Admin" className='parent-icon cursor-pointer ' onClick={() => { viewTickets(item?.oldestUser?.email, item?.id, item.name, item?.oldestUser?.id) }} />
                                            <img src="/images/eye.png" className="ps-2 cursor-pointer " title="Show School Tickets" onClick={(e) => { showSchoolTicket(item.id, item?.oldestUser?.id, item.name) }} />
                                        </td>
                                    </tr>
                                })
                                :
                                <tr>
                                    <td colSpan={18} className="p-3 text-center">
                                        No Record Found
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {showPagination && (
                        <Pagination
                            activePage={currentPage}
                            totalItemsCount={pageRangeCount}
                            pageRangeDisplayed={15}
                            itemsCountPerPage={LimitForPagination}
                            onChange={(e) => { setCurrentPage(e); setSortbyFlag(SortbyFlag) }}
                            color="primary"
                            lastPageText=""
                            firstPageText=""
                            itemClassFirst="MasterClass"
                            itemClassPrev="MasterClass"
                            itemClassNext="MasterClass"
                            itemClassLast="MasterClass"
                        />
                    )}
                </div>
            </div>
            <AlertPopup isshow={isStatusPopup} img={'/images/greenInfo.svg'}
                headingtext={'Login as School Admin'} text={`Are you sure want to login as ${AdminData.Schoolname}?`}
                cancelcall={(e) => setisStatusPopup(false)}
                canceltext={"No"} successtext={"Continue"} successcall={LoginIntoSchool} btntype={'SaveBtn'} />

            {CustomizationModal && <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Schools'} tabFlag={schooltabs.k12customers == "active" ? '24' : '25'} GetCall={() => ApiGetHeaders(42, setColumnNames)} />}
        </React.Fragment>
    )
}
