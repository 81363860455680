import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { DeviceAllocate } from '../DeviceAllocation';
import { ErrorPage } from '../404Error';
export default function Access8() {
    return (
        <Routes>
            <Route exact path="/" element={<DeviceAllocate />} />
            <Route exact path="/allocate-devices" element={<DeviceAllocate />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    )
}
