import React from 'react'
import { MMDDYYYY } from '../../../JS/Common';
import AlertsComp from '../../../Components/AlertsComp';

export default function StaffTickets(props) {
    var vData = props?.data
    const downloadCsv = () => {
        const csvContent = [];
        vData?.forEach(items => {
            csvContent.push([`Name: ${items.user_name}`]);
            csvContent.push([`Access: ${items.access_type_name}`]);
            csvContent.push([`Email: ${items.user_email}`]);
            csvContent.push([`Total Tickets: ${items.tickets_count}`]);
            csvContent.push(['Ticket Number', 'Serial Number', 'Status', 'Student Number', 'Notes', 'Return Location', 'Created At']);
            items.tickets_details.forEach(details => {
                const serialnum = details.serial_num;
                const ticketNumber = details.ticket_num;
                const status = details.ticket_status;
                const notes = details?.ticket_notes?.replace(/[,\n.]/g, ' ');
                const studentNumber = details.student_num;
                const returnLocation = details.return_location;
                const createdDate = MMDDYYYY(details.created_at);
                csvContent.push([ticketNumber, serialnum, status, studentNumber, notes, returnLocation, createdDate]);
            });
            csvContent.push([]);
        });
        if (csvContent.length > 0) {
            const csvString = csvContent.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Staff Tickets.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            props.setAlerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
            setTimeout(() => {
                props.setAlerts(<AlertsComp show={false} />)
            }, 1500);
        }
    };
    return (
        <div className="text-center h-100 greyBox">
            <div className="pb-5 w-100">
                <div>
                    <h4>Tickets Created by Staff Members</h4>
                </div>
                <div className='justify-content-center d-flex mb-3'>
                    <label className='cursor-pointer d-flex align-items-center' onClick={downloadCsv} style={{ fontSize: "12px" }}>
                        <img src="/images/downloadPlan.svg" className="pe-1" title='Download CSV' height='10px' />
                        <label className='cursor-pointer'>Download Detailed Reports</label>
                    </label>
                </div>
                <div className="p-3 data-table-container">
                    {props.loder == true ?
                        <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div>
                        :
                        <table className="table data-table">
                            <thead className='GridHeader'>
                                <tr>
                                    <th scope="col" className='fw-600'>Name</th>
                                    <th scope="col" className='fw-600'>Access Type</th>
                                    <th scope="col" className='fw-600'>Email</th>
                                    <th scope="col" className='fw-600'>Total Created Tickets</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vData?.length != 0 ?
                                    vData?.map((item, i) => {
                                        return <tr key={i} className="OddEvenClass tableBorderHide">
                                            <td scope="col" className='py-12 text-start'>{item.user_name} </td>
                                            <td scope="col" className='py-12 text-start'>{item.access_type_name} </td>
                                            <td scope="col" className='py-12 text-start'>{item.user_email} </td>
                                            <td scope="col" className='py-12 text-start'>{item.tickets_count} </td>
                                        </tr>
                                    })
                                    :
                                    <tr className="text-center"> <td colSpan={15}>No Record Found</td></tr>
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    )
}
