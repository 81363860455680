import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { MMDDYYYY } from '../../../JS/Common';
import AlertsComp from '../../../Components/AlertsComp';

export default function TicketStatuses(props) {
    var vData = props?.data;
    const [series, setSeries] = useState([]);
    const [chartLoaded, setChartLoaded] = useState(false);
    useEffect(() => {
        const newSeries = vData?.map(value => parseFloat(value.count)) || [];
        setSeries(newSeries);
        setChartLoaded(true);
    }, [props.data]);
    var category = vData?.map((item) => item.status_name);

    var options = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: "top",
                },
            }
        },
        colors: ['#3CBBA5'],
        dataLabels: {
            style: {
                colors: ["black"],
            },
        },
        xaxis: {
            categories: category,
            labels: {
                formatter: function (value) {
                    if (value.length > 25) {
                        return value.substring(0, 15) + '...';
                    }
                    return value;
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "Total Tickets: " + val;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    },
                },
            },
        },
    }

    const downloadCsv = () => {
        const csvContent = [];
        props?.data?.forEach((status) => {
            if (status.count > 0) {
                csvContent.push(['Status: ' + status.status_name]);
                const headers = ['Ticket Number', 'Serial Number', 'Student Number', 'Notes', 'Return Location', 'Created at'];
                csvContent.push(headers);
                status.Tickets.forEach((ticket) => {
                    const cleanedNotes = ticket?.notes?.replace(/[,\n.]/g, ' ');
                    const values = [ticket.ticket_num, ticket.Serial_number, ticket.student_num, cleanedNotes, ticket.return_location_name, MMDDYYYY(ticket.created_at)];
                    csvContent.push(values);
                });
                csvContent.push([]);
            }
        });

        if (csvContent.length > 0) {
            const csvString = csvContent.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'TicketsByStatus.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            props.setAlerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
            setTimeout(() => {
                props.setAlerts(<AlertsComp show={false} />)
            }, 1500);
        }
    };


    return (
        <>
            <div className="text-center h-100 greyBox">
                <div className="pb-5 w-100">
                    <div className='row'>
                        <h4>Tickets By Status</h4>
                    </div>
                    <div className='justify-content-center d-flex'>
                        <label onClick={downloadCsv} className='cursor-pointer  d-flex align-items-center' style={{ fontSize: "12px" }}>
                            <img src="/images/downloadPlan.svg" className="pe-1" title='Download CSV' height='10px' />
                            <label className='cursor-pointer'>Download Detailed Reports</label>
                        </label>
                    </div>
                    {props.loder == true ?
                        <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div>
                        :
                        <div id="chart">
                            {chartLoaded && (
                                <ReactApexChart options={options} series={[{ data: series }]} type="bar" height={350} />
                            )}
                        </div>
                    }

                </div>
            </div>
        </>
    )
}
