import React, { useEffect, useState } from 'react'
import { ApiGetCall, ApiPostCall } from '../JS/Connector';
import { faUpDownLeftRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBSwitch } from 'mdb-react-ui-kit';
import { CamelCaseWithSpaces, HideLoder, LogInSchoolID, LogInUserID, ShowLoder } from '../JS/Common';
import AlertsComp from './AlertsComp';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Cookies from 'js-cookie';
import Popup from './Popup';
export default function CustomizationPopup(props) {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const pageUrl = pathArray[1];
    const [headerData, setHeaderData] = useState([])
    const [Alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    function GetGridFlag() {
        switch (props.gridname) {
            case 'Manage Software':
                return 1;
            case 'Buildings':
                return 2;
            case 'Staff Members':
                return 3;
            case 'OutGoing Batches':
                return 4;
            case 'Manage Users':
                return 5;
            case 'Manage Ticket':
                return 41;
            case 'Manage Devices':
                return 40;
            case 'Manage Parts':
                return 13;
            case 'Ship Devices':
                return 14;
            case 'Billing':
                if (props.tabFlag == 16) {
                    return 16;     //school close ticket
                } else if (props.tabFlag == 17) {
                    return 17;    //school billing batch
                } else if (props.tabFlag == 32) {
                    return 32;   //admin billing
                }
            case 'Technology Support Tickets':
                return 18;
            case 'Maintenance Support Tickets':
                return 18;
            case 'Other Requests':
                return 43;
            case 'Purchases':
                return 44;
            case 'Incoming Batches':
                return 30;
            case 'Technicians':
                return 33;
            case 'Domains':
                return 34;
            case 'Locations':
                return 35;
            case 'Master Inventory':
                return 36;
            case 'Schools':
                return 42;
            case 'School Tickets':
                return 26;
        }
    }
    useEffect(() => {
        var gridid = GetGridFlag()
        setLoading(true)
        var SchoolID = null
        if (pageUrl == 'incoming-batches' || pageUrl == 'admin-invoice' || pageUrl == 'school-tickets') {
            SchoolID = Cookies.get('AdminSchoolId');
        } else {
            SchoolID = LogInSchoolID
        }
        ApiGetCall("/GetGridHeaders/" + gridid + '&' + LogInUserID + '&' + SchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    const initialHeaderData = responseRs.originalstring.split('~');
                    const msgColumns = responseRs.msg.split('~');
                    const updatedHeaderData = initialHeaderData.map((header) => {
                        const existsInMsg = msgColumns.includes(header);
                        return `${header}${existsInMsg ? "-1" : "-0"}`;
                    });
                    const sortedHeaderData = updatedHeaderData.sort((a, b) => {
                        const indexA = responseRs.msg.indexOf(a.split('-')[0]);
                        const indexB = responseRs.msg.indexOf(b.split('-')[0]);
                        const suffixA = a.endsWith("-1") ? "-1" : "-0";
                        const suffixB = b.endsWith("-1") ? "-1" : "-0";
                        if (indexA == indexB) {
                            return suffixA.localeCompare(suffixB);
                        }
                        return indexA - indexB;
                    });
                    const sortedHeaderDataWithMinusOneFirst = [
                        ...sortedHeaderData.filter(item => item.endsWith("-1")),
                        ...sortedHeaderData.filter(item => !item.endsWith("-1"))
                    ];

                    setHeaderData(sortedHeaderDataWithMinusOneFirst);
                }
            }
        });
    }, [])
    const handleChange = (e, columnName) => {
        const newHeaderData = headerData.map((item) => {
            const [header, switchValue] = item.split('-');
            if (header == columnName) {
                const newSwitchValue = switchValue == '1' ? '0' : '1';
                return `${header}-${newSwitchValue}`;
            }
            return item;
        });

        setHeaderData(newHeaderData);
    };
    function formatString(inputString) {
        let parts = inputString.split('~');
        for (let i = 0; i < parts.length; i++) {
            let part = parts[i];
            let match = part.match(/\((\d+)\)/); // Match IDs in parentheses
            if (match) {
                parts[i] = `(${match[1]})`;
            }
        }
        return parts.join('~');
    }

    async function handleGenerateJson() {
        var gridid = GetGridFlag()
        const allZeros = headerData.every(col => {
            const [, num] = col.split('-');
            return num === '0';
        });
        if (allZeros) {
            setAlerts(<AlertsComp show={true} variant="danger" msg={"At least one column must be added."} />);
            setTimeout(() => {
                setAlerts(<AlertsComp show={false} />)
            }, 2000);
            return;
        }
        var concatenatedString;
        if (gridid == 40) {
            const inputString = headerData.filter(item => item.endsWith('-1')).map(item => item.split('-')[0]).join('~');
            let extractedString = formatString(inputString);
            concatenatedString = extractedString.replace(/\((\d+)\)/g, "$1");
        } else {
            concatenatedString = headerData.filter(item => item.endsWith('-1')).map(item => item.split('-')[0]).join('~');
        }
        var SchoolID = null
        if (pageUrl == 'incoming-batches' || pageUrl == 'admin-invoice' || pageUrl == 'school-tickets') {
            SchoolID = Cookies.get('AdminSchoolId');
        } else {
            SchoolID = LogInSchoolID
        }
        var raw = JSON.stringify({
            schoolid: SchoolID,
            userid: LogInUserID,
            gridid: gridid,
            headers: concatenatedString
        });
        setLoading(true);
        await ApiPostCall("/saveHeaders", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={'Column preferences saved successfully'} />);
                    setTimeout(function () {
                        props.setCustomizationModal(false)
                        props.GetCall();
                    }, 2000);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    function handleDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const newHeaderData = Array.from(headerData);
        const [reorderedItem] = newHeaderData.splice(result.source.index, 1);
        newHeaderData.splice(result.destination.index, 0, reorderedItem);
        setHeaderData(newHeaderData);
    }
    const modalbody = (
        <>
            <div className='col-12 text-justify mb-2'>Please note that keeping too many columns active might make horizontal scroll appear which is not a good user experience. We recommend to keep limited number of preferred columns actively visible.</div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="headerData">
                    {(provided) => (
                        <div className='greyBox p-3 pb-5' {...provided.droppableProps} ref={provided.innerRef}>
                            <div className='row'>
                                <div className='col-9 cursor-pointer fw-600'>Header Name</div>
                                <div className='col-3 fw-600 text-end'>Is Visible?</div>
                            </div>
                            <hr />
                            {headerData.map((item, index) => {
                                var gridid = GetGridFlag()
                                var cloumnName = item.split('-');
                                var nameofcol = cloumnName[0].replace(/_/g, ' ');
                                var switchValue = cloumnName[1];
                                var isActive = switchValue == "1";
                                var extractedName = nameofcol.replace(/\(\d+\)/, '');;
                                var finalName = extractedName ? extractedName : nameofcol.trim();
                                return (
                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={`row my-2 greyBoxShadow m-0 cursor-pointer ${snapshot.isDragging ? 'dragged-shadow' : ''
                                                    }`}
                                            >
                                                <div className='col-9'>
                                                    <FontAwesomeIcon icon={faUpDownLeftRight} color='#6c6c6c' title='Move' className='pe-3' />
                                                    {CamelCaseWithSpaces(gridid == 40 ? finalName : nameofcol)}
                                                </div>
                                                <div className='col-3 text-end'>
                                                    <MDBSwitch
                                                        className='BgPink cursor-pointer'
                                                        name={cloumnName[0]}
                                                        checked={isActive}
                                                        onChange={(e) => handleChange(e, cloumnName[0])}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}

                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    )
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"md"} title={`Customize View for ${props?.gridname}`}
                closePopup={(e) => { props.setCustomizationModal(false) }}
                modalBody={modalbody} handleSave={handleGenerateJson} visibleSaveBtn={true} btnText={"Save"} />
        </>
    )
}
