import React, { useContext } from 'react'
import { useEffect } from 'react';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import { HideLoder, MMDDYYYY, ShowLoder, getUrlParameter } from '../../JS/Common';
import { useState } from 'react';
import AlertsComp from '../../Components/AlertsComp';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeviceDetailsPopup } from '../../Components/DeviceDetailsPopup';
import { UserContext } from '../../App';
import UserDetailsPopup from '../../Components/UserDetailsPopup';
import ReworkPopup from '../../Components/ReworkPopup';

function TicketHistoryPage() {
    var batchId = getUrlParameter("id");
    let newTotalAmounts = 0;
    const [AttachBatchdata, setAttachBatchdata] = useState([]);
    const [prices, setPrices] = useState({});
    const [NewAlerts, setNewAlerts] = useState('');
    const [loading, setLoading] = useState(false);
    const [Userid, setUserid] = useState('');
    const [UserModal, setUserModal] = useState(false);
    const location = useLocation();
    const [IsModal, setIsModal] = useState({
        isshowpopup: false,
        inventoryId: ''
    })
    const [BatchName, setBatchName] = useState('')
    const [ReworkModal, setReworkModal] = useState(false)
    const [TicketId, setTicketId] = useState('')
    const SchoolId = location.state && location.state.SchoolId;
    const FlagForSchoolAdmin = location.state && location.state.SchoolAdminFlag;
    const { UserData } = useContext(UserContext);
    const flagForSidemenu = UserData.MenuAccessFlag;
    const navigate = useNavigate();
    useEffect(() => {
        ApiGetCall("/DataForBatch/" + batchId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.batchdata.length != 0) {
                    setAttachBatchdata(responseRs.batchdata);
                } else {
                    setAttachBatchdata([]);
                }
                setBatchName(responseRs.batchname)
            }
        });
    }, [])

    async function UpdateAmountForInvoice() {
        setLoading(true);
        const finalArray = AttachBatchdata.map((item) => {
            const priceArray = item.Part.map((itemPart) => {
                const finalTicketId = item.TicketID;
                const finalPartId = itemPart.PartID;
                let price;
                if (prices[finalTicketId] && prices[finalTicketId][finalPartId] !== undefined) {
                    price = prices[finalTicketId][finalPartId] ? prices[finalTicketId][finalPartId] : 0;
                } else {
                    price = itemPart.PartPrice ? itemPart.PartPrice : 0;
                }
                return {
                    PartID: finalPartId,
                    Price: price ? price : 0,
                };
            });
            return {
                ticketId: item.TicketID,
                parts: priceArray,
            };
        });
        var raw = JSON.stringify({
            SchoolId: SchoolId,
            batchId: batchId,
            subtotal: newTotalAmounts,
            ticketData: finalArray
        });
        await ApiPostCall("/InvoicePaymentBatchAmountCheck", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = result;
                if (responseRs == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'Amount Updated Successfully'} />);
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />)
                        navigate('/admin-invoice')
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }

    function handleChangeforAmount(event, partId, ticketId) {
        let value = event.target.value;
        value = value.replace(/^0+/, '');
        if (!value) {
            value = "00";
        }
        const priceValue = value !== 0 ? value : 0;
        setPrices((prevPrices) => ({
            ...prevPrices,
            [ticketId]: {
                ...prevPrices[ticketId],
                [partId]: priceValue,
            },
        }));
        if (event.target.name === 'applyToAll') {
            handleApplyToAll(event, partId, priceValue);
        }
    }

    const handleApplyToAll = (ticketID, partID, price) => {
        const newPrice = parseFloat(prices[ticketID]?.[partID] || price);
        const updatedPrices = { ...prices };
        AttachBatchdata.forEach((item) => {
            item.Part.forEach((itemPart) => {
                if (itemPart.PartID === partID) {
                    if (!updatedPrices[item.TicketID]) {
                        updatedPrices[item.TicketID] = {};
                    }
                    updatedPrices[item.TicketID][itemPart.PartID] = newPrice;
                }
            });
        });
        setPrices(updatedPrices);
    };

    return (
        <>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row col-12 align-items-center pe-0'>
                <div className='col-md-6'>
                    <h1 className="PageHeading">Ticket History</h1>
                </div>
                <div className='col-md-6 text-end BatchNameHeading pe-0'>Batch Name : {BatchName}</div>
            </div>
            <div className="GridBox p-3">
                {
                    AttachBatchdata.length != 0 ?
                        AttachBatchdata?.map((item, i) => {
                            return <div className='row p-2'>
                                <div className='col-md-4 py-1'>
                                    <div className='greyBox'>
                                        <label className='fw-600' style={{ fontSize: "15px" }}>Ticket Details</label>
                                        <hr />
                                        <div className='px-2'>
                                            <div className='OddEvenClass row'>
                                                <div className='col-md-5'>
                                                    <label style={{ fontWeight: "600", color: "#4C5258" }}>Ticket # : </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    {item.TicketNum}
                                                    <img src="/images/InfoBtn.svg" title="Show Details" className="ps-3 cursor-pointer" height='16px'
                                                        onClick={(e) => { setTicketId(item.TicketID); setReworkModal(true) }} />
                                                </div>
                                            </div>
                                            <div className='OddEvenClass row'>
                                                <div className='col-md-5'>
                                                    <label style={{ fontWeight: "600", color: "#4C5258" }}>Serial # : </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    <u className='cursor-pointer wordwrap' onClick={(e) => setIsModal({ ...IsModal, isshowpopup: true, inventoryId: item.InventoryID })}>{item.SerialNum}</u>

                                                </div>
                                            </div>
                                            <div className='row OddEvenClass'>
                                                <div className='col-md-5'>
                                                    <label style={{ fontWeight: "600", color: "#4C5258" }}>Asset Tag : </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    {item.AssetTag || '-'}
                                                </div>
                                            </div>
                                            <div className='row OddEvenClass'>
                                                <div className='col-md-5'>
                                                    <label style={{ fontWeight: "600", color: "#4C5258" }}>User : </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    {item?.student?.device_user_first_name ?
                                                        <u className='cursor-pointer wordwrap' onClick={(e) => { setUserid(''); setUserModal(true); setUserid(item?.student?.id) }}>
                                                            {(item?.student?.device_user_first_name || '-') + ' ' + (item?.student?.device_user_middle_name || '') + ' ' + (item?.student?.device_user_last_name || '')}
                                                        </u>
                                                        :
                                                        "-"
                                                    }

                                                </div>
                                            </div>
                                            <div className='OddEvenClass row'>
                                                <div className='col-md-5'>
                                                    <label style={{ fontWeight: "600", color: "#4C5258" }}>Created Date : </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    {MMDDYYYY(item.CreatedDate)}
                                                </div>
                                            </div>
                                            <div className='row OddEvenClass'>
                                                <div className='col-md-5'>
                                                    <label style={{ fontWeight: "600", color: "#4C5258" }}>Created By : </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    {item.CreatedBy}
                                                </div>
                                            </div>
                                            <div className='OddEvenClass row'>
                                                <div className='col-md-5'>
                                                    <label style={{ fontWeight: "600", color: "#4C5258" }}>Repair Notes : </label>
                                                </div>
                                                <div className='col-md-7 wordwrap'>
                                                    {item.RepaiedNotes || '-'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8 py-1'>
                                    <div className='greyBox data-container'>
                                        <div className='col-12'  >
                                            <div className='row mt-1'>
                                                <div className={`${flagForSidemenu == 5 ? 'col-md-7' : 'col-md-9'} fw-600`}>Part Name</div>
                                                <div className='col-md-3 fw-600 text-center'>Price</div>
                                                {flagForSidemenu == 5 && (<div className='col-md-2 fw-600 text-center'>Action</div>)}
                                            </div>
                                            <hr />
                                            {item.Part.length != 0 ?
                                                <> {item.Part.map((itemPart, i) => {
                                                    var returData;
                                                    const totalAmount = (prices[item.TicketID]?.[itemPart.PartID]);
                                                    newTotalAmounts += parseFloat(isNaN(totalAmount) ? itemPart.PartPrice : totalAmount); // Update the totalAmounts value
                                                    returData = (
                                                        <div key={i} style={{ backgroundColor: "white" }} className='p-2 my-3'>
                                                            <div className={`row CommonClassParts_${item.TicketID}`} key={i} idForPartID={`PartsNoteId_${itemPart.PartID}`}>
                                                                <div className={`${itemPart.Parts_Covered_by == 1 ? 'highlight-part' : ''}  ${flagForSidemenu == 5 ? 'col-md-7' : 'col-md-9'} pt-1`}>{itemPart.PartName}</div>
                                                                <div className="col-md-3 text-center">
                                                                    <div className="input-group">
                                                                        <span className="input-group-text">$</span>
                                                                        {item.BatchStatus == "Success" || FlagForSchoolAdmin == 2 ?
                                                                            <input
                                                                                className="form-control CommonClass"
                                                                                autoComplete="off"
                                                                                type="number"
                                                                                name="notes"
                                                                                id={`Notes_${itemPart.PartID}`}
                                                                                defaultValue={itemPart.PartPrice}
                                                                                value={prices[item.TicketID] && prices[item.TicketID][itemPart.PartID] !== undefined ? prices[item.TicketID][itemPart.PartID] : itemPart.PartPrice}
                                                                                onChange={(event) => handleChangeforAmount(event, itemPart.PartID, item.TicketID)}
                                                                                disabled
                                                                                min={0}
                                                                            />
                                                                            :
                                                                            <input
                                                                                className="form-control CommonClass"
                                                                                autoComplete="off"
                                                                                type="number"
                                                                                name="notes"
                                                                                id={`Notes_${itemPart.PartID}`}
                                                                                defaultValue={itemPart.PartPrice}
                                                                                value={prices[item.TicketID] && prices[item.TicketID][itemPart.PartID] !== undefined ? prices[item.TicketID][itemPart.PartID] : itemPart.PartPrice}
                                                                                onChange={(event) => handleChangeforAmount(event, itemPart.PartID, item.TicketID)}
                                                                                min={0}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {flagForSidemenu == 5 && (<div
                                                                    className='col-md-2 fw-600 text-center'
                                                                    onClick={() => handleApplyToAll(item.TicketID, itemPart.PartID, itemPart.PartPrice)}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: '#3CBBA5'
                                                                    }}
                                                                    title='Apply to All'
                                                                >
                                                                    <u>Apply to All</u>
                                                                </div>)}

                                                            </div>
                                                            <div className='col-md-12 pt-1'>
                                                                <label style={{ color: "#4C5258", fontWeight: "600" }}>Part Notes : </label>&nbsp;{itemPart.PartNote || '-'}
                                                            </div>
                                                        </div>
                                                    );
                                                    return returData;
                                                })}
                                                </>
                                                :
                                                <div className="col-md-12 text-center p-3">
                                                    <img src='/images/NoPartsFound.svg' className='img-fluid' />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                        :
                        <div className='text-center col-md-12'>No Record Found</div>
                }
                <div className='col-12 text-center p-3' >
                    <h5 className='fw-600 '>Total : ${newTotalAmounts.toFixed(2)}</h5>
                </div>

                <div className="col-12 d-flex justify-content-center align-items-center text-center pt-3">
                    {UserData.MenuAccessFlag != 6 && FlagForSchoolAdmin != 2 ?
                        <>
                            <div className="text-center">
                                <button className="SaveBtn" onClick={UpdateAmountForInvoice}>Update Amount</button>
                            </div>
                        </>
                        : <></>
                    }
                    {FlagForSchoolAdmin != 2 ?
                        <label className='ms-2 cursor-pointer' onClick={() => navigate('/admin-invoice')}><u>Cancel</u></label>
                        :
                        <label className='ms-2 cursor-pointer' onClick={() => navigate('/school-invoice')}><u>Cancel</u></label>
                    }
                </div>
            </div>

            {IsModal.isshowpopup == true && (
                <DeviceDetailsPopup Showbuttons={0} createticketbutton={false} deviceid={IsModal.inventoryId} activetab="device" isShow={IsModal.isshowpopup} ClosePopup={(e) => setIsModal({ ...IsModal, isshowpopup: false })} />
            )}
            {UserModal == true && (
                <UserDetailsPopup isShow={UserModal} ClosePopup={(e) => setUserModal(false)} userid={Userid} />
            )}
            {ReworkModal == true && (
                <ReworkPopup isShow={ReworkModal} ClosePopup={(e) => setReworkModal(false)} ticketid={TicketId} />
            )}
        </>
    )
}

export default TicketHistoryPage
