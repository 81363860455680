import Cookies from 'js-cookie';
import { LogInSchoolID, LogInUserID, RemoveAllCookies } from './Common';
var ciphertext = Cookies.get('ciphertext');
var authkey = process.env.REACT_APP_AuthKey;
const BaseUrl = process.env.REACT_APP_Base_URL;
var myHeaders = new Headers();
const pathname = window.location.pathname;
const pathArray = pathname.split('/');
const pageUrl = pathArray[1];
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Access-Control-Allow-Origin", "*");
myHeaders.append("Authorization", ciphertext);
export function ApiGetCall(endpoint, signal = null) {
    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        ...(signal != null && { signal: signal })
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
        .then((response) => {
            if (response.status === 401) {
                RemoveAllCookies();
                window.location.href = "/";
            }
            return response.text();
        })
        .then((result) => { return result })
        .catch((error) => { return error; });
}
export function ApiPostCall(endpoint, payload) {
    var urlencoded = payload;
    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
        .then((response) => response.text())
        .then((result) => { return result })
        .catch((error) => { return error });
}

var myHeadersAuthToken = new Headers();
myHeadersAuthToken.append("Content-Type", "application/json");
myHeadersAuthToken.append("Access-Control-Allow-Origin", "*");
myHeadersAuthToken.append("Authorization", authkey);
export function ApiGetCallAuthKey(endpoint, signal = null) {
    var requestOptions = {
        method: "GET",
        headers: myHeadersAuthToken,
        redirect: "follow",
        ...(signal != null && { signal: signal })
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
        .then((response) => {
            if (response.status === 401) {
                RemoveAllCookies();
                window.location.href = "/";
            }
            return response.text();
        })
        .then((result) => { return result })
        .catch((error) => { return error; });
}
export function ApiPostCallAuthKey(endpoint, payload) {
    var urlencoded = payload;
    var requestOptions = {
        method: "POST",
        headers: myHeadersAuthToken,
        body: urlencoded,
        redirect: "follow",
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
        .then((response) => response.text())
        .then((result) => { return result })
        .catch((error) => { return error });
}

export const fetchAdminData = async (endpoint, dataKey, setAllData, signal = null) => {
    setAllData((prevState) => ({
        ...prevState,
        [dataKey]: { ...prevState?.[dataKey], loader: true },
    }));
    try {
        const result = await ApiGetCall(endpoint, signal);
        if (result == undefined || result == "") {
            alert("Something went wrong");
        } else {
            const responseRs = JSON.parse(result);
            setAllData((prevState) => ({
                ...prevState,
                [dataKey]: { ...prevState?.[dataKey], data: responseRs[dataKey], loader: false }
            }));
            return result;
        }
    } catch (error) {
        setAllData((prevState) => ({
            ...prevState,
            [dataKey]: { ...prevState?.[dataKey], data: [], loader: false }
        }));
        return error
    }

};
export async function ApiGetHeaders(gridid, setColumnNames) {
    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };
    var SchoolID = null
    if (pageUrl == 'incoming-batches' || pageUrl == 'admin-invoice' || pageUrl == 'school-tickets') {
        SchoolID = Cookies.get('AdminSchoolId');
    } else {
        SchoolID = LogInSchoolID
    }
    return await fetch(`${BaseUrl}/GetGridHeaders/${gridid}&${LogInUserID}&${SchoolID}`, requestOptions)
        .then((response) => {
            if (response.status === 401) {
                RemoveAllCookies();
                window.location.href = "/";
            }
            return response.text();
        })
        .then((result) => {
            const responseRs = JSON.parse(result);
            const columns = responseRs?.msg?.split('~');
            setColumnNames(columns);
        })
        .catch((error) => { return error; });
}
