import React, { useEffect, useState } from "react"
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import { CamelCaseWithSpaces, LogInUserID, MMDDYYYY, getUrlParameter, isValidEmail, typewatch } from "../../JS/Common";
import { MDBSwitch } from "mdb-react-ui-kit";
import AlertsComp from "../../Components/AlertsComp";
import { CheckValidation } from "../../Components/Validations";
import { Logout } from "../Signout";
import Pagination from "react-js-pagination";
import CustomizationPopup from "../../Components/CustomizationPopup";
import Deletepopup from "../../Components/Deletepopup";
import PageHead from "../../Components/PageHead";
import Popup from "../../Components/Popup";
export function Technician() {
    const [isTechnicianPopup, setisTechnicianPopup] = useState(false);
    const [ValidEmail, setValidEmail] = useState("");
    const [isShow, setisShow] = useState(false);
    const [allData, setAllData] = useState({
        firstname: "",
        lastname: "",
        access: 0,
        email: "",
        flag: 1,
        Memberid: "",
        UserId: "",
        searchstring: '',
        sortByflag: 'desc',
        allowmailin: 0
    });
    const [AccessDeviceForTech, setAccessDeviceForTech] = useState([]);
    const [Locations, setLocations] = useState([]);
    const [Users, setUsers] = useState([]);
    const [Alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const showPagination = Users.length > 0;
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [deletePopup, setDeletePopup] = useState(false);
    const [Techid, setTechid] = useState('')
    const [SortKey, setSortKey] = useState(null)
    useEffect(() => {
        ApiGetHeaders(33, setColumnNames)
    }, []);

    useEffect(() => {
        GetAccessDeviceForTech(allData.searchstring ? allData.searchstring : null, SortKey || null);
        ApiGetCall("/allLocation").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length != 0) {
                    setLocations(responseRs);
                }
            }
        });
        if (getUrlParameter("flag") == "1") {
            setTimeout(function () {
                setAllData({ ...allData, access: 5 });
            }, 500);
        }
    }, [currentPage, LimitForPagination]);
    async function GetAccessDeviceForTech(searchString, skey) {
        if (!searchString) searchString = null;
        if (skey) allData.sortByflag = allData.sortByflag == "asc" ? "desc" : "asc";
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/allK12User/" + searchString + '&' + skey + '&' + allData.sortByflag + '&' + currentPage + '&' + LimitForPagination + '&' + LogInUserID).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    setAccessDeviceForTech(responseRs.access);
                    setpageRangeCount(responseRs.msg.total)
                    setUsers(responseRs.msg.data.length ? responseRs.msg.data : []);
                }
            });
        }, 500);
    }
    function DeleteTechbyid(memberid, flag, Userid, e) {
        const { name, checked } = e.target;
        let tempuser = Users.map(user =>
            user.name === name ? { ...user, isChecked: checked } : user
        );
        setUsers(tempuser);
        setAllData({ ...allData, Memberid: memberid, Userid: Userid })
        if (flag == '1') {
            setisShow(true);
        } else {
            DeactivateTech(memberid, '1', Userid);
        }
    }
    async function DeactivateTech(Memberid, flag, Userid) {
        var loginuserid = LogInUserID;
        setLoading(true);
        await ApiGetCall("/deleteK12User/" + Memberid + "&" + flag).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setisShow(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    var msg = flag == 1 ? 'Technician Activated Successfully.' : 'Technician Deactivated Successfully.';
                    setAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    if (loginuserid == Userid) {
                        Logout();
                    } else {
                        GetAccessDeviceForTech(null, null);
                        setTimeout(() => {
                            setAlerts(<AlertsComp show={false} />)
                        }, 2000);
                    }

                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        })
    }
    async function GetUserbyidForTech(itemid) {
        setLoading(true);
        await ApiGetCall("/K12UserData/" + itemid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                setisTechnicianPopup(true);
                if (responseRs.msg.locationName != null) {
                    const updatedLocations = Locations.map((item) => {
                        if (responseRs.msg.locationName.find((loc) => loc.id === item.id)) {
                            return { ...item, isChecked: true, flag: 1 };
                        }
                        return { ...item, isChecked: false, flag: 0 }; // Reset the isChecked and flag properties
                    });
                    setLocations(updatedLocations);
                }
                setAllData({
                    ...allData, firstname: responseRs.msg.first_name, lastname: responseRs.msg.last_name,
                    email: responseRs.msg.email, access: responseRs.msg.access_type, flag: 2, Memberid: itemid, allowmailin: responseRs.msg.mailin_flag
                })
            }
        });
    }
    async function SaveTechnician(flag) {
        var LocationArray = [];
        if (allData.access == 6) {
            Locations.map((item) => {
                var vjson = {};
                if (item.flag) {
                    if (item.flag != 0) {
                        vjson['id'] = item.id
                        vjson['flag'] = item.flag
                        LocationArray.push(vjson)
                    }
                }
            });
        }
        var isFormValid = CheckValidation({ formID: 'UserForm' });
        if (allData.email != '') {
            if (!isValidEmail(allData.email)) {
                setValidEmail("Enter Valid Email");
                isFormValid = false;
            }
        }
        if (!isFormValid) return;
        var memberid = 0;
        if (flag == 2) {
            memberid = allData.Memberid;
        }
        var raw = JSON.stringify({
            firstname: allData.firstname,
            lastname: allData.lastname,
            access: allData.access,
            email: allData.email,
            id: memberid,
            location: LocationArray,
            mailin_flag: allData.allowmailin
        });
        setLoading(true);
        await ApiPostCall("/addUpdateK12User", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setisTechnicianPopup(false);
                    var msg = allData.flag == 1 ? 'Technicians Added Successfully.' : 'Technicians Updated Successfully.';
                    setAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    GetAccessDeviceForTech(allData.searchstring ? allData.searchstring : null, null);
                    setAllData({ ...allData, firstname: '', lastname: '', access: 0, email: '', flag: 1, });
                    ChangeAccess();
                    setValidEmail('');
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1000);
                }
            }
        });
    }
    function handleChange(e) {
        const { name, checked } = e.target;
        let tempUser = Locations.map(item => item.name == name ? { ...item, isChecked: checked, flag: checked ? 1 : 3 } : item);
        setLocations(tempUser);
    }
    function handleMailinChange(e) {
        if (e.target.checked) {
            setAllData({ ...allData, allowmailin: 1 })
        } else {
            setAllData({ ...allData, allowmailin: 0 })
        }
    }
    function ChangeAccess() {
        let tempUser = Locations.map(item => item.name ? { ...item, isChecked: false } : item);
        setLocations(tempUser);
    }
    const handleSortClick = (item) => {
        if (item == "Access") {
            item = "access_type";
        }
        if (item !== 0) {
            GetAccessDeviceForTech(allData.searchstring, item)
        }
        setSortKey(item)
    };
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "Name": item.first_name + ' ' + item.last_name || '-',
            "Access": item.accessname || '-',
            "email": item.email || '-',
            "location": <div className="address">{item.locationName || '-'}</div>,
            "Status": <span>
                {item.status == 1 ?
                    <MDBSwitch checked={item?.isChecked || true} className="BgPink cursor-pointer" onChange={(e) => { DeleteTechbyid(item.id, "1", item.userid, e) }} />
                    :
                    <MDBSwitch checked={item?.isChecked || false} className="BgPink cursor-pointer" onChange={(e) => { DeleteTechbyid(item.id, "2", item.userid, e) }} />
                }
            </span>,
            "created_at": item?.created_at == null ? <>-</> : MMDDYYYY(item?.created_at),
        };
        return contentMap[columnName] || null;
    }
    async function DeleteTech() {
        setLoading(true);
        await ApiPostCall("/deleteTechnician/" + Techid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setDeletePopup(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Technician Deleted Succesfully"} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                        GetAccessDeviceForTech(allData.searchstring ? allData.searchstring : null, null);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        GetAccessDeviceForTech(e.target.value, null);
        setAllData({ ...allData, searchstring: e.target.value });
    }
    const ModalBody = (
        <div className="row parent" id="UserForm">
            <div className='col-md-6 py-2'>
                <div className='FormLabel'>First Name<label className='redText'>*</label></div>
                <input type="text" name='firstname' autoComplete="off" className="form-control mt-2" required value={allData.firstname}
                    onChange={(e) => setAllData({ ...allData, firstname: e.target.value })} />
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            <div className='col-md-6 py-2'>
                <div className='FormLabel'>Last Name<label className='redText'>*</label></div>
                <input type="text" name='firstname' autoComplete="off" className="form-control mt-2" required value={allData.lastname}
                    onChange={(e) => setAllData({ ...allData, lastname: e.target.value })} />
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            <div className="col-md-6 py-2">
                <div className='FormLabel'>Access<label className='redText'>*</label></div>
                <select required className="mt-2" value={allData.access} onChange={(e) => { setAllData({ ...allData, access: e.target.value }); ChangeAccess(); }}>
                    <option value="0">Select </option>
                    {AccessDeviceForTech.map((item, i) => {
                        var returData;
                        returData = (
                            <option value={item.id} key={i} title={item.defination} >{item.access_type} </option>
                        );
                        return returData;
                    })}
                </select>
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            <div className='col-md-6 py-2'>
                <div className='FormLabel'>Email<label className='redText'>*</label></div>
                <input type="email" name='Email' autoComplete="off" required className="form-control mt-2" value={allData.email}
                    onChange={(e) => setAllData({ ...allData, email: e.target.value })} />
                <span className="Emailinvalid">{ValidEmail}</span>
                <span className="form-text invalid-feedback"> *required</span>
            </div>
            {allData.access == 6 && (
                <div className="col-12">
                    <hr />
                    <div className="col-12 pb-2">
                        <input type="checkbox" className="form-check-input me-2" name="allmailinschool" checked={allData.allowmailin == 1} onChange={handleMailinChange} />
                        <b>Enable access for all Mail-In schools?</b>
                    </div>
                    <div className='col-12 py-2'>
                        <b>Location</b>
                        <div className="row">
                            {Locations.map((item, i) => {
                                return <div className="col-md-6 py-2">
                                    <input type="checkbox" className="form-check-input me-2" onChange={handleChange} name={item.name} checked={item?.isChecked || false} flag={0} />
                                    {item.name}
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Technicians"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="GridBox px-3 mt-2 py-2">
                <div className='row justify-content-end'>
                    <div className="col-md-3 my-2 text-end">
                        <label className='BorderBtn text-center marginDetailsPopup' title='Add Technician' onClick={(e) => { ChangeAccess(); setisTechnicianPopup(true); setAllData({ ...allData, firstname: '', lastname: '', access: 0, email: '', flag: 1 }) }}>
                            <img src='/images/AddInventory.svg' className='img-fluid pe-2' alt='Add Technician' />
                            Add Technician
                        </label>
                    </div>
                </div>
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                {columnNames?.map((item) => {
                                    const columnName = item.replace(/_/g, ' ');
                                    return (
                                        item != "location" ?
                                            <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                                {CamelCaseWithSpaces(columnName)}
                                                <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                            </th>
                                            :
                                            <th key={item} scope="col" className='fw-600'>
                                                {CamelCaseWithSpaces(columnName)}
                                            </th>
                                    );
                                })}
                                <th scope="col" className='text-center fw-600'>Status </th>
                                <th scope="col" className='text-start fw-600'>Action  <img src="/images/EditHeaderIcon.svg" className="cursor-pointer  img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Users.length != 0 ? (
                                Users?.map((item, i) => {
                                    return (
                                        <tr key={i} className={`OddEvenClass tableBorderHide ${item.flag}`}>
                                            {columnNames?.map(header => (
                                                <td className="py-12" key={header}>
                                                    {renderContentForColumn(header, item)}
                                                </td>
                                            ))}
                                            <td className="text-center py-12">
                                                {item.status == "1" ?
                                                    <MDBSwitch checked={item?.isChecked || true} className="BgPink cursor-pointer" onChange={(e) => { DeleteTechbyid(item.id, "1", item.userid, e) }} />
                                                    :
                                                    <MDBSwitch checked={item?.isChecked || false} className="BgPink cursor-pointer" onChange={(e) => { DeleteTechbyid(item.id, "2", item.userid, e) }} />
                                                }
                                            </td>
                                            <td className="text-start py-12">
                                                {item.status == "1" ?
                                                    <img src="/images/EditIconNew.svg" title="Edit Technician" className="img-fluid cursor-pointer" onClick={(e) => GetUserbyidForTech(item.id)} techid={item.id} />
                                                    :
                                                    <img src="/images/DisableEditIcon.svg" className="img-fluid" techid={item.id} />
                                                }
                                                {item.access_type == 6 ? (
                                                    <img src="/images/DeleteIcon.svg" title="Delete Technician" style={{ height: "16px" }} className="img-fluid cursor-pointer px-2"
                                                        onClick={(e) => { setTechid(item.id); setDeletePopup(true) }} />
                                                ) : (<></>)}
                                            </td>
                                        </tr>
                                    )
                                }))
                                :
                                <tr className="text-center"><td colSpan={7}>No Record Found</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div>
            <Popup isshow={isShow} size={"md"} title={"Deactivate Technician"}
                closePopup={(e) => setisShow(false)}
                modalBody={"Are you sure Want to deactivate technician?"} handleSave={(e) => DeactivateTech(allData.Memberid, "2", allData.UserId)} visibleSaveBtn={true} btnText={"Yes"} />

            <Popup isshow={isTechnicianPopup} size={"md"} title={allData.flag == 1 ? 'Add Technician' : 'Edit Technician'}
                closePopup={(e) => { setisTechnicianPopup(false); setAllData({ ...allData, firstname: '', lastname: '', access: 0, email: '', flag: 1, }); ChangeAccess(); setValidEmail(''); }}
                modalBody={ModalBody} handleSave={(e) => allData.flag == 1 ? SaveTechnician(1) : SaveTechnician(2)} visibleSaveBtn={true} btnText={allData.flag == 1 ? 'Create Technician' : 'Update Technician'} />

            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Technicians'} GetCall={() => ApiGetHeaders(33, setColumnNames)} />
            )}

            <Deletepopup text={`You won't be able to revert this!`} deletePopup={deletePopup} size="xl" deletePart={DeleteTech} DeleteShowClose={(e) => setDeletePopup(false)} />
        </React.Fragment>
    )
}
