import React from 'react'
import { ApiGetCall } from '../../../JS/Connector';
import { HideLoder, LogInSchoolID, ShowLoder } from '../../../JS/Common';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export default function Exporttickets() {
    const [loading, setLoading] = useState(false);
    const [CsvDataForRepairTicket, setCsvDataForRepairTicket] = useState([]);
    const [divVisibleForExport, setDivVisibleForExport] = useState({
        forTicket: false,
        forTicketNoReocrd: false
    });
    async function ExportRepairTicket() {
        setLoading(true);
        await ApiGetCall("/exportTickets/" + LogInSchoolID + '&' + 4).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length > 0) {
                    setCsvDataForRepairTicket(responseRs);
                    setDivVisibleForExport({ ...divVisibleForExport, forTicket: true });
                } else {
                    setDivVisibleForExport({ ...divVisibleForExport, forTicketNoReocrd: true });
                }
                setLoading(false);
            }
        });
    }
    function RemoveRepairTicketCsv() {
        setCsvDataForRepairTicket([]);
        setDivVisibleForExport({ ...divVisibleForExport, forTicket: false });
    }

    const DownloadCsv = () => {
        const csvContent = [];
        const buildingMap = [];
        CsvDataForRepairTicket?.forEach((ticket) => {
            const rowData = {
                'Ticket Number': ticket.ticket_num,
                'Serial Number': ticket?.inventory_management?.serial_number,
                'Student Number': ticket?.inventory_management?.student_inventory?.student?.student_num,
                'User First Name': ticket?.inventory_management?.student_inventory?.student?.device_user_first_name,
                'User Middle Name': ticket?.inventory_management?.student_inventory?.student?.device_user_middle_name,
                'User Last Name': ticket?.inventory_management?.student_inventory?.student?.device_user_last_name,
                'Ticket Created By': ticket?.user?.first_name + ' ' + ticket?.user?.last_name,
                'Ticket Status': `"${ticket?.status?.status?.replace(/\n/g, '-')}"`,
                'Grade': ticket?.inventory_management?.student_inventory?.student?.grade,
                'Department': ticket?.inventory_management?.student_inventory?.student?.department,
                'Building': ticket?.inventory_management?.building_name?.name,
                'Return Location': ticket?.returnLocationName,
                'Notes': `"${ticket?.notes?.replace(/\n/g, ' ')}"`,
                'Created Date': ticket.CreatedDate ? ticket.CreatedDate : '',
                'Closed Date': ticket.closedDate ? ticket.closedDate : '',
                'Issues': `"${ticket?.issues.join(", ")}"`,
                'Parts': `"${ticket?.parts.map((itemm) => `${itemm.partName} ($${itemm.partPrice})`).join(", ")}"`,
                'Total Cost': `$${ticket?.subtotal}`
            };
            buildingMap.push(rowData);
        });

        if (buildingMap.length > 0) {
            const headers = ['Ticket Number', 'Serial Number', 'Student Number', 'User First Name', 'User Middle Name', 'User Last Name', 'Ticket Created By', 'Ticket Status',
                'Grade', 'Department', 'Building', 'Return Location', 'Notes', 'Created Date', 'Closed Date', 'Issues', 'Parts', 'Total Cost'];
            csvContent.push(headers);
            buildingMap.forEach((rowData) => {
                const values = Object.values(rowData);
                csvContent.push(values);
            });
            csvContent.push([]);
            const csvString = csvContent.map((row) => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Tickets.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No data available for download.');
        }
    };

    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Export Tickets</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    To efficiently export all of your tickets, utilize the option below, which allows you to download them in a single CSV file. This streamlined process ensures you have easy access to all your ticket data.
                </p>
                <div className="row pt-3 pb-5 px-3">
                    <div className="col-12 px-0 py-2">
                        <label className='BorderBtnForimportExport' onClick={ExportRepairTicket}>Export Tickets
                            <img src='/images/ExportInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                    {divVisibleForExport.forTicket &&
                        <div className="row pt-2">
                            <div>Your Repair Ticket CSV file is now ready for download. Please click the button below to initiate the file download.</div>
                            <div className="col-12 mt-1 text-center">
                                <label style={{ fontSize: "12px", color: "#04ADFD" }} id="ExportedStudentFileName">
                                    Tickets.csv
                                </label>
                                <button className='SaveBtn WhiteFont ms-4' onClick={DownloadCsv}>Download</button>
                                <FontAwesomeIcon icon={faCircleXmark} className="cursor-pointer ps-3" onClick={RemoveRepairTicketCsv} />
                            </div>
                        </div>
                    }
                    {divVisibleForExport.forTicketNoReocrd &&
                        <div className="row pt-2" >
                            <div className="text-danger">No Record Found!</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
