import React, { useState, useEffect, useContext } from 'react'
import { TableDataContext } from '../index';
import { DeviceDetailsPopup } from '../../../Components/DeviceDetailsPopup';
import TicketDetailsPopup from '../../../Components/TicketDetailsPopup';

export default function TicketTable(props) {
    const { AllDashBoardData } = useContext(TableDataContext);
    const [TicketsStatus, setTicketsStatus] = useState('open')
    const [loadData, setLoadData] = useState({ opentickets: [], repairedtickets: [], show: false })
    const [DeviceTicketData, setDeviceTicketData] = useState({
        deviceid: "",
        ticketid: ""
    });
    const [isdevicepopup, setisdevicepopup] = useState(false);
    const [isticketpopup, setisticketpopup] = useState(false);
    useEffect(() => {
        if (AllDashBoardData?.DeviceTickets?.data?.opentickets?.length > 0) {
            let loadTicketData = loadInitialData(AllDashBoardData?.DeviceTickets?.data?.opentickets)
            setLoadData((prevState) => ({
                ...prevState, opentickets: loadTicketData
            }));
        }
        if (AllDashBoardData?.DeviceTickets?.data?.repairedtickets?.length > 0) {
            let loadTicketData = loadInitialData(AllDashBoardData?.DeviceTickets?.data?.repairedtickets)
            setLoadData((prevState) => ({
                ...prevState, repairedtickets: loadTicketData
            }));
        }

    }, [AllDashBoardData?.DeviceTickets?.data]);

    const loadInitialData = (data, maxItems = 20) => {
        if (data?.length > maxItems) {
            return data.slice(0, maxItems);
        } else {
            return data;
        }
    };
    const LoadMore = () => {
        let newData = []
        if (TicketsStatus == "open") {
            if (loadData?.opentickets?.length != AllDashBoardData?.DeviceTickets?.data?.opentickets?.length) {
                newData = loadData?.opentickets?.concat(AllDashBoardData?.DeviceTickets?.data?.opentickets?.slice(loadData?.opentickets?.length, loadData?.opentickets?.length + 20));
            } else {
                newData = [].concat(AllDashBoardData?.DeviceTickets?.data?.opentickets?.slice(0, 20))
            }
            setLoadData({ ...loadData, opentickets: newData });
        } else {
            if (loadData?.repairedtickets?.length != AllDashBoardData?.DeviceTickets?.data?.repairedtickets?.length) {
                newData = loadData?.repairedtickets?.concat(AllDashBoardData?.DeviceTickets?.data?.repairedtickets?.slice(loadData?.repairedtickets?.length, loadData?.repairedtickets?.length + 20));
            } else {
                newData = [].concat(AllDashBoardData?.DeviceTickets?.data?.repairedtickets?.slice(0, 20))
            }
            setLoadData({ ...loadData, repairedtickets: newData });
        }
    }
    return (
        <React.Fragment>
            <div className="col-12  greyBoxShadow" style={{ height: "100%" }}>
                <div className="w-100 ">
                    <div className="row mt-2 px-2 time" >
                        <div className='Header'>
                            <b className='font-15'>Device Tickets</b><br />
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row py-2">
                        <div className="col-6"> <input className="form-check-input me-1" value="open" type="radio" name='Tickets' checked={TicketsStatus === "open" || ""} onChange={(e) => setTicketsStatus(e.target.value)} />
                            <label className="form-check-label font-13 fw-bolder">
                                Open Tickets
                            </label></div>
                        <div className="col-6"> <input className="form-check-input me-1" value="close" type="radio" name='Tickets' checked={TicketsStatus === "close" || ""} onChange={(e) => setTicketsStatus(e.target.value)} />
                            <label className="form-check-label font-13 fw-bolder">
                                Repaired Tickets
                            </label></div>
                    </div>
                </div>
                <hr className="mt-2 mb-0" />
                {AllDashBoardData?.DeviceTickets?.loader ?
                    <div className='row'>
                        <div className="col-12 text-center pt-2">
                            <img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div>
                    </div> :
                    <div className="greyBox">
                        <div className="w-100 px-2">
                            <div className="row pb-2 brdr-Btm">
                                <div className="col-md-3 col-4 fw-600">Ticket #</div>
                                <div className="col-md-6 col-4 fw-600">Serial #</div>
                                <div className="col-md-3 col-4 fw-600">User</div>
                            </div>
                            {TicketsStatus === "open" ? <>
                                {AllDashBoardData?.DeviceTickets?.data?.opentickets?.length != 0 ?
                                    loadData?.opentickets?.map((item, i) => (
                                        <div className="row py-2 tableBorderHide OddEvenClass" key={item?.id}>
                                            <div className="col-md-3 py-1 cursor-pointer wordwrap" onClick={(e) => { setisticketpopup(true); setDeviceTicketData({ ...DeviceTicketData, ticketid: item.id, deviceid: item.inventory_id }) }}><u>{item.ticket_num}</u></div>
                                            <div className="col-md-6 py-1 cursor-pointer wordwrap" onClick={(e) => { setisdevicepopup(true); setDeviceTicketData({ ...DeviceTicketData, deviceid: item.inventory_id }) }}><u>{item.SerialNum}</u></div>
                                            <div className="col-md-3 py-1 wordwrap">{(item?.student?.device_user_first_name || '-') + ' ' + (item?.student?.device_user_last_name || '')}</div>
                                        </div>
                                    ))
                                    :
                                    <div className='text-center py-3'> No Record Found</div>
                                }
                            </> :
                                <>
                                    {AllDashBoardData?.DeviceTickets?.data?.repairedtickets?.length != 0 ?
                                        loadData?.repairedtickets?.map((item, i) => (
                                            <div className="row py-2 tableBorderHide OddEvenClass" key={item?.id}>
                                                <div className="col-md-3 py-1 cursor-pointer wordwrap" onClick={(e) => { setisticketpopup(true); setDeviceTicketData({ ...DeviceTicketData, ticketid: item.id, deviceid: item.inventory_id }) }}><u>{item.ticket_num}</u></div>
                                                <div className="col-md-6 py-1 cursor-pointer wordwrap" onClick={(e) => { setisdevicepopup(true); setDeviceTicketData({ ...DeviceTicketData, deviceid: item.inventory_id }) }}><u>{item.SerialNum}</u></div>
                                                <div className="col-md-3 py-1 wordwrap">{(item?.student?.device_user_first_name || '-') + ' ' + (item?.student?.device_user_last_name || '')}</div>
                                            </div>
                                        ))
                                        :
                                        <div className='text-center py-3'> No Record Found</div>

                                    }
                                </>
                            }
                        </div>

                        {TicketsStatus === "open" ? <>{AllDashBoardData?.DeviceTickets?.data?.opentickets?.length > 20 && <div className="col-12 p-2 admin-table-border-top">
                            <span style={{ textDecoration: "underline", cursor: "pointer", color: "#3CBBA5" }} onClick={() => LoadMore()}> {loadData?.opentickets?.length == AllDashBoardData?.DeviceTickets?.data?.opentickets?.length ? "Hide" : "Load More"}</span>
                        </div>}</> : <>{AllDashBoardData?.DeviceTickets?.data?.repairedtickets?.length > 20 && <div className="col-12 p-2 admin-table-border-top">
                            <span style={{ textDecoration: "underline", cursor: "pointer", color: "#3CBBA5" }} onClick={() => LoadMore()}> {loadData?.repairedtickets?.length == AllDashBoardData?.DeviceTickets?.data?.repairedtickets?.length ? "Hide" : "Load More"}</span>
                        </div>}</>}
                    </div>
                }
            </div>
            {isdevicepopup == true && (
                <DeviceDetailsPopup Showbuttons={1} ticketid={DeviceTicketData.ticketid} createticketbutton={true} deviceid={DeviceTicketData.deviceid} activetab="device" isShow={isdevicepopup} ClosePopup={(e) => setisdevicepopup(false)} />
            )
            }
            {
                isticketpopup == true && (
                    <TicketDetailsPopup isShow={isticketpopup} ticketid={DeviceTicketData.ticketid} inventoryid={DeviceTicketData.deviceid} ClosePopup={(e) => setisticketpopup(false)} />
                )
            }
        </React.Fragment>
    )
}
