import { useEffect, useState } from "react"
import { ApiGetCall, ApiPostCall } from "../../JS/Connector";
import { HideLoder, MMDDYYYY, ShowLoder, typewatch } from "../../JS/Common";
import AlertsComp from "../../Components/AlertsComp";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValidation } from "../../Components/Validations";
import Popup from "../../Components/Popup";
import { useNavigate } from "react-router-dom";
import Deletepopup from "../../Components/Deletepopup";

export function PricingActivation() {
    const [GridData, setGridData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [detailspopup, setdetailspopup] = useState(false);
    const [PlanDetails, setPlanDetails] = useState("");
    const [totalprice, setTotalPrice] = useState(0);
    const [Schoolid, setSchoolid] = useState("");
    const [planstatus, setplanstatus] = useState("");
    const [Alerts, setNewAlerts] = useState("");
    const [Schools, setSchools] = useState([]);
    const [SchoolValue, setSchoolValue] = useState('');
    const [searchstring, setsearchstring] = useState('')
    const [SortFlag, setSortFlag] = useState('')
    const [activeFilter, setActiveFilter] = useState(null);
    const [resetFilter, setResetFilter] = useState('d-none')
    const [Previewflag, setPreviewflag] = useState(1)
    const [UncoveredCharge, setUncoveredCharge] = useState(0);
    const [showdeletepopup, setshowdeletepopup] = useState(false);
    const navigate = useNavigate();
    const [deleteplanvalue, setdeleteplavalue] = useState({
        schoolid: '',
        planid: ''
    })
    useEffect(() => {
        ApiGetCall("/getSchoolsWithInsurancePlans").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setSchools(responseRs.msg.length ? responseRs.msg : [])
                setLoading(false);
            }
        });
        AllPlans(null, null, null)
    }, []);
    async function AllPlans(sclid, searchString, sortFlag) {
        if (!searchString) searchString = null;
        if (!sortFlag) sortFlag = null;
        if (!sclid) sclid = null;
        if (sortFlag != null) {
            setResetFilter('')
        } else {
            setResetFilter('d-none')
            setActiveFilter(null)
        }
        setSchoolid(sclid);
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/allPlansForAdmin/" + sclid + '&' + searchString + '&' + sortFlag).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    setGridData(responseRs.length ? responseRs : [])
                }
            });
        }, searchString != null ? 1000 : 0);
    }
    async function ShowPricingDetails(planid) {
        setdetailspopup(true);
        setPreviewflag(1);
        setLoading(true)
        await ApiGetCall("/getAllPlanForAdmin/" + planid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                setSchoolid(responseRs.school_id);
                setPlanDetails(responseRs);
                setUncoveredCharge(responseRs.uncovered_charge);
                setplanstatus(responseRs.status)
                setTotalPrice(responseRs.price || 0)
            }
        });
    }
    async function SavePricingDetails() {
        setLoading(true);
        var raw = JSON.stringify({
            SchoolId: Schoolid,
            PlanId: PlanDetails.id,
            UncoveredCharge: UncoveredCharge,
            PlanPrice: totalprice,
            PlanStatus: planstatus
        });
        await ApiPostCall("/setPlanServicesPrice", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Prices Updated Successfully."} />);
                    setdetailspopup(false);
                    setPlanDetails("");
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        AllPlans(null, null, null);
                    }, 2000);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    function FilterBySch(e) {
        if (e.target.value == 0) {
            AllPlans(null, null, null)
        } else {
            AllPlans(e.target.value, searchstring, SortFlag)
        }
    }
    function ContinueClick() {
        var isFormValid = CheckValidation({ formID: 'uncoveredchargediv' });
        if (!isFormValid) return;
    }
    const modalBody = (
        <div className="row">
            <div className="col-lg-6 py-2 pe-0 wordwrap">
                <div className="col-md-12  fw-600 font-13">Plan Name : </div>
                <div className="col-md-12 mb-0 schoolColorStatus h5"> {PlanDetails.plan_name}</div>
            </div>
            <div className="col-lg-6  py-2 pe-0 wordwrap">
                <div className="col-md-12 fw-600 font-13">Grades: </div>
                <div className="col-md-12"> {PlanDetails.grades}</div>
            </div>
            <div className="col-lg-6  py-2 pe-0 wordwrap">
                <div className="col-md-12 fw-600 font-13">Sign Up Start Date: </div>
                <div className="col-md-12"> {MMDDYYYY(PlanDetails.start_date)}</div>
            </div>
            <div className="col-lg-6  py-2 pe-0 wordwrap">
                <div className="col-md-12 fw-600 font-13">Sign Up End Date: </div>
                <div className="col-md-12"> {MMDDYYYY(PlanDetails.end_date)}</div>
            </div>
            <div className="col-lg-6  py-2 pe-0 wordwrap">
                <div className="col-md-12 fw-600 font-13">Coverage Start Date: </div>
                <div className="col-md-12"> {MMDDYYYY(PlanDetails.coverage_start_date)}</div>
            </div>
            <div className="col-lg-6  py-2 pe-0 wordwrap">
                <div className="col-md-12 fw-600 font-13">Coverage End Date: </div>
                <div className="col-md-12"> {MMDDYYYY(PlanDetails.coverage_end_date)}</div>
            </div>
            <div className="col-lg-6 py-2 pe-0 wordwrap">
                <div className="col-md-12  fw-600 font-13">School Contact Name  : </div>
                <div className="col-md-12"> {PlanDetails.contact_name}</div>
            </div>
            <div className="col-lg-6 py-2 pe-0 wordwrap">
                <div className="col-md-12  fw-600 font-13">School Contact Email : </div>
                <div className="col-md-12"> {PlanDetails.contact_email}</div>
            </div>
            <div className="col-lg-6 py-2 pe-0 wordwrap">
                <div className="col-md-12  fw-600 font-13">Estimated Student Enrollment  : </div>
                <div className="col-md-12"> {PlanDetails.estimated_enrollment}</div>
            </div>
            <div className="col-lg-6 py-2 pe-0 wordwrap">
                <div className="col-md-12  fw-600 font-13">Device Models Covered : </div>
                <div className="col-md-12">{PlanDetails.device_models} </div>
            </div>
            <div className="col-12  py-2 row pe-0 wordwrap">
                <div className="col-md-6 fw-600 font-13">Percentage of Devices Going Home with Students: </div>
                <div className="col-md-6"> {PlanDetails.devices_not_leave_school}&nbsp;%</div>
            </div>
            {PlanDetails.negotiated_price != null && planstatus != 7 && (
                <div className="col-12 row py-2 pe-0 wordwrap">
                    <div className="col-md-6  fw-600 font-13">Negotiated Prices : </div>
                    <div className="col-md-6 mb-0 h5" style={{ color: '#ff9385' }}> <b>${PlanDetails.negotiated_price}</b></div>
                </div>
            )}
            {PlanDetails.rejection_note != null && planstatus != 7 && (
                <div className="col-12 row py-2 pe-0 wordwrap">
                    <div className="col-md-6  fw-600 font-13">Reason for Rejection : </div>
                    <div className="col-md-6 mb-0 h5" style={{ color: '#ff9385' }}> <b>{PlanDetails.rejection_note}</b></div>
                </div>
            )}
            <div className="col-lg-12 row align-items-center py-1 parent" id="uncoveredchargediv">

                <div className={`col-12 row ${PlanDetails?.school?.uncovered_repair_charges == 1 ? '' : 'd-none'}`}>
                    <div className="col-md-9 redText">
                        What charges would you like to add for uncovered repairs?
                    </div>
                    <div className="col-md-3 ps-0">
                        <div className="input-group d-flex">
                            <span className="input-group-text">$</span>
                            <input
                                className="form-control"
                                autoComplete="off"
                                value={UncoveredCharge}
                                type="number"
                                name="uncoveredprice"
                                onChange={(e) => setUncoveredCharge(e.target.value)}
                                min={0}
                                required
                            />
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12 row pt-2">
                    <div className="col-md-9">
                        What charges would you like to add for Protection plan?
                    </div>
                    <div className="col-md-3 ps-0">
                        <div className="input-group d-flex">
                            <span className="input-group-text">$</span>
                            <input
                                className="form-control"
                                autoComplete="off"
                                value={totalprice}
                                type="number"
                                name="uncoveredprice"
                                onChange={(e) => setTotalPrice(e.target.value)}
                                min={0}
                                required
                                disabled={planstatus == 7}
                            />
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {Previewflag == 2 && (
                <div className="col-12 mt-3 mb-2 alignment-item-center d-flex justify-content-center zoom-in-out-box">
                    <span><FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#3bbba4", height: "100px", height: '18px' }} className="img-fluid" /></span>
                    <span className="signuplink ps-2">Before proceeding, please verify the prices and other relevant details.</span>
                </div>)}
        </div>
    )
    async function DeletePlan() {
        setLoading(true);
        var raw = "";
        await ApiPostCall("/deleteunfinishedplan/" + deleteplanvalue.schoolid + "&" + deleteplanvalue.planid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setshowdeletepopup(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Plan Deleted Successfully."} />);
                    setTimeout(() => {
                        AllPlans(null, null, null)
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="row pe-0 align-items-center mb-2 me-0">
                <div className="col-md-6">
                    <h1 className="PageHeading">Pricing and Activation Process</h1>
                </div>
                <div className='col-md-3 pe-0 text-end SearchBarDivforloner'>
                    <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                        <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                        <input className="form-control marginCss" autoComplete="off" type="text" placeholder="Search" onKeyUp={(e) => { AllPlans(SchoolValue, e.target.value, SortFlag); setsearchstring(e.target.value) }} />
                    </form>
                </div>
                <div className="col-md-3 text-end pe-0">
                    <div className=" ms-2 py-1 pe-0">
                        <select value={SchoolValue} onChange={(e) => { setSchoolValue(e.target.value == "0" ? null : e.target.value); FilterBySch(e); setSchoolid(SchoolValue); }}>
                            <option value="0">Filter by School</option>
                            {Schools.map((item, j) => (
                                <option value={item.id} key={j}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='GridBox p-3'>
                <div className="d-flex justify-content-between align-items-baseline">
                    <div><u className={`${resetFilter} cursor-pointer`} onClick={(e) => { AllPlans(SchoolValue, null, null); setSortFlag('') }}>Reset All Filter</u></div>
                    <div className="d-flex align-items-center pb-3 ">
                        <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 1 ? 'activePlanFilter' : ''}`}
                            title="Filter" onClick={(e) => { setActiveFilter(1); setSortFlag(1); AllPlans(SchoolValue, searchstring, 1) }}>
                            <div className="setup-status-square yellow-backgroung-style mx-3"></div><span>Newly Added</span>
                        </div>
                        <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 2 ? 'activePlanFilter' : ''}`}
                            title="Filter" onClick={(e) => { setActiveFilter(2); setSortFlag(2); AllPlans(SchoolValue, searchstring, 2) }}>
                            <div className="setup-status-square purple-backgroung-style mx-3"></div><span>Pending</span>
                        </div>
                        <div className={`cursor-pointer d-flex align-items-center ${activeFilter === 3 ? 'activePlanFilter' : ''}`}
                            title="Filter" onClick={(e) => { setActiveFilter(3); setSortFlag(3); AllPlans(SchoolValue, searchstring, 3) }}>
                            <div className="setup-status-square green-backgroung-style mx-3"></div><span>Live</span>
                        </div>
                        <div className={`cursor-pointer d-flex align-items-center ${activeFilter === 4 ? 'activePlanFilter' : ''}`}
                            title="Filter" onClick={(e) => { setActiveFilter(4); setSortFlag(4); AllPlans(SchoolValue, searchstring, 4) }}>
                            <div className="setup-status-square pink-backgroung-style mx-3"></div><span>Past</span>
                        </div>
                        <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 5 ? 'activePlanFilter' : ''}`}
                            title="Filter" onClick={(e) => { setActiveFilter(5); setSortFlag(5); AllPlans(SchoolValue, searchstring, 5) }}>
                            <div className="setup-status-square rejected-backgroung-style mx-3"></div><span>In Negotiation</span>
                        </div>
                        <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 6 ? 'activePlanFilter' : ''}`}
                            title="Filter" onClick={(e) => { setActiveFilter(6); setSortFlag(6); AllPlans(SchoolValue, searchstring, 6) }}>
                            <div className="setup-status-square brown-backgroung-style mx-3"></div><span>Rejected</span>
                        </div>
                        <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 7 ? 'activePlanFilter' : ''}`}
                            title="Filter" onClick={(e) => { setActiveFilter(7); setSortFlag(7); AllPlans(SchoolValue, searchstring, 7) }}>
                            <div className="setup-status-square blue-backgroung-style mx-3"></div><span>Live and Uncovered</span>
                        </div>
                    </div>
                </div >
                <div className="col-12 greyBox p-3">
                    <div className='row row-cols-1 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 g-4 g-lg-4'>
                        {GridData.length !== 0 ? (
                            GridData.map((item, i) => {
                                return (
                                    <div className='col position-relative' key={i}>
                                        <div className='p-4 GridBox boxshaowstyle' style={{ maxHeight: '100%', minHeight: '100%', backgroundColor: item.color_code }}>
                                            <div className="row align-items-center justify-content-between">
                                                <div className="col-7">
                                                    <h4 className='mb-0 mt-1 fw-600 planname-style' style={{ wordBreak: 'break-all', overflowWrap: 'break-word', maxWidth: '300px' }}>{item.plan_name}</h4>
                                                </div>
                                                <div className="col-5 px-0 text-end">
                                                    {item.status == 3 && (
                                                        <img src="/images/EditPlan.svg" className="cursor-pointer" title='Edit Pricing Details' style={{ float: 'right' }} height='17px' onClick={(e) => {
                                                            navigate(`/add-setup/?id=${item.id}`, { state: { fromPage: 'admin', schoolid: item.school_id } });
                                                        }} planid={item.id} />
                                                    )}
                                                    {item.status == 4 &&
                                                        <>
                                                            <img src="/images/EditPlan.svg" className="cursor-pointer me-2" title='Edit Pricing Details' height='17px' onClick={(e) => {
                                                                navigate(`/add-setup/?id=${item.id}`, { state: { fromPage: 'admin', schoolid: item.school_id } });
                                                            }} planid={item.id} />
                                                            <img src="/images/DeleteIcon.svg" title="Delete Plan" className="cursor-pointer"
                                                                height="17px" onClick={(e) => { setdeleteplavalue({ ...deleteplanvalue, schoolid: item.school_id, planid: item.id }); setshowdeletepopup(true) }} />
                                                        </>
                                                    }
                                                    {item.status != 3 && item.status != 4 && (
                                                        <>
                                                            {item.price != null && (
                                                                <div className="box-3 pe-0" onClick={(e) => ShowPricingDetails(item.id)}>
                                                                    <div className="set-price-btn text-center ">
                                                                        <span className="fw-600" planid={item.id}>Edit Prices</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item.price == null && (
                                                                <div className="box-3 pe-0" onClick={(e) => ShowPricingDetails(item.id)}>
                                                                    <div className="set-price-btn text-center ">
                                                                        <span className="fw-600" planid={item.id}>Set Prices</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='mt-3 content-forsetupcards h5 mb-0'><b>{item.school_name}</b></div>

                                            <div className='mt-2 content-forsetupcards'><span className="fw-600">Estimated Student :</span> {item.estimated_enrollment}</div>
                                            {item.status == 5 && (
                                                <div className='mt-2 content-forsetupcards'><span className="fw-600">Negotiated Prices :</span> {item.negotiated_price != null ? `$${item.negotiated_price}` : '-'}</div>
                                            )}
                                            <div className='mt-2 mb-5 content-forsetupcards' ><span className="fw-600">Grades : </span>{item.grades}  </div>
                                            <div className='h5 mb-3 total-price '><b className="">Total Prices :  {item.price != null ? `$${item.price}` : '$0'}</b></div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className='m-auto py-5'>
                                <div className="col-md-12 text-center h3">No Record Found</div>
                                <div className='m-auto py-5'>
                                    <img src='/images/setupplanicon.svg' className="img-fluid" />
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            </div >
            <Popup isshow={detailspopup} size={"lg"} title={"Pricing and Activation Process"}
                closePopup={(e) => { setdetailspopup(false); setPlanDetails("") }}
                modalBody={modalBody} handleSave={() => {
                    if (Previewflag === 1) {
                        ContinueClick();
                        setPreviewflag(2);
                    } else {
                        SavePricingDetails();
                    }
                }}
                visibleSaveBtn={true} btnText={Previewflag == 1 ? 'Continue' : 'Save'} />
            <Deletepopup text={`You won't be able to revert this!`} deletePopup={showdeletepopup} deletePart={DeletePlan} DeleteShowClose={(e) => setshowdeletepopup(false)}></Deletepopup>

        </>
    )
}
