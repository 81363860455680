import React, { useRef } from 'react'
import { useState } from 'react';
import { HideLoder, ShowLoder } from '../../../JS/Common';
import Cookies from 'js-cookie';
import AlertsComp from '../../../Components/AlertsComp';
import { useNavigate } from 'react-router-dom';

export default function ImportInventory() {
    const InventoryRef = useRef();
    const BaseUrl = process.env.REACT_APP_Base_URL;
    var ciphertext = Cookies.get('ciphertext');
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", ciphertext);
    const [loading, setLoading] = useState(false);
    const [NewAlerts, setNewAlerts] = useState("");
    const [FileName, setFileName] = useState("");
    const navigate = useNavigate();
    async function ImportCSV(e) {
        setLoading(true)
        e.preventDefault();
        var formdata = new FormData();
        const fileInput = InventoryRef.current;
        const files = fileInput.files[0];
        if (files == undefined) {
            setLoading(false)
            setFileName("First, import the CSV file.");
        } else {
            formdata.append("file", files);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };
            try {
                const response = await fetch(`${BaseUrl}/importMasterInventory`, requestOptions);
                const result = await response.text();
                setLoading(false);
                if (result === "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'CSV imported Successfully'} />);
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />);
                        navigate('/master-inventory')
                    }, 1500);

                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 2000);
                }
            } catch (error) {
                console.log('error', error);
            }
        }
    }
    function ShowFileName() {
        const fileInput = InventoryRef.current;
        const files = fileInput.files[0];
        const filename = files.name;
        var extension = filename.split('.').pop();
        if (extension == "csv") {
            setFileName(filename);
        } else {
            setFileName("You can only upload CSV files.");
        }
    }
    return (
        <div>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Import Master Inventory</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    To ensure that Master Inventories are entered properly with data accuracy, please download our CSV template file and ensure your columns match the template before you import your master inventory into the system.
                </p>
                <div className="row pt-3 px-3">
                    <label className="pb-4 ps-0" style={{ fontSize: "13px" }}><a href="/Csv/masterinventory.csv" style={{ color: "#04ADFD", fontWeight: "600" }}>CLICK HERE</a> to download the template CSV file for master inventory data. Please use this as a template and prepare your CSV file accordingly. Once your file is ready, use button below to browse file from your computer and click on upload button to import all data into your account. </label>
                    <div className="col-12">
                        <form onSubmit={(e) => ImportCSV(e)}>
                            <input type="file" ref={InventoryRef} name="upload_filewithoutstudent" id="UploadFileWithoutStudentId" accept='.csv' onChange={ShowFileName} />
                            <label className='ImportInventoryBtnForimportExport buttonclssMobile1' htmlFor="UploadFileWithoutStudentId"> Import Master Inventory
                                <img src='/images/ImportInventory.svg' className='img-fluid ps-2' />
                            </label>
                            {FileName != '' ?
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' style={{ backgroundColor: '#3CBBA5', color: '#fff' }} />
                                :
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' />
                            }
                        </form>
                        <div className='col-md-12 pe-0'>
                            <div className=" ps-2" style={{ fontWeight: "600", color: 'green' }}>{FileName}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
