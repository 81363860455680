import { useEffect } from "react";
import { useState } from "react";
import { ApiGetCall } from "../../JS/Connector";
import PageHead from "../../Components/PageHead";

export function FaqIndex() {
    const [faqData, setfaqData] = useState([]);
    useEffect(() => {
        ApiGetCall("/FaqData").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setfaqData(responseRs);
            }
        });
    }, []);
    return (
        <>
            <PageHead heading={"FAQs"} showsearch={false} />
            <div className='GridBox px-3'>
                <div className="row">
                    {faqData.map((faq, index) => (
                        <div className="col-md-4 d-flex py-3">
                            <div className="faqcard">
                                <p className="card-title mb-2">{faq.Que}</p>
                                <p className="small-desc text-justify">
                                    {faq.Ans}
                                </p>
                                <div className="go-corner">
                                    <div className="go-arrow">→</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
