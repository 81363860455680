import React, { useEffect, useLayoutEffect, useState, createContext, useRef } from "react"
import { ApiGetCall, ApiGetCallAuthKey, ApiPostCall, fetchAdminData } from "../../JS/Connector";
import { HideLoder, MMDDYYYY, ShowLoder, LogInSchoolID, LogInUserID } from "../../JS/Common";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import AlertsComp from "../../Components/AlertsComp";
import { TicketStatusGraph } from "./Containers/TicketStatusGraph";
import BreakageRate from "./Containers/BreakageRate";
import SupportTicket from "./Containers/SupportTicket";
import TicketCloserTime from "./Containers/TicketCloserTime";
import TicketTable from "./Containers/TicketTable";
import SupportTicketTable from "./Containers/SupportTicketTable";
import DueTable from "./Containers/DueTable";
import IncomingBatch from "./Containers/IncomingBatch";
import DeptAndEmpTicketClosure from "./Containers/DeptAndEmpTicketClosure";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TicketIssueType from "./Containers/TicketIssueType";
import NumberOfRepairs from "./Containers/NumberOfRepairs";
export const TableDataContext = createContext();
export function SchoolDashBoard() {
    const controller = new AbortController();
    const signal = controller.signal;
    const FilterRef = useRef(null);
    const ArrangeRef = useRef(null);
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);
    const [startDate, setStartDate] = useState(oneMonthAgo);
    const [endDate, setEndDate] = useState(today);
    const [Alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const [grade, setgrade] = useState(0);
    const [building, setbuilding] = useState(0);
    const [GradeArray, setGradeArray] = useState([]);
    const [BuildingArray, setBuildingArray] = useState([]);
    const [AllDashBoardData, setAllDashBoardData] = useState({
        DeviceTickets: { data: [], loader: false },
        SupportTickets: { data: [], loader: false },
        DueInvoice: { data: [], loader: false },
        incomingBatch: { data: [], loader: false },
        BreakageRate: { data: [], loader: false },
        SupportTicket: { data: [], loader: false },
        TicketsByStatus: { data: [], loader: false },
        TicketsByIssue: { data: [], loader: false },
        NumberOfRepairs: { data: [], loader: false },
        Breakageratebybuilding: { data: [], loader: false },
        Breakageratebygrade: { data: [], loader: false },
        Breakageratebyuser: { data: [], loader: false },
        actual: { data: [], loader: false },
        TechnicianClosureTickets: { data: [], loader: false },
        GetEstimation: { data: [], loader: false },
        FilterData: {
            sdate: "", edate: "", gradeid: null, buildingid: null
        },
    });
    const [OnLoadData, setOnLoadData] = useState({ LoadGraphs: false, LoadTables: false })
    const graphData = [
        {
            Position: "1", Div: "Graphs", Size: "row", Name: "Graphs",
            Data: [
                { Position: "a", Div: "BreakageRate", Size: "col-md-4 col-12", Name: "Breakage Rate" },
                { Position: "b", Div: "SupportTicket", Size: "col-md-4 col-12", Name: "Support Ticket" },
                { Position: "c", Div: "TicketCloserTime", Size: "col-md-4 col-12", Name: "Ticket closure time analytics" },
            ]
        },
        { Position: "2", Div: "TicketStatusGraph", Size: "col-md-12 col-12", Name: "Ticket status" },
        { Position: "3", Div: "DeptAndEmpTicketClosure", Size: "col-md-12 col-12", Name: "Ticket closure by techician" },
        { Position: "4", Div: "TicketIssueType", Size: "col-md-12 col-12", Name: "Tickets by Issue Type" },
        { Position: "5", Div: "NumberOfRepairs", Size: "col-md-12 col-12", Name: "Tickets Sent Out for Repaired by Building" },
    ]
    const tableData = [{ Position: "1", Div: "TicketTable", Size: "col-md-6 col-12", Name: "Device tickets" },
    { Position: "2", Div: "SupportTicketTable", Size: "col-md-6 col-12", Name: "Support open tickets" },
    { Position: "3", Div: "DueTable", Size: "col-md-6 col-12", Name: "Due invoices" },
    { Position: "4", Div: "IncomingBatch", Size: "col-md-6 col-12", Name: "Outgoing batches" },
    ]
    const [ViewData, setViewData] = useState({
        actualData: { GraphData: [], TableData: [] },
        GraphView: {
            data: [],
            tab: "active"
        },
        TableView: {
            data: [],
            tab: ""
        },
        filterView: {
            arrangeView: false,
            sortView: false
        },
    });

    useLayoutEffect(() => {

        var pageContentElement = document.querySelector('.page-content');
        pageContentElement.style.padding = '0rem';
        const GetDashboardSettingFun = async () => {
            await ApiGetCall("/GetDashboardSetting/" + LogInSchoolID + "&" + LogInUserID, signal).then((result) => {
                try {

                    if (result == undefined || result == "") {
                        alert("Something went wrong");
                    } else {
                        const responseRs = JSON.parse(result);
                        var newGraphArray = graphData
                        var newTableArray = tableData
                        var GraphPosition = responseRs?.msg?.graph?.Orders;
                        var TablePosition = responseRs?.msg?.table?.Orders;
                        if (GraphPosition != null) {
                            newGraphArray = []
                            var GraphPositionFirst = GraphPosition.split('~')
                            var newGraphSubArray = []
                            var actualGraphList = graphData
                            GraphPositionFirst?.forEach((item, i) => {
                                var itemIndex = item.includes("1") ? "1" : item
                                var elementWithPosition = actualGraphList.find(items => items.Position == itemIndex)
                                if (item.includes("1")) {
                                    var GetSmallGraphSubArray = item.split("^")[1].split("-");
                                    GetSmallGraphSubArray?.forEach((SubArrayItem, j) => {
                                        var elementWithPositionSubArray = elementWithPosition?.Data?.find(SubArrayItemIndex => SubArrayItemIndex.Position == SubArrayItem);
                                        if (elementWithPositionSubArray) {
                                            newGraphSubArray.push(elementWithPositionSubArray);
                                        }
                                    });
                                    if (elementWithPosition) {
                                        elementWithPosition.Data = newGraphSubArray;
                                        newGraphArray.push(elementWithPosition);
                                    }
                                } else {
                                    newGraphArray.push(elementWithPosition);
                                }
                            });
                        }

                        if (TablePosition != null) {
                            newTableArray = []
                            var TablePositionFirst = TablePosition.split('~')

                            var actualTableList = tableData
                            TablePositionFirst?.forEach((item, i) => {
                                var itemIndex = item
                                var elementWithPosition = actualTableList.find(items => items.Position == itemIndex)
                                newTableArray.push(elementWithPosition);
                            });
                        }

                        setViewData((prevState) => ({
                            ...prevState,
                            actualData: {
                                ...prevState?.actualData,
                                GraphData: newGraphArray,
                                TableData: newTableArray,
                            },
                            GraphView: {
                                ...prevState?.GraphView,
                                data: newGraphArray,
                            },
                            TableView: {
                                ...prevState?.TableView,
                                data: newTableArray,
                            },
                        }))
                        setLoading(false)
                        GetAllData(startDate, endDate, null, null, 0);
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    setLoading(false)
                }

            });
        }
        setLoading(true)
        GetDashboardSettingFun();
        GetBuildingGrade();
        GetEstimationFun();
        return () => controller.abort();
    }, []);
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (!isFirstRender.current) {
            GetAllData(startDate, endDate, grade, building, 0);
        } else {
            isFirstRender.current = false;
        }
    }, [ViewData?.GraphView?.tab, OnLoadData])

    useEffect(() => {
        function handleClickOutside(ref, viewProperty) {
            return function (event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setViewData((prevState) => ({
                        ...prevState,
                        filterView: {
                            ...prevState?.filterView,
                            [viewProperty]: false
                        }
                    }));
                }
            }
        }

        const handleFilterClick = handleClickOutside(FilterRef, 'sortView');
        const handleArrangeClick = handleClickOutside(ArrangeRef, 'arrangeView');

        document.addEventListener('mousedown', handleFilterClick);
        document.addEventListener('mousedown', handleArrangeClick);

        return () => {
            document.removeEventListener('mousedown', handleFilterClick);
            document.removeEventListener('mousedown', handleArrangeClick);
        };
    }, [FilterRef, ArrangeRef, setViewData]);

    async function GetBuildingGrade() {
        await ApiGetCallAuthKey("/allGradeandBuilding/" + LogInSchoolID, signal).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setGradeArray(responseRs.grade);
                    setBuildingArray(responseRs.building);
                }
            }
        });
    }
    async function GetEstimationFun() {
        await fetchAdminData("/GetTicketSoutionEstimation/" + LogInSchoolID, 'GetEstimation', setAllDashBoardData, signal);
    }

    async function GetAllData(sdate, edate, gradeid, buildingid, TicketStatusAllData) {

        if (gradeid == 0) {
            gradeid = null;
        }
        if (buildingid == 0) {
            buildingid = null;
        }
        const params = { sdate, edate, gradeid, buildingid }
        if (ViewData?.GraphView?.tab == "active" && !OnLoadData?.LoadGraphs) {
            setAllDashBoardData((prevState) => ({
                ...prevState,
                FilterData: { ...prevState?.FilterData, ...params },
            }))
            const apiCalls = [
                fetchAdminData("/BreakageRate/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'BreakageRate', setAllDashBoardData, signal),
                fetchAdminData("/SupportTicketGraph/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'SupportTicket', setAllDashBoardData, signal),
                fetchAdminData("/TicketStatus/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid + '&' + null + '&' + TicketStatusAllData, 'TicketsByStatus', setAllDashBoardData, signal),
                fetchAdminData("/BreakageRateByBuilding/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'Breakageratebybuilding', setAllDashBoardData, signal),
                fetchAdminData("/BreakageRateByGrade/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'Breakageratebygrade', setAllDashBoardData, signal),
                fetchAdminData("/TicketClouserTime/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'actual', setAllDashBoardData, signal),
                fetchAdminData("/TicketClouserByTechnician/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'TechnicianClosureTickets', setAllDashBoardData, signal),
                fetchAdminData("/TicketIssue/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'TicketsByIssue', setAllDashBoardData, signal),
                fetchAdminData("/TicketByBuilding/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'NumberOfRepairs', setAllDashBoardData, signal),
                fetchAdminData("/BreakageRateByUser/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'Breakageratebyuser', setAllDashBoardData, signal)
            ];
            try {
                const responses = await Promise.all(apiCalls);
            } catch (error) {
                console.log(error)
            } finally {
                setOnLoadData((prevState) => ({ ...prevState, LoadGraphs: true }))
            }
        }
        if (ViewData?.TableView?.tab == "active" && !OnLoadData?.LoadTables) {
            setAllDashBoardData((prevState) => ({
                ...prevState,
                FilterData: { ...prevState?.FilterData, ...params },
            }))
            const apiCalls = [
                fetchAdminData("/DeviceTickets/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'DeviceTickets', setAllDashBoardData, signal),
                fetchAdminData("/SupportOpenTickets/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'SupportTickets', setAllDashBoardData, signal),
                fetchAdminData("/DueInvoices/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'DueInvoice', setAllDashBoardData, signal),
                fetchAdminData("/IncomingBatch/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'incomingBatch', setAllDashBoardData, signal),
            ];
            try {
                const responses = await Promise.all(apiCalls);
            } catch (error) {
                console.log(error)
            } finally {
                setOnLoadData((prevState) => ({ ...prevState, LoadTables: true }))
            }
        }

    }
    function handleStartDateChange(date) {
        setStartDate(date);
    };
    function handleEndDateChange(date) {
        setEndDate(date);
    };

    const UpdateDND = async (view) => {
        setViewData((prevState) => ({
            ...prevState,
            actualData: {
                ...prevState?.actualData,
                [view]: view == "GraphData" ? ViewData?.GraphView?.data : ViewData?.TableView?.data
            },
            filterView: {
                ...prevState?.filterView,
                arrangeView: false
            }
        }));
        var GraphPosition = ""
        var TablePosition = ""
        ViewData?.GraphView?.data?.forEach((item, i) => {
            GraphPosition += item.Position
            if (ViewData?.GraphView?.data?.length != i + 1 && item?.Position != "1") GraphPosition += "~"
            if (item?.Position == "1") {
                GraphPosition += "^"
                item?.Data?.forEach((items, j) => {
                    GraphPosition += items.Position
                    if (item?.Data?.length != j + 1) GraphPosition += "-"

                })
                if (ViewData?.GraphView?.data?.length != i + 1) GraphPosition += "~"
            }
        });
        ViewData?.TableView?.data?.forEach((item, i) => {
            TablePosition += item.Position
            if (ViewData?.TableView?.data?.length != i + 1) TablePosition += "~"
        });
        var raw = JSON.stringify({
            Orders: view == "GraphData" ? GraphPosition : TablePosition,
            SchoolId: parseInt(LogInSchoolID),
            GridFlag: view == "GraphData" ? 1 : 2,
            UserId: LogInUserID
        });
        await ApiPostCall("/DashboardSettingUpdate", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
            }
        })
    }

    const onDragEnd = async (result, view) => {
        if (!result.destination) {
            return;
        }
        if (view === "SmallGraphsView") {
            const updatedData = ViewData?.GraphView?.data?.map(item => {
                if (item.Div === "Graphs") {
                    const updatedItems = [...item?.Data];
                    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
                    updatedItems.splice(result.destination.index, 0, reorderedItem);
                    return { ...item, Data: updatedItems };
                }
                return item;
            });

            setViewData((prevState) => ({
                ...prevState,
                GraphView: {
                    ...prevState?.GraphView,
                    data: updatedData,
                },
            }));
        } else {
            const updatedItems = [...ViewData[view].data];
            const [reorderedItem] = updatedItems.splice(result.source.index, 1);
            updatedItems.splice(result.destination.index, 0, reorderedItem);

            setViewData((prevState) => ({
                ...prevState,
                [view]: {
                    ...prevState?.[view],
                    data: updatedItems
                }
            }));
        }
    };

    const SubmitpaymentDetailsFunction = (result) => {
        if (result == "success") {
            setAlerts(<AlertsComp show={true} variant="success" msg={"Payment Details Successfully Sent!"} />);
            setTimeout(function () {
                setAlerts(<AlertsComp show={false} />)
                GetAllData(startDate, endDate, grade, building, 0);
            }, 1500);

        } else {
            setAlerts(<AlertsComp show={true} variant="danger" msg={result.message} />);
            setTimeout(() => {
                setAlerts(<AlertsComp show={false} />)
            }, 1500);
        }
    }
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="" style={{ padding: "0 1.2rem 1.2rem 1.2rem" }}>
                <div className={` schoolDashboard pt-3`} >
                    <div className="row GridBox p-2" style={{ margin: "0" }}>
                        <div className="col-md-4 col-12 align-self-center pb-lg-0 pb-2"><h1 className="PageHeading mb-0">Dashboard</h1></div>
                        <div className="col-md-8 col-12">
                            <div className="row">
                                <div className="col-md-3 col-6 pb-lg-0 pb-2">
                                    <div className="tabView p-2 cursor-pointer display-center" onClick={() => { setViewData((prevState) => ({ ...prevState, GraphView: { ...prevState?.GraphView, tab: "active" }, TableView: { ...prevState?.TableView, tab: "" } })) }}>
                                        <img className="img-fluid pe-2" src={`/images/${ViewData?.GraphView?.tab == "active" ? "chartG" : "chart"}.svg`} alt="" />
                                        <span style={{ color: ViewData?.GraphView?.tab == "active" ? "#3CBBA5" : "#000", fontSize: "16px", fontWeight: "550" }}>Statistics</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6 pb-lg-0 pb-2">
                                    <div className="tabView p-2 cursor-pointer display-center" onClick={() => { setViewData((prevState) => ({ ...prevState, GraphView: { ...prevState?.GraphView, tab: "" }, TableView: { ...prevState?.TableView, tab: "active" } })) }}>
                                        <img className="img-fluid pe-2" src={`/images/${ViewData?.TableView?.tab == "active" ? "tableG" : "table"}.svg`} alt="" />
                                        <span style={{ color: ViewData?.TableView?.tab == "active" ? "#3CBBA5" : "#000", fontSize: "16px", fontWeight: "550" }}>Tables</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6 pb-lg-0 pb-2 arrange-dropdown" ref={ArrangeRef}>
                                    <div className="tabView p-2 cursor-pointer display-center" onClick={() => { setViewData((prevState) => ({ ...prevState, filterView: { ...prevState?.filterView, arrangeView: !prevState?.filterView?.arrangeView, } })) }}>
                                        <img className="img-fluid pe-2" src={`/images/${ViewData?.filterView?.arrangeView ? "arrangeG" : "arrange"}.svg`} alt="" />
                                        <span style={{ color: ViewData?.filterView?.arrangeView ? "#3CBBA5" : "#000", fontSize: "16px", fontWeight: "550" }}>Arrange</span>
                                    </div>
                                    <div className={`arrange-dropdown-content greyBoxShadow px-3 py-2 ${ViewData?.filterView?.arrangeView ? "" : "d-none"}`}>
                                        {ViewData?.GraphView?.tab == "active"
                                            ?
                                            <DragDropContext onDragEnd={(e) => onDragEnd(e, "GraphView")} >
                                                <Droppable droppableId="droppable" direction="vertical">
                                                    {(provided) => (
                                                        <>
                                                            <div className="row mb-2" ref={provided.innerRef} {...provided.droppableProps} style={{ minHeight: "300px" }}>
                                                                {ViewData?.GraphView?.data?.map((item, index) =>
                                                                (
                                                                    <div key={item.Position} className={`${item?.Size} ${item?.Name == "Graphs" ? "px-0" : "px-2"} m-0 py-1 dragMe`}>
                                                                        <Draggable draggableId={item.Position} index={index}>
                                                                            {(provided) => (
                                                                                <div ref={provided.innerRef} {...provided.draggableProps} >
                                                                                    {item?.Name == "Graphs" ?
                                                                                        <div className="greyBoxShadow h-100 display-center p-1" style={{ fontSize: "13px", fontWeight: "600" }}>
                                                                                            <div className="row"    >
                                                                                                <div className="col-12 text-center" {...provided.dragHandleProps}>
                                                                                                    <img src="/images/DragReorder.svg" alt="" style={{ width: "15px" }} />
                                                                                                </div>
                                                                                                <DragDropContext onDragEnd={(e) => onDragEnd(e, "SmallGraphsView")} >
                                                                                                    <Droppable droppableId="droppable" direction="horizontal">
                                                                                                        {(provided) => (
                                                                                                            <div className="row m-0 px-0" ref={provided.innerRef} {...provided.droppableProps}>
                                                                                                                {item?.Data?.map((item, index) =>
                                                                                                                (
                                                                                                                    <Draggable key={item.Position} draggableId={item.Position} index={index}>
                                                                                                                        {(provided, snapshot) => (
                                                                                                                            <div className={item?.Size + " px-3 py-2 dragMe m-0"}
                                                                                                                                ref={provided.innerRef}
                                                                                                                                {...provided.draggableProps}
                                                                                                                                {...provided.dragHandleProps}

                                                                                                                            >
                                                                                                                                <div className="greyBoxShadow h-100" style={{ fontSize: "13px", fontWeight: "600" }}>
                                                                                                                                    <div className="row p-1">
                                                                                                                                        <div className="col-12 text-center">
                                                                                                                                            <img src="/images/DragReorder.svg" alt="" style={{ transform: "rotate(-90deg)", width: "15px" }} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row p-1">
                                                                                                                                        <div className="col-12 text-center font-12">
                                                                                                                                            {item?.Name}
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Draggable>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Droppable>
                                                                                                </DragDropContext>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="greyBoxShadow h-100 display-center" style={{ fontWeight: "600" }}    {...provided.dragHandleProps}>
                                                                                            <div className="d-flex col-12 align-items-center font-12">
                                                                                                <img src="./images/DragReorder.svg" className="pe-3" alt="" style={{ width: "30px" }} />
                                                                                                {item?.Name}
                                                                                            </div>
                                                                                        </div>}
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                            <span className=" py-2">
                                                                <button className="mt-4 btn btn-secondary w-100 filter-apply" style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => { UpdateDND("GraphData") }}>Apply</button>
                                                            </span>

                                                        </>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            :
                                            <DragDropContext onDragEnd={(e) => onDragEnd(e, "TableView")} >
                                                <Droppable droppableId="droppable">
                                                    {(provided) => (
                                                        <div className="row" ref={provided.innerRef} {...provided.droppableProps}>
                                                            {ViewData?.TableView?.data?.map((item, index) =>
                                                            (
                                                                <Draggable key={item.Position} draggableId={item.Position} index={index}>
                                                                    {(provided) => (
                                                                        <div className={item?.Size + " p-2 dragMe"}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div className="greyBoxShadow h-100 display-center" style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                <div className="row">
                                                                                    <div className="col-12 text-center pb-1">
                                                                                        <img src="/images/four-arrows.svg" alt="" style={{ width: "20px" }} />
                                                                                    </div>
                                                                                    <div className="col-12 text-center pb-1">
                                                                                        {item?.Name}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            <div className="col-md-12 py-2">
                                                                <button className="mt-5 btn btn-secondary w-100 filter-apply" style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => { UpdateDND("TableData") }}>Apply</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>}
                                    </div>
                                </div>
                                <div className="col-md-3 col-6 pb-lg-0 pb-2 filter-dropdown " ref={FilterRef}>
                                    <div className="tabView p-2 cursor-pointer display-center " onClick={() => { setViewData((prevState) => ({ ...prevState, filterView: { ...prevState?.filterView, sortView: !prevState?.filterView?.sortView, } })) }}>
                                        <img className="img-fluid pe-2" src={`/images/${ViewData?.filterView?.sortView ? "sortG" : "sort"}.svg`} alt="" />
                                        <span style={{ color: ViewData?.filterView?.sortView ? "#3CBBA5" : "#000", fontSize: "16px", fontWeight: "550" }}>Filter</span>
                                    </div>
                                    <div className={`filter-dropdown-content greyBoxShadow p-2 pe-md-0 pe-4 ${ViewData?.filterView?.sortView ? "" : "d-none"}`}>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="row">
                                                    <div className="col-sm-6 d-flex py-1  align-items-center px-0">
                                                        <label className="px-3"></label>
                                                        <ReactDatePicker
                                                            className="form-control me-4 text-center"
                                                            selected={startDate}
                                                            onChange={(e) => { handleStartDateChange(e) }}
                                                            dateFormat="MM/dd/yyyy"
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 d-flex py-1 align-items-center px-0">
                                                        <label className="px-3">To</label>
                                                        <ReactDatePicker
                                                            className="form-control me-4 text-center"
                                                            selected={endDate}
                                                            onChange={(e) => { handleEndDateChange(e) }}
                                                            dateFormat="MM/dd/yyyy"
                                                            maxDate={today}
                                                            popperProps={{ strategy: 'fixed' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="row">
                                                    <div className="col-sm-6 py-1 pe-0">
                                                        <select className="FilterBorder" value={grade} onChange={(e) => { setgrade(e.target.value) }}>
                                                            <option value="0">Filter by Grade</option>
                                                            {GradeArray.map((item, j) => (
                                                                <option value={item.ID} key={j}>
                                                                    {item.Grade}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6 py-1 pe-0">
                                                        <select className="FilterBorder" value={building} onChange={(e) => { setbuilding(e.target.value) }}>
                                                            <option value="0">Filter by Building</option>
                                                            {BuildingArray.map((item, j) => (
                                                                <option value={item.ID} key={j}>
                                                                    {item.building}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 py-1 pe-md-4 pe-0">
                                                <div className="row">
                                                    <div className="col-md-8 col-10"><button className="btn btn-secondary w-100 filter-apply" onClick={() => {
                                                        setOnLoadData((prevState) => ({ ...prevState, LoadGraphs: false, LoadTables: false }))
                                                        setViewData(({ ...ViewData, filterView: { ...ViewData?.filterView, sortView: false } }))
                                                    }}>Apply</button></div>
                                                    <div className="col-md-4 col-2 ps-0" title="Clear filter"><img className="img-fluid cursor-pointer" src="/images/clear-filter.svg" alt="" onClick={() => { setStartDate(oneMonthAgo.setMonth(new Date().getMonth() - 1)); setEndDate(new Date()); setgrade("0"); setbuilding("0"); }} /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <div className='GridBox p-3 mt-4' style={{ opacity: ViewData?.filterView?.arrangeView || ViewData?.filterView?.sortView ? "0.4" : "1", boxShadow: "none" }}>
                    <div className=" pb-2" >
                        <TableDataContext.Provider value={{ AllDashBoardData, SubmitpaymentDetailsFunction, setAllDashBoardData, GetEstimationFun }}>
                            {ViewData?.GraphView?.tab == "active"
                                ?
                                <><div className="row">
                                    {ViewData?.actualData?.GraphData?.map((item) =>
                                    (
                                        <div className={item?.Size + " p-3 m-0"} key={item?.Position} >
                                            {item?.Name == "Graphs" ? <>{item?.Data?.map((items) => <div key={items?.Position} className={items?.Size}> {createComponent(items.Div)}</div>)}</> : <>{createComponent(item.Div)}</>}
                                        </div>

                                    ))}
                                </div>
                                </>
                                :
                                <>
                                    <div className="row" >
                                        {ViewData?.actualData?.TableData?.map((item) =>
                                        (<div className={item?.Size + " p-3"} key={item?.Position} >
                                            {createComponent(item.Div)}
                                        </div>

                                        ))}
                                    </div>

                                </>
                            }


                        </TableDataContext.Provider>
                    </div>

                </div >
            </div >
        </>
    )
}


function createComponent(component, props) {
    const componentMap = {
        BreakageRate,
        SupportTicket,
        TicketCloserTime,
        TicketStatusGraph,
        DeptAndEmpTicketClosure,
        TicketTable,
        SupportTicketTable,
        DueTable,
        IncomingBatch,
        TicketIssueType,
        NumberOfRepairs
    };
    return React.createElement(componentMap[component], props);
}


