import React, { useEffect, useState } from 'react'
import { LogInSchoolID, HideLoder, ShowLoder } from '../../JS/Common';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../App';
import { MDBSwitch } from 'mdb-react-ui-kit';
import AlertsComp from '../../Components/AlertsComp';
function Notification() {
    const navigate = useNavigate();
    const [NotificationData, setNotificationData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const { UserData } = useContext(UserContext);
    const flagForAdminSchool = UserData.MenuAccessFlag;
    const [visibleNoteID, setVisibleNoteID] = useState(null);
    useEffect(() => {
        GetAllNotifications();
    }, []);
    async function GetAllNotifications() {
        var schoolid = LogInSchoolID
        if (flagForAdminSchool == 5) {
            var flag = 1
        } else {
            var flag = 2
        }
        setLoading(true);
        if (schoolid == 0) {
            schoolid = null
        }
        await ApiGetCall("/GetAllNotifications/" + schoolid + '&' + flag).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                const eventsWithUsers = responseRs.eventsData.map(event => {
                    const usersInEvent = responseRs.users.map(user => {
                        const userInEvent = event.eventlog.find(log => log.user_id == user.id);
                        return {
                            ...user,
                            userflag: userInEvent ? 1 : 0
                        };
                    });

                    return {
                        ...event,
                        users: usersInEvent
                    };
                });
                setNotificationData(eventsWithUsers);
            }
        });
    }
    const handleCheckboxChangeForNewEmails = (userid, e) => {
        var updatedUserflag = 0;
        const updatedNotificationData = NotificationData.map((notification) => {
            if (notification.id == visibleNoteID) {
                const updatedUsers = notification.users.map((user) => {
                    if (user.id == userid) {
                        if (e.target.checked == true) {
                            updatedUserflag = 1;
                        }
                        return { ...user, userflag: updatedUserflag };
                    }
                    return user;
                });
                return { ...notification, users: updatedUsers };
            }
            return notification;
        });
        setNotificationData(updatedNotificationData);
    };

    function AttachNewUsers() {
        if (flagForAdminSchool == 5) {
            navigate("/technician")
        } else {
            navigate("/staff-members")
        }
    }
    function ShowExistingEmails(itemId) {
        setVisibleNoteID(prevID => (prevID == itemId ? null : itemId));
    }
    async function SaveEmails(eventtype) {
        var EmailsArray = [];
        NotificationData.map((notification) => {
            if (notification.id == eventtype) {
                notification.users?.map((user) => {
                    var vjson = {};
                    vjson['id'] = user.id;
                    if (user.userflag == 1) {
                        EmailsArray.push(vjson)
                    }
                })
            }
        })
        var raw = JSON.stringify({
            Emails: EmailsArray,
            EventType: eventtype,
            SchoolID: LogInSchoolID,
        });
        setLoading(true);
        await ApiPostCall("/AddDeleteEmails", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setVisibleNoteID(null);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={'Notification successfully updated.'} />);
                    setTimeout(function () {
                        setAlerts(<AlertsComp show={false} />)
                        GetAllNotifications()
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        })
    }
    async function DefaultAdminEmails(eventid, e) {
        var ischeck = e.target.checked;
        var flag = ischeck ? 1 : 2;
        await ApiPostCall("/setdefaultnotification/" + LogInSchoolID + "&" + eventid + "&" + flag).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setVisibleNoteID(null);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={'Notification successfully updated.'} />);
                    setTimeout(function () {
                        setAlerts(<AlertsComp show={false} />)
                        GetAllNotifications()
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        })
    }
    return (
        <div>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="row col-12 pe-0 align-items-center">
                <div className="col-md-9"> <h1 className="PageHeading">Notifications</h1>  </div>
                <div className='col-md-3 text-end pb-2 pe-0'>
                    <u className='cursor-pointer' onClick={AttachNewUsers}>Add New User</u>
                </div>
            </div>
            {NotificationData.map((notification, i) => {
                const isNoteVisible = notification.id === visibleNoteID;
                return (
                    <div className='GridBox p-3 mb-3' key={i}>
                        <div className='row align-items-center'>
                            <div className='col-10'>
                                <label className="fw-600 font-15"> {notification.events} </label>
                                <br />
                                <label className='pt-2'>
                                    {notification.description}
                                </label>
                            </div>
                            <div className='col-2 justify-content-end d-flex align-items-center'>
                                <MDBSwitch className="BgPink cursor-pointer" checked={notification.eventDefaultData == 1} title='Notify the administrator?' onChange={(e) => DefaultAdminEmails(notification.id, e)} />
                                <img src="/images/DownRoundArrow.svg" title='Show Users' className={`cursor-pointer img-fluid ${isNoteVisible ? "d-none" : ""}`} onClick={() => { ShowExistingEmails(notification.id) }} />
                                <img src="/images/UpRoundArrow.svg" title='Hide Users' className={`img-fluid cursor-pointer ${isNoteVisible ? "" : "d-none"}`} onClick={() => { ShowExistingEmails(notification.id) }} />
                            </div>
                        </div>
                        {isNoteVisible && (
                            <div className='row mt-2 px-3 notificationdiv'>
                                <hr />
                                {notification?.users?.length !== 0 ? (
                                    notification?.users?.map((user, j) => (
                                        <div className='col-md-4' key={j}>
                                            <label htmlFor="ticket" className="mb-2 checkbox-font cursor-pointer">
                                                <input
                                                    checked={user.userflag == 1}
                                                    className='form-check-input cursor-pointer'
                                                    id={`checkboxid${user.id}`}
                                                    type="checkbox"
                                                    name={user.email}
                                                    onChange={(e) => handleCheckboxChangeForNewEmails(user.id, e)}
                                                />
                                                &nbsp;&nbsp;
                                                <label className='cursor-pointer' htmlFor={`checkboxid${user.id}`}>{user.email}</label>
                                            </label>
                                            <br />
                                        </div>
                                    ))
                                ) : (
                                    <div className='col-md-12 text-center py-2'>No Record Found </div>
                                )}
                                <div className='col-12 text-center'>
                                    <button className='SaveBtn px-4' onClick={(e) => SaveEmails(notification.id)}>Save</button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}

        </div>
    )
}

export default Notification
