import React from 'react'
import { SemiCircleProgress } from 'react-semicircle-progressbar';
import { MMDDYYYY } from '../../../JS/Common';
import AlertsComp from '../../../Components/AlertsComp';

function KPI(props) {
    var breakagerate;
    if (props?.data >= 100) {
        breakagerate = 0
    } else {
        breakagerate = props?.data
    }
    const downloadCsv = () => {
        const csvContent = [];
        const buildingMap = [];
        props?.CsvData?.forEach((ticket) => {
            const rowData = {
                'Ticket Number': ticket.ticket_num,
                'Serial Number': ticket?.inventory_management?.serial_number,
                'Student Number': ticket?.inventory_management?.student_inventory?.student?.student_num,
                'Notes': ticket?.notes?.replace(/[,\n.]/g, ' '),
                'Return Location': ticket?.return_location?.name,
                'created at': MMDDYYYY(ticket.created_at),
            };
            buildingMap.push(rowData);
        });

        if (buildingMap.length > 0) {
            const headers = ['Ticket Number', 'Serial Number', 'Student Number', 'Notes', 'Return Location', 'created at'];
            csvContent.push(headers);
            buildingMap.forEach((rowData) => {
                const values = Object.values(rowData);
                csvContent.push(values);
            });
            csvContent.push([]);
            const csvString = csvContent.map((row) => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'AverageTicketCloseRate.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            props.setAlerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
            setTimeout(() => {
                props.setAlerts(<AlertsComp show={false} />)
            }, 1500);
        }
    };


    return (
        <div className="text-center greyBox h-100" >
            <div className="pb-5 w-100">
                <div>
                    <h4>Average Tickets Close Rate</h4>
                </div>
                <div className='justify-content-center d-flex mb-3'>
                    <label className='cursor-pointer d-flex align-items-center' onClick={downloadCsv} style={{ fontSize: "12px" }}>
                        <img src="/images/downloadPlan.svg" className="pe-1" title='Download CSV' height='10px' />
                        <label className='cursor-pointer'>Download Detailed Reports</label>
                    </label>
                </div>
                {props.loder == true ?
                    <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div>
                    :
                    <div id="chart">
                        <SemiCircleProgress percentage={breakagerate} size={{ width: 230, height: 290, }}
                            strokeWidth={5}
                            strokeColor="#3cbba5"
                            hasBackground />
                    </div>
                }
            </div>
        </div>
    )
}

export default KPI
