import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { SchoolInvoice } from '../SchoolBilling';
import ShowInvoice from '../SchoolBilling/ShowInvoice';
import { ErrorPage } from '../404Error';
import CloseTicketInvoice from '../../Components/CloseTicketInvoice';
import TicketHistoryPage from '../Billingandtickets/TicketHistoryPage';
import { FaqIndex } from '../FAQ';
export default function Access11() {
    return (
        <Routes>
            <Route exact path="/" element={<SchoolInvoice />} />
            <Route exact path="/school-invoice" element={<SchoolInvoice />} />
            <Route exact path="/generate-invoice" element={<ShowInvoice />} />
            <Route exact path="/CloseTicketInvoice" element={<CloseTicketInvoice />} />
            <Route exact path="/ticket-history" element={<TicketHistoryPage />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    )
}
