import React, { useLayoutEffect, useState, useRef } from 'react'
import AlertsComp from '../../../Components/AlertsComp';
import LoadingBar from 'react-top-loading-bar';
import GoogleImportButton from './GoogleImportButton';
import { LogInSchoolID, LogInUserID } from '../../../JS/Common';
import { ApiGetCall, ApiPostCall } from '../../../JS/Connector';
import { GetGoogleAdminData, PostGoogleAuthAPI, importGoogleDeviceAPI, PostErrorCall } from './GoogleAutomation';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AlertPopup from '../../../Components/AlertPopup';
export default function ImportGoogleDevices() {
    const clientId = process.env.REACT_APP_GoogleConsoleClientId;
    const clientSecret = process.env.REACT_APP_GoogleConsoleClientSecret;
    const IsGoogleSyncedData = { refresh_token: "", access_token: "", customer_id: "", processStatus: null, email: "" }
    const [Loader, setLoader] = useState(true);
    const [googlePopup, setGooglePopup] = useState({ popup: false, removeAccount: false })
    const [isGoogleAdminUser, setIsGoogleAdminUser] = useState({ msg: "", flag: false })
    const [NewAlerts, setNewAlerts] = useState("");
    const [IsGoogleSynced, setIsGoogleSynced] = useState(IsGoogleSyncedData);
    const refForGoogleImport = useRef(null);


    useLayoutEffect(() => {
        fetchGetGoogleEmailDetails();
    }, []);
    const fetchGetGoogleEmailDetails = async () => {
        setLoader(true)
        try {
            const result = await ApiGetCall("/GetGoogleEmailDetails/" + LogInSchoolID);
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs?.response == "success") {
                    setIsGoogleSynced({ refresh_token: responseRs?.data?.refresh_token, access_token: responseRs?.data?.access_token, customer_id: responseRs?.data?.customer_id, processStatus: responseRs?.data?.process_status, email: responseRs?.data?.email })
                } else {
                    setIsGoogleSynced(IsGoogleSyncedData)
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoader(false)
        }
    }
    const ImportGoogleDevicesFun = async (status) => {
        const loaderRef = refForGoogleImport.current
        if (loaderRef) {
            loaderRef.continuousStart();
        }
        try {
            setGooglePopup({ ...googlePopup, popup: false })
            try {
                let result = await importGoogleDeviceAPI(IsGoogleSynced, status)
                result = JSON.parse(result)
                if (result?.response == "success") {
                    status == "Add" && fetchGetGoogleEmailDetails()
                    alert("success", result?.message, status == "Update")
                } else {
                    alert("danger", result?.message)
                }
            } catch (error) {
                throw error
            }

        } catch (error) {
            console.error('Error:', error.message);
            try {
                let ErrorResultTwo = await PostErrorCall(error.message, "ImportGoogleDevices_Frontend")
                console.log(ErrorResultTwo)
            } catch (error) {
                console.log(error.message)
            }
            alert("danger", error.message)
        } finally {
            if (loaderRef) {
                loaderRef.complete();
            }
        }

    };
    const onSuccess = async (response) => {

        const loaderRef = refForGoogleImport.current
        if (loaderRef) {
            loaderRef.continuousStart();
        }
        var Email = ""
        try {
            var tokenData;
            const params = new URLSearchParams();
            params.append('client_id', clientId);
            params.append('client_secret', clientSecret);
            params.append('grant_type', 'authorization_code');
            params.append('code', response?.code);
            params.append('redirect_uri', process.env.REACT_APP_GoogleRedirecturi);

            try {
                const response = await PostGoogleAuthAPI(params);
                tokenData = response;
            } catch (error) {
                throw error
            }
            if (tokenData?.access_token) {
                try {
                    const accessToken = tokenData?.access_token;
                    const responseUserResult = await GetGoogleAdminData(accessToken, 'https://www.googleapis.com/oauth2/v3/userinfo');
                    const Email = responseUserResult?.result.email;
                    let isValidStatus = false;
                    if (responseUserResult?.statusCode === 200) {
                        const customerResult = await GetGoogleAdminData(accessToken, `https://admin.googleapis.com/admin/directory/v1/users/${Email}?fields=customerId`);
                        if (customerResult?.statusCode === 200) {
                            const customerIdValue = customerResult?.result?.customerId;
                            setIsGoogleSynced(prev => ({ ...prev, refresh_token: tokenData?.refresh_token, access_token: accessToken, email: Email, customer_id: customerIdValue }));
                            setGooglePopup({ ...googlePopup, popup: true });
                            setIsGoogleAdminUser({ ...isGoogleAdminUser, msg: "Are you about to add Google devices to your account?", flag: true });
                        } else {
                            isValidStatus = true;
                        }
                    } else {
                        isValidStatus = true
                    }

                    if (isValidStatus) {
                        setGooglePopup({ ...googlePopup, popup: true });
                        setIsGoogleAdminUser({ ...isGoogleAdminUser, msg: "User not found!", flag: false });
                    }
                } catch (error) {
                    throw error;
                }
            }
        } catch (error) {
            console.error('Error:', error.message)
            try {
                let ErrorResultTwo = await PostErrorCall(error.message, "GoogleAPI's_Frontend")
                console.log(ErrorResultTwo)
            } catch (error) {
                console.log(error.message)
            }
            alert("danger", error.message)
        } finally {
            if (loaderRef) {
                loaderRef.complete();
            }
        }
    }

    const removeAccountFunction = async () => {
        await ApiPostCall("/RemoveGoogleConsoleAccount/" + LogInSchoolID, {})
            .then(result => {
                if (result == "success") {
                    fetchGetGoogleEmailDetails()
                    setIsGoogleSynced(IsGoogleSyncedData)
                    alert("success", 'Google account removed successfully')
                } else {
                    alert("danger", result)
                }
            })
            .catch(error => {
                console.error('Error', error)
                alert("danger", error.message)
            });
        setGooglePopup({ popup: false, removeAccount: false })
    }
    const alert = (flag, message, redirect = false) => {
        setNewAlerts(<AlertsComp show={true} variant={flag} msg={message} />);
        setTimeout(() => {
            setNewAlerts(<AlertsComp show={false} />);
            if (redirect) window.location = "/manage-device";
        }, 2000);
    }
    return (
        <React.Fragment>
            {NewAlerts}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle pb-2'>Import Your Google Devices</span>
            </div>
            <hr className='my-0' />
            <div className="mt-3 mb-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    Connect your Google console account and import your Devices to K-12 Rocket.
                </p>
            </div>
            <div className='row'>
                {Loader ?
                    <div className='row'>
                        <div className="col-12 text-center pt-2">
                            <img src="/images/loder.svg" className="" style={{ width: "40px" }} />
                        </div>
                    </div> :
                    IsGoogleSynced?.processStatus == 1 ? <>
                        <div className='col-md-12'>
                            Your Google account is syncing with - <span style={{ fontWeight: "600" }}>{IsGoogleSynced?.email}</span>
                        </div>
                        <div className='col-md-12 fw-600 mt-3'>
                            Please wait, data fetching has been initiated in the background. Once the process is completed, you will be notified via email.
                        </div>
                    </>
                        : IsGoogleSynced?.processStatus == 2 ? <>
                            <div className='col-md-12'>
                                Your Google account synced with - <span style={{ fontWeight: "600" }}>{IsGoogleSynced?.email}</span>
                            </div>
                            <div className='col-md-12 mt-3'>
                                <div className="row">
                                    <div className="col-6">
                                        <button className="MicrosoftGoogleBtn"
                                            onClick={() => { ImportGoogleDevicesFun("Update") }}
                                        >
                                            <div className=""><img src="/images/GoogleBtn.svg" className="img-fluid pe-2" /> Sync your google devices</div>
                                            <span className='ms-3'>BETA</span>
                                        </button>
                                    </div>
                                    <div className="col-6"><button type="button" className="btn btn-danger w-100 h-100 remove-google-button" onClick={() => { setGooglePopup({ popup: true, removeAccount: true }) }}><img src="/images/delete-user.svg" className="img-fluid pe-2" />Remove you google admin account</button></div>
                                </div>
                            </div>
                        </> : <div className='col-md-6 pe-4'>
                            <GoogleOAuthProvider clientId={clientId}>
                                <GoogleImportButton onSuccess={onSuccess} text={"Import your google devices"} />
                            </GoogleOAuthProvider>
                        </div>}
            </div>
            <div className="mt-2">
                <LoadingBar style={{ borderRadius: "6px" }}
                    ref={refForGoogleImport}
                    containerClassName='progressbarClass CustomProgressbar' />
            </div>
            <AlertPopup isshow={googlePopup?.popup} img={'/images/greenInfo.svg'}
                headingtext={!googlePopup?.removeAccount ? isGoogleAdminUser?.msg : 'Are you sure you want to remove your Google account?'}
                text={!googlePopup?.removeAccount && 'Duplicate records will be skipped!'}
                cancelcall={(e) => !googlePopup?.removeAccount ? setGooglePopup({ ...googlePopup, popup: false }) : setGooglePopup({ popup: false, removeAccount: false })}
                canceltext={!googlePopup?.removeAccount ? isGoogleAdminUser?.flag ? "No" : "Back" : 'Back'}
                successtext={"Continue"} successcall={(e) => !googlePopup?.removeAccount ? isGoogleAdminUser?.flag && ImportGoogleDevicesFun("Add") : removeAccountFunction()}
                btntype={'SaveBtn'} />
        </React.Fragment >
    )
}

