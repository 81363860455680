export function ContactThankyou() {
    return (
        <>
            <div className='container m-auto' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <h4 className='py-5 text-center'>
                    <p className='pt-1' >Thank you for submitting your feedback to the School Admin. Your input has been received successfully.</p>
                    <p className='pt-1'>The administrative team will promptly review your query and respond accordingly.</p>
                    <h3 className="fw-600">Thank You!</h3>
                </h4>
            </div>
        </>
    )
}