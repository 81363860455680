import React from 'react'
import { MMDDYYYY } from '../../../JS/Common';
import AlertsComp from '../../../Components/AlertsComp';

function PartUsed(props) {
    const downloadCsv = () => {
        const csvContent = [];
        props?.data.forEach(part => {
            const partName = part.part_name;
            const partprice = part.orignal_price;
            csvContent.push([`Part Name: ${partName}`]);
            csvContent.push([`Part Price: $${partprice}`]);
            csvContent.push(['Ticket Number', 'Serial Number', 'Student Number', 'Notes', 'Return Location', 'Created At', 'Status']);
            part.used.forEach(usage => {
                const serialnum = usage.serial_num;
                const studentnumber = usage.student_num;
                const ticketNumber = usage.ticket_num;
                const status = usage.status;
                const createdat = MMDDYYYY(usage.created_at);
                const notes = usage?.ticket_notes?.replace(/[,\n.]/g, ' ');
                const ReturnLocation = usage?.return_location?.name;
                csvContent.push([ticketNumber, serialnum, studentnumber, notes, ReturnLocation, createdat, status]); // Leave blanks for these fields
            });
            csvContent.push([]);
        });
        if (csvContent.length > 0) {
            const csvString = csvContent.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'PartsUsage.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            props.setAlerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
            setTimeout(() => {
                props.setAlerts(<AlertsComp show={false} />)
            }, 1500);
        }
    };
    return (
        <div className="text-center h-100 greyBox">
            <div className="pb-5 w-100">
                <div>
                    <h4>Parts Used</h4>
                </div>
                <div className='justify-content-center d-flex mb-3'>
                    <label className='cursor-pointer d-flex align-items-center' onClick={downloadCsv} style={{ fontSize: "12px" }}>
                        <img src="/images/downloadPlan.svg" className="pe-1" title='Download CSV' height='10px' />
                        <label className='cursor-pointer'>Download Detailed Reports</label>
                    </label>
                </div>
                <div className="p-3 data-table-container">
                    {props.loder == true ?
                        <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div>
                        :
                        <table className="table data-table">
                            <thead className='GridHeader'>
                                <tr>
                                    <th scope="col" className='fw-600'>Part Name</th>
                                    <th scope="col" className='fw-600'>Total Cost($)</th>
                                    <th scope="col" className='fw-600'>Ticket Usage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props?.data?.length != 0 ?
                                    props?.data?.map((item, i) => {
                                        return <tr key={i} className="OddEvenClass tableBorderHide">
                                            <td scope="col" className='py-12 text-start note-wrap'>{item.part_name} </td>
                                            <td scope="col" className='py-12 text-start'>${item.orignal_price} </td>
                                            <td scope="col" className='py-12 text-start'>{item.used?.length} </td>
                                        </tr>
                                    })
                                    :
                                    <tr className="text-center">
                                        <td colSpan={15}>No Record Found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    )
}

export default PartUsed
