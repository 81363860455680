import React from 'react';

const Tab = ({ isActive, label, onClick, col }) => (
    <li className={`${col} nav-item navitembrdrbtm text-center cursor-pointer mx-1 marginForMobile`}>
        <a className={`nav-link linkclass TabNavlink ${isActive ? 'active' : ''}`}
            onClick={onClick}>
            {label}
        </a>
    </li>
);

export default Tab;
