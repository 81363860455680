import React from 'react'
import Popup from '../../Components/Popup';

export default function Imagepopup({ lgShow, setLgShow, PreviewImg }) {
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    return (
        <Popup isshow={lgShow} size={"lg"} title={"Preview Image"}
            closePopup={(e) => { setLgShow(false) }}
            modalBody={<img src={`${BucketS3Link}${PreviewImg}`} height={200} />} visibleSaveBtn={false} />
    )
}
