import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { TicketForm } from '../SupportTicket/TicketForm';
import { TicketDetails } from '../SupportTicket/TicketDetails';
import { HelpdeskTicketDetails } from '../Helpdesk/HelpdeskTicketDetails';
import { Logout } from '../Signout';
import ThankYouPage from '../SupportTicket/ThankYouPage';
import ParentContract from '../ParentalCoverage/ParentContract';
import { Contactus } from '../ContactUs';
import { ContactThankyou } from '../ContactUs/ContactThankyou';
import { Receipt } from '../ParentalCoverage/Receipt';
import ConfirmSchool from '../Signup/ConfirmSchool';
import HelpdeskForm from '../Helpdesk/HelpdeskForm';
import Thankyou from '../Helpdesk/Thankyou';
import PaymentSuccessPage from '../../Components/PaymentSuccessPage';
import { TermsAndCondition } from '../Termsandcondition';
import PrivacyPolicy from '../PrivacyPolicy';
export default function MatchingPath() {
    return (
        <Routes>
            <Route path="/tickets/*" element={<TicketForm />} />
            <Route path="/ticket/*" element={<TicketDetails />} />
            <Route path="/helpdesk-ticket/*" element={<HelpdeskTicketDetails />} />
            <Route path="/k12logout" element={<Logout />} />
            <Route path="/thankyou/*" element={<ThankYouPage />} />
            <Route exact path="/plan/*" element={<ParentContract />} />
            <Route exact path="/contact-us/*" element={<Contactus />} />
            <Route exact path="/thank-you" element={<ContactThankyou />} />
            <Route exact path="/plan-receipt" element={<Receipt />} />
            <Route exact path="/confirm-member/*" element={<ConfirmSchool />} />
            <Route exact path="/helpdesk-request/*" element={<HelpdeskForm />} />
            <Route exact path="/submithelpdesk/*" element={<Thankyou />} />
            <Route exact path="/payment/*" element={<PaymentSuccessPage />} />
            <Route exact path="/terms-of-service" element={<TermsAndCondition />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    )
}
export const pathsToMatch = [
    "tickets", "k12logout", "ticket", "thankyou", "plan", "contact-us",
    "thank-you", "plan-receipt", "confirm-member", "helpdesk-request",
    "submithelpdesk", "helpdesk-ticket", "payment", "terms-of-service",
    "privacy-policy"
];
