import React, { createContext, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import DeviceReportsIndex from "./DeviceReports/DeviceReportsIndex";
import StaffReportsIndex from "./StaffReports/StaffReportsIndex";
import TechTicketsIndex from "./TechnologyTickets/TechTicketsIndex";
import MaintenanceIndex from "./MaintenanceReports/MaintenanceIndex";
import ReactDatePicker from "react-datepicker";
import { ApiGetCallAuthKey, fetchAdminData } from "../../JS/Connector";
import { LogInSchoolID, MMDDYYYY } from "../../JS/Common";
import { UserContext } from "../../App";
import OtherHelpdeskTickets from "./OtherHelpdeskTickets/OtherHelpdeskTickets";
import Tab from "../../Components/Tab";
export const ReportDataContext = createContext();
export function SchoolReports() {
    const [Alert, setAlerts] = useState("");
    const controller = new AbortController();
    const signal = controller.signal;
    const [Tabs, setTabs] = useState({
        DeviceReports: "active",
        StaffReports: "",
        TechnologyTickets: "",
        MaintenanceTickets: "",
        HelpDesk: "",
    });
    const [Onload, setOnload] = useState({
        DeviceOnload: false,
        StaffOnload: false,
        TechnologyOnload: false,
        MaintenanceOnload: false,
        HelpdeskOnload: false
    });

    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);
    const [startDate, setStartDate] = useState(oneMonthAgo);
    const [endDate, setEndDate] = useState(today);
    const [GradeArray, setGradeArray] = useState([]);
    const [BuildingArray, setBuildingArray] = useState([]);
    const [grade, setgrade] = useState(null);
    const [building, setbuilding] = useState(null);
    const [AllReportData, setAllReportData] = useState({
        TicketStatusData: { data: [], loader: false },
        TicketByBuildingData: { data: [], loader: false },
        TicketByGradeData: { data: [], loader: false },
        PartUsedData: { data: [], loader: false },
        KpiData: { data: [], loader: false },
        StaffTicketsData: { data: [], loader: false },
        TechnologyTicketsCloseRateData: { data: [], loader: false },
        MaintenanceTicketsCloseRateData: { data: [], loader: false },
        TechnologyTicketsData: { data: [], loader: false },
        MaintenanceTotalTicketsData: { data: [], loader: false },
        HelpdeskTotalTicketsData: { data: [], loader: false },
        FilterData: {
            sdate: "", edate: "", gradeid: null, buildingid: null
        },
    });
    const { UserData } = useContext(UserContext)
    useEffect(() => {
        ApiGetCallAuthKey("/allGradeandBuilding/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setGradeArray(responseRs.grade);
                    setBuildingArray(responseRs.building);
                }
            }
        });
        GetAllData(startDate, endDate, 0, 0)
        return () => controller.abort();
    }, []);
    async function GetAllData(sdate, edate, gradeid, buildingid) {

        if (gradeid == 0) {
            gradeid = null;
        }
        if (buildingid == 0) {
            buildingid = null;
        }
        const params = { sdate, edate, gradeid, buildingid }
        if (Tabs?.DeviceReports == "active" && !Onload?.DeviceOnload) {
            setAllReportData((prevState) => ({
                ...prevState,
                FilterData: { ...prevState?.FilterData, ...params },
            }))
            const apiCalls = [
                fetchAdminData("/TicketByStatusforReport/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'TicketStatusData', setAllReportData, signal),
                fetchAdminData("/TicketByBuildingforReport/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'TicketByBuildingData', setAllReportData, signal),
                fetchAdminData("/TicketsByGradeforReport/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'TicketByGradeData', setAllReportData, signal),
                fetchAdminData("/PartsAvrgforReports/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'PartUsedData', setAllReportData, signal),
                fetchAdminData("/AvrageTicketCloseRate/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'KpiData', setAllReportData, signal),
            ];
            try {
                const responses = await Promise.all(apiCalls);
            } catch (error) {
                console.log(error)
            } finally {
                setOnload((prevState) => ({ ...prevState, DeviceOnload: true }))
            }
        }
        if (Tabs?.StaffReports == "active" && !Onload?.StaffOnload) {
            setAllReportData((prevState) => ({
                ...prevState,
                FilterData: { ...prevState?.FilterData, ...params },
            }))
            const apiCalls = [
                fetchAdminData("/TicketCreatedbyStaffMembers/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + '&' + gradeid + '&' + buildingid, 'StaffTicketsData', setAllReportData, signal),
            ];
            try {
                const responses = await Promise.all(apiCalls);
            } catch (error) {
                console.log(error)
            } finally {
                setOnload((prevState) => ({ ...prevState, StaffOnload: true }))
            }
        }
        if (Tabs?.TechnologyTickets == "active" && !Onload?.TechnologyOnload) {
            setAllReportData((prevState) => ({
                ...prevState,
                FilterData: { ...prevState?.FilterData, ...params },
            }))
            const apiCalls = [
                fetchAdminData("/TechnologyTicketsReport/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'TechnologyTicketsData', setAllReportData, signal),
            ];
            try {
                const responses = await Promise.all(apiCalls);
            } catch (error) {
                console.log(error)
            } finally {
                setOnload((prevState) => ({ ...prevState, TechnologyOnload: true }))
            }
        }
        if (Tabs?.MaintenanceTickets == "active" && !Onload?.MaintenanceOnload) {
            setAllReportData((prevState) => ({
                ...prevState,
                FilterData: { ...prevState?.FilterData, ...params },
            }))
            const apiCalls = [
                fetchAdminData("/MaintenanceTicketsReport/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'MaintenanceTotalTicketsData', setAllReportData, signal),
            ];
            try {
                const responses = await Promise.all(apiCalls);
            } catch (error) {
                console.log(error)
            } finally {
                setOnload((prevState) => ({ ...prevState, MaintenanceOnload: true }))
            }
        }
        if (Tabs?.HelpDesk == "active" && !Onload?.HelpdeskOnload) {
            setAllReportData((prevState) => ({
                ...prevState,
                FilterData: { ...prevState?.FilterData, ...params },
            }))
            const apiCalls = [
                fetchAdminData("/HelpdeskTicketsReport/" + LogInSchoolID + "&" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate), 'HelpdeskTotalTicketsData', setAllReportData, signal),
            ];
            try {
                const responses = await Promise.all(apiCalls);
            } catch (error) {
                console.log(error)
            } finally {
                setOnload((prevState) => ({ ...prevState, HelpdeskOnload: true }))
            }
        }

    }

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (!isFirstRender.current) {
            GetAllData(startDate, endDate, grade, building);
        } else {
            isFirstRender.current = false;
        }
    }, [Tabs, Onload])
    function FuncTabActive(tabKey) {
        setTabs(prevTabs => {
            return Object.fromEntries(
                Object.entries(prevTabs).map(([key]) => [key, key === tabKey ? "active" : ""])
            );
        });
    }
    return (
        <React.Fragment>
            {Alert}
            <div className="row mx-0 pb-3">
                {Tabs.DeviceReports == 'active' || Tabs.StaffReports == 'active' ?
                    <div className='col-md-3' style={{ placeSelf: "center" }}>
                        <h1 className="PageHeading " >Reports</h1>
                    </div>
                    :
                    <div className='col-md-7' style={{ placeSelf: "center" }}>
                        <h1 className="PageHeading " >Reports</h1>
                    </div>
                }
                <div className="col-md-4 text-end row px-0">
                    <div className="col-md-6 d-flex  align-items-center px-0">
                        <label className="px-3"></label>
                        <ReactDatePicker
                            className="form-control me-4 text-center"
                            selected={startDate}
                            onChange={(e) => { setStartDate(e) }}
                            dateFormat="MM/dd/yyyy"
                        />
                    </div>
                    <div className="col-md-6 d-flex  align-items-center px-0">
                        <label className="px-3">To</label>
                        <ReactDatePicker
                            className="form-control me-4 text-center"
                            selected={endDate}
                            onChange={(e) => { setEndDate(e) }}
                            dateFormat="MM/dd/yyyy"
                            maxDate={today}
                        />
                    </div>
                </div>
                {Tabs.DeviceReports == 'active' || Tabs.StaffReports == 'active' ?
                    <div className="col-md-2 ms-2 py-1 pe-0">
                        <select value={grade} onChange={(e) => { setgrade(e.target.value) }}>
                            <option value="null">Filter by Grade</option>
                            {GradeArray.map((item, j) => (
                                <option value={item.ID} key={j}>
                                    {item.Grade}
                                </option>
                            ))}
                        </select>
                    </div>
                    : <></>
                }
                {Tabs.DeviceReports == 'active' || Tabs.StaffReports == 'active' ?
                    <div className="col-md-2 ms-2 py-1 pe-0">
                        <select value={building} onChange={(e) => { setbuilding(e.target.value) }}>
                            <option value="null">Filter by Building</option>
                            {BuildingArray.map((item, j) => (
                                <option value={item.ID} key={j}>
                                    {item.building}
                                </option>
                            ))}
                        </select>
                    </div>
                    : <></>
                }
                <div className="col-md-1 ms-2 py-1 pe-0">
                    <button className="btn btn-secondary w-100 filter-apply" onClick={() => {
                        setOnload({
                            DeviceOnload: false,
                            StaffOnload: false,
                            TechnologyOnload: false,
                            MaintenanceOnload: false,
                        })
                    }}>Apply</button>
                </div>
            </div>
            <div className='GridBox px-3'>
                <div className="row p-3">
                    <ul className="nav nav-tabs ">
                        {Object.entries({
                            DeviceReports: "Device Reports",
                            StaffReports: "Staff Reports",
                            TechnologyTickets: "Technology Tickets",
                            MaintenanceTickets: "Maintenance Tickets"
                        }).map(([key, value], index) => (
                            <Tab key={index} isActive={Tabs[key] == 'active'}
                                label={value} onClick={() => FuncTabActive(key)} />
                        ))}
                        {UserData.helpdeskUpdatedFlag != null && (
                            <Tab isActive={Tabs.HelpDesk == 'active'}
                                label="Other Helpdesk Tickets" onClick={() => FuncTabActive("HelpDesk")} />
                        )}
                    </ul>
                </div>
                <ReportDataContext.Provider value={{ AllReportData }}>
                    {Tabs.DeviceReports == "active" && (
                        <DeviceReportsIndex setAlerts={setAlerts} />
                    )}
                    {Tabs.StaffReports == "active" && (
                        <StaffReportsIndex setAlerts={setAlerts} />
                    )}
                    {Tabs.TechnologyTickets == "active" && (
                        <TechTicketsIndex />
                    )}
                    {Tabs.MaintenanceTickets == "active" && (
                        <MaintenanceIndex />
                    )}
                    {Tabs.HelpDesk == "active" && (
                        <OtherHelpdeskTickets />
                    )}
                </ReportDataContext.Provider>

            </div>
        </React.Fragment>
    )
}
