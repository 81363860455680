import React, { useState, useContext, useLayoutEffect } from "react";
import Chart from "react-apexcharts";
import { TableDataContext } from "../index";
import _ from 'lodash';

function NumberOfRepairs(props) {
    const { AllDashBoardData } = useContext(TableDataContext);
    const actualData = AllDashBoardData?.NumberOfRepairs?.data
    const [data, setData] = useState(actualData);
    useLayoutEffect(() => {
        setData(AllDashBoardData?.NumberOfRepairs?.data)
    }, [AllDashBoardData?.NumberOfRepairs?.data, AllDashBoardData?.FilterData])

    var categories = []
    var series = []
    categories = data?.map((item) => item.buildingName);
    series = data?.map((item) => item.count);
    const sumofcount = series?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const options = {
        chart: {
            height: 350,
            type: "bar",
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top", // Place labels on top of the bars
                },
                columnWidth: "50%",
            },
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
        },
        dataLabels: {
            style: {
                colors: ["black"],
            },
        },
        chart: {
            toolbar: {
                show: false,
            },
        },
        colors: ["#3CBBA5"],
        tooltip: {
            y: {
                formatter: function (val) {
                    return "Total Tickets: " + val;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    },
                },
            },
        },
    };
    return (
        <>
            <div className="col-12 text-center greyBoxShadow" style={{ height: "100%" }}>
                <div className="w-100">
                    <div className="row my-2 px-2 time">
                        <div className='Header'>
                            <b className='font-15'>Tickets Sent Out for Repaired by Building</b><br />
                        </div>
                    </div>
                    {AllDashBoardData?.NumberOfRepairs?.loader ? <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div> :
                        <div className="">
                            <div style={{ overflowX: "scroll" }}>
                                <div className="" style={{ width: series?.length > 10 ? "1500px" : "100%" }}>
                                    <Chart options={options} series={[{ data: series }]} type="bar" height={250} />
                                </div>
                                <h6 className='mt-3'>Total Send Out for Repaired Tickets : {sumofcount}</h6>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default NumberOfRepairs