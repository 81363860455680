import React from 'react'
import { useEffect } from 'react';

import { HideLoder, LogInSchoolID, LogInUserID, ShowLoder } from '../JS/Common';
import { useState } from 'react';
import { ApiGetCall, ApiPostCall } from '../JS/Connector';
import AlertsComp from './AlertsComp';
import Cookies from 'js-cookie';
import Popup from './Popup';

function TicketStatuspopup(props) {
    const [TicketStatus, setTicketStatus] = useState([]);
    const [NewAlerts, setNewAlerts] = useState("");
    const [TicketStatusData, setTicketStatusData] = useState({
        TicketAccess: '',
        TicketAccessForClose: 0,
        Errormsg: '',
        Errormsg1: '',
        ticketstatusnotes: ''
    })
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (props.GridFlag == 1 || props.GridFlag == 3) {
            var flag = 1
        } else if (props.GridFlag == 2) {
            var flag = 2
        } else if (props.GridFlag == 5) {
            var flag = 3
        }
        setLoading(true);
        ApiGetCall("/getTicketStatus/" + flag).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setTicketStatus(responseRs);
                setLoading(false);
            }
        });
        setTicketStatusData({ ...TicketStatusData, TicketAccess: props.TicketStatusId })
    }, [])
    async function StatusCall() {
        setTicketStatusData({ ...TicketStatusData, Errormsg: 'Select atleast one option' })
        if (TicketStatusData.TicketAccessForClose == 0 && [2, 7, 8].includes(TicketStatusData.TicketAccess) && props.Lonerflag == 'yes') {
            setTicketStatusData({ ...TicketStatusData, Errormsg1: 'Select atleast one option' })
            return
        }
        if (TicketStatusData.TicketAccess != '') {
            var vArray = []
            var vJson = {};
            vJson['TicketID'] = props.TicketId;
            vArray.push(vJson);
            var FlagforStatusForLoner = 0
            if (props.Lonerflag == 'yes') {
                var FlagforStatusForLoner = 1
            }
            var raw = JSON.stringify({
                UserId: LogInUserID,
                IssueIDArray: vArray,
                Status: TicketStatusData.TicketAccess,
                Flag: FlagforStatusForLoner,
                closestatus: TicketStatusData.TicketAccessForClose,
                schoolID: LogInSchoolID
            });
            setLoading(true)
            await ApiPostCall("/changeticketStatus", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    if (result == "success") {
                        setNewAlerts(<AlertsComp show={true} variant="success" msg={"Status Updated Successfully"} />);
                        setTimeout(function () {
                            setNewAlerts(<AlertsComp show={false} />)
                            props.ClosePopup();
                            props.clearSearch();
                            props.setSelectedIds([]);
                            if (props.GridFlag == 1) {
                                props.GetGridData(1, null, null, null)
                            } else if (props.GridFlag == 2) {
                                props.GetGridData(2, null, null, null)
                            } else if (props.GridFlag == 3) {
                                props.GetGridData(3, null, null, null)
                            }
                        }, 1500);
                    } else {
                        setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                        setTimeout(() => {
                            setNewAlerts(<AlertsComp show={false} />)
                        }, 2000);
                    }
                }
            });
        }
    }
    async function StatusCallForAdmin() {
        setTicketStatusData({ ...TicketStatusData, Errormsg: 'Select atleast one option' })
        if (TicketStatusData.TicketAccess != '') {
            var vArray = [];
            var vjson = {};
            vjson['ID'] = props.TicketId;
            vjson['StudentID'] = props.StudentidForSatuschange == undefined ? null : props.StudentidForSatuschange;
            vArray.push(vjson);
            var raw = JSON.stringify({
                UserId: LogInUserID,
                TicketIDArray: vArray,
                Status: TicketStatusData.TicketAccess,
                Note: TicketStatusData.ticketstatusnotes,
                WhoWorkedOn: Cookies.get('CsvUserId'),
                schoolID: props.schoolId
            });
            setLoading(true)
            await ApiPostCall("/AdminChangeTicketStatus", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    if (result == "success") {
                        setNewAlerts(<AlertsComp show={true} variant="success" msg={"Status Updated Successfully"} />);
                        setTimeout(function () {
                            setNewAlerts(<AlertsComp show={false} />)
                            props.ClosePopup();
                            var batchid = props.selectedbatchid
                            if (batchid == 0) {
                                batchid = null
                            }
                            if (props.flag == 4) {
                                props.GetGridData(null, 4, batchid, null)
                            } else if (props.flag == 1) {
                                props.GetGridData(null, 1, batchid, null)
                            } else if (props.flag == 2) {
                                props.GetGridData(null, 2, batchid, null)
                            } else {
                                props.GetGridData(null, 3, batchid, null)
                            }
                        }, 1500);
                    } else {
                        setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                        setTimeout(() => {
                            setNewAlerts(<AlertsComp show={false} />)
                        }, 2000);
                    }
                }
            });
        }
    }
    const modalbody = (
        <>
            <div>
                {TicketStatus.map((item, i) => {
                    var returData;
                    returData = (
                        <div className="col-md-12 py-2 pe-0" key={i}>
                            {props.TicketStatusId == item.id ?
                                <input className="form-check-input me-2 TicketStatus" defaultChecked statusid={item.id} type="radio" name="ticketstatus"
                                    onChange={(e) => setTicketStatusData({ ...TicketStatusData, TicketAccess: item.id, Errormsg1: '' })} />
                                :
                                <input className="form-check-input me-2 TicketStatus" statusid={item.id} type="radio" name="ticketstatus"
                                    onChange={(e) => setTicketStatusData({ ...TicketStatusData, TicketAccess: item.id, Errormsg1: '' })} />
                            }

                            {item.status}
                        </div>
                    );
                    return returData;
                })}
                {([2, 7, 8].includes(TicketStatusData.TicketAccess) && props.Lonerflag === 'yes') ?
                    <div className="col-12" id="CLoseTicketLoanerDivId">
                        <hr />
                        <h4>Close Ticket</h4>
                        <hr />
                        <div className="col-12 pb-3">
                            <input className="form-check-input me-2 CommonCloseTicketClass" type="radio" name="CloseStatus" id="FreeDevice"
                                onChange={(e) => setTicketStatusData({ ...TicketStatusData, TicketAccessForClose: 2 })} />Give Original Device Back To User?
                        </div>
                        <div className="col-12">
                            <input className="form-check-input me-2 CommonCloseTicketClass" type="radio" name="CloseStatus" id="ParmanentDevice"
                                onChange={(e) => setTicketStatusData({ ...TicketStatusData, TicketAccessForClose: 1 })} /> Keep loaner device as a permanent device for student?
                        </div>
                    </div>
                    : <></>}

                {props.GridFlag == 5 && (
                    <div className="col-12">
                        <textarea rows={3} className="form-control" value={TicketStatusData.ticketstatusnotes} onChange={(e) => setTicketStatusData({ ...TicketStatusData, ticketstatusnotes: e.target.value })}></textarea>
                        <span>Notes</span>
                    </div>
                )}
            </div>

            {TicketStatusData.TicketAccess == '' ?
                <div className=" px-5" id='statusMsg' style={{ color: 'red' }}>{TicketStatusData.Errormsg}</div>
                : <></>
            }
            {TicketStatusData.TicketAccessForClose == 0 ?
                <div className=" px-5" id='statusMsg' style={{ color: 'red' }}>{TicketStatusData.Errormsg1}</div>
                : <></>
            }
        </>
    )
    return (
        <>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"md"} title={"Ticket Status"}
                closePopup={props.ClosePopup}
                modalBody={modalbody} handleSave={(e) => props.GridFlag != 5 ? StatusCall(e) : StatusCallForAdmin(e)} visibleSaveBtn={true} btnText={"Change Status"} />
        </>
    )
}

export default TicketStatuspopup
