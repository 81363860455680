import React from 'react'
import { Modal } from 'react-bootstrap'

export default function AlertPopup(props) {
    return (
        <Modal show={props.isshow}>
            <div className="text-center p-3">
                <img src={props.img} className="img-fluid ps-2 mt-3" />
                <h5 className="fw-600 mt-2">{props.headingtext}</h5>
                <div className="mt-3">{props.text}</div>
                <div className="col-12 text-center py-4">
                    <button className={props.btntype} onClick={(e) => props.successcall(e)}>{props.successtext}</button>
                    <label className="ps-4 cursor-pointer" onClick={props.cancelcall}><u>{props.canceltext}</u></label>
                </div>
            </div>
        </Modal>
    )
}
