import React, { useContext, useEffect, useRef, useState } from 'react'
import { ApiGetCall } from '../JS/Connector';
import { LogInSchoolID, formatDateToMMDDYYYY, getAllGrades, isValidEmail, typewatch } from '../JS/Common';
import { CheckValidation } from './Validations';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertsComp from './AlertsComp';
import CloseInvoicePopup from './CloseInvoicePopup';
import { UserContext } from '../App';
import PageHead from './PageHead';

function CloseTicketInvoice() {
    const navigate = useNavigate()
    const [allData, setAllData] = useState({
        FirstName: "",
        MiddleName: '',
        LastName: "",
        StudentNumber: "",
        ParentEmail: "",
        ParentName: "",
        ParentPhoneNumber: "",
        ParentCheck: 2,
        emailError: "",
        Grade: "",
        Department: "",
        StudentId: null,
        IsStudent: 0
    })
    const [totalCost, setTotalCost] = useState('');
    const [ticketDetails, setticketDetails] = useState('')
    const [loading, setLoading] = useState(false);
    const [ExistingNew, setExistingNew] = useState(0);
    const [ParentEmail, setParentEmail] = useState(0);
    const [FormShowHide, setFormShowHide] = useState({
        ShowSuggestion: "d-none",
        UpdateDiv: "d-none",
    });
    const [SuggestionBoxArray, setSuggestionBoxArray] = useState([]);
    const [Alerts, setAlerts] = useState("");
    const [SearchString, setSearchString] = useState('');
    const [SchoolAddress, setSchoolAddress] = useState('')
    const [Exisitingerror, setExisitingerror] = useState("")
    const [TicketIssues, setTicketIssues] = useState("")
    const [TicketNote, setTicketNote] = useState("")
    const [TicketParts, setTicketParts] = useState("")
    const [InvoiceModal, setInvoiceModal] = useState(false)
    const [InvoiceModalData, setInvoiceModalData] = useState('')
    const [smallLoder, setSmallLoder] = useState(false)
    const [Grades, setGrades] = useState([]);
    const [GradeOtherDiv, setGradeOtherDiv] = useState('d-none');
    const [customGrade, setCustomGrade] = useState("");
    const location = useLocation();
    const TicketId = location.state && location.state.Ticketid;
    const AllTickets = location.state && location.state.tickets;
    const suggestionBoxRef = useRef(null);
    const { UserData } = useContext(UserContext);
    const [currentTicketIndex, setCurrentTicketIndex] = useState(0);
    useEffect(() => {
        getAllGrades(setGrades);
        ApiGetCall("/SchoolAddressbyID/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length != 0) {
                    setSchoolAddress(responseRs.schoolAddress)
                }
            }
        });
        const handleClickOutside = (event) => {
            if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
                setFormShowHide({ ...FormShowHide, ShowSuggestion: "d-none" });
            }
        };
        const handleWindowClick = (event) => {
            handleClickOutside(event);
        };
        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);
    useEffect(() => {
        GetData();
    }, [currentTicketIndex]);
    async function GetData() {
        setLoading(true)
        var ticketid;
        if (AllTickets && AllTickets.length > 0) {
            ticketid = AllTickets[currentTicketIndex].ID;
        } else {
            ticketid = TicketId;
        }
        await ApiGetCall("/getTicketDataById/" + ticketid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result)
                setLoading(false);
                var sugData = responseRs.ticket;
                if (responseRs.response == "success") {
                    ClearState();
                    setParentEmail(0);
                    setticketDetails(sugData);
                    setTicketIssues('');
                    const ticketIssues = sugData.ticket_issues.map((item) => item.issue_name).join(", ");
                    setTicketIssues(ticketIssues)
                    setTicketNote('');
                    const ticketNote = sugData.notes || '';
                    setTicketNote(ticketNote)
                    setTicketParts('');
                    const ticketParts = sugData.ticket_attachments.length != 0 ?
                        sugData.ticket_attachments.map((item) => item.part_name).join(', ') : 'No Parts Attached'
                    setTicketParts(ticketParts)
                    if (sugData.Student != null) {
                        setAllData({
                            ...allData, FirstName: sugData.Student.device_user_first_name,
                            LastName: sugData.Student.device_user_last_name, MiddleName: sugData.Student.device_user_middle_name,
                            StudentNumber: sugData.Student.student_num, ParentEmail: sugData.Student.parent_guardian_email, ParentName: sugData.Student.parent_guardian_name, ParentPhoneNumber: sugData.Student.parent_phone_number, ParentCheck: sugData.Student.parental_coverage,
                            StudentId: sugData.Student.id, Grade: sugData.Student.grade, Department: sugData.Student.department, IsStudent: sugData.Student.is_student
                        })
                        setExistingNew(1);
                    } else {
                        setAllData({
                            ...allData, FirstName: "", MiddleName: '', LastName: "", StudentNumber: "", ParentEmail: "", ParentName: "", ParentPhoneNumber: "", ParentCheck: 2, emailError: "", Grade: "", Department: "", StudentId: null, IsStudent: 0
                        })
                        setExistingNew(0);
                    }
                    setTotalCost('')
                }
            }
        });
    }
    const handleChange = (flag) => {
        if (flag == "yes") {
            setAllData({ ...allData, ParentCheck: true, CheckParentCoverage: 1 })
        } else {
            setAllData({ ...allData, ParentCheck: false, CheckParentCoverage: 0 })
        }
    }
    function PreviewInvoice() {
        var isFormValid = CheckValidation({ formID: 'TicketData' });
        let errors = {};
        if (ticketDetails.Student == null) {
            if (allData.FirstName == "") {
                setExisitingerror("First you need to add user.")
                isFormValid = false;
            }
        }
        if (allData.ParentEmail !== "" && allData.ParentEmail !== null) {
            if (!isValidEmail(allData.ParentEmail)) {
                errors.emailError = "*Invalid Email";
                isFormValid = false;
            } else {
                errors.emailError = "";
            }
        }
        setAllData({ ...allData, ...errors });
        if (!isFormValid) return;
        setLoading(true);
        var flagfortype = null
        if (ticketDetails.Student != null) {
            flagfortype = 1
        } else if (ExistingNew == 0) {
            flagfortype = 0
        } else if (ExistingNew == 1) {
            flagfortype = 2
        }
        var ticketid;
        if (AllTickets && AllTickets.length > 0) {
            ticketid = AllTickets[currentTicketIndex].ID;
        } else {
            ticketid = TicketId;
        }

        var raw = {
            Serial: ticketDetails.inventory_management.serial_number,
            SchoolAddress: SchoolAddress,
            SchoolnameOnly: UserData.schoolname,
            SchoolLogoOnly: UserData.schoollogo,
            CreatedDate: formatDateToMMDDYYYY(ticketDetails.created_at),
            Ticket: ticketDetails.ticket_num,
            Issues: TicketIssues,
            CreatedBy: ticketDetails.ticketCreatedBy,
            Building: ticketDetails.inventory_management.building ? ticketDetails.inventory_management.building.name : '-',
            Notes: TicketNote,
            PartsUsed: TicketParts,
            FirstName: allData.FirstName,
            MiddleName: allData.MiddleName,
            LastName: allData.LastName,
            StudentNumber: allData.StudentNumber,
            Grade: allData.Grade,
            Department: allData.Department,
            ParentEmail: allData.ParentEmail,
            ParentName: allData.ParentName,
            ParentPhoneNumber: allData.ParentPhoneNumber,
            ParentCheck: allData.ParentCheck,
            TotalCost: totalCost,
            TicketId: ticketid,
            StudentId: allData.StudentId,
            StudentType: flagfortype,
            ParentEmailFlag: ParentEmail,
            IsStudent: allData.IsStudent
        };
        setLoading(false);
        setInvoiceModalData(raw)
        setInvoiceModal(true)
    }
    async function SearchUsers(SearchString) {
        setSuggestionBoxArray([]);
        if (SearchString == "" || SearchString == null) {
            setFormShowHide({ ...FormShowHide, ShowSuggestion: "d-none" });
            SearchString = null;
        }
        typewatch(async function () {
            setSmallLoder(true)
            await ApiGetCall("/SearchUtilizer/" + LogInSchoolID + "&" + SearchString).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setSmallLoder(false)
                    const responseRs = JSON.parse(result);
                    var sugData = responseRs;
                    var vArray = [];
                    if (sugData.length != 0) {
                        setFormShowHide({ ...FormShowHide, ShowSuggestion: "" });
                        for (var i = 0; i < sugData.length; i++) {
                            var html = <div className="row brdr-Btm font-14" key={i} onClick={(e) => ShowUserDetails(e)} utilizerid={sugData[i].id}>
                                <div className='col-9' style={{ padding: "2px 15px" }}>User : <b>{sugData[i].device_user_first_name} {sugData[i].device_user_middle_name == null ? '' : sugData[i].device_user_middle_name} {sugData[i].device_user_last_name}</b></div>
                                <div className="col-3 text-end" style={{ padding: "2px 15px" }}>{sugData[i].student_num}</div>
                                <div className="col-12" style={{ padding: "2px 15px" }}>{sugData[i].parent_guardian_email}</div>
                            </div>
                            vArray.push(html);
                        }
                    } else {
                        var html = <div className="brdr-Btm font-14 text-center">
                            No Record Found
                        </div>
                        vArray.push(html)
                    }
                    setSuggestionBoxArray(vArray);
                    setLoading(false);
                }
            });
        }, 500);
    }
    async function ShowUserDetails(e) {
        var utilizerid = e.currentTarget.attributes[1].value;
        await ApiGetCall("/utilizerdatabyID/" + utilizerid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                var sugData = responseRs.msg;
                if (responseRs.status == "success") {
                    setFormShowHide({ ...FormShowHide, ShowSuggestion: "d-none", UpdateDiv: "" });
                    setSearchString('')
                    setAllData({
                        ...allData, FirstName: sugData.device_user_first_name, MiddleName: sugData.device_user_middle_name, LastName: sugData.device_user_last_name,
                        StudentNumber: sugData.student_num, ParentName: sugData.parent_guardian_name,
                        ParentEmail: sugData.parent_guardian_email, ParentPhoneNumber: sugData.parent_phone_number, ParentCheck: sugData.parental_coverage,
                        ParentId: sugData.id, Grade: sugData.grade, StudentId: sugData.id, Department: sugData.department,
                        IsStudent: sugData.is_student
                    })
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        });
    }
    function ClearState() {
        setAllData({
            FirstName: "",
            MiddleName: '',
            LastName: "",
            StudentNumber: "",
            ParentEmail: "",
            ParentName: "",
            ParentPhoneNumber: "",
            ParentCheck: 2,
            emailError: "",
            Grade: "",
            Department: "",
            StudentId: null,
            IsStudent: 0
        });
        setTotalCost('')
    };
    const handleCheckboxChange = (e) => {
        setAllData({
            ...allData,
            IsStudent: e.target.checked ? 1 : 0,
        });
    };
    return (
        <>
            <PageHead heading={"Generate invoice for Parent/Student"}
                loading={loading} Alerts={Alerts} showsearch={false} />
            <div className="GridBox mt-2 p-3">
                <div>
                    <div className="parent" id="TicketData">
                        <div className="row">
                            {ticketDetails && (
                                <div className='col-md-12 ps-3'>
                                    <div className='greyBox row'>
                                        <div className='row px-0 align-items-center'>
                                            <div className='col-md-9'>
                                                <b className='h4 m-0'>Ticket Details</b>
                                            </div>
                                            <div className='col-md-3 text-end'>
                                                <b className='font-13'>Created Date : </b>{formatDateToMMDDYYYY(ticketDetails.created_at)}
                                            </div>
                                        </div>
                                        <hr className='mt-2' />
                                        <div className='col-lg-4 py-1'>
                                            <div><b className='font-13'>Serial # : </b> {ticketDetails.inventory_management.serial_number}</div>
                                        </div>
                                        <div className='col-lg-4 py-1'>
                                            <div><b className='font-13'>Ticket # : </b> {ticketDetails.ticket_num}</div>
                                        </div>
                                        <div className='col-lg-4 py-1'>
                                            <div><b className='font-13'>Building : </b>{ticketDetails.inventory_management.building ? ticketDetails.inventory_management.building.name : '-'}</div>
                                        </div>
                                        <div className='col-lg-4 py-1'>
                                            <div><b className='font-13'>Issue : </b>
                                                <textarea type="textarea" rows={2} autoComplete="off" name="RepairedNm" className="form-control"
                                                    value={TicketIssues} onChange={(e) => setTicketIssues(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 py-1'>
                                            <div><b className='font-13'>Notes : </b>
                                                <textarea type="textarea" rows={2} autoComplete="off" name="Note" className="form-control"
                                                    value={TicketNote} onChange={(e) => setTicketNote(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 py-2'>
                                            <div ><b className='font-13'>Parts Used : </b>
                                                <textarea type="textarea" rows={2} autoComplete="off" name="part" className="form-control"
                                                    value={TicketParts} onChange={(e) => setTicketParts(e.target.value)} /></div>
                                        </div>
                                        <div className='Header d-flex pt-5 align-items-center'>
                                            <b className='h4 pe-5'>User Details</b>
                                            {ticketDetails.Student == null && (
                                                <div className='d-flex'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name='existingnew' checked={ExistingNew == 1} onChange={() => { setExistingNew(1); ClearState() }} />
                                                        <label className="form-check-label"> Existing</label>
                                                    </div>
                                                    <div className="form-check ms-3">
                                                        <input className="form-check-input" type="radio" name='existingnew' checked={ExistingNew == 0} onChange={() => { setExistingNew(0); ClearState() }} />
                                                        <label className="form-check-label"> New </label>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <hr className='m-2' />
                                        {ticketDetails.Student != null ?
                                            <div className='row pb-3'>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Device User First Name : </b>{ticketDetails?.Student?.device_user_first_name || '-'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Device User Middle Name : </b>{ticketDetails?.Student?.device_user_middle_name || '-'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Device User Last Name : </b>{ticketDetails?.Student?.device_user_last_name || '-'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>User ID : </b>{ticketDetails?.Student?.student_num || '-'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Is the user a student? : </b>{ticketDetails?.Student?.is_student == 1 ? 'Yes' : 'No'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Grade : </b>{ticketDetails?.Student?.grade || '-'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Department : </b>{ticketDetails?.Student?.department || '-'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Parent Email : </b>{ticketDetails?.Student?.parent_guardian_email || '-'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Parent Name : </b>{ticketDetails?.Student?.parent_guardian_name || '-'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Parent Contact : </b>{ticketDetails?.Student?.parent_phone_number || '-'} </div>
                                                </div>
                                                <div className='col-lg-6 py-2'>
                                                    <div><b className='font-13'>Parental Coverage : </b>{ticketDetails?.Student?.parental_coverage == 1 ? 'Yes' : 'No'}</div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {ExistingNew == 0 && (
                                                    <div className="row mt-2">
                                                        <div className='col-md-4 align-items-center pt-2'>
                                                            <div className='FormLabel'>Device User First Name<label className='redText'>*</label></div>
                                                            <div>
                                                                <input type="text" autoComplete='off' name='firstname' required className="form-control" value={allData.FirstName}
                                                                    onChange={(e) => setAllData({ ...allData, FirstName: e.target.value })} />
                                                                <span className="form-text invalid-feedback">
                                                                    *required
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 align-items-center pt-2'>
                                                            <div className='FormLabel'>Device User Middle Name</div>
                                                            <div>
                                                                <input type="text" autoComplete='off' name='middleName' className="form-control" value={allData.MiddleName}
                                                                    onChange={(e) => setAllData({ ...allData, MiddleName: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 align-items-center pt-2'>
                                                            <div className='FormLabel'>Device User Last Name<label className='redText'>*</label></div>
                                                            <div>
                                                                <input type="text" autoComplete='off' name='lastName' required className="form-control" value={allData.LastName}
                                                                    onChange={(e) => setAllData({ ...allData, LastName: e.target.value })} />
                                                                <span className="form-text invalid-feedback">
                                                                    *required
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 align-items-center pt-3'>
                                                            <div className='FormLabel'> User ID<label className='redText'>*</label></div>
                                                            <div>
                                                                <input type="text" name='studentID' required autoComplete='off' className="form-control" value={allData.StudentNumber}
                                                                    onChange={(e) => setAllData({ ...allData, StudentNumber: e.target.value.toUpperCase() })} />
                                                                <span className="form-text invalid-feedback"> *required </span>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 align-items-center pt-3'>
                                                            <div className='FormLabel'>Parent Name</div>
                                                            <div>
                                                                <input type="text" autoComplete='off' name='guardianName' className="form-control" value={allData.ParentName}
                                                                    onChange={(e) => setAllData({ ...allData, ParentName: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 align-items-center pt-3'>
                                                            <div className='FormLabel'>Parent Contact</div>
                                                            <div>
                                                                <input type="number" autoComplete='off' name='parentphonenumber' className="form-control" value={allData.ParentPhoneNumber}
                                                                    onChange={(e) => setAllData({ ...allData, ParentPhoneNumber: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 d-flex align-items-center pt-4 justify-content-between">
                                                            <div className='FormLabel'>Is the user a student?</div>
                                                            <div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name='isstudent'
                                                                        checked={allData.IsStudent == 1}
                                                                        onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label">
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 align-items-center pt-3'>
                                                            <div className='FormLabel'>Grade</div>
                                                            <div>
                                                                <select className="gradeselect" defaultValue={allData.Grade} disabled={allData.IsStudent == 0} onChange={(e) => {
                                                                    setAllData({ ...allData, Grade: e.target.value });
                                                                    if (e.target.value == "other") {
                                                                        setAllData({ ...allData, Grade: "" });
                                                                        setGradeOtherDiv("");
                                                                        setCustomGrade("");
                                                                    }
                                                                    else {
                                                                        setGradeOtherDiv("d-none");
                                                                        setAllData({ ...allData, Grade: e.target.value })
                                                                    }
                                                                }}>
                                                                    <option value="">Select</option>
                                                                    {Grades.map((grade, key) => (
                                                                        <option key={key} value={grade} selected={allData.Grade == grade}>{grade}</option>
                                                                    ))}
                                                                    <option value="other">Other</option>
                                                                </select>
                                                                <div className={`${GradeOtherDiv} mt-2`}>
                                                                    <input type="text" className='form-control' placeholder="Enter Grade" value={customGrade} onChange={(e) => { setCustomGrade(e.target.value.toUpperCase()); setAllData({ ...allData, Grade: e.target.value.toUpperCase() }) }} />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 align-items-center pt-3'>
                                                            <div className='FormLabel'>Department</div>
                                                            <div>
                                                                <input type="text" autoComplete='off' name='department' className="form-control" value={allData.Department}
                                                                    onChange={(e) => setAllData({ ...allData, Department: e.target.value })} disabled={allData.IsStudent == 1} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 align-items-center pt-3'>
                                                            <div className='FormLabel'>Parent Email</div>
                                                            <div>
                                                                <input type="text" name='guardianemail' autoComplete='off' className="form-control" value={allData.ParentEmail}
                                                                    onChange={(e) => setAllData({ ...allData, ParentEmail: e.target.value })} />
                                                                <span className="Emailinvalid">
                                                                    {allData.emailError}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-4 d-flex align-items-center justify-content-between pt-4'>
                                                            <div className='FormLabel pe-3'>Parental Coverage</div>
                                                            <div className='d-flex'>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name='ParentalCoverage' checked={allData.ParentCheck == 1 ? true : false} onChange={(e) => handleChange("yes")} />
                                                                    <label className="form-check-label"> Yes  </label>
                                                                </div>
                                                                <div className="form-check ms-5">
                                                                    <input className="form-check-input" type="radio" name='ParentalCoverage' checked={allData.ParentCheck == 0 ? true : false} onChange={(e) => handleChange("no")} />
                                                                    <label className="form-check-label">   No  </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {ExistingNew == 1 && (
                                                    <div>
                                                        <div className={`col-md-8 my-2 d-flex`}>
                                                            <form className="gridsearchbar col-md-6" onSubmit={(e) => e.preventDefault()}>
                                                                <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                                                                <input className="form-control" autoComplete="off" type="text" placeholder="Search User" onKeyUp={(e) => SearchUsers(e.target.value)} value={SearchString} onChange={(e) => setSearchString(e.target.value)} />
                                                                <div className={`SuggestionBoxDiv ${FormShowHide.ShowSuggestion}`} style={{ zIndex: '1' }} ref={suggestionBoxRef}>
                                                                    {SuggestionBoxArray}
                                                                </div>
                                                            </form>
                                                            {smallLoder == true && (<div className='row px-2'><div className="text-center"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div>)}
                                                        </div>
                                                        <div className='row pb-3'>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>Device User First Name : </b>{allData.FirstName || '-'} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>Device User Middle Name : </b>{allData.MiddleName || '-'} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>Device User Last Name : </b>{allData.LastName || '-'} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>User ID : </b>{allData.StudentNumber || '-'} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>Is the user a student? : </b>{allData?.IsStudent == 1 ? 'Yes' : 'No'} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>Grade : </b>{allData.Grade || '-'} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>Department : </b>{allData.Department || '-'} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>Parent Email : </b>{allData.ParentEmail ? allData.ParentEmail : '-'} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>Parent Name : </b>{allData.ParentName ? allData.ParentName : <>-</>} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b className='font-13'>Parent Contact : </b>{allData.ParentPhoneNumber || '-'} </div>
                                                            </div>
                                                            <div className='col-lg-6 py-2'>
                                                                <div><b>Parental Coverage : </b>{allData.ParentCheck == 1 ? 'Yes' : 'No'}</div>
                                                            </div>
                                                        </div>
                                                        <h6 className="redText">{Exisitingerror}</h6>
                                                    </div>
                                                )}
                                            </>
                                        }
                                        <div className='row pt-5 justify-content-center text-center'>
                                            <div className='col-md-2'>
                                                <div className='FormLabel'>Total Cost<label className='redText'>*</label></div>
                                                <div className="input-group pt-2">
                                                    <span className="input-group-text">$</span>
                                                    <input type="text" required autoComplete='off' className="form-control" name='totalcost'
                                                        placeholder='00' value={totalCost} pattern="[0-9]+(\.[0-9]+)?"
                                                        onChange={(e) => setTotalCost(e.target.value.replace(/[^0-9.]/g, ''))}
                                                    />
                                                    <span className="form-text invalid-feedback">  *required</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-12 py-3 text-center'>
                                            <input
                                                className="form-check-input mt-1 cursor-pointer"
                                                type="checkbox"
                                                checked={ParentEmail == 1}
                                                onChange={(e) => setParentEmail(1)}
                                            /> Send Invoices to Parent's Emails
                                        </div>
                                        <div className='col-12 d-flex text-center pb-5 justify-content-center align-items-center'>
                                            <button className="SaveBtn col-md-1" onClick={PreviewInvoice}>Preview</button>
                                            <label className="ms-2 cursor-pointer">
                                                <u onClick={(e) => navigate('/school-invoice', { state: { cancelflag: 1 } })}>Cancel</u>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div >

            {InvoiceModal && (
                <CloseInvoicePopup isShow={InvoiceModal} data={InvoiceModalData} setCurrentTicketIndex={setCurrentTicketIndex}
                    closeInvoiceModal={() => setInvoiceModal(false)} AllTickets={AllTickets} currentTicketIndex={currentTicketIndex} />
            )}
        </>
    )
}

export default CloseTicketInvoice
