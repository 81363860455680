import React, { useEffect, useRef, useState } from 'react'
import { CheckValidation } from '../../Components/Validations';
import { LogInSchoolID, convertToBase64 } from '../../JS/Common';
import { ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Popup from '../../Components/Popup';

export default function UpdateCategoryModal(props) {
    const [name, setName] = useState(props.UpdateData.Name);
    const [description, setdescription] = useState(props.UpdateData.description);
    const [icon, seticon] = useState(props.UpdateData.icon);
    const quillRef = useRef(null);
    var data = props.UpdateData;
    const customImageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/svg+xml,image/png,image/jpeg,image/jpg');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            if (file.size > 2 * 1024 * 1024) {
                alert("Image size exceeds 2MB limit.");
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                quill.insertEmbed(range.index, 'image', e.target.result);
            };
            reader.readAsDataURL(file);
        };
    };
    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.getModule('toolbar').addHandler('image', customImageHandler);
        }
    }, []);
    const UpdateCategory = async () => {
        var isFormValid = CheckValidation({ formID: 'editdiv' });
        if (!isFormValid) return;
        var raw = JSON.stringify({
            UpdateId: data.Updateid,
            SchoolID: LogInSchoolID,
            Name: name,
            Flag: data.Flag,
            description: description,
            icon: icon
        });
        await ApiPostCall("/UpdateIssueandCategory", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                if (result == "success") {
                    props.setAlerts(<AlertsComp show={true} variant="success" msg={`Data Updated Successfully`} />);
                    props.ClosePopup()
                    props.getcall();
                    setTimeout(() => {
                        props.setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    props.setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        props.setAlerts(<AlertsComp show={false} />)
                    }, 1000);
                }
            }
        });
    }
    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };
    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];
    function handleContentChange(content) {
        setdescription(content);
    }
    async function handleFileUpload(e) {
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            alert("File size exceeds 2MB limit.");
            return;
        }
        const base64 = await convertToBase64(file);
        seticon(base64);
    };
    const modalbody = (
        <div className='col-12 parent' id='editdiv'>
            <div className='row'>
                <div className={`${data.headername != "Edit Helpdesk Type" ? 'col-9' : 'col-12'}`}>
                    {name != "Technology" && name != "Maintenance" ?
                        <>
                            <input type="text" autoComplete='off' name='serialnumber'
                                className="form-control" required defaultValue={name} onChange={(e) => setName(e.target.value)} />
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </>
                        :
                        <input type="text" autoComplete='off' name='serialnumber'
                            className="form-control" defaultValue={name} disabled />
                    }

                </div>
                {data.headername != "Edit Helpdesk Type" && (
                    <div className='col-md-3 px-0'>
                        {icon ?
                            <div className='d-flex align-items-center ps-3'>
                                <img src={icon} alt="" className='categoryimg-icon' />
                                <FontAwesomeIcon className='ps-2' icon={faTimes} style={{ cursor: 'pointer' }} onClick={(e) => seticon('')} title='Remove Uploaded Icon' />
                            </div>

                            :
                            <form className="col-12">
                                <input type="file" label="Image" name="myFile" accept=".jpg,.png,.svg,.jpeg" id="UploadIconFileId" onChange={(e) => handleFileUpload(e)} />
                                <label className="BorderBtn font-12 m-0 p-2" htmlFor="UploadIconFileId"><img src='/images/AddInventory.svg' className='img-fluid pe-1' style={{ height: "10px" }} /> Upload Icon</label>
                            </form>
                        }
                    </div>
                )}
            </div>
            <div style={{ justifyContent: "center" }} className='mt-2 Description'>
                <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder="Description ...."
                    onChange={handleContentChange}
                    value={description}
                >
                </ReactQuill>
            </div>
        </div>
    )
    return (
        <Popup isshow={props.show} size={"md"} title={data.headername}
            closePopup={props.ClosePopup}
            modalBody={modalbody} handleSave={UpdateCategory} visibleSaveBtn={true} btnText={"Update"} />
    )
}
