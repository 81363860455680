import React, { useEffect, useState } from 'react'
import { ApiGetCallAuthKey, ApiPostCallAuthKey } from '../../JS/Connector';
import { HideLoder, ShowLoder } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ConfirmSchool() {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const schoolid = pathArray[2];
    const [UserDetails, setUserDetails] = useState({
        FirstName: "",
        LastName: "",
        Id: ""
    })
    const [UserEmail, setUserEmail] = useState('')
    const [AccessTypes, setAccessTypes] = useState([])
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [ActiveAccessType, setActiveAccessType] = useState(1);
    useEffect(() => {
        setLoading(true);
        ApiGetCallAuthKey("/schoolContactById/" + schoolid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setUserEmail(responseRs.user?.email);
                setUserDetails({ ...UserDetails, Id: responseRs.user?.id, FirstName: responseRs.user?.first_name, LastName: responseRs.user?.last_name })
                setAccessTypes(responseRs?.accessTypes)
            }
        });
    }, [])
    async function confirmSchoolCall() {
        var raw = JSON.stringify({
            schoolId: schoolid,
            userId: UserDetails.Id,
            accessId: ActiveAccessType
        });
        await ApiPostCallAuthKey("/sameDomainSchoolConfirmClick", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Staff Member Added Successfully."} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        window.location.href = '/'
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
            <div className="position-relative MainDiv">
                <div className="card mx-auto">
                    <div className="p-4 row">
                        <p>Staff Member Name : <b>{UserDetails.FirstName} {UserDetails.LastName}</b></p>
                        <p>Staff Member Email : <b>{UserEmail}</b></p>
                        <div className="col-md-4 offset-md-4">
                            <img src="/images/righticonsetup.svg" className="img-fluid my-3" style={{ height: '45px' }} />
                        </div>
                        <div className="pt-3" style={{ color: '#495057' }}><h5>Are you sure you want to confirm the <span className='fw-600 whats-cover-text'>{UserEmail}</span> as a staff member?</h5></div>
                        <div className=' justify-content-center pt-3'>
                            <label className='pb-2'>In which access type would you like to add this staff member?</label>
                        </div>
                        <div className='row m-auto' style={{ paddingLeft: "7rem" }}>
                            {AccessTypes.map((devicetype, index) => {
                                return (
                                    <div className="col-md-6 pb-1 px-0 text-start" key={index}>
                                        <input
                                            className="form-check-input repairtechIssueClass me-2"
                                            type="radio"
                                            name="AccessType"
                                            checked={ActiveAccessType == devicetype.id}
                                            onChange={() => setActiveAccessType(devicetype.id)}
                                        />
                                        <label className="form-check-label font-13">{devicetype.access_type}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='text-center pt-3'>
                            <button className='SaveBtn my-3 font-15 px-3' onClick={confirmSchoolCall}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfirmSchool
