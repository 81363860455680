import React, { createContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LogInSchoolID, convertToBase64 } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import { ApiGetCallAuthKey, ApiPostCall } from '../../JS/Connector';
import Subcategory from './Subcategory';
import TextEditor from './TextEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import PageHead from '../../Components/PageHead';
import AlertPopup from '../../Components/AlertPopup';
export const questiondetailscontext = createContext();
function HelpdeskTicket() {
    const navigate = useNavigate();
    const location = useLocation();
    const CategoryId = location.state && location.state.categoryid;
    const [HelpdeskDetails, setHelpdeskDetails] = useState({
        TicketType: '',
        MainCategoryValue: '',
        Has_subcategory: false,
        SubCategory: [],
        description: '',
        icon: ''
    });
    const [errorMessages, setErrorMessages] = useState('');
    const [CategoryData, setCategoryData] = useState('');
    const [showsubcategory, setshowsubcategory] = useState(false);
    const [loading, setLoading] = useState('');
    const [Alerts, setAlerts] = useState('');
    const [MainCategoryVisibility, setMainCategoryVisibility] = useState('');
    const [subcategoryerror, setsubcategoryerror] = useState('');
    const [ShowSubcategory, setShowSubcategory] = useState(2);
    const [ConfirmationCancelModal, setConfirmationCancelModal] = useState(false);
    useEffect(() => {
        ApiGetCallAuthKey("/GetDataById/" + LogInSchoolID + "&" + CategoryId + "&" + 1).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                setCategoryData(responseRs);
                setHelpdeskDetails({ ...HelpdeskDetails, TicketType: responseRs.id })
            }
        });
    }, []);

    const SaveHelpDeskTicket = async () => {
        if (HelpdeskDetails.MainCategoryValue.trim() === '') {
            setErrorMessages('Please enter a help desk category name');
            return;
        } else {
            setErrorMessages('');
        }
        const subCategories = HelpdeskDetails.SubCategory;
        if (HelpdeskDetails.Has_subcategory == true) {
            for (let i = 0; i < subCategories.length; i++) {
                if (subCategories[i].value.trim() === '') {
                    setsubcategoryerror('Please enter sub-category values.');
                    return;
                } else {
                    setsubcategoryerror('')
                }
            }
            for (let i = 0; i < subCategories.length; i++) {
                const isAnyIssueEmpty = subCategories[i].issues.some(issue => issue.value.trim() === '');
                if (isAnyIssueEmpty) {
                    subCategories[i].issues = subCategories[i].issues.filter(item =>
                        item.value.trim() !== ''
                    );
                }
            }

        }
        setLoading(true);
        var raw = JSON.stringify({
            Schoolid: LogInSchoolID,
            Subcategory: HelpdeskDetails.SubCategory,
            Has_subcategory: HelpdeskDetails.Has_subcategory,
            Tickettype: HelpdeskDetails.TicketType,
            name: HelpdeskDetails.MainCategoryValue,
            Description: HelpdeskDetails.description,
            Icon: HelpdeskDetails.icon
        });
        await ApiPostCall("/SaveHelpdeskTicket", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={`Help desk created successfully for ${CategoryData.name}`} />);
                    setTimeout(() => {
                        navigate("/helpdesk")
                        setAlerts(<AlertsComp show={false} />)
                    }, 2500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1000);
                }
            }
        });
    }
    function handleShowSubcategory(flag) {
        setShowSubcategory(flag);
        setErrorMessages('')
        if (flag == 1) {
            if (HelpdeskDetails.MainCategoryValue.trim() === '') {
                setErrorMessages('Please enter a help desk category name');
                return;
            } else {
                setErrorMessages('');
            }
            setHelpdeskDetails({
                ...HelpdeskDetails,
                Has_subcategory: true,
                SubCategory: [
                    ...HelpdeskDetails.SubCategory,
                    { value: '', issues: [], Has_subcategory: false, description: '', icon: '', SubCategoryVisibility: false }
                ]
            });
        } else {
            setHelpdeskDetails({
                ...HelpdeskDetails,
                SubCategory: []
            });
        }

    }
    async function handleFileUpload(e) {
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            alert("File size exceeds 2MB limit.");
            return;
        }
        const base64 = await convertToBase64(file);
        setHelpdeskDetails(prevDetails => {
            return { ...prevDetails, icon: base64 };
        })
    };
    const toggleCategoryVisibility = () => {
        if (MainCategoryVisibility) {
            setMainCategoryVisibility(false);
        } else {
            setMainCategoryVisibility(true);
        }
    };
    return (
        <questiondetailscontext.Provider value={{ HelpdeskDetails, setHelpdeskDetails, setsubcategoryerror, subcategoryerror }}>
            <PageHead heading={`Helpdesk setup for ${CategoryData.name}`}
                loading={loading} Alerts={Alerts} showsearch={false} />
            <div className='row align-items-center px-2 mb-2'>
                <div className="GridBox mt-2 p-3">
                    <div className='col-12 text-center pb-3'>
                        <label>
                            You can setup as many categories and sub categories as you need below for {CategoryData.name}.
                        </label>
                    </div>
                    <div className='col-12'>
                        <div className='maincategory row align-items-center'>
                            <div className='col-md-2 text-center fw-600 FormLabel ps-1 '>{CategoryData.name} Category</div>
                            <div className='col-md-6 px-0'>
                                <input className="form-control" autoComplete='off' placeholder={`Enter value...`}
                                    name="maincategory" value={HelpdeskDetails.MainCategoryValue}
                                    onChange={(e) => { setHelpdeskDetails({ ...HelpdeskDetails, MainCategoryValue: e.target.value }); e.target.value ? setshowsubcategory(true) : setshowsubcategory(false) }} />
                            </div>
                            <div className='col-md-2 text-end px-0'>
                                {HelpdeskDetails.icon ?
                                    <div className='d-flex align-items-center ps-3'>
                                        <img src={HelpdeskDetails.icon} alt="" className='categoryimg-icon' />
                                        <FontAwesomeIcon className='ps-2' icon={faTimes} style={{ cursor: 'pointer' }} onClick={(e) => setHelpdeskDetails({ ...HelpdeskDetails, icon: '' })} title='Remove Uploaded Icon' />
                                    </div>
                                    :
                                    <form>
                                        <input type="file" label="Image" name="myFile" accept=".jpg,.png,.svg,.jpeg" id="UploadIconFileId" onChange={(e) => handleFileUpload(e)} />
                                        <label className="BorderBtn font-12" htmlFor="UploadIconFileId"><img src='/images/AddInventory.svg' className='img-fluid pe-1' style={{ height: "10px" }} /> Upload Icon</label>
                                    </form>
                                }
                            </div>
                            <div className='col-md-2'>
                                <label className="BorderBtn font-12 cursor-pointer" onClick={toggleCategoryVisibility} >
                                    <FontAwesomeIcon icon={faAngleDown} style={{ color: "#969c9b" }} title="Show Description" className={`mt-1 ${MainCategoryVisibility ? 'd-none' : ''}`} />
                                    <FontAwesomeIcon icon={faAngleUp} style={{ color: "#969c9b" }} title="Show Description" className={`${MainCategoryVisibility ? '' : 'd-none'}`} />
                                    <label className='ps-1'>Description</label>
                                </label>
                            </div>
                        </div>
                        {showsubcategory && (
                            <div className='col-12 p-3'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <label className='pe-5 FormLabel'>Would you like to include subcategories?</label>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" defaultChecked={ShowSubcategory == 1} name='subcategoryyesno' onChange={() => { handleShowSubcategory(1) }} />
                                        <label className="form-check-label"> Yes</label>
                                    </div>
                                    <div className="form-check ms-3">
                                        <input className="form-check-input" defaultChecked={ShowSubcategory == 2} type="radio" name='subcategoryyesno' onChange={() => { handleShowSubcategory(2) }} />
                                        <label className="form-check-label"> No </label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {MainCategoryVisibility && (
                        <div className='col-12 p-3'>
                            <TextEditor identifier="mainCategory" />
                        </div>
                    )}
                    {errorMessages && <p style={{ color: 'red' }} className='text-center'>{errorMessages}</p>}
                    {ShowSubcategory == 1 && <Subcategory />}
                    <div className='col-12 text-center py-3'>
                        <button className="me-2 Cancel-btn cursor-pointer" onClick={(e) => setConfirmationCancelModal(true)}>Cancel</button>
                        <button className='SaveBtn align-items-center' onClick={SaveHelpDeskTicket}>Save</button>
                    </div>
                </div>
            </div >
            <AlertPopup isshow={ConfirmationCancelModal} img={'/images/redInfo.svg'}
                headingtext={`Confirm to cancel changes?`} text={''}
                cancelcall={(e) => setConfirmationCancelModal(false)}
                canceltext={"No"} successtext={"Yes"} successcall={(e) => navigate('/helpdesk')} btntype={'deleteBtn'} />
        </questiondetailscontext.Provider >
    )
}

export default HelpdeskTicket
