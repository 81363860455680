import React from 'react'
import Popup from '../../Components/Popup';

export default function VideoPopup({ videoShow, setVideoShow, PreviewVideo }) {
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const modalbody = (
        <iframe
            width="500"
            height="500"
            src={`${BucketS3Link}${PreviewVideo}`}
            frameborder="0"
            allowfullscreen
        ></iframe>
    )
    return (
        <Popup isshow={videoShow} size={"lg"} title={"Preview Video"}
            closePopup={(e) => { setVideoShow(false) }}
            modalBody={modalbody} visibleSaveBtn={false} />
    )
}
