import { useEffect, useRef, useState } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap";
import { HideLoder, LogInSchoolID, LogInSchoolnumber, MMDDYYYY, ShowLoder } from "../../JS/Common";
import { ApiGetCall, ApiGetCallAuthKey, ApiPostCall } from "../../JS/Connector";
import { useNavigate } from "react-router-dom";
import AlertsComp from "../../Components/AlertsComp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTimes } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import Deletepopup from "../../Components/Deletepopup";
import Popup from "../../Components/Popup";
export function Setup() {
    const [Loading, setLoading] = useState(false);
    const [NewAlerts, setNewAlerts] = useState("");
    const [RejectedReason, setRejectedReason] = useState("");
    const [planid, setplanid] = useState("");
    const [GridData, setGridData] = useState([]);
    const [ContractModal, setContractModal] = useState(false);
    const [EditData, setEditData] = useState({})
    const [resetFilter, setResetFilter] = useState('d-none')
    const [searchstring, setsearchstring] = useState(null)
    const [SortFlag, setSortFlag] = useState(null)
    const [activeFilter, setActiveFilter] = useState(null);
    const [ChangedPrice, setChangedPrice] = useState('');
    const [Confirmbtn, setConfirmbtn] = useState('');
    const [RejectedDiv, setRejectedDiv] = useState('d-none');
    const [CounterOfferDiv, setCounterOfferDiv] = useState('d-none');
    const [ContractUrl, setContractUrl] = useState('');
    const [policyPopup, setPolicyPopup] = useState(false);
    const [showdeletepopup, setshowdeletepopup] = useState(false);
    const [policyFileName, setPolicyFileName] = useState('')
    const [policyDocument, setPolicyDocument] = useState(null)
    const [showcopymsg, setshowcopymsg] = useState(false);
    const [Description, setDescription] = useState('');
    const [NewFile, setNewFile] = useState(0);
    const [PolicyFlag, setPolicyFlag] = useState(0);
    const navigate = useNavigate()
    var baseurl = window.location.origin;
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const BaseUrlForCall = process.env.REACT_APP_Base_URL;
    var ciphertext = Cookies.get('ciphertext');
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", ciphertext);
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    const labelRef = useRef(null);
    useEffect(() => {
        GetGridData(null, null);
    }, []);

    async function GetGridData(flag, searchstring) {
        setLoading(true);
        if (!searchstring) searchstring = null;
        if (flag != null) {
            setResetFilter('')
        } else {
            setResetFilter('d-none')
            setActiveFilter(null)
        }
        await ApiGetCall("/getAllPlans/" + LogInSchoolID + '&' + searchstring + '&' + flag).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setGridData(responseRs.plan.length ? responseRs.plan : []);
                setContractUrl(responseRs.plan.length ? responseRs.url : '')
            }
        });
    }

    async function ViewDetails(id) {
        setLoading(true);
        setContractModal(true);
        await ApiGetCall("/getPlanById/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setEditData(responseRs)
                setLoading(false);
            }
        });
    }
    async function ConfirmContract(id, PlanNum, PlanName, SchoolName, flag) {
        setLoading(true);
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            PlanId: id,
            PlanNum: PlanNum,
            PlanName: PlanName,
            SchoolName: SchoolName,
            Flag: flag,
            NegotiatedPrice: ChangedPrice ? ChangedPrice : 0,
            StartDate: formattedDate,
            RejectedReason: RejectedReason
        });
        await ApiPostCall("/confirmPlan", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    var msg = flag == 1 ? 'Contract Confirmed!' : flag == 2 ? 'Proposal Rejected!' : 'Proposal Send Successfully';
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    setContractModal(false)
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        GetGridData(null, null)
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const handleCopyClick = () => {
        if (labelRef.current) {
            const labelValue = labelRef.current.innerText;
            const tempInput = document.createElement('input');
            tempInput.value = labelValue;
            document.body.appendChild(tempInput);
            tempInput.select();
            tempInput.setSelectionRange(0, 99999); // For mobile devices
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            setshowcopymsg(true);
            setTimeout(() => {
                setshowcopymsg(false);
            }, 1500);

        }
    };
    function Downloadpdf(pdfurl) {
        const link = document.createElement('a');
        link.href = `${BucketS3Link}${pdfurl}`;
        window.open(link.href, '_blank');
    }
    const [showPopover, setShowPopover] = useState(false);

    const popover = (
        <Popover id="popover-basic" style={{ maxWidth: '50%', fontSize: '14px' }} onMouseLeave={() => setShowPopover(false)}>
            <Popover.Header as="h3">What is Parental Coverage?</Popover.Header>
            <Popover.Body>
                <ul className="text-justify">
                    K-12 Tech Repairs provide coverage options for devices. Under such coverage plans,
                    devices can be covered for any kind of damages or break downs for the whole term.
                    Schools can sign-up for such coverage plans and do an agreement with K-12.
                    Schools need to create a plan for it and confirm coverage charges with K-12
                    for desired requirements for their students/users. Students/Users who purchases
                    coverage plans from the school will be covered for repairs/replacements as per agreed
                    terms by K-12 Tech.
                    <br />
                    <h6 className=" pt-3 mb-0 font-14 fw-800">How it works:- </h6>
                    <li className="pt-1"><label className="fw-600">School: </label> Begin the process by filling out the "Create Plan" form and providing the required details.
                        Next, select the products or services you want to offer from the provided options. Finally,
                        complete the plan by creating it based on your selections. </li>

                    <li className="pt-1"> <label className="fw-600">K-12: </label> Once the school creates a plan, it is then notified to the K-12.
                        The K-12 will check the plan, add pricing for services or products, and confirm the plan.</li>

                    <li className="pt-1"><label className="fw-600">Prices and Contract Confirmation: </label> The K-12 sets the prices and sends them to the school.
                        The school checks the details and can negotiate if the prices need improvement, notifying the K-12.
                        Once the K-12 approves the price, the school confirms the contract, generating a shareable link.
                        This action activates the Parental Coverage plan.</li>

                    <li className="pt-1"> <label className="fw-600">Plan Sharing: </label> The school can use the generated URL to share with students and parents.
                        Using this link, students and parents can choose services, buy a plan, and start coverage.
                        The plan will only be valid for a certain amount of time and will expire afterward.</li>
                </ul>
            </Popover.Body>
        </Popover>
    );
    const handleToggle = (nextShow) => {
        setShowPopover(nextShow);
    };
    function handlePdfFileUpload(e) {
        const file = e.target.files[0];
        setPolicyFileName(file.name);
        setPolicyDocument(file)
        setNewFile(1);
        setPolicyFlag(1);
    };

    function UploadPolicyDocument() {
        setLoading(true);
        const formdata = new FormData();
        formdata.append('SchoolId', LogInSchoolID);
        formdata.append('File', NewFile == 1 ? policyDocument : null);
        formdata.append('Flag', PolicyFlag);
        formdata.append('Description', Description);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        fetch(`${BaseUrlForCall}/addUpdateInsurancePolicy`, requestOptions).then(response => response.text())
            .then(result => {
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'Document Added successfully'} />)
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />)
                        setPolicyPopup(false)
                        setPolicyFileName("");
                    }, 2000);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.status} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }
    const openPDFInNewTab = () => {
        const link = document.createElement('a');
        link.href = `${BucketS3Link}${policyDocument}`;
        window.open(link.href, '_blank');
    };

    async function DeletePlan() {
        setLoading(true);
        var raw = "";
        await ApiPostCall("/deleteunfinishedplan/" + LogInSchoolID + "&" + planid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setshowdeletepopup(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Plan Deleted Successfully."} />);
                    setTimeout(() => {
                        GetGridData(null, null);
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const sortGradesString = (gradesString) => {
        const gradesArray = gradesString.split(',');
        gradesArray.sort((a, b) => a.localeCompare(b));
        return gradesArray.join(',');
    };
    const modalbody = (
        <div className="row">
            <div className="col-md-6 d-flex  pb-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Plan Name : </div>
                <div className="col-md-6 mb-0 schoolColorStatus h5"> {EditData.plan_name}</div>
            </div>
            <div className="col-md-6 d-flex  pb-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Estimated Student : </div>
                <div className="col-md-6"> {EditData.estimated_enrollment}</div>
            </div>
            <div className="col-md-6 d-flex  py-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Contact Name : </div>
                <div className="col-md-6"> {EditData.contact_name}</div>
            </div>
            <div className="col-md-6 d-flex py-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Contact Email : </div>
                <div className="col-md-6"> {EditData.contact_email}</div>
            </div>
            <div className="col-md-6 d-flex py-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Sign Up Start Date : </div>
                <div className="col-md-6"> {MMDDYYYY(EditData.start_date)}</div>
            </div>
            <div className="col-md-6 d-flex py-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Sign Up End Date : </div>
                <div className="col-md-6"> {MMDDYYYY(EditData.end_date)}</div>
            </div>
            <div className="col-md-6 d-flex py-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Coverage Start Date : </div>
                <div className="col-md-6"> {MMDDYYYY(EditData.coverage_start_date)}</div>
            </div>
            <div className="col-md-6 d-flex py-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Coverage End Date : </div>
                <div className="col-md-6"> {MMDDYYYY(EditData.coverage_end_date)}</div>
            </div>
            <div className="col-md-6 d-flex py-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Grades : </div>
                <div className="col-md-6"> {EditData.grades}</div>
            </div>
            <div className="col-md-6 d-flex py-2 pe-0 wordwrap">
                <div className="col-md-6  fw-600">Device Models Covered : </div>
                <div className="col-md-6"> {EditData.device_models}</div>
            </div>
            <div className="col-md-12 d-flex  pt-2 pe-0 wordwrap">
                <div className="col-md-6 fw-600">Percentage of Devices Going Home with Students : </div>
                <div className="col-md-4"> {EditData.devices_not_leave_school}&nbsp;%</div>
            </div>
            <img src="/images/HorizontalLine.svg" className="img-fluid w-100 my-2 postclass" />
            <div className="row pb-3">
                {EditData?.school?.uncovered_repair_charges == 1 && (
                    <div className="col-md-6 pt-2">
                        <b>Uncoverd Repair Charges :</b> ${EditData.uncovered_charge}
                    </div>
                )}
                <div className="col-md-6 pt-2">
                    <b>Parental Device Protection Charges :</b> ${EditData.price}
                </div>
            </div>
            <div className={`${CounterOfferDiv}`}>
                <div className="col-md-12 text-center font-14 py-2" style={{ color: '#3CBBA5' }}>Do you want to suggest a price to negotiate with K-12 Tech?</div>
                <div className="input-container col-md-4 offset-md-4 d-flex align-items-center">
                    <input type="number" autoComplete="off" name='totaldevices' defaultValue={ChangedPrice || '0'} placeholder="Enter Price" className="form-control" min={0} onChange={(e) => { setChangedPrice(e.target.value) }} />
                    <label><img src='/images/CancelIcon.svg' className="input-icon img-fluid ms-3 cursor-pointer" title="Back To Confirm Contract" onClick={(e) => { setConfirmbtn(''); setCounterOfferDiv('d-none') }} /></label>
                </div>
                <hr />
            </div>
            <div className={`${RejectedDiv}`}>
                <div className="col-md-12 text-center font-14 py-2" style={{ color: '#3CBBA5' }}>You are about to reject this offer from K-12 Tech, please provide a reason for rejection below: </div>
                <div className="input-container col-md-6 offset-md-3 d-flex align-items-center">
                    <textarea type="textarea" rows={2} autoComplete="off" name="rejectreason" className="form-control" defaultValue={RejectedReason} onChange={(e) => setRejectedReason(e.target.value)} />
                    <label><img src='/images/CancelIcon.svg' className="input-icon img-fluid ms-3 cursor-pointer" title="Back To Confirm Contract" onClick={(e) => { setConfirmbtn(''); setRejectedDiv('d-none') }} /></label>
                </div>
                <hr />
            </div>
            <div className="contract-confirm-font pt-2 text-center">If you agree and confirm offered coverage charges from K-12 Tech then please go ahead and confirm offer. You can also place your counter offer by clicking on button Counter Offer below. </div>
        </div>
    )
    const modalfooter = (
        <>
            <label className={`ms-2 cursor-pointer ${Confirmbtn}`}
                onClick={(e) => { setConfirmbtn('d-none'); setRejectedDiv('') }}>
                <u>Reject Offer</u>
            </label>
            <button className={`deleteBtn ${Confirmbtn}`} onClick={(e) => { setConfirmbtn('d-none'); setCounterOfferDiv('') }}>Counter Offer  </button>
            <button className={`SaveBtn ms-2 ${Confirmbtn}`} onClick={(e) => ConfirmContract(EditData.id, EditData.plan_num, EditData.plan_name, EditData.school_name, 1)}>Confirm Offer</button>
            <button className={`SaveBtn ms-2 ${CounterOfferDiv}`} onClick={(e) => ConfirmContract(EditData.id, EditData.plan_num, EditData.plan_name, EditData.school_name, 2)}>Send Your Proposal</button>
            <button className={`SaveBtn ms-2 ${RejectedDiv}`} onClick={(e) => ConfirmContract(EditData.id, EditData.plan_num, EditData.plan_name, EditData.school_name, 3)}>Reject Offer</button>
        </>
    )
    const policypopup = (
        <>
            <div className="pb-2">
                You can copy and share coverage plan URL from below with your parents/students or any other users. Using this URL, your users would be easily able to sign-up for coverage plans. </div>
            <div className="p-1 pe-0 d-flex align-items-center">
                <label className='' ref={labelRef} style={{ wordBreak: 'break-all' }}>
                    <b> {baseurl}/{ContractUrl}</b>
                </label>
                <FontAwesomeIcon icon={faCopy} style={{ height: "20px" }} className='cursor-pointer ps-3' title='Click to copy this URL' onClick={handleCopyClick} />
            </div>
            {showcopymsg == true && (
                <div className='px-2 text-center col-md-12' style={{ color: "#3bbba4" }}>Copied!</div>
            )}
            <br />
            <div className="col-12 pb-2">
                <div>
                    <label className='fw-600'>Explanation for Parents: What is a Student Number?: </label>
                    <textarea type="textarea" rows={2} autoComplete="off" name="description"
                        className="form-control"
                        value={Description} onChange={(e) => setDescription(e.target.value)} />
                </div>
            </div>

            {NewFile == 2 ?
                <div className="pt-3">
                    <label className="fw-600">Attached Policy Document :</label>
                    <label style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>
                        {policyDocument}
                    </label>
                    <img src='/images/pdficon.svg' title="View Document" className='px-3 cursor-pointer' onClick={(e) => openPDFInNewTab(e)} />
                    <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} title='Remove Policy' onClick={(e) => { setPolicyDocument(null); setPolicyFileName(""); setNewFile(0); setPolicyFlag(2) }} />
                </div>
                :
                <div className="col-md-12">
                    <div className="col-md-12 fw-600 pt-2">Upload Fair Use Policy Document:</div>
                    <div className="col-md-12">
                        <input type="file" label="img" id="UploadFileId" name="myFile" accept="application/pdf" onChange={(e) => handlePdfFileUpload(e)} />
                        <label className="ImportInventoryBtn col-md-8" htmlFor="UploadFileId" style={{ color: "#909091" }}>
                            Choose File
                        </label>
                        <input type="file" className="col-md-4 col-9 ImportInventoryBtn" />
                        <label className="ImportInventoryBtn1 text-center col-md-4 col-3" htmlFor="UploadFileId" style={{ backgroundColor: "#3CBBA5", color: "white", height: "40px", }}>
                            Browse
                        </label>
                    </div>
                    {policyFileName &&
                        <label className="ps-3 mt-1 d-flex" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{policyFileName} </label>}
                </div>
            }
        </>
    )

    function GetSchoolData() {
        ApiGetCallAuthKey("/schoolDatabyNumber/" + LogInSchoolnumber).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setPolicyPopup(true)
                    setPolicyDocument(responseRs?.msg?.insurance_policy)
                    setDescription(responseRs?.msg?.insurance_notes || "");
                    if (responseRs?.msg?.insurance_policy) {
                        setNewFile(2)
                    }
                }
            }
        });
    }
    return (
        <>
            {NewAlerts}
            {Loading ? <ShowLoder /> : <HideLoder />}
            <div className="row pe-0 align-items-center mb-2 me-0">
                <div className="col-md-5 d-flex align-items-center">
                    <h1 className="PageHeading m-0">Set Up Coverage Plans</h1>
                    <div className="ps-2">
                        <OverlayTrigger
                            trigger="click"
                            placement="right"
                            show={showPopover}
                            onToggle={handleToggle}
                            overlay={popover}
                        >
                            <a href="#" role="button">
                                <img src="/images/InfoBtn.svg" title="What is Parental Coverage?" className="cursor-pointer" height='16px' />
                            </a>
                        </OverlayTrigger>
                    </div>
                </div>

                <div className="col-md-2 text-end">
                    <u className={`${resetFilter} cursor-pointer`} onClick={(e) => { GetGridData(null, null); setsearchstring("") }}>Reset All Filter</u>
                </div>
                <div className='col-md-3 pe-0 text-end SearchBarDivforloner'>
                    <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                        <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                        <input className="form-control marginCss" autoComplete="off" type="text" placeholder="Search" onKeyUp={(e) => { GetGridData(SortFlag, e.target.value); setsearchstring(e.target.value) }} />
                    </form>
                </div>
                <div className="col-md-2 text-end pe-0">
                    <label className='col-12 BorderBtn text-center' onClick={() => navigate('/add-setup')}>  Create Plan
                        <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                    </label>
                </div>
            </div>
            <div className='GridBox p-3'>
                <div className="row pb-2 align-items-center">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-evenly align-items-center font-13">
                            <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 1 ? 'activePlanFilter' : ''}`}
                                title="Filter" onClick={(e) => { setActiveFilter(1); setSortFlag(1); GetGridData(1, searchstring) }}>
                                <div className="setup-status-square yellow-backgroung-style mx-3"></div><span>Newly Added</span>
                            </div>
                            <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 2 ? 'activePlanFilter' : ''}`}
                                title="Filter" onClick={(e) => { setActiveFilter(2); setSortFlag(2); GetGridData(2, searchstring) }}>
                                <div className="setup-status-square purple-backgroung-style mx-3"></div><span>Waiting for confirmation</span>
                            </div>
                            <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 3 ? 'activePlanFilter' : ''}`}
                                title="Filter" onClick={(e) => { setActiveFilter(3); setSortFlag(3); GetGridData(3, searchstring) }}>
                                <div className="setup-status-square green-backgroung-style mx-3"></div><span>Live</span>
                            </div>
                            <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 5 ? 'activePlanFilter' : ''}`}
                                title="Filter" onClick={(e) => { setActiveFilter(5); setSortFlag(5); GetGridData(5, searchstring) }}>
                                <div className="setup-status-square rejected-backgroung-style mx-3"></div><span> In Negotiation</span>
                            </div>
                            <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 4 ? 'activePlanFilter' : ''}`}
                                title="Filter" onClick={(e) => { setActiveFilter(4); setSortFlag(4); GetGridData(4, searchstring) }}>
                                <div className="setup-status-square pink-backgroung-style mx-3"></div><span>Past</span>
                            </div>
                            <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 6 ? 'activePlanFilter' : ''}`}
                                title="Filter" onClick={(e) => { setActiveFilter(6); setSortFlag(6); GetGridData(6, searchstring) }}>
                                <div className="setup-status-square brown-backgroung-style mx-3"></div><span>Rejected</span>
                            </div>
                            <div className={`cursor-pointer d-flex align-items-center ${activeFilter == 7 ? 'activePlanFilter' : ''}`}
                                title="Filter" onClick={(e) => { setActiveFilter(7); setSortFlag(7); GetGridData(7, searchstring) }}>
                                <div className="setup-status-square blue-backgroung-style mx-3"></div><span>Live and Uncovered</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 pt-2 text-end">
                        {ContractUrl != null && ContractUrl != '' && (
                            <div className="col-12">
                                <label className="BorderBtn cursor-pointer align-item-center" onClick={() => GetSchoolData()}>
                                    View Plan Link and Fair Use Policy
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="col-md-12 pt-2 text-center">
                        <label className="font-12 pe-0">
                            New 'Uncovered Repair Cost' options: select 'Flat Fee' to enter a price, or 'Full Repair' to set costs at repair time. Configure in settings.
                        </label>
                    </div>
                </div>
                <div className="col-12 greyBox p-3 pt-2">
                    <div className='row row-cols-1 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 g-4 g-lg-4'>
                        {GridData.length !== 0 ? (
                            GridData.map((item, i) => {
                                var grades = sortGradesString(item.grades);
                                return (
                                    <div className='col position-relative'>
                                        <div className='p-4 GridBox boxshaowstyle' style={{ maxHeight: '100%', minHeight: '100%', backgroundColor: item.color_code }}>
                                            <div className="row align-items-center justify-content-between">
                                                <div className="col-8">
                                                    <h4 className='mb-0 fw-600 planname-style' style={{ wordBreak: 'break-all', overflowWrap: 'break-word', maxWidth: '300px' }}>
                                                        {item.plan_name}
                                                    </h4>
                                                </div>
                                                <div className="col-4 px-0 mt-1 text-end">
                                                    {item.status == 1 && (
                                                        <>
                                                            <img src="/images/EditPlan.svg" title="Edit Plan" className="cursor-pointer" height='16px' onClick={(e) => { navigate("/add-setup/?id=" + item.id) }} planid={item.id} />
                                                            <img src="/images/DeleteIcon.svg" title="Delete Plan" className="cursor-pointer ps-3" height="16px" onClick={(e) => { setplanid(item.id); setshowdeletepopup(true) }} />
                                                        </>
                                                    )}
                                                    {item.status == 2 && (
                                                        <div className="d-flex flex-wrap">
                                                            <img src="/images/EditPlan.svg" title="Edit Plan" className="cursor-pointer mb-1" height='16px' onClick={(e) => { navigate("/add-setup/?id=" + item.id) }} planid={item.id} />
                                                            <img src="/images/setupapprove.svg" className="cursor-pointer ps-2 mb-1" title='Confirm Contract' height='16px' onClick={(e) => { ViewDetails(item.id) }} planid={item.id} />
                                                            <img src="/images/downloadPlan.svg" className="cursor-pointer ps-2 mb-1" title='Preview Contract' height='16px' onClick={(e) => Downloadpdf(item.pdf)} planid={item.id} />
                                                            <img src="/images/DeleteIcon.svg" title="Delete Plan" className="cursor-pointer ps-2 mb-1" height="16px" onClick={(e) => { setplanid(item.id); setshowdeletepopup(true) }} />
                                                        </div>
                                                    )}
                                                    {item.status == 5 && (
                                                        <>
                                                            <img src="/images/EditPlan.svg" title="Edit Plan" className="cursor-pointer" height='16px' onClick={(e) => { navigate("/add-setup/?id=" + item.id) }} planid={item.id} />
                                                            <img src="/images/DeleteIcon.svg" title="Delete Plan" className="cursor-pointer ps-3" height="16px" onClick={(e) => { setplanid(item.id); setshowdeletepopup(true) }} />
                                                        </>
                                                    )}
                                                    {item.status == 3 && (
                                                        <img src="/images/downloadPlan.svg" className="cursor-pointer ps-3" title='Preview Contract' height='16px' onClick={(e) => Downloadpdf(item.pdf)} planid={item.id} />
                                                    )}
                                                    {item.status == 4 && (
                                                        <>
                                                            <img src="/images/downloadPlan.svg" className="cursor-pointer ps-3" title='Preview Contract' height='16px' onClick={(e) => Downloadpdf(item.pdf)} planid={item.id} />
                                                            <img src="/images/DeleteIcon.svg" title="Delete Plan" className="cursor-pointer ps-3" height="16px" onClick={(e) => { setplanid(item.id); setshowdeletepopup(true) }} />
                                                        </>
                                                    )}
                                                    {item.status == 6 && (
                                                        <>
                                                            <img src="/images/EditPlan.svg" title="Edit Plan" className="cursor-pointer" height='16px' onClick={(e) => { navigate("/add-setup/?id=" + item.id) }} planid={item.id} />
                                                            <img src="/images/DeleteIcon.svg" title="Delete Plan" className="cursor-pointer ps-3" height="16px" onClick={(e) => { setplanid(item.id); setshowdeletepopup(true) }} />
                                                        </>
                                                    )}
                                                    {item.status == 7 && (
                                                        <img src="/images/downloadPlan.svg" className="cursor-pointer ps-3" title='Preview Contract' height='16px' onClick={(e) => Downloadpdf(item.pdf)} planid={item.id} />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className='mt-3 content-forsetupcards'><span className="fw-600">Estimated Student :</span> {item.estimated_enrollment}</div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className='mt-2 content-forsetupcards'><span className="fw-600">Start Date :</span> {MMDDYYYY(item.start_date)}</div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className='mt-2 content-forsetupcards'><span className="fw-600">End Date :</span> {MMDDYYYY(item.end_date)}</div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className='mt-2 content-forsetupcards'><span className="fw-600">Access Code :</span> {item.access_token}</div>
                                            </div>
                                            <div className='mt-2 mb-3 content-forsetupcards' ><span className="fw-600">Grades : </span>
                                                {grades}
                                            </div>
                                            {item.price != null && (<div className='h5 total-price'><b>Total Prices : ${item.price.toFixed(2)}</b></div>)}
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className='m-auto py-5'>
                                <div className="col-md-12 text-center h3">No Record Found</div>
                                <div className='m-auto py-5'>
                                    <img src='/images/setupplanicon.svg' className="img-fluid" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div >
            <Deletepopup text={`You won't be able to revert this!`} deletePopup={showdeletepopup} deletePart={DeletePlan} DeleteShowClose={(e) => setshowdeletepopup(false)}></Deletepopup>

            <Popup isshow={ContractModal} size={"lg"} title={"Review & Confirm K-12 Coverage Charges"}
                closePopup={(e) => setContractModal(false)}
                modalBody={modalbody} modalfooter={modalfooter} />

            <Popup isshow={policyPopup} size={"md"} title={"View Plan Link and Fair Use Policy"}
                closePopup={(e) => { setPolicyPopup(false); setshowcopymsg(false); setPolicyFileName(''); setPolicyDocument(null) }}
                modalBody={policypopup} handleSave={UploadPolicyDocument} visibleSaveBtn={true} btnText={"Upload"} />

        </>
    )
}
