import React, { useContext, useState } from 'react'
import { questiondetailscontext } from './HelpdeskTicket';
import Issues from './Issues';
import TextEditor from './TextEditor';
import { convertToBase64 } from '../../JS/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Subcategory() {
    const { HelpdeskDetails, setHelpdeskDetails, setsubcategoryerror, subcategoryerror } = useContext(questiondetailscontext);
    const [showIssues, setShowIssues] = useState(Array(HelpdeskDetails.SubCategory.length).fill(false));
    const handleInputChange = (event, subCategoryIndex) => {
        const { name, value } = event.target;
        const updatedSubCategory = [...HelpdeskDetails.SubCategory];
        updatedSubCategory[subCategoryIndex].value = value;
        setHelpdeskDetails({
            ...HelpdeskDetails,
            SubCategory: updatedSubCategory
        });
    };
    const handleDeleteSubcategory = (index) => {
        const updatedSubcategories = HelpdeskDetails.SubCategory.filter((_, i) => i !== index);
        setHelpdeskDetails({
            ...HelpdeskDetails,
            SubCategory: updatedSubcategories
        });
    };
    const handleAddIssues = (index, flag) => {
        if (flag == 1) {
            setsubcategoryerror('');
            const subCategoryValue = HelpdeskDetails.SubCategory[index].value.trim();
            if (!subCategoryValue) {
                setsubcategoryerror('Please enter a subcategory before adding issues.');
                return;
            }
            const updatedSubCategory = [...HelpdeskDetails.SubCategory];
            if (updatedSubCategory[index].issues.length === 0) {
                updatedSubCategory[index].issues.push({ value: '', description: '', icon: '' });
                setHelpdeskDetails({
                    ...HelpdeskDetails,
                    SubCategory: updatedSubCategory
                });
                setShowIssues(prevState => {
                    const newShowIssues = [...prevState];
                    newShowIssues[index] = true;
                    return newShowIssues;
                });
            }
        } else {
            const updatedSubCategory = [...HelpdeskDetails.SubCategory];
            updatedSubCategory[index].issues = [];
            setHelpdeskDetails({
                ...HelpdeskDetails,
                SubCategory: updatedSubCategory
            });
            setShowIssues(prevState => {
                const newShowIssues = [...prevState];
                newShowIssues[index] = false;
                return newShowIssues;
            });
        }
    };
    async function handleSubcategoryFileUpload(e, index) {
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            alert("File size exceeds 2MB limit.");
            return;
        }
        const base64 = await convertToBase64(file);
        const updatedSubCategory = [...HelpdeskDetails.SubCategory];
        updatedSubCategory[index].icon = base64;
        setHelpdeskDetails({
            ...HelpdeskDetails,
            SubCategory: updatedSubCategory
        });
    };
    const toggleSubCategoryVisibility = (index) => {
        const updatedSubCategory = [...HelpdeskDetails.SubCategory];
        if (updatedSubCategory[index].SubCategoryVisibility) {
            updatedSubCategory[index].SubCategoryVisibility = false;
        } else {
            updatedSubCategory[index].SubCategoryVisibility = true;
        }
        setHelpdeskDetails({
            ...HelpdeskDetails,
            SubCategory: updatedSubCategory
        });
    }
    const handleAddSubcategory = () => {
        setsubcategoryerror('')
        const subCategories = HelpdeskDetails.SubCategory;
        for (let i = 0; i < subCategories.length; i++) {
            if (subCategories[i].value.trim() === '') {
                setsubcategoryerror('Please enter sub-category values.');
                return;
            } else {
                setsubcategoryerror('')
            }
        }
        setHelpdeskDetails({
            ...HelpdeskDetails,
            SubCategory: [
                ...HelpdeskDetails.SubCategory,
                { value: '', issues: [], Has_subcategory: false, description: '', icon: '', SubCategoryVisibility: false }
            ]
        });
    };
    const handleRemoveUploadedIcon = (index) => {
        const updatedSubcategories = HelpdeskDetails.SubCategory.map((item, i) => {
            if (i === index) {
                return { ...item, icon: '' }; // Clear the icon value
            }
            return item;
        });
        setHelpdeskDetails({
            ...HelpdeskDetails,
            SubCategory: updatedSubcategories
        });
    }
    return (
        <div>
            <div className='col-12 px-3'>
                {HelpdeskDetails?.SubCategory?.map((item, i) => {
                    const isLastItem = i === HelpdeskDetails.SubCategory.length - 1;
                    return <div style={{ marginLeft: `40px` }} className='list-unstyled py-2' key={i}>
                        <div className={`subcategorydiv row`} >
                            <div className='col-md-2 text-center fw-600 FormLabel ps-1'>Sub Category {i + 1}:</div>
                            <div className='col-md-5 px-0'>
                                <input className="form-control" autoComplete='off' placeholder={`Enter value...`}
                                    name={`Question ${i + 1}`} value={item.value} onChange={(e) => handleInputChange(e, i)} />
                            </div>
                            <div className='col-2 text-end px-0'>
                                {item.icon ?
                                    <div className='d-flex align-items-center ps-3'>
                                        <img src={item.icon} alt="" className='categoryimg-icon' />
                                        <FontAwesomeIcon className='ps-2' icon={faTimes} style={{ cursor: 'pointer' }} onClick={(e) => handleRemoveUploadedIcon(i)} title='Remove Uploaded Icon' />
                                    </div>
                                    :
                                    <form className="col-12">
                                        <input type="file" label="Image" name={`mysubFile-${i}`} accept=".jpg,.png,.svg,.jpeg" id={`UploadSubcategoryFileId-${i}`} onChange={(e) => handleSubcategoryFileUpload(e, i)} />
                                        <label className="BorderBtn font-12" htmlFor={`UploadSubcategoryFileId-${i}`}><img src='/images/AddInventory.svg' className='img-fluid pe-1' style={{ height: "10px" }} /> Upload Icon</label>
                                    </form>
                                }
                            </div>
                            <div className='col-2 text-end'>
                                <label className="BorderBtn font-12 cursor-pointer" onClick={(e) => toggleSubCategoryVisibility(i)} >
                                    <FontAwesomeIcon icon={faAngleDown} style={{ color: "#969c9b" }} title="Show Description" className={`mt-1 ${item.SubCategoryVisibility ? 'd-none' : ''}`} />
                                    <FontAwesomeIcon icon={faAngleUp} style={{ color: "#969c9b" }} title="Show Description" className={`${item.SubCategoryVisibility ? '' : 'd-none'}`} />
                                    <label className='ps-1'>Description</label>
                                </label>
                            </div>
                            <div className='col-md-1 px-0'>
                                {isLastItem && <img src='/images/GreenQuestion.svg' className='cursor-pointer' title='Add Sub Category' style={{ height: "20px" }} onClick={handleAddSubcategory} />}
                                <img src='/images/RedDelete.svg' title='Delete this question' style={{ height: "20px" }} className='cursor-pointer ms-2' onClick={() => handleDeleteSubcategory(i)} />
                            </div>
                        </div>
                        {item.value != '' && (
                            <div className='col-12 p-3'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <label className='pe-5'>Would you like to add Issue Types to this sub category?</label>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name={`issue ${i + 1}`} onChange={() => { handleAddIssues(i, 1) }} />
                                        <label className="form-check-label"> Yes</label>
                                    </div>
                                    <div className="form-check ms-3">
                                        <input className="form-check-input" type="radio" defaultChecked name={`issue ${i + 1}`} onChange={() => { handleAddIssues(i, 2) }} />
                                        <label className="form-check-label"> No </label>
                                    </div>
                                </div>
                            </div>
                        )}
                        {item.SubCategoryVisibility && (
                            <div className='col-12 p-3'>
                                <TextEditor identifier="Subcategory" subindex={i} />
                            </div>
                        )}
                        {showIssues[i] && item.issues.length > 0 && <Issues issues={item.issues} subCategoryIndex={i} />}
                    </div>
                })}
            </div>
            {subcategoryerror && <p style={{ color: 'red' }} className='text-center'>{subcategoryerror}</p>}
        </div>
    )
}
