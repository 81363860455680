import React, { useEffect, useState } from 'react'
import { ApiGetCall } from '../../../JS/Connector';
import { HideLoder, MMDDYYYY, ShowLoder } from '../../../JS/Common';
import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Popup from '../../../Components/Popup';

export default function TechnicianTable(props) {
    var hasRecords = false;
    const [AllData, setAllData] = useState({
        technician: { data: [], pagination: {}, loader: false },
    });
    useEffect(() => {
        setAllData({ technician: { data: props?.technicianData?.data?.data, pagination: props?.technicianData?.data?.pagination, loader: props?.technicianData?.loader } })
    }, [props?.technicianData])
    const [isticketshow, setisticketshow] = useState(false);
    const [TicketData, setTicketData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPageForTechnician, setCurrentPageForTechnician] = useState(1);
    const totalPages = AllData?.technician?.pagination || 0
    const showPagination = AllData?.technician?.data?.length > 0;

    const handlePageChange = (pageDir) => {
        let plusMinus = currentPageForTechnician;
        if (pageDir === 'next') {
            plusMinus += 1;
        } else {
            plusMinus -= 1;
        }
        setAllData({ ...AllData, technician: { ...AllData?.technician, loader: true } })
        var locationid = props.LocationValue == 0 ? null : props.LocationValue;
        var schoolvalue = props.SchoolValue == 0 ? null : props.SchoolValue;
        ApiGetCall("/getTechnicianPerformance/" + MMDDYYYY(props.startDate) + "&" + MMDDYYYY(props.endDate) + "&" + locationid + "&" + schoolvalue + '&' + plusMinus).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setAllData({ ...AllData, technician: { ...AllData?.technician, data: responseRs?.technician?.data, pagination: responseRs?.technician?.pagination, loader: false }, })
            }
        });
        setCurrentPageForTechnician(plusMinus)
    }
    async function ShowTicketDetails(techid) {
        var locationid = props.LocationValue == 0 ? null : props.LocationValue;
        var schoolvalue = props.SchoolValue == 0 ? null : props.SchoolValue;
        setLoading(true)
        await ApiGetCall("/TechnicianRepairedLog/" + techid + "&" + MMDDYYYY(props.startDate) + "&" + MMDDYYYY(props.endDate) + "&" + locationid + "&" + schoolvalue).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                if (responseRs.msg.length != 0) {
                    setTicketData(responseRs.msg);
                } else {
                    setTicketData([]);
                }
            }
        });
    }
    const modalBody = (
        <div className="row scrollbar-css-ticketform h-100">
            {TicketData.length != 0 ?
                TicketData.map((item, i) => {
                    return <div className="col-md-6  " key={i}>
                        <div className="col-12 py-1">
                            <b>Ticket #:</b>
                            <span className="ps-3">{item.ticket_num ? item.ticket_num : <>-</>}</span>
                        </div>
                        <div className="col-12 py-1">
                            <b>Ticket Status:</b>
                            <span className="ps-3" style={{ color: "green" }}>{item.status_name}</span>
                        </div>
                        <div className="col-12 py-1">
                            <b>Notes:</b>
                            <span className="ps-3">{item.notes}</span>
                        </div>
                        <hr />
                    </div>
                })
                :
                <div className="col-12 text-center">
                    No Record Found
                </div>
            }
        </div>
    )
    return (
        <React.Fragment>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="col-md-6 p-3" >
                <div className="greyBox " style={{ height: "100%" }}>
                    <h5 className="fw-bold ps-2 mb-0">Technicians Performance</h5>
                    <hr className="my-2" />

                    <div className="w-100 px-2">
                        <div className="row py-3">
                            <div className="col-md-6 col-3 fw-600 location-name">Technician</div>
                            <div className="col-md-2 col-3 fw-600">Tickets</div>
                            <div className="col-md-2 col-3 fw-600">Location</div>
                            <div className="col-md-2 col-3 fw-600">Points</div>
                        </div>
                        {props?.technicianData?.loader || AllData?.technician?.loader ? <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div> : <>

                            {AllData?.technician?.data?.length !== 0 ? (AllData?.technician?.data?.map((item, i) => {
                                hasRecords = true;
                                return (
                                    <div className="row py-2 admin-table-border-top" key={i}>
                                        <div className="col-md-6 col-3">{item.TechnicianName}</div>
                                        <div className="col-md-2 col-3 cursor-pointer" onClick={(e) => { setisticketshow(true); ShowTicketDetails(item.TechID) }}><u>{item.TotalTickets}</u></div>
                                        <div className="col-md-2 col-3 location-name">{item.locationName || '-'}</div>
                                        <div className="col-md-2 col-3 location-name">{item.Points}</div>
                                    </div>
                                );
                            })
                            ) : (
                                <div className="col-12 text-center admin-table-border-top py-2">
                                    No Record Found
                                </div>
                            )}
                            {!hasRecords && AllData.technician?.data?.length !== 0 && (
                                <div className="col-12 text-center py-2 admin-table-border-top">
                                    No Record Found
                                </div>
                            )}
                        </>}
                        {showPagination && (
                            <Pagination>
                                {currentPageForTechnician != 1 ?
                                    <FontAwesomeIcon icon={faAngleLeft} className='cursor-pointer mx-3' onClick={() => { handlePageChange('prev') }} style={{ height: '20px' }} />
                                    :
                                    <FontAwesomeIcon icon={faAngleLeft} className='mx-3' style={{ height: '20px', opacity: '0.3' }} />
                                }
                                {currentPageForTechnician < totalPages ?
                                    <FontAwesomeIcon icon={faAngleRight} className='cursor-pointer mx-3' onClick={() => { handlePageChange('next') }} style={{ height: '20px' }} />
                                    :
                                    <FontAwesomeIcon icon={faAngleRight} className='mx-3' style={{ height: '20px', opacity: '0.3' }} />
                                }
                            </Pagination>
                        )}
                    </div>
                </div>
            </div>
            <Popup isshow={isticketshow} size={"xl"} title={"Ticket Details"}
                closePopup={(e) => setisticketshow(false)}
                modalBody={modalBody} visibleSaveBtn={false} />
        </React.Fragment>
    )
}
