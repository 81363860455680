import React, { useContext } from 'react';
import ReactApexChart from "react-apexcharts";
import { TableDataContext } from '../index';
import { UserContext } from '../../../App';
export default function SupportTicket(props) {
    const { UserData } = useContext(UserContext);
    const { AllDashBoardData } = useContext(TableDataContext);
    var options = {}
    if (AllDashBoardData?.SupportTicket?.data?.counts != undefined && AllDashBoardData?.SupportTicket?.data?.series != undefined) {
        var ticketValue = AllDashBoardData?.SupportTicket?.data?.counts?.map((value) => parseFloat(value?.toFixed(2)));
        options = {
            series: AllDashBoardData?.SupportTicket?.data?.series?.map((value) => parseFloat(value?.toFixed(2))),
            options: {
                chart: {
                    type: 'donut',
                },
                tooltip: {
                    custom: function ({ series, seriesIndex, w }) {
                        const labels = ['Technology Open', 'Technology Closed', 'Maintenance Open', 'Maintenance Closed']; // Your labels
                        const additionalData = ticketValue;
                        const label = labels[seriesIndex];
                        const tooltipValue = additionalData[seriesIndex];

                        return `<div class="p-2">${label}: ${tooltipValue} tickets</div>`;
                    },
                },

                colors: ["#3CBBA5", "#7f7f7f", "#151821", "#c3c3c3"],
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                            minAngleToShowLabel: 20,
                            style: {
                                fontSize: '12px',
                            }
                        },
                    },
                },
                labels: [`Technology Open (${ticketValue[0]})`, `Technology Closed  (${ticketValue[1]})`, `Maintenance Open (${ticketValue[2]})`, `Maintenance Closed  (${ticketValue[3]})`],
                responsive: [
                    {
                        breakpoint: 200,
                        options: {
                            chart: {
                                height: '250',
                            },
                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center',
                                fontSize: '10px',
                            },
                        },
                    },
                    {
                        breakpoint: 6000,
                        options: {
                            chart: {
                                height: '280',
                            },
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                ],
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    fontSize: '10px',


                },
            },
        };
    }


    return (
        <React.Fragment >
            <div className="col-12 text-center  greyBoxShadow" style={{ height: "100%" }}>
                <div className="w-100">
                    <div className="row mt-2 px-2 time">
                        <div className='Header'>
                            <b className='font-15'>
                                {UserData.helpdeskUpdatedFlag != null ? 'Helpdesk Tickets' : 'Support Tickets'}
                            </b>
                            <br />
                        </div>
                    </div>
                    {AllDashBoardData?.SupportTicket?.loader ?
                        <><div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div></>
                        : AllDashBoardData?.SupportTicket?.data?.series?.every(item => item === 0) ? <>
                            <div className="fw-bold py-3">Yahoo, there were no tickets</div>

                        </> : <><div className="py-5"><ReactApexChart options={options?.options} series={options?.series} type="donut" /></div></>}


                </div>
            </div>
        </React.Fragment >
    )
}
