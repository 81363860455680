import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { Login } from '../Signin';
import { Signup } from '../Signup';
import { Logout } from '../Signout';
import StaticLogin from '../Signin/StaticLogin';
import { TermsAndCondition } from '../Termsandcondition';
import PrivacyPolicy from '../PrivacyPolicy';
export default function UnauthenticatedRoutes() {
    return (
        <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/k12logout" element={<Logout />} />
            <Route path="/" element={<Login />} />
            <Route path="/loadtestinglogin" element={<StaticLogin />} />
            <Route exact path="/terms-of-service" element={<TermsAndCondition />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    )
}
