import React, { useEffect, useState } from 'react'
import PageHead from '../../Components/PageHead'
import Tab from '../../Components/Tab';
import { formatDateToMMDDYYYY, LogInSchoolID, MMDDYYYY, typewatch } from '../../JS/Common';
import { ApiGetCall } from '../../JS/Connector';
import Pagination from 'react-js-pagination';
import Popup from '../../Components/Popup';

export default function History() {
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [SortbyKey, setSortbyKey] = useState(null);
    const [UncoveredPopup, setUncoveredPopup] = useState(false);
    const [Tabs, setTabs] = useState({
        RefundHistory: "active",
        CoverageHistory: ""
    })
    const [AllData, setAllData] = useState({
        searchstringForRefund: "",
        searchstringForCoverage: "",
        SortbyFlag: "asc"
    })
    const [GridData, setGridData] = useState([]);
    const [PlanData, setPlanData] = useState({});
    const showPagination = GridData.length > 0;
    const itemsPerPage = LimitForPagination;
    useEffect(() => {
        if (Tabs.RefundHistory == "active") {
            GetData(1, AllData.searchstringForRefund || null, SortbyKey || null)
        } else {
            GetData(2, AllData.searchstringForCoverage || null, SortbyKey || null)
        }
    }, [currentPage, LimitForPagination])
    function GetData(tabflag, searchstring, sortbykey) {
        if (!searchstring) searchstring = null;
        if (sortbykey) {
            AllData.SortbyFlag = AllData.SortbyFlag == "asc" ? "desc" : "asc";
        }
        else {
            sortbykey = 'id';
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/getplanhistory/" + tabflag + '&' + LogInSchoolID + '&' + searchstring + '&' + sortbykey + '&' + AllData.SortbyFlag).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    setGridData(responseRs.length ? responseRs : [])
                }
            });
        }, searchstring != null ? 1000 : 0);
    }
    function handleSearchChange(e) {
        if (Tabs.RefundHistory == "active") {
            setCurrentPage(1);
            GetData(1, e.target.value, null);
            setAllData({ ...AllData, searchstringForRefund: e.target.value });
        } else {
            setCurrentPage(1);
            GetData(2, e.target.value, null);
            setAllData({ ...AllData, searchstringForCoverage: e.target.value });
        }
    }
    function handleTabClick(tabType) {
        if (tabType == "refund") {
            GetData(1, null, null);
            setTabs({ ...Tab, RefundHistory: "active", CoverageHistory: "" });
            setCurrentPage(1);
        } else if (tabType == "covered") {
            GetData(2, null, null);
            setTabs({ ...Tab, RefundHistory: "", CoverageHistory: "active", });
            setCurrentPage(1);
        }
    }
    const handleSortClick = (item) => {
        if (item !== 0) {
            if (Tabs.RefundHistory == "active") {
                GetData(1, AllData.searchstringForRefund, item)
            } else {
                GetData(2, AllData.searchstringForCoverage, item)
            }
            setSortbyKey(item)
        }
    };
    const modalbody = (
        <div className='row'>
            <div className='col-12'>
                Student num : <b>{PlanData?.student?.student_num}</b>
            </div>
            <div className='col-12'>
                Student Name : <b>{PlanData?.student?.device_user_first_name} {PlanData?.student?.device_user_last_name}</b>
            </div>
            <div className='col-12 pb-2'>
                Uncovered to covered date : <b>{MMDDYYYY(PlanData?.uncovered_to_covered_date)}</b>
            </div>
            <hr />
            <b>Uncovered Plan : </b>
            <div className='col-12 py-1'>
                Plan Name : <b>{PlanData.old_allocation}</b>
            </div>
            <div className='col-12 py-1'>
                Sign Up Start Date : <b>{MMDDYYYY(PlanData.old_allocation_SignUp_Date)}</b>
            </div>
            <div className='col-12 py-1'>
                Paid Amount : <b>$0</b>
            </div>
            <div className='col-12 pt-1 pb-3'>
                Grade : <b>{PlanData.old_allocation_Grade}</b>
            </div>
            <hr />
            <b>Covered Plan :</b>
            <div className='col-12 py-1'>
                Plan Name : <b>{PlanData.new_allocation}</b>
            </div>
            <div className='col-12 py-1'>
                Sign Up Start Date : <b>{MMDDYYYY(PlanData.new_allocation_SignUp_Date)}</b>
            </div>
            <div className='col-12 py-1'>
                Paid Amount : <b>${PlanData.new_allocation_Paid_amount}</b>
            </div>
            <div className='col-12 py-1'>
                Grade : <b>{PlanData.new_allocation_Grade}</b>
            </div>
        </div>
    )
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Plan History"} handleSearch={handleSearchChange}
                loading={loading} showsearch={true} />
            <div className="GridBox mt-2 p-3">
                <div className="row px-0 pb-2">
                    <ul className="nav nav-tabs">
                        <Tab isActive={Tabs.RefundHistory == 'active'}
                            label="Students Refunded" onClick={() => handleTabClick('refund')} col={'col-md-3'} />
                        <Tab isActive={Tabs.CoverageHistory == 'active'}
                            label="Uncovered to Covered" onClick={() => handleTabClick('covered')} col={'col-md-3'} />
                    </ul>
                </div>
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                <th scope="col" className='cursor-pointer fw-600' title={`Sort by Student Num`} onClick={(e) => handleSortClick('student_num')}>
                                    Student Num<img src="/images/sortbynew.svg" className="img-fluid ps-2 sortbyClass" />
                                </th>
                                <th scope="col" className='cursor-pointer fw-600' title={`Sort by Student Name`} onClick={(e) => handleSortClick('device_user_first_name')}>
                                    Student Name<img src="/images/sortbynew.svg" className="img-fluid ps-2 sortbyClass" />
                                </th>
                                <th scope="col" className='cursor-pointer fw-600' title={`Sort by Plan Name`} onClick={(e) => handleSortClick('plan_name')}>
                                    Plan Name<img src="/images/sortbynew.svg" className="img-fluid ps-2 sortbyClass" />
                                </th>
                                <th scope="col" className='cursor-pointer fw-600' title={`Sort by Paid Amount`} onClick={(e) => handleSortClick('paid_amount')}>
                                    Paid Amount<img src="/images/sortbynew.svg" className="img-fluid ps-2 sortbyClass" />
                                </th>
                                <th scope="col" className='cursor-pointer fw-600' title={`Sort by Grade`} onClick={(e) => handleSortClick('grade')}>
                                    Grade<img src="/images/sortbynew.svg" className="img-fluid ps-2 sortbyClass" />
                                </th>
                                {Tabs.RefundHistory == "active" && (
                                    <th scope="col" className='fw-600'>
                                        Refunded Date
                                    </th>
                                )}
                                {Tabs.CoverageHistory == "active" && (
                                    <th scope="col" className='fw-600'>
                                        Action
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {GridData.length != 0 ?
                                GridData.map((item, i) => {
                                    return <tr key={i} className="OddEvenClass tableBorderHide">
                                        {Tabs.CoverageHistory == "active" ?
                                            <>
                                                <td className="py-12">
                                                    {item.student?.student_num}
                                                </td>
                                                <td className="py-12">
                                                    {item.student?.device_user_first_name} {item.student?.device_user_last_name}
                                                </td>
                                                <td className="py-12">
                                                    {item.new_allocation}
                                                </td>

                                                <td className="py-12">
                                                    ${item.new_allocation_Paid_amount}
                                                </td>
                                                <td className="py-12">
                                                    {item.new_allocation_Grade}
                                                </td>
                                                <td className='py-12'>
                                                    <img src="/images/InfoIcon.svg" title="Show User History"
                                                        className="img-fluid cursor-pointer px-2"
                                                        onClick={(e) => { setPlanData(item); setUncoveredPopup(true) }} />
                                                </td>
                                            </>
                                            :
                                            <>
                                                <td className="py-12">
                                                    {item.student_num}
                                                </td>
                                                <td className="py-12">
                                                    {item.student_name}
                                                </td>
                                                <td className="py-12">
                                                    {item.oldplan?.plan_name}
                                                </td>
                                                <td className="py-12">
                                                    ${item.paid_amount}
                                                </td>
                                                <td className="py-12">
                                                    {item.grade}
                                                </td>
                                                <td className='py-12'>
                                                    {formatDateToMMDDYYYY(item.created_at)}
                                                </td>
                                            </>
                                        }
                                    </tr>
                                })
                                :
                                <tr className="text-center">
                                    <td colSpan={15}>No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={GridData.length}
                        pageRangeDisplayed={15}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div>
            <Popup isshow={UncoveredPopup} size={"md"} title={"Uncovered to Covered Student"}
                closePopup={(e) => { setUncoveredPopup(false) }}
                modalBody={modalbody} visibleSaveBtn={false} />
        </React.Fragment>
    )
}
