import React, { Fragment, useEffect, useState } from 'react'
import { CamelCaseWithSpaces, LogInUserID, MMDDYYYY, getpostalcodes, typewatch } from '../../JS/Common';
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from '../../JS/Connector';
import Pagination from 'react-js-pagination';
import { CheckValidation } from '../../Components/Validations';
import AlertsComp from '../../Components/AlertsComp';
import { MDBSwitch } from 'mdb-react-ui-kit';
import CustomizationPopup from '../../Components/CustomizationPopup';
import PageHead from '../../Components/PageHead';
import Popup from '../../Components/Popup';
export function Locations() {
    const [allData, setAllData] = useState({
        flag: 3,
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        locationId: '',
        EditID: 0,
        LocationName: '',
        SortbyFlag: 'asc',
        RecipientsName: '',
        Recipientsphone: ''
    })
    const [SortbyFlag, setSortbyFlag] = useState('asc')
    const [SortbyKey, setSortbyKey] = useState(null)
    const [AddressData, setAddressData] = useState([]);
    const [DefaultAddressData, setDefaultAddressData] = useState({
        StreetLine: '',
        StreetLine2: '',
        City: '',
        State: '',
        postalCode: '',
        ContactNumber: '',
        ContactName: '',
        PostalCode: '',
        CountryCode: '',
        CreateDate: ''
    });
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [AddupdatePopup, setAddupdatePopup] = useState(false);
    const [AllState, setallstate] = useState([]);
    const [Searchstring, setSearchstring] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const showPagination = AddressData.length > 0;
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    useEffect(() => {
        ApiGetHeaders(35, setColumnNames)
    }, []);
    useEffect(() => {
        GetAddressData(Searchstring, SortbyKey, SortbyFlag);
        getpostalcodes(setallstate);
    }, []);
    async function GetAddressData(searchstring, sortbykey, sortbyflag) {
        if (!searchstring) searchstring = null;
        if (sortbykey != null) {
            setSortbyKey(sortbykey)
            setSortbyFlag(sortbyflag);
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/LocationAddress/" + searchstring + '&' + sortbykey + '&' + sortbyflag + '&' + currentPage + '&' + LimitForPagination + '&' + LogInUserID).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    setpageRangeCount(responseRs.orignalAddress.total)
                    setDefaultAddressData({
                        ...DefaultAddressData, StreetLine: responseRs.defaultAddress.street_line_1, StreetLine2: responseRs.defaultAddress.street_line_2, City: responseRs.defaultAddress.city,
                        State: responseRs.defaultAddress.state_or_province_code, postalCode: responseRs.defaultAddress.postal_code,
                        ContactNumber: responseRs.defaultAddress.contact_number, ContactName: responseRs.defaultAddress.contact_name,
                        PostalCode: responseRs.defaultAddress.postal_code, CountryCode: responseRs.defaultAddress.country_code,
                        CreateDate: responseRs.defaultAddress.created_at
                    })
                    if (responseRs.orignalAddress.length != 0) {
                        setAddressData(responseRs.orignalAddress.data);
                    }
                    else {
                        setAddressData([]);
                    }
                }
            });
        }, 500);
    }

    async function EditBuildingGetData(id) {
        setAddupdatePopup(true)
        await ApiGetCall("/LocationDataByID/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                const { street_line_1, street_line_2, city, state_or_province_code, postal_code, id } = responseRs
                setLoading(false);
                setAllData({
                    ...allData, flag: 2, address1: street_line_1 || DefaultAddressData.StreetLine, address2: street_line_2 || DefaultAddressData.StreetLine2,
                    city: city || DefaultAddressData.City, state: state_or_province_code || DefaultAddressData.State, postalCode: postal_code || DefaultAddressData.postalCode, EditID: id, LocationName: responseRs.name,
                    RecipientsName: responseRs.contact_name || DefaultAddressData.ContactName, Recipientsphone: responseRs.contact_number || DefaultAddressData.ContactNumber
                });
            }
        });
    }
    async function AddupdateCall(flag) {
        var isFormValid = CheckValidation({ formID: 'Addresspopup' });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            LocationName: allData.LocationName,
            StreetLine1: allData.address1,
            StreetLine2: allData.address2 ? allData.address2 : null,
            City: allData.city,
            StateOrProvinceCode: allData.state,
            PostalCode: allData.postalCode,
            Id: allData.EditID,
            RecipientsName: allData.RecipientsName,
            Recipientsphone: allData.Recipientsphone
        });
        await ApiPostCall("/AddUpdateLocationAddress ", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    if (allData.flag == 1) {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Location Added Successfully."} />);
                    } else {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Location Updated Successfully."} />);
                    }
                    setAddupdatePopup(false);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        setAllData({ ...allData, flag: 1, RecipientsName: '', Recipientsphone: '', LocationName: '', city: '', postalCode: '', address1: '', address2: '', state: '' });

                        GetAddressData(Searchstring, SortbyKey, SortbyFlag);
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }

    async function ActiveDeactive(id, name, flag) {
        setLoading(true);
        var raw = JSON.stringify({
            Id: id,
            name: name,
            flag: flag
        });
        await ApiPostCall("/AddUpdateLocation", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    if (flag == 1) {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Location Activated Successfully."} />);
                    } else {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Location Deactivated Successfully."} />);
                    }
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        GetAddressData(Searchstring, SortbyKey, SortbyFlag);
                    }, 2000);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const handleSortClick = (item) => {
        if (item == 'Location') {
            item = 'name'
        } else if (item == 'StreetLine1') {
            item = 'street_line_1'
        } else {
            item = item.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
        }
        if (item !== 0) {
            setSortbyKey((prevSortbyKey) => {
                setSortbyFlag((prevSortbyFlag) => {
                    const newSortbyFlag = prevSortbyFlag === "asc" ? "desc" : "asc";
                    GetAddressData(Searchstring, item, newSortbyFlag);
                    return newSortbyFlag;
                });
                return item;
            });
        }
    };
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "Location": item.name || '-',
            "StreetLine1": item.street_line_1 || DefaultAddressData.StreetLine || '-',
            "City": item.city || DefaultAddressData.City || '-',
            "StateOrProvinceCode": item.state_or_province_code || DefaultAddressData.State || '-',
            "ContactName": item.contact_name || DefaultAddressData.ContactName || '-',
            "ContactNumber": item.contact_number || DefaultAddressData.ContactNumber || '-',
            "PostalCode": item.postal_code || DefaultAddressData.PostalCode || '-',
            "CountryCode": item.country_code || DefaultAddressData.CountryCode || '-',
            "created_at": item.created_at ? MMDDYYYY(item.created_at) : DefaultAddressData.CreateDate ? MMDDYYYY(DefaultAddressData.CreateDate) : '-',
        };
        return contentMap[columnName] || null;
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        setSearchstring((prevSearchstring) => {
            const newSearchstring = e.target.value;
            GetAddressData(newSearchstring, SortbyKey, SortbyFlag);
            return newSearchstring;
        });
    }
    const modalBody = (
        <div id="Addresspopup" className="parent">
            <div className='row'>
                <div className='col-md-6 py-2'>
                    <div className='FormLabel'>Recipients Name<label className='redText'>*</label></div>
                    <input className="form-control" name='building' autoComplete='off' required value={allData.RecipientsName} onChange={(e) => { setAllData({ ...allData, RecipientsName: e.target.value }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className='col-md-6 py-2'>
                    <div className='FormLabel'>Recipients Phone<label className='redText'>*</label></div>
                    <input type='number' className="form-control" name='building' autoComplete='off' required value={allData.Recipientsphone} onChange={(e) => { setAllData({ ...allData, Recipientsphone: e.target.value }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className='col-md-6 py-2'>
                    <div className='FormLabel'>Location Name<label className='redText'>*</label></div>
                    <input className="form-control" name='building' autoComplete='off' required value={allData.LocationName} onChange={(e) => { setAllData({ ...allData, LocationName: e.target.value }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className='col-md-6 py-2'>
                    <div className='FormLabel'>Street Line 1<label className='redText'>*</label></div>
                    <input className="form-control" name='building' autoComplete='off' required value={allData.address1} onChange={(e) => { setAllData({ ...allData, address1: e.target.value }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className='col-md-6 py-2'>
                    <div className='FormLabel'>Street Line 2</div>
                    <input className="form-control" name='building' autoComplete='off' value={allData.address2} onChange={(e) => { setAllData({ ...allData, address2: e.target.value }) }} />
                </div>
                <div className='col-md-6 py-2'>
                    <div className='FormLabel'>City<label className='redText'>*</label></div>
                    <input className="form-control" name='building' autoComplete='off' required value={allData.city} onChange={(e) => { setAllData({ ...allData, city: e.target.value }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className='col-md-6 py-2'>
                    <div className='FormLabel'>State<label className='redText'>*</label></div>
                    <select required value={allData.state} onChange={(e) => setAllData({ ...allData, state: e.target.value })}>
                        <option value="0">Select State</option>
                        {AllState.map((item, i) => {
                            return <option value={item.state_code}>{item.name}</option>
                        })}
                    </select>
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className='col-md-6 py-2'>
                    <div className='FormLabel'>Postal Code<label className='redText'>*</label></div>
                    <input type='number' className="form-control" name='building' autoComplete='off' required value={allData.postalCode} onChange={(e) => { setAllData({ ...allData, postalCode: e.target.value }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className="col-md-6 py-2">
                    <div className='FormLabel'>Country</div>
                    <input type="text" autoComplete='off' value='US' disabled name='City' className="form-control" />
                </div>
            </div>
        </div>
    )
    return (
        <Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Locations"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="GridBox px-3 py-2 mt-2">
                <div className='row text-end'>
                    <div className="col-md-9"></div>
                    <div className="col-md-3 pb-2">
                        <label className='BorderBtn text-center marginDetailsPopup' onClick={(e) => { setAllData({ ...allData, flag: 1, RecipientsName: '', Recipientsphone: '', LocationName: '', city: '', postalCode: '', address1: '', address2: '', state: '' }); setAddupdatePopup(true) }}> Add Location
                            <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                </div>
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                {columnNames?.map((item) => {
                                    let columnName = item.replace(/_/g, ' ');
                                    if (item == 'StreetLine1') {
                                        columnName = 'Street';
                                    } else if (item == 'StateOrProvinceCode') {
                                        columnName = 'State'
                                    }
                                    return (
                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                            {CamelCaseWithSpaces(columnName)}
                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                        </th>
                                    );
                                })}
                                <th scope="col" className='cursor-pointer fw-600 text-center'>Status</th>
                                <th scope="col" className='fw-600 text-center'>Action<img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {AddressData.length != 0 ?
                                AddressData.map((item, i) => {
                                    return (
                                        <tr className="OddEvenClass tableBorderHide">
                                            {columnNames?.map(header => (
                                                <td className="py-12" key={header}>
                                                    {renderContentForColumn(header, item)}
                                                </td>
                                            ))}
                                            <td scope="col" className='text-center py-12'>
                                                {item.status == 1 ?
                                                    <MDBSwitch className="BgPink cursor-pointer" checked={item?.isChecked || true} onChange={(e) => { ActiveDeactive(item.id, item.name, 2) }} locationid={item.id} />
                                                    :
                                                    <MDBSwitch className="BgPink cursor-pointer" checked={item?.isChecked || false} onChange={(e) => { ActiveDeactive(item.id, item.name, 1) }} locationid={item.id} />
                                                }
                                            </td>
                                            <td scope="col" className='text-center py-12'>
                                                <img src='/images/EditIconNew.svg' title='Edit Location' className='img-fluid pe-2 cursor-pointer'
                                                    onClick={(e) => { setAllData({ ...allData, flag: 2 }); EditBuildingGetData(item.id) }} locationid={item.id} />
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr className="text-center">
                                    <td colSpan={15}>No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => { setCurrentPage(e); setSortbyFlag(SortbyFlag) }}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div>

            <Popup isshow={AddupdatePopup} size={"md"} title={allData.flag == 1 ? 'Add Location' : 'Edit Location'}
                closePopup={(e) => { setAllData({ ...allData, address1: '', address2: '', city: '', state: '', postalCode: '', flag: 3, LocationName: '', EditID: 0, RecipientsName: '', Recipientsphone: '' }); setAddupdatePopup(false) }}
                modalBody={modalBody} handleSave={(e) => allData.flag == 1 ? AddupdateCall(1) : AddupdateCall(2)}
                visibleSaveBtn={true} btnText={allData.flag == 1 ? 'Save' : 'Update'} />

            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Locations'} GetCall={() => ApiGetHeaders(35, setColumnNames)} />
            )}
        </Fragment >
    )
}
