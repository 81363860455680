import React, { useState, useContext, useCallback, useRef, useLayoutEffect } from "react";
import Chart from "react-apexcharts";
import { TableDataContext } from "..";
import Charts from "./Charts";
import { quicksort } from "../../../JS/Common";


export default function DeptAndEmpTicketClosure(props) {
    const { AllDashBoardData, FilterData } = useContext(TableDataContext);
    const [data, setData] = useState([]);
    useLayoutEffect(() => {
        setData(AllDashBoardData?.TechnicianClosureTickets?.data?.data)
    }, [AllDashBoardData?.TechnicianClosureTickets?.data, FilterData])
    const [sortOrder, setSortOrder] = useState("desc");
    const handleSort = (val) => {
        let sortedData;
        if (val === "ticket") {
            sortedData = quicksort([...data], "ticket_count", sortOrder);
        } else if (val === "alphabet") {
            sortedData = quicksort([...data], "name", sortOrder);
        } else if (val === "minutes") {
            sortedData = quicksort([...data], "time", sortOrder);
        } else {
            sortedData = data;
        }

        setData(sortedData);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    return (
        <div className="col-12  greyBoxShadow" style={{
            height: "100%"
        }}>
            <div className=" w-100">
                <div className="row my-2 px-2 time text-center">
                    <div className='Header'>
                        <b className='font-15'>Tickets Closure by Technicians</b><br />
                    </div>
                </div>
                {AllDashBoardData?.TechnicianClosureTickets?.loader ?
                    <><div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div></>
                    : <><div className="row py-3 px-2">
                        <div className="col-md-8 col-12 m-auto">
                            <div className="row">
                                <div className="col-md-3 col-6">
                                    <div className="boxStyle p-2 text-center" style={{ fontSize: "10px", fontWeight: "600", border: " 1px solid" }}>
                                        <div>Total tickets</div>
                                        <div>closed: <span style={{ color: "#3CBBA5", fontWeight: "700" }}>{AllDashBoardData?.TechnicianClosureTickets?.data?.TotalTicketClosed}</span></div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="boxStyle p-2 text-center" style={{ fontSize: "10px", fontWeight: "600", border: " 1px solid" }}>
                                        <div>Overall tickets</div>
                                        <div>closure avg: <span style={{ color: "#3CBBA5", fontWeight: "700" }}>{AllDashBoardData?.TechnicianClosureTickets?.data?.OverallTicketsClosureAvg} days</span></div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 cursor-pointer mt-md-0 mt-3" onClick={() => handleSort("alphabet")} title="Alphabetical sorting.">
                                    <div className="boxShadowStyle">
                                        <div className="row text-center py-1">
                                            <div className="col-6 pe-0">
                                                <div className="row">
                                                    <div className="col-12" style={{ fontSize: "11px" }}>
                                                        A
                                                    </div>
                                                    <div className="col-12" style={{ fontSize: "11px" }}>
                                                        Z
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 ps-0">
                                                <img className="img-fluid pt-1" src="/images/mi_sort.svg" alt="" style={{ height: "25px" }} />
                                            </div></div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 cursor-pointer mt-md-0 mt-3" onClick={() => handleSort("ticket")} title="Ticket quantity-wise sorting."><div className="boxShadowStyleGray"><div className="row py-1">
                                    <div className="col-6 pe-0">
                                        <div className="row text-center ">
                                            <div className="col-12" style={{ fontSize: "11px", color: "#ffffff" }}>
                                                0
                                            </div>
                                            <div className="col-12" style={{ fontSize: "11px", color: "#ffffff" }}>
                                                9
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 ps-0">
                                        <img className="img-fluid pt-1" src="/images/mi_sort_white.svg" alt="" style={{ height: "25px" }} />
                                    </div>
                                </div>
                                </div>
                                </div>
                                <div className="col-md-2 col-4 cursor-pointer mt-md-0 mt-3" onClick={() => handleSort("minutes")} title="Efficiency-based sorting."><div className="boxShadowStyleBlack"><div className="row py-1">
                                    <div className="col-6 pe-0">
                                        <div className="row text-center">
                                            <div className="col-12" style={{ fontSize: "11px", color: "#ffffff" }}>
                                                0
                                            </div>
                                            <div className="col-12" style={{ fontSize: "11px", color: "#ffffff" }}>
                                                60
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 ps-0">
                                        <img className="img-fluid pt-1" src="/images/mi_sort_white.svg" alt="" style={{ height: "25px" }} />
                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="d-flex justify-content-center py-1">
                            <div ><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="#3CBBA5" />
                            </svg><span className="ps-2 fw-bold">Tickets closed by technician</span></div>
                            <div className="ps-3"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="black" />
                            </svg><span className="ps-2 fw-bold">Tickets closure avg. (days)</span></div>
                        </div>
                        <div className="pb-0" style={{ overflowX: "scroll" }}>
                            <Charts data={data} />
                        </div>
                        <div className="row py-1">
                            <div className="col-12 text-center fw-bold">
                                Technicians
                            </div>
                        </div></>}

            </div>
        </div >
    );
}
