import React from 'react'
import { useState } from 'react'
import { CheckValidation } from '../../Components/Validations';
import { HideLoder, LogInSchoolID, ShowLoder, getTodayDate } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import Popup from '../../Components/Popup';
function SoftwareAddUpdate(props) {
    const initialState = {
        SoftwareName: '',
        PurchasedDate: getTodayDate(new Date()),
        LengthOfLicenses: '',
        EndDate: '',
        Cost: '',
        BuildingsUsed: '',
        Quantity: '',
        Document: null,
        Filename: "",
        Notes: '',
        PdfView: '',
        Grade: '',
        Subject: ''
    };

    const [allData, setAllData] = useState(initialState)
    const [NewAlerts, setNewAlerts] = useState("");
    const [loading, setLoading] = useState(false);

    const FlagforAddupadte = props.addupdateFlag
    const BaseUrl = process.env.REACT_APP_Base_URL;
    const BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const ciphertext = Cookies.get('ciphertext');
    const myHeaders = new Headers({
        "Access-Control-Allow-Origin": "*",
        "Authorization": ciphertext,
    });
    useEffect(() => {
        if (props.EditData) {
            setAllData({
                SoftwareName: props.EditData.name,
                PurchasedDate: props.EditData.date_purchased,
                LengthOfLicenses: props.EditData.license_length,
                EndDate: props.EditData.license_renewal,
                Cost: props.EditData.cost,
                BuildingsUsed: props.EditData.buildings,
                Quantity: props.EditData.license_qty,
                Notes: props.EditData.notes,
                Filename: props.EditData.document,
                PdfView: props.EditData.document,
                Grade: props.EditData.grade,
                Subject: props.EditData.subject
            });
        }
        if (FlagforAddupadte == 1) {
            setAllData(initialState);
        }
    }, [props.EditData, FlagforAddupadte])
    const handlePdfFileUpload = (e) => {
        const file = e.target.files[0];
        setAllData({ ...allData, Filename: file.name, Document: file })
    };
    const saveSoftware = async (flag) => {
        if (!CheckValidation({ formID: 'AddSoftwareForm' })) return;
        setLoading(true);
        const formdata = new FormData();
        formdata.append('schoolId', LogInSchoolID);
        formdata.append('Name', allData.SoftwareName);
        formdata.append('purchaseDate', allData.PurchasedDate);
        formdata.append('licenselength', allData.LengthOfLicenses);
        formdata.append('licenserenewal', allData.EndDate);
        formdata.append('cost', allData.Cost);
        formdata.append('Buildings', allData.BuildingsUsed);
        formdata.append('licenseQty', allData.Quantity);
        formdata.append('Notes', allData.Notes);
        formdata.append('addupdateflag', flag);
        formdata.append('ID', props.Softwareid);
        formdata.append('Document', allData.Document);
        formdata.append('Grade', allData.Grade);
        formdata.append('Subject', allData.Subject);
        try {
            const response = await fetch(`${BaseUrl}/addupdatesoftware`, {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            });
            const result = await response.json();
            if (result.response == "success") {
                const msg = flag == 1 ? 'Software Successfully Added!' : 'Software Successfully Updated!';
                setNewAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                setTimeout(() => {
                    setNewAlerts(<AlertsComp show={false} />);
                    props.ClosePopup();
                    props.GetAllData(null, null, null);
                    setAllData(initialState);
                }, 2000);
            } else {
                setNewAlerts(<AlertsComp show={true} variant="danger" msg={result.response} />);
                setTimeout(() => {
                    setNewAlerts(<AlertsComp show={false} />);
                }, 2000);
            }
        } catch (error) {
            console.error('error from addupdatesoftware:', error);
        }
    }
    const openPDFInNewTab = () => {
        if (props.EditData && props.EditData.document) {
            const link = document.createElement('a');
            link.href = `${BucketS3Link}${props.EditData.document}`;
            window.open(link.href, '_blank');
        }
    };
    const modalBody = (
        <form id='AddSoftwareForm' className="parent">
            <div className='row justify-content-between'>
                {[
                    { label: 'Name', type: 'text', name: 'SoftwareName', required: true, value: allData.SoftwareName },
                    { label: 'Purchase Date', type: 'date', name: 'PurchasedDate', required: true, value: allData.PurchasedDate },
                    { label: 'Length of Licenses', type: 'number', name: 'LengthOfLicenses', required: true, value: allData.LengthOfLicenses, min: 0 },
                    { label: 'License Renewal / End Date', type: 'date', name: 'EndDate', required: true, value: allData.EndDate },
                    { label: 'Cost', type: 'number', name: 'Cost', required: true, value: allData.Cost, prefix: '$', min: 0 },
                    { label: 'Buildings Used for', type: 'text', name: 'BuildingsUsed', required: true, value: allData.BuildingsUsed },
                    { label: 'Quantity', type: 'number', name: 'Quantity', required: true, value: allData.Quantity, min: 0 },
                    { label: 'Grade', type: 'text', name: 'Grade', value: allData.Grade },
                    { label: 'Subject', type: 'text', name: 'Subject', value: allData.Subject },
                ].map((field, index) => (
                    <div key={index} className='col-md-6 pb-3'>
                        <label className='FormLabel'>{field.label}</label> {field.required && <label className='redText'>*</label>}
                        <div className="input-group">
                            {field.prefix && <span className="input-group-text">{field.prefix}</span>}
                            <input
                                type={field.type}
                                autoComplete='off'
                                className="form-control"
                                name={field.name}
                                required={field.required}
                                value={field.value}
                                min={field.min}
                                onChange={(e) => setAllData({ ...allData, [field.name]: e.target.value })}
                            />
                            {field.required && <span className="form-text invalid-feedback">*required</span>}
                        </div>
                    </div>
                ))}
                <div className='col-md-6 pb-3 align-items-center'>
                    <label className='FormLabel'>Add Document</label><br />
                    {props.EditData && props.EditData.document ?
                        <>
                            <input type="file" id="UploadFileId" name="myFile" accept="application/pdf" onChange={handlePdfFileUpload} />
                            <label className="FileUploadButton col-md-12" htmlFor="UploadFileId" style={{ color: "#909091" }}>
                                Choose File
                            </label>
                            <label className="ps-2 mt-1" style={{ fontWeight: "600", color: '#3CBBA5' }}>{allData.PdfView}</label>
                            <img src='/images/pdficon.svg' title="Show Software Details" className='pe-2' onClick={openPDFInNewTab} />
                        </>
                        :
                        <>
                            <input type="file" id="UploadFileId" name="myFile" accept="application/pdf" onChange={handlePdfFileUpload} />
                            <label className="FileUploadButton col-md-12" htmlFor="UploadFileId" style={{ color: "#909091" }}>
                                Choose File
                            </label>
                            {allData.Filename && <label className="ps-2 mt-1" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{allData.Filename}</label>}
                        </>
                    }
                </div>
                <div className='col-md-12 pb-3'>
                    <label className='FormLabel'>Notes</label>
                    <textarea className='form-control notesdata' name='Notes' rows={2} value={allData.Notes}
                        onChange={(e) => setAllData({ ...allData, Notes: e.target.value })}></textarea>
                </div>
            </div>
        </form>
    )
    return (
        <div>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"lg"} title={FlagforAddupadte == 1 ? 'Add New Software' : 'Edit Software'}
                closePopup={(e) => { props.ClosePopup(); setAllData(initialState); }}
                modalBody={modalBody} handleSave={(e) => FlagforAddupadte == 1 ? saveSoftware(1) : saveSoftware(2)} visibleSaveBtn={true}
                btnText={FlagforAddupadte == 1 ? 'Add' : 'Update'} />
        </div>
    )
}

export default SoftwareAddUpdate
