import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { MMDDYYYY } from '../../../JS/Common';
import AlertsComp from '../../../Components/AlertsComp';

export default function TicketByBuilding(props) {
    var vData = props?.data;
    const [series, setSeries] = useState([]);
    const [chartLoaded, setChartLoaded] = useState(false);
    useEffect(() => {
        const newSeries = vData?.series?.map(value => parseFloat(value)?.toFixed(2)) || [];
        setSeries(newSeries);
        setChartLoaded(true);
    }, [props.data]);
    const options1 = {
        chart: {
            height: 350,
            type: "bar",
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top", // Place labels on top of the bars
                },
            },
        },
        xaxis: {
            categories: vData?.categories,
            title: {
                text: "Building",
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return parseFloat(val)?.toFixed(2);
                },
            },
            title: {
                text: "No of Ticket",
            },
        },
        dataLabels: {
            style: {
                colors: ["black"],
            },
        },
        chart: {
            toolbar: {
                show: false,
            },
        },
        colors: ["#92b5e4"],
        tooltip: {
            y: {
                formatter: function (val) {
                    return "Total Tickets: " + val;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    },
                },
            },
        },
    };
    const downloadCsv = () => {
        const csvContent = [];
        const gradeMap = new Map();
        props?.CsvData?.forEach((ticket) => {
            const building = ticket?.inventory_management?.building_name?.name ? ticket?.inventory_management?.building_name?.name : 'Unknown';
            if (!gradeMap.has(building)) {
                gradeMap.set(building, []);
            }
            const rowData = {
                'Ticket Number': ticket.ticket_num,
                'Serial Number': ticket?.inventory_management?.serial_number,
                'Student Number': ticket?.inventory_management?.student_inventory?.student?.student_num,
                'Notes': ticket?.notes?.replace(/[,\n.]/g, ' '),
                'Return Location': ticket?.return_location?.name,
                'Ticket Status': ticket?.status?.status,
                'Created at': MMDDYYYY(ticket.created_at),

            };
            gradeMap.get(building).push(rowData);
        });

        gradeMap.forEach((gradeData, building) => {
            csvContent.push([`Building: ${building}`]);
            const headers = ['Ticket Number', 'Serial Number', 'Student Number', 'Notes', 'Return Location', 'Ticket Status', 'Created at'];
            csvContent.push(headers);
            gradeData.forEach((rowData) => {
                const values = Object.values(rowData);
                csvContent.push(values);
            });
            csvContent.push([]);
        });

        if (csvContent.length > 0) {
            const csvString = csvContent.map(row => row.join(',')).join('\n'); // Convert to CSV string
            const blob = new Blob([csvString], { type: 'text/csv' });  // Create a Blob with the CSV content
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'TicketsByBuilding.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            props.setAlerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
            setTimeout(() => {
                props.setAlerts(<AlertsComp show={false} />)
            }, 1500);
        }
    };
    return (
        <div className="text-center h-100 greyBox">
            <div className="pb-5 w-100">
                <div className='row'>
                    <h4>Tickets By Building</h4>
                </div>
                <div className='justify-content-center d-flex mb-3'>
                    <label className='cursor-pointer d-flex align-items-center' onClick={downloadCsv} style={{ fontSize: "12px" }}>
                        <img src="/images/downloadPlan.svg" className="pe-1" title='Download CSV' height='10px' />
                        <label className='cursor-pointer'>Download Detailed Reports</label>
                    </label>
                </div>
                {props.loder == true ?
                    <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div>
                    :
                    <div id="chart">
                        {chartLoaded && (
                            <ReactApexChart options={options1} series={[{ data: series }]} type="bar" height={350} />
                        )}
                    </div>
                }
            </div>
        </div>
    )
}
