import Cookies from "js-cookie";
import { ApiGetCall, ApiGetCallAuthKey } from "./Connector";
export var LogInSchoolnumber = Cookies.get('Schoolnumber');
export var LogInUserID = parseInt(Cookies.get('CsvUserId'));
export var LogInSchoolID = Cookies.get('schoolid');
export function MMDDYYYY(utcDateString) {
  const utc = new Date(utcDateString);
  const offset = utc.getTimezoneOffset();
  const local = new Date(utc.getTime() + offset * 60000);
  const month = String(local.getMonth() + 1).padStart(2, "0");
  const day = String(local.getDate()).padStart(2, "0");
  const year = local.getFullYear();
  return `${month}-${day}-${year}`;
}
export const formatDateToMMDDYYYY = (today) => {
  var date = new Date(today);
  var yr = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0");
  var day = date.getDate().toString().padStart(2, "0");
  var newDate = month + "-" + day + "-" + yr;
  return newDate;
};
export function YYYYMMDD(date) {
  var d = new Date(date);
  var curr_year = d.getFullYear();
  var curr_month = (d.getMonth() + 1).toString().padStart(2, "0");
  var curr_date = d.getDate().toString().padStart(2, "0");
  var newDate = curr_year + "-" + curr_month + "-" + curr_date;
  return newDate;
}
export var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};
export function ShowLoder() {
  return (
    <>
      <div className="text-center"><img src="/images/loder.svg" className="LoderImg" style={{ display: "block" }} /></div>
      <div id="Overlay" style={{ display: "block" }}></div>
    </>
  );
}
export function HideLoder() {
  return (
    <>
      <div id="Overlay" style={{ display: "none" }}></div>
      <img src="/images/loder.svg" className="m-auto LoderImg" style={{ display: "none" }} />
    </>
  );
}

export function handleKeyPress(event) {
  const pattern = /^[a-zA-Z0-9]+$/;
  const inputChar = String.fromCharCode(event.charCode);
  if (!pattern.test(inputChar)) {
    event.preventDefault();
  }
}
export const typewatch = (function () {
  var timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
export function isValidEmail(email) {
  if (!email) {
    return false; // Email is empty
  }

  const [localPart, domainPart] = email.split("@");
  if (!domainPart || domainPart.indexOf(".") === -1) {
    return false; // Domain part is missing or does not contain a dot
  }

  const localPartRegex = /^[^@\s]+$/; // Match any character that is not '@' or whitespace
  if (!localPartRegex.test(localPart)) {
    return false; // Local part contains invalid characters or is empty
  }

  const domainPartRegex = /^[A-Za-z0-9.-]+$/; // Match letters, numbers, dots, and hyphens
  if (!domainPartRegex.test(domainPart) || domainPart.length < 2) {
    return false; // Domain part contains invalid characters or is too short
  }

  // Check that the domain part does not contain consecutive periods
  if (domainPart.indexOf("..") !== -1) {
    return false;
  }

  const tldRegex = /^[A-Za-z]{2,}$/; // Match at least two letters for the TLD
  const tld = domainPart.split(".").pop();
  if (!tldRegex.test(tld)) {
    return false; // Invalid TLD
  }
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!pattern.test(email)) {
    return false;
  }
  return true;
}
export function Camelcase(str) {
  return str?.replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}
export function RemoveAllCookies() {
  localStorage.clear();
  sessionStorage.clear();
  Cookies.remove('AdminSchoolId');
  Cookies.remove('CsvUserId');
  Cookies.remove('schoolid');
  Cookies.remove('Schoolnumber');
  Cookies.remove('ciphertext');
  Cookies.remove('supportTicketDetails');
  Cookies.remove('SchoolContactID');
  Cookies.remove('ShippingType');
  Cookies.remove('Schoolname');
  Cookies.remove('ShipSchoolName');
}

export function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const quicksort = (arr, key, sortOrder) => {
  if (arr.length <= 1) {
    return arr;
  }

  const pivot = arr[Math.floor(arr.length / 2)];
  const less = arr.filter((item) => item[key] < pivot[key]);
  const equal = arr.filter((item) => item[key] === pivot[key]);
  const greater = arr.filter((item) => item[key] > pivot[key]);

  let sortedArray;
  if (sortOrder === 'asc') {
    sortedArray = [...quicksort(less, key, sortOrder), ...equal, ...quicksort(greater, key, sortOrder)];
  } else {
    sortedArray = [...quicksort(greater, key, sortOrder), ...equal, ...quicksort(less, key, sortOrder)];
  }

  return sortedArray;
};

export const customDates = (qflag) => {
  let startDate, endDate;

  const now = new Date();

  if (qflag === 2) {
    startDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
    endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);

  } else if (qflag === 3) {
    const currentQuarter = Math.ceil((now.getMonth() + 1) / 3);
    const startMonth = (currentQuarter - 1) * 3;
    startDate = new Date(now.getFullYear(), startMonth, 1, 0, 0, 0);
    endDate = new Date(now.getFullYear(), startMonth + 2, new Date(now.getFullYear(), startMonth + 2, 0).getDate(), 23, 59, 59);

  } else if (qflag === 4) {
    startDate = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
    endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59);

  } else if (qflag === 5) {
    startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0);
    endDate = new Date(now.getFullYear(), now.getMonth() - 1, new Date(now.getFullYear(), now.getMonth(), 0).getDate(), 23, 59, 59);

  } else if (qflag === 6) {
    startDate = new Date(now.getFullYear(), now.getMonth() - 5, 1, 0, 0, 0);
    endDate = new Date(now.getFullYear(), now.getMonth() - 3, new Date(now.getFullYear(), now.getMonth(), 0).getDate(), 23, 59, 59);
  } else {
    startDate = new Date(now.getFullYear() - 1, 0, 1, 0, 0, 0);
    endDate = new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59);
  }
  return { startDate, endDate }
};
export const CamelCaseWithSpaces = (input) => {
  if (/\s/.test(input)) {
    return input?.replace(/\b\w/g, (match) => match.toUpperCase());
  }
  return input.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
};
export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const handleCustomSubmit = (customFields, OriginalCustomFields, flag) => {
  const newCustomFieldValues = [];
  customFields.forEach((item, i) => {
    if (item.value && item.value_type != 7) {
      newCustomFieldValues.push({
        CustomFieldValueid: flag == 1 ? null : item.CustomFieldValueid,
        custom_field_id: item.id,
        custom_field_structure_id: null,
        value_type: item.value_type,
        value: item.value,
      });
    } else if (item.value_type == 7 && item.value.length != 0) {
      newCustomFieldValues.push({
        CustomFieldValueid: flag == 1 ? null : item.CustomFieldValueid,
        custom_field_id: item.id,
        custom_field_structure_id: item.value,
        value_type: item.value_type,
        value: item.value,
      });
    } else if (flag == 2 && (OriginalCustomFields[i].value != "" && OriginalCustomFields[i].value != null) && (item.value == null || item.value == "" || isNaN(item.value))) {
      newCustomFieldValues.push({
        CustomFieldValueid: item.CustomFieldValueid,
        custom_field_id: item.id,
        custom_field_structure_id: null,
        value_type: item.value_type,
        value: null,
      });
    }
  });
  return newCustomFieldValues;
};

export const getAllGrades = async (setGrades) => {
  var sclid = LogInSchoolID > 0 ? LogInSchoolID : 0;
  await ApiGetCall("/getGradesBySchool/" + sclid).then((result) => {
    if (result == undefined || result == "") {
      alert("Something went wrong");
    } else {
      const responseRs = JSON.parse(result);
      setGrades(responseRs);
    }
  });
}
export const getpostalcodes = async (setallstate) => {
  await ApiGetCall("/PostalCodes").then((result) => {
    if (result == undefined || result == "") {
      alert("Something went wrong");
    } else {
      const responseRs = JSON.parse(result);
      setallstate(responseRs);
    }
  });
}

export const getAllBuildings = async (setallbuilding, schoolid) => {
  await ApiGetCallAuthKey("/getBuildingsAndRooms/" + schoolid).then((result) => {
    if (result == undefined || result == "") {
      alert("Something went wrong");
    } else {
      const responseRs = JSON.parse(result);
      responseRs.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      setallbuilding(responseRs);
    }
  });
}

export const getAllUsers = async (setAllUsers) => {
  await ApiGetCall("/allUser/" + LogInSchoolID + '&null&null&asc' + "&" + LogInUserID).then((result) => {
    if (result == undefined || result == "") {
      alert("Something went wrong");
    } else {
      const responseRs = JSON.parse(result);
      setAllUsers(responseRs.msg.length ? responseRs.msg : []);
    }
  });
}
export function generateRandomString() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // months are 0-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}
