import React from 'react'
import { ApiGetCall } from '../../../JS/Connector';
import { HideLoder, ShowLoder } from '../../../JS/Common';
import { CSVLink } from 'react-csv';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export default function ExportMasterInventory() {
    const [loading, setLoading] = useState(false);
    const [InventoryDataCsv, setInventoryDataCsv] = useState([]);
    const [divVisibleForExport, setDivVisibleForExport] = useState({
        forUsers: false,
        forUsersNoRecord: false
    });
    const headers = [
        { label: "Part Name", key: "title" },
        { label: "Price", key: "variant_price" },
        { label: "Handle", key: "handle" },
        { label: "Status", key: "status" },
    ];
    const csvReport = {
        filename: "MasterInventory.csv",
        headers: headers,
        data: InventoryDataCsv,
    };
    async function ExportInventory() {
        setLoading(true);
        await ApiGetCall("/exportMasterInventory").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.MasterSku.length != 0) {
                    setInventoryDataCsv(responseRs.MasterSku);
                    setDivVisibleForExport({ ...divVisibleForExport, forUsers: true });
                } else {
                    setInventoryDataCsv([]);
                    setDivVisibleForExport({ ...divVisibleForExport, forUsersNoRecord: true });
                }
                setLoading(false);
            }
        });
    }
    function RemoveUserCsv() {
        setInventoryDataCsv([]);
        setDivVisibleForExport({ ...divVisibleForExport, forUsers: false, forUsersNoRecord: false });
    }
    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}

            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Export Master Inventory</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    Would you like to export your master inventory data? To do so, simply click on the 'Export Master Inventory' button.
                </p>
                <div className="row pt-3 pb-2 px-3">
                    <div className="col-12 px-0 py-2">
                        <label className='BorderBtnForimportExport' onClick={ExportInventory}>Export Master Inventory
                            <img src='/images/ExportInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>

                </div>
                {divVisibleForExport.forUsers &&
                    <div className="row pt-2" >
                        <div>Your Master Inventory CSV file is now ready for download. Please click the button below to initiate the file download.</div>
                        <div className="col-12 mt-1 text-center">
                            <label style={{ fontSize: "12px", color: "#04ADFD" }} id="ExportedStudentFileName">
                                MasterInventory.csv
                            </label>
                            <CSVLink {...csvReport} className="WhiteFont"> <button className='SaveBtn ms-4'>Download</button></CSVLink>
                            <FontAwesomeIcon icon={faCircleXmark} className="cursor-pointer ps-3" onClick={RemoveUserCsv} />
                        </div>
                    </div>
                }
                {divVisibleForExport.forUsersNoRecord &&
                    <div className="row pt-2">
                        <div className="text-danger">No Record Found!</div>
                    </div>
                }
            </div>

        </div>
    )
}
