import React, { useContext, useEffect, useRef, useState } from 'react'
import { CamelCaseWithSpaces, LogInUserID, MMDDYYYY, typewatch } from '../../JS/Common';
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Pagination from 'react-js-pagination';
import { UserContext } from '../../App';
import Deletepopup from '../../Components/Deletepopup';
import jinja from "jinja-js";
import html2pdf from "html2pdf.js";
import CustomizationPopup from '../../Components/CustomizationPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Schoolsearchbar } from '../../Components/Schoolsearchbar';
import SignatureCanvas from "react-signature-canvas";
import PageHead from '../../Components/PageHead';
import Tab from '../../Components/Tab';
import Popup from '../../Components/Popup';
export function AdminBilling() {
    const BaseUrl = process.env.REACT_APP_Base_URL;
    const sigCanvasForPackedBy = useRef({});
    const [SchoolId, setSchoolId] = useState('')
    const [AllInvoiceData, setAllInvoiceData] = useState([]);
    const [AdminInvoiceParams, setAdminInvoiceParams] = useState({
        BatchId: '',
        searchString: '',
        subTotal: 0,
        sendreceipt: false,
        InvoiceNo: '',
        TransactionId: '',
        uploadReceipt: false,
        flagforuploadReceipt: 2,
        fileName: '',
        file: null,
        base64String: '',
        Notes: '',
        SortbyFlag: 'desc'
    });
    const [GridTabs, setGridTabs] = useState({
        BatchesTabs: "active",
        PastBatchesTabs: ''
    });
    const [SelectedFileName, setSelectedFileName] = useState([]);
    const [NewAlerts, setNewAlerts] = useState("");

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const showPagination = AllInvoiceData.length > 0;
    const storedSearchValue = Cookies.get('AdminSchoolId');
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const { UserData } = useContext(UserContext);
    const [deletePopup, setDeletePopup] = useState(false);
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [ShowCreateBatchButton, setShowCreateBatchButton] = useState(false)
    const [selectedIdsAll, setSelectedIdsAll] = useState([]);
    const [SelectedItems, setSelectedItems] = useState([]);
    const [DeliverBatchModal, setDeliverBatchModal] = useState(false)
    const [itemID, setItemID] = useState('')
    const [FlagForItem, setFlagForItem] = useState('')
    const [PaymentLink, setPaymentLink] = useState('')
    const [linkFileName, setlinkFileName] = useState('')
    const [sendInvoiceDoc, setsendInvoiceDoc] = useState('')
    var ciphertext = Cookies.get('ciphertext');
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", ciphertext);
    const [SortKey, setSortKey] = useState(null)
    const [SignatureDataForPackedBy, setSignatureDataForPackedBy] = useState('')
    const [signatureModal, setSignatureModal] = useState(false)
    useEffect(() => {
        ApiGetHeaders(32, setColumnNames)
    }, []);
    useEffect(() => {
        if (storedSearchValue != null && storedSearchValue != 0 && storedSearchValue != undefined) {
            if (GridTabs.BatchesTabs == 'active') {
                GetAdminInvoiceData(storedSearchValue, AdminInvoiceParams.searchString ? AdminInvoiceParams.searchString : null, SortKey || null, 2)
            } else {
                GetAdminInvoiceData(storedSearchValue, AdminInvoiceParams.searchString ? AdminInvoiceParams.searchString : null, SortKey || null, 3)
            }
        }
    }, [storedSearchValue, currentPage, LimitForPagination]);
    useEffect(() => {
        const selectedArray = [
            ...selectedIdsAll.map((id) => ({ id })),
        ];
        const idAndSubtotalArray = selectedArray.map(({ id }) => ({ id: id }));
        setSelectedItems(idAndSubtotalArray);
        if (idAndSubtotalArray.length === 0) {
            setShowCreateBatchButton(false)
        }
    }, [selectedIdsAll])
    async function GetAdminInvoiceData(id, searchString, sortkey, flag) {
        setSchoolId(id)
        if (searchString == undefined || searchString == "") {
            searchString = null;
        }
        if (sortkey == undefined || sortkey == "") {
            sortkey = null;
        }
        if (sortkey != null) {
            if (AdminInvoiceParams.SortbyFlag == "desc") {
                AdminInvoiceParams.SortbyFlag = "asc";
            } else {
                AdminInvoiceParams.SortbyFlag = "desc";
            }
        }
        var GridFlagForBilling = null
        if (flag == 2) {
            GridFlagForBilling = 32
        } else {
            GridFlagForBilling = 37
        }
        var joinedString = null;
        if (columnNames.length > 0) {
            var joinedString = columnNames.join('~');
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/AllBatchData/" + id + "&" + searchString + "&" + sortkey + "&" + AdminInvoiceParams.SortbyFlag + '&' + currentPage + '&' + LimitForPagination + '&' + GridFlagForBilling + "?data=" + joinedString).then((result) => {
                setLoading(false);
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs.total)
                    if (responseRs.data.length != 0) {
                        setAllInvoiceData(responseRs.data);
                    } else {
                        setAllInvoiceData([]);
                    }
                }
            });
        }, searchString != null ? 500 : 0);
    }
    function handleSetSchoolId(id) {
        setSchoolId(id);
    }

    function handleChange(e) {
        const selectedFile = e.target.files[0];
        setAdminInvoiceParams({ ...AdminInvoiceParams, fileName: selectedFile.name, file: selectedFile })
        setSelectedFileName(selectedFile.name)
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
            setAdminInvoiceParams({ ...AdminInvoiceParams, base64String: reader.result })
        };
        reader.onerror = (error) => {
            console.log("Error: ", error);
        };

    };

    function handleSubmit(e) {
        e.preventDefault();
    };

    async function SubmitInvoicePaymentCheck() {
        setLoading(true);
        var raw = JSON.stringify({
            Receipt: AdminInvoiceParams.base64String,
            SchoolId: SchoolId,
            InvoiceId: AdminInvoiceParams.InvoiceNo,
            BatchId: AdminInvoiceParams.BatchId,
            Flag: AdminInvoiceParams.flagforuploadReceipt,
            InvoiceStatus: 1,
            Notes: AdminInvoiceParams.Notes,
            UserID: LogInUserID
        });
        await ApiPostCall("/InvoicePaymentCheck", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = result;
                setAdminInvoiceParams({ ...AdminInvoiceParams, sendreceipt: false, Notes: '', BatchId: '', uploadReceipt: false, flagforuploadReceipt: 2 })
                if (responseRs == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'Payment Processed Successfully'} />);
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />)
                        if (GridTabs.BatchesTabs == 'active') {
                            GetAdminInvoiceData(SchoolId, null, null, 2)
                        } else {
                            GetAdminInvoiceData(SchoolId, null, null, 3)
                        }
                    }, 2000);

                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }

    function trackingHandle(trackingnum) {
        const url = `https://www.fedex.com/fedextrack/?action=track&trackingnumber=${trackingnum}&cntry_code=us&locale=en_us`;
        window.open(url, '_blank');
    }
    async function ExtraDocument(FedExQr) {
        const link = document.createElement('a');
        link.href = `${BucketS3Link}${FedExQr}`;
        window.open(link.href, '_blank');
    };

    async function DeleteBatch() {
        setLoading(true);
        var raw = JSON.stringify({
            Flag: 2,
            BatchID: AdminInvoiceParams.BatchId,
            SchoolID: parseInt(SchoolId)
        });
        await ApiPostCall("/DeleteIncomingOutgoingOrBillingBatch", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setDeletePopup(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Batch Deleted Succesfully"} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                        if (GridTabs.BatchesTabs == 'active') {
                            GetAdminInvoiceData(storedSearchValue, AdminInvoiceParams.searchString ? AdminInvoiceParams.searchString : null, null, 2)
                        } else {
                            GetAdminInvoiceData(storedSearchValue, AdminInvoiceParams.searchString ? AdminInvoiceParams.searchString : null, null, 3)
                        }
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }

    function PrintPackingList(batchid) {
        var ciphertext = Cookies.get('ciphertext');
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", ciphertext);
        setLoading(true)
        fetch('PackingListForAdminBatch.html')
            .then(response => response.text())
            .then(templateText => jinja.compile(templateText))
            .then(template => {
                fetch(BaseUrl + "/printlistForBillingBatch/" + batchid, { headers: myHeaders })
                    .then(response => response.json())
                    .then(data => {
                        const formattedDate = new Date(data.created_at).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        });
                        const rendered = template.render({ data, formattedDate });
                        const pdfContent = document.createElement('div');
                        pdfContent.innerHTML = rendered;
                        pdfContent.style.padding = '20px';
                        html2pdf().from(pdfContent).save();
                        setLoading(false)
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error('Error loading data from API', error);
                    });
            })
            .catch(error => {
                setLoading(false)
                console.error('Error loading template', error);
            });
    }
    const handleSortClick = (item) => {
        if (item == 'deviceCount') {
            item = 'batch_log_count'
        }
        if (item !== 0) {
            if (GridTabs.BatchesTabs == 'active') {
                GetAdminInvoiceData(SchoolId, AdminInvoiceParams.searchString, item, 2)
            } else {
                GetAdminInvoiceData(SchoolId, AdminInvoiceParams.searchString, item, 3)
            }
            setSortKey(item)
        }
    };
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "Name": (
                <span className="cursor-pointer" title='Show Batch Details' onClick={(e) => { navigate('/ticket-history?id=' + item?.id, { state: { SchoolId, SchoolAdminFlag: 1 } }) }}>
                    <u>{item?.name || '-'}</u>
                </span>
            ),
            "TrackingNum": (
                <span>
                    {UserData.MenuAccessFlag == 6 ?
                        (item?.tracking_num != null ? item?.tracking_num : <>-</>)
                        :
                        (item?.tracking_num != null ? <u className='cursor-pointer' onClick={(e) => trackingHandle(item?.tracking_num)}>{item?.tracking_num}</u> : <>-</>)
                    }
                </span>
            ),
            "deviceCount": item?.batch_log_count || '-',
            "InvoiceNum": item?.invoice[0]?.id == null ? <>-</> : <div>#{item?.invoice[0]?.id}</div>,
            "Amount": item?.amount == null ? <>0</> : <div>${item?.amount}</div>,
            "created_at": item?.created_at == null ? <>-</> : MMDDYYYY(item?.created_at),
            "Payment_Status": (
                <span>
                    {item?.invoice[0]?.payment_status == 1 ? (
                        <span style={{ color: "#FF7076" }}>Pending</span>
                    ) : item?.invoice[0]?.payment_status == 3 ? (
                        <span style={{ color: "#3CBBA5" }}>Success</span>
                    ) : (
                        <span className='cursor-pointer' style={{ color: "#F37B22" }} onClick={(e) => setAdminInvoiceParams({ ...AdminInvoiceParams, sendreceipt: true, BatchId: item?.id, InvoiceNo: item?.invoice[0]?.id, TransactionId: item?.invoice[0]?.cheque_no })}>
                            <u>In Progress</u>
                        </span>
                    )}
                </span>
            ),
            "Notes": <div className="note-wrap">{item.notes || '-'}</div>,
        };

        return contentMap[columnName] || null;
    }

    function handleHeaderCheckboxChange(e, ticketFlag) {
        setShowCreateBatchButton(true)
        const isChecked = e.target.checked;
        if (ticketFlag === "all") {
            setSelectedIdsAll(
                isChecked ? AllInvoiceData.filter(item => item.type == null).map((item) => item.id) : []
            );
        }
    };
    function handleCheckboxChange(e, ticketFlag, ID) {
        const isChecked = e.target.checked;
        setShowCreateBatchButton(true)
        if (ticketFlag === "all") {
            setSelectedIdsAll((prevIds) => {
                if (isChecked) {
                    return [...prevIds, ID];
                } else {
                    return prevIds.filter((id) => id !== ID);
                }
            });
        }
    }
    async function DeliverBatchCall() {
        var vArray = [];
        var vjson = {};
        vjson['id'] = itemID;
        vArray.push(vjson)
        var paymentlink = null;
        if (PaymentLink) {
            if (!PaymentLink.startsWith("https://") && !PaymentLink.startsWith("http://")) {
                paymentlink = "https://" + PaymentLink;
            } else {
                paymentlink = PaymentLink;
            }
        }

        var raw = JSON.stringify({
            SchoolID: parseInt(SchoolId),
            BatchID: FlagForItem == 1 ? vArray : SelectedItems,
            Flag: 1,
            UserID: LogInUserID,
            linkForpayment: paymentlink,
            Document: sendInvoiceDoc
        });
        setLoading(true)
        await ApiPostCall("/DeliverAndReciverBtnForBilling", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setDeliverBatchModal(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Invoice Sent Successfully"} />);
                    setTimeout(() => {
                        GetAdminInvoiceData(storedSearchValue, AdminInvoiceParams.searchString ? AdminInvoiceParams.searchString : null, null, 2)
                        setSelectedIdsAll([])
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    function handlePdfFileForInvoiceLink(e) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1];
            setlinkFileName(file.name);
            setsendInvoiceDoc(base64String)
        };
        reader.readAsDataURL(file);
    };
    const clearSignatureForPackedBy = () => {
        if (SignatureDataForPackedBy != null) {
            setSignatureDataForPackedBy(null)
        } else {
            sigCanvasForPackedBy.current.clear();
        }
    };
    async function SaveSignatureCall() {
        var signatureBase64 = null;
        if (SignatureDataForPackedBy == null) {
            signatureBase64 = sigCanvasForPackedBy.current.isEmpty() ? null : sigCanvasForPackedBy.current.getTrimmedCanvas().toDataURL("image/png");
        } else {
            signatureBase64 = SignatureDataForPackedBy
        }
        setLoading(true);
        var raw = JSON.stringify({
            BatchID: null,
            schoolId: parseInt(SchoolId),
            BatchPackerSignature: null,
            BatchReceiverSignature: null,
            AdminBatchID: AdminInvoiceParams.BatchId,
            AdminBatchDeliverSignature: signatureBase64,
            AdminBatchReceiverSignature: null,
            flag: 1,
            UserID: LogInUserID
        });
        await ApiPostCall("/addSignature", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setSignatureModal(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Signature Added Succesfully"} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                        if (GridTabs.BatchesTabs == 'active') {
                            GetAdminInvoiceData(SchoolId, null, null, 2)
                        } else {
                            GetAdminInvoiceData(SchoolId, null, null, 3)
                        }
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        });
    }
    function handleSearchChange(e) {
        setAdminInvoiceParams({ ...AdminInvoiceParams, searchString: e.target.value });
        setCurrentPage(1);
        GridTabs.BatchesTabs == 'active' ?
            GetAdminInvoiceData(SchoolId, e.target.value, null, 2) :
            GetAdminInvoiceData(SchoolId, e.target.value, null, 3)
    }
    function handleTabClick(tabType) {
        if (tabType == "current") {
            setGridTabs({ ...GridTabs, BatchesTabs: "active", PastBatchesTabs: '' });
            GetAdminInvoiceData(storedSearchValue, AdminInvoiceParams.searchString ? AdminInvoiceParams.searchString : null, null, 2)
        } else if (tabType == "past") {
            setGridTabs({ ...GridTabs, BatchesTabs: "", PastBatchesTabs: 'active' });
            GetAdminInvoiceData(storedSearchValue, AdminInvoiceParams.searchString ? AdminInvoiceParams.searchString : null, null, 3)
        }
    }
    const sendreceiptBody = (
        <div>
            <div className=" col-md-12 row">
                <div className="col-md-12">
                    <div className="fw-600">Transaction ID :{AdminInvoiceParams.TransactionId}</div>
                </div>
                <div className="col-md-12 d-flex align-item-center justify-content-between py-3">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name='ParentalCoverage' value='PaymentSuccess' id="SuccessYes" onChange={(e) => setAdminInvoiceParams({ ...AdminInvoiceParams, uploadReceipt: true, flagforuploadReceipt: 1 })} onClick={(e) => setAdminInvoiceParams({ ...AdminInvoiceParams, uploadReceipt: true, flagforuploadReceipt: 1 })} />
                        <label className="form-check-label">
                            Payment Success
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" defaultChecked type="radio" name='ParentalCoverage' value='PaymentWaiting' id="WaitingNo" onChange={(e) => setAdminInvoiceParams({ ...AdminInvoiceParams, uploadReceipt: false, flagforuploadReceipt: 2 })} onClick={(e) => setAdminInvoiceParams({ ...AdminInvoiceParams, uploadReceipt: false, flagforuploadReceipt: 2 })} />
                        <label className="form-check-label">
                            Payment Waiting
                        </label>
                    </div>
                </div>
                <div className=" mt-1">
                    <textarea placeholder="Notes*" rows="2" className="form-control" value={AdminInvoiceParams.Notes} required onChange={(e) => setAdminInvoiceParams({ ...AdminInvoiceParams, Notes: e.target.value })} ></textarea>
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
            </div>

            {AdminInvoiceParams.uploadReceipt == true && (
                <div className="col-md-12 row mt-2">
                    <div className="col-md-12 fw-600 py-12">Upload Invoice Receipt</div>
                    <div className="col-md-12">
                        <form onSubmit={handleSubmit}>
                            <input type="file" label="Image" name="myFile" accept="image/*" id="UploadFileId" onChange={handleChange} />
                            <label className='ImportInventoryBtnForAdminInvoice' htmlFor="UploadFileId"> Chooes file
                                <img src='/images/ImportInventory.svg' className='img-fluid ps-2' />
                            </label>
                            {SelectedFileName && <label id="ImportInventoryStudentText" className="ps-2 mt-1" style={{ fontWeight: "600", color: '#3CBBA5' }}>{SelectedFileName}</label>}
                        </form>
                    </div>

                </div>
            )}
        </div>
    )
    const delievermodal = (
        <div id="LinkDiv" className="parent">
            <div className='col-12 text-center pt-2'>
                <div className='FormLabel text-start'>Enter Payment Link</div>
                <div className='col-md-12 py-2'>
                    <div className="input-group ">
                        <span className="input-group-text">https://</span>
                        <input type="text" autoComplete='off' className="form-control" name='paymentlink'
                            value={PaymentLink} onChange={(e) => setPaymentLink(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="col-md-12 pt-3">
                <div className='FormLabel text-start'>Enter Invoice Document</div>
                <div className="col-md-12 py-2">
                    <input type="file" label="img" id="UploadFileId" name="myFile" accept="application/pdf" onChange={(e) => handlePdfFileForInvoiceLink(e)} />
                    <label className="ImportInventoryBtn col-md-8" htmlFor="UploadFileId" style={{ color: "#909091" }}>
                        Choose File
                    </label>
                    <input type="file" className="col-md-4 col-9 ImportInventoryBtn" />
                    <label className="ImportInventoryBtn1 text-center col-md-4 col-3" htmlFor="UploadFileId" style={{ backgroundColor: "#3CBBA5", color: "white", height: "40px", }}>
                        Browse
                    </label>
                </div>
                {linkFileName &&
                    <label className="ps-3 mt-1 d-flex" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{linkFileName} </label>}
            </div>

        </div>
    )
    const signaturemodalbody = (
        <div className="col-12 py-1">
            <div className="greyBox">
                <div className='fw-600 font-15 text-center'>Packed By Signature:</div>
                <div className='mt-2 text-center'>
                    {SignatureDataForPackedBy ? (
                        <img src={SignatureDataForPackedBy} alt="Packed By Signature" />
                    ) : (
                        <SignatureCanvas
                            ref={sigCanvasForPackedBy}
                            canvasProps={{ width: 430, height: 100, className: "signatureCanvas" }}
                        />
                    )}
                </div>
                <div className="text-end"> <label className="cursor-pointer" onClick={clearSignatureForPackedBy}><u>Clear</u></label></div>
            </div>
        </div>
    )
    return (
        <div>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Billing"} handleSearch={handleSearchChange}
                loading={loading} Alerts={NewAlerts} showsearch={SchoolId || null} />
            <div className='row col-12 align-items-center pe-0 pt-2'>
                <Schoolsearchbar schlid={handleSetSchoolId} GetGridData={GetAdminInvoiceData} flag={GridTabs.BatchesTabs == 'active' ? 1 : 2} /></div>
            <div className="GridBox mt-2 p-3">
                {storedSearchValue && (
                    <div className="row align-items-center mb-3">
                        <div className="col-md-9 px-0">
                            <ul className="nav nav-tabs px-3">
                                <Tab isActive={GridTabs.BatchesTabs == 'active'}
                                    label="Current Batches" onClick={() => handleTabClick('current')} col={'col-md-3'} />
                                <Tab isActive={GridTabs.PastBatchesTabs == 'active'}
                                    label="Past Batches" onClick={() => handleTabClick('past')} col={'col-md-3'} />
                            </ul>
                        </div>
                        <div className="col-md-3 text-end px-0 ">
                            {ShowCreateBatchButton == true && (
                                <label className="BorderBtn ms-3 text-end mx-3 mt-0" onClick={() => { setDeliverBatchModal(true); }}><FontAwesomeIcon icon={faPaperPlane} className="pe-2" />Send Invoice
                                </label>
                            )}
                        </div>
                    </div>
                )}
                <div className="greyBox p-3">
                    {storedSearchValue ?
                        <>
                            <div className="data-table-container">
                                <table className="table data-table">
                                    <thead className='GridHeader'>
                                        <tr>
                                            {GridTabs.BatchesTabs == 'active' && AllInvoiceData?.filter(item => item.type == null).length != 0 ?
                                                <th scope="col">
                                                    <input className='form-check-input' type="checkbox" checked={selectedIdsAll.length == AllInvoiceData.filter(item => item.type == null).length} onChange={(e) => handleHeaderCheckboxChange(e, "all")} />
                                                </th>
                                                : <th scope="col"></th>}
                                            {columnNames?.map((item) => {
                                                let columnName = item.replace(/_/g, ' ');
                                                return (
                                                    item != "Payment_Status" ?
                                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                                            {CamelCaseWithSpaces(columnName)}
                                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                                        </th>
                                                        :
                                                        <th key={item} scope="col" className='fw-600'>
                                                            {CamelCaseWithSpaces(columnName)}
                                                        </th>
                                                );
                                            })}
                                            <th scope="col" className='fw-600'>Signature </th>
                                            {UserData.MenuAccessFlag == 6 ?
                                                <></> :
                                                <th scope="col" className='fw-600'>Action
                                                    <img src="/images/EditHeaderIcon.svg" className="cursor-pointer  img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {AllInvoiceData?.length != 0 ?
                                            AllInvoiceData?.map((item, i) => {
                                                return <tr key={i} className='OddEvenClass tableBorderHide'>
                                                    {GridTabs.BatchesTabs == 'active' ?
                                                        <td className="py-12">
                                                            {item.type == null ? (
                                                                <input className='form-check-input' type="checkbox" checked={selectedIdsAll.includes(item.id)} onChange={(e) => handleCheckboxChange(e, "all", item.id)} batchid={item.id} />
                                                            ) : <></>}
                                                        </td>
                                                        : <td></td>
                                                    }
                                                    {columnNames?.map(header => (
                                                        <td className="py-12" key={header}>
                                                            {renderContentForColumn(header, item)}
                                                        </td>
                                                    ))}
                                                    <td>
                                                        <FontAwesomeIcon icon={faPencilAlt} title="Signature" className="ps-2 cursor-pointer"
                                                            onClick={(e) => {
                                                                setSignatureDataForPackedBy(item.batch_delivered_signature);
                                                                setSignatureModal(true);
                                                                setAdminInvoiceParams({ ...AdminInvoiceParams, BatchId: item.id })
                                                            }} />
                                                    </td>
                                                    {UserData.MenuAccessFlag != 6 && (
                                                        <td className='col-md-1 pe-5'>
                                                            {GridTabs.BatchesTabs == 'active' && item.type == null && (
                                                                <FontAwesomeIcon icon={faPaperPlane} className="pe-2 cursor-pointer" title='Send Invoice' onClick={() => { setItemID(item.id); setFlagForItem(1); setDeliverBatchModal(true); setPaymentLink(''); setsendInvoiceDoc(''); setlinkFileName('') }} />
                                                            )}

                                                            {item?.invoice[0]?.payment_status != 3 && (
                                                                <img src="/images/DeleteIcon.svg" title="Delete Batch" style={{ height: "18px" }} className="img-fluid cursor-pointer px-1"
                                                                    onClick={(e) => { setDeletePopup(true); setAdminInvoiceParams({ ...AdminInvoiceParams, BatchId: item.id }) }} />
                                                            )}
                                                            <img src="/images/printlabelicon.svg" title="Show Packing List" className="img-fluid cursor-pointer ps-1" onClick={(e) => PrintPackingList(item.id)} style={{ height: 18 }} />
                                                            {item?.invoice[0]?.invoice_pdf != null ?
                                                                <img src="/images/previewpdficon.svg" className="px-1 cursor-pointer" style={{ height: "18px" }} title="Preview Invoice" onClick={(e) => window.open(BucketS3Link + item?.invoice[0]?.invoice_pdf, '_blank')} />
                                                                : <>
                                                                </>
                                                            }

                                                            {item?.fedex_qr ?
                                                                <img src="/images/eye.png" className="cursor-pointer ps-1 pe-5" title="Preview Shipping Label"
                                                                    onClick={(e) => ExtraDocument(item?.fedex_qr)} />
                                                                :
                                                                <></>
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                            })
                                            :
                                            <tr><td colSpan={15} className="p-3 text-center"> No Record Found </td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {showPagination && (
                                <Pagination
                                    activePage={currentPage}
                                    totalItemsCount={pageRangeCount}
                                    pageRangeDisplayed={15}
                                    itemsCountPerPage={LimitForPagination}
                                    onChange={(e) => setCurrentPage(e)}
                                    color="primary"
                                    lastPageText=""
                                    firstPageText=""
                                    itemClassFirst="MasterClass"
                                    itemClassPrev="MasterClass"
                                    itemClassNext="MasterClass"
                                    itemClassLast="MasterClass"
                                />
                            )}
                        </>
                        :
                        <div className='text-center'>
                            <h1 className="PageHeading text-center mt-1" style={{ fontSize: "30px" }}>Please select school from the search box above to get going!</h1>
                            <img src="/images/SchoolTicketVector.svg" className="img-fluid"></img>
                        </div>
                    }
                </div>
            </div>

            <Popup isshow={AdminInvoiceParams.sendreceipt} size={"md"} title={"Payment Details"}
                closePopup={(e) => setAdminInvoiceParams({ ...AdminInvoiceParams, sendreceipt: false, Notes: '' })}
                modalBody={sendreceiptBody} handleSave={SubmitInvoicePaymentCheck} visibleSaveBtn={true} btnText={"Submit"} />

            <Deletepopup text={`You won't be able to revert this!`} deletePopup={deletePopup} size="xl" deletePart={DeleteBatch} DeleteShowClose={(e) => setDeletePopup(false)} />

            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} tabFlag={32} gridname={'Billing'} GetCall={() => ApiGetHeaders(32, setColumnNames)} />
            )}

            <Popup isshow={DeliverBatchModal} size={"md"} title={"Send Invoice & Deliver Batch"}
                closePopup={(e) => { setSelectedIdsAll([]); setPaymentLink(''); setsendInvoiceDoc(''); setlinkFileName(''); setDeliverBatchModal(false); }}
                modalBody={delievermodal} handleSave={DeliverBatchCall} visibleSaveBtn={true} btnText={"Send"} />

            {signatureModal == true && (
                <Popup isshow={signatureModal} size={"md"} title={"Signature"}
                    closePopup={(e) => setSignatureModal(false)}
                    modalBody={signaturemodalbody} handleSave={SaveSignatureCall} visibleSaveBtn={true} btnText={"Save"} />
            )}
        </div>
    )
}
