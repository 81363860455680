import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import AlertsComp from "../../Components/AlertsComp";

export function TicketBySchool(props) {
  const [Alerts, setAlerts] = useState('');
  var vData = props.schooldata;
  var series = [{
    data: vData.map(item => item.Tickets)
  }];
  var labels = vData.map(item => item.School);
  var chartHeight = Math.max(300, vData.length * 20);
  var options = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, { seriesIndex, dataPointIndex }) {
        var totalTickets = vData[dataPointIndex].Amount;
        return val + " - $" + totalTickets;
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        show: true,
        style: {
          fontSize: '12px',
        }
      }
    },
    colors: ['#3CBBA5'],
    tooltip: {
      y: {
        formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
          var totalAmount = vData[dataPointIndex].Amount;
          return "Total Tickets : " + val + "<br/>Amount: $" + totalAmount;
        },
        title: {
          formatter: function (seriesName, val) {
            return "";
          }
        }
      }

    },
    chart: {
      toolbar: {
        show: false,
      },
    }
  };
  const DownloadReport = () => {
    const csvContent = [];
    const gradeMap = new Map();


    // Add headers once
    csvContent.push(['School Name', 'Signup Date', 'Total Tickets Created (Selected Period)', 'Amount']);

    vData?.forEach((ticket, i) => {
      const grade = ticket.School || 'Unknown';
      if (!gradeMap.has(grade)) {
        gradeMap.set(grade, []);
      }
      const rowData = {
        'School Name': ticket?.School,
        'Account Creation Date': ticket.AccountCreatedAt,
        'Total Tickets Created (Selected Period)': ticket?.Tickets,
        'Amount': '$' + ticket?.Amount
      };
      gradeMap.get(grade).push(rowData);
    });

    gradeMap.forEach((gradeData) => {
      gradeData.forEach((rowData) => {
        const values = Object.values(rowData).map(value => {
          if (typeof value == 'string' && value.includes(',')) {
            return `"${value}"`;
          }
          return value;
        });
        csvContent.push(values);
      });
    });

    if (csvContent.length > 1) { // Check if there is data (excluding headers)
      const csvString = csvContent.map(row => row.join(',')).join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'TicketsCreated.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setAlerts(<AlertsComp show={true} variant="danger" msg={'No data available for download.'} />);
      setTimeout(function () {
        setAlerts(<AlertsComp show={false} />)
      }, 2500);
    }
  };

  return (
    <>
      {Alerts}
      <div className="col-12 text-center  greyBoxShadow" style={{ height: "100%", borderRadius: "10px", backgroundColor: "white" }}>
        <div className="w-100">
          <h4>Tickets Distribution by School</h4>
          <u className="cursor-pointer" onClick={(e) => DownloadReport()}>Download Report</u>
          <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
        </div>
      </div >
    </>

  );
}
