import { useEffect } from 'react';
import googleFonts from 'google-fonts';

const GlobalStyles = () => {
    useEffect(() => {
        googleFonts.add({
            Poppins: ['400', '600', '700'],
        });
    }, []);

    return null; // This component doesn't render anything
};

export default GlobalStyles;
