import React, { useContext, useEffect, useState } from 'react'
import { LogInSchoolID } from '../../JS/Common';
import { ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import { UserContext } from '../../App';

export default function SignatureDetails({ setLoading, setNewAlerts }) {
    const { UserData, setUserData } = useContext(UserContext);
    const [signatureFlag1, setSignatureFlag1] = useState(UserData.signatureFlag)
    useEffect(() => {
        setSignatureFlag1(UserData.signatureFlag)
    }, [UserData.signatureFlag])
    async function SignatureCall() {
        setLoading(true);
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            Flag: signatureFlag1
        })
        await ApiPostCall("/SetSignatureSetting", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Signature Updated Successfully"} />);
                    setTimeout(() => {
                        setUserData((prevUserData) => ({ ...prevUserData, signatureFlag: signatureFlag1 }));
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }
    return (
        <>
            <div className="mt-2 greyBox h-100 p-3 align-items-center">
                <div className='row d-flex align-items-center p-1'>
                    <span className='GridTitle pb-2'>Signature</span>
                </div>
                <hr className='my-0' />
                <div className="p-1 pe-0 mt-1 pt-3">
                    <label>Do you want to include a signature when receiving a batch?</label>
                    <div className='col-md-12 mt-3 d-flex align-items-center'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name='sign'
                                onChange={(e) => setSignatureFlag1(1)}
                                defaultChecked={UserData.signatureFlag == 1}
                            />
                            <label className="form-check-label">On</label>
                        </div>
                        <div className="form-check ms-5">
                            <input
                                className="form-check-input"
                                type="radio"
                                name='sign'
                                onChange={(e) => setSignatureFlag1(0)}
                                defaultChecked={UserData.signatureFlag != 1}
                            />
                            <label className="form-check-label">Off</label>
                        </div>
                        <div className='px-5 text-center'>
                            <button className="SaveBtn" onClick={SignatureCall}> Confirm   </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
