import React from 'react'
import AlertPopup from './AlertPopup';
function Deletepopup(props) {
    return (
        <AlertPopup isshow={props.deletePopup} img={'/images/redInfo.svg'}
            headingtext={'Are you sure?'} text={props.text} cancelcall={props.DeleteShowClose}
            canceltext={"Cancel"} successtext={"Yes, Delete It!"} successcall={(e) => props.deletePart(e)}
            btntype={'deleteBtn'} />
    )
}

export default Deletepopup;