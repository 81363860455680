import React, { useContext, useEffect, useState } from 'react'
import { CheckValidation } from '../../Components/Validations';
import { LogInSchoolID } from '../../JS/Common';
import { ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import { UserContext } from '../../App';

export default function PaymentLink({ setLoading, setNewAlerts }) {
    const { UserData, setUserData } = useContext(UserContext);
    const [PaymentLinkVal, setPaymentLinkVal] = useState('');
    const [PaymentLinkFlag, setPaymentLinkFlag] = useState(UserData.PaymentLinkFlag)
    useEffect(() => {
        setPaymentLinkVal(UserData.PaymentLink)
        setPaymentLinkFlag(UserData.PaymentLinkFlag)
    }, [UserData.PaymentLink, UserData.PaymentLinkFlag])
    async function SavePaymentLink() {
        if (PaymentLinkFlag == 1) {
            var isFormValid = CheckValidation({ formID: 'PaymentLinkDiv' });
            if (!isFormValid) return;
        }
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            PaymentLink: PaymentLinkFlag == 1 ? PaymentLinkVal : null,
            Flag: PaymentLinkFlag
        });
        await ApiPostCall("/SaveExternalPaymentLink", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Payment Link Added Successfully."} />);
                    setTimeout(() => {
                        setUserData((prevUserData) => ({ ...prevUserData, PaymentLink: PaymentLinkVal, PaymentLinkFlag: PaymentLinkFlag }));
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const handlePaymentLinkVal = (e) => {
        let inputValue = e.target.value;
        if (!inputValue.startsWith("https://") && !inputValue.startsWith("http://")) {
            inputValue = "https://" + inputValue;
        }
        setPaymentLinkVal(inputValue);
    }
    return (
        <>
            <div className="mt-2 greyBox h-100 p-3 align-items-center">
                <div className='row d-flex align-items-center p-1'>
                    <span className='GridTitle pb-2'>External Payment Link</span>
                </div>
                <hr className='my-0' />
                <div className="p-1 pe-0 mt-1 pt-3">
                    <label>Do you want to add payment link for parent invoice?</label>
                    <div className='col-md-12 mt-3 d-flex align-items-center'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name='Paymentlink'
                                onChange={(e) => { setPaymentLinkFlag(1); setPaymentLinkVal('') }}
                                defaultChecked={PaymentLinkFlag == 1}
                            />
                            <label className="form-check-label">On</label>
                        </div>
                        <div className="form-check ms-5">
                            <input
                                className="form-check-input"
                                type="radio"
                                name='Paymentlink'
                                onChange={(e) => setPaymentLinkFlag(0)}
                                defaultChecked={PaymentLinkFlag != 1}
                            />
                            <label className="form-check-label">Off</label>
                        </div>
                    </div>
                    {PaymentLinkFlag == 1 && (
                        <div className='col-12 pb-2 pt-3 parent' id="PaymentLinkDiv">
                            <label className='pb-1'>Enter External Payment Link</label>
                            <div className="input-group ">
                                <span className="input-group-text">https://</span>
                                <input type="text" autoComplete='off' className="form-control px-3" name='paymentlink' required
                                    value={PaymentLinkVal} onChange={handlePaymentLinkVal} />
                                <span className="form-text invalid-feedback">
                                    *required
                                </span>
                            </div>


                        </div>
                    )}
                    <div className='pt-2'>
                        <button className="SaveBtn" onClick={SavePaymentLink}> Confirm   </button>
                    </div>
                </div>
            </div>
        </>

    )
}
