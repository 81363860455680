import React from 'react'
import { Fragment } from 'react'
import { Camelcase, LogInSchoolID, LogInUserID, MMDDYYYY, typewatch } from '../../JS/Common';
import { useState } from 'react';
import { useEffect } from 'react';
import { ApiGetCall, ApiGetCallAuthKey, ApiGetHeaders, ApiPostCall } from '../../JS/Connector';
import Pagination from 'react-js-pagination';
import { CheckValidation } from '../../Components/Validations';
import AlertsComp from '../../Components/AlertsComp';
import CustomizationPopup from '../../Components/CustomizationPopup';
import PageHead from '../../Components/PageHead';
import Popup from '../../Components/Popup';

function Building() {
    const [BuildingData, setBuildingData] = useState([]);
    const [allData, setAllData] = useState({
        flag: 3,
        BuildingName: '',
        BuildingID: '',
        SortbyFlag: 'asc',
        searchstring: ''
    })
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [isBuildingPopup, setisBuildingPopup] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const showPagination = BuildingData.length > 0;

    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [SortbyKey, setSortbyKey] = useState(null);
    const [addRoomsFlag, setaddRoomsFlag] = useState(false)
    const [fields, setFields] = useState([
        { id: 1, value: '', flag: 1 },
    ]);
    const [SubEntryError, setSubEntryError] = useState('')

    useEffect(() => {
        ApiGetHeaders(2, setColumnNames)
    }, []);
    useEffect(() => {
        GetAllBuildings(allData.searchstring || null, SortbyKey || null);
    }, [currentPage, LimitForPagination]);
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "Building": item.name || '-',
            "created_at": MMDDYYYY(item.created_at) || '-',
        };
        return contentMap[columnName] || null;
    }
    async function GetAllBuildings(searchstring, sortkey) {
        if (searchstring == "" || searchstring == null) {
            searchstring = null;
        }
        if (sortkey != null) {
            if (allData.SortbyFlag == "asc") {
                allData.SortbyFlag = "desc";
            } else {
                allData.SortbyFlag = "asc";
            }
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCallAuthKey("/allBuildings/" + LogInSchoolID + '&' + searchstring + '&' + sortkey + '&' + allData.SortbyFlag + '&' + currentPage + '&' + LimitForPagination + '&' + LogInUserID).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    setpageRangeCount(responseRs.msg.total)
                    if (responseRs.msg.data.length != 0) {
                        setBuildingData(responseRs.msg.data);
                    }
                    else {
                        setBuildingData([]);
                    }
                }
            });
        }, 500);
    }
    async function EditBuildingGetData(id) {
        setLoading(true)
        setisBuildingPopup(true)
        await ApiGetCall("/getBuildingDataById/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                setLoading(false);
                setAllData({ ...allData, flag: 2, BuildingName: responseRs.msg.name, BuildingID: responseRs.msg.id });
                if (responseRs?.msg?.room.length != 0) {
                    var rooms = responseRs?.msg?.room?.map((item) => ({
                        id: item.id,
                        value: item.name,
                        flag: 2 // Flag to indicate existing room
                    }));
                    setFields(rooms);
                    setaddRoomsFlag(true)
                } else {
                    setaddRoomsFlag(false)
                }
            }
        });
    }
    async function AddUpdateBuilding(flag) {
        var updateArray = [];
        {
            fields.map((item, i) => {
                if (item.value != '') {
                    var vjson = {};
                    vjson['id'] = item.flag == 1 ? 0 : item.id;
                    vjson['room'] = item.value;
                    updateArray.push(vjson)
                }
            })
        }
        if (updateArray.filter(item => item?.name?.trim() == "").length != 0) {
            setSubEntryError('* All fields are required');
            return;
        } else {
            setSubEntryError('');
        }
        var isFormValid = CheckValidation({ formID: 'BuildingPopup' });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            school_id: LogInSchoolID,
            name: allData.BuildingName,
            building_id: allData.BuildingID ? allData.BuildingID : 0,
            buildingaddupdateflag: flag,
            roomArray: updateArray
        });
        await ApiPostCall("/addUpdateBuildings", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    if (allData.flag == 1) {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Building Added Successfully."} />);
                    } else {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Building Updated Successfully."} />);
                    }
                    setisBuildingPopup(false);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        GetAllBuildings(null, null);
                        setFields([{ id: 1, value: '', flag: 1 }]); setaddRoomsFlag(false); setSubEntryError('')
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }

    const renderFields = () => {
        let seriesId = 1;
        return fields.map((field) => {
            if (field.flag == 3) {
                return null;
            }
            const seriesLabel = `Room ${seriesId++}`;
            return (
                <div key={field.id} className='d-flex py-1 col-md-12 align-items-center'>
                    <div className='col-md-12'>
                        <input className="form-control" type="text" flag={field.flag}
                            id={`field-${field.id}`} name={`field-${field.id}`} value={field.value}
                            onChange={(event) => handleChange(event, field.id)} placeholder={`Enter ${seriesLabel}`} />
                    </div>
                    <br />
                </div>
            );
        });
    };
    function handleAddField() {
        // Check if any existing field is empty
        const hasEmptyField = fields.some(field => field.value.trim() == '');
        if (hasEmptyField) {
            setSubEntryError('Please fill all previous fields first')
            return;
        } else {
            setSubEntryError('')
        }
        // Add a new field if all previous fields are filled
        setFields([...fields, { id: fields.length + 1, value: '', flag: 1 }]);
    };
    function handleChange(event, fieldId) {
        const updatedFields = [...fields];
        const index = updatedFields.findIndex((field) => field.id === fieldId);
        updatedFields[index].value = event.target.value;
        setFields(updatedFields);
    };
    function handleSearchChange(e) {
        setAllData({ ...allData, searchstring: e.target.value })
        setCurrentPage(1);
        GetAllBuildings(e.target.value, null);
    }
    const modalBody = (
        <div id="BuildingPopup" className="parent">
            <div className='col-12'>
                <div className='FormLabel'>Building Name<label className='redText'>*</label></div>
                <input className="form-control" autoComplete='off' name='building' required value={allData.BuildingName}
                    onChange={(e) => setAllData({ ...allData, BuildingName: e.target.value })} />
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            {addRoomsFlag == false && (
                <div className="mt-3 text-center">
                    <label className='BorderBtn text-center marginDetailsPopup me-0' title='Add Rooms' onClick={(e) => { setaddRoomsFlag(true) }}> Add Rooms
                        <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                    </label>
                </div>
            )}
            {addRoomsFlag == true && (
                <div className='mt-3'>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <label className='FormLabel'>Building Rooms</label>
                        {renderFields()}
                    </form>
                    <div className="col-md-12 offset-md-0 Emailinvalid my-1" id='statusMsg'>{SubEntryError}</div>
                    <div className='row col-md-12'>
                        <div className='col-md-12 offset-md-0 pt-1'>
                            <label className='BorderBtn text-center' onClick={handleAddField}>
                                <img src='/images/AddInventory.svg' className='img-fluid pe-2' style={{ height: '12px' }} /> Add
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
    return (
        <Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Buildings"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="GridBox mt-2 px-3 pb-3">
                <div className='row text-end'>
                    <div className="col-md-9"></div>
                    <div className="col-md-3 my-2">
                        <label className='BorderBtn text-center marginDetailsPopup me-0' title='Add Building'
                            onClick={(e) => {
                                setAllData({ ...allData, flag: 1, BuildingID: '', BuildingName: "" });
                                setisBuildingPopup(true); setaddRoomsFlag(false); setFields([{ id: 1, value: '', flag: 1 }]);
                            }}> Add Building
                            <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                </div>
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                {columnNames?.map((item) => {
                                    const columnName = item.replace(/_/g, ' ');
                                    return (
                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => { GetAllBuildings(allData.searchstring, item == 'Building' ? 'name' : item); setSortbyKey(item) }}>
                                            {Camelcase(columnName)}
                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                        </th>
                                    );
                                })}
                                <th scope="col" className=' fw-600 text-center'>Action
                                    <img src="/images/EditHeaderIcon.svg" className="img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {BuildingData.length != 0 ?
                                BuildingData.map((item, i) => {
                                    return <tr key={i} className="OddEvenClass tableBorderHide">
                                        {columnNames?.map(header => (
                                            <td className="py-12" key={header}>
                                                {renderContentForColumn(header, item)}
                                            </td>
                                        ))}
                                        <td scope="col" className='text-center py-12'>
                                            <img src='/images/EditIconNew.svg' title='Edit Building' className='img-fluid pe-2 cursor-pointer'
                                                onClick={(e) => { setAllData({ ...allData, flag: 2 }); EditBuildingGetData(item.id) }} buildingid={item.id} />
                                        </td>
                                    </tr>
                                })
                                :
                                <tr className="text-center">
                                    <td colSpan={15}>No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div>

            <Popup isshow={isBuildingPopup} size={"md"} title={allData.flag == 1 ? 'Add Building' : 'Edit Building'}
                closePopup={(e) => { setAllData({ ...allData, flag: 3, BuildingID: "", BuildingName: "" }); setisBuildingPopup(false) }}
                modalBody={modalBody} handleSave={(e) => allData.flag == 1 ? AddUpdateBuilding(1) : AddUpdateBuilding(2)} visibleSaveBtn={true}
                btnText={allData.flag == 1 ? 'Save' : 'Update'} />

            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Buildings'} modalSize={'l'} GetCall={(e) => ApiGetHeaders(2, setColumnNames)} />
            )}
        </Fragment>
    )
}

export default Building
