import React, { useEffect, useState, Fragment } from "react";
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import { LogInSchoolID, typewatch, Camelcase } from "../../JS/Common";
import Pagination from "react-js-pagination";
import Deletepopup from "../../Components/Deletepopup";
import AlertsComp from "../../Components/AlertsComp";
import { CheckValidation } from "../../Components/Validations";
import CustomizationPopup from "../../Components/CustomizationPopup";
import PageHead from "../../Components/PageHead";
import Tab from "../../Components/Tab";
import Popup from "../../Components/Popup";

export function ManageParts() {
  const [partsTabs, setPartsTabs] = useState({
    SchoolPartsTab: "active",
    MasterPartsTab: "",
  });
  const [SearchForSchool, setSearchForSchool] = useState("");
  const [SearchForMaster, setSearchForMaster] = useState("");
  const [tabflag, settabflag] = useState(1);
  const [GridData, setGridData] = useState([]);
  const [addpartsPopup, setAddpartsPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [formData, setFormdata] = useState({
    partName: "",
    brandName: "",
    variantPrice: "",
    Quantity: "",
    reorderQuantity: "",
    Handel: "",
  });
  const [FlagForAddUpdateDelete, setFlagForAddUpdateDelete] = useState({
    Flag: "",
    IdForDelete: "",
  });
  const [NewAlerts, setNewAlerts] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [LimitForPagination, setLimitForPagination] = useState(25);
  const [pageRangeCount, setpageRangeCount] = useState(0);
  const showPagination = GridData.length > 0;
  const [CustomizationModal, setCustomizationModal] = useState(false)
  const [columnNames, setColumnNames] = useState([]);

  useEffect(() => {
    ApiGetHeaders(13, setColumnNames)
  }, []);

  useEffect(() => {
    if (partsTabs.MasterPartsTab == "active") {
      GetPartData(2, SearchForMaster ? SearchForMaster : null);
    } else {
      GetPartData(1, SearchForSchool ? SearchForSchool : null);
    }
  }, [currentPage, LimitForPagination]);

  async function GetPartData(flag, SearchString) {
    if (partsTabs.MasterPartsTab == "active") {
      setFormdata((prevState) => ({ ...prevState, Quantity: "" }));
    }
    if (!SearchString) SearchString = null;
    var joinedString = null;

    if (columnNames.length > 0) {
      var joinedString = columnNames.join('~');
    }
    typewatch(async function () {
      setLoading(true);
      await ApiGetCall("/getPartsList/" + LogInSchoolID + "&" + SearchString + "&" + flag + '&' + currentPage + '&' + LimitForPagination + "?data=" + joinedString).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          setLoading(false);
          const responseRs = JSON.parse(result);
          setpageRangeCount(responseRs.msg.total)
          setGridData(responseRs.msg.data.length ? responseRs.msg.data : [])
        }
      });
    }, 500);
  }
  async function SavePart() {
    if (FlagForAddUpdateDelete.Flag != 3) {
      var isFormValid = CheckValidation({ formID: "AddPartDiv" });
      if (!isFormValid) return;
    }
    setLoading(true);
    var raw = JSON.stringify({
      SchoolId: LogInSchoolID,
      Title: formData.partName,
      BrandName: formData.brandName ? formData.brandName : null,
      Price: formData.variantPrice,
      Quantity: formData.Quantity,
      ReminderQuantity: formData.reorderQuantity,
      ID: FlagForAddUpdateDelete.IdForDelete,
      Handel: formData.Handel,
      Flag: FlagForAddUpdateDelete.Flag,
    });
    await ApiPostCall("/addUpdateDeleteParts", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        setLoading(false);
        const responseRs = JSON.parse(result);
        if (responseRs.status == "success") {
          if (FlagForAddUpdateDelete.Flag == 1) {
            var alertmsg = "Part Added Successfully";
          } else if (FlagForAddUpdateDelete.Flag == 2) {
            var alertmsg = "Part Updated Successfully";
          } else if (FlagForAddUpdateDelete.Flag == 3) {
            var alertmsg = "Part Delete Succesfully";
          } else if (FlagForAddUpdateDelete.Flag == 4) {
            var alertmsg = "Inventory Added Successfully";
          }
          setNewAlerts(
            <AlertsComp show={true} variant="success" msg={alertmsg} />
          );
          setTimeout(() => {
            setNewAlerts(<AlertsComp show={false} />);
            setAddpartsPopup(false);
            setDeletePopup(false);
            setFormdata({ ...formData, Quantity: '', reorderQuantity: '', brandName: '' })
            if (FlagForAddUpdateDelete.Flag == 4) {
              setCurrentPage(1)
              setPartsTabs({ SchoolPartsTab: "active", MasterPartsTab: "" });
              GetPartData(1, null);
            } else {
              if (partsTabs.MasterPartsTab == "active") {
                GetPartData(2, null);
              } else {
                GetPartData(1, null);
              }
            }
          }, 2000);
        } else {
          setNewAlerts(
            <AlertsComp show={true} variant="danger" msg={result} />
          );
          setTimeout(() => {
            setNewAlerts(<AlertsComp show={false} />);
          }, 2000);
        }
      }
    });
  }
  async function GetDataById(id) {
    setLoading(true);
    await ApiGetCall("/getPartsById/" + id).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        setLoading(false);
        setFormdata({
          ...formData,
          partName: responseRs.title,
          brandName: responseRs.brand_name,
          variantPrice: responseRs.variant_price,
          Quantity: responseRs.quantity,
          reorderQuantity: responseRs.reminder_quantity,
          Handel: responseRs.handle,
        });
      }
    });
  }
  function renderContentForColumn(columnName, item) {
    const contentMap = {
      "Title": item.title,
      "Brand_Name": item.brand_name || '-',
      "Variant_Price": `$${item?.variant_price?.toFixed(2)}` || '0',
      "Quantity": item.quantity || '0',
      "Reminder_Quantity": item.reminder_quantity || '0',
      "Handle": item.handle || '0',
    };
    return contentMap[columnName] || null;
  }
  function handleSearchChange(e) {
    tabflag == 1 ? setSearchForSchool(e.target.value) : setSearchForMaster(e.target.value);
    setCurrentPage(1);
    GetPartData(tabflag == 1 ? 1 : 2, e.target.value);
  }
  function handleTabClick(tabType) {
    if (tabType == "school") {
      GetPartData(1, null);
      setPartsTabs({ ...partsTabs, SchoolPartsTab: "active", MasterPartsTab: "" });
      setCurrentPage(1);
      settabflag(1);
      setSearchForMaster("");
      setSearchForSchool("");
    } else if (tabType == "master") {
      GetPartData(2, null);
      setPartsTabs({ ...partsTabs, SchoolPartsTab: "", MasterPartsTab: "active", });
      setCurrentPage(1);
      settabflag(2);
      setSearchForMaster("");
      setSearchForSchool("");
    }
  }
  const modalBody = (
    <div id="AddPartDiv" className="parent">
      <div className="">
        <div className='FormLabel'>Part Name<label className='redText'>*</label></div>
        <div className="ps-0">
          <input type="text" autoComplete="off" name="partName" className="form-control partNamee" required
            value={formData.partName} onChange={(e) => setFormdata({ ...formData, partName: e.target.value })}
            disabled={FlagForAddUpdateDelete.Flag == 4} />
          <span className="form-text invalid-feedback" > *required </span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 py-3 ">
          <div className='FormLabel'>Price<label className='redText'>*</label></div>
          <div className=" pe-0">
            <div className="input-group">
              <span className="input-group-text">$</span>
              <input type="number" autoComplete="off" className="form-control" name="variantPrice" min={0}
                placeholder="00" value={formData.variantPrice} required onChange={(e) => setFormdata({ ...formData, variantPrice: e.target.value, })}
                disabled={FlagForAddUpdateDelete.Flag == 4} />
              <span className="form-text invalid-feedback">*required </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 py-3">
          <div className='FormLabel'>Quantity</div>
          <div className="pe-0">
            <input type="number" autoComplete="off" name="Quantity" className="form-control" placeholder="0" min={0} value={formData.Quantity} onChange={(e) => setFormdata({ ...formData, Quantity: e.target.value })} />
          </div>
        </div>
        {partsTabs.SchoolPartsTab === "active" && (
          <div className="col-md-6 py-3 " >
            <div className='FormLabel'>Reorder Quantity</div>
            <div className=" pe-0">
              <input type="number" autoComplete="off" name="Quantity" className="form-control" placeholder="0" min={0}
                value={formData.reorderQuantity} onChange={(e) => setFormdata({ ...formData, reorderQuantity: e.target.value, })} />
            </div>
          </div>
        )}
        {partsTabs.SchoolPartsTab === "active" && (
          <div className="col-md-6 py-3  ">
            <div className='FormLabel'>Brand Name</div>
            <div className=" pe-0">
              <input type="text" autoComplete="off" name="brandName" className="form-control" value={formData.brandName}
                onChange={(e) => setFormdata({ ...formData, brandName: e.target.value })} disabled={FlagForAddUpdateDelete.Flag == 4} />
            </div>
          </div>
        )}
      </div>
      <div className="row mt-3"></div>
    </div>
  )
  return (
    <Fragment>
      <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
        setCurrentPage={setCurrentPage} heading={"Manage Parts"} handleSearch={handleSearchChange}
        loading={loading} Alerts={NewAlerts} showsearch={true} />
      <div className="GridBox mt-2 p-3">
        <div className="col-12 ">
          <div className="row align-items-center">
            <div className="col-xl-9 col-md-9 px-0">
              <ul className="nav nav-tabs px-3">
                <Tab isActive={partsTabs.SchoolPartsTab == 'active'}
                  label="School Inventory" onClick={() => handleTabClick('school')} col={'col-md-3'} />
                <Tab isActive={partsTabs.MasterPartsTab == 'active'}
                  label="Master Inventory" onClick={() => handleTabClick('master')} col={'col-md-3'} />
              </ul>
            </div>
            <div className="col-xl-3 col-md-3 col-12 text-end">
              {partsTabs.SchoolPartsTab == "active" && (
                <label className="BorderBtn text-center"
                  onClick={(e) => {
                    setAddpartsPopup(true); setFlagForAddUpdateDelete({ ...FlagForAddUpdateDelete, Flag: 1 });
                    setFormdata((prevState) => ({ ...prevState, Quantity: "", partName: "", variantPrice: "", reorderQuantity: "", brandName: "", }));
                  }}> Add Inventory  <img src="/images/AddInventory.svg" className="img-fluid ps-2" /> </label>
              )}
            </div>
          </div>

          <div className="col-12 greyBox mt-3 data-table-container">
            <table className="table data-table mb-0">
              <thead className="GridHeader">
                <tr>
                  {columnNames?.map((item) => {
                    const columnName = item.replace(/_/g, ' ');
                    return (
                      <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`}>
                        {Camelcase(columnName)}
                      </th>
                    );
                  })}
                  {partsTabs.SchoolPartsTab == "active" ? (
                    <>
                      <th scope="col" className=" fw-600"> Action </th>
                      <th scope="col" className=" fw-600">Reorder <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                    </>
                  ) : (
                    <>
                      <th scope="col" className="fw-600">Add to School Inventory</th>
                      <th scope="col" className=" fw-600">  Order <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /> </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {GridData.length != 0 ? (
                  GridData.map((item, i) => {
                    return (
                      <tr key={i} className={`OddEvenClass tableBorderHide `} style={{ height: "30px" }}>
                        {columnNames?.map(header => (
                          <td className="py-12" key={header}> {renderContentForColumn(header, item)} </td>
                        ))}
                        {partsTabs.SchoolPartsTab == "active" ? (
                          <>
                            <td className="py-12">
                              <img src="/images/EditIconNew.svg" title="Edit Inventory" className="img-fluid pe-2 cursor-pointer partsgetID" partid={item.id}
                                onClick={(e) => {
                                  setAddpartsPopup(true); setFlagForAddUpdateDelete({ ...FlagForAddUpdateDelete, Flag: 2, IdForDelete: item.id });
                                  GetDataById(item.id);
                                }} />
                              <img src="/images/DeleteIcon.svg" title="Delete Inventory" className="img-fluid cursor-pointer" partid={item.id}
                                onClick={(e) => { setDeletePopup(true); setFlagForAddUpdateDelete({ ...FlagForAddUpdateDelete, Flag: 3, IdForDelete: item.id }); }} />
                            </td>
                            {item.Stockflag == 1 ? (
                              <td className="py-12">
                                <img src="/images/Reorder.svg" className="img-fluid cursor-pointer" onClick={(e) => window.open(item.handle)} partid={item.id} />
                              </td>
                            ) : (
                              <td className="py-12">-</td>
                            )}
                          </>
                        ) : (
                          <>
                            <td className="py-12">
                              <img src="/images/PurchaseParts.svg" className="img-fluid cursor-pointer" title="Add to School Inventory"
                                onClick={(e) => {
                                  setPartsTabs((prevState) => ({ ...prevState, Quantity: "", }));
                                  setAddpartsPopup(true);
                                  setFlagForAddUpdateDelete({ ...FlagForAddUpdateDelete, Flag: 4, IdForDelete: item.id });
                                  GetDataById(item.id);
                                }}
                                partid={item.id}
                              />
                            </td>
                            <td className="py-12">
                              <img src="/images/orderButton.svg" className="img-fluid cursor-pointer" partid={item.id} onClick={(e) => window.open(item.handle)} />
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr> <td colSpan={18} className="p-3 text-center"> No Record Found </td> </tr>
                )}
              </tbody>
            </table>
          </div>
          {showPagination && (
            <Pagination activePage={currentPage} totalItemsCount={pageRangeCount}
              pageRangeDisplayed={15} itemsCountPerPage={LimitForPagination} onChange={(e) => setCurrentPage(e)}
              color="primary" lastPageText="" firstPageText="" itemClassFirst="MasterClass" itemClassPrev="MasterClass"
              itemClassNext="MasterClass" itemClassLast="MasterClass" />
          )}
        </div>
      </div>

      <Popup isshow={addpartsPopup} size={"md"} title={FlagForAddUpdateDelete.Flag == 1 ? 'Add Inventory' : FlagForAddUpdateDelete.Flag == 2 ? 'Edit Inventory' : 'Add to School Inventory'}
        closePopup={(e) => { setAddpartsPopup(false); setFormdata([]); }}
        modalBody={modalBody} handleSave={SavePart} visibleSaveBtn={true} btnText={
          FlagForAddUpdateDelete.Flag == 1 ? 'Add Inventory'
            : FlagForAddUpdateDelete.Flag == 2 ? 'Update Inventory'
              : 'Save Inventory'
        } />

      <Deletepopup text={`You won't be able to revert this!`} deletePopup={deletePopup} size="xl" deletePart={SavePart} DeleteShowClose={(e) => setDeletePopup(false)} />

      {CustomizationModal == true && (
        <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Manage Parts'} GetCall={() => ApiGetHeaders(13, setColumnNames)} />
      )}
    </Fragment>

  );
}
