import React, { createContext, useEffect, useState } from 'react'
import { ApiGetCall } from '../../JS/Connector';
import { HideLoder, LogInSchoolID, ShowLoder, LogInUserID } from '../../JS/Common';
import ScanDevices from './ScanDevices';
import FinalAudit from './FinalAudit';
import BuildingRoom from './BuildingRoom';
import FinishAuditPopup from './FinishAuditPopup';
import { useNavigate } from 'react-router-dom';
import Terms_Conditions_Audit from './Terms_Conditions_Audit';
export const AuditContext = createContext();
function InventoryAudit() {
    const navigate = useNavigate();
    const [VisibilityDiv, setVisibilityDiv] = useState(1);
    const [BuildingData, setBuildingData] = useState({
        AllBuildings: [],
        BuildingId: 0,
        BuildingName: ''
    })
    const [RoomData, setRoomData] = useState({
        AllRooms: [],
        RoomId: 0,
        RoomName: '',
        ShowRoomDiv: 'd-none'
    })
    const [FinalScanned, setFinalScanned] = useState([])
    const [FinalUnScanned, setFinalUnScanned] = useState([])
    const [AllAuditDrafts, setAllAuditDrafts] = useState([]);
    const [Auditnum, setAuditnum] = useState('');
    const [PendingAuditModal, setPendingAuditModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showWelcomeAuditDiv, setShowWelcomeAuditDiv] = useState(2);
    useEffect(() => {
        getAllDrafts();
    }, [VisibilityDiv]);
    function getAllDrafts() {
        setLoading(true)
        ApiGetCall("/getAllAuditDrafts/" + LogInSchoolID + '&' + LogInUserID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false)
                setAllAuditDrafts(responseRs.msg);
                setShowWelcomeAuditDiv(responseRs.agreement_flag);
                setAuditnum(responseRs.audit_number);
            }
        });
    }

    return (
        <AuditContext.Provider value={{
            BuildingData, RoomData, setRoomData, setBuildingData, AllAuditDrafts, showWelcomeAuditDiv, setFinalUnScanned, FinalUnScanned,
            FinalScanned, setFinalScanned, setVisibilityDiv, Auditnum, setAuditnum, getAllDrafts, setShowWelcomeAuditDiv
        }}>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row align-items-center pe-0'>
                <div className='col-md-6'>  <h1 className="PageHeading mb-0">Inventory Audit</h1>  </div>
                <div className='col-md-6 text-end'>
                    {AllAuditDrafts?.length > 0 && (
                        <label className="font-14 pb-1 pe-3" style={{ color: 'black', cursor: 'pointer' }}
                            onClick={() => setPendingAuditModal(true)}>
                            <u>Pending Audits</u>
                        </label>
                    )}
                    {showWelcomeAuditDiv == 1 &&
                        <label className="BorderBtn p-2 me-0 c text-center" onClick={() => navigate('/previous-audits')}>
                            Previous Audits
                        </label>
                    }
                </div>
            </div>
            {showWelcomeAuditDiv == 0 ? (
                <Terms_Conditions_Audit />
            ) : showWelcomeAuditDiv == 1 ? (
                <div>
                    {VisibilityDiv == 1 && (<BuildingRoom />)}
                    {VisibilityDiv == 2 && (<ScanDevices />)}
                    {VisibilityDiv == 3 && (<FinalAudit />)}
                </div>
            ) : (
                <></>
            )}

            {PendingAuditModal == true && (
                <FinishAuditPopup PendingAuditModal={PendingAuditModal} closepopup={(e) => setPendingAuditModal(false)} />
            )}
        </AuditContext.Provider >
    )
}
export default InventoryAudit