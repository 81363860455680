import React, { useState, useContext, useCallback, useLayoutEffect } from "react";
import Chart from "react-apexcharts";
import { TableDataContext } from "../index";
import { LogInSchoolID, MMDDYYYY } from "../../../JS/Common";
import { ApiGetCall } from "../../../JS/Connector";
import _ from 'lodash';


export function TicketStatusGraph(props) {
    const { AllDashBoardData, FilterData } = useContext(TableDataContext);
    const actualData = AllDashBoardData?.TicketsByStatus?.data
    const filterData = AllDashBoardData?.FilterData
    const [data, setData] = useState(actualData);
    const [loader, setLoader] = useState(false);
    useLayoutEffect(() => {
        setData(AllDashBoardData?.TicketsByStatus?.data)
    }, [AllDashBoardData?.TicketsByStatus?.data, AllDashBoardData?.FilterData])
    const [searchTerm, setSearchTerm] = useState('');

    const delayedSearch = useCallback(_.debounce(async (text, val) => {
        if (text !== "") {
            try {
                setLoader(true)
                const result = await ApiGetCall("/TicketStatus/" + LogInSchoolID + "&" + MMDDYYYY(val?.sdate) + "&" + MMDDYYYY(val?.edate) + '&' + val?.gradeid + '&' + val?.buildingid + '&' + text.trim() + '&' + 0);
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setData(responseRs?.TicketsByStatus);
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                setLoader(false)
            }

        } else {
            setData(actualData)
        }
    }, 500), []);

    const handleChange = (e) => {
        const { value } = e.target;
        delayedSearch(value, filterData)
        setSearchTerm(value);
    };
    var categories = []
    var series = []
    if (searchTerm == '') {
        categories = actualData?.map((item) => item.status_name);
        series = actualData?.map((item) => item.count);
    } else {
        categories = data?.map((item) => item.status_name);
        series = data?.map((item) => item.count);
    }
    const options = {
        chart: {
            height: 350,
            type: "bar",
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top",
                },
            },
        },
        xaxis: {
            categories: categories,
            title: {
                text: "Tickets status",
            },
        },
        yaxis: {
            title: {
                text: "No. of tickets",
            },
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
        },
        dataLabels: {
            style: {
                colors: ["black"],
            },
        },
        chart: {
            toolbar: {
                show: false,
            },
        },
        colors: ["#3CBBA5"],
        tooltip: {
            y: {
                formatter: function (val) {
                    return "Total Tickets: " + val;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    },
                },
            },
        },
    };
    return (
        <div className="col-12 text-center  greyBoxShadow" style={{ height: "100%" }}>
            <div className="w-100">
                <div className="row my-2 px-2 time">
                    <div className='Header'>
                        <b className='font-15'>Tickets Status</b><br />
                    </div>
                </div>
                {AllDashBoardData?.TicketsByStatus?.loader ? <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div> :
                    <>
                        <div className="row">
                            <div className="col-4 m-auto">
                                <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                                    <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                                    <input className="form-control" autoComplete="off" type="text" placeholder="Search" id="SearchInvoiceId"
                                        value={searchTerm} onChange={handleChange} />
                                    {loader && <img src="/images/loder.svg" className="position-absolute top-50 translate-middle-y" style={{ width: "20px", right: "2%" }} />}
                                </form>
                            </div>
                        </div>
                        <Chart
                            options={options}
                            series={[{ data: series }]}
                            type="bar"
                            height={250}
                        />
                    </>
                }

            </div>
        </div>
    );
}
