import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../App';
import { LogInSchoolID } from '../../JS/Common';
import { ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import AlertPopup from '../../Components/AlertPopup';

export default function UncoveredRepairDetails({ setLoading, setNewAlerts }) {
    const { UserData, setUserData } = useContext(UserContext);
    const [Uncoveredcharge, setUncoveredcharge] = useState(UserData.repairflag)
    const [InNegotiationPopup, setInNegotiationPopup] = useState(false);
    useEffect(() => {
        setUncoveredcharge(UserData.repairflag)
    }, [UserData.repairflag])
    function UncoveredChange(flag) {
        if (flag != 1) {
            RepairChargeCall();
        } else {
            setInNegotiationPopup(true)
        }
    }
    async function RepairChargeCall() {
        setLoading(true);
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            RepairFlag: Uncoveredcharge
        })
        await ApiPostCall("/repairCostShowHide", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Repair Cost Updated Successfully"} />);
                    setInNegotiationPopup(false)
                    setTimeout(() => {
                        setUserData((prevUserData) => ({ ...prevUserData, repairflag: Uncoveredcharge }));
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }

    return (
        <>
            <div className="mt-2 greyBox h-100 p-3 align-items-center">
                <div className='row d-flex align-items-center p-1'>
                    <span className='GridTitle pb-2'>Uncovered Repair Cost</span>
                </div>
                <hr className='my-0' />
                <div className="p-1 pe-0 mt-1 pt-3">
                    <label>Please select the type of cost coverage for repairs that are not covered by the warranty.</label>
                    <div className='col-md-12 mt-3 d-flex align-items-center'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name='repairflag'
                                onChange={(e) => setUncoveredcharge(1)}
                                defaultChecked={UserData.repairflag == 1}
                            />
                            <label className="form-check-label">Flat Fee</label>
                        </div>
                        <div className="form-check ms-5">
                            <input
                                className="form-check-input"
                                type="radio"
                                name='repairflag'
                                onChange={(e) => setUncoveredcharge(0)}
                                defaultChecked={UserData.repairflag != 1}
                            />
                            <label className="form-check-label">Full Repair</label>
                        </div>
                        <div className='px-5 text-center'>
                            <button className="SaveBtn" onClick={(e) => UncoveredChange(Uncoveredcharge)}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>

            <AlertPopup isshow={InNegotiationPopup} img={'/images/redInfo.svg'}
                headingtext={`Are you sure?`} text={'Switching from "Full Repair" to "Flat Fee" with active parental coverage plans will cause plans to back up for negotiation to determine uncovered repair costs. Proceed?'}
                cancelcall={(e) => setInNegotiationPopup(false)}
                canceltext={"Cancel"} successtext={"Yes, Proceed!"} successcall={(e) => RepairChargeCall(e)} btntype={'deleteBtn'} />

        </>
    )
}
