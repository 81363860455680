import React, { useState, useContext } from "react";
import Chart from "react-apexcharts";
import { TableDataContext } from '../index';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import CustomProgress from "./CustomProgress";
import Popup from "../../../Components/Popup";
export default function BreakageRate(props) {
    const { AllDashBoardData } = useContext(TableDataContext);
    const [ModalShow, setModalShow] = useState(false)
    const Open = () => {
        setModalShow(true)
    }
    const categories = AllDashBoardData?.Breakageratebybuilding?.data?.categories;
    const series = AllDashBoardData?.Breakageratebybuilding?.data?.series?.map((value) => parseFloat(value)?.toFixed(2));
    const abbreviatedCategories = categories?.map(label => label?.length > 25 ? label?.substring(0, 25) + '...' : label);
    const options = {
        chart: {
            height: 350,
            type: "bar",
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top", // Place labels on top of the bars
                },
            },
        },
        xaxis: {
            categories: abbreviatedCategories,
            title: {
                text: "Buildings",
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return parseFloat(val)?.toFixed(2);
                },
            },
            title: {
                text: "Breakage Rate",
            },
        },
        dataLabels: {
            style: {
                colors: ["black"],
            },
        },
        chart: {
            toolbar: {
                show: false,
            },
        },
        colors: ["#3CBBA5"],
        tooltip: {
            y: {
                formatter: function (val) {
                    return "Breakage Rate " + val;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    },
                },
            },
        },
    };
    const series1 = AllDashBoardData?.Breakageratebygrade?.data?.series?.map((value) => parseFloat(value)?.toFixed(2));
    const options1 = {
        chart: {
            height: 350,
            type: "bar",
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top", // Place labels on top of the bars
                },
            },
        },
        xaxis: {
            categories: AllDashBoardData?.Breakageratebygrade?.data?.categories,
            title: {
                text: "Grades",
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return parseFloat(val)?.toFixed(2);
                },
            },
            title: {
                text: "Breakage Rate",
            },
        },
        dataLabels: {
            style: {
                colors: ["black"],
            },
        },
        chart: {
            toolbar: {
                show: false,
            },
        },
        colors: ["#3CBBA5"],
        tooltip: {
            y: {
                formatter: function (val) {
                    return "Breakage Rate " + val;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    },
                },
            },
        },
    };
    const series2 = AllDashBoardData?.Breakageratebyuser?.data?.series?.map((value) => parseFloat(value)?.toFixed(2));
    const options2 = {
        chart: {
            height: 350,
            type: "bar",
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top", // Place labels on top of the bars
                },
            },
        },
        xaxis: {
            categories: AllDashBoardData?.Breakageratebyuser?.data?.categories,
            title: {
                text: "Users",
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return parseFloat(val)?.toFixed(2);
                },
            },
            title: {
                text: "Breakage Rate",
            },
        },
        dataLabels: {
            style: {
                colors: ["black"],
            },
        },
        chart: {
            toolbar: {
                show: false,
            },
        },
        colors: ["#3CBBA5"],
        tooltip: {
            y: {
                formatter: function (val) {
                    return "Breakage Rate " + val;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    },
                },
            },
        },
    };
    const modalbody = (
        <>
            <div className="my-4">
                <div className="col-12 text-center  greyBoxShadow" style={{ height: "100%", borderRadius: "10px", backgroundColor: "white" }}>
                    <div className="w-100">
                        <h5 className="text-center pt-3">Breakage rate by building</h5>
                        {AllDashBoardData?.Breakageratebybuilding?.loader ? <><div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div></> : <>
                            <div style={{ overflowX: "scroll" }}>
                                <div className="" style={{
                                    width: series?.length > 10 ? "1500px" : "100%",
                                }}>
                                    <Chart options={options} series={[{ data: series }]} type="bar" height={200} />
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
            <div className="my-4">
                <div className="col-12 text-center  greyBoxShadow" style={{ height: "100%", borderRadius: "10px", backgroundColor: "white" }}>
                    <div className="w-100">
                        <h5 className="text-center pt-3">Breakage rate by grade</h5>
                        {AllDashBoardData?.Breakageratebygrade?.loader ? <><div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div></> : <>
                            <div className="">
                                <div style={{ overflowX: "scroll" }}>
                                    <div className="" style={{
                                        width: series1?.length > 10 ? "1500px" : "100%",
                                    }}>
                                        <Chart options={options1} series={[{ data: series1 }]} type="bar" height={200} />
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
            <div className="my-4">
                <div className="col-12 text-center  greyBoxShadow" style={{ height: "100%", borderRadius: "10px", backgroundColor: "white" }}>
                    <div className="w-100">
                        <h5 className="text-center pt-3">Breakage rate by user</h5>
                        {AllDashBoardData?.Breakageratebyuser?.loader ? <><div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div></> : <>
                            <div className="">
                                <div style={{ overflowX: "scroll" }}>
                                    <div className="" style={{
                                        width: series2?.length > 10 ? "1500px" : "100%",
                                    }}>
                                        <Chart options={options2} series={[{ data: series2 }]} type="bar" height={200} />
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
    return (
        <React.Fragment>
            <div className="col-12 text-center  greyBoxShadow">
                <div className="w-100">
                    <div className="position-relative">
                        <div className="row mt-2 px-2 time">
                            <div className='Header'>
                                <b className='font-15'>Breakage Rate </b><br />
                            </div>
                        </div>
                        <div className="position-absolute top-0 end-0 cursor-pointer" onClick={Open}>
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                        </div>
                    </div>
                    {AllDashBoardData?.BreakageRate?.loader ?
                        <div className='row'>
                            <div className="col-12 text-center pt-2">
                                <img src="/images/loder.svg" className="" style={{ width: "30px" }} />
                            </div>
                        </div>
                        : <div className="py-4 progress-container">
                            <CustomProgress
                                percentage={AllDashBoardData?.BreakageRate?.data?.data}
                                size={{ width: 230, height: 290 }}
                                strokeWidth={5}
                                strokeColor="#3cbba5"
                                hasBackground
                            />
                        </div>
                    }
                </div>
            </div>
            <Popup isshow={ModalShow} size={"lg"} title={"Device Breakage Rate"}
                closePopup={(e) => setModalShow(false)}
                modalBody={modalbody} visibleSaveBtn={false} />
        </React.Fragment>
    );
};

