import React from 'react'
import { ApiGetCall } from '../../../JS/Connector';
import { HideLoder, LogInSchoolID, ShowLoder } from '../../../JS/Common';
import { CSVLink } from 'react-csv';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export default function Exportusers() {
    const [loading, setLoading] = useState(false);
    const [CsvUserData, setCsvUserData] = useState([]);
    const [divVisibleForExport, setDivVisibleForExport] = useState({
        forUsers: false,
        forUsersNoRecord: false
    });
    const UsersHaders = [
        { label: "Student Name", key: "StudentName" },
        { label: "Parent Guardian Name", key: "parent_guardian_name" },
        { label: "Parent Guardian Email", key: "parent_guardian_email" },
        { label: "Parent Phone Number", key: "parent_phone_number" },
        { label: "Student Number", key: "student_num" },
        { label: "Device Serial Number", key: "Serial_number" },
        { label: "Grade", key: "grade" },
        { label: "Department", key: "department" },
    ];
    const csvUserReport = {
        filename: "Users.csv",
        headers: UsersHaders,
        data: CsvUserData,
    };
    async function ExportUsers() {
        setLoading(true);
        await ApiGetCall("/UtilizerExport/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var msgdata = responseRs.msg;
                if (msgdata.length > 0) {
                    setCsvUserData(msgdata);
                    setDivVisibleForExport({ ...divVisibleForExport, forUsers: true });
                } else {
                    setDivVisibleForExport({ ...divVisibleForExport, forUsersNoRecord: true });
                }
                setLoading(false);
            }
        });
    }
    function RemoveUserCsv() {
        setCsvUserData([]);
        setDivVisibleForExport({ ...divVisibleForExport, forUsers: false, forUsersNoRecord: false });
    }
    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}

            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Export Users</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    Would you like to export your user data? To do so, simply click on the 'Export Users' button.
                </p>
                <div className="row pt-3 pb-2 px-3">
                    <div className="col-12 px-0 py-2">
                        <label className='BorderBtnForimportExport' onClick={ExportUsers}>Export Users
                            <img src='/images/ExportInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>

                </div>
                {divVisibleForExport.forUsers &&
                    <div className="row pt-2" >
                        <div>Your Users CSV file is now ready for download. Please click the button below to initiate the file download.</div>
                        <div className="col-12 mt-1 text-center">
                            <label style={{ fontSize: "12px", color: "#04ADFD" }} id="ExportedStudentFileName">
                                Users.csv
                            </label>
                            <CSVLink {...csvUserReport} className="WhiteFont"> <button className='SaveBtn ms-4'>Download</button></CSVLink>
                            <FontAwesomeIcon icon={faCircleXmark} className="cursor-pointer ps-3" onClick={RemoveUserCsv} />
                        </div>
                    </div>
                }
                {divVisibleForExport.forUsersNoRecord &&
                    <div className="row pt-2">
                        <div className="text-danger">No Record Found!</div>
                    </div>
                }
            </div>

        </div>
    )
}
