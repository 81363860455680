import React, { useState, useLayoutEffect } from 'react'
import Chart from "react-apexcharts";
import { quicksort } from '../../../JS/Common';
export default function MonthlyTicketComparison({ MonthlyTicketComparison }) {
    const [data, setData] = useState([]);
    const [sortOrder, setSortOrder] = useState("desc");
    useLayoutEffect(() => { setData(MonthlyTicketComparison?.data) }, [MonthlyTicketComparison?.data])
    const handleSort = (val) => {
        let sortedData = val == "sendOutRepair" ? quicksort([...data?.totalMonthlyData], "sendoutforrepairticket", sortOrder) : quicksort([...data?.totalMonthlyData], "otherticket", sortOrder)
        setData((prevState) => ({ ...prevState, totalMonthlyData: sortedData }));
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };
    const categories = data?.totalMonthlyData?.map((item) => item?.month);
    const series = data?.totalMonthlyData?.map((item) => item?.sendoutforrepairticket);
    const series1 = data?.totalMonthlyData?.map((item) => item?.otherticket);
    const state = {
        series1: [
            {
                name: "Send out for repair",
                data: series
            },
            {
                name: "Total Tickets",
                data: series1
            }
        ],
        options1: {
            chart: {
                height: 250,
                type: 'line',
                toolbar: {
                    show: false
                }
            },
            colors: ['#3CBBA5', "#000"],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: 'smooth'
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: categories,
                title: {
                    text: 'Months',
                    style: {
                        fontSize: '12px'
                    }
                },

            },
            yaxis: {
                title: {
                    text: 'No. of tickets',
                    style: {
                        fontSize: '12px'
                    }
                },
                min: 0,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                floating: true,
                offsetY: -25,
                offsetX: -5
            }
        },


    }

    return (
        <div className="col-12  p-3" style={{ height: "100%" }}>
            <div className="greyBoxShadow w-100">
                <div className="row my-2 px-2 time text-center">
                    <div className='Header'>
                        <h5 className="fw-bold ps-2 mb-0">Monthly Tickets Comparison</h5>
                    </div>
                </div>
                {MonthlyTicketComparison?.loader ?
                    <div className='row'>
                        <div className="col-12 text-center pt-2">
                            <img src="/images/loder.svg" className="" style={{ width: "30px" }} />
                        </div>
                    </div>
                    :
                    <>
                        <div className="row py-3 px-2">
                            <div className="col-md-7 col-12 m-auto">
                                <div className="row display-center">
                                    <div className="col-md-3 col-6">
                                        <div className="boxStyle p-2" style={{ fontSize: "10px", border: " 1px solid" }}>
                                            <div style={{ fontWeight: "900" }}>Send out for repair </div>
                                            <div style={{ fontWeight: "600" }}>Max tickets month: </div>
                                            <div><span style={{ color: "red", fontWeight: "700" }}>{data?.maxSendOutRepair}</span></div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="boxStyle p-2" style={{ fontSize: "10px", border: " 1px solid" }}>
                                            <div style={{ fontWeight: "900" }}>Open tickets </div>
                                            <div style={{ fontWeight: "600" }}>Max tickets month: </div>
                                            <div><span style={{ color: "red", fontWeight: "700" }}>{data?.maxOpenTickets}</span></div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-4 cursor-pointer mt-md-0 mt-3" onClick={() => handleSort("sendOutRepair")} title="Ticket quantity-wise sorting."><div className="boxShadowStyleGray"><div className="row py-1">
                                        <div className="col-6 pe-0">
                                            <div className="row text-center ">
                                                <div className="col-12" style={{ fontSize: "11px", color: "#ffffff" }}>
                                                    0
                                                </div>
                                                <div className="col-12" style={{ fontSize: "11px", color: "#ffffff" }}>
                                                    9
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 ps-0">
                                            <img className="img-fluid pt-1" src="/images/mi_sort_white.svg" alt="" style={{ height: "25px" }} />
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="col-md-2 col-4 cursor-pointer mt-md-0 mt-3" onClick={() => handleSort("openTickets")} title="Efficiency-based sorting."><div className="boxShadowStyleBlack"><div className="row py-1">
                                        <div className="col-6 pe-0">
                                            <div className="row text-center">
                                                <div className="col-12" style={{ fontSize: "11px", color: "#ffffff" }}>
                                                    0
                                                </div>
                                                <div className="col-12" style={{ fontSize: "11px", color: "#ffffff" }}>
                                                    9
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 ps-0">
                                            <img className="img-fluid pt-1" src="/images/mi_sort_white.svg" alt="" style={{ height: "25px" }} />
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-1">
                            <div className="col-md-5 col-10 m-auto">
                                <div className="row">
                                    <div className="col-md-6 col-12 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8 8" fill="none">
                                        <circle cx="4" cy="4" r="4" fill="#3CBBA5" />
                                    </svg><span className="ps-2 fw-bold">Send out for repair</span></div>
                                    <div className="col-md-6 col-12 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8 8" fill="none">
                                        <circle cx="4" cy="4" r="4" fill="black" />
                                    </svg><span className="ps-2 fw-bold">Total tickets</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="pb-0">
                            <Chart
                                options={state?.options1}
                                series={state?.series1}
                                type={"line"}
                                height={250}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
