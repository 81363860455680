import React, { useState } from 'react';

const CustomFields = ({ type, value, options, onChange, isrequired, radiorequired, checkboxrequired }) => {
    const [selectedValues, setSelectedValues] = useState(value || []);
    const Options = options;
    switch (type) {
        case 2: // Long Text
            return <><textarea className="form-control" rows="1" value={value}
                onChange={(e) => onChange(e?.target.value)} required={isrequired == 1}></textarea>
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </>
        case 1: // Small Text
        case 3: // Number
        case 4: // Date
        case 5: // Date & Time 
            return <><input className="form-control" type={type === 3 ? 'number' : type === 4 ? 'date' : type === 5 ? 'datetime-local' : 'text'}
                value={value} onChange={(e) => onChange(type === 3 ? parseInt(e?.target.value) : e?.target.value)}
                required={isrequired == 1} />
                <span className="form-text invalid-feedback">
                    *required
                </span></>;
        case 6: // Single Selection (using radio buttons)
            return (
                <div>
                    <div className="row">
                        {Options.map((option) => (
                            <div className="col-6">
                                <div className="form-check">
                                    <label className="form-check-label" key={option.value}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={"radio" + option.custom_field_id}
                                            value={option.id}
                                            defaultChecked={value === option.id}
                                            onChange={(e) => onChange(parseInt(e?.target.value))}
                                        />
                                        {option.value}
                                    </label>
                                </div>
                            </div>
                        ))}
                        <span className="Emailinvalid">
                            {radiorequired}
                        </span>
                    </div>
                </div>
            );
        case 7: // Multi Selection (using checkboxes)
            const handleChange = (event) => {
                const newSelection = [...selectedValues];
                if (event.target.checked) {
                    newSelection.push(parseInt(event.target.value));
                } else {
                    newSelection.splice(newSelection.indexOf(parseInt(event.target.value)), 1);
                }
                setSelectedValues(newSelection);
                onChange(newSelection);
            };
            return (
                <div>
                    <div className="row">
                        {Options.map((option) => (
                            <div className="col-6">
                                <label className="form-check-label" key={option.value}>
                                    <input
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        value={option.id}
                                        checked={selectedValues.includes(option.id)}
                                        onChange={handleChange}
                                    />
                                    {option.value}
                                </label>
                            </div>
                        ))}
                        <span className="Emailinvalid">
                            {checkboxrequired}
                        </span>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default CustomFields;
