import React, { useState, useLayoutEffect, useContext } from 'react'
import { TableDataContext } from '../index';
export default function IncomingBatch(props) {
    const [loadData, setLoadData] = useState({ data: [], show: false })
    const { AllDashBoardData } = useContext(TableDataContext);
    useLayoutEffect(() => {
        if (AllDashBoardData?.incomingBatch?.data?.length > 0) {
            let Data = AllDashBoardData?.incomingBatch?.data
            if (Data?.length > 20) {
                setLoadData({ ...loadData, data: [].concat(Data.slice(0, 20)) })
            } else {
                setLoadData({ ...loadData, data: Data })
            }
        }
    }, [AllDashBoardData?.incomingBatch?.data])
    const LoadMore = () => {
        let newData = []
        if (loadData?.data?.length != AllDashBoardData?.incomingBatch?.data?.length) {
            newData = loadData?.data?.concat(AllDashBoardData?.incomingBatch?.data?.slice(loadData?.data?.length, loadData?.data?.length + 20));
        } else {
            newData = [].concat(AllDashBoardData?.incomingBatch?.data?.slice(0, 20))
        }
        setLoadData({ ...loadData, data: newData });
    }

    function trackingHandle(trackingnum) {
        const url = `https://www.fedex.com/fedextrack/?action=track&trackingnumber=${trackingnum}&cntry_code=us&locale=en_us`;
        window.open(url, '_blank');
    }
    return (
        <React.Fragment><div className="col-12  greyBoxShadow" style={{ height: "100%" }}>
            <div className="pb-5 w-100">
                <div className="row mt-2 px-2 time" >
                    <div className='Header'>
                        <b className='font-15'>Outgoing Batches</b><br />
                    </div>
                </div>
                <hr className="my-2" />
                {AllDashBoardData?.incomingBatch?.loader ? <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div> : <>
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>

                                <th scope="col" className='fw-600 font-14'>Batch Name</th>
                                <th scope="col" className='fw-600 font-14' >Devices</th>
                                <th scope="col" className='fw-600 font-14' >Tracking #</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AllDashBoardData?.incomingBatch?.data?.length > 0 ?
                                loadData?.data?.map((data, i) => (
                                    <tr className='OddEvenClass tableBorderHide py-3' key={data?.id}>
                                        <td className='location-name'>{data?.batch_name}</td>
                                        <td>{data?.school_batch_log[0]?.unique_ticket_count}</td>
                                        <td className='py-12 cursor-pointer' title="Track Shipment">{data.tracking_num != null ? <u onClick={(e) => trackingHandle(data.tracking_num)}>{data.tracking_num}</u> : <>-</>}</td>

                                    </tr>
                                )) :
                                <tr className='OddEvenClass tableBorderHide py-3'>
                                    <td colSpan={3} className='text-center'> No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </>}

                {
                    AllDashBoardData?.incomingBatch?.data?.length > 20 && <div className="col-12 p-2 admin-table-border-top">
                        <span style={{ textDecoration: "underline", cursor: "pointer", color: "#3CBBA5" }} onClick={() => LoadMore()}> {loadData?.data?.length == AllDashBoardData?.incomingBatch?.data?.length ? "Hide" : "Load More"}</span>
                    </div>
                }
            </div>
        </div></React.Fragment>
    )
}
