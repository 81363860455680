import React, { useState } from 'react'
import { ApiGetCall } from '../../../JS/Connector';
import { HideLoder, LogInSchoolID, ShowLoder } from '../../../JS/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export default function ExportbuildingroomComp() {
    const [loading, setLoading] = useState(false);
    const [CsvDataForBuildingRooms, setCsvDataForBuildingRooms] = useState([]);
    const [divVisibleForExport, setDivVisibleForExport] = useState({
        forBuildingRooms: false,
        forBuildingRoomsNoReocrd: false
    });
    async function ExportBuildingRooms() {
        setLoading(true);
        await ApiGetCall("/exportBuildingsRooms/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length > 0) {
                    setCsvDataForBuildingRooms(responseRs);
                    setDivVisibleForExport({ ...divVisibleForExport, forBuildingRooms: true });
                } else {
                    setDivVisibleForExport({ ...divVisibleForExport, forBuildingRoomsNoReocrd: true });
                }
                setLoading(false);
            }
        });
    }
    function RemoveBuildingRoomsCsv() {
        setCsvDataForBuildingRooms([]);
        setDivVisibleForExport({ ...divVisibleForExport, forBuildingRooms: false });
    }

    const DownloadCsv = () => {
        const csvContent = [];
        const buildingMap = [];
        CsvDataForBuildingRooms?.forEach((data) => {
            const rowData = {
                'Building': data?.building,
                'Rooms': data?.rooms,
            };
            buildingMap.push(rowData);
        });

        if (buildingMap.length > 0) {
            const headers = ['Buildings', 'Rooms'];
            csvContent.push(headers);
            buildingMap.forEach((rowData) => {
                const values = Object.values(rowData);
                csvContent.push(values);
            });
            csvContent.push([]);
            const csvString = csvContent.map((row) => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Buildings_Rooms.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No data available for download.');
        }
    };
    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Export Buildings with Rooms</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    To efficiently export all of your buildings with rooms, utilize the option below, which allows you to download them in a single CSV file. This streamlined process ensures you have easy access to all your buildings with rooms data.
                </p>
                <div className="row pt-3 pb-5 px-3">
                    <div className="col-12 px-0 py-2">
                        <label className='BorderBtnForimportExport' onClick={ExportBuildingRooms}>Export Buildings with Rooms
                            <img src='/images/ExportInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                    {divVisibleForExport.forBuildingRooms &&
                        <div className="row pt-2">
                            <div>Your Buildings with Rooms CSV file is now ready for download. Please click the button below to initiate the file download.</div>
                            <div className="col-12 mt-1 text-center">
                                <label style={{ fontSize: "12px", color: "#04ADFD" }}>
                                    Buildings_Rooms.csv
                                </label>
                                <button className='SaveBtn WhiteFont ms-4' onClick={DownloadCsv}>Download</button>
                                <FontAwesomeIcon icon={faCircleXmark} className="cursor-pointer ps-3" onClick={RemoveBuildingRoomsCsv} />
                            </div>
                        </div>
                    }
                    {divVisibleForExport.forBuildingRoomsNoReocrd &&
                        <div className="row pt-2" >
                            <div className="text-danger">No Record Found!</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
