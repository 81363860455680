import React, { useRef, useState } from 'react'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { HideLoder, LogInSchoolID, ShowLoder } from '../../../JS/Common';
import AlertsComp from '../../../Components/AlertsComp';

export default function ImportBuildingRoom() {
    const BaseUrl = process.env.REACT_APP_Base_URL;
    var ciphertext = Cookies.get('ciphertext');
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", ciphertext);
    const [NewAlerts, setNewAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const BuildingRoomRef = useRef();
    const [fileName, setFileName] = useState('');
    const navigate = useNavigate();
    function ImportBuildingRoomsCSV(e) {
        setLoading(true)
        e.preventDefault();
        const fileInput = BuildingRoomRef.current;
        const files = fileInput.files[0];
        if (!files) {
            setLoading(false);
            setFileName("First, import the CSV file.");
            return;
        }
        const filename = files.name;
        const extension = filename.split('.').pop();

        if (extension !== "csv") {
            setLoading(false);
            setFileName(filename);
            return;
        }
        setFileName(filename);

        const formdata = new FormData();
        formdata.append("file", files);
        formdata.append("schId", parseInt(LogInSchoolID));

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        fetch(`${BaseUrl}/importBuildingAndRooms`, requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'CSV imported Successfully'} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                        navigate('/building')
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            })
            .catch(error => console.error('error', error));
    }
    function ShowFileName(flag) {
        const fileInput = BuildingRoomRef.current;
        const files = fileInput.files[0];
        const filename = files.name;
        var extension = filename.split('.').pop();
        if (extension == "csv") {
            setFileName(filename)
        } else {
            setFileName("You can only upload CSV files.")
        }
    }
    return (
        <div>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Import Buildings with Rooms</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    To ensure data accuracy when entering buildings with rooms, please download our CSV template file. Ensure that your columns match the template before importing your buildings and rooms into the system.
                </p>
                <div className="row pt-3 px-3">
                    <label className="pb-4 ps-0 text-justify" style={{ fontSize: "13px" }}><a href="/Csv/Buildings_rooms.csv" style={{ color: "#04ADFD", fontWeight: "600" }}>CLICK HERE</a> to download the template CSV file for buildings with rooms data. Please use this as a template and prepare your CSV file accordingly. Once your file is ready, use button below to browse file from your computer and click on upload button to import all data into your account. </label>
                    <div className="col-12">
                        <form onSubmit={(e) => ImportBuildingRoomsCSV(e)}>
                            <input type="file" ref={BuildingRoomRef} name="rbuildingroom_file" id="BuildingRoomsFile" accept='.csv' onChange={(e) => ShowFileName(4)} />
                            <label className='ImportInventoryBtnForimportExport buttonclssMobile1' htmlFor="BuildingRoomsFile"> Import Buildings with Rooms
                                <img src='/images/ImportInventory.svg' className='img-fluid ps-2' />
                            </label>
                            {fileName != '' ?
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' style={{ backgroundColor: '#3CBBA5', color: '#fff' }} />
                                :
                                <input type="submit" value="Upload" className='UploadBtn buttonclssMobile' />
                            }
                            <br />
                            <div style={{ fontWeight: "600", color: 'green' }}>{fileName}</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
