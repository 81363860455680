import Cookies from 'js-cookie'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Thankyou() {
    Cookies.remove('helpTicketDetails');
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const schoolnumber = pathArray[2];
    const hdId = pathArray[3];
    const reqnum = pathArray[4];
    const baseurl = window.location.origin;
    const url = `${baseurl}/helpdesk-ticket/${schoolnumber}/${hdId}/${reqnum}`;
    return (
        <>
            <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
            <div className="position-relative MainDiv">
                <div className='col-md-8 offset-md-2 d-flex align-items-center' style={{ height: '100vh' }}>
                    <div className="GridBox mx-auto text-center p-4 ">
                        <div className="my-4">
                            <img src="/images/LoginLogo.png" className="img-fluid" alt="Logo" />
                        </div>

                        <h5 className='py-3 text-center'>
                            {schoolnumber == undefined ?
                                <p>Thank you for submitting your helpdesk!</p>
                                :
                                <>
                                    <p className='pt-1' >Your helpdesk request has been generated successfully.</p>
                                    <p className='pt-1'>You can view the details of your helpdesk request by clicking <a href={url}><u>here</u>.</a></p>
                                    <p className='pt-1'>You will receive a notification on your email when any action is taken on your Ticket.</p>
                                    <p className='pt-1'> Thank You!</p>
                                </>
                            }
                        </h5>
                    </div>
                </div>
            </div>
        </>
    )
}
