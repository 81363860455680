import React from 'react'
import { MMDDYYYY } from '../../JS/Common'
import Popup from '../../Components/Popup';

function SoftwareInfo(props) {
    const itemData = props.EditData
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const openPDFInNewTab = () => {
        const link = document.createElement('a');
        link.href = `${BucketS3Link}${props.EditData.document}`;
        window.open(link.href, '_blank');
    };
    const modalBody = (
        <div className="row px-2">
            <div className="col-md-6 p-1 pe-0 ">
                <label className='FormLabel'>Name : &nbsp;</label><label className='fw-600'>{itemData.name}</label>
            </div>
            <div className="col-md-6 p-1 pe-0">
                <label className='FormLabel'>Purchase Date : &nbsp;</label><label className='fw-600'>{MMDDYYYY(itemData.date_purchased)}</label>
            </div>
            <div className="col-md-6 p-1 pe-0">
                <label className='FormLabel'>Length of Licenses : &nbsp;</label><label className='fw-600'>{itemData.license_length}</label>
            </div>
            <div className="col-md-6 p-1 pe-0">
                <label className='FormLabel'>License Renewal : &nbsp;</label><label className='fw-600'>{MMDDYYYY(itemData.license_renewal)}</label>
            </div>
            <div className="col-md-6 p-1 pe-0">
                <label className='FormLabel'>Cost : &nbsp;</label><label className='fw-600'>${itemData.cost}</label>
            </div>
            <div className="col-md-6 p-1 pe-0">
                <label className='FormLabel'>Building Used For : &nbsp;</label><label className='fw-600'>{itemData.buildings}</label>
            </div>
            <div className="col-md-6 p-1 pe-0">
                <label className='FormLabel'>Quantity : &nbsp;</label><label className='fw-600'>{itemData.license_qty}</label>
            </div>
            <div className="col-md-6 p-1 pe-0">
                <label className='FormLabel'>Grade : &nbsp;</label><label className='fw-600'>{itemData.grade != null ? itemData.grade : '-'}</label>
            </div>
            <div className="col-md-6 p-1 pe-0 ">
                <label className='FormLabel'>Subject : &nbsp;</label><label className='fw-600'>{itemData.subject != null ? itemData.subject : '-'}</label>
            </div>
            <div className="col-md-6 p-1 pe-0 ">
                <label className='FormLabel'>Document : &nbsp;</label><label className='fw-600'>
                    {itemData.document != null ?
                        <>
                            <label className="mt-1" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{itemData.document} </label>
                            <img src='/images/eyeicon.svg' title="Show Software Details" className='pe-2' onClick={(e) => openPDFInNewTab(e)} />
                        </>
                        :
                        '-'}
                </label>
            </div>
            <div className="col-12 p-1 pe-0 ">
                <label className='FormLabel'>Notes : &nbsp;</label>
                <div className="fw-600"> {itemData.notes || '-'}</div>
            </div>
        </div>
    )
    return (
        <Popup isshow={props.isShow} size={"md"} title={"Software Details"}
            closePopup={props.ClosePopup} modalBody={modalBody} visibleSaveBtn={false} />
    )
}

export default SoftwareInfo
