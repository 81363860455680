import React, { useContext, useState } from 'react'
import { HideLoder, LogInSchoolID, LogInUserID, ShowLoder } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import { ApiPostCall } from '../../JS/Connector';
import { useNavigate } from 'react-router-dom';
import { AuditContext } from '.';
import { UserContext } from '../../App';
import SectionComponent from './Sectioncomponent';
import UnscannedDevice from './UnscannedDevice';
export default function FinalAudit() {
    const { FinalScanned, Auditnum, showWelcomeAuditDiv, FinalUnScanned } = useContext(AuditContext);
    const navigate = useNavigate();
    const BucketS3Link = process.env.REACT_APP_CDN_KEY
    const { UserData } = useContext(UserContext);
    const [Visibility, setVisibility] = useState({});
    const [deviceOptions, setDeviceOptions] = useState(FinalScanned.map(() => ({
        newDevice: 0,
        mismatch: 0,
        insystemWithoutBuilding: 0,
        insystemWithoutRoom: 0
    })));
    const [Loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState('');
    async function downloadReports(flag) {
        setLoading(true);
        var FinalArray = [];
        FinalScanned.map((item, index) => {
            var vJson = {};
            vJson['scannedBuildingId'] = item.scannedBuildingId;
            vJson['scannedBuildingName'] = item.scannedBuildingName;
            vJson['scannedRoomId'] = item.scannedRoomId;
            vJson['scannedRoomName'] = item.scannedRoomName;
            vJson['newDeviceOption'] = deviceOptions[index].newDevice;
            vJson['newDevice'] = item.newDevice;
            vJson['mismatchDeviceOption'] = deviceOptions[index].mismatch;
            vJson['mismatch'] = item.mismatch;
            vJson['insystemWithoutBuilding'] = item.insystemWithoutBuilding;
            vJson['insystemWithoutBuildingOption'] = deviceOptions[index].insystemWithoutBuilding;
            vJson['insystemWithoutRoom'] = item.insystemWithoutRoom;
            vJson['insystemWithoutRoomOption'] = deviceOptions[index].insystemWithoutRoom;
            vJson['correctlyFound'] = item.correctlyFound;
            vJson['auditNumber'] = item.auditNumber;
            FinalArray.push(vJson);
        })
        var raw = JSON.stringify({
            schoolId: LogInSchoolID,
            userId: LogInUserID,
            agreementFlag: showWelcomeAuditDiv,
            auditNumber: Auditnum,
            username: UserData.UserName,
            status: flag == 1 ? 2 : 3,
            finalData: FinalArray,
            unscannedData: FinalUnScanned
        });
        if (flag == 1) {
            await ApiPostCall("/DownloadAuditReport", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    if (responseRs.response == "success") {
                        setLoading(false);
                        window.open(BucketS3Link + responseRs.pdf_url, '_blank');
                    }
                    else {
                        setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.response} />);
                    }
                }
            });
        } else {
            await ApiPostCall("/completeAudit", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    if (responseRs.response == "success") {
                        setLoading(false);
                        window.open(BucketS3Link + responseRs.pdf_url, '_blank');
                        navigate('/manage-device');
                    }
                    else {
                        setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    }
                }
            });
        }
    }
    const handleRadioChange = (index, type, value) => {
        setDeviceOptions((prevState) => {
            const newState = [...prevState];
            newState[index][type] = value;
            return newState;
        });
    };
    function handleArrowClick(i) {
        setVisibility((prevState) => ({
            ...prevState,
            [i]: !prevState[i],
        }));
    }
    return (
        <>
            {Alerts}
            {Loading ? <ShowLoder /> : <HideLoder />}
            <div className='row pt-3 px-3'>
                <UnscannedDevice data={FinalUnScanned} />
                {FinalScanned?.map((item, i) => {
                    const isVisible = Visibility[i];
                    return (<div className='col-12 greyBoxShadow my-2' key={i}>
                        <div className='row py-2'>
                            <div className='col-10'>
                                <b className='mb-0'>Audit Report for <b className='schoolColorStatus'>{item.scannedBuildingName}</b> {item.scannedRoomId == 0 ? 'Across The' : ','} <b className='schoolColorStatus'>{item.scannedRoomName}</b></b>
                            </div>
                            <div className='col-2 text-end'>
                                <img src="/images/DownRoundArrow.svg" className={`cursor-pointer img-fluid ${isVisible ? 'd-none' : ''}`}
                                    onClick={() => handleArrowClick(i)} />
                                <img src="/images/UpRoundArrow.svg" className={`img-fluid cursor-pointer ${isVisible ? '' : 'd-none'}`}
                                    onClick={() => handleArrowClick(i)} />
                            </div>
                        </div>
                        {isVisible && (
                            <>
                                <hr className='m-0' />
                                <SectionComponent
                                    title="Scanned Devices Not in Rocket"
                                    data={item.newDevice}
                                    scannedYesNoType="newDevice"
                                    handleRadioChange={handleRadioChange}
                                    scanyesno={deviceOptions[i].newDevice}
                                    radiotext={`These devices you scanned weren't found in the system. Would you like to add them now?`}
                                    showyesno={true}
                                    index={i}
                                />
                                <SectionComponent
                                    title="Mismatched Locations"
                                    data={item.mismatch}
                                    scannedYesNoType="mismatch"
                                    handleRadioChange={handleRadioChange}
                                    scanyesno={deviceOptions[i].mismatch}
                                    radiotext={'Scanned devices have location mismatches. Would you like to update their locations in the system?'}
                                    showyesno={true}
                                    index={i}
                                />

                                <SectionComponent
                                    title="Correctly Scanned Rocket Devices"
                                    data={item.correctlyFound}
                                    showyesno={false}
                                    index={i}
                                />
                                <SectionComponent
                                    title="Scanned Devices In Rocket Without Building"
                                    data={item.insystemWithoutBuilding}
                                    scannedYesNoType="insystemWithoutBuilding"
                                    handleRadioChange={handleRadioChange}
                                    scanyesno={deviceOptions[i].insystemWithoutBuilding}
                                    radiotext={'All devices exist in the system without being assigned to a building. Would you like to add a scanned building?'}
                                    showyesno={true}
                                    index={i}
                                />
                                <SectionComponent
                                    title="Scanned Devices In Rocket Without Room"
                                    data={item.insystemWithoutRoom}
                                    scannedYesNoType="insystemWithoutRoom"
                                    handleRadioChange={handleRadioChange}
                                    scanyesno={deviceOptions[i].insystemWithoutRoom}
                                    radiotext={'All devices exist in the system without being assigned to a room. Would you like to add a scanned room?'}
                                    showyesno={true}
                                    index={i}
                                />
                            </>
                        )}
                    </div>);
                })}
            </div>
            <div className='col-12 text-center pt-3'>
                <label className='redText'>Before Execute & downloading the reports, please verify your selection and confirm any changes you want to make.</label>
                <br />
                <button className='SaveBtn mx-2 mt-3' onClick={(e) => downloadReports(1)}>Download Report</button>
                <button className='SaveBtn mx-2 mt-3' onClick={(e) => downloadReports(2)}>Download Report & Execute</button>
            </div>
        </>
    )
}
