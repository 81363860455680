import React, { useEffect, useState } from 'react'
import { DeviceDetailsPopup } from '../../Components/DeviceDetailsPopup';
import { ApiGetCall, ApiGetHeaders } from '../../JS/Connector';
import { CamelCaseWithSpaces, MMDDYYYY, typewatch } from '../../JS/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import TicketStatuspopup from '../../Components/TicketStatuspopup';
import TicketDetailsPopup from '../../Components/TicketDetailsPopup';
import Cookies from 'js-cookie';
import TicketStatusDropdown from '../../Components/TicketStatusDropdown';
import Pagination from 'react-js-pagination';
import CustomizationPopup from '../../Components/CustomizationPopup';
import UserDetailsPopup from '../../Components/UserDetailsPopup';
import jinja from 'jinja-js';
import AdminCreateBatch from './AdminCreateBatch';
import { Schoolsearchbar } from '../../Components/Schoolsearchbar';
import AlertsComp from '../../Components/AlertsComp';
import PageHead from '../../Components/PageHead';
import Tab from '../../Components/Tab';

export function SchoolTickets() {
    const BaseUrl = process.env.REACT_APP_Base_URL;
    const [TicketsTabs, setTicketsTabs] = useState({
        openTicketTab: "",
        pendingTicketTab: '',
        sentOutForRepair: 'active',
        atVendorTicketTab: '',
        SearchForOpen: '',
        SearchForPending: '',
        searchForSentOutForRepair: '',
        SearchForAtVendor: '',
        numberOfTicketCount: 0,
        numberofSubtotal: 0,
        sortbykey: null,
        sortbyflag: "asc"
    });
    const [tabflag, settabflag] = useState(4);
    const [noteVisibility, setNoteVisibility] = useState({});
    const [TicketParams, setTicketParams] = useState({
        inventoryId: '',
        ticketId: '',
        flag: 1,
    });
    const [StudentidForSatuschange, setStudentidForSatuschange] = useState('')
    const [IsModal, setIsModal] = useState({
        isshowpopup: false,
        isTicketDetailspopup: false,
        CreateBatchmodal: false,
        CreateBatchbuttonVisible: false,
        isTicketStatuspopup: false,
        DeviceNameForAmountMOdal: '',
        subtotal: 0,
        TicketStatusId: ''
    })
    const [GridDataForOpen, setGridDataForOpen] = useState([]);
    const [GridDataForPending, setGridDataForPending] = useState([]);
    const [GridDataForSentOutTicket, setGridDataForSentOutTicket] = useState([]);
    const [GridDataForAtVendor, setGridDataForAtVendor] = useState([]);
    const [selectedIdsOpen, setSelectedIdsOpen] = useState([]);
    const [selectedIdsPedning, setSelectedIdsPedning] = useState([]);
    const [selectedIdsSentOut, setSelectedIdsSentOut] = useState([]);
    const [selectedIdsAtVendor, setSelectedIdsAtVendor] = useState([]);
    const [selectedIdsOpen1, setSelectedIdsOpen1] = useState([]);
    const [selectedIdsPedning1, setSelectedIdsPedning1] = useState([]);
    const [selectedIdsSentOut1, setSelectedIdsSentOut1] = useState([]);
    const [selectedIdsAtVendor1, setSelectedIdsAtVendor1] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const navigate = useNavigate();
    const [schoolId, setSchoolId] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [Batches, setBatches] = useState([]);
    const [loading, setLoading] = useState(false);
    const storedSearchValue = Cookies.get('AdminSchoolId');
    const [selectedbatchid, setselectedbatchid] = useState(0);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const showPagination = GridDataForOpen.length > 0;
    const showPagination1 = GridDataForPending.length > 0;
    const showPagination2 = GridDataForSentOutTicket.length > 0;
    const showPagination3 = GridDataForAtVendor.length > 0;
    const [sortingNumForSentOut, setSortingNumForSentOut] = useState(0)
    const [sortingNumForOpen, setSortingForOpen] = useState(0)
    const [sortingNumForRepair, setSortingNumForRepair] = useState(0)
    const [sortingNumForAtvendor, setSortingNumForAtvendor] = useState(0)
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const batchid = pathArray[2];
    const [SelectBuildingID, setSelectBuildingID] = useState(null)
    const [BuildingNames, setBuildingNames] = useState([])
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [UserModal, setUserModal] = useState(false)
    const [Userid, setUserid] = useState('');
    const [alert, setAlert] = useState('')
    const location = useLocation();
    const TicketIdFromCreateTicket = location.state && location.state.ticketid;
    useEffect(() => {
        const isAlertShownInHistory = window.history.state && window.history.state.alertShown;
        if (TicketIdFromCreateTicket != null && !isAlertShownInHistory) {
            setAlert(<AlertsComp show={true} variant="success" msg={`Ticket (${TicketIdFromCreateTicket}) Created Successfully`} />);
            window.history.pushState({ alertShown: true }, '');
            setTimeout(function () {
                setAlert(<AlertsComp show={false} />);
            }, 5000);
        }
    }, [TicketIdFromCreateTicket]);
    useEffect(() => {
        ApiGetHeaders(26, setColumnNames) //atvendor
    }, []);
    useEffect(() => {
        setSchoolId(storedSearchValue);
        setselectedbatchid(batchid)
        if (storedSearchValue != undefined) {
            if (TicketsTabs.sentOutForRepair == "active") {
                if (tabflag == 4) {
                    GetGridData(TicketsTabs.SearchForAtVendor || null, 4, batchid, sortingNumForAtvendor || null)//atvendor
                } else if (tabflag == 1) {
                    AllBatches();
                    GetGridData(TicketsTabs.searchForSentOutForRepair || null, 1, batchid, sortingNumForSentOut || null)//sendoutforrepair
                } else if (tabflag == 2) {
                    GetGridData(TicketsTabs.SearchForOpen || null, 2, batchid, sortingNumForOpen || null)//open
                }
            } else if (TicketsTabs.pendingTicketTab == 'active') {
                AllReturnLocation();
                GetGridData(TicketsTabs.SearchForPending ? TicketsTabs.SearchForPending : null, 3, batchid, sortingNumForRepair || null)//repaired
            }
        }
    }, [storedSearchValue, currentPage, LimitForPagination, sortingNumForSentOut, sortingNumForOpen, sortingNumForRepair, sortingNumForAtvendor, tabflag, SelectBuildingID]);

    useEffect(() => {
        const selectedArray = [
            ...selectedIdsOpen1.map((item) => ({ id: item?.id, subtotal: item?.price || 0, studentID: item?.studentid })),
            ...selectedIdsPedning1.map((item) => ({ id: item?.id, subtotal: item?.price || 0, studentID: item?.studentid })),
            ...selectedIdsSentOut1.map((item) => ({ id: item?.id, subtotal: item?.price || 0, studentID: item?.studentid })),
            ...selectedIdsAtVendor1.map((item) => ({ id: item?.id, subtotal: item?.price || 0, studentID: item?.studentid })),
        ];
        const idAndSubtotalArray = selectedArray.map(({ id, subtotal, studentID }) => ({ ID: id, SubTotal: subtotal, StudentID: studentID == undefined ? null : studentID }));
        setSelectedItems(idAndSubtotalArray);
        if (selectedIdsOpen1.length === 0 && selectedIdsPedning1.length === 0 && selectedIdsSentOut1.length === 0 && selectedIdsAtVendor1.length === 0) {
            setIsModal({ ...IsModal, CreateBatchbuttonVisible: false });
        }
        const selectedOnlyID = selectedArray.map(({ id, subtotal, studentID }) => ({ ID: id, StudentID: studentID == undefined ? null : studentID }))
        setSelectedIds(selectedOnlyID)
        const idCount = idAndSubtotalArray.length;
        const subtotalSum = idAndSubtotalArray.reduce((acc, { SubTotal }) => acc + SubTotal, 0);
        setTicketsTabs({ ...TicketsTabs, numberOfTicketCount: idCount, numberofSubtotal: subtotalSum })
    }, [selectedIdsOpen, selectedIdsPedning, selectedIdsSentOut, selectedIdsAtVendor, selectedIdsOpen1, selectedIdsPedning1, selectedIdsSentOut1, selectedIdsAtVendor1])

    async function AllBatches() {
        await ApiGetCall("/getAllSchoolBatchNames/" + storedSearchValue).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setBatches(responseRs.msg.length ? responseRs.msg : [])
            }
        });
    }
    async function AllReturnLocation() {
        await ApiGetCall("/allReturnLocation/" + storedSearchValue).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setBuildingNames(responseRs?.msg?.length ? responseRs?.msg : [])
            }
        });
    }
    function GetGridData(SearchString, flag, batchid, sortbykey) {
        if (batchid === "0" || batchid === undefined) {
            batchid = null;
        }
        settabflag(flag);
        setLoading(true);
        if (!SearchString) SearchString = null;
        if (sortbykey) TicketsTabs.sortbyflag = TicketsTabs.sortbyflag == "asc" ? "desc" : "asc";
        var sortbykeyy;
        if (flag == 1) {
            if (sortingNumForSentOut != 0) {
                sortbykeyy = sortingNumForSentOut
            } else {
                sortbykeyy = sortbykey
            }
        } else if (flag == 2) {
            if (sortingNumForOpen != 0) {
                sortbykeyy = sortingNumForOpen
            } else {
                sortbykeyy = sortbykey
            };
        } else if (flag == 3) {
            if (sortingNumForRepair != 0) {
                sortbykeyy = sortingNumForRepair
            } else {
                sortbykeyy = sortbykey
            }
        } else {
            if (sortingNumForAtvendor != 0) {
                sortbykeyy = sortingNumForAtvendor
            } else {
                sortbykeyy = sortbykey
            }
        }

        typewatch(async function () {
            await ApiGetCall("/AllTicketsForAdminPanel/" + sortbykeyy + "&" + TicketsTabs.sortbyflag + '&' + storedSearchValue + '&' + SearchString + '&' + currentPage + '&' + LimitForPagination + '&' + flag + '&' + batchid + '&' + SelectBuildingID).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs.total)
                    if (responseRs.data.length != 0) {
                        if (flag == 1) {
                            setGridDataForSentOutTicket(responseRs.data)
                        } else if (flag == 2) {
                            setGridDataForOpen(responseRs.data);
                        } else if (flag == 3) {
                            setGridDataForPending(responseRs.data);
                        } else {
                            setGridDataForAtVendor(responseRs?.data)
                        }
                    } else {
                        if (flag == 1) {
                            setGridDataForSentOutTicket([])
                        } else if (flag == 2) {
                            setGridDataForOpen([]);
                        } else if (flag == 3) {
                            setGridDataForPending([]);
                        } else {
                            setGridDataForAtVendor([])
                        }
                    }
                }
            });
        }, SearchString != null ? 500 : 0);
    }
    function handleArrowClick(ticketId) {
        setNoteVisibility((prevState) => ({ ...prevState, [ticketId]: !prevState[ticketId], }));
    };
    function CreateBatchPopupShow() {
        setIsModal({ ...IsModal, CreateBatchmodal: true })
    }
    function handleSetSchoolId(id) {
        setSchoolId(id);
    };
    function handleHeaderCheckboxChange(e, ticketFlag) {
        setIsModal({ ...IsModal, CreateBatchbuttonVisible: true });
        const isChecked = e.target.checked;
        const getSelectableIds = (data) => {
            return data.filter(item => !item.batch_flag && item.batch_flag == 0).map(item => item.id);
        }
        const getSelectableIds1 = (data) => {
            return data
                .filter(item => !item.batch_flag && item.batch_flag == 0)
                .map(item => ({ id: item.id, price: item.onlyadminpartspriceTotal, studentid: item?.StudentID || null }));
        }
        if (ticketFlag == "open") {
            setSelectedIdsOpen(isChecked ? getSelectableIds(GridDataForOpen) : []);
            setSelectedIdsOpen1(isChecked ? getSelectableIds1(GridDataForOpen) : []);
        } else if (ticketFlag == 'pending') {
            setSelectedIdsPedning(isChecked ? getSelectableIds(GridDataForPending) : []);
            setSelectedIdsPedning1(isChecked ? getSelectableIds1(GridDataForPending) : []);
        } else if (ticketFlag == 'sentout') {
            setSelectedIdsSentOut(isChecked ? getSelectableIds(GridDataForSentOutTicket) : []);
            setSelectedIdsSentOut1(isChecked ? getSelectableIds1(GridDataForSentOutTicket) : []);
        } else if (ticketFlag == 'atvendor') {
            setSelectedIdsAtVendor(isChecked ? getSelectableIds(GridDataForAtVendor) : []);
            setSelectedIdsAtVendor1(isChecked ? getSelectableIds1(GridDataForAtVendor) : []);
        }
    };
    function handleCheckboxChange(e, ticketFlag, ticketID, price, studentID) {
        const isChecked = e.target.checked;
        setIsModal({ ...IsModal, CreateBatchbuttonVisible: true });

        const updateSelectedIds = (setSelectedIds, setSelectedIds1) => {
            setSelectedIds(prevIds =>
                isChecked ? [...prevIds, ticketID] : prevIds.filter(id => id != ticketID)
            );
            setSelectedIds1(prevIds =>
                isChecked ? [...prevIds, { id: ticketID, price, studentid: studentID || null }] : prevIds.filter(item => item.id !== ticketID)
            );
        };

        switch (ticketFlag) {
            case 'open':
                updateSelectedIds(setSelectedIdsOpen, setSelectedIdsOpen1);
                break;
            case 'pending':
                updateSelectedIds(setSelectedIdsPedning, setSelectedIdsPedning1);
                break;
            case 'sentout':
                updateSelectedIds(setSelectedIdsSentOut, setSelectedIdsSentOut1);
                break;
            case 'atvendor':
                updateSelectedIds(setSelectedIdsAtVendor, setSelectedIdsAtVendor1);
                break;
            default:
                break;
        }
    }
    function RepairdetailsScreen(TicketID, FlagParent) {
        setTicketParams({ ...TicketParams, ticketId: TicketID, });
        var tabTicketId = TicketID
        var schoolid = schoolId
        var workedonflag = 1
        var tickettabflag = tabflag
        var parentalCoverageFlag = FlagParent
        navigate('/repair-details', { state: { tabTicketId, tickettabflag, workedonflag, schoolid, parentalCoverageFlag } });
    }
    const handleSortClick = (item) => {
        if (item == 'User') {
            item = 'device_user_first_name'
        } else if (item == 'parts_total') {
            item = 'subTotal'
        } else if (item == 'created_by') {
            item = 'first_name'
        }
        if (TicketsTabs.sentOutForRepair == 'active') {
            if (tabflag == 4) {
                GetGridData(TicketsTabs.SearchForAtVendor, 4, batchid, item); setSortingNumForAtvendor(item)
            } else if (tabflag == 1) {
                GetGridData(TicketsTabs.searchForSentOutForRepair, 1, batchid, item); setSortingNumForSentOut(item)
            } else if (tabflag == 2) {
                GetGridData(TicketsTabs.SearchForOpen, 2, batchid, item); setSortingForOpen(item)
            }
        } else if (TicketsTabs.pendingTicketTab == 'active') {
            GetGridData(TicketsTabs.SearchForPending, 3, batchid, item); setSortingNumForRepair(item)
        }
    };
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "ticket_num": (
                <span className='cursor-pointer' ticketid={item.id} onClick={(e) => {
                    setIsModal({ ...IsModal, isTicketDetailspopup: true });
                    setTicketParams({ ...TicketParams, ticketId: item?.id, inventoryId: item?.inventory_id })
                }}>
                    <u>{item?.ticket_num}</u>
                </span>
            ),
            "Serial_number": (
                <span
                    className="cursor-pointer"
                    title="Show Device Details"
                    ticketid={item.id}
                    onClick={(e) => {
                        setIsModal({ ...IsModal, isshowpopup: true });
                        setTicketParams({ ...TicketParams, inventoryId: item.inventory_id, ticketId: item.id });
                    }}
                >
                    <u>{item?.serial_number || "-"}</u>
                </span>
            ),
            "User": item.StudentID
                ? <u onClick={(e) => { setUserid(item.StudentID); setUserModal(true) }} className='cursor-pointer'>{item?.device_user_first_name + ' ' + (item?.device_user_middle_name || '') + ' ' + (item?.device_user_last_name || '')}</u>
                : '-',
            "return_location": item?.return_location_building_name || '-',
            "created_at": MMDDYYYY(item.created_at) || "-",
            "created_by": item.first_name ? (item.first_name + ' ' + item.last_name) : "-",
            "parts_total": item.subTotal ? `$${item.subTotal.toFixed(2)}` : "$0",
            "Device_model": item?.device_model || "-",
            "asset_tag": item?.asset_tag || "-",
            "Grade": item?.grade || "-",
            "Parent_Guardian_Email": item?.parent_guardian_email || "-",
            "Parent_phone_number": item?.parent_phone_number || "-",
            "Parent_guardian_name": item?.parent_guardian_name || "-",
            "Parental_coverage": item?.parental_coverage == "1" ? "Yes" : "No",
            "Student_num": item?.student_num || "-",
            'CountofClaim': item?.ClaimCount ? (item?.ClaimCount >= 2 ? <b className='redText'>{item?.ClaimCount}</b> : item?.ClaimCount) : '0'
        };

        return contentMap[columnName] || null;
    }
    async function PrintTicketDetails() {
        var raw = JSON.stringify({
            TicketIdArray: selectedIds
        });
        var ciphertext = Cookies.get('ciphertext');
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", ciphertext);
        fetch("AutomaticTicketCreation.html")
            .then((response) => response.text())
            .then((templateText) => {
                const template = jinja.compile(templateText);
                fetch(BaseUrl + "/GetSelectedTicketData", {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw
                }).then((response) => response.json())
                    .then((data) => {
                        const rendered = template.render({ data });
                        const printWindow = window.open('', '_blank');
                        printWindow.document.write(rendered);
                        printWindow.document.close();
                        printWindow.document.head.insertAdjacentHTML('beforeend', `
                            <style>
                                @page {
                                    size: auto;
                                    margin: 10mm;
                                }
                                body{
                                    margin: 10mm;
                                }
                                @media print {
                                    body {
                                        -webkit-print-color-adjust: exact;
                                        color-adjust: exact;
                                    }
                                    @page {
                                        size: auto;
                                        margin: 10mm;
                                    }
                                }
                            </style>
                        `);
                        printWindow.onload = () => {
                            printWindow.print();
                            printWindow.onafterprint = () => {
                                printWindow.close();
                            };
                        };
                    })
                    .catch((error) => {
                        console.error("Error loading data from API", error);
                    });
            })
            .catch((error) => {
                console.error("Error loading template", error);
            });
    }
    const renderTable = (gridData, selectedIds, showPagination, GridFlagg) => {
        return (
            <>
                <table className="table data-table mb-0">
                    <thead className='GridHeader'>
                        <tr>
                            <th scope="col">
                                {gridData.filter(item => !item.batch_flag).length != 0 && (
                                    <input className='form-check-input' type="checkbox"
                                        checked={selectedIds.length == gridData.filter(item => !item.batch_flag).length}
                                        onChange={(e) => handleHeaderCheckboxChange(e, GridFlagg)} />
                                )}
                            </th>
                            {columnNames?.map((item) => {
                                let columnName = item.replace(/_/g, ' ');
                                if (columnName == 'CountofClaim') { columnName = 'Claims' }
                                return (
                                    <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                        {CamelCaseWithSpaces(columnName)}
                                        <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                    </th>
                                );
                            })}
                            <th scope="col" className='fw-600' >Status</th>
                            <th scope="col">Action<img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {gridData.length != 0 ?
                            <>
                                {gridData.map((item, i) => {
                                    const isNoteVisible = noteVisibility[item.id];
                                    var returData =
                                        <tr key={i} className={`OddEvenClass tableBorderHide ${item.batch_flag == true ? 'redText' : ''}`}>
                                            <td className="py-12">
                                                {item.batch_flag == true ? <></> :
                                                    <input className='form-check-input' type="checkbox" checked={selectedIds.includes(item.id)}
                                                        onChange={(e) => handleCheckboxChange(e, GridFlagg, item.id, item.onlyadminpartspriceTotal, item?.StudentID)} ticketnum={item.ticket_num} />}
                                            </td>
                                            {columnNames?.map(header => (<td className="py-12" key={header}>  {renderContentForColumn(header, item)}  </td>))}
                                            <td className='py-12 cursor-pointer'>
                                                <u onClick={(e) => { setStudentidForSatuschange(item.StudentID); setIsModal({ ...IsModal, isTicketStatuspopup: true, TicketStatusId: item.ticket_status }); setTicketParams({ ...TicketParams, ticketId: item?.id }); }} ticketid={item.id}>{item?.status}</u>
                                            </td>
                                            <td className='py-12'>
                                                <img src="/images/DownRoundArrow.svg" id={`DownArrow_${item.id}`} className={`cursor-pointer img-fluid ${isNoteVisible ? 'd-none' : ''}`}
                                                    onClick={() => handleArrowClick(item.id)} ticketid={item.id} />
                                                <img src="/images/UpRoundArrow.svg" id={`UpArrow_${item.id}`} className={`img-fluid cursor-pointer ${isNoteVisible ? '' : 'd-none'}`}
                                                    onClick={() => handleArrowClick(item.id)} ticketid={item.id} />
                                                <img src="/images/repairTech.svg" className="px-2 cursor-pointer" title="Add Repair Parts" onClick={(e) => { RepairdetailsScreen(item.id, item?.StudentHaveInsurancePlan) }} ticketid={item.id} />
                                            </td>
                                        </tr>
                                    if (isNoteVisible) {
                                        returData = (
                                            <>
                                                {returData}
                                                <tr className={`col-12  px-5`}>
                                                    <td colSpan={18}>
                                                        <b className="font-13 pe-3"> Notes:</b>
                                                        <div style={{ display: "inline" }}> {item.notes || '-'} </div>
                                                    </td>
                                                </tr>
                                                <tr className={`col-12 py-1 px-5`}>
                                                    <td colSpan={18}>
                                                        <b className="font-13 pe-3">Device Issues:</b>
                                                        <div style={{ display: "inline" }}> {item.issue_names} </div>
                                                    </td>
                                                </tr>
                                                <tr className={`col-12 py-1 px-5`}>
                                                    <td colSpan={18}>
                                                        <b className="font-13 pe-3">Building :</b>
                                                        <div style={{ display: "inline" }}> {item.inventory_building_name || '-'} </div>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    }
                                    return returData;
                                })}
                            </>
                            :
                            <tr> <td colSpan={18} className="p-3 text-center"> No Record Found </td> </tr>
                        }
                    </tbody>
                </table>
                {showPagination && (
                    <Pagination activePage={currentPage} totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15} itemsCountPerPage={LimitForPagination} onChange={(e) => setCurrentPage(e)}
                        color="primary" lastPageText="" firstPageText="" itemClassFirst="MasterClass" itemClassPrev="MasterClass"
                        itemClassNext="MasterClass" itemClassLast="MasterClass" />
                )}
            </>
        );
    };
    function handleSearch(e) {
        if (tabflag == 1) { //send out for repair
            setCurrentPage(1);
            GetGridData(e.target.value, 1, selectedbatchid, null)
            setTicketsTabs({ ...TicketsTabs, searchForSentOutForRepair: e.target.value });
        } else if (tabflag == 2) { //open tickets
            setCurrentPage(1);
            GetGridData(e.target.value, 2, selectedbatchid, null)
            setTicketsTabs({ ...TicketsTabs, SearchForOpen: e.target.value });
        } else if (tabflag == 3) { //repaired tab
            setCurrentPage(1);
            GetGridData(e.target.value, 3, selectedbatchid, null)
            setTicketsTabs({ ...TicketsTabs, SearchForPending: e.target.value });
        } else if (tabflag == 4) {// at vendor tab
            setCurrentPage(1);
            GetGridData(e.target.value, 4, selectedbatchid, null)
            setTicketsTabs({ ...TicketsTabs, SearchForAtVendor: e.target.value });
        }
    }
    function handleTabClick(tabType) {
        if (tabType == "open") {
            GetGridData(null, 4, selectedbatchid, null)
            settabflag(4)
            setTicketsTabs({ ...TicketsTabs, openTicketTab: "", pendingTicketTab: '', sentOutForRepair: 'active', atVendorTicketTab: '', SearchForOpen: '', SearchForPending: '', searchForSentOutForRepair: '', SearchForAtVendor: '' });
            setCurrentPage(1);
            setLimitForPagination(25);
            setSelectBuildingID(null)
        } else if (tabType == "repaired") {
            GetGridData(null, 3, null, null)
            setTicketsTabs({ ...TicketsTabs, openTicketTab: "", pendingTicketTab: 'active', sentOutForRepair: '', atVendorTicketTab: '', SearchForOpen: '', SearchForPending: '', searchForSentOutForRepair: '', SearchForAtVendor: '' });
            settabflag(3);
            setCurrentPage(1);
            setLimitForPagination(25);
        }
    }
    const handleRadioChange = (flag, selectedbatchid = null) => {
        GetGridData(null, flag, selectedbatchid, null);
        setTicketsTabs({
            ...TicketsTabs,
            openTicketTab: "",
            pendingTicketTab: '',
            sentOutForRepair: 'active',
            atVendorTicketTab: '',
            SearchForOpen: '',
            SearchForPending: '',
            searchForSentOutForRepair: '',
            SearchForAtVendor: ''
        });
        settabflag(flag);
        setCurrentPage(1);
        setLimitForPagination(25);
    };

    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"School Tickets"} handleSearch={handleSearch}
                loading={loading} Alerts={alert} showsearch={schoolId || null} />
            <div className='row col-12 align-items-center pe-0 pt-2'>
                <div className='col-md-10 text-end'>
                    <Schoolsearchbar schlid={handleSetSchoolId} GetGridData={GetGridData} setSelectedIdsOpen1={setSelectedIdsOpen1} setSelectedIdsPedning1={setSelectedIdsPedning1} setSelectedIdsSentOut1={setSelectedIdsSentOut1} setSelectedIdsAtVendor1={setSelectedIdsAtVendor1}
                        SchoolLoginFlag={1} batchid={batchid} tabflag={tabflag} settabflag={settabflag}
                        setSelectedIdsOpen={setSelectedIdsOpen} setSelectedIdsPedning={setSelectedIdsPedning} setSelectedIdsSentOut={setSelectedIdsSentOut} setSelectedIdsAtVendor={setSelectedIdsAtVendor} />
                </div>
                <div className='col-md-2 text-end'>
                    {selectedIds.length != 0 && (<img src="/images/print_ticket_details_i.svg" style={{ height: '24px' }} title='Print Ticket Details' className="img-fluid cursor-pointer px-1" onClick={PrintTicketDetails} />)}
                    {storedSearchValue != undefined && (
                        <img src="/images/create_ticket_i.svg" style={{ height: '24px' }} title='Create Ticket' className="img-fluid cursor-pointer px-1" onClick={(e) => navigate('/create-ticket')} />
                    )}
                    {storedSearchValue != undefined && (
                        <img src="/images/create_device_i.svg" style={{ height: '20px' }} title='Create Device' className="img-fluid cursor-pointer px-1" onClick={(e) => navigate('/addupdate-device')} />
                    )}
                </div>
            </div>
            <div className="GridBox mt-2 p-3">
                <div className="greyBox p-3">
                    {storedSearchValue == undefined ?
                        <div className='text-center'>
                            <h1 className="PageHeading text-center mt-1" style={{ fontSize: "30px" }}>Please select school from the search box above to get going!</h1>
                            <img src="/images/SchoolTicketVector.svg" className="img-fluid"></img>
                        </div>
                        : <>
                            <section>
                                <div className="row align-items-center">
                                    <div className="col-md-5 pe-0">
                                        <ul className="nav nav-tabs">
                                            <Tab isActive={TicketsTabs.sentOutForRepair == 'active'}
                                                label="All Open Tickets" onClick={() => handleTabClick('open')} col={'col-md-5'} />
                                            <Tab isActive={TicketsTabs.pendingTicketTab == 'active'}
                                                label="Repaired Tickets" onClick={() => handleTabClick('repaired')} col={'col-md-5'} />
                                        </ul>
                                    </div>
                                    <div className='col-md-4 px-0'>
                                        {TicketsTabs.numberOfTicketCount != 0 && (
                                            <p className='mb-0'>Total <b>{TicketsTabs.numberOfTicketCount}</b> tickets selected with a total of <b>${TicketsTabs.numberofSubtotal}</b>.</p>
                                        )}
                                    </div>
                                    <div className='col-md-3 marginDetailsPopup'>
                                        {tabflag == 3 &&
                                            <div className='text-end'>
                                                <select onChange={(e) => { setSelectBuildingID(e.target.value) }} value={SelectBuildingID}  >
                                                    <option value='null'>Filter by Building</option>
                                                    {BuildingNames.map((item, i) => {
                                                        var returData;
                                                        returData = (
                                                            <option value={item?.return_location?.id} key={i}>{item?.return_location?.name} </option>
                                                        );
                                                        return returData;
                                                    })}
                                                </select>
                                            </div>
                                        }
                                        {tabflag == 1 && (
                                            <div className='col-md-12 pe-0'>
                                                <select
                                                    value={selectedbatchid}
                                                    onChange={(e) => {
                                                        GetGridData(TicketsTabs.searchForSentOutForRepair, tabflag, e.target.value, null);
                                                        setselectedbatchid(e.target.value);
                                                        setTicketsTabs({ ...TicketsTabs, numberOfTicketCount: 0, numberofSubtotal: 0 });
                                                        setSelectedIdsOpen1([]);
                                                        setSelectedIdsPedning1([]);
                                                        setSelectedIdsSentOut1([])
                                                    }}>
                                                    <option value="0">Incoming Batches</option>
                                                    <option value="0">All</option>
                                                    {Batches.map((item, i) => {
                                                        if (item.hide == true) {
                                                            return null;
                                                        }
                                                        return <option key={i} value={item.id}>{item.batch_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row align-items-center mt-3">
                                    <div className='col-md-8 d-flex'>
                                        {TicketsTabs.sentOutForRepair == 'active' && (
                                            <>
                                                {[
                                                    { label: "At Vendor Tickets", flag: 4 },
                                                    { label: "Sent Out For Repair Tickets", flag: 1, selectedbatchid: selectedbatchid },
                                                    { label: "Open Tickets", flag: 2 }
                                                ].map((item, index) => (
                                                    <div key={index} className={`form-check ${index == 1 ? 'mx-4' : ''}`}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name='MasterInventory'
                                                            onChange={() => handleRadioChange(item.flag, item.selectedbatchid)}
                                                            defaultChecked={item.flag == 4}
                                                        />
                                                        <label className="form-check-label">{item.label}</label>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                        {tabflag == 3 && (
                                            <div className='redText col-12 pt-2' style={{ fontSize: '12px' }}>* Tickets are already included in the billing batches</div>
                                        )}
                                    </div>
                                    <div className='col-md-2 text-end marginDetailsPopup'>
                                        {selectedIds.length != 0 &&
                                            <TicketStatusDropdown selectedbatchid={selectedbatchid} selectedIds={selectedIds}
                                                setSelectedIdsOpen={setSelectedIdsOpen} setSelectedIdsPedning={setSelectedIdsPedning} setSelectedIdsSentOut={setSelectedIdsSentOut} setSelectedIdsAtVendor={setSelectedIdsAtVendor}
                                                setSelectedIdsOpen1={setSelectedIdsOpen1} setSelectedIdsPedning1={setSelectedIdsPedning1} setSelectedIdsSentOut1={setSelectedIdsSentOut1} setSelectedIdsAtVendor1={setSelectedIdsAtVendor1}
                                                GetGridData={GetGridData} GridFlag={5} schoolId={schoolId} tabflagforadmin={tabflag} />
                                        }
                                    </div>
                                    <div className="col-md-2 text-end ps-2 marginDetailsPopup">
                                        {IsModal.CreateBatchbuttonVisible == true && (
                                            <button className="BorderBtn p-2 col-12" id='createdBatchbutton' onClick={CreateBatchPopupShow}><img src="/images/AddInventory.svg" className="img-fluid pe-2" />Create Batch </button>
                                        )}
                                    </div>
                                </div>


                            </section>
                            <div className="row px-3">
                                <div className="greyBox px-0 pt-1">
                                    <div className="Header row align-items-center">
                                        <div className="col-12 data-table-container">
                                            {tabflag == 1 ?
                                                <>{renderTable(GridDataForSentOutTicket, selectedIdsSentOut, showPagination2, "sentout")}  </>
                                                : tabflag == 2 ?
                                                    <>  {renderTable(GridDataForOpen, selectedIdsOpen, showPagination, "open")}  </>
                                                    : tabflag == 3 ?
                                                        <>  {renderTable(GridDataForPending, selectedIdsPedning, showPagination1, "pending")} </>
                                                        : tabflag == 4 ?
                                                            <> {renderTable(GridDataForAtVendor, selectedIdsAtVendor, showPagination3, "atvendor")} </>
                                                            : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            {IsModal.isTicketDetailspopup && <TicketDetailsPopup isShow={IsModal.isTicketDetailspopup} ticketid={TicketParams.ticketId} inventoryid={TicketParams.inventoryId} ClosePopup={(e) => setIsModal({ ...IsModal, isTicketDetailspopup: false })} BatchNmFlag={1} />}
            {IsModal.isshowpopup && <DeviceDetailsPopup Showbuttons={0} createticketbutton={false} deviceid={TicketParams.inventoryId} activetab="device" isShow={IsModal.isshowpopup} ClosePopup={(e) => setIsModal({ ...IsModal, isshowpopup: false })} />}
            {IsModal.CreateBatchmodal && <AdminCreateBatch isShow={IsModal.CreateBatchmodal} IsModal={IsModal} setIsModal={setIsModal} schoolId={schoolId} selectedItems={selectedItems} setselectedbatchid={setselectedbatchid} GetGridData={GetGridData} tabflag={tabflag} batchid={batchid} setSelectedIdsOpen={setSelectedIdsOpen} setSelectedIdsPedning={setSelectedIdsPedning} setSelectedIdsSentOut={setSelectedIdsSentOut} />}
            {IsModal.isTicketStatuspopup && <TicketStatuspopup isShow={IsModal.isTicketStatuspopup} StudentidForSatuschange={StudentidForSatuschange} selectedbatchid={selectedbatchid} ClosePopup={(e) => setIsModal({ ...IsModal, isTicketStatuspopup: false })} flag={tabflag} TicketId={TicketParams.ticketId} GridFlag={5} GetGridData={GetGridData} schoolId={schoolId} TicketStatusId={IsModal.TicketStatusId} />}
            {CustomizationModal && <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={"School Tickets"} GetCall={() => ApiGetHeaders(26, setColumnNames)} />}
            {UserModal && <UserDetailsPopup isShow={UserModal} ClosePopup={(e) => setUserModal(false)} userid={Userid} />}
        </React.Fragment>
    )
}
