import { faDesktop, faFileInvoice, faTicket, faTruckFast, faUsers, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function GreyBoxForReports({ icon, name, onclickfunc }) {
    function getIcons() {
        switch (icon) {
            case 'faTicket':
                return faTicket;
            case 'faDesktop':
                return faDesktop;
            case 'faUsers':
                return faUsers;
            case 'faUsersGear':
                return faUsersGear;
            case 'faTruckFast':
                return faTruckFast;
            case 'faFileInvoice':
                return faFileInvoice;
        }
    }

    return (
        <div className='greyBoxShadow text-center py-4 h-100'>
            <FontAwesomeIcon icon={getIcons()} style={{ height: "40px" }} />
            <h6 className='my-2'>{name}</h6>
            <label className='BorderBtn mt-3 me-0' onClick={onclickfunc}>
                <img src='/images/downloadPlan.svg' className='img-fluid pe-2' />  Download
            </label>
        </div>
    )
}
