import React, { useState, useLayoutEffect, useContext } from 'react'
import { TableDataContext } from '../index';
export default function DueTable(props) {
    const { AllDashBoardData } = useContext(TableDataContext);
    const [loadData, setLoadData] = useState({ data: [], show: false })
    useLayoutEffect(() => {
        if (AllDashBoardData?.DueInvoice?.data?.length > 0) {
            let Data = AllDashBoardData?.DueInvoice?.data
            if (Data?.length > 20) {
                setLoadData({ ...loadData, data: [].concat(Data.slice(0, 20)) })
            } else {
                setLoadData({ ...loadData, data: Data })
            }
        }
    }, [AllDashBoardData?.DueInvoice?.data])
    const LoadMore = () => {
        let newData = []
        if (loadData?.data?.length != AllDashBoardData?.DueInvoice?.data?.length) {
            newData = loadData?.data?.concat(AllDashBoardData?.DueInvoice?.data?.slice(loadData?.data?.length, loadData?.data?.length + 20));
        } else {
            newData = [].concat(AllDashBoardData?.DueInvoice?.data?.slice(0, 20))
        }
        setLoadData({ ...loadData, data: newData });
    }

    return (
        <React.Fragment>
            <div className="col-12  greyBoxShadow" style={{ height: "100%" }}>
                <div className="pb-5 w-100">
                    <div className="row mt-2 px-2 time" >
                        <div className='Header'>
                            <b className='font-15'>Due Invoices</b><br />
                        </div>
                    </div>
                    <hr className="my-2" />
                    {AllDashBoardData?.DueInvoice?.loader ? <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div> : <>
                        <table className="table data-table">
                            <thead className='GridHeader'>
                                <tr>

                                    <th scope="col" className='fw-600 font-14'>Invoice #</th>
                                    <th scope="col" className='fw-600 font-14' >Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AllDashBoardData?.DueInvoice?.data?.length > 0 ?
                                    loadData?.data?.map((item, i) => (
                                        <tr className='OddEvenClass tableBorderHide py-3' key={item?.id}>
                                            <td>{item?.InvoiceNum}</td>
                                            <td>${item?.amount}</td>
                                        </tr>
                                    )) :
                                    <tr className='OddEvenClass tableBorderHide py-3'>
                                        <td colSpan={4} className='text-center'> No Record Found</td>
                                    </tr>

                                }
                            </tbody>
                        </table></>}

                    {
                        AllDashBoardData?.DueInvoice?.data?.length > 20 && <div className="col-12 p-2 admin-table-border-top">
                            <span style={{ textDecoration: "underline", cursor: "pointer", color: "#3CBBA5" }} onClick={() => LoadMore()}> {loadData?.data?.length == AllDashBoardData?.DueInvoice?.data?.length ? "Hide" : "Load More"}</span>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
