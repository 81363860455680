import React, { useEffect, useState } from 'react'
import { ApiGetCall } from '../../JS/Connector';

function SSInfoOnly() {
    const [AllData, setAllData] = useState([])
    useEffect(() => {
        ApiGetCall("/getOverAllData").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setAllData(responseRs)
            }
        });
    }, [])
    return (
        <div className="row">
            <span>Total schools -<b>{AllData?.totalschools}</b></span>
            <span>Total active schools -<b>{AllData?.totalactiveschools}</b></span>
            <span>Total active technicians -<b>{AllData?.totalactivetechnicians}</b></span>
            <span>Total incoming devices -<b>{AllData?.totalincomingdevices}</b></span>
            <span>Total outgoing devices -<b>{AllData?.totaloutgoingdevices}</b></span>
            <span>Total domains -<b>{AllData?.totaldomains}</b></span>
            <span>Total active domains -<b>{AllData?.totalactivedomains}</b></span>
            <span>Total locations -<b>{AllData?.totallocations}</b></span>
            <span>Total active locations -<b>{AllData?.totalactivelocations}</b></span>
            <span>Total active master inventory -<b>{AllData?.totalactivemasterinventory}</b></span>
            <span>Highest tickets in which school and how many? -<b>{AllData?.highestTicket?.name} -{AllData?.highestTicket?.ticket_count}</b></span>
            <span>Highest devices in which school and how many? -<b>{AllData?.highestDevice?.name} -{AllData?.highestDevice?.device_count}</b></span>
            <span>Total count of technology support tickets across all schools. -<b>{AllData?.technologysupportticket}</b></span>
            <span>Total count of maintenance  support tickets across all schools. -<b>{AllData?.totalmaintencesupporttickets}</b></span>
            <span>Total count of software across all schools. -<b>{AllData?.totalsoftwares}</b></span>
            <span>Total count of staff members across all schools. -<b>{AllData?.totalStaffmembers}</b></span>
        </div>

    )
}

export default SSInfoOnly