import ReactApexChart from "react-apexcharts";

export function TicketByIssueGraph({ TicketByIssue }) {
    var vData = TicketByIssue?.data[0]
    var series = [{
        data: vData?.map(item => item?.count)
    }];
    var vLabels = vData?.map(item => item?.name);
    var colors = ['#267dc3', '#26e7a5', '#febb3b', '#ff6077', '#8b75d7', '#6d838d', '#46b3a9', '#d730eb']
    var options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            }
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: vLabels,
            labels: {
                style: {
                    colors: colors,
                    fontSize: '12px'
                }
            }
        },
        tooltip: {
            y: {
                formatter: function (val, { dataPointIndex }) {
                    var amount = vData[dataPointIndex].amount;
                    return "Amount: $" + amount.toFixed(2) + "<br/>Total Tickets: " + val;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    }
                }
            }
        },

    };
    return (

        <div className="col-md-6 col-12 p-3  text-center" style={{ height: "100%" }}>
            <div className="greyBoxShadow w-100">
                <div className="row mt-2 px-2 time">
                    <div className='Header'>
                        <b className='font-16'>Tickets by Issues</b><br />
                    </div>
                </div>
                {TicketByIssue?.loader ?
                    <><div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div></>
                    : <>
                        <div className="py-5">
                            <ReactApexChart options={options} series={series} type="bar" height={273} />
                        </div>
                    </>}


            </div>
        </div>
    )
}
