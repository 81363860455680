import { useGoogleLogin } from '@react-oauth/google';
import React from 'react'

export default function GoogleImportButton({ onSuccess, text }) {
    const login = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: tokenResponse => { onSuccess(tokenResponse) },
        onFailure: error => { console.log(error) },
        accessType: 'offline',
        responseType: "code",
        prompt: 'consent',
        scope: 'https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly https://www.googleapis.com/auth/admin.reports.audit.readonly',
        fetchBasicProfile: true,
    });
    return (
        <>
            <button onClick={() => login()} className="MicrosoftGoogleBtn">
                <div className=""><img src="/images/GoogleBtn.svg" className="img-fluid pe-2" /> {text}</div>
                <span className='ms-3'>BETA</span>
            </button>
        </>
    )
}
