import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { Admindashboard } from '../AdminDashboard/admindashboard';
import { IndexSchools } from '../Schools';
import { SchoolTickets } from '../SchoolTickets';
import RepairDetails from '../../Components/RepairDetails';
import { AdminBilling } from '../Billingandtickets/AdminBilling';
import TicketHistoryPage from '../Billingandtickets/TicketHistoryPage';
import { FaqIndex } from '../FAQ';
import { ErrorPage } from '../404Error';
import { CreateTicket } from '../CreateTicket';
import { AddUpdate } from '../ManageDevice/addupdate';
import { ReportsIndex } from '../AdminReports';
export default function Access6() {
    return (
        <Routes>
            <Route exact path="/" element={<Admindashboard />} />
            <Route exact path="/dashboard" element={<Admindashboard />} />
            <Route exact path="/schools" element={<IndexSchools />} />
            <Route exact path="/school-tickets" element={<SchoolTickets />} />
            <Route exact path="/repair-details/*" element={<RepairDetails />} />
            <Route exact path="/admin-invoice" element={<AdminBilling />} />
            <Route exact path="/ticket-history" element={<TicketHistoryPage />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
            <Route exact path="*" element={<ErrorPage />} />
            <Route exact path="/create-ticket" element={<CreateTicket />} />
            <Route path="/addupdate-device/*" element={<AddUpdate />} />
            <Route exact path="/reports" element={<ReportsIndex />} />
        </Routes>
    )
}
