import React, { useContext } from 'react'
import { ReportDataContext } from '../index';
import { UserContext } from '../../../App';

export default function MaintenanceIndex(props) {
    const { AllReportData } = useContext(ReportDataContext);
    const { UserData } = useContext(UserContext)
    const downloadCsv = () => {
        const csvContent = [];
        AllReportData?.MaintenanceTotalTicketsData?.data?.forEach(items => {
            {
                UserData.helpdeskUpdatedFlag != null ?
                    csvContent.push([`Ticket Category: ${items.name}`])
                    : csvContent.push([`Ticket Type: ${items.name}`]);
            }
            csvContent.push([`Total Opened Tickets: ${items.open_ticket_count}`]);
            csvContent.push([`Total Closed Tickets: ${items.close_ticket_count}`]);
            csvContent.push([`Average Resolution Time (in hours): ${items.average_resolution_time}`]);
            if (items.ticketdata.length > 0) {
                csvContent.push(['Student No', 'Name', 'Email', 'Ticket Status', 'Title', 'Description']);
                items.ticketdata.forEach(details => {
                    const StudentNum = details.student_no;
                    const Title = details.title;
                    const Name = details.name;
                    const Email = details.email;
                    const Description = details.description;
                    const Status = details.status == 1 ? 'Open' : 'Closed'
                    csvContent.push([StudentNum, Name, Email, Status, Title, Description]);
                });
            }
            csvContent.push([]);
        });
        const csvString = csvContent.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Maintenance Support Ticket details.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div className='row'>
            <div className='col-md-12  py-2'>
                <div className="text-center h-100 greyBox">
                    <div className="pb-5 w-100">
                        <div>
                            <h4>Maintenance {UserData.helpdeskUpdatedFlag != null ? 'Helpdesk' : 'Support'} Tickets Details</h4>
                        </div>
                        {AllReportData?.MaintenanceTotalTicketsData?.data?.length != 0 && (
                            <div className='justify-content-center d-flex mb-3'>
                                <label className='cursor-pointer d-flex align-items-center' onClick={downloadCsv} style={{ fontSize: "12px" }}>
                                    <img src="/images/downloadPlan.svg" className="pe-1" title='Download CSV' height='10px' />
                                    <label className='cursor-pointer'>Download Detailed Reports</label>
                                </label>
                            </div>
                        )}
                        <div className="p-3 data-table-container">
                            {AllReportData?.MaintenanceTotalTicketsData?.loader == true ?
                                <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div>
                                :
                                <table className="table data-table">
                                    <thead className='GridHeader'>
                                        <tr>
                                            <th scope="col" className='fw-600'>{UserData.helpdeskUpdatedFlag != null ? 'Ticket Category' : 'Ticket Type'}</th>
                                            <th scope="col" className='fw-600'>Total Opened Tickets</th>
                                            <th scope="col" className='fw-600'>Total Closed Tickets</th>
                                            <th scope="col" className='fw-600'>Average Resolution Time (in hours)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {AllReportData?.MaintenanceTotalTicketsData?.data?.length != 0 ?
                                            AllReportData?.MaintenanceTotalTicketsData?.data?.map((item, i) => {
                                                return <tr key={i} className="OddEvenClass tableBorderHide">
                                                    <td scope="col" className='py-12 text-start'>{item.name} </td>
                                                    <td scope="col" className='py-12 text-start'>{item.open_ticket_count} </td>
                                                    <td scope="col" className='py-12 text-start'>{item.close_ticket_count} </td>
                                                    <td scope="col" className='py-12 text-start'>{item.average_resolution_time.toFixed(0)} </td>
                                                </tr>
                                            })
                                            :
                                            <tr className="text-center"> <td colSpan={15}>No Record Found</td></tr>
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
