import { useState, useEffect } from "react";
import { MicrosoftLoginForm } from "./MicrosoftLogin";
import { RemoveAllCookies } from "../../JS/Common";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import GoogleOauth from "../../Components/GoogleOauth";
export function Login() {
    const [AlertMsgs, setAlertMsgs] = useState("");
    const [AlertColor, setAlertColor] = useState("");
    useEffect(() => {
        RemoveAllCookies();
    }, []);
    return (
        <>
            <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
            <div className="position-relative MainDiv">
                <div className="card mx-auto">
                    <div className="p-4 row">
                        <div className="col-12 text-center">
                            <div style={{ color: `${AlertColor}` }} className="font-16" dangerouslySetInnerHTML={{ __html: AlertMsgs }} />
                        </div>
                        <div className="my-4">
                            <img src="/images/NewLogo.svg" className="img-fluid" alt="Logo" />
                        </div>
                        <div className="col-md-8 offset-md-2 text-center mt-3">
                            <GoogleOauth alertmsg={setAlertMsgs} alertcolor={setAlertColor} flag="signin" />
                        </div>
                        <div className="col-12 text-center py-3">
                            <img src="/images/NewLoginOr.svg" className="img-fluid" />
                        </div>
                        <div className="col-md-8 offset-md-2 text-center">
                            <MicrosoftLoginForm alertmsg={setAlertMsgs} alertcolor={setAlertColor} />
                        </div>
                        <div className="row">
                            <div className="col-md-6 pt-5 font-13">
                                Register for our demo <a className="cursor-pointer" style={{ color: "rgb(30 191 162)" }} href="https://k12tech.clickmeeting.com/k12tech-rocket-demo/register" target="_blank">Here</a>
                            </div>
                            <div className="col-md-6  pt-5 font-13">
                                Need an account?<a className="ps-2 cursor-pointer" style={{ color: "rgb(30 191 162)" }} href="/register">Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
