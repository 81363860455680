import React from 'react'
import ReactApexChart from "react-apexcharts";
export default function TicketsPerRepair({ TicketsPerDevice }) {
    var state = {}
    if (TicketsPerDevice?.data?.length > 0) {
        if (TicketsPerDevice?.data[0]?.counts != undefined && TicketsPerDevice?.data[0]?.series != undefined) {
            var ticketValue = TicketsPerDevice?.data[0]?.counts?.map((value) => parseFloat(value?.toFixed(2)));
            state = {
                series: TicketsPerDevice?.data[0]?.series?.map((value) => parseFloat(value?.toFixed(2))),
                options: {
                    chart: {
                        type: 'pie',
                    },
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                style: {
                                    fontSize: '12px',
                                },
                            },
                        },
                    },
                    tooltip: {
                        custom: function ({ series, seriesIndex, w }) {
                            const labels = ['Chrome Book', 'Windows Device', 'iPad', 'MacBook', 'iPad Frame Swap', 'Warranty Chromebook Repair', 'others'];
                            const label = labels[seriesIndex];
                            const tooltipValue = ticketValue[seriesIndex];

                            return `<div class="p-2">${label}: ${tooltipValue} tickets</div>`;
                        },
                    },
                    colors: ["#3CBBA5", "#FFD700", "#FF6347", "#00FFD3", "#ffa5a5", "#151821", "#C3C3C3"],
                    labels: [`Chrome Book (${ticketValue[0]})`, `Windows Device (${ticketValue[1]})`, `iPad (${ticketValue[2]})`, `MacBook (${ticketValue[3]})`, `iPad Frame Swap (${ticketValue[4]})`, `Warranty Chromebook Repair (${ticketValue[5]})`, `others (${ticketValue[6]})`],
                    responsive: [
                        {
                            breakpoint: 200,
                            options: {
                                chart: {
                                    height: '300',
                                },
                                legend: {
                                    position: 'bottom',
                                    horizontalAlign: 'center',
                                    fontSize: '10px',
                                },
                            },
                        },
                        {
                            breakpoint: 6000,
                            options: {
                                chart: {
                                    height: '320',
                                },
                                legend: {
                                    position: 'bottom',
                                },
                            },
                        },
                    ],
                    legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        fontSize: '10px',


                    },
                },


            };
        }
    }
    return (
        <div className="col-md-6 col-12 p-3 text-center" style={{ height: "100%" }}>
            <div className="greyBoxShadow w-100">
                <div className="row mt-2 px-2 time">
                    <div className='Header'>
                        <b className='font-16'>Tickets Per Device</b><br />
                    </div>
                </div>
                {TicketsPerDevice?.loader ?
                    <><div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div></>
                    : TicketsPerDevice?.data[0]?.series?.every(item => item === 0) ? <>
                        <div className="fw-bold py-3">Yahoo, there were no tickets</div>
                    </> : <>
                        <div className="py-5">
                            {TicketsPerDevice?.data?.length > 0 && <ReactApexChart options={state?.options} series={state?.series} type="pie" />}
                        </div>
                    </>}


            </div>
        </div>
    )
}
