import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap';
import { ApiGetCall } from '../../../JS/Connector';
import { MMDDYYYY } from '../../../JS/Common';

export default function IncomingDeviceTable(props) {
    const [AllData, setAllData] = useState({
        incomming: { data: [], pagination: {}, loader: false },
    });
    useEffect(() => {
        setAllData({ incomming: { data: props?.incommingData?.data?.data, pagination: props?.incommingData?.data?.pagination, loader: props?.incommingData?.loader } })
    }, [props?.incommingData])
    const [currentPageForIncoming, setCurrentPageForIncoming] = useState(1);
    const totalPages = AllData?.incomming?.pagination?.last_page || 0
    const showPagination = AllData?.incomming?.data?.length > 0;
    const handlePageChange = (pageDir) => {
        let plusMinus = currentPageForIncoming;
        if (pageDir === 'next') {
            plusMinus += 1;
        } else {
            plusMinus -= 1;
        }
        setAllData({ ...AllData, incomming: { ...AllData?.incomming, loader: true } })
        var locationid = props.LocationValue == 0 ? null : props.LocationValue;
        var schoolvalue = props.SchoolValue == 0 ? null : props.SchoolValue;
        ApiGetCall("/getIncomingDevices/" + MMDDYYYY(props.startDate) + "&" + MMDDYYYY(props.endDate) + "&" + locationid + "&" + schoolvalue + '&' + plusMinus).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setAllData({ ...AllData, incomming: { ...AllData?.incomming, data: responseRs.incomming?.data, pagination: responseRs?.incomming?.pagination, loader: false }, })
            }
        });
        setCurrentPageForIncoming(plusMinus)
    }
    return (
        <React.Fragment>
            <div className="col-md-6 p-3" >
                <div className="greyBox" style={{ height: "100%" }}>
                    <h5 className="fw-bold ps-2 mb-0">Incoming Devices</h5>
                    <hr className="my-2" />

                    <div className="w-100 px-2">
                        <div className="row py-3">
                            <div className="col-md-8 col-4 fw-600">School</div>
                            <div className="col-md-2 col-4 fw-600">Devices</div>
                            <div className="col-md-2 col-4 fw-600">Amount</div>
                        </div>
                        {props?.incommingData?.loader || AllData?.incomming?.loader ? <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div> :
                            <>
                                {AllData?.incomming?.data?.length != 0 ?
                                    AllData?.incomming?.data?.map((item, i) => {
                                        return <>
                                            <div className="row py-2 admin-table-border-top" key={i}>
                                                <div className="col-md-8 col-4">{item.schoolName}</div>
                                                <div className="col-md-2 col-4">{item.ticketCount}</div>
                                                <div className="col-md-2 col-4 wordwrap">${item.amount.toFixed(2)}</div>
                                            </div>
                                        </>

                                    })
                                    :
                                    <div className="col-12 text-center p-2 admin-table-border-top">
                                        No Record Found
                                    </div>
                                }
                            </>
                        }
                        {showPagination && (
                            <Pagination>
                                {currentPageForIncoming != 1 ?
                                    <FontAwesomeIcon icon={faAngleLeft} className='cursor-pointer mx-3' onClick={() => { handlePageChange('prev') }} style={{ height: '20px' }} />
                                    :
                                    <FontAwesomeIcon icon={faAngleLeft} className='mx-3' style={{ height: '20px', opacity: '0.3' }} />
                                }
                                {currentPageForIncoming < totalPages ?
                                    <FontAwesomeIcon icon={faAngleRight} className='cursor-pointer mx-3' onClick={() => { handlePageChange('next') }} style={{ height: '20px' }} />
                                    :
                                    <FontAwesomeIcon icon={faAngleRight} className='mx-3' style={{ height: '20px', opacity: '0.3' }} />
                                }
                            </Pagination>
                        )}
                    </div>

                </div>
            </div></React.Fragment>
    )
}
