import { faAngleDown, faAngleUp, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import { questiondetailscontext } from './HelpdeskTicket';
import TextEditor from './TextEditor';
import { convertToBase64 } from '../../JS/Common';

export default function Issues(props) {
    const { HelpdeskDetails, setHelpdeskDetails } = useContext(questiondetailscontext);
    const [errorMessage, setErrorMessage] = useState('');
    const [noteVisibility, setNoteVisibility] = useState(true);
    const handleArrowClick = () => {
        setNoteVisibility(!noteVisibility);
    }
    const handleInputIssueChange = (e, index) => {
        const { name, value } = e.target;
        const updatedIssues = [...props.issues];
        updatedIssues[index].value = value;
        setHelpdeskDetails(prevState => ({
            ...prevState,
            SubCategory: prevState.SubCategory.map((subCategory, subIndex) => {
                if (subIndex == props.subCategoryIndex) {
                    return {
                        ...subCategory,
                        issues: updatedIssues
                    };
                }
                return subCategory;
            })
        }));
    }
    const AddMoreIssues = (subCategoryIndex) => {
        const updatedIssues = [...props.issues];
        const isAnyBlank = props.issues.some(input => input.value.trim() === '');
        if (isAnyBlank) {
            setErrorMessage('Please fill all inputs.');
            return;
        }
        const isDescriptionblank = props.issues.some(input => input.type == 1 && !input.description);
        if (isDescriptionblank) {
            setErrorMessage('Please make sure to provide a description.');
            return;
        }
        updatedIssues.push({ value: '', description: '', icon: '' });
        setHelpdeskDetails(prevState => ({
            ...prevState,
            SubCategory: prevState.SubCategory.map((subCategory, subIndex) => {
                if (subIndex === subCategoryIndex) {
                    return {
                        ...subCategory,
                        issues: updatedIssues
                    };
                }
                return subCategory;
            })
        }));
        setErrorMessage('');
    }
    const handleDeleteIssue = (indexToDelete) => {
        const updatedIssues = props.issues.filter((_, index) => index !== indexToDelete);
        setHelpdeskDetails(prevState => ({
            ...prevState,
            SubCategory: prevState.SubCategory.map((subCategory, subIndex) => {
                if (subIndex == props.subCategoryIndex) {
                    return {
                        ...subCategory,
                        issues: updatedIssues
                    };
                }
                return subCategory;
            })
        }));
    };
    async function handleFileUpload(e, subCategoryIndex, issueIndex) {
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            alert("File size exceeds 2MB limit.");
            return;
        }
        const base64 = await convertToBase64(file);
        const updatedSubCategory = [...HelpdeskDetails.SubCategory];
        updatedSubCategory[subCategoryIndex].issues[issueIndex].icon = base64;
        setHelpdeskDetails(prevState => ({
            ...prevState,
            SubCategory: updatedSubCategory
        }));
    };
    const handleRemoveUploadedIcon = (subCategoryIndex, issueIndex) => {
        const updatedSubCategory = [...HelpdeskDetails.SubCategory];
        updatedSubCategory[subCategoryIndex].issues[issueIndex].icon = '';
        setHelpdeskDetails(prevState => ({
            ...prevState,
            SubCategory: updatedSubCategory
        }));
    };

    return (
        <div className='row pt-4 px-5'>
            <div className='AnswerBox p-0'>
                <div className='p-2'>
                    <div className='row'>
                        <div className='col-md-11 fw-600 font-13 ps-4'>
                            Add Issues
                        </div>
                        <div className='col-md-1 text-end'>
                            <FontAwesomeIcon icon={faAngleDown} title="Show More Details" className={`cursor-pointer ${noteVisibility ? 'd-none' : ''}`} onClick={handleArrowClick} />
                            <FontAwesomeIcon icon={faAngleUp} title="Show More Details" className={`cursor-pointer ${noteVisibility ? '' : 'd-none'}`} onClick={handleArrowClick} />
                        </div>
                    </div>
                </div>
                {noteVisibility &&
                    <div className='admin-table-border-top'>
                        <div className='row px-4'>
                            {props.issues.map((issue, index) => (
                                <div className='row py-3' key={index}>
                                    <div className='col-md-1 px-0'>
                                        <label className='fw-500 FormLabel'>Issue {index + 1}: </label>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='col-12'>
                                            <input
                                                type="text"
                                                autoComplete='off'
                                                className="form-control"
                                                placeholder={`Enter Issue...`}
                                                value={issue.value}
                                                onChange={(e) => handleInputIssueChange(e, index)}
                                                subindex={index}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-2 ps-2 px-0'>
                                        {issue.icon ?
                                            <div className='d-flex align-items-center ps-3'>
                                                <img src={issue.icon} alt="" className='categoryimg-icon' />
                                                <FontAwesomeIcon className='ps-2' icon={faTimes} style={{ cursor: 'pointer' }} onClick={(e) => handleRemoveUploadedIcon(props.subCategoryIndex, index)} title='Remove Uploaded Icon' />
                                            </div>
                                            :
                                            <form className="col-12">
                                                <input type="file" label="Image" name={`UploadIssueName-${props.subCategoryIndex}-${index}`} accept=".jpg,.png,.svg,.jpeg" id={`UploadIssueId-${props.subCategoryIndex}-${index}`} onChange={(e) => handleFileUpload(e, props.subCategoryIndex, index)} />
                                                <label className="BorderBtn font-12 me-0" htmlFor={`UploadIssueId-${props.subCategoryIndex}-${index}`}><img src='/images/AddInventory.svg' className='img-fluid pe-1' style={{ height: "10px" }} /> Upload Icon</label>
                                            </form>
                                        }
                                    </div>
                                    <div className='col-md-1'>
                                        <img className="img-fluid cursor-pointer mt-1" src="/images/CancelIcon.svg" title='Delete Issue' onClick={(e) => handleDeleteIssue(index)} />
                                    </div>
                                    <div className='col-md-11 offset-md-1 pt-2'>
                                        <TextEditor identifier="Issuess" subindex={index} subcategoryindex={props.subCategoryIndex} />
                                    </div>
                                </div>
                            ))}
                            {errorMessage && <div className='col-md-9 offset-md-2 pt-2'><p style={{ color: 'red' }}>{errorMessage}</p></div>}
                            <div className='col-md-3 offset-md-2 py-3'>
                                <button className='SaveBtn align-items-center py-1 px-2 font-12' onClick={() => AddMoreIssues(props.subCategoryIndex)}>
                                    <img src='/images/white_plus.svg' className='pe-2' style={{ height: "20px" }} />Add More</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
