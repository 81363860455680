import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ApiGetCallAuthKey } from "../../JS/Connector";
import { HideLoder, MMDDYYYY, ShowLoder } from "../../JS/Common";
import { saveAs } from 'file-saver'
export function Receipt() {
    const [loading, setLoading] = useState(false);
    const [AllData, setAllData] = useState([]);
    const location = useLocation();
    const customerid = location.state && location.state.custid;
    const schoolid = location.state && location.state.schoolidReceipt;
    const planid = location.state && location.state.planid;
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    useEffect(() => {
        setLoading(true);
        ApiGetCallAuthKey("/getstripedata/" + customerid + '&' + schoolid + '&' + planid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setAllData(responseRs.length ? responseRs : [])
            }
        });
    }, []);

    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className={window.innerWidth >= 767 ? 'm-3 px-1' : ''}>
                        <div className="col-12 text-center py-3">
                            <img src="/images/LoginLogo.png" className="img-fluid" alt="Logo" />
                        </div>
                        <div className={window.innerWidth >= 767 ? 'GridBox p-4 px-5 m-auto mx-5' : 'GridBox p-4 px-5 m-auto'}>
                            <div className="row d-flex pt-3">
                                <div className="col-md-10">
                                    <div className="col-12 py-1">
                                        <h4 className="mb-0" style={{ color: "gray" }}>Receipts from Stripe</h4>
                                    </div>
                                    <div className="col-12 py-1">
                                        <h1 className="fw-600 mb-0">${AllData.total}</h1>
                                    </div>
                                    <div className="col-12 py-1">
                                        <h4 className="mb-0" style={{ color: "gray" }}>Paid {MMDDYYYY(AllData.payment_success_date)}</h4>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <FontAwesomeIcon icon={faFileInvoice} style={{ color: "#e1e1e1", height: "100px" }} className="img-fluid" />
                                </div>
                            </div>
                            <hr />
                            <div className="col-12 pb-4 pt-1">
                                <label title="Download Receipt" className="cursor-pointer d-flex align-items-center justify-content-center" onClick={(e) => saveAs(BucketS3Link + AllData.url)}>
                                    <FontAwesomeIcon icon={faDownload} style={{ color: "#878484", height: "20px" }} className="img-fluid" />
                                    <span className="ms-3" style={{ color: "#878484", fontWeight: "600", fontSize: "20px" }}>Download Receipt</span>
                                </label>
                            </div>
                            <div className="col-12">
                                <div className="row py-2 font-16">
                                    <div className="col-md-6">
                                        <span style={{ color: "#878484", fontWeight: "600", fontSize: "15px" }}>Plan Name</span>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span style={{ fontSize: "15px" }}>{AllData.plan}</span>
                                    </div>
                                </div>
                                <div className="row py-2 font-16">
                                    <div className="col-md-6">
                                        <span style={{ color: "#878484", fontWeight: "600", fontSize: "15px" }}>Student Number</span>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span style={{ fontSize: "15px" }}>{AllData.student_number}</span>
                                    </div>
                                </div>
                                <div className="row py-2 font-16">
                                    <div className="col-md-6">
                                        <span style={{ color: "#878484", fontWeight: "600", fontSize: "15px" }}>Customer Id</span>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span style={{ fontSize: "15px" }}>{AllData.customer_id}</span>
                                    </div>
                                </div>
                                <div className="row py-2 font-16">
                                    <div className="col-md-6">
                                        <span style={{ color: "#878484", fontWeight: "600", fontSize: "15px" }}>Invoice Number</span>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span style={{ fontSize: "15px" }}>{AllData.invoice_number}</span>
                                    </div>
                                </div>
                                <div className="row py-2 font-16">
                                    <div className="col-md-6">
                                        <span style={{ color: "#878484", fontWeight: "600", fontSize: "15px" }}>Payment Method</span>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span style={{ fontSize: "15px" }}><b>{AllData.card_name}</b> - {AllData.payment_method_last_four}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
