import { faAngleDown, faAngleUp, faBuilding, faChartLine, faCirclePlay, faClipboardList, faClockRotateLeft, faCog, faCogs, faDatabase, faDesktop, faDiagramPredecessor, faFileArrowDown, faFileInvoiceDollar, faGlobe, faGraduationCap, faHandHoldingUsd, faHeadset, faLaptopCode, faListCheck, faMapMarkerAlt, faNoteSticky, faQrcode, faQuestionCircle, faReceipt, faRightLeft, faRobot, faSchool, faScrewdriverWrench, faShippingFast, faShoppingCart, faTableColumns, faTabletAlt, faTags, faTicket, faTicketAlt, faTools, faTruck, faUpload, faUser, faUserShield, faUsers, faUsersRectangle, faWandMagicSparkles, faWarehouse, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';

export default function LeftMenu({ isWrapperToggled, setIsWrapperToggled, FinalPath }) {
    const [activeSubmenuItem, setActiveSubmenuItem] = useState('');
    const { UserData, menuData } = useContext(UserContext);
    const navigate = useNavigate();
    const handleMenuClick = (item, e) => {
        const isCtrlPressed = e.ctrlKey || e.metaKey;
        if (isCtrlPressed) {
            window.open(item.href, '_blank');
        } else {
            navigate(item.href);
        }
    };
    const handleSubmenuClick = (subItem, e) => {
        const isCtrlPressed = e.ctrlKey || e.metaKey;
        if (isCtrlPressed) {
            window.open(subItem.Href, '_blank');
        } else {
            navigate(subItem.Href);
        }
    };
    function getIcon(imageName) {
        switch (imageName) {
            case "faCog":
                return faCog;
            case "faDatabase":
                return faDatabase;
            case "faDesktop":
                return faDesktop;
            case "faFileInvoiceDollar":
                return faFileInvoiceDollar;
            case "faReceipt":
                return faReceipt;
            case "faRightLeft":
                return faRightLeft;
            case "faSchool":
                return faSchool;
            case "faTableColumns":
                return faTableColumns;
            case "faTools":
                return faTools;
            case "faUser":
                return faUser;
            case "faUsers":
                return faUsers;
            case "faGlobe":
                return faGlobe;
            case "faChartLine":
                return faChartLine;
            case "faTicketAlt":
                return faTicketAlt;
            case "faQuestionCircle":
                return faQuestionCircle;
            case "faShippingFast":
                return faShippingFast;
            case "faTruck":
                return faTruck;
            case "faMapMarkerAlt":
                return faMapMarkerAlt;
            case "faShoppingCart":
                return faShoppingCart;
            case "faWrench":
                return faWrench;
            case "faTabletAlt":
                return faTabletAlt;
            case "faLaptopCode":
                return faLaptopCode;
            case "faHeadset":
                return faHeadset;
            case "faScrewdriverWrench":
                return faScrewdriverWrench;
            case "faBuilding":
                return faBuilding;
            case "faCogs":
                return faCogs;
            case "faTicket":
                return faTicket;
            case "faWarehouse":
                return faWarehouse;
            case "faUserShield":
                return faUserShield;
            case "faListCheck":
                return faListCheck;
            case "faHandHoldingUsd":
                return faHandHoldingUsd;
            case "faTags":
                return faTags;
            case "faWandMagicSparkles":
                return faWandMagicSparkles;
            case "faQrcode":
                return faQrcode;
            case "faUpload":
                return faUpload;
            case "faGraduationCap":
                return faGraduationCap;
            case "faRobot":
                return faRobot;
            case "faNoteSticky":
                return faNoteSticky;
            case "faUsersRectangle":
                return faUsersRectangle;
            case "faClipboardList":
                return faClipboardList;
            case "faCirclePlay":
                return faCirclePlay;
            case "faDiagramPredecessor":
                return faDiagramPredecessor;
            case "faClockRotateLeft":
                return faClockRotateLeft;
            case "faFileArrowDown":
                return faFileArrowDown;
            default:
                return null;
        }
    }
    function toggleWrapper() {
        if (isWrapperToggled) {
            setIsWrapperToggled(false);
        } else {
            setIsWrapperToggled(true);
        }
    };
    return (
        <aside className="side-menu-scroll-style sidebar-wrapper" data-simplebar="init">
            <div className="simplebar-wrapper m-0">
                <div className="simplebar-height-auto-observer-wrapper">
                    <div className="simplebar-height-auto-observer"></div>
                </div>
                <div className="simplebar-mask">
                    <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                        <div className="simplebar-content-wrapper" style={{ height: "100%", overflow: "hidden" }}>
                            <div className="simplebar-content mm-active p-0">
                                <div className="sidebar-header cursor-pointer">
                                    <img src={UserData.schoollogo} id="LeftMenuLogo" className="logo-icon Leftmenulogo" alt="logo icon" onClick={toggleWrapper} />
                                    <div className="toggle-icon ms-auto" id="sidebar-toggle" onClick={toggleWrapper}><i className="bi bi-list"></i></div>
                                </div>
                                <ul className="metismenu mm-show">
                                    {menuData?.map((item, i) => {
                                        const href = `/${item.href}`;
                                        const isActive = FinalPath ? item.href == FinalPath : i == 0;
                                        return (
                                            <div key={i}>
                                                {item.Submenu.length == 0 ?
                                                    <li className={isActive ? 'mm-active' : ''} onClick={window.innerWidth <= 767 ? toggleWrapper : null}>
                                                        <a className='d-flex align-items-center'
                                                            onClick={(e) => item.name == "Shop" ? window.open("https://shop.k12techrepairs.com/", "_blank") : handleMenuClick(item, e)}>
                                                            <FontAwesomeIcon icon={getIcon(item.image)} className='parent-icon p-1 smallicon' />
                                                            <div className="menu-title">{item.name}</div>
                                                        </a>
                                                    </li>
                                                    :
                                                    <li>
                                                        <a className='d-flex align-items-center justify-content-between'
                                                            onClick={(e) => setActiveSubmenuItem((prevItem) => prevItem == item.name ? '' : item.name)}>
                                                            <div className="d-flex align-items-center">
                                                                <FontAwesomeIcon icon={getIcon(item.image)} className='parent-icon p-1 smallicon' />
                                                                <div className="menu-title">{item.name}</div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="menu-title">
                                                                    <FontAwesomeIcon icon={activeSubmenuItem == item.name ? faAngleUp : faAngleDown} className='parent-icon p-1 text-end' style={{ height: "15px", width: "15px" }} />
                                                                </div>
                                                            </div>
                                                        </a>
                                                        {activeSubmenuItem == item.name && (
                                                            <div className='py-1'>
                                                                {item.Submenu.map((subItem, j) => {
                                                                    const isSubActive = subItem.Href == FinalPath;
                                                                    return (
                                                                        UserData.helpdeskUpdatedFlag != null ?
                                                                            <li key={j} className={`${isSubActive ? 'mm-active' : ''}`} onClick={window.innerWidth <= 767 ? toggleWrapper : null}>
                                                                                <a className='d-flex ps-5 align-items-center ps-3' onClick={(e) => handleSubmenuClick(subItem, e)}>
                                                                                    <FontAwesomeIcon icon={getIcon(subItem.Image)} className='parent-icon p-1' style={{ height: "20px", width: "20px" }} />
                                                                                    <div className="menu-title ms-1">{subItem.Name}</div>
                                                                                </a>
                                                                            </li>
                                                                            :
                                                                            <li key={j} className={`${isSubActive ? 'mm-active' : ''} ${subItem.ID == 40 ? 'd-none' : ''}`} onClick={window.innerWidth <= 767 ? toggleWrapper : null}>
                                                                                <a className='d-flex ps-5 align-items-center ps-3' onClick={(e) => handleSubmenuClick(subItem, e)}>
                                                                                    <FontAwesomeIcon icon={getIcon(subItem.Image)} className='parent-icon p-1' style={{ height: "20px", width: "20px" }} />
                                                                                    <div className="menu-title ms-1">{subItem.Name}</div>
                                                                                </a>
                                                                            </li>
                                                                    )
                                                                })}
                                                            </div>
                                                        )}
                                                    </li >
                                                }
                                            </div>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}
