import { LogInSchoolID, LogInUserID } from '../../../JS/Common';
import Cookies from "js-cookie";

export const importGoogleDeviceAPI = async (data, NewUpdate) => {
    try {
        const BaseUrl = process.env.REACT_APP_Base_URL;
        const AuthKey = process.env.REACT_APP_AuthKey;
        var myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", AuthKey);
        var formdata = new FormData();
        formdata.append("ID", LogInUserID);
        formdata.append("schId", LogInSchoolID);
        formdata.append("GoogleRefreshtoken", data?.refresh_token);
        formdata.append("GoogleAccesstoken", data?.access_token);
        formdata.append("GoogleLoginMail", data?.email);
        formdata.append("CustomerId", data?.customer_id);
        formdata.append("AddUpdateFlag", NewUpdate == "Add" ? 0 : 1);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        try {
            const response = await fetch(`${BaseUrl}/initiateGoogleDataFetch`, requestOptions);
            const result = await response.text();
            return result
        } catch (error) {
            throw error
        }
    } catch (error) {
        throw error
    }
};

export const GetGoogleAdminData = async (accessToken, url) => {
    try {
        var requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            },
            redirect: 'follow'
        };
        const response = await fetch(url, requestOptions);
        const statusCode = response.status;
        const result = await response.json();
        return { statusCode, result };
    } catch (error) {
        throw error
    }
}

export const PostGoogleAuthAPI = async (params) => {
    try {
        const response = await fetch('https://accounts.google.com/o/oauth2/token', {
            method: 'POST',
            body: params,
        });
        const result = await response.json();
        return result
    } catch (error) {
        throw error
    }
}

export const PostErrorCall = async (msg, FunctionName) => {
    try {
        const BaseUrl = process.env.REACT_APP_Base_URL;
        var ciphertext = Cookies.get('ciphertext');
        var myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", ciphertext);
        const raw = JSON.stringify({
            msg: msg,
            function: FunctionName,
            userid: LogInUserID,
            schoolid: LogInSchoolID
        })
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        try {
            const response = await fetch(`${BaseUrl}/SaveFrontendErrors`, requestOptions);
            const result = await response.text();
            return result
        } catch (error) {
            throw error
        }
    } catch (error) {
        throw error
    }
}
