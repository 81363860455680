import React, { useContext } from 'react'
import TicketStatuses from './TicketStatuses'
import TicketByBuilding from './TicketByBuilding';
import TicketsByGrade from './TicketsByGrade';
import PartUsed from './PartUsed';
import KPI from './KPI';
import { ReportDataContext } from '../index';

export default function DeviceReportsIndex({ setAlerts }) {
    const { AllReportData } = useContext(ReportDataContext);
    return (
        <>
            <div className='row'>
                <div className='col-md-12  py-2'>
                    <TicketStatuses data={AllReportData?.TicketStatusData?.data} loder={AllReportData?.TicketStatusData?.loader} setAlerts={setAlerts} />
                </div>
                <div className='col-md-6 py-2'>
                    <TicketByBuilding data={AllReportData?.TicketByBuildingData?.data?.Data} CsvData={AllReportData?.TicketByBuildingData?.data?.TicketData} loder={AllReportData?.TicketByBuildingData?.loader} setAlerts={setAlerts} />
                </div>
                <div className='col-md-6 py-2'>
                    <TicketsByGrade data={AllReportData?.TicketByGradeData?.data?.Data} CsvData={AllReportData?.TicketByGradeData?.data?.TicketData} loder={AllReportData?.TicketByGradeData?.loader} setAlerts={setAlerts} />
                </div>
                <div className='col-md-6 py-2'>
                    <PartUsed data={AllReportData?.PartUsedData?.data} loder={AllReportData?.PartUsedData?.loader} setAlerts={setAlerts} />
                </div>
                <div className='col-md-6 py-2'>
                    <KPI data={AllReportData?.KpiData?.data?.data} loder={AllReportData?.KpiData?.loader} CsvData={AllReportData?.KpiData?.data?.TicketData} setAlerts={setAlerts} />
                </div>
            </div>
        </>
    )
}
