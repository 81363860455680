import { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { RemoveAllCookies } from "../../JS/Common";
import Cookies from "js-cookie";
import { LazyLoadImage } from "react-lazy-load-image-component";
export function Logout() {
    var loadtesting = Cookies.get('isloadtesting');
    useEffect(() => {
        RemoveAllCookies();
    }, [])
    const [count, setCount] = useState(5);
    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            if (count > 0) {
                setCount(count - 1);
            } else {
                if (loadtesting == 1) {
                    window.location.href = '/loadtestinglogin'
                } else {
                    window.location.href = '/'
                }
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [count]);
    return (
        <>
            <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
            <div className="position-relative MainDiv">
                <div className="card mx-auto">
                    <div className="p-4 row">
                        <p>Redirecting to login page in {count} seconds...</p>
                        <div className="col-md-4 offset-md-4">
                            <img src='/images/LogOutImage.svg' height='90px' alt="log out" />
                        </div>
                        <div className="pt-4" style={{ color: '#495057' }}><h4>You are Logged Out</h4></div>
                        <div className="pt-2" style={{ color: '#878a99' }}><h6>Thank you for using K12 Tech Repair</h6></div>
                        <div className='text-center pt-3'>
                            <button className='SaveBtn' onClick={() => loadtesting == 1 ? window.location.href = '/loadtestinglogin' : window.location.href = '/'} style={{ width: '90%' }}>Sign In</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
