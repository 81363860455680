import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import { HideLoder, LogInSchoolID, ShowLoder, typewatch } from '../../JS/Common';
import { UserContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import AlertsComp from '../../Components/AlertsComp';
function Deallocatation(props) {
    const [AttachDevice, setAttachDevice] = useState({
        ShowSuggestion: "d-none",
    });
    const [flagforyesno, setFlagforyesno] = useState(2)
    const [ValueFordeallocation, setValueFordeallocation] = useState('')
    const [Notes, setNotes] = useState('')
    const [UserNm, setUserNm] = useState('')
    const [SuggestionBoxArray, setSuggestionBoxArray] = useState([]);
    const [IdForDevice, setIdForDevice] = useState("");
    const [Alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const [DeviceBrokenDiv, setDeviceBrokenDiv] = useState(false);
    const suggestionBoxRef = useRef(null);
    const { UserData } = useContext(UserContext);
    const flagForSidemenu = UserData.MenuAccessFlag;
    const navigate = useNavigate();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
                setAttachDevice({ ...AttachDevice, ShowSuggestion: "d-none" });
            }
        };
        const handleWindowClick = (event) => {
            handleClickOutside(event);
        };
        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);
    async function GetAllDeviceForDeallocate(searchstring) {
        if (searchstring == "") {
            searchstring = null;
        }
        typewatch(async function () {
            setLoading(true)
            await ApiGetCall("/allAllocatedDevice/" + LogInSchoolID + "&" + searchstring).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    var vArray = [];
                    var sugData = responseRs;
                    setLoading(false)
                    setAttachDevice({ ...AttachDevice, ShowSuggestion: "" });
                    if (sugData.length != 0) {
                        for (var i = 0; i < sugData.length; i++) {
                            var html = <div className="col-12 p-2 brdr-Btm text-start" onClick={(e) => handleInputChangeForDeallocate(e)} deviceid={sugData[i].id} serialnumber={sugData[i].serial_number} Notes={sugData[i].notes ? sugData[i].notes : '-'} User={(sugData[i].student_inventory?.student?.device_user_first_name) + ' ' + (sugData[i].student_inventory?.student?.device_user_middle_name || '') + ' ' + (sugData[i].student_inventory?.student?.device_user_last_name || '-') + ' (' + sugData[i].student_inventory?.student?.student_num + ') '}>
                                {sugData[i].serial_number}
                            </div>
                            vArray.push(html);
                        }
                    } else {
                        var vhtml = <div className="brdr-Btm font-14 text-center">
                            No Record Found
                        </div>
                        vArray.push(vhtml);
                    }
                    setSuggestionBoxArray(vArray);

                }
            });
        }, 500);
    }
    function handleInputChangeForDeallocate(e) {
        var id = e.currentTarget.attributes[1].value;
        var value = e.currentTarget.attributes[2].value;
        var notes = e.currentTarget.attributes[3].value;
        var usernm = e.currentTarget.attributes[4].value;
        setNotes(notes)
        setUserNm(usernm)
        setAttachDevice({ ...AttachDevice, ShowSuggestion: "d-none" });
        setValueFordeallocation(value);
        setDeviceBrokenDiv(true)
        setIdForDevice(id)
        props.setErrormsgForDeallocation('')
    };

    function handelUserChange(flag) {
        if (flag == "yes") {
            setFlagforyesno(1)
        } else {
            setFlagforyesno(2)
        }
    }
    async function AllocationSave(flag) {
        var vArray = [];
        var vArray = [];
        var vjson = {};
        vjson['userid'] = 0;
        vjson['deviceid'] = IdForDevice;
        vArray.push(vjson);
        if (IdForDevice == '') {
            props.setErrormsgForDeallocation('Please choose a value from the suggestion box, and make sure not to leave it blank.')
            return
        }
        var raw = JSON.stringify({
            Flag: flag,
            DeviceArray: vArray,
            Schoolid: LogInSchoolID,
            AssignedFlag: props.AssignedFlag
        });
        setLoading(true);
        await ApiPostCall("/DeviceAllocationToUSer", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result)
                if (responseRs.response == "success") {
                    var alertmsg = 'Device Deallocated Successfully';
                    setAlerts(<AlertsComp show={true} variant="success" msg={alertmsg} />);
                    if (flag == 2 && flagForSidemenu != 8) {
                        setTimeout(() => {
                            setAlerts(<AlertsComp show={false} />)
                            if (flagforyesno == 2) {
                                setValueFordeallocation('')
                                setNotes('')
                                setUserNm('')
                                setFlagforyesno(2)
                                setDeviceBrokenDiv(false)
                            } else {
                                navigate('/create-ticket/?deviceid=' + IdForDevice)
                            }
                        }, 2000);
                    } else {
                        setTimeout(() => {
                            setAlerts(<AlertsComp show={false} />)
                            setValueFordeallocation('')
                            setNotes('')
                            setUserNm('')
                            setFlagforyesno(2)
                            setDeviceBrokenDiv(false)
                        }, 2000);
                    }
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    return (
        <Fragment>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <h5 className='col-md-12 px-2 fw-600 mb-0'>Deallocate Devices</h5>
            <hr className='mb-0' />
            <div className="col-12 py-2 text-center px-2">
                <label className='text-justify FormLabel'>To quickly remove users from devices, select the device from which you wish to remove a user. Continue this process for all devices from which you want to remove users. Once you've made all your selections, click the 'Deallocate Device' button to complete the removals promptly.</label>
            </div>
            <hr />
            <div className='col-md-12 fw-600 ps-2 pt-3'>Device</div>
            <div className="col-md-12 d-flex px-2 pb-2 pt-2 align-items-center">
                <div className="col-md-10 row align-items-center ps-3">
                    <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                        <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                        <input className="form-control" autoComplete="off" type="text" placeholder="Search Device"
                            value={ValueFordeallocation} onKeyUp={(e) => GetAllDeviceForDeallocate(e.target.value)}
                            onChange={(e) => setValueFordeallocation(e.target.value)}
                        />
                        <div className={`SuggestionBoxDiv ${AttachDevice.ShowSuggestion}`} ref={suggestionBoxRef}>
                            {SuggestionBoxArray}
                        </div>
                    </form>
                </div>

            </div>
            <div className='ps-5'><b>Notes:</b>&nbsp;{Notes || '-'}</div>
            <div className='ps-5'><b>User:</b>&nbsp;{UserNm || '-'}</div>
            <div className='col-md-12 ps-3 pt-2 redText row'>{props.ErrormsgForDeallocation} </div>
            {DeviceBrokenDiv == true && (
                <div className='p-5 col-12'>
                    <label className='text-justify FormLabel'>Is this Device Broken?</label>
                    <div className='col-md-12 mt-3 d-flex'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name='AssignDevice' onChange={(e) => handelUserChange("yes")} />
                            <label className="form-check-label">
                                Yes
                            </label>
                        </div>
                        <div className="form-check ms-5">
                            <input className="form-check-input" defaultChecked type="radio" name='AssignDevice' onChange={(e) => handelUserChange("no")} />
                            <label className="form-check-label">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            )}
            <div className="col-12 text-center py-5">
                <img src="https://tablepadscdn.azureedge.net/Localimages/loder.svg" className="m-auto pe-3 d-none" id="SmallLoader" alt="Loader" />
                <button className='SaveBtn' onClick={() => { AllocationSave(2) }}>Deallocate Device</button>
                <label className="ms-2 cursor-pointer" onClick={(e) => window.location.reload()}><u>Cancel</u></label>
            </div>
        </Fragment >
    )
}

export default Deallocatation
