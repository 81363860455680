import React, { useEffect, useState } from 'react';
import "./style.css"
import Form from 'react-bootstrap/Form';
import { LogInSchoolID, MMDDYYYY } from '../../JS/Common';
import { CheckValidation } from '../../Components/Validations';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import { useNavigate } from 'react-router-dom';
import Tab from '../../Components/Tab';
import PageHead from '../../Components/PageHead';
import Popup from '../../Components/Popup';

export default function DeviceCustomField() {
    const navigate = useNavigate();
    const [ansType, setAnsType] = useState('1')
    const [RequiredField, setRequiredField] = useState(0)
    const [fields, setFields] = useState([
        { id: 1, value: '', flag: 1, structureID: 0 },
    ]);
    const [FieldName, setFieldName] = useState('')
    const [ansTypeError, setAnsTypeError] = useState('')
    const [SubEntryError, setSubEntryError] = useState('')
    const [NewAlerts, setNewAlerts] = useState('');
    const [loading, setLoading] = useState(false);
    const [customField, setCustomField] = useState([])
    const [AddupdateFlag, setAddupdateFlag] = useState('')
    const [ExistingFields, setExistingFields] = useState([
        { name: 'Serial Number', type: 'Text' },
        { name: 'Device Model', type: 'Text' },
        { name: 'Device MPN', type: 'Number' },
        { name: 'Repair Cap', type: 'Text' },
        { name: 'Building', type: 'Text' },
        { name: 'Device Manufacturer', type: 'Text' },
        { name: 'Asset Tag', type: 'Text' },
        { name: 'Device OS', type: 'Text' },
        { name: 'Notes', type: 'Text' },
        { name: 'Purchase Date', type: 'Date' },
        { name: 'Expected Retirement', type: 'Date' },
        { name: 'Manufacturer Warranty Until', type: 'Date' },
        { name: 'Manufacturer ADP Until', type: 'Date' },
        { name: 'Third Party Extended Warranty Until', type: 'Date' },
        { name: 'Third Party ADP Until', type: 'Date' },
        { name: 'Is this device a loaner device?', type: 'Single Selection' },
        { name: 'Do you want to assign this device to user?', type: 'Single Selection' },
        { name: 'Device User First Name', type: 'Text' },
        { name: 'Device User Middle Name', type: 'Text' },
        { name: 'Device User Last Name', type: 'Text' },
        { name: 'User ID', type: 'Text' },
        { name: 'Grade', type: 'Text' },
        { name: 'Department', type: 'Text' },
        { name: 'Parent / Guardian Email', type: 'Text' },
        { name: 'Parent / Guardian Name', type: 'Text' },
        { name: 'Parent Phone Number', type: 'Text' },
        { name: 'Parental Coverage', type: 'Single Selection' },
    ])
    const [Tabs, setTabs] = useState({
        Current: 'active',
        Existing: ''
    })
    const [CustomeModal, setCustomeModal] = useState(false)
    // const [InfoModal, setInfoModal] = useState(false)
    const [EditId, setEditId] = useState('')

    useEffect(() => {
        GetCustomFieldsNames();
    }, [])

    async function GetCustomFieldsNames() {
        setLoading(true)
        await ApiGetCall("/getCustomFieldsName/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                setCustomField(responseRs)
                setLoading(false);
            }
        });
    }
    async function GetFieldInfoCall(id) {
        setLoading(true)
        setAddupdateFlag(2)
        await ApiGetCall("/getCustomFieldsDataByID/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setFieldName(responseRs?.name)
                setAnsType(responseRs?.value_type)
                setRequiredField(responseRs?.is_required)
                setCustomeModal(true);
                setLoading(false)
                setEditId(responseRs?.id)
                const updatedrow = responseRs?.customefieldstructures.map(item => item.custom_field_id != null ? { ...item, flag: 2, structureID: item.id } : { ...item, flag: 1, structureID: 0 })
                setFields(updatedrow)
            }
        });
    }

    function handleAddField() {
        setFields([...fields, { id: fields.length + 1, value: '', flag: 1, structureID: 0 }]);
    };

    function handleRemoveField(fieldId) {
        setFields(fields.map((field) => {
            if (field.id === fieldId) {
                return { ...field, flag: 3 };
            }
            return field;
        }).map((field, index) => ({ ...field, id: index + 1 })));
    };


    function handleChange(event, fieldId) {
        const updatedFields = [...fields];
        const index = updatedFields.findIndex((field) => field.id === fieldId);
        updatedFields[index].value = event.target.value;
        setFields(updatedFields);
    };

    const renderFields = () => {
        let seriesId = 1;
        return fields.map((field) => {
            if (field.flag === 3) {
                return null;
            }
            const seriesLabel = `Option ${seriesId++}`;
            return (
                <div key={field.id} className='d-flex py-1 col-md-12 align-items-center'>
                    <div className='col-md-2 FormLabel'>{seriesLabel}:</div>
                    <div className='col-md-8'>
                        <input
                            className="form-control"
                            type="text"
                            flag={field.flag}
                            id={`field-${field.id}`}
                            name={`field-${field.id}`}
                            value={field.value}
                            onChange={(event) => handleChange(event, field.id)}
                            required
                        />
                    </div>
                    {fields.length > 1 && (
                        <img src="/images/delete_red_icon.svg" title="Delete Option"
                            className="img-fluid cursor-pointer ps-2" onClick={() => handleRemoveField(field.id)} />
                    )}
                    <br />
                </div>
            );
        });
    };



    function handleRadioChange(flag) {
        setAnsType(flag)
        setFields([{ id: 1, value: '', flag: 1, structureID: 0 }]);
    }

    async function SaveFieldCall() {
        setAnsTypeError('* required')
        var valuesArray = []
        const checkvalidation = fields.filter(item => !(item.flag == 3 && item.structureID == 0));
        if (ansType == 6 || ansType == 7) {
            if (checkvalidation.filter(item => item.value.trim() === "").length != 0) {
                setSubEntryError('* All fields are required');
                return;
            } else {
                const newArray = fields.filter(item => !(item.flag == 3 && item.structureID == 0));
                valuesArray = newArray.map(item => item.value);
                setSubEntryError('');
            }
        }
        var isFormValid = CheckValidation({ formID: "RequiredID" });
        if (!isFormValid) return;
        var raw = JSON.stringify({
            schoolID: LogInSchoolID,
            name: FieldName,
            type: ansType,
            isRequired: RequiredField,
            subType: valuesArray,
            parentObjectType: '1'
        })
        setLoading(true)
        await ApiPostCall("/saveCustomFields", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setCustomeModal(false)
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Field Added Successfully"} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        // navigate('/manage-device')
                        GetCustomFieldsNames();
                        setFieldName(''); setRequiredField(0); setFields([{ id: 1, value: '' }])
                    }, 1500);
                } else {
                    const responseRs = JSON.parse(result);
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.response} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }

    async function UpdateFieldCall(flag, deleteId) {
        var updateArray = [];
        {
            fields.map((item, i) => {
                var vjson = {};
                vjson['flag'] = item.flag;
                vjson['name'] = item.value;
                vjson['structureID'] = item.structureID;
                updateArray.push(vjson)
            })
        }
        var newArray;
        if (flag == 1) {
            const checkvalidation = updateArray.filter(item => !(item.flag == 3 && item.structureID == 0));
            newArray = updateArray.filter(item => item.name.trim() !== '' && !(item.flag === 3 && item.structureID === 0));
            setAnsTypeError('* required')
            if (ansType == 6 || ansType == 7) {
                if (checkvalidation.filter(item => item.name.trim() === "").length != 0) {
                    setSubEntryError('* All fields are required');
                    return;
                } else {
                    setSubEntryError('');
                }
            }
            var isFormValid = CheckValidation({ formID: "RequiredID" });
            if (!isFormValid) return;
        }

        var raw = JSON.stringify({
            schoolID: LogInSchoolID,
            name: FieldName,
            valueType: ansType,
            isRequired: RequiredField,
            parentObjectType: '1',
            structureArray: newArray,
            customFieldID: flag == 1 ? EditId : deleteId,
            updateDeleteFlag: flag
        })
        setLoading(true)
        await ApiPostCall("/updateOrDeleteCustomFields", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setCustomeModal(false)
                const responseRs = JSON.parse(result)
                if (responseRs.status == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        // navigate('/manage-device')
                        GetCustomFieldsNames();
                        setFieldName(''); setRequiredField(0); setFields([{ id: 1, value: '' }])
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    function handleTabClick(tabType) {
        if (tabType == "current") {
            setTabs({ ...Tabs, Current: "active", Existing: "" });
            GetCustomFieldsNames();
        } else if (tabType == "default") {
            setTabs({ ...Tabs, Current: "", Existing: "active" });
        }
    }
    const modalbody = (
        <div>
            <div className="d-flex col-md-12 align-items-center parent" id="RequiredID">
                <div className="col-md-2 FormLabel"> Field Name : </div>
                <div className='ps-4 col-md-6'>
                    <input className="form-control" autoComplete='off' name='add field' placeholder='Enter Field Name' required value={FieldName} onChange={(e) => { setFieldName(e.target.value) }} />
                    <span className="form-text invalid-feedback"> *required </span>
                </div>
                <br />
            </div>

            <div className="d-flex col-md-12 pt-3">
                <div className="col-md-2"> Field Type:</div>
                <div className='ps-4 col-md-10'>
                    <div className='row'>
                        <div className="col-6 my-2">
                            <Form.Check type="radio" name="group1" aria-label="radio 1" label="Small Text (max character 100)" onChange={(e) => { handleRadioChange(1) }} checked={ansType == 1} defaultChecked disabled={AddupdateFlag == 2} />
                        </div>
                        <div className="col-3 my-2">
                            <Form.Check type="radio" name="group1" aria-label="radio 1" label="Long Text" onChange={(e) => { handleRadioChange(2) }} checked={ansType == 2} disabled={AddupdateFlag == 2} />
                        </div>
                        <div className="col-3 my-2">
                            <Form.Check type="radio" name="group1" aria-label="radio 1" label="Number" onChange={(e) => { handleRadioChange(3) }} checked={ansType == 3} disabled={AddupdateFlag == 2} />
                        </div>
                        <div className="col-3 my-2">
                            <Form.Check type="radio" name="group1" aria-label="radio 1" label="Date" onChange={(e) => { handleRadioChange(4) }} checked={ansType == 4} disabled={AddupdateFlag == 2} />
                        </div>
                        <div className="col-3 my-2">
                            <Form.Check type="radio" name="group1" aria-label="radio 1" label="Date & Time" onChange={(e) => { handleRadioChange(5) }} checked={ansType == 5} disabled={AddupdateFlag == 2} />
                        </div>
                        <div className="col-3 my-2">
                            <Form.Check type="radio" name="group1" aria-label="radio 1" label="Single Selection" onChange={(e) => { handleRadioChange(6) }} checked={ansType == 6} disabled={AddupdateFlag == 2} />
                        </div>
                        <div className="col-3 my-2">
                            <Form.Check type="radio" name="group1" aria-label="radio 1" label="Multi Selection" onChange={(e) => { handleRadioChange(7) }} checked={ansType == 7} disabled={AddupdateFlag == 2} />
                        </div>
                        <div className='col-3 my-2'></div>
                        {ansType == '' ?
                            <div className="Emailinvalid col-12" id='statusMsg'>{ansTypeError}</div>
                            : <></>}

                        {ansType == 6 || ansType == 7 ? (
                            <div className='d-flex col-md-12 mb-3'>
                                <div className='greyBox p-3 col-md-12'>
                                    <div className='font-16 fw-600'>{ansType == 6 ? 'Options for Single Selection' : 'Options for Multi Selection'}</div>
                                    <hr />
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        {renderFields()}
                                    </form>
                                    <div className="col-md-8 offset-md-2 Emailinvalid" id='statusMsg'>{SubEntryError}</div>

                                    <div className='row col-md-12'>
                                        <div className='col-md-8 offset-md-2 pt-1'>
                                            <label className='BorderBtn text-center' onClick={handleAddField}>
                                                <img src='/images/AddInventory.svg' className='img-fluid pe-2' style={{ height: '12px' }} />Add
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<></>)}
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center'>
                <div>Make this field mandatory?</div>
                <div className='d-flex'>
                    <div className="my-2 ms-3">
                        <Form.Check type="radio" name="group2" aria-label="radio 1" label="Yes" onChange={(e) => { setRequiredField(1) }} checked={RequiredField == 1} />
                    </div>
                    <div className="my-2 ms-3">
                        <Form.Check type="radio" name="group2" aria-label="radio 1" label="No" onChange={(e) => { setRequiredField(0) }} checked={RequiredField == 0} />
                    </div>
                </div>
            </div>
        </div>
    )
    return (
        <React.Fragment>
            <PageHead heading={"Manage Device Fields"}
                loading={loading} Alerts={NewAlerts} showsearch={false} />
            <div className="GridBox p-3 mt-3">
                <div className="row align-items-center">
                    <div className="col-md-9 px-0">
                        <ul className="nav nav-tabs px-3">
                            <Tab isActive={Tabs.Current == 'active'}
                                label="Current Custom" onClick={() => handleTabClick('current')} col={'col-md-3'} />
                            <Tab isActive={Tabs.Existing == 'active'}
                                label="Default Existing" onClick={() => handleTabClick('default')} col={'col-md-3'} />
                        </ul>
                    </div>
                    <div className="col-md-3 text-end">
                        {Tabs.Current == 'active' && (
                            <label className='BorderBtn ms-3 text-center' onClick={(e) => { setCustomeModal(true); setAnsTypeError(''); setSubEntryError(''); setFields([{ id: 1, value: '' }]); setAnsType('1'); setRequiredField(0); setAddupdateFlag(1) }}> Add Field
                                <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                            </label>
                        )}
                    </div>
                </div>

                <div className="greyBox mt-2">
                    {Tabs.Current == 'active' && (
                        <table className="table data-table">
                            <thead className='GridHeader'>
                                <tr>
                                    <th scope="col" className='fw-600'>Name </th>
                                    <th scope="col" className='fw-600'>Type </th>
                                    <th scope="col" className='fw-600'>Created at </th>
                                    <th scope="col" className='fw-600'>Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                {customField.length != 0 ?
                                    customField.map((item, i) => {
                                        return <tr key={i} className="OddEvenClass tableBorderHide">
                                            <td className="py-12">{item.name} </td>
                                            <td className="py-12">{item.value_type_name} </td>
                                            <td className="py-12">{MMDDYYYY(item.created_at)} </td>
                                            <td className='py-12'>
                                                <img src='/images/EditIconNew.svg' title='Edit Field' className='img-fluid pe-2 cursor-pointer' onClick={(e) => { GetFieldInfoCall(item.id); }} />
                                                <img src='/images/DeleteIcon.svg' title='Delete Field' className='img-fluid pe-2 cursor-pointer' onClick={(e) => { UpdateFieldCall(2, item.id); }} />
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr className="text-center">
                                        <td colSpan={15}>No Record Found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    )}
                    {Tabs.Existing == 'active' && (
                        <table className="table data-table">
                            <thead className='GridHeader'>
                                <tr>
                                    <th scope="col" className='fw-600'>Name </th>
                                    <th scope="col" className='fw-600'>Type </th>
                                </tr>
                            </thead>
                            <tbody>
                                {ExistingFields.length != 0 ?
                                    ExistingFields.map((item, i) => {
                                        return <tr key={i} className="OddEvenClass tableBorderHide">
                                            <td className="py-12" >{item.name} </td>
                                            <td className="py-12" >{item.type} </td>
                                        </tr>
                                    })
                                    :
                                    <tr className="text-center">
                                        <td colSpan={15}>No Record Found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
            <Popup isshow={CustomeModal} size={"lg"} title={AddupdateFlag == 2 ? 'Edit Field' : 'Add Field'}
                closePopup={(e) => { setCustomeModal(false); setFieldName(''); setRequiredField(0); setFields([{ id: 1, value: '' }]); }}
                modalBody={modalbody} handleSave={(e) => AddupdateFlag == 2 ? UpdateFieldCall(1, null) : SaveFieldCall()}
                visibleSaveBtn={true} btnText={AddupdateFlag == 2 ? 'Update' : 'Save'} />
        </React.Fragment >
    )
}
