import React, { useContext, useEffect, useRef, useState } from 'react'
import { ApiPostCall } from '../../JS/Connector';
import { HideLoder, LogInSchoolID, ShowLoder, convertToBase64 } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../App';
import Popup from '../../Components/Popup';

export default function HdTypeModal(props) {
    const [NewData, setNewData] = useState([{ name: '', description: '', icon: '' }]);
    const [errorMessage, setErrorMessage] = useState('');
    const [CategoryVisibility, setCategoryVisibility] = useState(false);
    const [Alerts, setAlerts] = useState('');
    const [loading, setLoading] = useState(false);
    const quillRef = useRef(null);
    const { UserData, setUserData } = useContext(UserContext);
    useEffect(() => {
        setNewData([{ name: '', description: '', icon: '' }]);
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.getModule('toolbar').addHandler('image', customImageHandler);
        }
    }, [])
    const customImageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/svg+xml,image/png,image/jpeg,image/jpg');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            if (file.size > 2 * 1024 * 1024) {
                alert("Image size exceeds 2MB limit.");
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                quill.insertEmbed(range.index, 'image', e.target.result);
            };
            reader.readAsDataURL(file);
        };
    };
    const handleInputChange = (index, val, name) => {
        const updatedCategories = [...NewData];
        updatedCategories[index][name] = val;
        setNewData(updatedCategories);
    };
    const handleAddCategory = () => {
        setErrorMessage('');
        if (NewData.every(item => item.name !== '')) {
            setNewData([
                ...NewData,
                { name: '', description: '', icon: '' }
            ]);
        } else {
            setErrorMessage(`Please fill in all previous categories before adding a new one.`);
        }
    };
    async function SaveTypes() {
        // if (props.categories?.data?.length == 0) {
        if (NewData.every(item => item.name === '')) {
            setErrorMessage('One Category is required');
            return;
        }
        // }
        setLoading(true);
        var typearray = [];
        NewData.map((item) => {
            item.name != '' && typearray.push(item);
        })
        var raw = JSON.stringify({
            SchoolID: LogInSchoolID,
            ...(props?.categories?.name !== "HdType" && {
                Tickettype: parseInt(props?.categories?.hd_type_id),
                Categoryid: parseInt(props?.categories?.id),
            }),
            NameArray: typearray,
            RequestFlag: props?.categories?.name === "HdType" ? 1 : 2,
        });
        await ApiPostCall("/StoreData", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={props.categories?.alerttext} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        props.ClosePopup();
                        props.apicall();
                        setUserData((prevUserData) => ({ ...prevUserData, helpdeskUpdatedFlag: responseRs.helpdesk_updated_at }));
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };
    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];
    const toggleCategoryVisibility = () => {
        if (CategoryVisibility) {
            setCategoryVisibility(false)
        } else {
            setCategoryVisibility(true)
        }

    };
    async function handleFileUpload(e, index, icon) {
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            alert("File size exceeds 2MB limit.");
            return;
        }
        const base64 = await convertToBase64(file);
        const updatedCategories = [...NewData];
        updatedCategories[index][icon] = base64;
        setNewData(updatedCategories);
    };
    const deleteRow = (index) => {
        var datalength = NewData.length;
        if (datalength == 1) {
            setErrorMessage('One value is Required.')
        } else {
            const updatedCategories = [...NewData];
            updatedCategories.splice(index, 1);
            setNewData(updatedCategories);
        }
    };
    const handleRemoveImage = (index) => {
        const updatedCategories = [...NewData];
        updatedCategories[index]['icon'] = ''; // Setting icon to an empty string to remove the uploaded image
        setNewData(updatedCategories);
    };
    const modalbody = (
        <>
            <div className='GreyGridBox cursor-pointer' onClick={toggleCategoryVisibility}>
                <div className='row p-2'>
                    <div className='col-11'>
                        <label className='font-13 fw-500'>{props.categories?.title}</label>
                    </div>
                    <div className='col-1 text-end'>
                        <FontAwesomeIcon icon={faAngleDown} style={{ color: "#969c9b", height: "14px" }} title="Show More Details" className={`cursor-pointer mt-1 ${CategoryVisibility ? 'd-none' : ''}`} />
                        <FontAwesomeIcon icon={faAngleUp} style={{ color: "#969c9b", height: "14px" }} title="Show More Details" className={`cursor-pointer mt-1 ${CategoryVisibility ? '' : 'd-none'}`} />
                    </div>
                </div>
            </div>
            {CategoryVisibility && (
                props.categories?.data?.length > 0 ? (
                    <div className='mt-1 p-2 GridBox'>
                        {props.categories?.data?.map((item, i) => {
                            return <ul className='col-12 m-0' key={i}>
                                <li>{item.name}</li>
                            </ul>
                        })}
                    </div>)
                    :
                    (props.categories?.name == 'HdType' ? (
                        <div className='mt-1 p-2 GridBox'>
                            <ul className='col-12 m-0'>
                                <li>Technology</li>
                            </ul>
                            <ul className='col-12 m-0'>
                                <li>Maintenance</li>
                            </ul>
                        </div>)
                        :
                        (<div className='p-2 text-center'>
                            <label>No Record Found</label>
                        </div>)
                    )
            )}
            {NewData.map((item, index) => (
                <div key={index} className='pt-3'>
                    <div className='row align-items-center'>
                        <div className={`${props.categories?.name == "HdType" ? 'col-12' : 'col-9'} pb-2`}>
                            <div class="form-group">
                                <input type="text" autoComplete='off' className="form-control"
                                    placeholder="Enter Value" id="Category" name="name"
                                    value={item.name} onChange={(e) => handleInputChange(index, e.target.value, "name")} />
                            </div>
                        </div>
                        {props.categories?.name != "HdType" && (
                            <div className='col-md-3 px-0 pb-2'>
                                {item.icon ?
                                    <div className='d-flex align-items-center ps-3'>
                                        <img src={item.icon} alt="" className='categoryimg-icon' />
                                        <FontAwesomeIcon className='ps-2' icon={faTimes} style={{ cursor: 'pointer' }} onClick={(e) => handleRemoveImage(index)} title='Remove Uploaded Icon' />
                                    </div>
                                    :
                                    <form className="col-12">
                                        <input type="file" label="Image" name="myFile" accept=".jpg,.png,.svg,.jpeg" id={`UploadIconFileId${index}`} onChange={(e) => handleFileUpload(e, index, "icon")} />
                                        <label className="BorderBtn font-12 m-0 p-2" htmlFor={`UploadIconFileId${index}`}><img src='/images/AddInventory.svg' className='img-fluid pe-1' style={{ height: "10px" }} /> Upload Icon</label>
                                    </form>
                                }
                            </div>
                        )}
                        {/* <div className='col-1 text-center pb-2'>
                                    <img className="img-fluid cursor-pointer" src="/images/CancelIcon.svg" title='Delete Issue' onClick={() => deleteRow(index)} />
                                </div> */}

                    </div>
                    <div className='pb-3'>
                        <ReactQuill ref={quillRef}
                            theme="snow" modules={modules}
                            formats={formats} placeholder="Description ...."
                            onChange={(e) => handleInputChange(index, e, "description")}
                            value={item.description}
                        />
                    </div>
                </div>
            ))}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </>
    )
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"md"} title={props.categories?.headername}
                closePopup={(e) => { props.ClosePopup(); props.apicall(); }}
                modalBody={modalbody} handleSave={SaveTypes} visibleSaveBtn={true} btnText={"Save Changes"} />
        </>

    )
}
