import React, { Fragment, useContext, useState } from 'react'
import { AuditContext } from '.';
import { ApiPostCall } from '../../JS/Connector';
import { HideLoder, ShowLoder, LogInSchoolID, LogInUserID, generateRandomString } from '../../JS/Common';

export default function Terms_Conditions_Audit() {
    const [isChecked, setisChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setShowWelcomeAuditDiv, setAuditnum } = useContext(AuditContext);
    function handleTermsAndConditions() {
        setisChecked(!isChecked);
    }
    async function ProceedAudit() {
        setLoading(true);
        var auditNumber = generateRandomString();
        setAuditnum(auditNumber);
        var raw = JSON.stringify({
            auditNum: auditNumber,
            SchoolId: LogInSchoolID,
            UserId: LogInUserID,
            agreementFlag: 1
        })
        await ApiPostCall("/changeUserAgreement", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            }
            else {
                setLoading(false);
                if (result == "success") {
                    setLoading(false);
                    setShowWelcomeAuditDiv(1);
                }
            }
        })
    }
    return (
        <Fragment>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='GridBox px-4 py-3 mt-3'>
                <div className='col-12 greyBox text-justify px-3'>
                    <h4 className='fw-600'>Welcome to the Rocket Inventory Audit system!</h4>
                    <p className='pt-3 FormLabel'>
                        We're here to help you streamline your inventory management. This system is designed
                        to be efficient and user-friendly, but it works best with pre-existing data in place.
                        At the end of the audit, several important reports will be generated, including:
                    </p>
                    <ul className='FormLabel'>
                        <li className='text-justify p-2'>
                            <b>Unscanned Devices in Rocket:</b> Any devices in Rocket, but not scanned, will be flagged.
                        </li>
                        <li className='text-justify p-2'>
                            <b>Devices Scanned Not in Rocket:</b> Devices that were scanned, but not present in Rocket, will be listed.
                        </li>
                        <li className='text-justify p-2'>
                            <b>Scanned Devices with Mismatched Locations:</b> Devices with mismatched location data between Rocket and their scanned location will be identified.
                        </li>
                        <li className='text-justify p-2'>
                            <b>Correctly Scanned Rocket Devices:</b> These are devices that were scanned and are present in Rocket's records.
                        </li>
                        <li className='text-justify p-2'>
                            <b>Scanned Devices in Rocket Without Building:</b> Devices that were scanned and are present in Rocket but lack an associated building.
                        </li>
                        <li className='text-justify p-2'>
                            <b>Scanned Devices in Rocket Without Room:</b> Devices that were scanned and are present in Rocket but lack an associated room.
                        </li>
                    </ul>
                    <p className='FormLabel'>
                        Our system supports scanning for serial numbers or asset tags to identify devices.
                        This means some asset tags might replace serial numbers if the latter isn’t available,
                        which could lead to discrepancies. To avoid this, you can choose not to update serial
                        numbers with scanned data.
                    </p>
                    <p className='FormLabel'>
                        After the audit, the finalized report will be saved in Rocket. You can download it
                        anytime to review the scanned devices.
                    </p>
                    <div className='pt-3 d-flex align-items-center justify-content-center'>
                        <input className="form-check-input m-0 cursor-pointer" type="checkbox" onChange={handleTermsAndConditions} />
                        <span className='fw-600 ps-3'>
                            I have reviewed the above information and agree to proceed with the Rocket Inventory Audit.
                        </span>
                    </div>
                    {isChecked &&
                        <div className='text-center mt-3'>
                            <button className='SaveBtn' onClick={ProceedAudit}>Proceed</button>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}
