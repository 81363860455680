import React, { Fragment, useState, useRef, useEffect } from 'react'
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import { Camelcase, typewatch } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import Deallocatation from './deallocatation';
import { LogInSchoolID } from '../../JS/Common';
import PageHead from '../../Components/PageHead';

export function DeviceAllocate() {
    const suggestionBoxUserRef = useRef(null);
    const suggestionBoxDeviceRef = useRef(null);
    const [SuggestionBoxArrayForDevice, SetSuggestionBoxArrayForDevice] = useState([]);
    const [SuggestionBoxArrayForUser, SetSuggestionBoxArrayForUser] = useState([]);
    const [Alerts, setAlerts] = useState("");
    const [Errormsg, setErrormsg] = useState("");
    const [ErrormsgForDeallocation, setErrormsgForDeallocation] = useState("");
    const [AttachDevice, setAttachDevice] = useState({
        smallloder: "d-none",
        devicesearchstring: "",
        ShowSuggestionfordevice: "d-none",
        AttachDevices: [],
        Notes: ''
    });
    const [AllocationDeviceID, setAllocationDeviceID] = useState('')
    const [AttachUser, setAttachUser] = useState({
        smallloder: "d-none",
        usersearchstring: "",
        ShowSuggestionforuser: "d-none",
        AttachDevices: [],
    });
    const [valueforUser, setValueforUser] = useState('')
    const [valueforDevice, setValueforDevice] = useState('')
    const [IdForUser, setIdForUser] = useState('')
    const [loading, setLoading] = useState(false);
    const [AssignedFlag, setAssignedFlag] = useState(0)
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionBoxUserRef.current && !suggestionBoxUserRef.current.contains(event.target)) {
                setAttachUser({ ...AttachUser, ShowSuggestionforuser: "d-none" })
            }
        };
        const handleClickOutsideForDevice = (event) => {
            if (suggestionBoxDeviceRef.current && !suggestionBoxDeviceRef.current.contains(event.target)) {
                setAttachDevice((prevdata) => ({ ...prevdata, ShowSuggestionfordevice: "d-none" }))
            }
        };
        const handleWindowClick = (event) => {
            handleClickOutside(event);
            handleClickOutsideForDevice(event);
        };
        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, [])
    async function GetAllDevices(searchstring) {
        if (searchstring == "") {
            searchstring = null;
        }
        typewatch(async function () {
            setLoading(true)
            await ApiGetCall("/allActiveDevice/" + LogInSchoolID + "&" + searchstring).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    var vArray = [];
                    var sugData = responseRs.msg;
                    setLoading(false)
                    if (responseRs.response == "success") {
                        setAttachDevice({ ...AttachDevice, ShowSuggestionfordevice: "" });
                        if (sugData.length != 0) {
                            for (var i = 0; i < sugData.length; i++) {
                                var vhtml = <div className="col-12 p-2 brdr-Btm d-flex justify-content-between" onClick={(e) => handleInputChangeForDevice(e)} deviceid={sugData[i].id} serialnumber={sugData[i].serial_number} Notes={sugData[i].notes ? sugData[i].notes : '-'} AssignedFlag={sugData[i].flag} >
                                    <label>{sugData[i].serial_number}</label> <label className='redText'>{sugData[i].flag}</label>
                                </div>
                                vArray.push(vhtml)
                            }
                        } else {
                            var vhtml = <div className="brdr-Btm font-14 text-center" >
                                No Record Found
                            </div>
                            vArray.push(vhtml);
                        }
                        SetSuggestionBoxArrayForDevice(vArray);
                    }
                    else {
                        setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs} />);
                        setTimeout(() => {
                            setAlerts(<AlertsComp show={false} />)
                        }, 1500);
                    }
                }
            });
        }, 500);
    }
    async function GetAllUsers(searchstring) {
        if (searchstring == "" || searchstring == undefined) {
            searchstring = null;
        }
        typewatch(async function () {
            setLoading(true)
            await ApiGetCall("/allActiveUtilizer/" + LogInSchoolID + "&" + searchstring).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    var vArray1 = [];
                    var sugData = responseRs.msg;
                    setLoading(false)
                    if (responseRs.response == "success") {
                        setAttachUser({ ...AttachUser, ShowSuggestionforuser: "" });
                        if (sugData.length != 0) {
                            for (var i = 0; i < sugData.length; i++) {
                                var html = <div className="col-12 p-2 brdr-Btm text-start" onClick={(e) => handleInputChangeForUser(e)} userid={sugData[i].id} firstname={sugData[i]?.device_user_first_name + ' ' + (sugData[i]?.device_user_middle_name == null ? '' : sugData[i]?.device_user_middle_name) + ' ' + sugData[i]?.device_user_last_name} >
                                    {sugData[i].device_user_first_name && (Camelcase(sugData[i].device_user_first_name))}&nbsp;{sugData[i].device_user_middle_name && (Camelcase(sugData[i].device_user_middle_name))}&nbsp;{sugData[i].device_user_last_name && (Camelcase(sugData[i].device_user_last_name))}&nbsp;{'(' + sugData[i].student_num + ')'}
                                </div>
                                vArray1.push(html)
                            }
                        } else {
                            var vhtml = <div className="brdr-Btm font-14 text-center" >
                                No Record Found
                            </div>
                            vArray1.push(vhtml);
                        }
                        SetSuggestionBoxArrayForUser(vArray1);
                    }
                    else {
                        setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs} />);
                        setTimeout(() => {
                            setAlerts(<AlertsComp show={false} />)
                        }, 1500);
                    }
                }
            });
        }, 500);
    }
    function handleInputChangeForDevice(e) {
        const id = e.currentTarget.attributes[1].value;
        const value = e.currentTarget.attributes[2].value;
        const notes = e.currentTarget.attributes[3].value;
        if (e.currentTarget.attributes.length == 5) {
            const assignedflag = e.currentTarget.attributes[4].value;
            if (assignedflag == 'assigned') {
                setAssignedFlag(1)
            } else {
                setAssignedFlag(0)
            }
        } else {
            setAssignedFlag(0)
        }
        setAttachDevice({ ...AttachDevice, ShowSuggestionfordevice: "d-none", Notes: notes });
        setValueforDevice(value)
        setAllocationDeviceID(id)
        setErrormsg("");
    };
    function handleInputChangeForUser(e) {
        const id = e.currentTarget.attributes[1].value;
        const value = e.currentTarget.attributes[2].value;
        setAttachUser({ ...AttachUser, ShowSuggestionforuser: "d-none" });
        setValueforUser(value)
        setIdForUser(id)
        setErrormsg("");
    };
    async function AllocationSave(flag) {
        var vArray = [];
        var vArray = [];
        var vjson = {};
        vjson['userid'] = IdForUser;
        vjson['deviceid'] = AllocationDeviceID;
        vArray.push(vjson);
        if (IdForUser == '' || AllocationDeviceID == '') {
            setErrormsg('Please choose a value from the suggestion box, and make sure not to leave it blank.')
            return
        }
        var raw = JSON.stringify({
            Flag: flag,
            DeviceArray: vArray,
            Schoolid: LogInSchoolID,
            AssignedFlag: AssignedFlag
        });
        setLoading(true);
        await ApiPostCall("/DeviceAllocationToUSer", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result)
                if (responseRs.response == "success") {
                    var alertmsg = 'Device Allocated Successfully';
                    setAlerts(<AlertsComp show={true} variant="success" msg={alertmsg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                        setValueforDevice('')
                        setValueforUser('')
                        setAttachDevice({ ...AttachDevice, Notes: '' })
                    }, 2000);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }

    return (
        <Fragment>
            <PageHead heading={"Device Allocation"}
                loading={loading} Alerts={Alerts} showsearch={false} />
            <div className="container-fluid px-0" id="GridDiv">
                <div className="GridBox px-3 pb-3 pt-3">
                    <div className='row'>
                        <div className='col-md-7 '>
                            <div className='greyBox'>
                                <h5 className='col-md-12 px-2 fw-600 mb-0'>Allocate Devices</h5>
                                <hr className='mb-0' />
                                <div className="col-12 py-2 text-center px-2">
                                    <label className='text-justify FormLabel'>To allocate devices to specific users, first choose the desired device from the left input. Next, select the intended user from the right input. Repeat these steps for every device-user assignment you need to make. Once you're done, click the 'Allocate Device' button to confirm all the allocations. Please keep in mind that a device can only be allocated to a single user at a time, but a user can have multiple devices allocated to them.</label>
                                </div>
                                <hr />
                                <div>
                                    <div className="row pb-2 pt-4 align-items-center RowClass">
                                        <div className="px-3 col-md-6 row align-items-center">
                                            <div className='col-md-12 fw-600 ps-2'>User</div>
                                            <div className='col-md-11'></div>
                                            <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                                                <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                                                <input className="form-control" autoComplete="off" type="text" placeholder="Search User" value={valueforUser}
                                                    onKeyUp={(e) => GetAllUsers(e.target.value)} onChange={(e) => setValueforUser(e.target.value)} />
                                                <div className={`SuggestionBoxDiv ${AttachUser.ShowSuggestionforuser}`} ref={suggestionBoxUserRef} >
                                                    {SuggestionBoxArrayForUser}
                                                </div>
                                            </form>
                                        </div>

                                        <div className='px-3 col-md-6 row align-items-center '>
                                            <div className='col-md-12 fw-600 ps-2'>Device</div>

                                            <div className='col-md-11'></div>
                                            <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                                                <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                                                <input className="form-control" autoComplete="off" type="text" placeholder="Search Device" value={valueforDevice}
                                                    onKeyUp={(e) => GetAllDevices(e.target.value)} onChange={(e) => setValueforDevice(e.target.value)} />
                                                <div className={`SuggestionBoxDiv ${AttachDevice.ShowSuggestionfordevice}`} ref={suggestionBoxDeviceRef} >
                                                    {SuggestionBoxArrayForDevice}
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                    <div className='ps-2'><b>Notes:</b>&nbsp;{AttachDevice.Notes || '-'}</div>
                                    <div className='col-md-12 ps-3 pt-2 redText row'>{Errormsg} </div>
                                </div>

                                <div className="col-12 text-center py-5">
                                    <img src="https://tablepadscdn.azureedge.net/Localimages/loder.svg" className="m-auto pe-3 d-none" id="SmallLoader" alt="Loader" />
                                    <button className='SaveBtn' onClick={() => AllocationSave(1)}>Allocate Device</button>
                                    <label className="ms-2 cursor-pointer" onClick={(e) => window.location.reload()}><u>Cancel</u></label>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='greyBox marginDetailsPopup'>
                                <Deallocatation AllocationSave={AllocationSave} ErrormsgForDeallocation={ErrormsgForDeallocation} setErrormsgForDeallocation={setErrormsgForDeallocation} AssignedFlag={AssignedFlag} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}



