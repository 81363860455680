import React from 'react'

function ThankYouPage() {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const schoolnumber = pathArray[2];
    const ticketNumber = pathArray[3];
    const guid = pathArray[4];
    const baseurl = window.location.origin;
    const url = `${baseurl}/ticket/${schoolnumber}/${ticketNumber}/${guid}`;
    return (
        <>
            <div className='container m-auto' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <h3 className='py-5 text-center'>
                    <p className='pt-1' >Your ticket has been generated successfully.</p>
                    <p className='pt-1'>You can check Ticket status by clicking <a href={url}><u>here</u>.</a></p>
                    <p className='pt-1'>You will receive a notification on your email when any action is taken on your Ticket.</p>
                    <p className='pt-1'> Thank You!</p>
                </h3>
            </div>
        </>
    )
}

export default ThankYouPage