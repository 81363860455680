import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { HideLoder, LogInSchoolID, LogInUserID, ShowLoder, typewatch } from '../../../JS/Common';
import { ApiGetCall } from '../../../JS/Connector';
import AlertsComp from '../../../Components/AlertsComp';

function ImportCustomWithUser() {
    const InventoryRef = useRef();
    const BaseUrl = process.env.REACT_APP_Base_URL;
    var ciphertext = Cookies.get('ciphertext');
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", ciphertext);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [NewAlerts, setNewAlerts] = useState("");
    const [FileName, setFileName] = useState("");
    const [FileNameError, setFileNameError] = useState("");
    const [headers, setHeaders] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [Errormsg, setErrormsg] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [SuggestionBoxArray, setSuggestionBoxArray] = useState([]);
    const [innerload, setinnerload] = useState(false);
    const suggestionBoxRef = useRef(null);
    const [suggestionBoxIndex, setSuggestionBoxIndex] = useState(null);
    const [searchValues, setSearchValues] = useState(Array(headers.length).fill(''));
    const [ListOfType, setListOfType] = useState([])
    const [ActiveDeviceVal, setActiveDeviceVal] = useState('')
    const [LoanerDeviceVal, setLoanerDeviceVal] = useState('')
    const [DecommissionedDeviceVal, setDecommissionedDeviceVal] = useState('')
    const [SuccessMsg, setSuccessMsg] = useState('')
    const [smallLoder, setSmallLoder] = useState(false)
    const [errorMessages, setErrorMessages] = useState([]);
    const [ParentalYesVal, setParentalYesVal] = useState('')
    const [ParentalNoVal, setParentalNoVal] = useState('')
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
                setSuggestionBoxIndex(null);
            }
        };
        const handleWindowClick = (event) => {
            handleClickOutside(event);
        };
        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, [])
    function ShowFileName() {
        const fileInput = InventoryRef.current;
        const files = fileInput.files[0];
        if (!files) {
            return;
        }
        const reader = new FileReader();
        reader.readAsText(files);
        const filename = files.name;
        var extension = filename?.split('.')?.pop();
        if (extension == "csv") {
            setFileName(filename);
            setFileNameError("")
        } else {
            setFileName("");
            setFileNameError("You can only upload CSV files")
            return
        }
        reader.onload = function (e) {
            const csv = e.target.result;
            const parsed = Papa.parse(csv);
            if (parsed.data.length > 0) {
                const enteredHeaders = parsed.data[0];
                setHeaders(enteredHeaders);
                GetAllAvailableFields()
            } else {
                setHeaders([]);
            }
        };
    }
    function handleUpload() {
        cancelBth();
        const fileInput = InventoryRef.current;
        if (fileInput) {
            fileInput.click();
        }
    }
    async function GetAllAvailableFields() {
        await ApiGetCall("/getCsvHeadersForSchool/" + LogInSchoolID + '&' + 3).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setListOfType(responseRs)
                setLoading(false);
            }
        });
    }
    const getColor = (suggestion) => {
        if (suggestion.name == 'excluded_field') return 'gray';
        switch (suggestion.flag) {
            case 1:
                return 'black';
            case 2:
                return 'green';
            case 3:
                return 'blue';
            default:
                return 'black';
        }
    };
    async function GetAvailableFields(searchString, headerId) {
        if (searchString == "") {
            searchString = null;
        }
        setSuggestionBoxArray([]);
        typewatch(async function () {
            setinnerload(true);
            const searchWords = searchString ? searchString.split(' ') : [];
            var filteredList = ListOfType;
            if (searchString != null) {
                filteredList = ListOfType.filter(suggestion =>
                    searchWords.every(word =>
                        suggestion.name.toLowerCase().includes(word.toLowerCase())
                    )
                );
            }
            var sugArray = filteredList.length > 0 ? (
                filteredList.map((suggestion, index) => (
                    <div className="row brdr-Btm font-14" key={index}>
                        <div className="col-12 px-3 py-2" onClick={(e) =>
                            handleSuggestionClick(suggestion.unique_id, suggestion.name, headerId,
                                suggestion.flag, suggestion.value_type, suggestion.structure_array)}>
                            <label style={{ color: getColor(suggestion) }}>
                                {suggestion.name?.replace(/_/g, ' ')?.replace(/\b\w/g, c => c.toUpperCase())}
                            </label>
                        </div>
                    </div>
                ))
            ) : (
                <div className="row brdr-Btm font-14">
                    <div className="col-12 text-center" style={{ padding: "8px 15px" }}>
                        <label>No Record Found</label>
                    </div>
                </div>
            );
            setSuggestionBoxArray(sugArray);
            setSuggestionBoxIndex(headerId);
            setinnerload(false);
        }, 300);
    }
    const handleSuggestionClick = (id, name, headerId, sflag, vtype, sarray) => {
        const isValueAlreadyUsed = selectedFields.some(field => field.name == name);
        if (isValueAlreadyUsed && name !== 'excluded_field') {
            const updatedErrorMessages = [...errorMessages];
            updatedErrorMessages[headerId] = 'This value is already used. Please select a different value.';
            setErrorMessages(updatedErrorMessages);
            return;
        } else {
            setErrorMessages('');
        }

        setSuggestionBoxIndex(null);
        const updatedSearchValues = [...searchValues];
        updatedSearchValues[headerId] = name;
        setSearchValues(updatedSearchValues);

        const selectedItem = {
            id: headerId,
            name: name,
            flag: name == "excluded_field" ? 4 : sflag,
            value_type: name == "excluded_field" ? "" : vtype,
            structure_array: name == "excluded_field" ? [] : sarray,
            header: headers[headerId]
        };
        const existingIndex = selectedFields.findIndex(item => item.id == headerId);
        if (existingIndex !== -1) {
            const updatedSelectedFields = [...selectedFields];
            updatedSelectedFields[existingIndex] = selectedItem;
            setSelectedFields(updatedSelectedFields);
        } else {
            setSelectedFields([...selectedFields, selectedItem]);
        }
    };
    const handleInputChange = (e, index) => {
        const updatedSearchValues = [...searchValues];
        const value = e.target.value;
        updatedSearchValues[index] = value;
        setSearchValues(updatedSearchValues);
        if (value == "") {
            const updatedSelectedFields = selectedFields.filter(item => item.id != index);
            setSelectedFields(updatedSelectedFields);
        }
        GetAvailableFields(value, index);
    };
    async function ImportCSV(e) {
        setErrorMessages([])
        setErrormsg('');
        const nameCounts = {};
        var vArray = [];
        var hasInventoryStatus = false;
        var hasParentalYesNo = false;
        var isValidInventoryStatus = false;
        var isValidParentalStatus = false;
        {
            selectedFields?.map((item, i) => {
                var vjson = {};
                vjson['name'] = item.name;
                vjson['flag'] = item?.flag;
                vjson['header'] = item?.header;
                vjson['value_type'] = item?.value_type;
                vjson['structure_array'] = item?.structure_array;
                vjson['active_value'] = item.name == 'device_status' ? ActiveDeviceVal : null;
                vjson['loaner_value'] = item.name == 'device_status' ? LoanerDeviceVal : null;
                vjson['decommission_value'] = item.name == 'device_status' ? DecommissionedDeviceVal : null;
                vjson['parental_yes_value'] = item.name == 'parental_coverage' ? ParentalYesVal : null;
                vjson['parental_no_value'] = item.name == 'parental_coverage' ? ParentalNoVal : null;
                vArray.push(vjson)
                if (item.name != 'excluded_field') {
                    nameCounts[item?.name] = (nameCounts[item?.name] || 0) + 1;
                }
                if (item.name == 'device_status') {
                    hasInventoryStatus = true;
                    if (ActiveDeviceVal || LoanerDeviceVal || DecommissionedDeviceVal) {
                        isValidInventoryStatus = true;
                    }
                }
                if (item.name == 'parental_coverage') {
                    hasParentalYesNo = true;
                    if (ParentalYesVal || ParentalNoVal) {
                        isValidParentalStatus = true;
                    }
                }
            })
        }
        const duplicateNames = Object.keys(nameCounts).filter(name => nameCounts[name] > 1);
        const duplicateNamesLength = duplicateNames.length;
        const hasSerialNumber = vArray.some(item => item.name == 'serial_number');

        const hasStudentNum = vArray.some(item => item.name == 'student_num');

        if (selectedFields.length != headers.length) {
            setErrormsg('Please choose a value from the suggestion box, and make sure not to leave it blank');
            return
        } else if (duplicateNamesLength != 0) {
            setErrormsg('Ensure that each field is used only once');
            return;
        } else if (!hasSerialNumber) {
            setErrormsg('Serial Number field is required');
            return;
        } else if (!hasStudentNum) {
            setErrormsg('Student Number field is required because you are importing a CSV with user data');
            return;
        } else if (hasStudentNum && (!vArray.some(item => item.name == 'device_user_first_name'))) {
            setErrormsg('Device User first name is required when the student number is present');
            return;
        } else if (hasInventoryStatus && !isValidInventoryStatus) {
            setErrormsg('At least one input is required for inventory status');
            return;
        } else if (hasParentalYesNo && !isValidParentalStatus) {
            setErrormsg('At least one input is required for parental coverage');
            return;
        }
        else {
            setErrormsg('')
        }
        const flagTwoObjects = vArray?.filter(item => item.flag == 1);
        const flagTwoLength = flagTwoObjects.length;
        const customHeadersJson = JSON.stringify(vArray);
        e.preventDefault();
        const formdata = new FormData();
        const fileInput = InventoryRef.current;
        const files = fileInput.files[0];
        formdata.append('schoolID', LogInSchoolID);
        formdata.append('userID', LogInUserID);
        formdata.append('csvFlag', flagTwoLength != 0 ? 2 : 1); //2 means with user 1 means without user
        formdata.append('customHeaders', customHeadersJson);
        formdata.append('file', files);

        if (files == undefined) {
            setFileName("First, import the CSV file.");
        } else {
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };
            try {
                setSmallLoder(true);
                const responsePromise = fetch(`${BaseUrl}/uploadCustomCSV`, requestOptions);
                const timeout = setTimeout(function () {
                    setSuccessMsg('CSV file is currently being processed for import into the system. You will receive an email notification once the upload is complete');
                    setTimeout(function () {
                        setSuccessMsg('');
                        setFileName('');
                        setHeaders([]);
                        setSmallLoder(false);
                        setSelectedFields([]);
                        InventoryRef.current.value = null;
                        return;
                    }, 10000);
                    responsePromise.catch(() => {
                    });
                }, 5000);
                const response = await responsePromise;
                clearTimeout(timeout);
                const result = await response.text();
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'CSV imported Successfully'} />);
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />);
                        navigate('/manage-device');
                        setSmallLoder(false);
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            } catch (error) {
                console.log('error', error);
            }
        }
    }
    const cancelBth = () => {
        setHeaders([]);
        setFileName('');
        InventoryRef.current.value = null;
        setErrormsg('');
        setSearchValues([]);
        setErrorMessages([])
        setSelectedFields([])
    }
    const handleHover = () => {
        setIsHovered(!isHovered);
    };
    return (
        <div>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 m-auto text-center d-flex flex-column align-items-center">
                        <div className='col-md-4'>
                            <form onSubmit={(e) => e.preventDefault()} >
                                <input type="file" ref={InventoryRef} name="upload_filecustomdevices" id="UploadFile" accept='.csv' onChange={ShowFileName} />
                                <button
                                    type="submit"
                                    className='UploadCSVBtn buttonclssMobile w-100 cursor-pointer'
                                    style={{ backgroundColor: FileName != '' ? '#3CBBA5' : '', color: FileName != '' ? '#fff' : '' }}
                                    onClick={FileName == '' ? handleUpload : null}>
                                    {FileName != '' ? 'CSV uploaded' : 'Import Devices With Users'}
                                    {FileName == '' && <img src="/images/ImportInventory.svg" className="img-fluid ps-3" />}
                                </button>
                            </form>
                        </div>
                        <div className='col-md-12 pe-0'>
                            <div className="pt-2 ps-2" style={{ fontWeight: "600", color: 'green' }}>{FileName}</div>
                            <div className="pt-2 ps-2" style={{ fontWeight: "600", color: 'green' }}>{FileNameError}</div>
                        </div>
                    </div>
                </div>
            </div>
            {headers.length > 0 && (
                <div className='px-5 py-4'>
                    <div className='GridBox p-3'>
                        <div className='col-md-12'>
                            <div className="d-flex font-12 justify-content-end pe-1 pb-1">
                                <label className='ps-3' style={{ color: 'black' }}>*Device Fields</label>
                                <label className='ps-3' style={{ color: 'green' }}>*Student Fields</label>
                                <label className='ps-3' style={{ color: 'blue' }}>*Custom Fields</label>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="row px-3">
                                <hr className='mb-0 mt-2' style={{ opacity: '0.1' }} />
                                <div className="row mt-2 pe-0">
                                    <div className='font-16 fw-600 col-md-6'>Your CSV Headers</div>
                                    <div className='font-16 fw-600 col-md-6'>Available Fields</div>
                                </div>
                                <hr className='mb-0 mt-2' style={{ opacity: '0.1' }} />
                                <div className='row mt-2 pe-0 align-items-center'>
                                    {headers.map((header, index) => (
                                        <div className='row align-items-center' key={index}>
                                            <div className='col-md-6 align-items-center'>
                                                <label key={index}>{header}</label>
                                            </div>
                                            <div className='col-md-5 align-items-center py-1'>
                                                <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                                                    <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon">
                                                        <i className="bi bi-search searchIconClass"></i>
                                                    </div>
                                                    <input className="form-control" autoComplete="off" type="text"
                                                        placeholder="Search Field" style={{ backgroundColor: '#f9fafb' }}
                                                        value={searchValues[index]?.replace(/_/g, ' ')?.replace(/\b\w/g, c => c.toUpperCase())}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        onFocus={(e) => { GetAvailableFields(searchValues[index], index) }}
                                                    />
                                                    <div className={`SuggestionBoxDiv ${suggestionBoxIndex == index ? "" : "d-none"}`} style={{ zIndex: '1' }} ref={suggestionBoxRef}>
                                                        {innerload && (
                                                            <div className='row'>
                                                                <div className="col-12 text-center pt-2">  <img src="images/loder.svg" alt="loader" style={{ width: "30px" }} /> </div>
                                                            </div>
                                                        )}
                                                        {SuggestionBoxArray}
                                                    </div>
                                                </form>
                                                {errorMessages[index] && (
                                                    <div className="Emailinvalid mt-1 ms-2">  {errorMessages[index]} </div>
                                                )}
                                            </div>
                                            {searchValues[index] == 'device_status' && (
                                                <div className='col-12'>
                                                    <div className='row py-1'>
                                                        <div className='col-md-5'>
                                                            <label className=' ps-3 font-13'>Value of Active Device</label>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <input type='text' className="form-control mt-1" autoComplete='off' name='active'
                                                                value={ActiveDeviceVal} onChange={(e) => { setActiveDeviceVal(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className='row py-1'>
                                                        <div className='col-md-5'>
                                                            <label className=' ps-3 font-13'>Value of Loaner Device</label>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <input type='text' className="form-control mt-1" autoComplete='off' name='active'
                                                                value={LoanerDeviceVal} onChange={(e) => { setLoanerDeviceVal(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className='row py-1'>
                                                        <div className='col-md-5'>
                                                            <label className=' ps-3 font-13'>Value of Decommissioned Device</label>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <input type='text' className="form-control mt-1" autoComplete='off' name='active'
                                                                value={DecommissionedDeviceVal} onChange={(e) => { setDecommissionedDeviceVal(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {searchValues[index] == 'parental_coverage' && (
                                                <div className='col-12'>
                                                    <div className='row py-1'>
                                                        <div className='col-md-5'>
                                                            <label className=' ps-3 font-13'>Value of Parental Coverage Yes</label>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <input type='text' className="form-control mt-1" autoComplete='off' name='active'
                                                                value={ParentalYesVal} onChange={(e) => { setParentalYesVal(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className='row py-1'>
                                                        <div className='col-md-5'>
                                                            <label className=' ps-3 font-13'>Value of Parental Coverage No</label>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <input type='text' className="form-control mt-1" autoComplete='off' name='active'
                                                                value={ParentalNoVal} onChange={(e) => { setParentalNoVal(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    {Errormsg != '' && (<div className='redText mt-4'>{Errormsg}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center pt-5">
                            {smallLoder == true && (<img src="/images/loder.svg" className="mx-1" style={{ width: "30px" }} />)}
                            <button className='SaveBtn align-items-center px-5 ms-3' onClick={(e) => ImportCSV(e)}><span className='pt-2 fw-600'>Save</span></button>
                            <button className={`Cancel-btn ms-3 ${isHovered ? 'hovered' : ''}`} onClick={(e) => cancelBth()}
                                onMouseEnter={handleHover} onMouseLeave={handleHover}  >
                                <span className='pt-2 fw-600'>Cancel</span>
                            </button>
                        </div>
                        <div className='py-2 text-center font-16' style={{ color: 'green' }}>{SuccessMsg}</div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ImportCustomWithUser
