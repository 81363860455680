import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap';
import { MMDDYYYY } from '../../../JS/Common';
import { ApiGetCall } from '../../../JS/Connector';

export default function SchoolTable(props) {
    const [AllData, setAllData] = useState({
        schoolinvoice: { data: [], pagination: {}, loader: false },
    });
    useEffect(() => {
        setAllData({ schoolinvoice: { data: props?.schoolinvoiceData?.data?.data, pagination: props?.schoolinvoiceData?.data?.pagination, loader: props?.schoolinvoiceData?.loader } })
    }, [props?.schoolinvoiceData])
    const [currentPageForSchoolinvoice, setCurrentPageForSchoolinvoice] = useState(1);
    const totalPages = AllData?.schoolinvoice?.pagination?.last_page || 0
    const showPagination = AllData?.schoolinvoice?.data?.length > 0;

    const handlePageChange = (pageDir) => {
        let plusMinus = currentPageForSchoolinvoice;
        if (pageDir === 'next') {
            plusMinus += 1;
        } else {
            plusMinus -= 1;
        }
        setAllData({ ...AllData, schoolinvoice: { ...AllData?.schoolinvoice, loader: true } })
        var locationid = props.LocationValue == 0 ? null : props.LocationValue;
        var schoolvalue = props.SchoolValue == 0 ? null : props.SchoolValue;
        ApiGetCall("/getSchoolInvoices/" + MMDDYYYY(props.startDate) + "&" + MMDDYYYY(props.endDate) + "&" + locationid + "&" + schoolvalue + '&' + plusMinus).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setAllData({ ...AllData, schoolinvoice: { ...AllData?.schoolinvoice, data: responseRs?.schoolinvoice?.data, pagination: responseRs?.schoolinvoice?.pagination, loader: false }, })
            }
        });
        setCurrentPageForSchoolinvoice(plusMinus)
    }
    return (
        <React.Fragment>
            <div className="col-md-6 p-3">
                <div className="greyBox" style={{ height: "100%" }}>
                    <h5 className="fw-bold ps-2 mb-0">School Invoices</h5>
                    <hr className="my-2" />
                    <div className="w-100 px-2">
                        <div className="row py-3">
                            <div className="col-md-6 col-3 fw-600">School</div>
                            <div className="col-md-2 col-3 fw-600">Invoices</div>
                            <div className="col-md-2 col-3 fw-600">Tickets</div>
                            <div className="col-md-2 col-3 fw-600">Total</div>
                        </div>
                        {props?.schoolinvoiceData?.loader || AllData.schoolinvoice?.loader ? <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div> : <>
                            {AllData.schoolinvoice?.data?.length != 0 ?
                                AllData.schoolinvoice?.data?.map((item, i) => {
                                    return <div className="row py-2 admin-table-border-top" key={i}>
                                        <div className="col-md-6 col-3">{item.name}</div>
                                        <div className="col-md-2 col-3">{item.InvoiceCount}</div>
                                        <div className="col-md-2 col-3">{item.count}</div>
                                        <div className="col-md-2 col-3 wordwrap">${item.Invoice.toFixed(2)}</div>
                                    </div>
                                })
                                :
                                <div className="col-12 text-center p-2 admin-table-border-top">
                                    No Record Found
                                </div>
                            }</>}
                        {showPagination && (
                            <Pagination>
                                {currentPageForSchoolinvoice != 1 ?
                                    <FontAwesomeIcon icon={faAngleLeft} className='cursor-pointer mx-3' onClick={() => { handlePageChange('prev') }} style={{ height: '20px' }} />
                                    :
                                    <FontAwesomeIcon icon={faAngleLeft} className='mx-3' style={{ height: '20px', opacity: '0.3' }} />
                                }
                                {currentPageForSchoolinvoice < totalPages ?
                                    <FontAwesomeIcon icon={faAngleRight} className='cursor-pointer mx-3' onClick={() => { handlePageChange('next') }} style={{ height: '20px' }} />
                                    :
                                    <FontAwesomeIcon icon={faAngleRight} className='mx-3' style={{ height: '20px', opacity: '0.3' }} />
                                }
                            </Pagination>
                        )}

                    </div>

                </div>
            </div>
        </React.Fragment >
    )
}
