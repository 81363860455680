import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { SchoolReports } from '../SchoolReports';
import { ErrorPage } from '../404Error';
export default function Access10() {
    return (
        <Routes>
            <Route exact path="/" element={<SchoolReports />} />
            <Route exact path="/reports" element={<SchoolReports />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    )
}
