import React from 'react';

const CustomProgress = ({ percentage, size, strokeWidth, strokeColor, hasBackground }) => {
    const radius = (size.width - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (Math.min(percentage, 100) / 100) * circumference;

    return (
        <svg width={size.width} height={size.height} viewBox={`0 0 ${size.width} ${size.height}`}>
            {hasBackground && (
                <circle
                    cx={size.width / 2}
                    cy={size.height / 2}
                    r={radius}
                    stroke="#e6e6e6"
                    strokeWidth={strokeWidth}
                    fill="none"
                />
            )}
            <circle
                cx={size.width / 2}
                cy={size.height / 2}
                r={radius}
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                fill="none"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                style={{ transition: 'stroke-dashoffset 0.5s ease' }}
            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dy=".3em"
                fontSize="20px"
                fill={strokeColor}
            >
                {`${percentage}%`}
            </text>
        </svg>
    );
};

export default CustomProgress;
