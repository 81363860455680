import React, { useContext, useEffect, useState } from 'react'
import { ApiPostCall } from '../../JS/Connector';
import { LogInSchoolID } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import { UserContext } from '../../App';

export default function MasterinventoryDetails({ setLoading, setNewAlerts }) {
    const { UserData, setUserData } = useContext(UserContext);
    const [MasterInventoryFlag, setMasterInventoryFlag] = useState(UserData.inventoryflag)
    useEffect(() => {
        setMasterInventoryFlag(UserData.inventoryflag)
    }, [UserData.inventoryflag])
    async function MasterInventroryPartsCall() {
        setLoading(true);
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            InventoryFlag: MasterInventoryFlag
        })
        await ApiPostCall("/masterInventoryShowHide", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Master Inventory Setting Updated Successfully"} />);
                    setTimeout(() => {
                        setUserData((prevUserData) => ({ ...prevUserData, inventoryflag: MasterInventoryFlag, }));
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    return (
        <div className="greyBox h-100 p-3 align-items-center" >
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle pb-2'>Master Inventory</span>
            </div>
            <hr className='my-0' />
            <div className="p-1 pe-0 mt-1 pt-3">
                <label>Would you like to display the master inventory section in manage parts?  </label>
                <div className='col-md-12 mt-3 d-flex'>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name='MasterInventory'
                            onChange={(e) => setMasterInventoryFlag(1)}
                            defaultChecked={MasterInventoryFlag == 1}
                        />
                        <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check ms-5">
                        <input
                            className="form-check-input"
                            type="radio"
                            name='MasterInventory'
                            onChange={(e) => setMasterInventoryFlag(0)}
                            defaultChecked={MasterInventoryFlag != 1}
                        />
                        <label className="form-check-label">No</label>
                    </div>
                </div>
                <div className="col-12 pt-4">
                    <button className="SaveBtn" onClick={MasterInventroryPartsCall}> Confirm   </button>
                </div>
            </div>
        </div>
    )
}
