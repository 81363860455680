import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MDBSwitch } from "mdb-react-ui-kit";
import AlertsComp from "../../Components/AlertsComp";
import { Camelcase, HideLoder, LogInUserID, ShowLoder, MMDDYYYY } from "../../JS/Common";
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import { staffdetailscontent } from ".";
import { useContext } from "react";
import Pagination from "react-js-pagination";
import { Logout } from "../Signout";
import CustomizationPopup from "../../Components/CustomizationPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Popup from "../../Components/Popup";
export function StaffAddUpdate(props) {
    const { allData, setAllData } = useContext(staffdetailscontent);
    const [isShow, setisShow] = useState(false);
    const [NewAlerts, setNewAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const [CustomizationModal, setCustomizationModal] = useState(false)
    useEffect(() => {
        const selectedArray = [
            ...props.selectedIdsAll.map((id) => ({ id })),
        ];
        const idAndSubtotalArray = selectedArray.map(({ id }) => ({ id: id }));
        props.setSelectedItems(idAndSubtotalArray);
        if (idAndSubtotalArray.length === 0) {
            props.setCreateGroupModal(false)
            props.setCreateBatchbuttonVisible(false)
        }
    }, [props.selectedIdsAll])
    useEffect(() => {
        props.GetAccessDevice(null, null);
        ApiGetHeaders(3, props.setColumnNames)
    }, []);
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "avatar": item.avatarimg != null ? <img src={`${BucketS3Link}avtar/${item.avatarimg}`} height='25px' /> : '-',
            "Name": item.first_name && (Camelcase(item.first_name + ' ' + item.last_name)) || '-',
            "access_type": item?.accesstype?.access_type || '-',
            "email": item.email || '-',
            "created_at": MMDDYYYY(item.created_at) || '-'
        };
        return contentMap[columnName] || null;
    }
    async function DeactivateStaff(memberid, flag) {
        var loginuserid = LogInUserID;
        setLoading(true);
        await ApiPostCall("/deleteUser/" + memberid + "&" + flag, "").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setisShow(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    var msg = flag == 1 ? 'User Activated Successfully' : 'User Deactivated Successfully';
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    if (loginuserid == memberid) {
                        Logout();
                    } else {
                        setTimeout(() => {
                            setNewAlerts(<AlertsComp show={false} />)
                            props.GetAccessDevice(null, null);
                        }, 2000);
                    }

                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        })
    }
    function handleChange(e, memberid, itemstatus) {
        const { name, checked } = e.target;

        let tempuser = props.CurrentUsers.map(user =>
            user.name === name ? { ...user, isChecked: checked } : user
        );
        props.setUsers(tempuser);

        setAllData({ ...allData, Memberid: memberid })

        if (itemstatus == 1) {
            setisShow(true);
        } else {
            DeactivateStaff(memberid, 1);
        }
    }
    async function GetUserbyid(itemid) {
        setLoading(true);
        await ApiGetCall("/getUserById/" + itemid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                setAllData({
                    ...allData, firstname: responseRs.msg[0].first_name, lastname: responseRs.msg[0].last_name,
                    email: responseRs.msg[0].email, access: responseRs.msg[0].access_type, flag: 2, Memberid: itemid
                })
            }
        });
    }
    function handleHeaderCheckboxChange(e, flag) {
        props.setCreateBatchbuttonVisible(true)
        const isChecked = e.target.checked;
        if (flag == "all") {
            props.setSelectedIdsAll(
                isChecked ? props.CurrentUsers.map((item) => item.id) : []
            );
        }
    };
    function handleCheckboxChange(e, flag, ID) {
        const isChecked = e.target.checked;
        props.setCreateBatchbuttonVisible(true)
        if (flag == "all") {
            props.setSelectedIdsAll((prevIds) => {
                if (isChecked) {
                    return [...prevIds, ID];
                } else {
                    return prevIds.filter((id) => id !== ID);
                }
            });
        }
    }
    return (
        <>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="data-table-container">
                <table className="table data-table">
                    <thead className='GridHeader'>
                        <tr>
                            <th scope="col"> <input className='form-check-input' type="checkbox" checked={props.selectedIdsAll.length === props.CurrentUsers.length} onChange={(e) => handleHeaderCheckboxChange(e, "all")} /></th>
                            {props.columnNames?.map((item) => {
                                const columnName = item.replace(/_/g, ' ');
                                return (
                                    item != 'avatar' && item != 'access_type' ?
                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => props.GetAccessDevice(item == 'Name' ? 'first_name' : item, allData.searchstring)}>
                                            {Camelcase(columnName)}
                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                        </th>
                                        :
                                        <th key={item} scope="col" className='fw-600'>
                                            {Camelcase(columnName)}
                                        </th>
                                )
                            })}
                            <th scope="col" className="text-center fw-600">Status</th>
                            <th scope="col" className='text-center fw-600'>Action<img src="/images/EditHeaderIcon.svg" className="img-fluid ps-2" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.CurrentUsers?.length != 0 ? (
                            props.CurrentUsers?.map((item, i) => {
                                return (<tr key={i} className={`OddEvenClass tableBorderHide ${item.statusFlag}`}>
                                    <td className="py-12">
                                        <input className='form-check-input' type="checkbox" checked={props.selectedIdsAll.includes(item.id)} onChange={(e) => handleCheckboxChange(e, "all", item.id)} batchid={item.id} />
                                    </td>
                                    {props.columnNames?.map(header => (
                                        <td className="py-12" key={header}>
                                            {renderContentForColumn(header, item)}
                                        </td>
                                    ))}
                                    <td className="text-center py-12">
                                        <MDBSwitch className="BgPink cursor-pointer"
                                            name={item.name} memberid={item.id} itemstatus={item.status}
                                            checked={item.status == 1 ? item?.isChecked || true : item?.isChecked || false}
                                            onChange={(e) => handleChange(e, item.id, item.status)}
                                        />
                                    </td>
                                    <td className="text-center py-12">
                                        {item.status == 1 ?
                                            <img src="/images/EditIconNew.svg" className="img-fluid cursor-pointer" title="Edit Staff Member" onClick={(e) => { props.setIsaddupadtepopup(true); GetUserbyid(item.id) }} staffmemberid={item.id} />
                                            :
                                            <img src="/images/DisableEditIcon.svg" className="img-fluid" />
                                        }
                                    </td>
                                </tr>
                                )
                            }))
                            :
                            <tr className="col-12 text-center p-2"><td colSpan={6}>No Record Found</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            {props.showPagination && (
                <Pagination activePage={props.currentPage} totalItemsCount={props.pageRangeCount}
                    pageRangeDisplayed={15} itemsCountPerPage={props.LimitForPagination} onChange={(e) => props.setCurrentPage(e)}
                    color="primary" lastPageText="" firstPageText="" itemClassFirst="MasterClass" itemClassPrev="MasterClass"
                    itemClassNext="MasterClass" itemClassLast="MasterClass" />
            )}
            <Popup isshow={isShow} size={"md"} title={"Deactivate Staff Member"}
                closePopup={(e) => { setisShow(false) }}
                modalBody={"Are you sure Want to deactivate this member?"} handleSave={(e) => DeactivateStaff(allData.Memberid, "2")} visibleSaveBtn={true} btnText={"Yes"} />

            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Staff Members'} modalSize={'lg'} GetCall={(e) => ApiGetHeaders(3, props.setColumnNames)} />
            )}
        </>

    )
}
