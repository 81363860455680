import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function AveragePart(props) {
    var vData = props.schooldata;
    var series = [{
        data: vData.map(item => item.avrg)
    }];
    var labels = vData.map(item => item.school);
    var chartHeight = Math.max(300, vData.length * 5);
    var options = {
        chart: {
            type: 'bar',
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, { seriesIndex, dataPointIndex }) {
                var totalTickets = vData[dataPointIndex].avrg;
                return totalTickets;
            },
        },
        xaxis: {
            categories: labels,
            labels: {
                show: true,
                style: {
                    fontSize: '12px',
                }
            }
        },
        colors: ['#3CBBA5'],
        tooltip: {
            y: {
                formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
                    var totalTickets = vData[dataPointIndex].totalTickets;
                    var ticketAttachmentCount = vData[dataPointIndex].ticketAttachmentCount;
                    var avrgparts = vData[dataPointIndex].avrg;
                    return "Total Tickets : " + totalTickets + "<br/>Attached Parts : " + ticketAttachmentCount + "<br/>Average Parts : " + avrgparts;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    }
                }
            }

        },
        chart: {
            toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: "tickets_distribution_by_school",
                        columnDelimiter: ',',
                        headerCategory: 'School,Amount,Total Tickets',
                        headerValue: '',
                        headerSeries: '',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        },
                        dropShadow: false
                    },
                    svg: {
                        filename: "tickets_distribution_by_school",
                    },
                    png: {
                        filename: "tickets_distribution_by_school",
                    }
                },
                autoSelected: 'zoom'
            },
        }
    };

    return (
        <div className="col-12 text-center  greyBoxShadow" style={{ height: "100%", borderRadius: "10px", backgroundColor: "white" }}>
            <div className="w-100">
                <h4>Average parts per ticket per school</h4>
                <div style={{ overflowX: "scroll" }}>
                    <div className="" style={{
                        width: series[0]?.data?.length > 10 ? "2000px" : "100%",
                    }}>
                        <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
                    </div>
                </div>
            </div>
        </div >
    );
}
