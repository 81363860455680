import React, { useLayoutEffect, useState } from 'react'
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { usePagination } from "@table-library/react-table-library/pagination";
import { ApiGetCall } from '../../JS/Connector';
import Cookies from 'js-cookie';

export default function ErrorLogsPage() {
    const theme = useTheme(getTheme());
    const [search, setSearch] = useState("");
    const [Loader, setLoader] = useState(true);
    const [nodes, setNodes] = useState([]);

    const BucketS3Link = process.env.REACT_APP_CDN_KEY
    useLayoutEffect(() => {
        getLogs()
    }, [])
    const getLogs = async () => {
        await ApiGetCall("/GetGoogleImportErrors/" + parseInt(Cookies.get('schoolid'))).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setNodes(responseRs);
                setLoader(false)
            }
        });
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const data = {
        nodes: nodes?.filter((item) =>
            item.error_from.toLowerCase().includes(search.toLowerCase())
        ),
    };
    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 10,
        },
    });
    const COLUMNS = [
        { label: "Id", renderCell: (item) => <div title={item.id}>{item.id}</div> },
        { label: "School Id", renderCell: (item) => <div title={item.school_id}>{item.school_id}</div> },
        {
            label: "Error From",
            renderCell: (item) =>
                <div title={item.error_from}>{item.error_from}</div>
        },
        { label: "Error Message", renderCell: (item) => <div title={item.error_msg}>{item.error_msg}</div> },
        {
            label: "Created At",
            renderCell: (item) => <div title={item.created_at}>{item.created_at}</div>,
        },
        { label: "File", renderCell: (item) => <a href={BucketS3Link + item.file} target='_black' download="data">Click to View</a> },
    ];
    return (
        <React.Fragment>
            <div className="GridBox m-3 p-4">
                {Loader ? <><div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div></>
                    : <>
                        <div className="row">
                            <div className="col-5">
                                <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping">Search by error from</span>
                                    <input className='form-control' aria-describedby="addon-wrapping" type="text" value={search} onChange={handleSearch} autoComplete='off' />
                                </div>
                                <br />
                            </div>
                        </div>
                        <CompactTable
                            columns={COLUMNS}
                            data={data}
                            theme={theme}
                            pagination={pagination}
                        />
                        <br />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Pages: {pagination.state.getTotalPages(data.nodes)}</span>

                            <span>
                                Page:{" "}
                                {pagination.state.getPages(data.nodes).map((_, index) => (
                                    <button
                                        className='btn btn-outline-primary'
                                        key={index}
                                        type="button"
                                        style={{
                                            fontWeight: pagination.state.page === index ? "bold" : "normal",
                                        }}
                                        onClick={() => pagination.fns.onSetPage(index)}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </span>
                        </div>
                    </>}

            </div>
        </React.Fragment>
    )
}
