import React, { useContext, useEffect, useState } from 'react'
import StaffTickets from './StaffTickets';
import { ReportDataContext } from '../index';

export default function StaffReportsIndex({ setAlerts }) {
    const { AllReportData } = useContext(ReportDataContext);
    return (
        <div className='row'>
            <div className='col-md-12  py-2'>
                <StaffTickets data={AllReportData?.StaffTicketsData?.data} loder={AllReportData?.StaffTicketsData?.loader} setAlerts={setAlerts} />
            </div>
        </div>
    )
}
