import React from 'react'
import ImportCustomWithUser from './Devices/ImportCustomWithUser'
import ImportCustomWithoutUser from './Devices/ImportCustomWithoutUser'
import ImportCustomFieldUsers from './Users/ImportCustomUsers'

export default function CustomCsvImports() {
    return (
        <div>
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle pb-2'>Custom CSV Imports</span>
            </div>
            <hr className='my-0' />
            <div className="row pt-3 px-3">
                <label className="pb-3 ps-0" style={{ fontSize: "13px" }}>
                    After preparing your CSV file with all the necessary data, you can upload it here. Below, you'll find options to match your headers with the corresponding field names. Once you've made your selections, click 'Save' to import the data into your account.</label>
                <label className='redText text-center pb-4'>Ensure the date format is in mm/dd/yyyy within the CSV file.</label>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <ImportCustomWithUser />
                </div>
                <div className='col-md-12'>
                    <ImportCustomWithoutUser />
                </div>
                <div className='col-md-12'>
                    <ImportCustomFieldUsers />
                </div>
            </div>
        </div>
    )
}
