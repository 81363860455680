import React, { useContext, useLayoutEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApiPostCall } from "../../../JS/Connector";
import { LogInSchoolID } from "../../../JS/Common";
import { TableDataContext } from '../index';
export default function TicketCloserTime(props) {
    const { AllDashBoardData, GetEstimationFun } = useContext(TableDataContext);
    const [TicketData, setTicketData] = useState({ device: 0, maintenance: 0, technology: 0 })
    const [Loader, setLoader] = useState(false)
    useLayoutEffect(() => {
        if (AllDashBoardData?.GetEstimation?.data?.length != 0 && AllDashBoardData?.GetEstimation?.data)
            setTicketData({ device: AllDashBoardData?.GetEstimation?.data[0]?.estimated_time_in_days, maintenance: AllDashBoardData?.GetEstimation?.data[2]?.estimated_time_in_days, technology: AllDashBoardData?.GetEstimation?.data[1]?.estimated_time_in_days })
    }, [AllDashBoardData?.GetEstimation?.data])

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (!isNaN(value) && value >= 0) {
            const data = { ...TicketData };
            data[name] = value;
            setTicketData(data);
        }
    }
    const options = {
        chart: {
            id: "double-bar-chart",
        },
        colors: ["#3CBBA5", "#151821"],
        xaxis: {
            categories: ["Device", "Maintenance", "Technology"],
        },
        yaxis: {
            title: {
                text: "No. of days",
            },
            labels: {
                formatter: function (val) {
                    return parseFloat(val)?.toFixed(2);
                },
            },
        },
        chart: {
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top",
                },
            },
        },
        responsive: [
            {
                breakpoint: 200,
                options: {
                    chart: {
                        height: '220',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
            {
                breakpoint: 6000,
                options: {
                    chart: {
                        height: '250',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    }
    const series = [
        {
            name: "Goal",
            data: [TicketData?.device, TicketData?.maintenance, TicketData?.technology],
        },
        {
            name: "Actual",
            data: AllDashBoardData?.actual?.data?.data?.map((value) => parseFloat(value?.toFixed(2))),
        },
    ]

    const Submit = async () => {
        try {
            setLoader(true)
            var raw = JSON.stringify({
                SchoolId: LogInSchoolID,
                Device: TicketData?.device,
                Maintenance: TicketData?.maintenance,
                Technology: TicketData?.technology
            });
            await ApiPostCall("/SetCompleteDaysForTickets", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoader(false);
                    GetEstimationFun();
                    const responseRs = result;
                }
            });
        } catch (error) {
            console.log(error)
            setLoader(false)
        }

    }
    return (
        <React.Fragment >
            <div className="col-12 text-center  greyBoxShadow" style={{ height: "100%" }}>
                <div className=" w-100">
                    <div className="row mt-2 px-2 time" >
                        <div className='Header'>
                            <b className='font-15'>Tickets Closure Time (in days)</b><br />
                        </div>
                    </div>
                    {Loader || AllDashBoardData?.actual?.loader || AllDashBoardData?.GetEstimation?.loader ? <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div> : <>
                        <div className="row py-3 px-2 borderNone">
                            <div className="col-20 pe-0">
                                <div className="ticketCloser" title="Expected repair time">Goal</div>
                            </div>
                            <div className="col-20 px-0">
                                <input type="number" autoComplete="off" name="device" className="form-control" placeholder="Dev..." value={TicketData?.device} onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-20 px-0">
                                <input type="number" autoComplete="off" name="maintenance" className="form-control" placeholder="Tech..." value={TicketData?.maintenance} onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-20 px-0">
                                <input type="number" autoComplete="off" name="technology" className="form-control" placeholder="Maint..." value={TicketData?.technology} onChange={(e) => handleChange(e)}
                                /></div>
                            <div className="col-20 ps-0">
                                <button type="submit" className="btn btn-secondary w-100 ticketCloserBtn" onClick={Submit}>Apply</button>
                            </div>
                        </div>
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="bar"
                        /></>}

                </div>
            </div></React.Fragment >
    )
}
