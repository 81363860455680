import React, { useEffect, useState } from "react";
import { ApiGetCall, ApiGetHeaders } from "../../JS/Connector";
import { CamelCaseWithSpaces, Camelcase, LogInSchoolID, formatDateToMMDDYYYY, typewatch } from "../../JS/Common";
import TicketDetailsPopup from "../../Components/TicketDetailsPopup";
import { DeviceDetailsPopup } from "../../Components/DeviceDetailsPopup";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import CreateShippingCom from "../../Components/CreateShippingCom";
import CustomizationPopup from "../../Components/CustomizationPopup";
import UserDetailsPopup from "../../Components/UserDetailsPopup";
import Cookies from 'js-cookie';
import PageHead from "../../Components/PageHead";
import Tab from "../../Components/Tab";

export function ShipDevices() {
    const navigate = useNavigate();
    const [AllShipDevices, setAllShipDevices] = useState([]);
    const [AllShipDevicesForOpen, setAllShipDevicesForOpen] = useState([]);
    const [TicketTabs, setTicketTabs] = useState({
        OpenTicketsTab: "",
        AwaitingShipTab: "active",
        sortbykey: "",
        sortbyflag: "desc",
    });
    const [searchString, setSearchString] = useState({
        SearchForOpen: '',
        SearchForAwaitingShip: '',
    })
    const [IsModal, setIsModal] = useState({
        TicketPopup: false,
        DevicePopup: false,
        TicketID: "",
        DeviceID: "",
        createbatch: false,
        ShowCreateBatchButton: false,
    });
    const [loading, setLoading] = useState(false);
    const [selectedIdsAll, setSelectedIdsAll] = useState([]);
    const [selectedIdsOpen, setSelectedIdsOpen] = useState([]);
    const [SelectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const showPagination = AllShipDevices.length > 0;
    const showPagination1 = AllShipDevicesForOpen.length > 0;
    const [totalBatches, setTotalBatches] = useState('')
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [UserModal, setUserModal] = useState(false)
    const [Userid, setUserid] = useState('');
    var schoolname = Cookies.get('ShipSchoolName')
    if (schoolname) {
        schoolname = decodeURIComponent(schoolname);
    }

    useEffect(() => {
        ApiGetHeaders(14, setColumnNames)
    }, []);

    useEffect(() => {
        if (TicketTabs.OpenTicketsTab == "active") {
            GetTickets(1, searchString.SearchForOpen ? searchString.searchStringSearchForOpen : null, TicketTabs.sortbykey ? TicketTabs.sortbykey : null);
        } else {
            GetTickets(2, searchString.SearchForAwaitingShip ? searchString.SearchForAwaitingShip : null, TicketTabs.sortbykey ? TicketTabs.sortbykey : null);
        }
    }, [currentPage, LimitForPagination]);

    useEffect(() => {
        const selectedArray = [
            ...selectedIdsAll.map((id) => ({ id })),
            ...selectedIdsOpen.map((id) => ({ id })),
        ];
        const idAndSubtotalArray = selectedArray.map(({ id }) => ({ id: id }));
        setSelectedItems(idAndSubtotalArray);
        if (idAndSubtotalArray.length === 0 && selectedIdsOpen.length === 0) {
            setIsModal({ ...IsModal, ShowCreateBatchButton: false });
        }
        const sumOfTotalBatches = selectedIdsAll.length + selectedIdsOpen.length;
        setTotalBatches(sumOfTotalBatches)
    }, [selectedIdsAll, selectedIdsOpen])

    async function GetTickets(Schoolflag, searchstring, sortbykey) {
        if (!searchstring) searchstring = null;
        if (sortbykey) TicketTabs.sortbyflag = TicketTabs.sortbyflag == "asc" ? "desc" : "asc";
        setTicketTabs({ ...TicketTabs, sortbykey: sortbykey })
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/TicketsForShipping/" + LogInSchoolID + "&" + Schoolflag + "&" + searchstring + "&" + sortbykey + "&" + TicketTabs.sortbyflag + '&' + currentPage + '&' + LimitForPagination).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false)
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs.Tickets.total)
                    if (responseRs.Tickets.data.length != 0) {
                        if (Schoolflag == 2) {
                            setAllShipDevices(responseRs.Tickets.data);
                        } else if (Schoolflag == 1) {
                            setAllShipDevicesForOpen(responseRs.Tickets.data);
                        }
                    } else {
                        setAllShipDevices([]);
                        setAllShipDevicesForOpen([]);
                    }
                }
            });
        }, searchstring != null ? 500 : 0);
    }

    function ShowCreateBatchPopup() {
        setIsModal({ ...IsModal, createbatch: true })
    }

    function handleHeaderCheckboxChange(e, ticketFlag) {
        setIsModal({ ...IsModal, ShowCreateBatchButton: true });
        const isChecked = e.target.checked;
        if (ticketFlag === "all") {
            setSelectedIdsAll(
                isChecked ? AllShipDevices.map((item) => item.id) : []
            );
        } else if (ticketFlag === "open") {
            setSelectedIdsOpen(
                isChecked ? AllShipDevicesForOpen.map((item) => item.id) : []
            );
        }
    };
    function handleCheckboxChange(e, ticketFlag, ticketID) {
        const isChecked = e.target.checked;
        setIsModal({ ...IsModal, ShowCreateBatchButton: true });
        if (ticketFlag === "all") {
            setSelectedIdsAll((prevIds) => {
                if (isChecked) {
                    return [...prevIds, ticketID];
                } else {
                    return prevIds.filter((id) => id !== ticketID);
                }
            });
        } else if (ticketFlag === "open") {
            setSelectedIdsOpen((prevIds) => {
                if (isChecked) {
                    return [...prevIds, ticketID];
                } else {
                    return prevIds.filter((id) => id !== ticketID);
                }
            });
        }
    }
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "ticket_num": (
                <span className='py-12 cursor-pointer' onClick={(e) =>
                    setIsModal({ ...IsModal, TicketPopup: true, TicketID: item.id, DeviceID: item.inventory_id })}>
                    <u>{item.ticket_num}</u>
                </span>
            ),
            "Serial_number": (
                <span className='py-12 cursor-pointer' onClick={(e) =>
                    setIsModal({ ...IsModal, DevicePopup: true, TicketID: item.id, DeviceID: item.inventory_id })}>
                    <u>{item?.inventory_management?.serial_number || '-'}</u>
                </span>
            ),
            "User": item?.inventory_management?.student_inventory?.student?.device_user_first_name ?
                (
                    <u onClick={(e) => {
                        setUserid(item?.inventory_management?.student_inventory?.student?.id);
                        setUserModal(true);
                    }} className="cursor-pointer">
                        {Camelcase(item?.inventory_management?.student_inventory?.student?.device_user_first_name) + ' ' + (Camelcase(item?.inventory_management?.student_inventory?.student?.device_user_middle_name || ' ')) + ' ' + Camelcase(item?.inventory_management?.student_inventory?.student?.device_user_last_name || '')}
                    </u>
                ) : '-',
            "return_location": item?.return_location?.name || '-',
            "created_at": item.created_at ? formatDateToMMDDYYYY(item.created_at) : "-",
            "created_by": item?.user?.first_name + ' ' + item?.user?.last_name || "-",
            "parts_total": item.subTotal ? `$${item.subTotal.toFixed(2)}` : "$0",
            "Device_model": item?.inventory_management?.device_model || "-",
            "asset_tag": item?.inventory_management?.asset_tag || "-",
            "Grade": item?.inventory_management?.student_inventory?.student?.grade || "-",
            "Parent_Guardian_Email": item?.inventory_management?.student_inventory?.student?.parent_guardian_email || "-",
            "Parent_phone_number": item?.inventory_management?.student_inventory?.student?.parent_phone_number || "-",
            "Parent_guardian_name": item?.inventory_management?.student_inventory?.student?.parent_guardian_name || "-",
            "Parental_coverage": item?.inventory_management?.student_inventory?.student?.parental_coverage == "1" ? "Yes" : "No",
            "Student_num": item?.inventory_management?.student_inventory?.student?.student_num || "-",
        };

        return contentMap[columnName] || null;
    }
    const handleSortClick = (item) => {
        if (item == 'asset_tag') {
            item = 'Asset_tag'
        }
        if (item !== 0) {
            if (TicketTabs.OpenTicketsTab == "active") {
                GetTickets(1, searchString.SearchForOpen, item)
            } else {
                GetTickets(2, searchString.SearchForAwaitingShip, item)
            }
        }
    };
    const renderTable = (gridData, selectedIds, showPagination, GridFlagg) => {
        return (
            <>
                <table className="table data-table mb-0" >
                    <thead className='GridHeader'>
                        <tr>
                            <th scope="col">
                                {gridData.length != 0 && (
                                    <input className='form-check-input' type="checkbox" checked={selectedIds.length === gridData.length} onChange={(e) => handleHeaderCheckboxChange(e, GridFlagg)} />
                                )}
                            </th>
                            {columnNames?.map((item) => {
                                const columnName = item.replace(/_/g, ' ');
                                return (
                                    item == "Serial_number" || item == 'User' || item == "asset_tag" || item == "Device_model" || item == "Student_num" || item == "Grade" || item == 'ticket_num' ?
                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={() => handleSortClick(item)}>
                                            {CamelCaseWithSpaces(columnName)}
                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                        </th>
                                        :
                                        <th key={item} scope="col" className='fw-600'>
                                            {CamelCaseWithSpaces(columnName)}
                                        </th>
                                );
                            })}
                            <th><img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2" title='Customize Headers' style={{ height: '25px', marginRight: '20px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                        </tr>

                    </thead>
                    <tbody>
                        {gridData.length != 0 ? (
                            gridData.map((item, i) => {
                                return (
                                    <tr key={i} className={`OddEvenClass tableBorderHide`}>
                                        <td className="py-12">
                                            <input className='form-check-input' type="checkbox" checked={selectedIds.includes(item.id)} onChange={(e) => handleCheckboxChange(e, GridFlagg, item.id)} ticketid={item.id} />
                                        </td>
                                        {columnNames?.map(header => (
                                            <td className="py-12" key={header}>  {renderContentForColumn(header, item)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                )
                            }))
                            :
                            <tr><td colSpan={15} className="p-3 text-center">  No Record Found</td></tr>
                        }
                    </tbody>
                </table>
                {showPagination && (
                    <Pagination activePage={currentPage} totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15} itemsCountPerPage={LimitForPagination} onChange={(e) => setCurrentPage(e)}
                        color="primary" lastPageText="" firstPageText="" itemClassFirst="MasterClass" itemClassPrev="MasterClass"
                        itemClassNext="MasterClass" itemClassLast="MasterClass" />
                )}
            </>
        );
    };
    function handleSearchChange(e) {
        const isActiveTab = TicketTabs.OpenTicketsTab == "active";
        const searchValue = e.target.value;
        setCurrentPage(1);
        GetTickets(isActiveTab ? 1 : 2, searchValue, TicketTabs.sortbykey);

        setSearchString(prevState => ({
            ...prevState,
            [isActiveTab ? 'SearchForOpen' : 'SearchForAwaitingShip']: searchValue
        }));
    }
    function handleTabClick(tabType) {
        const isAwaiting = tabType == 'awaiting';
        const ticketType = isAwaiting ? 2 : 1;
        const searchValue = isAwaiting ? searchString.SearchForAwaitingShip : searchString.SearchForOpen;
        const newTicketTabs = {
            ...TicketTabs,
            Schoolflag: isAwaiting ? 1 : 2,
            OpenTicketsTab: isAwaiting ? "" : "active",
            AwaitingShipTab: isAwaiting ? "active" : ""
        };
        const newSearchString = {
            ...searchString,
            SearchForOpen: '',
            SearchForAwaitingShip: ''
        };

        GetTickets(ticketType, searchValue, TicketTabs.sortbykey);
        setTicketTabs(newTicketTabs);
        setSearchString(newSearchString);
    }
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Ship Devices"} handleSearch={handleSearchChange}
                loading={loading} showsearch={true} />
            <section>
                <div className="GridBox p-3 mt-2">
                    <div className='row align-items-center'>
                        <div className="col-md-7 px-0">
                            <ul className="nav nav-tabs px-3">
                                <Tab isActive={TicketTabs.AwaitingShipTab == 'active'}
                                    label="Awaiting Shipment" onClick={() => handleTabClick('awaiting')} col={'col-md-4'} />
                                <Tab isActive={TicketTabs.OpenTicketsTab == 'active'}
                                    label="All Open Tickets" onClick={() => handleTabClick('open')} col={'col-md-4'} />
                            </ul>
                        </div>
                        <div className="col-md-5 text-end">
                            {totalBatches != 0 && (<label className="font-14 pt-1" style={{ color: 'black', cursor: 'pointer' }} onClick={(e) => navigate("/outgoing-batches")}> Total Selected Device : <b>{totalBatches}</b></label>)}
                            {IsModal.ShowCreateBatchButton == true && (
                                <label className="BorderBtn ms-3 text-center" onClick={ShowCreateBatchPopup}> Create Batch
                                    <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                                </label>
                            )}
                            <label className="font-14 pt-1 pe-3" style={{ color: 'black', cursor: 'pointer' }} onClick={(e) => navigate("/outgoing-batches")}><u> Created Batches</u></label>

                        </div>
                    </div>
                    <div className="greyBox p-3 mt-3">
                        <div className="row px-3">
                            <div className="greyBox py-0">
                                <div className="Header row align-items-center">
                                    <div className="col-12 data-table-container">
                                        {TicketTabs.AwaitingShipTab == 'active' ? (
                                            <>{renderTable(AllShipDevices, selectedIdsAll, showPagination, "all")}</>
                                        ) : TicketTabs.OpenTicketsTab == 'active' ? (
                                            <>{renderTable(AllShipDevicesForOpen, selectedIdsOpen, showPagination1, "open")}</>
                                        ) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {IsModal.TicketPopup && <TicketDetailsPopup isShow={IsModal.TicketPopup} ticketid={IsModal.TicketID} inventoryid={IsModal.DeviceID} ClosePopup={(e) => setIsModal({ ...IsModal, TicketPopup: false })} />}
            {IsModal.DevicePopup && <DeviceDetailsPopup Showbuttons={1} ticketid={IsModal.TicketID} createticketbutton={true} deviceid={IsModal.DeviceID} activetab="device" isShow={IsModal.DevicePopup} ClosePopup={(e) => setIsModal({ ...IsModal, DevicePopup: false })} />}
            {IsModal.createbatch && <CreateShippingCom SelectedItems={SelectedItems} schoolname={schoolname}
                AllShipDevices={AllShipDevices} setAllShipDevices={setAllShipDevices}
                ClosePopup={(e) => setIsModal({ ...IsModal, createbatch: false })} isShow={IsModal.createbatch}
                shippingFlag={1} GetTickets={GetTickets} action={(e) => setIsModal({ ...IsModal, createbatch: false, ShowCreateBatchButton: false })}
                setSelectedIdsAll={setSelectedIdsAll} setSelectedIdsOpen={setSelectedIdsOpen}
                ActiveTab={(e) => setTicketTabs({ ...TicketTabs, AwaitingShipTab: 'active', OpenTicketsTab: '' })}
                HideBtn={(e) => setIsModal({ ...IsModal, createbatch: false, ShowCreateBatchButton: false })}
            />}
            {CustomizationModal && <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Ship Devices'} GetCall={() => ApiGetHeaders(14, setColumnNames)} />}
            {UserModal && <UserDetailsPopup isShow={UserModal} ClosePopup={(e) => setUserModal(false)} userid={Userid} />}
        </React.Fragment >
    )
}
