import React, { useRef, useState } from 'react'
import { ApiGetCallAuthKey, ApiPostCallAuthKey } from '../../JS/Connector';
import { HideLoder, isValidEmail, ShowLoder } from '../../JS/Common';
import { CheckValidation } from '../../Components/Validations';
import ReCAPTCHA from "react-google-recaptcha";
import AlertsComp from '../../Components/AlertsComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function NoContractOption(props) {
    const [Loading, setLoading] = useState(false);
    const [blankCaptcha, setblankCaptcha] = useState("");
    const [studentData, setStudentData] = useState({
        studentnum: '',
        firstname: "",
        lastname: '',
        parentfirstname: '',
        parentlastname: '',
        phoneno: '',
        email: ''
    })
    var captchasitekey = process.env.REACT_APP_Captcha_Sitekey;
    const [CaptchaKey, setCaptchaKey] = useState("");
    const [NewAlerts, setNewAlerts] = useState("");
    const [studentnumerror, setstudentnumerror] = useState("")
    const [nostudenterror, setnostudenterror] = useState("")
    const [Emailerror, setEmailrror] = useState("")
    const [disableInputs, setdisableInputs] = useState(true)
    const recaptcharef = useRef();
    function clearStudentData() {
        setdisableInputs(true)
        setStudentData({
            ...studentData,
            firstname: "",
            lastname: '',
            parentfirstname: '',
            parentlastname: '',
            phoneno: '',
            email: ''
        })
    }
    async function Searchfunction(searchkey) {
        if (searchkey == "") {
            searchkey = null;
        }
        if (searchkey) {
            setLoading(true)
            await ApiGetCallAuthKey("/StudentDataByStudentNum/" + searchkey + '/' + props.SchoolId).then((result) => {
                if (result == undefined || result == "") {
                    setStudentData({
                        ...studentData,
                        studentnum: '',
                        firstname: "",
                        lastname: '',
                        parentfirstname: '',
                        parentlastname: '',
                        phoneno: '',
                        email: ''
                    })
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false)
                    if (responseRs.status == "success") {
                        const parentname = responseRs?.msg?.parent_guardian_name;
                        let pfirstName = "";
                        let plastName = "";
                        if (parentname) {
                            const splitNames = parentname?.split(' ');
                            pfirstName = splitNames.length > 0 ? splitNames[0] : '';
                            plastName = splitNames.length > 1 ? splitNames.slice(1).join(' ') : '';
                        }
                        setStudentData({
                            ...studentData, studentnum: responseRs?.msg?.student_num,
                            firstname: responseRs?.msg?.device_user_first_name || '',
                            lastname: responseRs?.msg?.device_user_last_name ? responseRs?.msg?.device_user_last_name.toUpperCase() : '',
                            parentfirstname: pfirstName,
                            parentlastname: plastName,
                            phoneno: responseRs?.msg?.parent_phone_number,
                            email: responseRs?.msg?.parent_guardian_email
                        })
                        setdisableInputs(false)
                        setnostudenterror("");
                    } else {
                        setStudentData({
                            ...studentData,
                            firstname: "",
                            lastname: '',
                            parentfirstname: '',
                            parentlastname: '',
                            phoneno: '',
                            email: ''
                        })
                        setdisableInputs(false)
                        setnostudenterror("This student ID was not found in K12Tech's system. Verify the information is correct and then press 'Verify Details' to save this student's information to Rocket.")
                    }
                }
            });
        }
    }
    function handleRecaptchaChange(value) {
        setCaptchaKey(value);
    }
    async function NoPurchaesPlanCall() {
        var isFormValid = CheckValidation({ formID: 'addProtectionForm' });
        if (CaptchaKey == "") {
            setblankCaptcha("Before you proceed, please complete the captcha");
            isFormValid = false;
        } else {
            setblankCaptcha("");
        }
        if (studentData.studentnum.trim() == "") {
            setstudentnumerror("*required");
            isFormValid = false;
        } else {
            setstudentnumerror("");
        }
        if (!isValidEmail(studentData.email)) {
            setEmailrror("*Invalid Email");
            isFormValid = false;
        } else {
            setEmailrror("");
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            SchoolID: props.SchoolId,
            sFirstName: studentData.firstname,
            sLastName: studentData.lastname,
            pFirstName: studentData.parentfirstname,
            pLastName: studentData.parentlastname,
            phoneNumber: studentData.phoneno,
            Email: studentData.email,
            Grade: props.planGradeVal,
            StudentNo: studentData.studentnum,
            Total: 0,
            PlanId: props.planid,
            OtherProducts: [],
            Captcha: CaptchaKey,
            PolicyAgree: props.PolicyAgreeFlag,
            coverage_purchased: 0,
            Flag: props.planflag
        });
        await ApiPostCallAuthKey("/purchasePlanjasonValidation", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    handlePurchasePlan(responseRs.tempId);
                }
                else {
                    recaptcharef.current.reset();
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    const handlePurchasePlan = async (TempId) => {
        var raw = JSON.stringify({
            TempID: TempId,
        });
        setLoading(true)
        await ApiPostCallAuthKey("/purchasePlan", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Student Added Successfully"} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                        window.location.reload();
                    }, 1500);
                }
                else {
                    alert('something went wrong')
                }
            }
        });
    }
    return (
        <div>
            {NewAlerts}
            {Loading ? <ShowLoder /> : <HideLoder />}
            <div className='text-center'><label style={{ color: '#3cbba5' }}>Please complete signup below with basic student information</label></div>
            <div className='row pt-3 pb-3'>
                <div className='col-12 text-start  pb-1 fw-600'>Student No. *</div>
                <div className={`row col-md-9 `}>
                    <div className='col-md-8'>
                        <input className="form-control" autoComplete="off" type="text" placeholder="Student No." name="studentnum"
                            value={studentData.studentnum} onKeyUp={clearStudentData}
                            onChange={(e) => setStudentData({ ...studentData, studentnum: e.target.value.toUpperCase() })}
                        />
                    </div>
                    <div className='col-md-4'>
                        <label className='col-12 BorderBtn text-center m-0 pt-2' onClick={(e) => Searchfunction(studentData.studentnum)}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} /> Search
                        </label>
                    </div>
                    <div className='col-12'>
                        <span className="Emailinvalid">
                            {studentnumerror}
                        </span>
                    </div>
                </div>
            </div>

            <span className="Emailinvalid pt-2">
                {nostudenterror}
            </span>
            <div className='col-12 pt-4'>
                <label className='fw-600'>How to find the student number?  :</label> <label className='redText'>{props.Description || '-'}</label>
            </div>
            <div className='col-12 py-3'>
                <div className='col-12 text-start px-1 fw-600'>Student Name*</div>
                <div className='row pt-1'>
                    <div className="col-md-6">
                        <input type="text" autoComplete='off' required name='firstname' disabled={disableInputs} className="form-control" placeholder='First Name'
                            value={studentData.firstname} onChange={(e) => { setStudentData({ ...studentData, firstname: e.target.value }) }} />
                        <span className="form-text invalid-feedback">
                            *required
                        </span>
                    </div>
                    <div className="col-md-6">
                        <input type="text" autoComplete='off' name='lastname' disabled={disableInputs} required className="form-control" placeholder='Last Name'
                            value={studentData.lastname} onChange={(e) => { setStudentData({ ...studentData, lastname: e.target.value.toUpperCase() }) }} />
                        <span className="form-text invalid-feedback">
                            *required
                        </span>
                    </div>
                </div>
            </div>
            <div className='py-3'>
                <div className='col-12 text-start px-1 fw-600'>Parent Name *</div>
                <div className='row pt-1'>
                    <div className="col-md-6">
                        <input type="text" autoComplete='off' name='pfname' disabled={disableInputs} required className="form-control" placeholder='First Name'
                            value={studentData.parentfirstname} onChange={(e) => { setStudentData({ ...studentData, parentfirstname: e.target.value }) }} />
                        <span className="form-text invalid-feedback"> *required </span>
                    </div>
                    <div className="col-md-6">
                        <input type="text" autoComplete='off' name='plname' disabled={disableInputs} required className="form-control" placeholder='Last Name'
                            value={studentData.parentlastname} onChange={(e) => { setStudentData({ ...studentData, parentlastname: e.target.value.toUpperCase() }) }} />
                        <span className="form-text invalid-feedback">  *required  </span>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className={`col-md-6 py-3`}>
                    <div className='col-12 text-start px-1 pb-1 fw-600'>Parent Contact #</div>
                    <input type="text" autoComplete='off' placeholder='Phone No.' name='contact' disabled={disableInputs} className="form-control"
                        value={studentData.phoneno} onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                                setStudentData({ ...studentData, phoneno: e.target.value });
                            }
                        }} />
                </div>
                <div className={`col-md-6 py-3`}>
                    <div className='col-12 text-start px-1 pb-1 fw-600'>Parent Email *</div>
                    <input type="text" autoComplete='off' placeholder='Email' name='email' disabled={disableInputs} className="form-control"
                        value={studentData.email} onChange={(e) => { setStudentData({ ...studentData, email: e.target.value }) }} />
                    <span className="Emailinvalid">{Emailerror}</span>
                </div>
            </div>
            <div className='row'>
                <div className='redText py-2 text-center'>
                    {props.UncoveredPrice == null ?
                        <label>Note: If your device breaks during the school year, you will be charged the full cost of the repair.</label>
                        :
                        <label>Note: If your device breaks during the school year, you will be charged the ${props.UncoveredPrice} uncovered flat fee.</label>
                    }

                </div>
                <div className="col-md-4 offset-md-4 text-center pb-2">
                    <div className="d-flex justify-content-center my-3">
                        <ReCAPTCHA ref={recaptcharef} sitekey={captchasitekey} onChange={handleRecaptchaChange} />
                    </div>
                    <div className="text-center">
                        <span style={{ fontSize: '.875em', color: '#dc3545' }}>{blankCaptcha}</span>
                    </div>
                </div>
                {props.PolicyAgreeFlag == 1 &&
                    <div className='col-12 text-center redText pb-2'>
                        By Continuing, you confirm that you have read our Fair Use Policy and agree to it.
                    </div>
                }
                <button type='submit' className='SaveBtn px-5 col-md-4 offset-md-4' onClick={(e) => NoPurchaesPlanCall()}>Verify Details</button>
                <label className="my-4 text-center cursor-pointer">
                    <u onClick={(e) => window.location.reload()}>Cancel</u>
                </label>
            </div>
        </div>
    )
}

export default NoContractOption
