import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Modal } from 'react-bootstrap'

export default function Popup({ isshow, size, title, closePopup, modalBody, handleSave, visibleSaveBtn,
    btnText, ExtraText, handleSaveForExtra, extraBtn, btntype, modalfooter, hideCloseButton }) {
    return (
        <Modal show={isshow} size={size}>
            <Modal.Header className='d-flex justify-content-between'>
                <Modal.Title>
                    <b className='font-16'>{title}</b>
                </Modal.Title>
                {!hideCloseButton && <FontAwesomeIcon icon={faTimes} className='cursor-pointer' onClick={closePopup} />}
            </Modal.Header>
            <Modal.Body>
                {modalBody}
            </Modal.Body>
            <Modal.Footer>
                {modalfooter ?
                    modalfooter
                    :
                    <>
                        {!hideCloseButton &&
                            <label className="ms-2 cursor-pointer" onClick={closePopup}><u>Cancel</u></label>
                        }
                        {extraBtn && (
                            <button className={`${btntype ? 'SaveBtn' : 'Cancel-btn'} px-3`} onClick={handleSaveForExtra}>{ExtraText}</button>
                        )}
                        {visibleSaveBtn && (
                            <button className='SaveBtn' onClick={handleSave}>{btnText}</button>
                        )}
                    </>
                }

            </Modal.Footer>
        </Modal>
    )
}
