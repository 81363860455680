import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { Camelcase, LogInUserID, typewatch } from '../../JS/Common';
import Pagination from 'react-js-pagination';
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from '../../JS/Connector';
import { CheckValidation } from '../../Components/Validations';
import AlertsComp from '../../Components/AlertsComp';
import { MDBSwitch } from 'mdb-react-ui-kit';
import Cookies from 'js-cookie';
import { faRightLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomizationPopup from '../../Components/CustomizationPopup';
import { useNavigate } from 'react-router-dom';
import PageHead from '../../Components/PageHead';
import Popup from '../../Components/Popup';

export function MasterInventory() {
    const [loading, setLoading] = useState(false);
    const [InventoryData, setInventoryData] = useState([]);
    const [InventoryPopup, setInventoryPopup] = useState(false);
    const [Alerts, setNewAlerts] = useState("");
    const [AllData, setAllData] = useState({
        Title: "",
        Price: "",
        PartId: "",
        Handle: "",
        SortbyFlag: ''
    });
    const [Searchstring, setSearchstring] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [addUpdateFlag, setAddUpdateFlag] = useState('')
    const showPagination = InventoryData?.length > 0;
    var ciphertext = Cookies.get('ciphertext');
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", ciphertext);
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        ApiGetHeaders(36, setColumnNames)
    }, []);

    useEffect(() => {
        GetAllMasterInventory(Searchstring ? Searchstring : null, '');
    }, [currentPage, LimitForPagination]);
    async function GetAllMasterInventory(searchkey, sFlag) {
        if (!searchkey) searchkey = null;
        sFlag = sFlag == "asc" ? "desc" : "asc";
        setAllData({ ...AllData, SortbyFlag: sFlag })
        typewatch(async function () {
            await ApiGetCall("/GetAllMasterInventory/" + searchkey + '&' + sFlag + '&' + currentPage + '&' + LimitForPagination + '&' + LogInUserID).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs.MasterSku.total);
                    setInventoryData(responseRs.MasterSku?.data?.length ? responseRs.MasterSku.data : [])
                }
            });
        }, 500);
    }
    async function EditMasterInventoryData(id) {
        setLoading(true)
        setInventoryPopup(true);
        await ApiGetCall("/GetMasterInventoryById/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                setAllData({
                    ...AllData, Title: responseRs.title, Price: responseRs.variant_price, PartId: responseRs.id,
                    Handle: responseRs.handle
                })
            }
        });
    }
    async function AddUpdateMasterPart(flag) {
        var isFormValid = CheckValidation({ formID: "InventoryPopup" });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            ID: AllData.PartId || null,
            Title: AllData.Title,
            Price: AllData.Price,
            Flag: flag,
            Handle: AllData.Handle
        });
        await ApiPostCall("/addupdateMasterInventory", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setInventoryPopup(false);
                if (responseRs.response == "success") {
                    var msg = flag == 1 ? 'Part Added Successfully' : 'Part Updated Successfully';
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        GetAllMasterInventory(null, '');
                    }, 2000);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 2000);
                }
            }
        });
    }
    async function ChangeMasterPartStatus(partid, flag, e) {
        const { name, checked } = e.target;
        let tempuser = InventoryData.map(user =>
            user.id == name ? { ...user, isChecked: checked } : user
        );
        setInventoryData(tempuser);
        var raw = JSON.stringify({
            ID: partid,
            Flag: 2
        });
        await ApiPostCall("/addupdateMasterInventory", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setInventoryPopup(false);
                if (responseRs.response == "success") {
                    var msg = flag == 1 ? 'Part Deactivated Successfully' : 'Part Activated Successfully';
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        GetAllMasterInventory(null, '');
                    }, 2000);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 2000);
                }
            }
        });
    }
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "Title": item.title || '-',
            "Variant_Price": `$${item.variant_price || 0}`,
            "Quantity": item.quantity || '-',
            "Handle": <div className='address'>{item.handle || '-'}</div>,
        };
        return contentMap[columnName] || null;
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        GetAllMasterInventory(e.target.value, AllData.SortbyFlag);
        setSearchstring(e.target.value)
    }
    const modalBody = (
        <div id="InventoryPopup" className="parent row">
            <div className='col-md-8'>
                <div className='FormLabel'>Title<label className='redText'>*</label></div>
                <input className="form-control" name='title' required value={AllData.Title}
                    onChange={(e) => setAllData({ ...AllData, Title: e.target.value })} />
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
            <div className='col-md-4'>
                <div className='FormLabel'>Price<label className='redText'>*</label></div>
                <div className="input-group">
                    <span className="input-group-text">$</span>
                    <input type="number" autoComplete="off" className="form-control" name="variantPrice"
                        min={0} value={AllData.Price} required onChange={(e) => setAllData({ ...AllData, Price: e.target.value })} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
            </div>
            <div className='py-3'>
                <div className='FormLabel'>Handle<label className='redText'>*</label></div>
                <input className="form-control col-12" name='title' required value={AllData.Handle}
                    onChange={(e) => setAllData({ ...AllData, Handle: e.target.value })} />
                <span className="form-text invalid-feedback">
                    *required
                </span>
            </div>
        </div>
    )
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Master Inventory"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="row mt-2 align-items-center">
                <div className='col-md-7'>
                </div>
                <div className='col-md-3 text-end'>
                    <label className="BorderBtn p-2 me-0 text-center" onClick={() => { navigate('/import-export') }}>
                        <FontAwesomeIcon icon={faRightLeft} className="pe-2" />
                        Import & Export Inventory
                    </label>
                </div>
                <div className='col-md-2 ps-0 text-end'>
                    <label className="p-2 BorderBtn text-center" onClick={(e) => { setAddUpdateFlag(1); setInventoryPopup(true); setAllData({ AllData, Title: '', Price: '', PartId: '', Handle: '', SortbyFlag: '' }) }} >
                        Add Inventory
                        <img src="/images/AddInventory.svg" className="img-fluid ps-2" />
                    </label>
                </div>
            </div>
            <div className="GridBox mt-2 p-3">
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                {columnNames?.map((item) => {
                                    const columnName = item.replace(/_/g, ' ');
                                    return (
                                        item == "Title" ?
                                            <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName} `} onClick={() => GetAllMasterInventory(Searchstring, AllData.SortbyFlag)}>
                                                {Camelcase(columnName)}
                                                <img src="/images/sortbynew.svg" alt={`Sort by ${columnName} `} className="img-fluid ps-2 sortbyClass" />
                                            </th>
                                            :
                                            <th key={item} scope="col" className='fw-600'>
                                                {Camelcase(columnName)}
                                            </th>
                                    );
                                })}
                                <th scope="col" className='fw-600'>Status</th>
                                <th scope="col" className='fw-600 text-center'>Action<img src="/images/EditHeaderIcon.svg" className="cursor-pointer  img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {InventoryData.length != 0 ?
                                InventoryData.map((item, i) => {
                                    return (
                                        <tr key={i} className="OddEvenClass tableBorderHide">
                                            {columnNames?.map(header => (
                                                <td className="py-12" key={header}>
                                                    {renderContentForColumn(header, item)}
                                                </td>
                                            ))}
                                            <td className="py-12">
                                                {item.status == 1 ?
                                                    <MDBSwitch name={item.id} checked={item?.isChecked || true} className="BgPink cursor-pointer" onChange={(e) => { ChangeMasterPartStatus(item.id, "1", e) }} partid={item.id} />
                                                    :
                                                    <MDBSwitch name={item.id} checked={item?.isChecked || false} className="BgPink cursor-pointer" onChange={(e) => { ChangeMasterPartStatus(item.id, "2", e) }} partid={item.id} />
                                                }
                                            </td>
                                            <td scope="col" className='text-center py-12'>
                                                <img src='/images/EditIconNew.svg' title='Edit Master Part' className='img-fluid pe-2 cursor-pointer'
                                                    onClick={(e) => { setAddUpdateFlag(0); EditMasterInventoryData(item.id) }} partid={item.id} />
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={15} className="p-3 text-center">
                                        No Record Found
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div>
            <Popup isshow={InventoryPopup} size={"md"} title={addUpdateFlag == 1 ? 'Add Master Part' : 'Edit Master Part'}
                closePopup={(e) => { setInventoryPopup(false); setAllData({ ...AllData, Title: '', Price: '', PartId: '', Handle: '', SortbyFlag: '' }) }}
                modalBody={modalBody} handleSave={(e) => addUpdateFlag == 1 ? AddUpdateMasterPart(1) : AddUpdateMasterPart(0)}
                visibleSaveBtn={true} btnText={addUpdateFlag == 1 ? 'Add' : 'Update'} />

            {CustomizationModal && <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Master Inventory'} modalSize={'l'} GetCall={(e) => ApiGetHeaders(36, setColumnNames)} />}
        </React.Fragment>
    )
}

