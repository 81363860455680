import { useState, useContext, useLayoutEffect } from 'react';
import '../Styles/Sidemenu.css';
import { UserContext } from '../App';
import LeftMenu from './Menu/LeftMenu';
import TopMenu from './Menu/TopMenu';
import { useLocation } from 'react-router-dom';
export function Sidemenu({ children }) {
    const { UserData } = useContext(UserContext);
    const [isWrapperToggled, setIsWrapperToggled] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname.split("/");
    var FinalPath = currentPath[1];
    useLayoutEffect(() => {
        if ([1, 5, 6].includes(UserData.MenuAccessFlag)) {
            const pageContentElement = document.querySelector('.page-content');
            pageContentElement.style.padding = (location.pathname == "/" || location.pathname == "/dashboard") ? '0rem' : '1.2rem';
        }
    }, [location, UserData.MenuAccessFlag]);
    return (
        <div className={`wrapper ${isWrapperToggled ? 'toggled' : ''}`}>
            <LeftMenu isWrapperToggled={isWrapperToggled} setIsWrapperToggled={setIsWrapperToggled} FinalPath={FinalPath} />
            <TopMenu setIsWrapperToggled={setIsWrapperToggled} />
            <main className="page-content">{children}</main>
        </div >
    )
}
