import React, { useContext, useEffect, useRef, useState } from "react"
import { ApiGetCall, ApiPostCall, ApiGetHeaders } from "../../JS/Connector";
import { CamelCaseWithSpaces, LogInSchoolID, LogInUserID, MMDDYYYY, typewatch } from "../../JS/Common";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import AlertsComp from "../../Components/AlertsComp";
import Deletepopup from "../../Components/Deletepopup";
import { faPencilAlt, faShippingFast, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateShippingCom from "../../Components/CreateShippingCom";
import Cookies from "js-cookie";
import jinja from "jinja-js";
import html2pdf from "html2pdf.js";
import CustomizationPopup from "../../Components/CustomizationPopup";
import BatchDetailsPopup from "../../Components/BatchDetailsPopup";
import { UserContext } from "../../App";
import SignatureCanvas from "react-signature-canvas";
import PageHead from "../../Components/PageHead";
import Popup from "../../Components/Popup";
function Outgoingbatches() {
    const BaseUrl = process.env.REACT_APP_Base_URL;
    const [Batches, setBatches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [AttachTicketData, setAttachTicketData] = useState([]);
    const [BatchParams, setBatchParams] = useState({
        BatchTicketDataModal: false,
        BatchId: '',
        SortbyFlag: 'desc',
        searchString: '',
        BatchName: ''
    });
    const [SortbyKey, setSortbyKey] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const showPagination = Batches.length > 0;
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const [deletePopup, setDeletePopup] = useState(false);
    const navigate = useNavigate()
    const [createbatch, setCreatebatch] = useState(false)
    const schoolDetail = Cookies.get('ShippingType')
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [batchData, setBatchData] = useState({
        batchName: '',
        batchNote: ''
    })
    var schoolname = Cookies.get('ShipSchoolName')
    const sigCanvasForPackedBy = useRef({});
    const [signatureModal, setSignatureModal] = useState(false)
    const [SignatureDataForPackedBy, setSignatureDataForPackedBy] = useState('')
    const [ReceivedData, setReceivedData] = useState({
        receivedname: '',
        receiveddate: ''
    })
    const { UserData } = useContext(UserContext);
    if (schoolname) {
        schoolname = decodeURIComponent(schoolname);
    }
    useEffect(() => {
        AllBatches(BatchParams.searchString ? BatchParams.searchString : null, SortbyKey ? SortbyKey : null);
    }, [currentPage, LimitForPagination]);
    useEffect(() => {
        ApiGetHeaders(4, setColumnNames)
    }, [])

    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "BatchName": <span>
                <u className="cursor-pointer" title="Show Ticket History" onClick={(e) => ShowTicketDataForBatch(item.id)} batchid={item.id}>{item.batch_name}</u>
            </span>,
            "TrackingNum": <span className="cursor-pointer">
                {item.tracking_num != null ? <u title="Track Shipment" onClick={(e) => trackingHandle(item.tracking_num)}>{item.tracking_num}</u> : <>-</>}
            </span>,
            "created_at": MMDDYYYY(item.created_at) || '-',
            "Notes": <div className="note-wrap">{item.notes || '-'}</div>,
            "Total_Devices": item.school_batch_log_count || '-',
        };
        return contentMap[columnName] || null;
    }
    async function AllBatches(searchString, SortKey) {
        if (!searchString) searchString = null;
        if (SortKey) BatchParams.SortbyFlag = BatchParams.SortbyFlag == "asc" ? "desc" : "asc";
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/getAllSchoolBatch/" + LogInSchoolID + '&' + searchString + '&' + SortKey + '&' + BatchParams.SortbyFlag + '&' + currentPage + '&' + LimitForPagination + '&' + 3).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs.total)
                    setBatches(responseRs.data.length ? responseRs.data : [])
                }
            });
        }, searchString != null ? 500 : 0);

    }
    async function ShowTicketDataForBatch(batchId) {
        setLoading(true)
        setBatchParams({ ...BatchParams, BatchTicketDataModal: true, BatchId: batchId });
        await ApiGetCall("/getSchoolBatchData/" + batchId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                setBatchData({ ...batchData, batchName: responseRs?.BatchName, batchNote: responseRs?.BatchNotes })
                if (responseRs.ticket.length != 0) {
                    setAttachTicketData(responseRs.ticket);
                } else {
                    setAttachTicketData([]);
                }
            }
        });
    }
    function trackingHandle(trackingnum) {
        const url = `https://www.fedex.com/fedextrack/?action=track&trackingnumber=${trackingnum}&cntry_code=us&locale=en_us`;
        window.open(url, '_blank');
    }
    async function ExtraDocument(FedExQr) {
        const link = document.createElement('a');
        link.href = `${BucketS3Link}${FedExQr}`;
        window.open(link.href, '_blank');
    };
    async function DeleteBatch() {
        setLoading(true);
        var raw = JSON.stringify({
            Flag: 1,
            BatchID: BatchParams.BatchId,
            SchoolID: parseInt(LogInSchoolID),
        });
        await ApiPostCall("/DeleteIncomingOutgoingOrBillingBatch", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setDeletePopup(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Batch Deleted Succesfully"} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                        AllBatches(BatchParams.searchString ? BatchParams.searchString : null, null);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    function PrintPackingList(batchid) {
        var ciphertext = Cookies.get('ciphertext');
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", ciphertext);
        setLoading(true)
        fetch('PackingList.html')
            .then(response => response.text())
            .then(templateText => jinja.compile(templateText))
            .then(template => {
                fetch(BaseUrl + "/printlistForBatch/" + batchid, { headers: myHeaders })
                    .then(response => response.json())
                    .then(data => {
                        const formattedDate = new Date(data.created_at).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        });
                        const rendered = template.render({ data, formattedDate });
                        const pdfContent = document.createElement('div');
                        pdfContent.innerHTML = rendered;
                        pdfContent.style.padding = '20px';
                        html2pdf().from(pdfContent).save();
                        setLoading(false)
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error('Error loading data from API', error);
                    });
            })
            .catch(error => {
                setLoading(false)
                console.error('Error loading template', error);
            });
    }
    const handleSortClick = (item) => {
        if (item == 'Total_Devices') {
            item = 'count'
        } else if (item == 'BatchName') {
            item = 'batch_name'
        } else if (item == 'TrackingNum') {
            item = 'tracking_num'
        } else if (item == 'Notes') {
            item = 'notes'
        }
        if (item !== 0) {
            AllBatches(BatchParams.searchString, item);
            setSortbyKey(item)
        }
    };
    const clearSignatureForPackedBy = () => {
        if (SignatureDataForPackedBy != null) {
            setSignatureDataForPackedBy(null)
        } else {
            sigCanvasForPackedBy.current.clear();
        }
    };
    async function SaveSignatureCall() {
        var signatureBase64 = null;

        if (SignatureDataForPackedBy == null) {
            signatureBase64 = sigCanvasForPackedBy.current.isEmpty() ? null : sigCanvasForPackedBy.current.getTrimmedCanvas().toDataURL("image/png");
        } else {
            signatureBase64 = SignatureDataForPackedBy
        }
        setLoading(true);
        var raw = JSON.stringify({
            BatchID: BatchParams.BatchId,
            schoolId: parseInt(LogInSchoolID),
            BatchPackerSignature: signatureBase64,
            BatchReceiverSignature: null,
            AdminBatchID: null,
            AdminBatchDeliverSignature: null,
            AdminBatchReceiverSignature: null,
            flag: 2,
            UserID: LogInUserID
        });
        await ApiPostCall("/addSignature", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setSignatureModal(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Signature Added Succesfully"} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                        AllBatches(BatchParams.searchString ? BatchParams.searchString : null, null);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        });
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        AllBatches(e.target.value, null);
        setBatchParams({ ...BatchParams, searchString: e.target.value });
    }
    const signaturemodalbody = (
        <div className="col-12 py-1">
            <div className="greyBox">
                <div className='fw-600 font-15 text-center'>Packed By Signature:</div>
                <div className='mt-2 text-center'>
                    {SignatureDataForPackedBy ? (
                        <img src={SignatureDataForPackedBy} alt="Packed By Signature" />
                    ) : (
                        <SignatureCanvas
                            ref={sigCanvasForPackedBy}
                            canvasProps={{ width: 430, height: 100, className: "signatureCanvas" }}
                        />
                    )}
                </div>
                <div className="text-end"> <label className="cursor-pointer" onClick={clearSignatureForPackedBy}><u>Clear</u></label></div>
            </div>
            {ReceivedData.receivedname != '' && (
                <div className="col-12 p-3 text-start">
                    <b>Received By : </b>{ReceivedData.receivedname}
                    <br />
                    <b>Date Received : </b>{ReceivedData.receiveddate}
                </div>
            )}
        </div>
    )
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"OutGoing Batches"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="GridBox mt-2 p-3">
                <div className="col-12 text-end">
                    <div className="font-14 pb-1" style={{ color: 'black', cursor: 'pointer' }} onClick={() => navigate("/ship-devices")}><u>  Go to Tickets</u></div>
                </div>
                <div className="greyBox p-3">
                    <div className="row px-3">
                        <div className="greyBox py-0">
                            <div className="Header row align-items-center">
                                <div className="col-12 data-table-container">
                                    <table className="table data-table mb-0" >
                                        <thead className='GridHeader'>
                                            <tr>
                                                {columnNames?.map((item) => {
                                                    const columnName = item.replace(/_/g, ' ');
                                                    return (
                                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={() => handleSortClick(item)} >
                                                            {CamelCaseWithSpaces(columnName)}
                                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                                        </th>
                                                    );
                                                })}
                                                {UserData.signatureFlag == 1 && (
                                                    <th scope="col" className='fw-600'>Signature </th>
                                                )}
                                                <th scope="col" className='fw-600'>Action <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Batches.length != 0 ?
                                                Batches.map((item, i) => {
                                                    return (
                                                        <tr key={i} className={`OddEvenClass tableBorderHide ${item.TicketFlag}`}>

                                                            {columnNames?.map(header => (
                                                                <td className="py-12" key={header}>
                                                                    {renderContentForColumn(header, item)}
                                                                </td>
                                                            ))}
                                                            {UserData.signatureFlag == 1 && (
                                                                <td>
                                                                    <FontAwesomeIcon icon={faPencilAlt} title="Signature" className="ps-2 cursor-pointer"
                                                                        onClick={(e) => {
                                                                            setSignatureDataForPackedBy(item.batch_packer_signature);
                                                                            setSignatureModal(true);
                                                                            setBatchParams({ ...BatchParams, BatchId: item.id, BatchName: item.batch_name });
                                                                            setReceivedData({ ...ReceivedData, receiveddate: item.received_date || '', receivedname: item.received_by_user ? item.received_by_user?.first_name + ' ' + item.received_by_user?.last_name : '' })
                                                                        }}
                                                                        batchid={item.id} />
                                                                </td>
                                                            )}
                                                            <td>
                                                                <img src="/images/DeleteIcon.svg" title="Delete Batch" style={{ height: "18px" }} className="img-fluid cursor-pointer"
                                                                    onClick={(e) => { setDeletePopup(true); setBatchParams({ ...BatchParams, BatchId: item.id }) }} batchid={item.id} />
                                                                {item.fedex_qr ?
                                                                    <img src="/images/previewpdficon.svg" className="cursor-pointer ps-2" title="Preview FedEX Document"
                                                                        onClick={(e) => ExtraDocument(item.fedex_qr)} style={{ height: "18px" }} batchid={item.id} />
                                                                    :
                                                                    schoolDetail == 2 ?
                                                                        <FontAwesomeIcon icon={faShippingFast} title="Create Shipping" className="ps-2 cursor-pointer" onClick={(e) => { setCreatebatch(true); setBatchParams({ ...BatchParams, BatchId: item.id, BatchName: item.batch_name }) }} batchid={item.id} />
                                                                        : <>-</>
                                                                }
                                                                <img src="/images/printlabelicon.svg" title="Show Packing List" className="img-fluid cursor-pointer ps-2" onClick={(e) => PrintPackingList(item.id)} style={{ height: 18 }} batchid={item.id} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={15} className="p-3 text-center">
                                                        No Record Found
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {showPagination && (
                                    <Pagination activePage={currentPage} totalItemsCount={pageRangeCount}
                                        pageRangeDisplayed={15} itemsCountPerPage={LimitForPagination} onChange={(e) => setCurrentPage(e)}
                                        color="primary" lastPageText="" firstPageText="" itemClassFirst="MasterClass" itemClassPrev="MasterClass"
                                        itemClassNext="MasterClass" itemClassLast="MasterClass" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {BatchParams.BatchTicketDataModal && <BatchDetailsPopup isShow={BatchParams.BatchTicketDataModal} ClosePopup={() => setBatchParams({ ...BatchParams, BatchTicketDataModal: false })} batchData1={batchData} AttachTicketData={AttachTicketData} />}
            <Deletepopup text={`You won't be able to revert this!`} deletePopup={deletePopup} size="xl" deletePart={DeleteBatch} DeleteShowClose={(e) => setDeletePopup(false)} />
            {createbatch && <CreateShippingCom schoolname={schoolname}
                ClosePopup={(e) => setCreatebatch(false)} isShow={createbatch} shippingFlag={2}
                batchname={BatchParams.BatchName} batchid={BatchParams.BatchId} AllBatches={AllBatches} />}
            {CustomizationModal && <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'OutGoing Batches'} GetCall={(e) => ApiGetHeaders(4, setColumnNames)} />}
            {signatureModal && (
                <Popup isshow={signatureModal} size={"md"} title={"Signature"}
                    closePopup={(e) => { setSignatureModal(false) }}
                    modalBody={signaturemodalbody} handleSave={SaveSignatureCall} visibleSaveBtn={true} btnText={"Save"} />
            )}
        </React.Fragment>
    )
}
export default Outgoingbatches
