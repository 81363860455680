import React from 'react'
import { useEffect } from 'react'
import { Fragment } from 'react'
import { ApiGetCall, ApiGetHeaders } from '../../JS/Connector'
import { CamelCaseWithSpaces, LogInSchoolID, LogInUserID, MMDDYYYY, typewatch } from '../../JS/Common'
import { useState } from 'react'
import AlertsComp from '../../Components/AlertsComp'
import SoftwareAddUpdate from './softwareaddupdate'
import SoftwareInfo from './SoftwareInfo'
import Pagination from 'react-js-pagination'
import CustomizationPopup from '../../Components/CustomizationPopup'
import PageHead from '../../Components/PageHead'
function ManageSoftware() {
    const [GridData, setGridData] = useState([])
    const [loading, setLoading] = useState(false);
    const [isaddupadtepopup, setIsaddupadtepopup] = useState(false);
    const [softwareInfoModal, setSoftwareInfoModal] = useState(false);
    const [Softwareid, setSoftwareid] = useState('');
    const [Alerts, setAlerts] = useState('');
    const [addupdateFlag, setaAddupdateFlag] = useState('');
    const [EditData, setEditData] = useState([]);
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const showPagination = GridData.length > 0;
    const [SortByFlag, setSortByFlag] = useState({
        SortbyFlag: "asc",
    });
    const [SearchString, setSearchString] = useState('')
    const [SortbyKey, setSortbyKey] = useState(null)
    const [columnNames, setColumnNames] = useState([]);
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    useEffect(() => {
        ApiGetHeaders(1, setColumnNames)
    }, []);
    useEffect(() => {
        GetAllData(SearchString ? SearchString : null, SortbyKey ? SortbyKey : null)
    }, [currentPage, LimitForPagination]);
    async function GetAllData(searchstring, sortbykey) {
        if (!searchstring) searchstring = null;
        if (sortbykey) SortByFlag.SortbyFlag = SortByFlag.SortbyFlag == "asc" ? "desc" : "asc";
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/GetAllSoftware/" + LogInSchoolID + '&' + searchstring + '&' + sortbykey + '&' + SortByFlag.SortbyFlag + '&' + currentPage + '&' + LimitForPagination + "&" + LogInUserID).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    setpageRangeCount(responseRs.msg.total);
                    setGridData(responseRs.msg.data.length ? responseRs.msg.data : [])
                }
            });
        }, 500);
    }
    async function GetDatabyID(id, flag) {
        setLoading(true);
        await ApiGetCall("/GetSoftwareById/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (flag == 1) {
                    setIsaddupadtepopup(true);
                } else {
                    setSoftwareInfoModal(true)
                }
                setEditData(responseRs)
                setLoading(false);

            }
        });
    }
    async function ExtraDocument(id) {
        await ApiGetCall("/GetSoftwareDocument/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = result;
                if (responseRs == "error") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"No extra attached document found"} />);
                    setTimeout(function () {
                        setAlerts(<AlertsComp show={false} />)
                    }, 3000);
                } else {
                    const link = document.createElement('a');
                    link.href = BucketS3Link + responseRs;
                    window.open(link.href, '_blank');
                }
            }
        });
    };
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "Name": item.name || '-',
            "Date_Purchased": item.date_purchased ? MMDDYYYY(item.date_purchased) : '-',
            "License_length": item.license_length || '-',
            "License_Renewal": item.license_renewal ? MMDDYYYY(item.license_renewal) : '-',
            "Cost": item.cost ? `$${item.cost}` : '0',
            "Buildings": item.buildings || '-',
            "License_Qty": item.license_qty || '-',
            "Document": item.document || '-',
            "Notes": <div className="note-wrap">{item.notes || '-'}</div>,
            "Grade": item.grade || '-',
            "Subject": item.subject || '-',
            "created_at": item.created_at ? MMDDYYYY(item.created_at) : '-'
        };

        return contentMap[columnName] || null;
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        GetAllData(e.target.value, null);
        setSearchString(e.target.value)
    }
    return (
        <Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Manage Software"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="GridBox mt-2 px-3 pb-3">
                <div className='row text-end'>
                    <div className="col-md-9"></div>
                    <div className="col-md-3 my-2">
                        <label className='BorderBtn text-center marginDetailsPopup me-0' onClick={(e) => { setIsaddupadtepopup(true); setaAddupdateFlag(1); setEditData([]) }}> Add Software
                            <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>
                </div>
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                {columnNames?.map((item) => {
                                    const columnName = item.replace(/_/g, ' ');
                                    return (
                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => { GetAllData(SearchString, item); setSortbyKey(item) }}>
                                            {CamelCaseWithSpaces(columnName)}
                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                        </th>
                                    );
                                })}
                                <th scope="col" className='cursor-pointer fw-600'>Action
                                    <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {GridData.length != 0 ?
                                GridData.map((item, i) => {
                                    return (
                                        <tr className="OddEvenClass tableBorderHide" key={i}>
                                            {columnNames?.map(header => (
                                                <td className="py-12" key={header}>
                                                    {renderContentForColumn(header, item)}
                                                </td>
                                            ))}
                                            <td scope="col" className='py-12'>
                                                <img src='/images/EditIconNew.svg' title='Edit Software' className='img-fluid pe-2 cursor-pointer' softwareid={item.id} onClick={(e) => { setSoftwareid(item.id); GetDatabyID(item.id, 1); setaAddupdateFlag(2) }} />
                                                <img src='/images/InfoIcon.svg' title="Show Software Details" className='pe-2' softwareid={item.id} onClick={(e) => { setSoftwareid(item.id); GetDatabyID(item.id, 2); }} />
                                                {item.document != null && (
                                                    <img src="/images/previewpdficon.svg" softwareid={item.id} className="" title="Preview Attached Document"
                                                        onClick={(e) => ExtraDocument(item.id)} style={{ width: '15px' }} />)}
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr className="text-center">
                                    <td colSpan={13}>No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div>

            {isaddupadtepopup && <SoftwareAddUpdate isShow={isaddupadtepopup}
                addupdateFlag={addupdateFlag}
                ClosePopup={(e) => setIsaddupadtepopup(false)}
                Softwareid={Softwareid}
                EditData={EditData}
                GetAllData={GetAllData}
            />}

            {softwareInfoModal == true && <SoftwareInfo isShow={softwareInfoModal}
                ClosePopup={(e) => setSoftwareInfoModal(false)}
                EditData={EditData}
            />}
            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Manage Software'} GetCall={(e) => ApiGetHeaders(1, setColumnNames)} />
            )}
        </Fragment >
    )
}

export default ManageSoftware
