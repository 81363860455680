import { useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker";
import { ApiGetCall } from "../../JS/Connector";
import { HideLoder, MMDDYYYY, ShowLoder } from "../../JS/Common";
import { TicketBySchool } from "./TicketBySchool";
import AveragePart from "./AveragePart";
import ReworkReport from "./ReworkReport";
import ReworkByTechnician from "./ReworkByTechnician";

export function ReportsIndex() {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);
    const [Locations, setLocations] = useState([]);
    const [Schools, setSchools] = useState([]);
    const [LocationValue, setLocationValue] = useState('');
    const [SchoolValue, setSchoolValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState({
        TicketSchool: [],
        AveragePart: [],
        ReworkTickets: []
    })
    const [startDate, setStartDate] = useState(oneMonthAgo);
    const [endDate, setEndDate] = useState(today);
    const [SchoolFilterData, setSchoolFilterData] = useState([]);
    const [TechnicianPerformance, setTechnicianPerformance] = useState([]);
    useEffect(() => {
        GetReports(startDate, endDate, null, null);
        GetTechnicianReports(startDate, endDate, null, null);
        ApiGetCall("/GetAllSchoolsByLocation").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs?.allLocation?.length != 0) {
                    setLocations(responseRs?.allLocation)
                    setSchoolFilterData(responseRs?.allSchool)
                    setSchools(responseRs?.allSchool)
                }
            }
        });
    }, []);
    async function GetReports(sdate, edate, locationid, schoolid) {
        setLoading(true);
        if (locationid == 0) {
            locationid = null;
        }
        if (schoolid == 0) {
            schoolid = null;
        }
        await ApiGetCall("/AdminSideReports/" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + "&" + locationid + "&" + schoolid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAllData({
                        ...allData, TicketSchool: responseRs.ticketbyschool, AveragePart: responseRs.parts_avrg, ReworkTickets: responseRs.reworkticketbyschool
                    })
                }
                setLoading(false);
            }
        });
    }
    async function GetTechnicianReports(sdate, edate, locationid, schoolid) {
        setLoading(true);
        if (locationid == 0) {
            locationid = null;
        }
        if (schoolid == 0) {
            schoolid = null;
        }
        await ApiGetCall("/TechnicianReworkTcketsPerformanceReport/" + MMDDYYYY(sdate) + "&" + MMDDYYYY(edate) + "&" + locationid + "&" + schoolid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setTechnicianPerformance(responseRs.TechnicianPerformance)
                setLoading(false);
            }
        });
    }
    function handleStartDateChange(date) {
        setStartDate(date);
        GetReports(date, endDate, LocationValue, SchoolValue);
        GetTechnicianReports(date, endDate, LocationValue, SchoolValue);
    };
    function handleEndDateChange(date) {
        setEndDate(date);
        GetReports(startDate, date, LocationValue, SchoolValue);
        GetTechnicianReports(startDate, date, LocationValue, SchoolValue);
    };
    const handleLocationValue = (val) => {
        setLocationValue(val);
        if (val == "0") {
            setSchoolFilterData(Schools)
        } else {
            var filterSchoolByLocation = Locations?.filter((data) => data?.id == parseInt(val))
            setSchoolFilterData(filterSchoolByLocation[0]?.school)
        }
    }
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row col-12 pe-0 align-items-center pb-3'>
                <div className='col-md-4'>
                    <h1 className="PageHeading">Reports</h1>
                </div>
                <div className="col-md-4 text-end row px-0">
                    <div className="col-md-6 d-flex  align-items-center py-2 px-0">
                        <label className="px-3"></label>
                        <ReactDatePicker
                            className="form-control me-4 text-center"
                            selected={startDate}
                            onChange={(e) => { handleStartDateChange(e); GetReports(e, endDate, LocationValue, SchoolValue); GetTechnicianReports(e, endDate, LocationValue, SchoolValue) }}
                            dateFormat="MM/dd/yyyy"
                        />
                    </div>
                    <div className="col-md-6 d-flex  align-items-center py-2 px-0">
                        <label className="px-3">To</label>
                        <ReactDatePicker
                            className="form-control me-4 text-center"
                            selected={endDate}
                            onChange={(e) => { handleEndDateChange(e); GetReports(startDate, e, LocationValue, SchoolValue); GetTechnicianReports(startDate, e, LocationValue, SchoolValue) }}
                            dateFormat="MM/dd/yyyy"
                            maxDate={today}
                        />
                    </div>
                </div>
                <div className="col-md-2 ms-2 py-1 pe-0">
                    <select value={LocationValue} onChange={(e) => { handleLocationValue(e.target.value); GetReports(startDate, endDate, e.target.value, SchoolValue); GetTechnicianReports(startDate, endDate, e.target.value, SchoolValue) }}>
                        <option value="0">Filter by Location</option>
                        {Locations.map((locationItem, j) => (
                            <option value={locationItem.id} key={j}>
                                {locationItem.locationName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2 ms-2 py-1 pe-0">
                    <select value={SchoolValue} onChange={(e) => { setSchoolValue(e.target.value); GetReports(startDate, endDate, LocationValue, e.target.value); GetTechnicianReports(startDate, endDate, LocationValue, e.target.value) }}>
                        <option value="0">Filter by School</option>
                        {SchoolFilterData?.length == 0 && <optgroup label="No school found"></optgroup>}
                        {SchoolFilterData?.map((item, j) => (
                            <option value={item.id} key={j}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='GridBox px-3'>
                <div className="row p-3">
                    <div className="col-12 p-2">
                        <AveragePart schooldata={allData.AveragePart} />
                    </div>
                    <div className="col-12 p-2">
                        <TicketBySchool schooldata={allData.TicketSchool} />
                    </div>
                    <div className="col-12 p-2">
                        <ReworkReport schooldata={allData.ReworkTickets}
                            startDate={startDate} endDate={endDate} LocationValue={LocationValue} SchoolValue={SchoolValue} />
                    </div>
                    <div className="col-12 p-2">
                        <ReworkByTechnician TechnicianPerformance={TechnicianPerformance} />
                    </div>
                </div>
            </div>
        </>
    )
}
