import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import { CamelCaseWithSpaces, MMDDYYYY, typewatch, LogInSchoolID } from "../../JS/Common";
import { useState } from "react";
import Deletepopup from "../../Components/Deletepopup";
import AlertsComp from "../../Components/AlertsComp";
import { DeviceDetailsPopup } from "../../Components/DeviceDetailsPopup";
import Pagination from "react-js-pagination";
import CustomizationPopup from "../../Components/CustomizationPopup";
import UserDetailsPopup from "../../Components/UserDetailsPopup";
import PageHead from "../../Components/PageHead";
export function IndexUsers() {
    const [AllUsers, setAllUsers] = useState([]);
    const [Alerts, setAlerts] = useState("");
    const [isshowpopup, setisshowpopup] = useState(false);
    const [deleteusershow, setdeleteusershow] = useState(false);
    const [GridData, setGridData] = useState({
        SearchString: "",
        sortbykey: "",
        sortbyflag: "desc",
        Userid: "",
        DeleteUserId: "",
        TicketId: null
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const showPagination = AllUsers.length > 0;
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [UserModal, setUserModal] = useState(false)
    const [Userid, setUserid] = useState('');
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    useEffect(() => {
        ApiGetHeaders(5, setColumnNames)
    }, []);
    useEffect(() => {
        GetAllUsers(null, null);
    }, [currentPage, LimitForPagination]);

    async function ExportUsersCall(flag) {
        await ApiGetCall("/UtilizerExport/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var msgdata = responseRs.msg;
                if (flag == 1) {
                    DownloadCsv(msgdata.length ? msgdata : [])
                } else {
                    const flatData = flattenData(msgdata);
                    const objectToCsvRow = (obj, headerKeys) => {
                        return headerKeys.map(key => `"${obj[key] || ''}"`).join(',');
                    };
                    const headerKeys = Array.from(new Set(flatData.flatMap(obj => Object.keys(obj))));
                    const csvContent = headerKeys.join(',') + '\n' + flatData.map(item => objectToCsvRow(item, headerKeys)).join('\n');
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = 'User_History.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }
                setLoading(false);
            }
        });
    }
    const DownloadCsv = (CsvDataForUser) => {
        const csvContent = [];
        const buildingMap = [];
        CsvDataForUser?.forEach((user) => {
            const rowData = {
                'User Name': user.StudentName,
                'Parent Guardian Name': user.parent_guardian_name,
                'Parent Guardian Email': user.parent_guardian_email,
                'Parent Guardian Number': user.parent_phone_number,
                'Student Number': user.student_num,
                'Device Serial Number': `${user?.Serial_number?.replace(/[,\n.]/g, '-')}`,
                'Grade': user.grade,
                'Department': user.department,
                'Parental Coverage': user.parental_coverage == 1 ? 'Yes' : 'No',
            };
            buildingMap.push(rowData);
        });

        if (buildingMap.length > 0) {
            const headers = ['User Name', 'Parent Guardian Name', 'Parent Guardian Email',
                'Parent Guardian Number', 'Student Number', 'Device Serial Number', 'Grade', 'Department', 'Parental Coverage'];
            csvContent.push(headers);
            buildingMap.forEach((rowData) => {
                const values = Object.values(rowData);
                csvContent.push(values);
            });
            csvContent.push([]);
            const csvString = csvContent.map((row) => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Users.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No data available for download.');
        }
    };

    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "Name": (item?.device_user_first_name || '-') + ' ' + (item?.device_user_middle_name || '') + ' ' + (item?.device_user_last_name || ''),
            "Grade": item.grade || '-',
            "Department": item.department || '-',
            "Device_Serial_#": (
                <span>
                    {item?.inventories.length > 0 ? (
                        item?.inventories?.map((inventory, index) => (
                            <span key={index} onClick={() => {
                                setGridData({ ...GridData, Userid: inventory.ID, TicketId: inventory.TicketID ? inventory.TicketID : null });
                                setisshowpopup(true);
                            }} className={`cursor-pointer`}>
                                {index > 0 && ", "}
                                <u className={`${inventory?.TicketFlag == 1 ? 'redText' : ''}`}>{inventory?.Serial_number}</u>
                            </span>
                        ))
                    ) : (
                        <>- </>
                    )}
                </span>
            ),
            "Student_num": item.student_num || '-',
            "Parent_guardian_name": item.parent_guardian_name || '-',
            "Parent_phone_number": item.parent_phone_number || '-',
            "Parent_Guardian_Email": item.parent_guardian_email || '-',
            "Parental_coverage": item.parental_coverage == "1" ? "Yes" : "No",
            "created_at": item.created_at == null ? '-' : MMDDYYYY(item.created_at),
        };
        return contentMap[columnName] || null;
    }
    async function GetAllUsers(sortbykey, searchstring) {
        if (searchstring == "") {
            searchstring = null;
        }
        if (!GridData.SearchString) GridData.SearchString = null;
        if (sortbykey) GridData.sortbyflag = GridData.sortbyflag == "asc" ? "desc" : "asc";
        if (!sortbykey) sortbykey = "id";
        var joinedString = null;
        if (columnNames.length > 0) {
            const replacePlaceholder = (str) => str.replace(/__HASH__/g, '#');
            joinedString = columnNames.map((columnName) => replacePlaceholder(columnName)).join('~').replace(/#/g, 'num');
        }
        typewatch(async function () {
            setLoading(true)
            await ApiGetCall("/UtilizerData/" + LogInSchoolID + "&" + searchstring + "&" + sortbykey + "&" + GridData.sortbyflag + "&" + currentPage + '&' + LimitForPagination + "?data=" + joinedString).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs?.msg?.total);
                    setAllUsers(responseRs?.msg?.data?.length ? responseRs?.msg?.data : [])
                    setLoading(false);

                }
            });
        }, GridData.SearchString != null ? 500 : 0);
    }

    const flattenData = (CsvUserDataForUser) => {
        const flatData = [];
        CsvUserDataForUser.forEach((student) => {
            const studentData = {
                StudentName: student.StudentName,
                Student_num: student.student_num,
                Grade: student.grade || "",
                ParentName: student.parent_guardian_name || "",
                ParentEmail: student.parent_guardian_email || "",
                ParentNumber: student.parent_phone_number || ""
            };
            if (student.Allocations.length > 0) {
                student.Allocations.forEach((log, index) => {
                    if (index < 3) {
                        studentData[`SerialNum${index + 1}`] = log?.SerialNum;
                        studentData[`AllocatedDate${index + 1}`] = log?.AllocatedDate;
                        studentData[`LonerDeviceAllocationDate${index + 1}`] = log?.LonerDeviceAllocationDate;
                        studentData[`VacantDate${index + 1}`] = log?.VacantDate;
                    }
                });
            }
            flatData.push(studentData);
        });
        return flatData;
    };

    async function DeleteUser() {
        setLoading(true);
        var raw = "";
        await ApiPostCall("/deleteUtilizer/" + GridData.DeleteUserId, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setdeleteusershow(false);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"User Deleted Successfully."} />);
                    setTimeout(() => {
                        GetAllUsers(null, GridData.SearchString);
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
            }
        });
    }
    const handleSortClick = (item) => {
        if (item == "Name") {
            item = "device_user_first_name";
        } else {
            item = item.toLowerCase().split(' ').join('_');
        }
        if (item !== 0) {
            GetAllUsers(item, GridData.SearchString)
            setGridData({ ...GridData, sortbykey: item })
        }
    };
    function handleSearchChange(e) {
        setCurrentPage(1);
        GetAllUsers(null, e.target.value);
        setGridData({ ...GridData, SearchString: e.target.value ? e.target.value : "" });
    }
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Manage Users"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="row my-2 pe-0 align-items-center">
                <div className="col-md-12 text-center justify-content-end align-items-end d-md-flex">
                    <a onClick={(e) => navigate("/import-export")} className="BlackFont cursor-pointer">
                        <label className="BorderBtn text-center">
                            Import Users<img src="/images/ImportInventory.svg" className="img-fluid ps-2" />
                        </label>
                    </a>
                    {AllUsers.length > 0 && (
                        <div className="BorderBtn ms-3" onClick={(e) => ExportUsersCall(1)}>
                            <label style={{ color: "black" }}> Export Users </label>
                            <img src="/images/ExportInventory.svg" className="img-fluid ps-2" />
                        </div>
                    )}
                    {AllUsers.length > 0 && (
                        <div className="BorderBtn ms-3" onClick={(e) => ExportUsersCall(2)}>
                            Export User History
                            <img src="/images/ExportInventory.svg" className="img-fluid ps-2" />
                        </div>
                    )}
                    <label className='BorderBtn ms-3 text-center' onClick={(e) => navigate("/addupdate-users")}> Add User
                        <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                    </label>
                </div>
                <div className="col-md-12">
                    <label className="theme-colorcode">* Student has Parental Coverage Plan</label>&nbsp;&nbsp;
                    <label className="redText">* A ticket has been created on the device</label>
                </div>
            </div>
            <div className="GridBox p-3">
                <div className="col-12 greyBox data-table-container">
                    <table className="table data-table">
                        <thead className="GridHeader">
                            <tr>
                                {columnNames?.map((item) => {
                                    const columnName = item.replace(/_/g, ' ');
                                    return (
                                        item != "Device_Serial_#" && item != "Parent_phone_number" ?
                                            <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={() => handleSortClick(item)}>
                                                {CamelCaseWithSpaces(columnName)}
                                                <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                            </th>
                                            :
                                            <th key={item} scope="col" className='fw-600'>
                                                {CamelCaseWithSpaces(columnName)}
                                            </th>
                                    );
                                })}
                                <th scope="col" className="fw-600" title=" Fair Use Acknowledgment">Ack</th>
                                <th scope="col" className="fw-600">  Action <img src="/images/EditHeaderIcon.svg" className="cursor-pointer  img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                            </tr>
                        </thead >
                        <tbody>
                            {AllUsers && AllUsers.length > 0 ? (
                                AllUsers.map((item, index) => (
                                    <tr key={index} className={`${item.studenthaveplan == 1 ? 'theme-colorcode' : ''} OddEvenClass tableBorderHide`}>
                                        {columnNames?.map(header => (
                                            <td className="py-12" key={header}>
                                                {renderContentForColumn(header, item)}
                                            </td>
                                        ))}
                                        <td className="py-12">
                                            {item.studentagreepolicy == 1 ? 'Yes' : item.studentagreepolicy == 0 ? 'No' : '-'}
                                        </td>
                                        <td className="py-12">
                                            <img src="/images/EditIconNew.svg" title="Edit User" className="img-fluid pe-2 cursor-pointer" utilizerid={item.id} onClick={(e) => { navigate("/addupdate-users/?id=" + item.id) }} />
                                            <img src="/images/DeleteIcon.svg" title="Delete User" className="img-fluid cursor-pointer" utilizerid={item.id} onClick={(e) => { setdeleteusershow(true); setGridData({ ...GridData, DeleteUserId: item.id }) }} />
                                            <img src="/images/InfoIcon.svg" title="Show User History" className="img-fluid cursor-pointer px-2" utilizerid={item.id} onClick={(e) => { setUserid(item.id); setUserModal(true) }} />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="col-12 text-center p-2">
                                    <td colSpan={12}>No Record Found</td>
                                </tr>
                            )}

                        </tbody>
                    </table >
                </div >
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}

            </div>

            {UserModal && <UserDetailsPopup isShow={UserModal} ClosePopup={(e) => setUserModal(false)} userid={Userid} />}
            {isshowpopup && <DeviceDetailsPopup Showbuttons={1} ticketid={GridData.TicketId} createticketbutton={true} deviceid={GridData.Userid} activetab="device" isShow={isshowpopup} ClosePopup={(e) => setisshowpopup(false)} />}
            {CustomizationModal && <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Manage Users'} GetCall={() => ApiGetHeaders(5, setColumnNames)} />}
            <Deletepopup text={`You won't be able to revert this!`} deletePopup={deleteusershow} deletePart={DeleteUser} DeleteShowClose={(e) => setdeleteusershow(false)}></Deletepopup>
        </React.Fragment>
    )
}
