import React from 'react'
import HdRequestData from './HdRequestData';
import Popup from './Popup';

export default function HDrequestpopup(props) {
    var PopupData = props.PopupData;
    return (
        <Popup isshow={props.isShow} size={"md"} title={"Helpdesk Request Details"}
            closePopup={(e) => props.closepopup()}
            modalBody={<HdRequestData data={PopupData} />} visibleSaveBtn={false} />
    )
}
