import React, { useState } from 'react'
import Chart from "react-apexcharts";
import AlertsComp from '../../Components/AlertsComp';
import { MMDDYYYY } from '../../JS/Common';
import { ApiGetCall } from '../../JS/Connector';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default function ReworkReport(props) {
    const [alerts, setalerts] = useState('');
    var vData = props.schooldata;
    var series;
    var options;
    let dynamicHeight = 250;
    if (vData?.series != undefined) {
        series = vData?.series
        dynamicHeight = Math.max(300, vData.schools.length * 20);
        options = {
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false,
                },
            },
            colors: ["#3CBBA5", "#ffa5a5", "#fdc457"],
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 20,
                            style: {
                                fontSize: '10px',
                                fontWeight: 900
                            },
                            formatter: function (val, opts) {
                                const schoolIndex = opts.dataPointIndex;
                                const totaltickets = vData.totalTickets[schoolIndex];
                                const reworkPercentage = vData.reworkpercentage[schoolIndex];
                                return `${totaltickets} (${reworkPercentage}%)`;
                            }
                        }
                    }
                },
            },

            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: vData?.schools,
                title: {
                    text: 'Tickets'
                },

            },
            yaxis: {
                title: {
                    text: 'Schools'
                },
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                offsetX: 40,
                show: false
            }
        };
    }

    function DownloadReworkReport() {
        const csvContent = [];
        const gradeMap = new Map();
        csvContent.push(['School Name', 'Approved Rework Tickets', 'Denied Rework Tickets', 'Pending Rework Tickets', 'Non-Rework Tickets', 'Total Tickets']);
        var location = props.LocationValue;
        var school = props.SchoolValue;
        if (props.LocationValue == 0 || props.LocationValue == "") {
            location = null;
        }
        if (props.SchoolValue == 0 || props.SchoolValue == "") {
            school = null;
        }
        ApiGetCall("/ReworkDetailsReport/" + MMDDYYYY(props.startDate) + '&' + MMDDYYYY(props.endDate) + '&' + location + '&' + school + '&' + 2).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                responseRs?.forEach((ticket, i) => {
                    const grade = ticket.School || 'Unknown';
                    if (!gradeMap.has(grade)) {
                        gradeMap.set(grade, []);
                    }
                    const rowData = {
                        'School Name': ticket?.school_name,
                        'Approved Rework Tickets': ticket.approved_rework_tickets,
                        'Denied Rework Tickets': ticket?.denied_rework_tickets,
                        'Pending Rework Tickets': ticket?.Pending_rework_tickets,
                        'Non-Rework Tickets': ticket?.non_rework_tickets,
                        'Total Tickets': ticket?.total_tickets
                    };
                    gradeMap.get(grade).push(rowData);
                });
                gradeMap.forEach((gradeData) => {
                    gradeData.forEach((rowData) => {
                        const values = Object.values(rowData).map(value => {
                            if (typeof value == 'string' && value.includes(',')) {
                                return `"${value}"`;
                            }
                            return value;
                        });
                        csvContent.push(values);
                    });
                });

                if (csvContent.length > 1) { // Check if there is data (excluding headers)
                    const csvString = csvContent.map(row => row.join(',')).join('\n');
                    const blob = new Blob([csvString], { type: 'text/csv' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'ReworkVerificationTickets.csv';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    setalerts(<AlertsComp show={true} variant="danger" msg={'No data available for download.'} />);
                    setTimeout(function () {
                        setalerts(<AlertsComp show={false} />)
                    }, 2500);
                }
            }
        });
    }
    function DownloadReport() {
        const csvContent = [];
        const gradeMap = new Map();
        csvContent.push(['School Name', 'Ticket #', 'Ticket Status', 'Old Ticket #', 'Rework Status', 'Created At', 'Who Worked On']);
        var location = props.LocationValue;
        var school = props.SchoolValue;
        if (props.LocationValue == 0 || props.LocationValue == "") {
            location = null;
        }
        if (props.SchoolValue == 0 || props.SchoolValue == "") {
            school = null;
        }
        ApiGetCall("/ReworkDetailsReport/" + MMDDYYYY(props.startDate) + '&' + MMDDYYYY(props.endDate) + '&' + location + '&' + school + '&' + 1).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                responseRs?.forEach((ticket, i) => {
                    const grade = ticket.School || 'Unknown';
                    if (!gradeMap.has(grade)) {
                        gradeMap.set(grade, []);
                    }
                    const rowData = {
                        'School Name': ticket?.schoolName,
                        'Ticket #': ticket.ticket_num,
                        'Ticket Status': ticket?.statusname?.status,
                        'Old Ticket #': ticket?.oldTicketNum,
                        'Rework Status': ticket?.ticketReworkStatus,
                        'Created At': MMDDYYYY(ticket?.created_at),
                        'Who Worked On': ticket?.TechName
                    };
                    gradeMap.get(grade).push(rowData);
                });
                gradeMap.forEach((gradeData) => {
                    gradeData.forEach((rowData) => {
                        const values = Object.values(rowData).map(value => {
                            if (typeof value == 'string' && value.includes(',')) {
                                return `"${value}"`;
                            }
                            return value;
                        });
                        csvContent.push(values);
                    });
                });

                if (csvContent.length > 1) { // Check if there is data (excluding headers)
                    const csvString = csvContent.map(row => row.join(',')).join('\n');
                    const blob = new Blob([csvString], { type: 'text/csv' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'ReworkTickets.csv';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    setalerts(<AlertsComp show={true} variant="danger" msg={'No data available for download.'} />);
                    setTimeout(function () {
                        setalerts(<AlertsComp show={false} />)
                    }, 2500);
                }
            }
        });


    }

    return (
        <>
            {alerts}
            <div className="col-12  h-100">
                <div className="greyBoxShadow w-100">
                    <div className="row mt-2 pt-2 time text-center">
                        <div className='Header'>
                            <h5 className="fw-bold ps-2 mb-0">Rework Tickets By School</h5>
                        </div>
                        <div className='col-12 d-flex justify-content-evenly py-3'>
                            <u className="cursor-pointer" onClick={(e) => DownloadReport()}>Download Detailed Rework Report</u>
                            <u className="cursor-pointer" onClick={(e) => DownloadReworkReport()}>Download Rework Verification Report</u>
                        </div>
                    </div>
                    <div className="pb-0">
                        {vData == null ?
                            <div className="fw-bold py-3 text-center">No tickets found.</div>
                            : vData?.schools != undefined &&
                            <Chart
                                options={options}
                                series={series}
                                type={"bar"}
                                height={dynamicHeight}
                            />}

                    </div>
                    <div className='row '>
                        <div className='col-md-4 d-flex align-items-center mb-2'>
                            <div className='setup-status-square d-flex' style={{ backgroundColor: "#3CBBA5" }}></div>
                            <label className='ps-2 content-forsetupcards'>Approve Rework Tickets</label>
                            <OverlayTrigger
                                trigger={['hover', 'focus']}
                                placement="auto"
                                overlay={
                                    <Popover>
                                        <Popover.Body>These tickets were marked as rework and approved by the admin.</Popover.Body>
                                    </Popover>
                                }>
                                <img src='/images/InfoBtn.svg' className='img-fluid cursor-pointer ps-2' style={{ height: "12px" }} />
                            </OverlayTrigger>
                        </div>
                        <div className='col-md-4 d-flex align-items-center mb-2'>
                            <div className='setup-status-square d-flex' style={{ backgroundColor: "#ffa5a5" }}></div>
                            <label className='ps-2 content-forsetupcards'>Denied Rework Tickets</label>
                            <OverlayTrigger
                                trigger={['hover', 'focus']}
                                placement="auto"
                                overlay={
                                    <Popover>
                                        <Popover.Body>These tickets were marked as rework and denied by the admin.</Popover.Body>
                                    </Popover>
                                }>
                                <img src='/images/InfoBtn.svg' className='img-fluid cursor-pointer ps-2' style={{ height: "12px" }} />
                            </OverlayTrigger>
                        </div>
                        <div className='col-md-4 d-flex align-items-center mb-2'>
                            <div className='setup-status-square d-flex' style={{ backgroundColor: "#fdc457" }}></div>
                            <label className='ps-2 content-forsetupcards'>Pending Rework Tickets</label>
                            <OverlayTrigger
                                trigger={['hover', 'focus']}
                                placement="auto"
                                overlay={
                                    <Popover>
                                        <Popover.Body>These tickets were marked as rework but were not approved or denied by the admin</Popover.Body>
                                    </Popover>
                                }>
                                <img src='/images/InfoBtn.svg' className='img-fluid cursor-pointer ps-2' style={{ height: "12px" }} />
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
